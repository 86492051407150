import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  trialBanner: false,
  temporaryHideTrialBanner: false,
  trialEndDate: null,
  displayedOutdatedTrial: null
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
