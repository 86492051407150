function show(context) {
  context.commit('show');
}

function hide(context) {
  context.commit('hide');
}

export default {
  hide,
  show
};
