<template>
  <div>
    <div class="subscription-picker">
      <div v-if="!displayCampaignInfo" class="picker-title">
        {{ $t('payment_new_subscription_title') }}
      </div>
      <div class="options-container">
        <div
          v-if="
            selectedProduct && selectedProduct.category === 'annonsforlangning'
          "
          class="subscription-options"
        >
          <div
            v-for="(product, index) in sortedProducts"
            :key="product.productId"
            :class="[
              'option',
              {
                'option-is-selected':
                  product.productId === selectedProduct.productId
              }
            ]"
            @click="handleSelectProductId(product.productId)"
          >
            <div v-if="index == 1" class="option-label">
              {{ $t('subscription_picker_label') }}
            </div>
            <div v-if="!displayCampaignInfo" class="subscription-period">
              <div class="period-length">
                {{ productTitle(product).length }}
              </div>
              <div class="period-label">{{ productTitle(product).label }}</div>
            </div>
            <div v-if="displayCampaignInfo" class="price-description">
              <div class="campaign-name">
                {{ product.name }}
              </div>
              {{ product.description }}
            </div>
            <div class="price-per-month">
              <template v-if="!product.campaign">
                {{ monthlyPrice(product) }}
                {{ $t('subscription_picker_new_currency') }}/{{
                  $t('subscription_picker_month_short')
                }}
              </template>
              <template v-else>
                {{ product.priceWithVAT }}
                {{ $t('subscription_picker_currency') }}
              </template>
            </div>
            <div v-if="monthlyPercentageOff(product)" class="price-discount">
              {{ $t('subscription_picker_save_perc_prefix') }}
              {{ monthlyPercentageOff(product) }}%
            </div>
          </div>
        </div>
      </div>

      <div v-if="displayCampaignInfo" class="campaign-info">
        <strong>{{ $t('product_list_title') }}</strong>
        <div>
          {{ $t('product_list_info_1') }}
          {{ paymentPeriod }} {{ $t('product_list_info_2') }} ({{
            selectedProduct.subscriptionProduct.priceInclVat
          }}
          {{ $t('product_list_info_3') }}).
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import events from '@/utils/helpers/events';

export default {
  name: 'SubscriptionPickerNew',

  computed: {
    ...mapGetters({
      products: 'klarnaPayments/getProducts',
      selectedProduct: 'klarnaPayments/getSelectedProduct',
      isMobile: 'screenSize/isMobile'
    }),

    sortedProducts() {
      const sorted = [...this.products];

      sorted.sort((a, b) => {
        return a.quantity > b.quantity ? 1 : -1;
      });

      return sorted;
    },

    displayCampaignInfo() {
      const { selectedProduct } = this;

      return (
        selectedProduct &&
        selectedProduct.campaign &&
        selectedProduct.subscriptionProduct
      );
    },

    paymentPeriod() {
      switch (this.selectedProduct.subscriptionProduct.quantity) {
        case 30:
          return this.$t('product_list_monthly');
        case 60:
          return this.$t('product_list_every_second_month');
        case 180:
          return this.$t('product_list_every_six_month');
        default:
          return this.$t('product_list_when_expired');
      }
    }
  },

  watch: {
    products() {
      if (this.products.length === 0) {
        return;
      }
      const productIds = this.products.map(p => p.id);
      events.emitEvent(events.eventTypes.PAGEVIEW, 'Payment', {
        productIds
      });
    }
  },

  methods: {
    ...mapActions({
      setSelectedProductId: 'klarnaPayments/selectProductId'
    }),

    handleSelectProductId(productId) {
      window.Logger.log('[ProductList]: Selecting productId: ', productId);
      if (productId) {
        this.setSelectedProductId({ productId });
      }
    },

    monthlyPrice(product) {
      if (this.$country.isCountry('SE')) {
        return Math.ceil(product.priceInclVat / (product.quantity / 30));
      }

      return (product.priceInclVat / (product.quantity / 30)).toFixed(2);
    },

    monthlyPercentageOff(product) {
      const mostExpensiveProductPrice = this.monthlyPrice(
        this.sortedProducts[0]
      );
      const discount = mostExpensiveProductPrice - this.monthlyPrice(product);

      if (discount > 0) {
        return Math.floor((discount / mostExpensiveProductPrice) * 100);
      }

      return false;
    },

    productTitle(product) {
      // Do not change name if product is a campaign product
      if (!product.subscriptionProduct) {
        switch (product.quantity) {
          case 30:
            return {
              length: '1',
              label: this.$t('payment_new_month')
            };
          case 60:
            return {
              length: '2',
              label: this.$t('payment_new_months')
            };
          case 90:
            return {
              length: '3',
              label: this.$t('payment_new_months')
            };
          case 180:
            return {
              length: '6',
              label: this.$t('payment_new_months')
            };
          default:
            return product.name;
        }
      } else {
        return product.name;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.subscription-picker {
  margin-top: 2rem;
  margin-bottom: 20px;
}

.picker-title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
}

.options-container {
  position: relative;
  padding-top: 16px;
}

.subscription-options {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  column-gap: 10px;
}

.option {
  position: relative;
  padding: 14px 4px 8px;
  border: 2px solid #dedede;
  border-radius: 8px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.option-is-selected {
  border-color: #409fff;
}

.option-label {
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 10px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #dedede;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.05em;
  white-space: nowrap;

  @media ($desktop) {
    font-size: 11px;
    top: -17px;
  }

  .option-is-selected & {
    background-color: #409fff;
    color: #f8f8fa;
  }
}

.subscription-period {
  font-weight: 700;
}

.period-length {
  font-size: 38px;
  margin-bottom: 2px;
}

.period-label {
  font-size: 17px;
  margin-bottom: 22px;
}

.price-per-month {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 4px;
}

.price-description {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 600;
}

.campaign-name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.price-discount {
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  color: #393939;
  background-color: #eee;
  border-radius: 34px;
  padding: 4px 8px;
}

.subscription-terms-info {
  margin-top: -5px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.campaign-info {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;

  @media ($desktop) {
    text-align: center;
  }
}
</style>
