<template>
  <div class="favourites">
    <h1 class="favourites-title">
      {{ $t('my_favouries_title') }}
    </h1>

    <div v-if="favouritesLoading" class="loader-wrapper">
      <SearchCardLoader v-for="i in [1, 2, 3, 4]" :key="i" />
    </div>

    <SearchCardsWrapper
      v-if="!favouritesLoading && filteredFavourites.length > 0"
    >
      <SearchCard
        v-for="favourite in filteredFavourites"
        :key="favourite.propositionId"
        :proposition="favourite"
        :large-search-cards="true"
      />
    </SearchCardsWrapper>
    <p
      v-if="!favouritesLoading && filteredFavourites.length === 0"
      class="info-text"
    >
      {{ $t('my_favouries_text') }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SearchCard from '../../components/PropositionCards/SearchCard';
import SearchCardsWrapper from '../../components/PropositionCards/SearchCardsWrapper';
import SearchCardLoader from '../../components/PropositionCards/Loaders/SearchCard';

export default {
  name: 'MyFavourites',

  components: {
    SearchCard,
    SearchCardsWrapper,
    SearchCardLoader
  },

  head: {
    title() {
      return {
        inner: this.$t('my_favouries_title')
      };
    }
  },

  computed: {
    ...mapGetters({
      user: 'app/user',
      favourites: 'favourites/getFavourites',
      favouritesLoading: 'favourites/isLoading',
      isMobile: 'screenSize/isMobile'
    }),

    filteredFavourites() {
      const active = this.favourites.filter(f => f.active);

      return active;
    }
  },

  mounted() {
    this.fetchFavourites();
    window.scrollTo(0, 0);
  },

  methods: {
    ...mapActions({
      fetchFavourites: 'favourites/fetchFavourites'
    })
  }
};
</script>

<style lang="scss" scoped>
.favourites {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.favourites-title {
  margin-left: 1.5%;
  margin-top: 0;

  @media ($mobile) {
    font-size: 24px;
    margin-top: 32px;
    margin-left: 0;
  }
}

.info-text {
  margin-left: 1.5%;
}

.loader-wrapper {
  width: 100%;
  margin: 30px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media ($desktop) {
    flex-wrap: wrap;
  }

  @media ($mobile) {
    flex-direction: column;
  }

  @media ($ipad) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
</style>
