function trialBannerOpen(state) {
  return state.trialBanner && !state.temporaryHideTrialBanner;
}

function conversionBannerOpen(state) {
  return !state.temporaryHideTrialBanner;
}

function trialEndDate(state) {
  return state.trialEndDate;
}

function trialDaysLeft(state) {
  const now = new Date();
  const trialEnd = state.trialEndDate ? state.trialEndDate.getTime() : 0;

  const timeDiff = trialEnd - now.getTime();
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return diffDays;
}

function displayedOutdatedTrial(state) {
  return state.displayedOutdatedTrial;
}

export default {
  trialBannerOpen,
  conversionBannerOpen,
  trialEndDate,
  trialDaysLeft,
  displayedOutdatedTrial
};
