<template>
  <div class="freemium-banner">
    <div class="icon-and-header">
      <BaseIcon
        icon-file="hands-clapping-light"
        :width="51"
        :height="51"
        class="icon"
      />
      <p>{{ $t('freemium_banner_header') }}</p>
    </div>
    <BaseButton
      class="secondary smaller banner-button"
      @click="onPaymentClick"
      >{{ $t('freemium_banner_cta') }}</BaseButton
    >
  </div>
</template>

<script>
import events from '@/utils/helpers/events';
import { mapGetters } from 'vuex';

export default {
  name: 'FreemiumBanner',

  computed: {
    ...mapGetters({
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    })
  },
  methods: {
    onPaymentClick() {
      const currentPath = this.$router.currentRoute.path;

      events.emitEvent(events.eventTypes.CLICK, 'Payment', {
        id: `freemium-banner`,
        src: currentPath,
        propositionId: this.primaryPropositionId
      });

      this.$router.push(
        `${this.$routes.PAYMENT_PROPOSITION}/${this.primaryPropositionId}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.freemium-banner {
  background: #003384;
  display: flex;
  flex-direction: column;
  padding: 5%;
  font-family: $font-headings;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.5;
  color: #ffffff;
  z-index: 1;

  @media ($desktop) {
    position: relative;
    top: 80px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    font-size: 20px;
  }
}

.icon-and-header {
  display: flex;
  align-items: center;
}

.icon {
  @media ($mobile) {
    flex-shrink: 0;
    margin-right: 16px;
    margin-bottom: 18px;
  }
}

p {
  @media ($mobile) {
    margin-top: 0;
  }

  @media ($desktop) {
    margin-right: 30px;
    margin-left: 24px;
  }
}

.banner-button {
  @media ($mobile) {
    width: 100%;
  }
}
</style>
