import { render, staticRenderFns } from "./BaseCarouselDots.vue?vue&type=template&id=23d8a6ca&scoped=true"
import script from "./BaseCarouselDots.vue?vue&type=script&lang=js"
export * from "./BaseCarouselDots.vue?vue&type=script&lang=js"
import style0 from "./BaseCarouselDots.vue?vue&type=style&index=0&id=23d8a6ca&prod&lans=scss&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d8a6ca",
  null
  
)

export default component.exports