var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{staticClass:"swaps-container",attrs:{"name":"swap-list","tag":"div"}},_vm._l((_vm.swapList),function(swap,index){return _c('div',{key:swap.propositionId + swap.details.type,ref:swap.propositionId + swap.details.type,refInFor:true,staticClass:"swap-items-wrapper"},[(index === 0)?_c('div',{class:_vm.shouldRevealProgressBar && _vm.showContextualTutorial
          ? 'reveal-progress'
          : '',attrs:{"hidden":_vm.tutActiveStep === null ||
        _vm.tutStatus === _vm.TUTORIAL_STATUS_TYPES.COMPLETED}},[_c('div',{directives:[{name:"outside-click",rawName:"v-outside-click",value:(_vm.handleClickOutsideProgress),expression:"handleClickOutsideProgress"}],staticClass:"progress-bar-wrapper",style:({
          visibility: !_vm.isShowingSwapModal ? 'visible' : 'hidden',
          opacity: !_vm.isShowingSwapModal ? 1 : 0
        })},[_c('ProgressBar',{ref:"progressBar",refInFor:true,attrs:{"percentage":_vm.progressBar}}),(_vm.shouldRevealProgressBar && _vm.showContextualTutorial)?_c('transition',{attrs:{"name":"tooltip-slide-up","appear":""}},[_c('div',{staticClass:"tooltip-container"},[_c('BaseTooltip',{on:{"callback":_vm.handleTooltipCallback}},[_vm._v(_vm._s(_vm.$t('tutorial_contextual_continue_interest')))]),(_vm.showTooltipButton)?_c('BaseButton',{staticClass:"smaller next-button",on:{"click":_vm.handleClickOutsideProgress}},[_vm._t("button",function(){return [_vm._v(" "+_vm._s(_vm.$t('tutorial_contextual_button_next'))+" ")]})],2):_vm._e()],1)]):_vm._e()],1)]):_vm._e(),(_vm.isMobile && swap.type !== 'sponsoredPropositions')?_c('BasePropositionCardNew',{key:swap.propositionId + swap.details.type,class:_vm.shouldRevealSwap && index === 0 ? 'reveal' : '',attrs:{"show-additional-images":!_vm.tutorialStarted,"show-tutorial-pointer":_vm.tutorialStarted && index === 0,"swap":swap,"disable-link":_vm.shouldDisableLink,"index":index,"is-blurred":(_vm.primaryProposition.isFreemium || _vm.primaryProposition.isTrial) &&
        _vm.swapFilterType === 'LAST_PART',"show-interest-buttons-block":!(
          (_vm.primaryProposition.isFreemium || _vm.primaryProposition.isTrial) &&
          _vm.swapFilterType === 'LAST_PART'
        )}}):_vm._e(),(!_vm.isMobile && swap.type !== 'sponsoredPropositions')?_c(_vm.currentSwapItemComponent,{key:swap.propositionId + swap.details.type,tag:"component",class:_vm.shouldRevealSwap && index === 0 ? 'reveal' : '',attrs:{"show-tutorial-pointer":_vm.tutorialStarted && index === 0,"show-swap-details":_vm.handleShowSwapDetails,"swap":swap,"disable-link":_vm.shouldDisableLink,"toned":index % 2 !== 0,"blur-content":(_vm.primaryProposition.isFreemium || _vm.primaryProposition.isTrial) &&
        _vm.swapFilterType == 'LAST_PART'}}):_vm._e(),(
        _vm.$country.isFeatureEnabled('EXTRA_VISIBILITY') &&
        _vm.sponsoredPropositions.length &&
        swap.type === 'sponsoredPropositions'
      )?_c('SponsoredPropositions',{staticClass:"sponsored-propositions",attrs:{"propositions":_vm.sponsoredPropositions,"title":_vm.$t('proposition_extra_visibility_title')}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }