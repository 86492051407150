import { isObjectSame } from './object';

export function toggleArrayItem(array, match) {
  if (!array) array = [];
  else array = [...array];

  const index = array.findIndex(x => {
    if (typeof x == 'object' && typeof match == 'object')
      return isObjectSame(x, match);
    else return x === match;
  });

  if (index !== -1) array.splice(index, 1);
  else array.push(match);

  return array;
}

export function arrayMoveMutable(array, fromIndex, toIndex) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;
  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;
    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}
export function arrayMoveImmutable(array, fromIndex, toIndex) {
  array = [...array];
  arrayMoveMutable(array, fromIndex, toIndex);
  return array;
}

export default { toggleArrayItem };
