<template>
  <div class="swap-menu-wrapper">
    <button
      :class="[
        'swap-menu-option',
        { selected: swapFilterType === SWAP_FILTER_TYPES.ONLY_FULL_INTEREST }
      ]"
      @click="
        () =>
          setSwapType({
            swapFilterType: SWAP_FILTER_TYPES.ONLY_FULL_INTEREST,
            update: true
          })
      "
    >
      <BaseIcon
        :icon-file="
          swapFilterType === SWAP_FILTER_TYPES.ONLY_FULL_INTEREST
            ? 'menu-stars-active'
            : 'menu-stars'
        "
        class="swap-menu-icon"
      />
      <p class="swap-menu-option-text">
        {{ $t('swaps_list_toggle_full') }}
        <span v-if="swapInterestCounts" class="swap-menu-option-count"
          >({{ swapInterestCounts.full.total }})</span
        >
      </p>
      <div
        v-if="swapInterestCounts && swapInterestCounts.full.unseen > 0"
        class="unseen-badge"
      >
        {{ swapInterestCounts.full.unseen }}
      </div>
    </button>

    <button
      :class="[
        'swap-menu-option',
        { selected: swapFilterType === SWAP_FILTER_TYPES.LAST_PART }
      ]"
      @click="
        () =>
          setSwapType({
            swapFilterType: SWAP_FILTER_TYPES.LAST_PART,
            update: true
          })
      "
    >
      <BaseIcon
        :icon-file="
          swapFilterType === SWAP_FILTER_TYPES.LAST_PART
            ? 'menu-key-active'
            : 'menu-key'
        "
        class="swap-menu-icon"
      />
      <p class="swap-menu-option-text">
        {{ $t('swaps_list_toggle_me') }}
        <span v-if="swapInterestCounts" class="swap-menu-option-count"
          >({{ swapInterestCounts.interestedInMine.total }})</span
        >
      </p>
      <div
        v-if="
          swapInterestCounts && swapInterestCounts.interestedInMine.unseen > 0
        "
        class="unseen-badge"
      >
        {{ swapInterestCounts.interestedInMine.unseen }}
      </div>
    </button>

    <button
      :class="[
        'swap-menu-option smaller',
        { selected: swapFilterType === SWAP_FILTER_TYPES.INTEREST_MARKED }
      ]"
      @click="
        () =>
          setSwapType({
            swapFilterType: SWAP_FILTER_TYPES.INTEREST_MARKED,
            update: true
          })
      "
    >
      <BaseIcon
        :icon-file="
          swapFilterType === SWAP_FILTER_TYPES.INTEREST_MARKED
            ? 'menu-thumb-up-active'
            : 'menu-thumb-up'
        "
        :width="isMobile ? 18 : 24"
        :height="isMobile ? 18 : 24"
        class="swap-menu-icon"
      />
      <p class="swap-menu-option-text">
        {{ $t('swaps_list_toggle_my_interests') }}
        <span v-if="swapInterestCounts" class="swap-menu-option-count"
          >({{ swapInterestCounts.ownerInterestMarks.total }})</span
        >
      </p>
    </button>

    <button
      :class="[
        'swap-menu-option smaller',
        { selected: swapFilterType === SWAP_FILTER_TYPES.FAVOURITES }
      ]"
      @click="
        () =>
          setSwapType({
            swapFilterType: SWAP_FILTER_TYPES.FAVOURITES,
            update: true,
            isFavourites: true
          })
      "
    >
      <BaseIcon
        :icon-file="
          swapFilterType === SWAP_FILTER_TYPES.FAVOURITES
            ? 'menu-heart-active'
            : 'menu-heart'
        "
        :width="isMobile ? 18 : 24"
        :height="isMobile ? 18 : 24"
        class="swap-menu-icon"
      />
      <p class="swap-menu-option-text">
        {{ $t('swaps_list_toggle_my_favourites') }}
        <span v-if="swapInterestCounts" class="swap-menu-option-count"
          >({{ swapInterestCounts.favourites.total }})</span
        >
      </p>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SWAP_FILTER_TYPES } from '../../store/modules/swapList';

export default {
  data() {
    return {
      SWAP_FILTER_TYPES: SWAP_FILTER_TYPES
    };
  },

  computed: {
    ...mapGetters({
      swapFilterType: 'swapList/swapFilterType',
      isMobile: 'screenSize/isMobile',
      swapInterestCounts: 'swapList/swapInterestCounts'
    })
  },

  methods: {
    ...mapActions({
      setSwapType: 'swapList/setSwapType'
    })
  }
};
</script>

<style lang="scss" scoped>
.swap-menu-wrapper {
  width: 100%;

  @media ($mobile) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }

  @media ($desktop) {
    padding-right: 15px;
    padding-left: 10px;
  }
}

.swap-menu-option {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  background-color: #fff;
  color: #2c3e50;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 2px;
  cursor: pointer;

  @media ($mobile) {
    // flex: 0 1 50%;
  }

  @media ($desktop) {
    flex-direction: row;
    justify-content: flex-start;
    border: 1px solid #f1f2f6;
    border-radius: 10px;
    padding: 15px 12px;
    width: 100%;
    margin-bottom: 15px;
  }
}

.swap-menu-option.smaller {
  @media ($mobile) {
    flex-direction: row;
    justify-content: center;
    padding: 8px 0;
  }
}

.swap-menu-icon {
  @media ($mobile) {
    margin-bottom: 3px;

    .smaller & {
      margin-bottom: 0;
      margin-top: -1px;
      margin-right: 3px;
    }
  }

  @media ($desktop) {
    margin-right: 10px;
  }
}

.swap-menu-option.selected {
  background: #e9efff;
  border: 1px solid #409fff;
}

.swap-menu-option-text {
  font-size: 12px;
  font-weight: 600;
  margin: 0;

  @media ($desktop) {
    font-size: 14px;
  }
}

.swap-menu-option-count {
  color: #878b9d;
  font-weight: 400;
  display: inline-block;
  margin-left: 1px;
}

.unseen-badge {
  display: grid;
  place-items: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border: 1px solid #f1f2f6;
  background-color: #409fff;
  font-size: 10px;
  color: #fff;
  font-weight: 600;
  letter-spacing: -0.02em;

  @media ($mobile) {
    position: absolute;
    top: -12px;
    right: 6px;
  }

  @media ($desktop) {
    border: none;
    margin-left: auto;
  }
}
</style>
