const getTokenExists = state => state.tokenExists;
const lastTokenCheck = state => state.lastTokenCheck;
const getButtonsEnabled = state => state.buttonsEnabled;
const getAgreementChecked = state => state.agreementChecked;
const getProducts = state => state.all;
const getSelectedProduct = state => state.selectedProduct;
const getSelectedAdditionalProducts = state => state.selectedAdditionalProducts;
const getProposition = state => state.current;
const isCreatingPayment = state => state.isCreatingPayment;
const sessionExists = state => Boolean(state.session.client_token);
const hasCheckedForToken = state => state.hasCheckedForToken;
const errorMessage = state => state.errorMessage;

export default {
  getTokenExists,
  lastTokenCheck,
  getButtonsEnabled,
  getAgreementChecked,
  getProducts,
  getSelectedProduct,
  getSelectedAdditionalProducts,
  getProposition,
  isCreatingPayment,
  sessionExists,
  hasCheckedForToken,
  errorMessage
};
