function show(state) {
  state.show = true;
}

function hide(state) {
  state.show = false;
}

export default {
  hide,
  show
};
