<template>
  <div class="report-proposition-link-wrapper">
    <div class="report-proposition-link" @click="handleReportPropositionClick">
      <BaseIcon icon-file="flag-black" :width="20" :height="20" class="icon" />
      {{ $t('report_proposition_label') }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'ReportProposition',

  methods: {
    ...mapActions({
      setGlobalModal: 'ui/setGlobalModal'
    }),
    handleReportPropositionClick() {
      this.setGlobalModal('ReportModal');
    }
  }
};
</script>

<style lang="scss" scoped>
$base-color: #2c3e50;

.report-proposition-link-wrapper {
  display: flex;
  margin-bottom: 2rem;
}

.report-proposition-link {
  display: flex;
  flex-shrink: 0;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(#2c3e50);
  text-decoration-line: underline;
  cursor: pointer;
}
</style>
