var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ReportScreenLayout',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.formHeadingLabel))}})]},proxy:true},{key:"body",fn:function(){return [(_vm.radioOptions.length > 0 && _vm.type === _vm.REPORT_SCREEN_TYPES.RADIO)?_c('div',{staticClass:"options-container"},_vm._l((_vm.radioOptions),function(option,index){return _c('div',{key:index,staticClass:"option"},[_c('ReportOption',{attrs:{"value":option.id,"selected":_vm.selectedRadioOption},on:{"change":_vm.handleChange}},[_c('div',{staticClass:"option-label",domProps:{"innerHTML":_vm._s(_vm.$t(option.label))}}),(option.description)?_c('div',{staticClass:"option-description",domProps:{"innerHTML":_vm._s(_vm.$t(option.description))}}):_vm._e()])],1)}),0):_vm._e(),_c('transition',{attrs:{"name":_vm.transitionName,"mode":"out-in"}},[(_vm.type === _vm.REPORT_SCREEN_TYPES.FORM)?_c('div',{staticClass:"report-describe-wrapper"},[_c('ReportDescribeScreen')],1):_vm._e()]),_c('transition',{attrs:{"name":"tutorialBounceIn","mode":"out-in","appear":""}},[(_vm.type === _vm.REPORT_SCREEN_TYPES.SUCCESS)?_c('div',[_c('Success')],1):_vm._e()])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"cta-buttons-wrapper"},[(_vm.showBackButton)?_c('BaseButton',{staticClass:"back-btn primary grey outline",staticStyle:{"min-width":"8rem"},attrs:{"type":"button"},on:{"click":function($event){return _vm.handleClick($event, 'back', _vm.previousScreen)}}},[_vm._v(_vm._s(_vm.$t('report_label_back'))+" ")]):_vm._e(),(_vm.primaryButtonType === _vm.REPORT_BUTTON_TYPES.NEXT)?_c('BaseButton',{class:_vm.primaryButtonClasses,attrs:{"type":"button","disabled":!_vm.selectedRadioOption},on:{"click":function($event){return _vm.handleClick(
            $event,
            _vm.currentScreen === '1.4' &&
              _vm.selectedRadioOption < _vm.radioOptions.length
              ? 'send'
              : 'next',
            _vm.selectedRadioOption
          )}}},[_vm._v(" "+_vm._s(_vm.$t('report_label_next'))+" ")]):_vm._e(),(_vm.primaryButtonType === _vm.REPORT_BUTTON_TYPES.SEND)?_c('BaseButton',{class:_vm.primaryButtonClasses,staticStyle:{"width":"100%"},attrs:{"disabled":!_vm.formFilled,"type":"button"},on:{"click":function($event){return _vm.handleClick($event, 'send')}}},[_vm._v(" "+_vm._s(_vm.$t('report_label_send'))+" ")]):_vm._e(),(_vm.primaryButtonType === _vm.REPORT_BUTTON_TYPES.CLOSE)?_c('BaseButton',{staticClass:"rounded grey",staticStyle:{"width":"100%"},attrs:{"type":"button","icon-file":_vm.showCloseButtonIcon ? 'search-white' : ''},on:{"click":function($event){return _vm.handleClick($event, 'done')}}},[_vm._v(" "+_vm._s(_vm.$t('report_label_done'))+" ")]):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }