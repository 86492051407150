<template>
  <div v-if="showCookiesModal && !isAppRequest" class="cookie-modal-wrapper">
    <div class="overlay"></div>
    <div class="cookie-modal-content">
      <template v-if="showSettings == false">
        <div class="cookie-modal-text">
          <strong class="cookie-modal-heading">{{
            $t('cookie_modal_title')
          }}</strong>
          <p>
            {{ $t('cookie_modal_text') }}
            <br />
            <BaseLink
              class="cookie-modal-link"
              link="/cookiepolicy"
              :is-external="true"
              >{{ $t('cookie_modal_link') }}</BaseLink
            >
            <br />
          </p>
        </div>
        <BaseButton
          key="accept-all"
          class="full-width"
          @click="handleAcceptAll"
          >{{ $t('cookie_modal_button_all') }}</BaseButton
        >
        <BaseButton
          key="accept-necessary"
          class="outline full-width button-only-necessary"
          @click="handleAcceptNecessary"
          >{{ $t('cookie_modal_button_only_necessary') }}</BaseButton
        >
        <BaseButton
          key="change-settings"
          class="outline full-width button-only-necessary"
          @click="handleChangeSettingsClick"
          >{{ $t('cookie_modal_button_change_settings') }}</BaseButton
        >
      </template>
      <template v-if="showSettings == true">
        <div class="cookie-modal-text">
          <strong class="cookie-modal-heading">{{
            $t('cookie_modal_settings_title')
          }}</strong>
          <p>
            {{ $t('cookie_modal_settings_text') }}
            <br />
            <BaseLink
              class="cookie-modal-link"
              link="/cookiepolicy"
              :is-external="true"
              target="_blank"
              >{{ $t('cookie_modal_link') }}</BaseLink
            >
            <br />
          </p>
        </div>
        <BaseCheckbox
          :selected="true"
          :disabled="true"
          :label="$t('cookie_modal_settings_necessary')"
        />
        <br />
        <BaseCheckbox
          :selected="allowAllCookies"
          :label="$t('cookie_modal_settings_all_checkbox')"
          @change="handleAllowAllCookiesChange"
        />
        <br /><br />
        <BaseButton
          key="save-settings"
          class="full-width"
          @click="handleSaveSettingsClick"
          >{{ $t('cookie_modal_button_save_settings') }}</BaseButton
        >
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { getCookie, setCookie } from '@/utils/cookies';
import { isDebug } from '../utils/debug';
import { getValue } from '../plugins/country/module';
import VueGtm from '@gtm-support/vue2-gtm';
import { router } from '../main';
import { log } from '../utils/logger';

export default {
  name: 'CookieModal',

  data() {
    return {
      showSettings: false,
      allowAllCookies: false
    };
  },

  computed: {
    ...mapGetters({
      isAppRequest: 'app/isAppRequest',
      showCookiesModal: 'ui/showCookiesModal'
    })
  },

  mounted() {
    const consent = getCookie('lb-cc');
    if (!consent) {
      this.setCookiesModal(true);
    } else if (consent !== 'false') {
      this.onConsent();
      this.allowAllCookies = true;
    }
  },

  methods: {
    ...mapMutations({
      setCookiesModal: 'ui/setCookiesModal'
    }),
    onConsent() {
      this.$emit('accepted-all-cookies');

      const gtmId = getValue('GOOGLE_TAG_MANAGER');

      if (gtmId && !isDebug()) {
        Vue.use(VueGtm, {
          id: gtmId,
          defer: false,
          enabled: !isDebug(),
          loadScript: true,
          vueRouter: router,
          ignoredViews: [],
          trackOnNextTick: false
        });

        const gtag = function () {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(arguments);
        };

        gtag('consent', 'update', {
          ad_user_data: 'granted',
          ad_personalization: 'granted',
          ad_storage: 'granted',
          analytics_storage: 'granted'
        });
      } else {
        if (!isDebug()) log(`GTM is not loaded: gtmId is empty`, true);
      }
    },
    handleAcceptAll() {
      setCookie('lb-cc', new Date().toLocaleString(), 180);
      this.onConsent();
      this.setCookiesModal(false);
    },
    handleAcceptNecessary() {
      setCookie('lb-cc', 'false', 180);
      this.setCookiesModal(false);
    },
    handleChangeSettingsClick() {
      this.showSettings = true;
    },
    handleAllowAllCookiesChange() {
      this.allowAllCookies = !this.allowAllCookies;
    },
    handleSaveSettingsClick() {
      if (this.allowAllCookies) {
        this.handleAcceptAll();
      } else {
        this.handleAcceptNecessary();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cookie-modal-content {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 40px 30px;
  border-radius: 8px;
  background-color: #fff;
  z-index: 2001;

  @media ($desktop) {
    left: 50%;
    top: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
    width: 92%;
    max-width: 400px;
    padding: 30px;
  }
}

.cookie-modal-text {
  margin-bottom: 30px;
  font-size: 15px;
  line-height: 1.3;

  p {
    margin: 0;
  }
}

.cookie-modal-heading {
  display: block;
  margin-bottom: 4px;
  font-size: 20px;
}

.cookie-modal-link {
  margin-top: 6px;
  line-height: 1.1;
}

.google-policy-link {
  margin-top: 8px;
  line-height: 1.1;
}

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}

.button-only-necessary {
  margin-top: 10px;
}
</style>
