import { render, staticRenderFns } from "./OneImage.vue?vue&type=template&id=3285a250&scoped=true"
import script from "./OneImage.vue?vue&type=script&lang=js"
export * from "./OneImage.vue?vue&type=script&lang=js"
import style0 from "./OneImage.vue?vue&type=style&index=0&id=3285a250&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3285a250",
  null
  
)

export default component.exports