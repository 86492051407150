import * as cloneDeep from 'lodash/cloneDeep';

function setSwapDetails(state, { swapDetails }) {
  state.swapDetails = swapDetails;
}

function setActiveSwap(state, { activeSwap, position }) {
  state.activeSwap = activeSwap;
  state.position = position;
}

function setSwapInterest(state, { interested, targetPropositionId }) {
  const swapsCopy = cloneDeep(state.swapDetails);
  const targets = swapsCopy.filter(
    swap => swap.propositionId.toString() === targetPropositionId.toString()
  );

  targets.forEach(target => (target.details.interests[0] = interested));

  state.swapDetails = swapsCopy;

  if (
    state.activeSwap &&
    state.activeSwap.propositionId.toString() === targetPropositionId.toString()
  ) {
    const activeSwapClone = cloneDeep(state.activeSwap);

    activeSwapClone.details.interests[0] = interested;

    state.activeSwap = activeSwapClone;
  }
}

export default { setSwapDetails, setActiveSwap, setSwapInterest };
