import Vue from 'vue';

function setPropositions(state, { propositions }) {
  state.propositions = propositions || [];
}

function setPrimaryProposition(state, { primaryProposition }) {
  if (!primaryProposition) return;

  state.primaryProposition = { ...primaryProposition };
}

function setLoading(state, { loading }) {
  state.loading = loading;
}

function deleteProposition(state, { propositionId }) {
  state.propositions = state.propositions.filter(
    proposition => proposition.propositionId !== propositionId
  );
}

function setPropositionLoading(state, { propositionId, loading }) {
  const index = state.propositions.findIndex(
    x => x.propositionId === propositionId
  );

  if (index === -1) return;

  const proposition = state.propositions[index];

  Vue.set(state.propositions, index, { ...proposition, loading: loading });
}

function setFetchingPropositions(state, value) {
  state.fetchingPropositions = value;
}

export default {
  setPropositions,
  setLoading,
  deleteProposition,
  setPropositionLoading,
  setPrimaryProposition,
  setFetchingPropositions
};
