<template>
  <div class="statistics-block-container">
    <div class="statistics-block">
      <div class="uppercase-heading">{{ content.Label }}</div>
      <h1>{{ content.Heading }}</h1>
      <p v-html="content.Bodytext"></p>
      <div class="statistics-items">
        <div class="statistics-item">
          <div class="value">{{ content.Box1Value }}</div>
          <div class="label">{{ content.Box1Label }}</div>
        </div>
        <div class="statistics-item">
          <div class="value">{{ content.Box2Value }}</div>
          <div class="label">{{ content.Box2Label }}</div>
        </div>
        <div class="statistics-item">
          <div class="value">{{ content.Box3Value }}</div>
          <div class="label">{{ content.Box3Label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatisticsBlock',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.statistics-block-container {
  background-color: #fde2cd;
  padding: 50px 0;
  margin-bottom: 32px;
  margin-top: 32px;

  @media ($large-desktop) {
    padding: 100px 0;
    margin-bottom: 60px;
    margin-top: 60px;
  }
}

.statistics-block {
  margin-bottom: 16px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: -0.01em;
  color: #213975;

  @media ($large-desktop) {
    font-size: 24px;
  }
}

.uppercase-heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 10px;
}

h1 {
  font-family: $font-headings;
  font-weight: 900;
  font-size: 32px;
  line-height: 1.1;
  color: #f7b180;
  margin-top: 0;

  @media ($desktop) {
    font-size: 64px;
  }
}

.statistics-items {
  margin-top: 60px;

  @media ($large-desktop) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 53px;
    margin-top: 80px;
  }
}

.statistics-item {
  background-color: #feeee1;
  border-radius: 20px;
  padding: 20px;
  text-align: center;

  @media ($small-desktop-max) {
    margin-bottom: 20px;
  }
}

.value {
  font-family: $font-headings;
  font-weight: 900;
  font-size: 24px;

  @media ($large-desktop) {
    font-size: 40px;
  }
}

.label {
  font-weight: 700;
}
</style>
