<template>
  <div class="extra-visibility-wrapper">
    <div class="extra-visibility">
      <h2 class="title">
        {{ $t('extra_vis_modal_title1') }} <br />
        {{ $t('extra_vis_modal_title2_variant') }}
      </h2>

      <div class="product-wrapper">
        <p class="info-text">
          {{ $t('extra_vis_modal_text') }}
        </p>

        <div class="proposition-card-wrapper">
          <BasePropositionCardNew
            :swap="propositionWithPicture"
            :show-swap-chain-block="false"
            :show-favourite-button="false"
            :always-show-boost-icon="true"
            :disable-link="true"
            class="proposition-card"
          />
        </div>

        <BasePayment
          v-if="!isFetchingProducts"
          :product="selectedProduct"
          :proposition-id="proposition.propositionId"
          :pay-text="$t('general_pay')"
          @loading="isLoading = $event"
          @success="handleSuccessfulPayment($event)"
          @pending="handlePendingPayment()"
        />
      </div>
    </div>

    <div class="extra-visibility-info-wrapper">
      <div class="extra-visibility-info">
        <h2>{{ $t('extra_vis_modal_extra_title') }}</h2>

        <ul>
          <li>{{ $t('extra_vis_modal_usp1') }}</li>
          <li>
            {{ $t('extra_vis_modal_usp2') }}
          </li>
          <li>
            {{ $t('extra_vis_modal_usp3') }}
          </li>
        </ul>
      </div>
    </div>

    <Spinner v-if="isLoading || isFetchingProducts" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import BasePayment from '@/components/Payments/BasePayment';

import Spinner from '../Loading/Spinner';

export default {
  name: 'ExtraVisibility',

  components: {
    Spinner,
    BasePayment
  },

  props: {
    proposition: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      isFetchingProducts: true
    };
  },

  computed: {
    ...mapGetters({
      isAppRequest: 'app/isAppRequest',
      isMobile: 'screenSize/isMobile',
      products: 'klarnaPayments/getProducts',
      selectedProduct: 'klarnaPayments/getSelectedProduct'
    }),

    propositionWithPicture() {
      const image = this.proposition.residences[0].images[0];
      return {
        ...this.proposition,
        residences: [
          {
            ...this.proposition.residences[0],
            ...(image && {
              pictures: [this.proposition.residences[0].images[0].fileName]
            })
          }
        ]
      };
    }
  },

  async mounted() {
    this.isLoading = true;

    this.isFetchingProducts = true;
    await this.fetchProducts({ productType: 'extraVisibility' });
    this.isFetchingProducts = false;

    // Keep loading if payment result is working
    if (!this.$route.query.q) {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      setSelectedProductId: 'klarnaPayments/selectProductId',
      fetchProducts: 'klarnaPayments/fetchProducts',
      paymentSuccess: 'payment/success'
    }),

    ...mapMutations({
      setExtraVisibilityPaymentVisible: 'extraVisibility/setVisible'
    }),

    handlePendingPayment() {
      this.setExtraVisibilityPaymentVisible(false);
    },

    handleSuccessfulPayment({ product, propositionId }) {
      this.setExtraVisibilityPaymentVisible(false);

      this.paymentSuccess({
        propositionId,
        product,
        notify: false,
        redirect: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.extra-visibility {
  width: 100%;
  padding: 0 16px;
  color: $text-primary;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.title {
  font-family: 'OktaNeue';
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #2c3e50;
  margin-top: 20px;
  margin-bottom: 25px;
}

.product-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  @media ($desktop) {
    min-width: 480px;
  }
}

.info-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #2c3e50;
  margin-top: 0;
  margin-bottom: 10px;
  max-width: 350px;
}

.proposition-card-wrapper {
  width: 100%;
  margin: 0;
  background-image: url('../../assets/img/boost-bg.png');
  background-size: 100%;
  background-position: center;

  @media ($mobile) {
    :deep(.proposition-card) {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      .primary-image {
        aspect-ratio: 2 / 1.2;
      }
    }
  }

  @media ($desktop) {
    margin-bottom: 20px;
  }
}

.proposition-card {
  pointer-events: none;

  @media ($desktop) {
    :deep(.proposition-card) {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
  }

  @media ($mobile) {
    transform: scale(0.8);
  }
}

.extra-visibility-info-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 110px 0 50px;
  width: 100%;
  background-color: #f5e6dc;

  @media ($desktop) {
    padding: 130px 40px 80px;
  }

  @media ($mobile) {
    &::after {
      content: '';
      position: absolute;
      top: 30px;
      left: 0;
      width: 216px;
      height: 120px;
      background-image: url('../../assets/svg/rocket-with-line.svg');
      background-size: contain;
    }
  }
}

.extra-visibility-info {
  position: relative;
  color: #2c3e50;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;

  @media ($desktop) {
    &::after {
      content: '';
      position: absolute;
      top: -80px;
      left: 6px;
      width: 216px;
      height: 131px;
      background-image: url('../../assets/svg/rocket-with-line.svg');
      background-size: contain;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 30px;
  }

  ul {
    margin: 0;
    margin-bottom: 12px;
    padding: 0 30px;

    @media ($desktop) {
      padding-right: 0;
    }
  }

  li {
    list-style-type: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #2c3e50;
    margin-bottom: 20px;

    &::before {
      flex-shrink: 0;
      margin-right: 16px;
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background-size: contain;
      background-image: url('../../assets/svg/icon-check-purple.svg');
    }
  }
}
</style>
