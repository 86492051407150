import services from './services';
import checkAppReady from '../../checkAppReady';
import { isDebug } from '../../../utils/debug';

function initTracking(context) {
  // Init empty tracking to prevent crashing on early tracking attempts
  window.trackingCommands = {
    trackPageVisit: function () {
      return {};
    }
  };

  const { isMobile } = context.rootState.screenSize;

  const script = document.createElement('script');

  if (!isDebug()) {
    script.src =
      'https://frontend-scripts-prod.s3.amazonaws.com/1.0.0/index.js';
  } else {
    script.src = 'https://frontend-scripts-dev.s3.amazonaws.com/1.0.0/index.js';
  }

  script.onload = () => {
    window.trackingCommands.init({
      device: isMobile ? 'mobile' : 'desktop'
    });
  };

  document.head.appendChild(script);
}

// ***************** PAYMENTS **************** //

function trackPaymentPageVisited(context, { propositionId }) {
  if (isDebug()) {
    return;
  }

  window.trackingCommands.trackPageVisit('payment-klarna', { propositionId });
}

// ************ PAYMENTS END  *************** //

// ***************** SUBSCRIPTIONS **************** //

function trackChangedSubscription(
  context,
  { propositionId, currentProductId, productId, changeContext }
) {
  if (isDebug()) {
    return;
  }

  services.changedSubscription({
    propositionId,
    productId,
    currentProductId,
    changeContext
  });
}

// ************ SUBSCRIPTIONS END  *************** //

async function trackPropositionVisited(context, { propositionId }) {
  if (isDebug()) {
    return;
  }

  await checkAppReady();
  const { isMobile } = context.rootState.screenSize;

  window.trackingCommands.trackPageVisit('proposition', {
    visitedPropositionId: propositionId
  });

  const userPropositionId =
    context.rootState.myPropositions.primaryProposition.propositionId;

  services.propositionVisited({
    userPropositionId,
    visitedPropositionId: propositionId,
    device: isMobile ? 'mobile' : 'desktop'
  });
}

function clickedPropositionPageTrialCTA(context, { variant }) {
  if (isDebug()) {
    return;
  }

  const { isMobile } = context.rootState.screenSize;
  const propositionId =
    context.rootState.myPropositions.primaryProposition.propositionId;

  services.clickedPropositionPageTrialCTA({
    propositionId,
    variant,
    device: isMobile ? 'mobile' : 'desktop'
  });
}

export default {
  initTracking,
  trackPaymentPageVisited,
  trackChangedSubscription,
  trackPropositionVisited,
  clickedPropositionPageTrialCTA
};
