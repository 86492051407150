<template>
  <div>
    <!-- UNLOCK CHATROOM MODAL -->
    <BaseInfoModalNew
      v-if="showGlobalModal === 'ChatLocked'"
      @close="setGlobalModal(false)"
    >
      <FancyModalContentNew>
        <template #icon>
          <BaseIcon
            icon-file="illustration-lock"
            :width="91"
            :height="95"
            class="icon"
          />
        </template>
        <template #title>{{ $t('modal_chat_locked_title') }}</template>
        <template #body>
          <ul>
            <li>{{ $t('modal_chat_locked_usp1') }}</li>
            <li>{{ $t('modal_chat_locked_usp2') }}</li>
            <li>{{ $t('modal_chat_locked_usp3') }}</li>
            <li v-if="$country.isFeatureEnabled('SWAP_APPLICATION')">
              {{ $t('modal_chat_locked_usp4') }}
            </li>
          </ul>
          <TrustBox
            v-if="$country.isFeatureEnabled('TRUSTPILOT')"
            key="chatlocked"
            type="Micro Trustscore"
            style="margin-top: 25px"
          />
        </template>
      </FancyModalContentNew>
      <template #actions>
        <BaseButton
          :link="`${$t(
            'path_payment_proposition',
            $routeLocale
          )}/${primaryPropositionId}`"
          icon-file="stars-white-filled"
          class="primary-action full-width"
          >{{ $t('modal_chat_locked_button2') }}</BaseButton
        >
      </template>
    </BaseInfoModalNew>

    <!-- UNLOCK SWAP MODAL -->
    <BaseInfoModalNew
      v-if="showGlobalModal === 'SwapsLocked'"
      @close="setGlobalModal(false)"
    >
      <FancyModalContentNew>
        <template #icon>
          <BaseIcon
            icon-file="illustration-stars"
            :width="72"
            :height="98"
            class="icon"
          />
        </template>
        <template #title>{{ $t('modal_swaps_locked_title') }}</template>
        <template #body>
          <ul>
            <li>{{ $t('modal_swaps_locked_usp1') }}</li>
            <li>{{ $t('modal_swaps_locked_usp2') }}</li>
            <li>{{ $t('modal_swaps_locked_usp3') }}</li>
            <li v-if="$country.isFeatureEnabled('SWAP_APPLICATION')">
              {{ $t('modal_swaps_locked_usp4') }}
            </li>
          </ul>
          <TrustBox
            v-if="$country.isFeatureEnabled('TRUSTPILOT')"
            key="swapslocked"
            type="Micro Trustscore"
            style="margin-top: 25px"
          />
        </template>
      </FancyModalContentNew>
      <template #actions>
        <BaseButton
          :link="`${$t(
            'path_payment_proposition',
            $routeLocale
          )}/${primaryPropositionId}`"
          icon-file="stars-white-filled"
          class="primary-action full-width"
          >{{ $t('modal_swaps_locked_button') }}</BaseButton
        >
      </template>
    </BaseInfoModalNew>

    <!-- UNLOCK UNLIMITED LIKES MODAL -->
    <BaseInfoModalNew
      v-if="showGlobalModal === 'GetUnlimitedLikes'"
      @close="setGlobalModal(false)"
    >
      <FancyModalContentNew>
        <template #icon>
          <BaseIcon
            icon-file="illustration-stars"
            :width="72"
            :height="98"
            class="icon"
          />
        </template>
        <template #title>{{ $t('modal_unlimited_title') }}</template>
        <template #body>
          <ul>
            <li>{{ $t('modal_swaps_locked_usp1') }}</li>
            <li>{{ $t('modal_swaps_locked_usp2') }}</li>
            <li>{{ $t('modal_swaps_locked_usp3') }}</li>
            <li v-if="$country.isFeatureEnabled('SWAP_APPLICATION')">
              {{ $t('modal_swaps_locked_usp4') }}
            </li>
          </ul>
          <TrustBox
            v-if="$country.isFeatureEnabled('TRUSTPILOT')"
            key="unlimitedlikes"
            type="Micro Trustscore"
            style="margin-top: 25px"
          />
        </template>
      </FancyModalContentNew>
      <template #actions>
        <BaseButton
          :link="`${$t(
            'path_payment_proposition',
            $routeLocale
          )}/${primaryPropositionId}`"
          icon-file="stars-white-filled"
          class="primary-action full-width"
          >{{ $t('modal_swaps_locked_button') }}</BaseButton
        >
      </template>
    </BaseInfoModalNew>

    <!-- SEE ALL SWAP SUGGESTIONS MODAL -->
    <BaseInfoModalNew
      v-if="showGlobalModal === 'SeeAllSwapSuggestions'"
      @close="setGlobalModal(false)"
    >
      <FancyModalContentNew>
        <template #icon>
          <BaseIcon
            icon-file="illustration-stars"
            :width="72"
            :height="98"
            class="icon"
          />
        </template>
        <template #title>{{ $t('modal_see_all_title') }}</template>
        <template #body>
          <ul>
            <li>{{ $t('modal_swaps_locked_usp1') }}</li>
            <li>{{ $t('modal_swaps_locked_usp2') }}</li>
            <li>{{ $t('modal_swaps_locked_usp3') }}</li>
            <li v-if="$country.isFeatureEnabled('SWAP_APPLICATION')">
              {{ $t('modal_swaps_locked_usp4') }}
            </li>
          </ul>
          <TrustBox
            v-if="$country.isFeatureEnabled('TRUSTPILOT')"
            key="seeallswapsuggestions"
            type="Micro Trustscore"
            style="margin-top: 25px"
          />
        </template>
      </FancyModalContentNew>
      <template #actions>
        <BaseButton
          :link="`${$t(
            'path_payment_proposition',
            $routeLocale
          )}/${primaryPropositionId}`"
          icon-file="stars-white-filled"
          class="primary-action full-width"
          >{{ $t('modal_swaps_locked_button') }}</BaseButton
        >
      </template>
    </BaseInfoModalNew>

    <!-- NO LIKES LEFT MODAL -->
    <BaseInfoModalNew
      v-if="showGlobalModal === 'NoLikesLeft'"
      @close="handleCloseNoLikesLeft"
    >
      <FancyModalContentNew>
        <template #icon>
          <BaseIcon
            icon-file="exclamation"
            :width="68"
            :height="75"
            class="icon"
          />
        </template>
        <template #title>{{ $t('modal_noleft_title') }}</template>
        <template #subtitle>{{ $t('modal_noleft_subtitle') }}</template>
        <template #body>
          <ul>
            <li>{{ $t('modal_swaps_locked_usp1') }}</li>
            <li>{{ $t('modal_swaps_locked_usp2') }}</li>
            <li>{{ $t('modal_swaps_locked_usp3') }}</li>
            <li v-if="$country.isFeatureEnabled('SWAP_APPLICATION')">
              {{ $t('modal_swaps_locked_usp4') }}
            </li>
          </ul>
          <TrustBox
            v-if="$country.isFeatureEnabled('TRUSTPILOT')"
            key="seeallswapsuggestions"
            type="Micro Trustscore"
            style="margin-top: 25px"
          />
        </template>
      </FancyModalContentNew>
      <template #actions>
        <BaseButton
          :link="`${$t(
            'path_payment_proposition',
            $routeLocale
          )}/${primaryPropositionId}`"
          icon-file="stars-white-filled"
          class="primary-action full-width"
          >{{ $t('modal_swaps_locked_button') }}</BaseButton
        >
      </template>
    </BaseInfoModalNew>

    <!-- UNLOCK OR ACTIVATE FREEMIUM -->
    <BaseInfoModal
      v-if="showGlobalModal === 'ActivateOrPay'"
      :is-loading="!selectedProduct || isLoading"
      :primary-action-text="$t('current_subscription_activate_freemium')"
      :secondary-action-text="
        $t('modal_action_become_member', {
          price: propositionProductPrice
        })
      "
      :secondary-action-link="`${$t(
        'path_payment_proposition',
        $routeLocale
      )}/${propositionIdQuery}`"
      @primaryActionClick="handleActivateFreemiumClick(propositionIdQuery)"
      @close="handleActivateOrPayClose()"
    >
      <FancyModalContent>
        <template #icon>
          <BaseIcon
            icon-file="icon-stars"
            :width="72"
            :height="72"
            class="icon"
          />
        </template>
        <template #title>{{ $t('freemium_banner_header') }}</template>
        <template #body>
          <p>{{ $t('freemium_banner_cta') }}</p>
        </template>
      </FancyModalContent>
    </BaseInfoModal>

    <!-- FIRST LIKE MODAL -->
    <BaseInfoModal
      v-if="showGlobalModal === 'FirstYesInterest'"
      :show-close-button="true"
      @close="onModalClosed"
    >
      <FancyModalContent>
        <template #icon>
          <BaseIcon
            icon-file="icon-stars"
            :width="72"
            :height="72"
            class="icon"
          />
        </template>
        <template #title>
          {{ $t('modal_first_like_title') }}
        </template>
        <template #body>
          <p>{{ $t('modal_first_like_body') }}</p>
        </template>
      </FancyModalContent>
    </BaseInfoModal>

    <!-- FIRST HIDE MODAL -->
    <BaseInfoModal
      v-if="showGlobalModal === 'FirstNoInterest'"
      :show-close-button="true"
      @close="onModalClosed"
    >
      <FancyModalContent>
        <template #icon>
          <BaseIcon
            icon-file="eye-closed"
            :width="72"
            :height="72"
            class="icon"
          />
        </template>
        <template #title>{{ $t('modal_first_dislike_title') }}</template>
        <template #body>
          <p>
            {{ $t('modal_first_dislike_body') }}
          </p>
        </template>
      </FancyModalContent>
    </BaseInfoModal>

    <!-- NEW INTEREST MARKS LIMITED MODAL -->
    <BaseInfoModal
      v-if="showGlobalModal === 'NewInterestLimited'"
      :tertiary-action-text="
        matchesViewABTest
          ? $t('blocked_modal_button')
          : $t('limited_modal_action')
      "
      :tertiary-action-link="
        matchesViewABTest ? $t('path_matches') : $t('path_messages')
      "
      @close="setGlobalModal(false)"
      @tertiary-clicked="chatLinkClicked"
    >
      <FancyModalContent>
        <template #icon>
          <BaseIcon
            icon-file="icon-stars"
            :width="72"
            :height="72"
            class="icon"
          />
        </template>
        <template #title>{{ $t('limited_modal_title') }}</template>
        <template #body>
          <p>
            {{ $t('limited_modal_text') }}
          </p>
        </template>
      </FancyModalContent>
    </BaseInfoModal>

    <!-- BOOST INFO MODAL -->
    <StandardModal
      v-if="showGlobalModal === 'BoostInfo'"
      standard-modal-name="BoostInfoModal"
      @close="setGlobalModal(false)"
    >
      <BoostInfoContent slot="content" />
    </StandardModal>

    <!-- UNLOCK MY VISITORS MODAL -->
    <BaseInfoModalNew
      v-if="showGlobalModal === 'MyVisitorsLocked'"
      @close="setGlobalModal(false)"
    >
      <FancyModalContentNew>
        <template #icon>
          <BaseIcon
            icon-file="illustration-stars"
            :width="72"
            :height="98"
            class="icon"
          />
        </template>
        <template #title>{{ $t('modal_my_visitors_locked_title') }}</template>
        <template #body>
          <ul>
            <li>{{ $t('modal_swaps_locked_usp5') }}</li>
            <li>{{ $t('modal_swaps_locked_usp1') }}</li>
            <li>{{ $t('modal_swaps_locked_usp2') }}</li>
            <li>{{ $t('modal_swaps_locked_usp3') }}</li>
            <li v-if="$country.isFeatureEnabled('SWAP_APPLICATION')">
              {{ $t('modal_swaps_locked_usp4') }}
            </li>
          </ul>
          <TrustBox
            v-if="$country.isFeatureEnabled('TRUSTPILOT')"
            key="swapslocked"
            type="Micro Trustscore"
            style="margin-top: 25px"
          />
        </template>
      </FancyModalContentNew>
      <template #actions>
        <BaseButton
          :link="`${$t(
            'path_payment_proposition',
            $routeLocale
          )}/${primaryPropositionId}`"
          icon-file="stars-white-filled"
          class="primary-action full-width"
          >{{ $t('modal_swaps_locked_button') }}</BaseButton
        >
      </template>
    </BaseInfoModalNew>
    <ReportModalWrapper
      v-if="showGlobalModal === 'ReportModal'"
      @close="setGlobalModal(false)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FancyModalContent from '@/components/Modals/ModalContentTemplates/FancyModalContent';
import FancyModalContentNew from '@/components/Modals/ModalContentTemplates/FancyModalContentNew';
import StandardModal from '@/components/Modals/Standard';
import BoostInfoContent from '@/components/Modals/BoostInfoContent';
import TrustBox from '@/components/TrustBox/TrustBox.vue';
import { formatCurrency } from '@/filters/currency';
import { log } from '../utils/logger';
import events from '@/utils/helpers/events';
import ReportModalWrapper from '@/components/Proposition/Report/ReportModalWrapper';

import {
  TUTORIAL_STATUS_TYPES,
  TUTORIAL_STEPS_TYPES
} from '../store/modules/tutorial';

export default {
  name: 'GlobalModalsContainer',

  components: {
    FancyModalContent,
    FancyModalContentNew,
    StandardModal,
    BoostInfoContent,
    TrustBox,
    ReportModalWrapper
  },

  data() {
    return {
      isLoading: false,
      TUTORIAL_STATUS_TYPES,
      TUTORIAL_STEPS_TYPES
    };
  },

  computed: {
    ...mapGetters({
      showGlobalModal: 'ui/showGlobalModal',
      selectedProduct: 'klarnaPayments/getSelectedProduct',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      products: 'klarnaPayments/getProducts',
      appReady: 'app/ready',
      tutStatus: 'tutorial/status'
    }),

    matchesViewABTest() {
      return this.$growthbook.isFeatureFlagEnabled('matches-view');
    },

    chatRoomId() {
      return this.$route.query.chatRoomId;
    },

    propositionIdQuery() {
      if (this.$route.query.propositionId) {
        return parseInt(this.$route.query.propositionId);
      }

      return null;
    },

    productPrice() {
      if (!this.selectedProduct) {
        return '';
      }

      return this.formatCurrency(this.selectedProduct.priceInclVat);
    },

    chatProductPrice() {
      if (!this.products) {
        return '';
      }

      const product = this.products.find(x => x.category === 'unlockchat');
      if (!product) {
        return '';
      }

      return this.formatCurrency(product.priceInclVat);
    },

    propositionProductPrice() {
      if (!this.products) {
        return '';
      }

      const product = this.products.find(
        x => x.category === 'annonsforlangning' && x.quantity === 30
      );
      if (!product) {
        return '';
      }

      return this.formatCurrency(product.priceInclVat);
    }
  },
  methods: {
    ...mapActions({
      setGlobalModal: 'ui/setGlobalModal',
      activateFreemium: 'handleSubscription/activateFreemium',
      setToast: 'toast/setToast',
      setTutActiveStep: 'tutorial/setTutActiveStep'
    }),

    formatCurrency,

    handleCloseNoLikesLeft() {
      this.setGlobalModal(false);
    },

    onModalClosed() {
      this.setGlobalModal(false);
    },

    async handleActivateFreemiumClick(propositionId) {
      this.isLoading = true;

      try {
        await this.activateFreemium(propositionId);
        this.setToast({
          title: this.$t('confirmation_proposition_title')
        });
      } catch (error) {
        log(`activateFreemium from inactivated: ${error}`, true);
        this.setToast({
          title: this.$t('toast_general_error_message')
        });
      }

      this.setGlobalModal(false);
    },

    handleActivateOrPayClose() {
      // Only clear query if chatRoomId is present
      if (this.$route.query.propositionId) {
        this.$router.replace({ query: {} });
      }

      this.setGlobalModal(false);
    },

    chatLinkClicked() {
      events.emitEvent(events.eventTypes.CLICK, 'Interest Limitation Chat', {
        src: 'Interest Limitation Modal'
      });
    }
  }
};
</script>

<style></style>
