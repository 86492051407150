<template>
  <div class="filter-radio-wrapper" @click.stop="select">
    <div class="title">
      {{ title }}
      <div
        v-if="badgeValue"
        :class="['badge', { highlighted: badgeType == 'highlighted' }]"
      >
        {{ badgeValue }}
      </div>
    </div>
    <div class="filter-radio">
      <transition name="scale" mode="out-in">
        <div v-if="selected" class="filter-radio-checked"></div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterRadio',

  props: {
    title: {
      type: String,
      required: true
    },
    selected: {
      type: [Boolean],
      default: false
    },
    select: {
      type: Function,
      required: true
    },
    badgeValue: {
      type: [String, Number],
      default: ''
    },
    badgeType: {
      type: String,
      default: 'Standard'
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-radio {
  height: 26px;
  width: 26px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  overflow: hidden;
  backface-visibility: hidden;

  &-wrapper {
    margin: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    backface-visibility: hidden;
  }

  &-checked {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: $transfer-blue;
    background-position: 35%;
    background-size: contain;
    background-image: url('../../../assets/svg/check-mark-white.svg');
    transition: all 0.2s ease-in-out;
    backface-visibility: hidden;
  }
}

.title {
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.badge {
  background-color: #e8e8e8;
  color: #2c3e50;
  border-radius: 5px;
  font-weight: 700;
  font-size: 0.7rem;
  padding: 4px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 6px;
}

.highlighted {
  background-color: $transfer-blue;
  color: #fff;
}
</style>
