<template>
  <button
    :class="[
      'button',
      {
        'is-fullwidth': isFullwidth,
        'is-disabled': isDisabled,
        'is-rounded': isRounded,
        'is-pill': isPill,
        'is-small': isSmall,
        'is-small': isSmall,
        'is-large': isLarge,
        'is-transparent': isTransparent,
        'is-default': isDefault,
        'is-dark': isDark,
        hover: hoverEffect,
        capitalize: capitalize,
        loading: isLoading
      },
      ...classes
    ]"
    v-bind="$attrs"
    :disabled="isDisabled"
    v-on="$listeners"
  >
    <div v-if="isLoading" class="inner-wrapper">
      <div class="spinner">
        <ASpinner />
      </div>
    </div>
    <div class="inner-wrapper" :class="{ hidden: isLoading || animating }">
      <slot />

      <div
        v-if="isPill"
        :class="{ hidden: !isPill || isLoading || animating }"
        class="right"
      >
        <div class="icon-wrapper">
          <AImage :url="icons.GENERAL.CLOSE_WHITE" :classes="['icon-close']" />
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import icons from '@/utils/icon-constants';

import ASpinner from '@/components/atomic/atoms/a-spinner';
import AImage from './a-image';

export default {
  name: 'AButton',
  components: { ASpinner, AImage },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    isFullwidth: {
      type: Boolean,
      default: false
    },

    isPill: { type: Boolean, default: false },

    isSmall: { type: Boolean, default: false },

    isLarge: { type: Boolean, default: false },

    isTransparent: { type: Boolean, default: false },

    isRounded: { type: Boolean, default: false },

    isDefault: { type: Boolean, default: false },

    isDark: { type: Boolean, default: false },

    hoverEffect: { type: Boolean, default: true },

    capitalize: { type: Boolean, default: false },

    classes: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      animating: false,
      icons: icons
    };
  }
};
</script>

<style lang="scss" scoped>
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  position: relative;
}

.button {
  background-color: $main-blue;
  -webkit-appearance: none;
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  outline: none;
  padding: 0 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  opacity: 1;

  &:active {
    outline: none;
  }
}

.button.hover:hover:not([disabled]) {
  filter: brightness(95%);
}

.is-fullwidth {
  width: 100%;
}

.capitalize {
  text-transform: capitalize;
}

.is-secondary {
  background-color: #2ec973;
}

.is-warning {
  background-color: red;
}

.is-dark {
  background-color: #2c3e50;
  border-color: #2c3e50;
}

.is-default {
  background-color: white;
  color: $text-primary;
  border: 1px solid #e8e8e8;
}

.is-uppercase {
  text-transform: uppercase;
}

.is-square {
  border-radius: 0;
}

.is-rounded {
  border-radius: 25px;
}

.is-rounded.is-large {
  border-radius: 30px;
}

.is-round {
  height: 45px;
  line-height: 45px;
  width: 45px;

  border-radius: 50%;
  padding: 0;

  &:hover {
    opacity: 1;
  }
}

.is-disabled {
  cursor: default;
  opacity: 0.5;
}

.spinner-fade-enter-active,
.spinner-fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-out;
}

.spinner-fade-enter {
  opacity: 1;
}

.spinner-fade-leave-active {
  opacity: 0;
}

.right {
  display: inline-flex;
  margin-left: 15px;
}

.icon-close {
  height: 12px;
  width: 12px;
  background-position: center;
  background-size: contain;
}

.icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.inner-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  word-break: keep-all;
}

.is-small {
  padding: 0 15px;
  height: 30px;
}

.is-large {
  padding: 0 30px;
  height: 60px;
}

.is-transparent {
  background-color: transparent;
}

.hidden {
  visibility: hidden;
}
</style>
