import { getValue } from '../../plugins/country/module';
const maxRent = getValue('MAX_RENT');

export const DEFAULT = {
  RESIDENCE: {
    RENT: {
      MIN: 0,
      MAX: maxRent
    },
    ROOMS: {
      MIN: 1,
      MAX: 6
    },
    SQM: {
      MIN: 0,
      MAX: 120
    },
    FLOOR: {
      MIN: 0,
      MAX: 10
    }
  },

  WISH: {
    ROOMS: {
      MIN: 1,
      MAX: 5
    },
    SQM: {
      MIN: 0,
      MAX: 100
    },
    FLOOR: {
      MIN: 0,
      MAX: 10
    },
    RENT: {
      MIN: 0,
      MAX: maxRent
    }
  }
};

export const INITIAL = {
  RESIDENCE: DEFAULT.RESIDENCE,

  WISH: {
    ROOMS: {
      MIN: 5,
      MAX: null
    },
    SQM: {
      MIN: 100,
      MAX: null
    },
    RENT: {
      MIN: 0,
      MAX: null
    },
    FLOOR: {
      MIN: 0,
      MAX: 10
    }
  }
};

export default {
  INITIAL,
  DEFAULT
};
