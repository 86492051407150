<template>
  <div
    v-if="appReady && filter"
    class="filters-wrapper"
    @keydown.enter="handleSearch()"
  >
    <div class="">
      <div class="mgb-30">
        <AToggleChoice
          :active="searchChoices.active"
          :inactive="searchChoices.inactive"
          :is-active="searchChoices.active.key === rootFilter.type"
          :button="true"
          @change="
            resetFilter($event.key);
            handleSearch();
          "
        />
      </div>

      <div class="mgb-30">
        <OGeolocation
          :value="geolocationValue"
          :is-multiple="false"
          :freetext-enabled="isTypeResidence"
          :max-results="4"
          :hide-type="true"
          :icon="icons.GENERAL.ARROW_RIGHT_ALT"
          :placeholder="
            $t(
              isTypeResidence
                ? 'search_filters_placeholder_1'
                : 'search_wish_area_placeholder'
            )
          "
          @input="searchSpecificArea($event)"
        ></OGeolocation>
      </div>

      <div v-if="isMobile" class="mgb-30">
        <AButton
          v-if="activeFilterKeys.includes('searchArea')"
          :is-pill="true"
          :is-small="true"
          :capitalize="true"
          :is-fullwidth="true"
          @click="
            setFilterDefaultByKey('searchArea');
            setRootFilter({ map: null });
            handleSearch();
          "
        >
          <span class="capitalize">
            {{ $t('general_map_territory') }}
          </span>
        </AButton>
      </div>

      <h2 v-if="!isTypeResidence" class="title">
        {{ $t('search_filters_title_3') }}
      </h2>

      <div class="pb-30">
        <AAccordion
          :icon="icons.FILTER.ROOMS"
          :active-on-init="true"
          :title="
            isTypeResidence
              ? $t('search_filters_choice_10_1')
              : $t('search_filters_choice_10_2')
          "
        >
          <h3 class="title">
            <span v-if="filter.rooms">
              <span v-html="rooms"> </span>
            </span>
            <span> {{ $t('slider_item_rooms') }}</span>
          </h3>

          <ASlider
            tooltip="off"
            :value="sliderValue(filter.rooms)"
            :min="sliderDefaults.ROOMS.MIN"
            :max="sliderDefaults.ROOMS.MAX"
            :piecewise="false"
            :piecewise-label="true"
            :formatter="formatRooms"
            :interval="1"
            :reversed="!isTypeResidence"
            @input="
              setFilter({ rooms: getSliderEventValue($event) });
              handleSearch();
            "
          />
        </AAccordion>
      </div>

      <div class="pb-30">
        <AAccordion
          :icon="icons.FILTER.RENT"
          :active-on-init="true"
          :title="
            isTypeResidence
              ? $t('search_filters_choice_12')
              : $t('search_sort_head_rent')
          "
        >
          <h3 class="title">
            {{ filter.rent.min | formatCurrency(false) }}
            <span
              v-if="
                filter.rent.max && filter.rent.max !== sliderDefaults.RENT.MAX
              "
            >
              {{ $t('search_filters_to') }}
              {{ filter.rent.max | formatCurrency(false) }}</span
            >
            <span v-else-if="filter.rent.max">
              {{ $t('search_filters_to') }} {{ filter.rent.max }}+</span
            >
            <span> {{ $t('search_filters_cost_per_month') }}</span>
          </h3>

          <ASlider
            tooltip="off"
            :value="sliderValue(filter.rent)"
            :min="sliderDefaults.RENT.MIN"
            :max="sliderDefaults.RENT.MAX"
            :interval="$country.isCountry('se') ? 500 : 50"
            :piecewise="false"
            :piecewise-label="true"
            :formatter="formatRent"
            @input="
              setFilter({ rent: getSliderEventValue($event) });
              handleSearch();
            "
          />
        </AAccordion>
      </div>

      <div class="pb-30">
        <AAccordion
          :icon="icons.FILTER.SQM"
          :active-on-init="activeFilterKeys.includes('sqm') || !isTypeResidence"
          :title="
            isTypeResidence
              ? $t('search_filters_choice_11_1')
              : $t('search_filters_choice_11_2')
          "
        >
          <h3 class="title">
            {{ filter.sqm.min }}
            <span
              v-if="filter.sqm.max && filter.sqm.max !== sliderDefaults.SQM.MAX"
            >
              {{ $t('search_filters_to') }} {{ filter.sqm.max }}</span
            ><span v-else-if="filter.sqm.max">
              {{ $t('search_filters_to') }} {{ filter.sqm.max }}+</span
            >
            <span> {{ $t('slider_item_sqm') }}</span>
          </h3>

          <ASlider
            tooltip="off"
            :value="sliderValue(filter.sqm)"
            :min="sliderDefaults.SQM.MIN"
            :max="sliderDefaults.SQM.MAX"
            :piecewise="false"
            :piecewise-label="true"
            :interval="5"
            :formatter="formatSqm"
            :reversed="!isTypeResidence"
            @input="
              setFilter({ sqm: getSliderEventValue($event) });
              handleSearch();
            "
          />
        </AAccordion>
      </div>

      <div class="pb-30">
        <AAccordion
          :icon="icons.FILTER.PROPOSITION_TYPE"
          :active-on-init="
            activeFilterKeys.includes('swapTypes') || !isTypeResidence
          "
          :title="$t('search_filters_title_1')"
        >
          <div class="container-around">
            <div class="flex-container">
              <APill
                :is-active="oneToOneChecked"
                :is-rounded="true"
                @click="
                  setRootFilter({
                    swapTypes: toggleArrayItem(rootFilter.swapTypes, 11)
                  });
                  handleSearch();
                "
              >
                {{ $t('general_1_for_1') }}
              </APill>

              <APill
                :is-active="oneToTwoChecked"
                :is-rounded="true"
                @click="
                  setRootFilter({
                    swapTypes: toggleArrayItem(rootFilter.swapTypes, 12)
                  });
                  handleSearch();
                "
              >
                {{ $t('general_1_for_2') }}
              </APill>

              <APill
                :is-active="twoToOneChecked"
                :is-rounded="true"
                @click="
                  setRootFilter({
                    swapTypes: toggleArrayItem(rootFilter.swapTypes, 21)
                  });
                  handleSearch();
                "
              >
                {{ $t('general_2_for_1') }}
              </APill>
            </div>
          </div>
        </AAccordion>
      </div>

      <AAccordion
        :icon="icons.FILTER.AMENITIES"
        :active-on-init="hasAmenities"
        :title="$t('search_filters_title_4')"
      >
        <div class="flex-container">
          <APill
            :is-active="filter.hasImage"
            :is-rounded="true"
            @click="
              setFilter({ hasImage: !filter.hasImage });
              handleSearch();
            "
          >
            {{ $t('search_filters_choice_4') }}
          </APill>

          <APill
            :is-active="filter.hasElevator"
            :is-rounded="true"
            @click="
              setFilter({
                hasElevator: !filter.hasElevator
              });
              handleSearch();
            "
          >
            {{ $t('search_filters_choice_5') }}
          </APill>

          <APill
            :is-active="filter.hasBalcony"
            :is-rounded="true"
            @click="
              setFilter({
                hasBalcony: !filter.hasBalcony
              });
              handleSearch();
            "
          >
            {{ $t('search_filters_choice_6') }}
          </APill>

          <APill
            :is-active="filter.hasFireplace"
            :is-rounded="true"
            @click="
              setFilter({
                hasFireplace: !filter.hasFireplace
              });
              handleSearch();
            "
          >
            {{ $t('search_filters_choice_13') }}
          </APill>

          <APill
            :is-active="filter.hasBathtub"
            :is-rounded="true"
            @click="
              setFilter({
                hasBathtub: !filter.hasBathtub
              });
              handleSearch();
            "
          >
            {{ $t('search_filters_choice_14') }}
          </APill>

          <APill
            :is-active="filter.floor === 0"
            :is-rounded="true"
            @click="
              setFilter({ floor: filter.floor === 0 ? null : 0 });
              handleSearch();
            "
          >
            {{ $t('search_filters_choice_7') }}
          </APill>

          <APill
            :is-active="filter.floor !== 0 && filter.floor !== null"
            :is-rounded="true"
            @click="
              setFilter({
                floor:
                  typeof filter.floor === 'object' && filter.floor !== null
                    ? null
                    : { min: 1 }
              });
              handleSearch();
            "
          >
            {{ $t('search_filters_choice_15') }}
          </APill>
        </div>
      </AAccordion>

      <template v-if="!isMobile"><br /><br /><br /></template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { toggleArrayItem } from '@/utils/helpers/array';
import endpoints from '@/utils/endpoints-constants';
import icons from '@/utils/icon-constants';
import { formatCurrency } from '@/filters/currency';
import { searchTypes } from '../../../models/search-filter';
import APill from '@/components/atomic/atoms/a-pill';
import AAccordion from '@/components/atomic/atoms/a-accordion';
import ASlider from '@/components/atomic/atoms/a-slider';
import sliderConstants from '@/utils/values/slider-filter-constants';
import OGeolocation from './o-geolocation';
import AToggleChoice from '@/components/atomic/atoms/a-toggle-choice';
import AButton from '@/components/atomic/atoms/a-button';
import { getRelatedLocalities } from '../../../store/modules/search/services';

export default {
  name: 'OSearchFilters',
  components: {
    APill,
    ASlider,
    OGeolocation,
    AToggleChoice,
    AAccordion,
    AButton
  },
  filters: {
    formatCurrency
  },
  data() {
    return {
      toggleActive: false,
      endpoints,
      sliderConstants,
      icons,
      searchTypes
    };
  },
  computed: {
    sliderDefaults() {
      return sliderConstants.DEFAULT[this.rootFilter.type.toUpperCase()];
    },

    rooms() {
      let rooms = 0;

      if (typeof this.filter.rooms === 'number') rooms = this.filter.rooms;
      else if (
        typeof this.filter.rooms === 'object' &&
        this.filter.rooms.max === null
      )
        rooms = this.filter.rooms.min;
      else if (
        typeof this.filter.rooms === 'object' &&
        this.filter.rooms.max !== null
      ) {
        let maxRooms = this.filter.rooms.max;
        if (this.filter.rooms.max === this.sliderDefaults.ROOMS.MAX)
          maxRooms = this.sliderDefaults.ROOMS.MAX + '+';
        return `<span>${this.filter.rooms.min} ${this.$t(
          'search_filters_to'
        )} ${maxRooms}</span>`;
      } else return null;

      if (rooms === 0) return 1;
      return rooms;
    },

    isTypeResidence() {
      return this.rootFilter.type === this.searchTypes.RESIDENCE;
    },

    sliderValue() {
      return input => {
        if (this.isTypeResidence) {
          return [input.min, input.max];
        }

        return input.min;
      };
    },

    geolocationValue() {
      if (this.filter.freetext) {
        return this.filter.freetext;
      } else if (!this.isTypeResidence) {
        return this.filter.geolocationsSource;
      } else {
        return this.filter.geolocations;
      }
    },

    oneToOneChecked() {
      return this.rootFilter.swapTypes.includes(11);
    },

    oneToTwoChecked() {
      return this.rootFilter.swapTypes.includes(12);
    },

    twoToOneChecked() {
      return this.rootFilter.swapTypes.includes(21);
    },

    searchChoices() {
      return {
        inactive: {
          key: searchTypes.RESIDENCE,
          text: this.$t('search_filters_radio_1', this.$routeLocale)
        },
        active: {
          key: searchTypes.WISH,
          text: this.$t('search_filters_radio_2', this.$routeLocale)
        }
      };
    },

    activeAmenitiesKeys() {
      if (!this.activeFilterKeys) return false;

      return Object.keys(this.filter).filter(
        x =>
          (x.startsWith('has') || x.startsWith('is')) && this.filter[x] === true
      );
    },

    hasAmenities() {
      return this.activeAmenitiesKeys.length > 0;
    },

    ...mapGetters({
      data: 'search/data',
      filter: 'search/filter',
      rootFilter: 'search/rootFilter',
      activeFilterKeys: 'search/activeFilterKeys',
      appReady: 'app/ready',
      isAuthenticated: 'app/isAuthenticated',
      isMobile: 'screenSize/isMobile'
    })
  },

  methods: {
    handleSearch(options = {}) {
      this.$emit('search', { scrollToTop: true, ...options });
    },

    toggleArrayItem,

    getSliderEventValue(e) {
      if (e.constructor === Array) return { min: e[0], max: e[1] };

      return { min: e, max: null };
    },

    stepVisible(steps, divides, current) {
      for (let step = 0; step < steps; step++) {
        if (divides * step === current) return true;
      }

      return false;
    },

    formatRent(input) {
      if (input == 0) return input;

      if (input == this.sliderDefaults.RENT.MAX) {
        if (this.isTypeResidence) {
          return this.sliderDefaults.RENT.MAX + '+';
        } else {
          return input;
        }
      }

      return '';
    },

    formatRooms(input) {
      if (
        this.isTypeResidence &&
        input &&
        input === this.sliderDefaults.ROOMS.MAX
      ) {
        return this.sliderDefaults.ROOMS.MAX + '+';
      }

      return input;
    },

    formatSqm(input) {
      if (input == 0) return input;

      const max = Number(this.sliderDefaults.SQM.MAX);
      const current = Number(input);

      if (current === max) {
        if (this.isTypeResidence) {
          return max + '+';
        } else {
          return input;
        }
      }

      return '';
    },

    async searchSpecificArea(eventData) {
      this.setRootFilter({
        fromMapInteraction: false
      });

      if (
        !this.isTypeResidence &&
        eventData.geolocations &&
        eventData.geolocations[0]
      ) {
        const relatedLocalities = await getRelatedLocalities(
          eventData.geolocations[0].id
        );

        eventData.geolocations = relatedLocalities.map(id => ({
          id: id
        }));
      }

      this.setFilter({
        geolocations: eventData.geolocations,
        freetext: eventData.freetext || null,
        geolocationsSource: eventData.geolocationsSource || null
      });

      this.handleSearch({ skipAreaSearch: true });
    },

    ...mapMutations({
      setItem: 'search/setItem',
      setFilter: 'search/setFilter',
      setRootFilter: 'search/setRootFilter',
      setFilterDefaultByKey: 'search/setFilterDefaultByKey'
    }),

    ...mapActions({
      fetchSearch: 'search/fetchSearch',
      resetFilter: 'search/resetFilter'
    })
  }
};
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-items: flex-start;
}

.flex-container > * {
  margin-bottom: 8px;
  margin-right: 8px;
}

.container-around {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: $brand-dark;
  margin-bottom: 10px;
  margin-top: 0px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pt-20 {
  padding-top: 20px;
}

.mgt-30 {
  margin-top: 30px;
}

.mgt-20 {
  margin-top: 20px;
}

.mgb-20 {
  margin-bottom: 20px;
}

.mgb-30 {
  margin-bottom: 30px;
}

.mgb-40 {
  margin-bottom: 40px;
}

::v-deep .vue-slider-piecewise-label {
  font-size: 10px !important;
}
</style>
