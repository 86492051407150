import { isCountry } from '@/plugins/country/module';

const fetchingPropositions = state => state.fetchingPropositions;
const propositions = state => state.propositions;
const loadingPropositions = state => state.loading;
const primaryPropositionId = state =>
  state.primaryProposition ? state.primaryProposition.propositionId : null;
const primaryProposition = state => state.primaryProposition;

const primaryPropositionActive = state => {
  if (!state.propositions || !state.propositions.length === 0) return false;

  const hasActive = state.propositions.some(
    p =>
      p &&
      p.active &&
      state.primaryProposition &&
      p.propositionId == state.primaryProposition.propositionId
  );

  return hasActive;
};

const isFreemiumAvailable = state => {
  if (!state.primaryProposition) return false;
  return state.primaryProposition.freemiumAvailable;
};

const primaryPropositionFreeArea = state => {
  if (!state.primaryProposition) return false;
  return state.primaryProposition.inFreeArea;
};

const primaryPropositionInTrial = state => {
  if (!state.primaryProposition) return false;
  return state.primaryProposition.isTrial;
};

const primaryPropositionIsLimited = state => {
  if (!state.primaryProposition) return false;

  return (
    state.primaryProposition.isFreemium || state.primaryProposition.isTrial
  );
};

const primaryPropositionIsPremium = state => {
  if (!state.primaryProposition) return false;
  return (
    !state.primaryProposition.inFreeArea &&
    !state.primaryProposition.isTrial &&
    !state.primaryProposition.isFreemium
  );
};

const primaryPropositionIsPremiumOrFree = state => {
  if (!state.primaryProposition) return false;
  return (
    !state.primaryProposition.isTrial && !state.primaryProposition.isFreemium
  );
};

const accountType = state => {
  if (!state.propositions) {
    return 'unknown';
  }

  if (
    state.propositions.some(
      x => x.productId != 1000 && x.productId != 2000 && !x.isTrial
    )
  ) {
    return 'paying';
  } else if (state.propositions.some(x => x.isTrial)) {
    return 'trial';
  } else {
    return 'free';
  }
};

const primaryPropositionSubscriptionType = state => {
  if (!state.primaryProposition || !state.primaryProposition.propositionId) {
    return '';
  }

  const pp = state.primaryProposition;

  if (pp.productId == 2000) return 'freemium';
  else if (pp.productId == 1000) return 'free';
  else if (pp.isTrial) return 'trial';
  else return 'paying';
};

const totalImageCount = state => {
  if (!state.propositions) return 0;

  const imageCountArray = state.propositions
    .map(x => x.residences.map(y => y.images.length).flat())
    .flat()
    .filter(x => x);
  let imagesCount = 0;
  imageCountArray.forEach(x => (imagesCount += x));

  return imagesCount;
};

const onboardingState = state => {
  if (
    !state.primaryProposition ||
    !state.primaryProposition.propositionId ||
    !state.propositions
  )
    return null;

  const { propositionId } = state.primaryProposition;

  const primaryProposition = state.propositions.find(
    p => p.propositionId === propositionId
  );

  if (
    !primaryProposition ||
    !primaryProposition.residences ||
    !primaryProposition.active
  )
    return null;

  const hasDescription = primaryProposition.residences.some(
    r => !!r.description
  );
  const hasImages = primaryProposition.residences.some(r => r.images.length);
  const hasLandlord = primaryProposition.residences.some(r => !!r.landlord);

  if (hasImages && hasDescription && (isCountry('se') ? hasLandlord : true))
    return null;

  if (isCountry('se')) {
    return {
      hasImages,
      hasDescription,
      hasLandlord,
      hasProposition: true
    };
  }

  return {
    hasImages,
    hasDescription,
    hasProposition: true
  };
};

const blurGalleryImages = (state, getters, rootState, rootGetters) => {
  const isAuthenticated = rootGetters['app/isAuthenticated'];
  const allowAccess = rootGetters['app/allowAccess'];
  if (allowAccess) {
    return false;
  }

  if (!isAuthenticated) {
    return true;
  }

  if (
    isAuthenticated &&
    state.primaryProposition &&
    state.primaryProposition.residences &&
    state.primaryProposition.residences[0]
  ) {
    const imageCount = state.primaryProposition.residences[0].images.length;
    if (imageCount >= 2) {
      return false;
    }
  }

  return true;
};

export default {
  propositions,
  loadingPropositions,
  accountType,
  totalImageCount,
  onboardingState,
  primaryPropositionId,
  primaryProposition,
  primaryPropositionActive,
  fetchingPropositions,
  isFreemiumAvailable,
  primaryPropositionFreeArea,
  primaryPropositionInTrial,
  primaryPropositionIsLimited,
  primaryPropositionIsPremium,
  primaryPropositionIsPremiumOrFree,
  primaryPropositionSubscriptionType,
  blurGalleryImages
};
