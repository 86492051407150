const showMobileHeader = state => state.showMobileHeader;
const showDesktopHeader = state => state.showDesktopHeader;
const showGlobalModal = state => state.showGlobalModal;
const showCookiesModal = state => state.showCookiesModal;
const propositionViewModalId = state => state.propositionViewModalId;
const propositionViewModalType = state => state.propositionType;
const pageModalActivePage = state => state.pageModalActivePage;
const pageModalZIndex = state => state.pageModalZIndex;
const disableScroll = state => state.disableScroll?.length > 0;
const freemiumBannerTopMargin = state => state.freemiumBannerTopMargin;
const activeModals = state => state.activeModals;
const usedModals = state => state.usedModals;
const currentModal = state => state.currentModal;

export default {
  showMobileHeader,
  showDesktopHeader,
  showGlobalModal,
  showCookiesModal,
  propositionViewModalId,
  propositionViewModalType,
  pageModalActivePage,
  pageModalZIndex,
  disableScroll,
  freemiumBannerTopMargin,
  activeModals,
  usedModals,
  currentModal
};
