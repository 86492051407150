<template>
  <div class="wrapper" @click.stop="handleClick">
    <div class="title">{{ title }}</div>
    <div :class="['toggle-wrapper', { on: isOn }]">
      <div :class="['toggle-indicator', { on: isOn }]"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleButton',

  props: {
    title: {
      type: String,
      required: true
    },
    isOn: {
      type: Boolean,
      default: false
    },
    onToggle: {
      type: Function,
      required: true
    }
  },

  methods: {
    handleClick() {
      this.onToggle();
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;

  @media ($mobile) {
    width: 100%;
    justify-content: space-between;
  }
}

.title {
  font-weight: 600;
  font-size: 0.8rem;
}

.toggle-wrapper {
  margin-left: 4px;
  width: 38px;
  height: 20px;
  border-radius: 25px;
  background-color: #e8eaec;
  position: relative;
  transition: all 0.2s ease-in-out;

  &.on {
    background-color: $transfer-blue;
  }
}

.toggle-indicator {
  height: 16px;
  width: 16px;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.2s ease-in-out;

  &.on {
    left: 20px;
  }
}
</style>
