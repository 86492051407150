import { vue } from '@/main';
import i18n from '@/i18n';
import services from './services';
import { subscribe } from '../../../utils/socket';
import events from '@/utils/helpers/events';

let lastInterest = null;

async function init({ dispatch, rootState }) {
  subscribe('interest', async data => {
    const { interested, propositionId, propositionId2 } = data;

    const currentUserProposition =
      rootState.myPropositions.primaryProposition.propositionId;
    if (currentUserProposition != propositionId) {
      return;
    }

    // Prevent action on echo
    if (
      lastInterest &&
      lastInterest.propositionId == propositionId &&
      propositionId2 == lastInterest.targetPropositionId &&
      interested === lastInterest.interested
    ) {
      return;
    }

    dispatch('markInterest', {
      interested,
      targetPropositionId: propositionId2,
      skipRequest: true
    });

    dispatch('swapList/getSwapInterestCounts', null, { root: true });
  });
}

async function markInterest(
  context,
  { targetPropositionId, interested, skipRequest, where }
) {
  window.Logger.log({ targetPropositionId, interested });
  const currentUserProposition =
    context.rootState.myPropositions.primaryProposition.propositionId; // primary proposition
  let interest;
  if (interested === true) {
    interest = 1;
  } else if (interested === false) {
    interest = 0;
  } else {
    interest = null;
  }

  // dispatch events to update vuex states where needed
  context.dispatch('setInterestStatus', { targetPropositionId, interested });

  if (skipRequest) return;

  // for checking socket echoing
  lastInterest = {
    interested,
    propositionId: currentUserProposition,
    targetPropositionId
  };

  events.emitEvent(events.eventTypes.CLICK, 'Interest', {
    src: where,
    interested
  });

  // send request
  try {
    const response = await services.markInterest({
      propositionId: currentUserProposition.toString(),
      propositionId2: targetPropositionId.toString(),
      interest,
      source: where
    });

    if (response.success === true) {
      context.dispatch('interests/setMarkInterestDone', true, { root: true });
      context.dispatch(
        'swapList/removeSwapFromArray',
        { targetPropositionId, interested },
        { root: true }
      );

      // Show +1 badge
      if (interested) {
        context.dispatch('swapList/showSwapAddedBadge', interested, {
          root: true
        });
      }

      context.dispatch('swapList/getSwapInterestCounts', null, {
        root: true
      });

      context
        .dispatch('menu/fetchNotifications', currentUserProposition, {
          root: true
        })
        .then(notifications => {
          context.dispatch(
            'swapList/setNewSwapsCount',
            notifications.newSwaps,
            {
              root: true
            }
          );
        });
    } else if (response.remaining === 0) {
      events.emitEvent(events.eventTypes.SEE, 'Paywall', {
        trigger: 'interest-mark',
        src: where
      });
    }

    context.dispatch('showLikeInfo', {
      ...response,
      interested,
      targetPropositionId
    });

    context.dispatch(
      'gtm/interestMarked',
      {
        propositionId: currentUserProposition.toString(),
        propositionId2: targetPropositionId.toString(),
        interest,
        where
      },
      { root: true }
    );
  } catch (e) {
    // handle if request fails, revert interest in vuex modules
    window.Logger.log("ERROR: Couldn't mark interest");
  }
}

function setInterestStatus(context, { targetPropositionId, interested }) {
  // propositions
  context.dispatch(
    'propositions/setSwapInterest',
    { targetPropositionId, interested },
    { root: true }
  );

  // swap list & swap chain
  context.dispatch(
    'swapList/setSwapInterest',
    { targetPropositionId, interested },
    { root: true }
  );

  context.dispatch(
    'swapMap/setSwapInterest',
    { targetPropositionId, interested },
    { root: true }
  );
}

async function showLikeInfo(
  context,
  { isFirstOfType, remaining, success, interested, targetPropositionId, error }
) {
  if (error === 'IS_PREMIUM_SUGGESTION') {
    context.dispatch('ui/setGlobalModal', 'GetUnlimitedLikes', {
      root: true
    });

    // Reset interest mark
    context.dispatch('setInterestStatus', {
      targetPropositionId,
      interested: null
    });

    return;
  }

  // Show first interest click (Yes and No) modal
  if (isFirstOfType) {
    if (interested) {
      context.dispatch('ui/setGlobalModal', 'FirstYesInterest', { root: true });
    } else {
      context.dispatch('ui/setGlobalModal', 'FirstNoInterest', { root: true });
    }
  }

  if (interested) {
    // If limited due to too many unanswered
    if (error === 'TOO_MANY_UNANSWERED') {
      context.dispatch('ui/setGlobalModal', 'NewInterestLimited', {
        root: true
      });

      events.emitEvent(events.eventTypes.SEE, 'Interest Limitation', {
        reason: 'Too many unanswered'
      });

      // Remove like
      context.dispatch('setInterestStatus', {
        targetPropositionId,
        interested: null
      });
    } else {
      // Show toast if likes left
      if (remaining >= 0 && success === true) {
        vue.$nextTick(() => {
          context.dispatch(
            'toast/setToast',
            {
              title: i18n.t('toast_likes_left', { likesLeft: remaining }),
              timer: 5
            },
            { root: true }
          );
        });
      }

      // Show no likes left modal
      if (remaining === 0 && success === false) {
        context.dispatch('ui/setGlobalModal', 'NoLikesLeft', {
          root: true
        });

        // Remove like
        context.dispatch('setInterestStatus', {
          targetPropositionId,
          interested: null
        });

        events.emitEvent(events.eventTypes.SEE, 'Interest Limitation', {
          reason: 'No likes left'
        });
      }
    }
  }
}

async function setMarkInterestDone(context, value) {
  context.commit('setMarkInterestDone', value);
}

export default {
  markInterest,
  setInterestStatus,
  showLikeInfo,
  setMarkInterestDone,
  init
};
