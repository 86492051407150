var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mobile-swap-header"},[(_vm.sortModalOpen)?_c('StandardModal',{staticStyle:{"z-index":"1002"},attrs:{"standard-modal-name":"SortModal","close-function":_vm.closeSortModal}},[_c('SortOptions',{attrs:{"slot":"content"},on:{"reorder":function (sortOption) { return _vm.handleSelectSortType(sortOption); }},slot:"content"})],1):_vm._e(),(_vm.filterModalOpen)?_c('StandardModal',{attrs:{"standard-modal-name":"FilterModal","title":_vm.$t('mobile_header_filter'),"close-function":_vm.closeFilterModal,"action-function":_vm.resetFilters,"action-title":_vm.$t('mobile_header_clear'),"with-border":true}},[_c('Filters',{attrs:{"slot":"content","close-filter-modal":_vm.closeFilterModal,"readable-sort-type":_vm.readableSortType},on:{"sort":_vm.openSortModal},slot:"content"})],1):_vm._e(),_c('div',{class:_vm.tutStatus === _vm.TUTORIAL_STATUS_TYPES.STARTED &&
      _vm.tutActiveStep === _vm.TUTORIAL_STEPS_TYPES.FIRST_INTEREST_MODAL
        ? 'animate-slide-down-height'
        : ''},[_c('InfoBanner',{staticClass:"info-banner"}),(_vm.isUserList && _vm.showPropositionSwitcher)?_c('PropositionSwitcher'):_vm._e(),(
        _vm.shouldShowOnboardingBanner &&
        _vm.tutStatus !== _vm.TUTORIAL_STATUS_TYPES.STARTED &&
        _vm.accountType !== 'trial'
      )?_c('div',{staticClass:"onboarding-wrapper",class:{
        'onboarding-fade-grow-animation':
          _vm.tutStatus === _vm.TUTORIAL_STATUS_TYPES.COMPLETED
      }},[_c('OnboardingBanner')],1):_vm._e(),(_vm.isUserList && !_vm.distributedSwapsEnabled && !_vm.matchesViewABTest)?_c('SwapListToggle'):_vm._e(),_c('div',{staticClass:"search-filter-wrapper"},[_c('Search'),_c('div',{staticClass:"filter-action",on:{"click":function($event){$event.stopPropagation();return _vm.openFilterModal.apply(null, arguments)}}},[_c('div',{staticClass:"filter-icon"}),(_vm.filterCount > 0)?_c('div',{staticClass:"filter-count"},[_vm._v(" "+_vm._s(_vm.filterCount)+" ")]):_vm._e()])],1),_c('div',{staticClass:"action-wrapper"},[(_vm.loading)?_c('div',{staticClass:"swap-count-wrapper"},[_vm._v(" "+_vm._s(_vm.$t('mobile_header_loading_swaps'))+" ")]):(_vm.distributedSwapsEnabled && _vm.newSwapsCount > 0)?_c('div',{staticClass:"swap-count-wrapper"},[(_vm.newSwapsCount === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_new_count_single_header')))]):_vm._e(),(_vm.newSwapsCount > 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('swaplist_header_new_count_header', { new: _vm.newSwapsCount }))+" ")]):_vm._e()]):_c('div',{staticClass:"swap-count-wrapper"},[_vm._v(" "+_vm._s(_vm.$t('mobile_header_showing'))+" "),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.postFilterCountTotal))]),_vm._v(" "+_vm._s(_vm.$t('mobile_header_swaps'))+" "),(_vm.swapFilterType == 'ALL' || _vm.swapFilterType == 'NEW')?_c('div',{staticClass:"swap-count-unanswered"},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.postFilterCountUnmarked))]),_vm._v(" "+_vm._s(_vm.$t('mobile_header_unanswered'))+" ")]):_vm._e()]),_c('ListTypeToggle')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }