<template>
  <SocialLoginButton :btn-text="$t('login_with_apple')" @click="logInWithApple">
    <template>
      <svg
        id="Social_Icon"
        enable-background="new 0 0 512 512"
        height="512px"
        version="1.1"
        viewBox="0 0 512 512"
        width="512px"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g>
          <path
            clip-rule="evenodd"
            d="M368.003,117.456c-44.426,0-68.012,22.217-102.915,21.764   c-34.912,0.453-58.49-21.764-102.922-21.764c-35.364,0-80.257,21.764-100.657,53.511c-37.629,58.048-34.005,121.088-18.135,186.378   C60.603,426.744,121.358,512,169.421,512c39.441,0,57.131-21.325,95.667-21.325c38.526,0,55.756,21.325,94.76,21.325   c44.425,0,97.472-73.023,120.152-137.876c-43.534-17.232-73.01-60.306-73.01-106.113c0-45.346,23.133-80.264,58.498-100.215   C443.729,138.314,402.008,117.456,368.003,117.456L368.003,117.456z M356.209,0c-63.918,6.804-111.521,60.313-107.444,121.081   C312.691,114.731,359.848,60.767,356.209,0L356.209,0z"
            fill="#C6C7C9"
            fill-rule="evenodd"
          />
        </g>
      </svg>
    </template>
  </SocialLoginButton>
</template>

<script>
import i18n from '@/i18n';
import { authenticationApi } from '@/utils/axiosConfig';
import SocialLoginButton from '../Buttons/SocialLoginButton.vue';

export default {
  name: 'AppleLoginButton',
  components: {
    SocialLoginButton
  },
  props: {
    onBeginAuthorization: {
      type: Function,
      required: true
    },
    onAuthorizationFailed: {
      type: Function,
      required: true
    },
    onAuthorizationSuccessful: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      initialized: false
    };
  },
  methods: {
    waitForScript() {
      return new Promise(resolve => {
        const readyCheckInterval = setInterval(() => {
          if (!window.AppleID || !window.AppleID.auth) {
            return;
          }

          clearInterval(readyCheckInterval);
          resolve();
        }, 100);
      });
    },
    async handleAppleIdentityResponse(response) {
      if (!response.authorization) {
        this.onAuthorizationFailed({
          type: 'UNAUTHORIZED'
        });
        return;
      }

      this.onBeginAuthorization();

      try {
        const authResponse = await authenticationApi.post(
          `apple/login?accessToken=${response.authorization.id_token}`
        );

        if (authResponse.data.success) {
          this.onAuthorizationSuccessful();
        } else {
          this.onAuthorizationFailed(authResponse.data);
        }
      } catch (error) {
        console.log('Error', error);
        this.onAuthorizationFailed({
          type: 'ERROR'
        });
      }
    },

    async logInWithApple() {
      this.loadAppleIdSDK();
      await this.waitForScript();
      this.initApple();
      const response = await window.AppleID.auth.signIn();
      this.handleAppleIdentityResponse(response);
    },
    initApple() {
      window.AppleID.auth.init({
        clientId: i18n.t('apple_login_client_id'),
        scope: 'name email',
        redirectURI: window.location.origin,
        state: 'SignInUserAuthenticationRequest',
        usePopup: true,
        responseType: 'code',
        responseMode: 'query'
      });
    },
    loadAppleIdSDK() {
      const id = 'apple-id-jssdk';
      const fjs = document.getElementsByTagName('script')[0];
      if (document.getElementById(id)) {
        return;
      }
      const js = document.createElement('script');
      js.id = id;
      js.src =
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

      fjs.parentNode.insertBefore(js, fjs);
    }
  }
};
</script>
