import { dayjs } from '@/plugins/dayjs/install';
import i18n from '@/i18n';

export function todayYesterdayDate(date) {
  if (dayjs(date).isSame(dayjs(), 'day')) {
    return i18n.t('date_today') + ' ' + dayjs(date).format('HH:mm');
  } else if (dayjs(date).isSame(dayjs().subtract(1, 'day'), 'day')) {
    return i18n.t('date_yesterday') + ' ' + dayjs(date).format('HH:mm');
  } else {
    return dayjs(date).format('D MMMM HH:mm');
  }
}

export default { todayYesterdayDate };
