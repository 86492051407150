<template>
  <section>
    <div class="full-width">
      <section v-if="hasPaymentToken && !changePaymentMethod">
        <section>
          <Agreement
            v-if="!changePaymentMethod"
            v-model="agreementChecked"
            :product="product"
            :checkbox-hidden="true"
          />
          <button
            :class="[
              'order-button',
              { 'extra-visibility': product.category === 'extrasynlighet' }
            ]"
            :disabled="isLoading"
            @click="pay()"
          >
            <span v-if="payText">
              {{ payText }}
            </span>
            <span v-else>{{ $t('klarna_button_action') }}</span>
          </button>

          <a v-if="!isLoading" class="change-payment-method" @click="restart()">
            {{ $t('payment_new_method_action') }}
          </a>
        </section>
      </section>

      <section v-show="!isLoadingDropinComponent">
        <form
          @submit.stop.prevent="
            changePaymentMethod ? createToken() : createTokenAndpay()
          "
        >
          <div id="klarna-container"></div>

          <div v-if="!hasPaymentToken">
            <Agreement
              v-if="!changePaymentMethod"
              v-model="agreementChecked"
              :product="product"
            />
            <button
              :disabled="
                isLoading ||
                isLoadingDropinComponent ||
                isUpdatingSession ||
                (!agreementChecked && !changePaymentMethod)
              "
              class="order-button"
              type="submit"
            >
              {{ $t('payment_continue_action') }}
            </button>
          </div>
        </form>
      </section>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { paymentApi } from '@/utils/axiosConfig';
import Agreement from '@/components/Payments/Agreement';
import events from '@/utils/helpers/events';

export default {
  components: { Agreement },

  props: {
    changePaymentMethod: {
      type: Boolean,
      default: false
    },

    payText: {
      type: String,
      default: null
    },

    product: {
      type: Object,
      default: null
    },

    additionalProducts: {
      type: Array,
      default: null
    },

    meta: {
      type: Object,
      default: null
    },

    propositionId: {
      type: [String, Number, null],
      default: null
    }
  },

  data() {
    return {
      isLoadingDropinComponent: false,
      hasPaymentToken: null,
      isLoading: false,
      paymentSuccessful: false,
      klarnaComponentInited: false,
      sessionId: null,
      isUpdatingSession: false,
      agreementChecked: false
    };
  },

  computed: {
    productId() {
      if (!this.product) {
        return null;
      }

      return this.product.productId;
    }
  },

  watch: {
    async product() {
      if (this.sessionId && !this.changePaymentMethod) {
        this.isUpdatingSession = true;
        // Update session with new product info
        await this.mergeSession();
        this.isUpdatingSession = false;
      }
    },
    async additionalProducts() {
      if (this.sessionId && !this.changePaymentMethod) {
        this.isUpdatingSession = true;
        // Update session with new product info
        await this.mergeSession();
        this.isUpdatingSession = false;
      }
    }
  },

  async created() {
    this.setIsLoading(true);

    if (!this.changePaymentMethod) {
      this.hasPaymentToken = await this.getHasPaymentToken();
      if (!this.hasPaymentToken) {
        await this.initPaymentForm();
      }
    } else {
      await this.initPaymentForm();
    }

    this.setIsLoading(false);
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast'
    }),

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
      this.$emit('loading', isLoading);
    },

    async restart() {
      this.$emit('resetPaymentMethod');
      this.setIsLoading(true);
      this.hasPaymentToken = false;
      await this.initPaymentForm();
      this.setIsLoading(false);
    },

    async initPaymentForm() {
      this.isLoadingDropinComponent = true;
      await this.loadJs();
      await this.mergeSession();
      this.isLoadingDropinComponent = false;
    },

    sendContinuePaymentEvent() {
      events.emitEvent(events.eventTypes.CLICK, 'Continue Payment', {
        provider: 'Klarna',
        propositionId: this.propositionId,
        productId: this.productId,
        additionalProductIds: this.additionalProducts
          ? this.additionalProducts.map(p => p.id)
          : []
      });
    },

    async createTokenAndpay() {
      this.setIsLoading(true);
      this.sendContinuePaymentEvent();

      const { authorization_token, approved } = await this.getKlarnaAuthToken();
      if (!approved) {
        this.restart();
        return;
      }

      const data = await this.createCustomerTokenAndPay(
        authorization_token,
        this.productId,
        this.propositionId
      );

      this.handlePaymentResponse(data);

      this.setIsLoading(false);
    },

    async createToken() {
      this.setIsLoading(true);

      const { authorization_token, approved } = await this.getKlarnaAuthToken();
      if (!approved) {
        this.restart();
        return;
      }

      const data = await this.createCustomerToken(authorization_token);
      this.handlePaymentResponse(data);

      this.setIsLoading(false);
    },

    async pay() {
      this.setIsLoading(true);
      this.sendContinuePaymentEvent();

      try {
        const { data } = await paymentApi.post(`/klarna/pay`, {
          productId: this.productId,
          propositionId: this.propositionId,
          meta: this.meta,
          additionalProductIds: this.additionalProducts
            ? this.additionalProducts.map(p => p.id)
            : []
        });

        this.handlePaymentResponse(data);
        this.setIsLoading(false);
      } catch (error) {
        this.handleError(error);
      }
    },

    handlePaymentResponse({ status, reason }) {
      if (status === 'AUTHORIZED') {
        this.$emit('success');
        return;
      } else if (status === 'REFUSED') {
        if (this.changePaymentMethod) {
          this.setToast({
            timer: 30,
            title: this.$t('payment_change_method_refused')
          });
        } else {
          if (reason) {
            this.setToast({
              timer: 30,
              title: this.$t('payment_refused_reason', {
                reason: reason
              })
            });
          } else {
            this.setToast({
              timer: 30,
              title: this.$t('payment_refused_reason', { reason: 'Unknown' })
            });
          }
        }

        this.restart();
      } else if (
        status === 'FAILED' ||
        status === 'INTERNAL_ERROR' ||
        status === 'ALREADY_PAID'
      ) {
        this.handleError(new Error(reason));
      }
    },

    getKlarnaAuthToken() {
      return new Promise(resolve => {
        window.Klarna.Payments.authorize(
          {
            instance_id: 'klarna-payments-instance'
          },
          {},
          result => {
            resolve(result);
          }
        );
      });
    },

    async createCustomerTokenAndPay(authToken, productId, propositionId) {
      try {
        const { data } = await paymentApi.post(
          `/klarna/create-payment-token-and-pay`,
          {
            authToken,
            productId,
            propositionId,
            meta: this.meta,
            additionalProductIds: this.additionalProducts
              ? this.additionalProducts.map(p => p.id)
              : []
          }
        );

        return data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async createCustomerToken(authToken) {
      try {
        const { data } = await paymentApi.post(`/klarna/create-payment-token`, {
          authToken
        });

        return data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getHasPaymentToken() {
      try {
        const { data } = await paymentApi.get(`/klarna/has-payment-token`);
        return data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async mergeSession() {
      try {
        const { data } = await paymentApi.post(`/klarna/merge-session`, {
          productId: this.changePaymentMethod ? 20230331 : this.productId,
          sessionId: this.sessionId,
          changePaymentMethodOnly: this.changePaymentMethod,
          additionalProductIds: this.additionalProducts
            ? this.additionalProducts.map(p => p.id)
            : []
        });

        if (!this.sessionId) {
          this.sessionId = data.session_id;
          await this.initWidget(data.client_token);
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    initWidget(token) {
      return new Promise(resolve => {
        if (!this.klarnaComponentInited) {
          window.Klarna.Payments.init({
            client_token: token
          });
        }

        window.Klarna.Payments.load(
          {
            container: '#klarna-container',
            instance_id: 'klarna-payments-instance'
          },
          {
            locale: this.$t('locale_language', this.$routeLocale)
          },
          result => {
            resolve(result.show_form);
          }
        );
      });
    },

    loadJs() {
      return new Promise(resolve => {
        const address = 'https://x.klarnacdn.net/kp/lib/v1/api.js';
        const exists = document.querySelector(`script[src="${address}"]`);

        if (exists) {
          resolve();
        }

        const script = document.createElement('script');
        script.onload = () => resolve();
        script.async = true;
        script.src = address;
        document.head.appendChild(script);
      });
    },

    handleError(error) {
      this.setIsLoading(false);
      this.$emit('error', error);
    }
  }
};
</script>

<style lang="scss" scoped>
.order-button {
  border: none;
  cursor: pointer;
  background-color: $transfer-blue;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  font-family: inherit;
  border-radius: 5px;
  padding: 15px 45px;
  margin-bottom: 30px;
  width: 100%;
  height: auto;
  line-height: inherit;
  margin-top: 10px;

  &.extra-visibility {
    background-color: #9f41e9;
  }
}

.order-button[disabled] {
  background: #d0d0d0;
}

.change-payment-method {
  display: block;
  font-weight: 600;
  color: #409fff;
  text-align: center;
}

.change-payment-method:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
