<template>
  <div class="gallery-wrapper">
    <div class="header-area">
      <h1 class="address">
        <a href="javascript:history.back()"
          ><span>{{
            currentAddress || $t('proposition_gallery_loading')
          }}</span></a
        >
      </h1>
    </div>

    <template
      v-if="
        currentProposition &&
        currentProposition.residences &&
        currentProposition.residences.length
      "
    >
      <div v-if="!isMobile" class="gallery-area-desktop">
        <div class="current-image-wrapper">
          <router-link
            v-if="images.length >= 2"
            class="scroll left"
            :to="getImageUrl(previousIndex)"
            replace
          >
          </router-link>
          <div
            v-if="blurGalleryImages && currentImageIndex >= 2"
            class="overlay"
          ></div>
          <div
            v-if="blurGalleryImages && currentImageIndex >= 2"
            class="blur-msg-wrapper"
          >
            <div
              class="blur-msg-click-container"
              @click.stop="goToEditPropositionImages"
            >
              <BaseIcon icon-file="blur-img-eye" :width="48" :height="46" />

              <div class="blur-msg">
                <p class="unblock-text">
                  {{ $t('unlock_blur_msg') }}
                </p>
                <div class="upload-images-text">
                  <span
                    ><u>{{ $t('proposition_gallery_blur_link_msg') }}</u>
                  </span>
                  <span>
                    {{ $t('proposition_gallery_blur_msg') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <img
            :src="currentShownImage"
            class="current-image"
            :class="{
              'blurred-background-image':
                blurGalleryImages && currentImageIndex >= 2
            }"
          />
          <router-link
            v-if="images.length >= 2"
            class="scroll right"
            :to="getImageUrl(nextIndex)"
            replace
          ></router-link>
        </div>

        <div class="thumbnails">
          <router-link
            v-for="(image, index) in images"
            :key="image.fileName + index"
            :class="[
              'thumbnail',
              {
                active: index == currentImageIndex,
                'blur-thumbnail': index >= 2 && blurGalleryImages
              }
            ]"
            :style="[{ backgroundImage: `url(${image.fileName})`, opacity: 1 }]"
            :to="getImageUrl(index)"
            replace
          >
          </router-link>
        </div>
      </div>

      <div v-else class="gallery-area-mobile">
        <div
          v-for="(img, index) in images"
          :key="img.fileName"
          class="full-screen-image-mobile-container"
        >
          <img
            :src="img.fileName"
            class="full-screen-image-mobile"
            :class="{
              'blurred-background-image': blurGalleryImages && index >= 2
            }"
          />
          <div v-if="blurGalleryImages && index >= 2" class="overlay"></div>
          <div v-if="blurGalleryImages && index >= 2" class="blur-msg-wrapper">
            <div
              class="blur-msg-click-container"
              @click="goToEditPropositionImages"
            >
              <BaseIcon icon-file="blur-img-eye" :width="48" :height="46" />

              <div class="blur-msg">
                <p class="unblock-text">
                  {{ $t('unlock_blur_msg') }}
                </p>
                <div class="upload-images-text">
                  <span
                    ><u>{{ $t('proposition_gallery_blur_link_msg') }}</u>
                  </span>
                  <span>
                    {{ $t('proposition_gallery_blur_msg') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PropositionGallery',

  head: {
    title() {
      return {
        inner: this.currentAddress || ''
      };
    }
  },

  computed: {
    ...mapGetters({
      currentProposition: 'propositions/currentProposition',
      isMobile: 'screenSize/isMobile',
      user: 'app/user',
      allowAccess: 'app/allowAccess',
      isAuthenticated: 'app/isAuthenticated',
      appReady: 'app/ready',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      blurGalleryImages: 'myPropositions/blurGalleryImages'
    }),

    currentImageIndex() {
      return this.$route.params.imageIndex || 0;
    },

    hasAccess() {
      return this.allowAccess || this.isAuthenticated;
    },

    previousIndex() {
      if (this.currentImageIndex == 0) {
        return this.images.length - 1;
      }

      return parseInt(this.currentImageIndex) - 1;
    },

    nextIndex() {
      if (this.currentImageIndex == this.images.length - 1) {
        return 0;
      }

      return parseInt(this.currentImageIndex) + 1;
    },

    residenceIndex() {
      let residenceIndex = this.$route.params.residenceIndex;

      if (residenceIndex) {
        return residenceIndex - 1;
      } else if (!residenceIndex) {
        return 0;
      } else {
        return residenceIndex;
      }
    },

    currentResidence() {
      if (
        !this.currentProposition ||
        !this.currentProposition.residences ||
        !this.currentProposition.residences.length > 0
      ) {
        return null;
      }

      return this.currentProposition.residences[this.residenceIndex];
    },

    currentAddress() {
      if (!this.currentResidence) {
        return '';
      }

      return this.currentResidence.streetAddress;
    },

    images() {
      if (this.currentResidence && this.currentResidence.images) {
        return this.currentResidence.images;
      }

      return [];
    },

    currentShownImage() {
      if (!this.currentResidence) {
        return '';
      } else if (this.currentResidence.images[this.currentImageIndex]) {
        return this.currentResidence.images[this.currentImageIndex].fileName;
      } else {
        this.changeMainImage(0);
        return '';
      }
    }
  },

  watch: {
    currentAddress() {
      this.$emit('updateHead');
    },
    appReady() {
      this.checkAccess();
    }
  },

  created() {
    this.checkAccess();

    this.hideMobileTabBar();

    this.setCurrentProposition({
      propositionId: this.$route.params.propositionId
    });

    this.setupKeyListeners();
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  beforeDestroy() {
    this.showMobileTabBar();
    window.removeEventListener('keyup', this.handleKeyPress);
  },
  methods: {
    ...mapActions({
      setCurrentProposition: 'propositions/setCurrentProposition',
      showMobileTabBar: 'menu/showMobileTabBar',
      hideMobileTabBar: 'menu/hideMobileTabBar'
    }),

    checkAccess() {
      if (!this.hasAccess && this.appReady) {
        window.location.replace('/');
      }
    },

    changeMainImage(index) {
      this.$router.replace(this.getImageUrl(index));
    },

    getImageUrl(index) {
      return `${this.$routes.PROPOSITION_GALLERY}/${this.currentProposition.propositionId}/${this.$route.params.residenceIndex}/${index}`;
    },

    handleKeyPress(event) {
      const key = event.which;
      switch (key) {
        case 37:
          this.changeMainImage(this.previousIndex);
          break;
        case 39:
          this.changeMainImage(this.nextIndex);
          break;
        default:
          break;
      }
    },

    setupKeyListeners() {
      window.addEventListener('keyup', this.handleKeyPress);
    },

    goToEditPropositionImages() {
      this.$router.push({
        path: `${this.$t('path_edit_proposition_photos', this.$routeLocale)}/${
          this.primaryPropositionId
        }/1`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.gallery-wrapper {
  width: 100vw;

  background-color: #fff;

  @media ($mobile) {
    // overflow-y: scroll;

    padding: 0;
    margin-top: -20px;
  }

  @media ($desktop) {
    position: fixed;
    padding-top: 0;
    top: 0;
    left: 0;
    z-index: 1001;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }
}

.header-area {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 5%;
  border-bottom: 1px solid rgba(28, 45, 65, 0.103);

  @media ($mobile) {
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    height: 60px;
    z-index: 1002;
  }
}

.address {
  margin-block-start: 0;
  margin-block-end: 0;
  position: relative;
  display: inline-block;
  font-size: 1.2rem;

  @media ($mobile) {
    padding-left: 20px;
  }

  a {
    position: relative;
    color: $text-primary;
    text-decoration: none;
    padding: 1rem;
  }
  span {
    position: relative;
  }

  span::after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    bottom: -5px;
    border-radius: 3px;
    height: 2px;
    background-color: $transfer-blue;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  a::before {
    position: absolute;
    content: '';
    background-size: contain;
    background-position: center;
    background-image: url('../../assets/svg/arrow-down-black.svg');
    background-repeat: no-repeat;
    width: 20px;
    left: -0.6rem;
    top: 50%;
    transform: translate(0px, -50%) rotate(90deg);
    height: 15px;
    transition: all 0.2s ease-in-out;
    will-change: transform;

    @media ($mobile) {
      left: -7px;
      bottom: 4px;
    }
  }

  @media ($desktop) {
    a:hover {
      span::after {
        opacity: 1;
      }

      &::before {
        transform: translate(-5px, -50%) rotate(90deg);
      }
    }
  }
}

.gallery-area-mobile {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: rgb(39, 39, 39);
  margin: 0 auto;
}

.gallery-area-desktop {
  display: flex;
  padding: 3% 5%;
}

.current-image-wrapper {
  width: 70%;
  max-width: 100%;
  min-height: 75vh;
  max-height: 75vh;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #f8f8fa;
  border-radius: 5px;
  z-index: 2;
  overflow: hidden;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    display: block;
  }
}

.current-image {
  max-width: inherit;
  object-fit: contain;
}

.thumbnails {
  max-height: 75vh;
  max-width: 30%;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.thumbnail {
  cursor: pointer;
  height: 12vh;
  width: 140px;
  margin: 0 10px 15px 10px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  position: relative;
  background-size: cover;
  z-index: 1;

  &.active {
    // box-shadow: 1px 1px 6px 6px rgba(34, 167, 240, 0.5);
    border: 3px solid $transfer-blue;
  }
}

.blur-thumbnail {
  filter: blur(8px);
}

.scroll {
  height: 36px;
  width: 36px;
  border: none;
  border-radius: 50%;
  top: calc(50% - 15px);
  position: absolute;
  background-color: #fff;
  background-size: 50%;
  background-position: center;
  background-image: url('../../assets/svg/arrow-down-black.svg');
  cursor: pointer;
  outline: none;
  background-repeat: no-repeat;

  &.left {
    left: 30px;
    transform: rotate(90deg);
    z-index: 2;
  }

  &.right {
    right: 30px;
    transform: rotate(-90deg);
    z-index: 1;
  }
}

.full-screen-image-mobile-container {
  padding: 0 10px;
  background-color: rgb(39, 39, 39);
  position: relative;
  overflow: hidden;

  &:first-of-type {
    padding-top: 10px;
  }

  @media ($mobile) {
    padding: 0;

    &:first-of-type {
      padding-top: 0;
    }
  }
}

.full-screen-image-mobile {
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000;

  &-container {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.blurred-background-image {
  opacity: 1;
  margin: -30px -60px -60px -30px;
  filter: blur(30px);
  -webkit-filter: blur(30px); /* Safari 6.0 - 9.0 */
  -webkit-backface-visibility: hidden; /* For backface visibility */
  backface-visibility: hidden; /* Standard */
  transform: translateZ(0); /* Hardware acceleration */
  -moz-filter: blur(30px);
  -o-filter: blur(30px);
  -ms-filter: blur(30px);

  @media ($mobile) {
    padding: 0;
    opacity: 1;

    &:first-of-type {
      padding-top: 0;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  @media ($mobile) {
    padding: 0;

    &:first-of-type {
      padding-top: 0;
    }
  }
}

.blur-msg-wrapper {
  position: absolute;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  @media ($mobile) {
    padding: 0;
    width: unset;
    height: unset;

    &:first-of-type {
      padding-top: 0;
    }
  }

  .blur-msg-wrapper-desktop {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
  }

  .blur-msg-click-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 26px;
    margin: 0 40px;
    cursor: pointer;

    p {
      margin: 0;
    }

    @media ($medium-screen) {
      width: 320px;
    }

    @media ($large-screen) {
      width: unset;
      margin: 0 60px;
    }
  }

  .blur-msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 18px;
    color: #fff;
    .unblock-text {
      font-weight: 600;
    }
    .upload-images-text {
      line-height: 150%;
    }
  }
}
</style>
