<template>
  <transition name="slide" appear>
    <div
      ref="container"
      class="menu"
      :class="{ 'full-height': !isAuthenticated }"
    >
      <template v-if="isAuthenticated && user">
        <UserCard :user-avatar="user.avatar" />

        <router-link
          v-if="$country.isFeatureEnabled('BANKID') && !user.verified"
          :to="$routes.EDIT_PROFILE"
          class="verify-action"
        >
          <BaseIcon
            icon="verified"
            :width="20"
            :height="20"
            class="verified-badge"
          />
          <span>Verifiera dig med BankID</span>
        </router-link>
      </template>

      <a v-else :href="$routes.GET_STARTED" class="full-width">
        <Banner />
      </a>

      <MenuItems />
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserCard from '../PopupMenuContent/UserCard';
import MenuItems from '../PopupMenuContent/MenuItems';
import Banner from '../PopupMenuContent/Banner';

export default {
  name: 'Menu',
  components: {
    UserCard,
    MenuItems,
    Banner
  },

  data() {
    return {
      startX: null
    };
  },

  computed: {
    ...mapGetters({
      user: 'app/user',
      isAuthenticated: 'app/isAuthenticated'
    })
  },
  mounted() {
    this.setUpSwipeability();
    this.showDarkOverlay();
  },

  beforeDestroy() {
    this.destroySwipeability();
  },

  destroyed() {
    this.hideDarkOverlay();
  },
  methods: {
    ...mapActions({
      closeMenu: 'menu/closeMenu',
      showDarkOverlay: 'overlay/show',
      hideDarkOverlay: 'overlay/hide'
    }),

    checkTouchStartX: function (e) {
      if (!e.touches[0]) return; // if no support

      this.startX = e.touches[0].screenX;
    },

    checkTouchEndX: function (e) {
      if (!e.changedTouches[0]) return; // if no support

      const endX = e.changedTouches[0].screenX;

      const movementX = this.startX - endX;

      if (movementX < -50) this.closeMenu();
    },

    setUpSwipeability: function () {
      const { container } = this.$refs;

      container.addEventListener('touchstart', this.checkTouchStartX, {
        passive: true
      });
      container.addEventListener('touchend', this.checkTouchEndX, {
        passive: true
      });
    },

    destroySwipeability: function () {
      const { container } = this.$refs;
      container.removeEventListener('touchstart', this.checkTouchStartX);
      container.removeEventListener('touchend', this.checkTouchEndX);
    }
  }
};
</script>

<style lang="scss" scoped>
.menu {
  height: 100vh;
  width: 80%;
  max-width: 320px;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  z-index: $mobile-menu-z-index;
}

.full-height {
  height: 100vh;
}

.full-width {
  width: 100%;
}

.slide-enter-active,
.slide-leave-active {
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease;
}

.slide-enter,
.slide-leave-active {
  transform: translateX(100%);
}

a {
  text-decoration: none;
}

.verify-action {
  display: flex;
  align-items: center;
  padding: 14px 12px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
  border: 1px solid #f1f2f6;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #2c3e50;
  width: calc(100% - 40px);
}

.verified-badge {
  margin-right: 6px;
}
</style>
