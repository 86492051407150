<template>
  <transition-group
    tag="div"
    name="search-cards-wrapper"
    class="search-cards-wrapper"
  >
    <slot></slot>
  </transition-group>
</template>

<script>
// component for wrapping search cards and controlling layout
export default {
  name: 'SearchCardsWrapper'
};
</script>

<style lang="scss" scoped>
.search-cards-wrapper {
  width: 100%;
  margin: 30px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media ($desktop) {
    flex-wrap: wrap;
  }

  @media ($mobile) {
    flex-direction: column;
  }
  @media ($ipad) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &-move {
    transition: all 600ms ease-in-out 600ms;
  }

  &-enter-active {
    transition: all 600ms ease-out;
  }

  &-leave-active {
    transition: all 600ms ease-in;
    position: absolute;
    z-index: 0;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
