<template>
  <div :class="['filter-section', { open: !collapsable || open }]">
    <div
      :class="['section-title', { open: !collapsable || open }]"
      @click.stop="toggleSlot"
    >
      {{ title }}
      <div v-if="collapsable" :class="['icon-cross', { open }]"></div>
    </div>
    <slot v-if="!collapsable || open" name="section-content" />
  </div>
</template>

<script>
export default {
  name: 'FilterSection',

  props: {
    title: {
      type: String,
      required: true
    },
    collapsable: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      open: false
    };
  },

  methods: {
    toggleSlot() {
      this.open = !this.open;
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-section {
  border-bottom: 1px solid rgba(28, 45, 65, 0.103);
  z-index: 0;

  &.open {
    padding-bottom: 20px;
  }
}
.section-title {
  cursor: pointer;
  padding: 20px 0;
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: $text-secondary;
  display: flex;
  justify-content: space-between;
  align-items: center;

  -webkit-tap-highlight-color: transparent;

  &.open {
    margin-bottom: 16px;
    padding-bottom: 10px;
  }
}

.icon-cross {
  height: 16px;
  width: 16px;
  background-size: contain;
  background-position: center;
  opacity: 0.5;
  background-image: url('../../../assets/svg/plus-skinny.svg');
  transition: all 0.2s ease-in-out;

  &.open {
    transform: rotate(45deg);
  }
}
</style>
