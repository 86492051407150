import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getLanguageFromCountry } from '@/plugins/country/module';

Vue.use(VueI18n);

// Import texts
import textsSV from './locales/sv-SE.json';
import textsNL from './locales/nl-NL.json';
import textsFR from './locales/fr-FR.json';
import textsDE from './locales/de-DE.json';
import textsDK from './locales/da-DK.json';

// Import paths
import pathsSE from './locales/paths/SE.json';
import pathsNL from './locales/paths/NL.json';
import pathsDE from './locales/paths/DE.json';
import pathsDK from './locales/paths/DK.json';

const messages = {
  sv: {
    ...textsSV,
    ...pathsSE
  },
  nl: {
    ...textsNL,
    ...pathsNL
  },
  fr: {
    ...textsFR
  },
  de: {
    ...textsDE,
    ...pathsDE
  },
  da: {
    ...textsDK,
    ...pathsDK
  }
};

const lang = getLanguageFromCountry();

document.documentElement.setAttribute('lang', lang);

export const routeLocale = lang;
Vue.prototype.$routeLocale = routeLocale;

export default new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  messages
});
