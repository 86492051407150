<template>
  <div v-if="notifications.length > 0" class="notifications-group">
    <slot name="heading" />
    <div
      v-for="notification in notifications"
      :key="notification.id"
      :class="['notification-item', !!notification.readAt ? '' : 'unread']"
      @click="() => $emit('notificationItemClick', notification)"
    >
      <div
        class="icon-container"
        :style="{ backgroundColor: getIconFromType(notification.type).bgcolor }"
      >
        <BaseIcon :icon="getIconFromType(notification.type).icon" />
      </div>
      <div class="body">
        <div class="message">
          <p v-html="notification.content"></p>
          <template v-if="notification.buttons">
            <BaseButton
              v-for="(button, i) in notification.buttons"
              :key="i"
              class="tiny message-button"
              >{{ button.text }}</BaseButton
            >
          </template>
        </div>
        <time class="timestamp">
          <BaseIcon
            icon="watch"
            :width="14"
            :height="14"
            class="timestamp-icon"
          />
          {{ formattedDate(notification.createdAt) }}
        </time>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationsGroup',
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    formattedDate(date) {
      return this.$dayjs.tz(date).fromNow();
    },
    getIconFromType(type) {
      switch (type) {
        case 'MESSAGE_REMINDER':
          return { icon: 'comment-question', bgcolor: '#409FFF' };
        case 'VIEW_SUMMARY':
        case 'VIEW_SUMMARY_TRIAL':
          return { icon: 'eye-white', bgcolor: '#C4C4C4' };
        case 'TRIAL':
        case 'OUTGOING_PROPOSITION_X':
          return { icon: 'clock-ten', bgcolor: '#FFC212' };
        case 'DIRECT_SWAP_100':
        case 'MULTIPLE_DIRECT_SWAP_100':
        case 'TRIANGLE_SWAP_100':
        case 'MULTIPLE_TRIANGLE_SWAP_100':
          return { icon: 'trophy', bgcolor: '#2EC973' };
        case 'INTEREST_MARKED':
          return { icon: 'comment-info', bgcolor: '#2EC973' };
        case 'DIRECT_SWAP_INACTIVE':
        case 'TRIANGLE_SWAP_INACTIVE':
          return { icon: 'clock-ten', bgcolor: '#2EC973' };
        case 'DIRECT_SWAP_0':
        case 'TRIANGLE_SWAP_0':
          return { icon: 'link-broken', bgcolor: '#2C3E50' };
        case 'PAYMENT_FAILED':
          return { icon: 'megaphone', bgcolor: '#FF3843' };
        default:
          return { icon: 'comment-info', bgcolor: '#409FFF' };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.notifications-group {
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.notification-item {
  display: flex;
  position: relative;
  padding: 10px 15px 10px 28px;
  margin-bottom: 10px;
  text-decoration: none;
  color: $text-primary;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-color: #e9efff;
  }
}

.notification-item.unread::before {
  content: '';
  display: block;
  position: absolute;
  top: 22px;
  left: 12px;
  width: 8px;
  height: 8px;
  background-color: $main-blue;
  border-radius: 8px;
}

.icon-container {
  display: grid;
  place-items: center;
  flex: 0 0 32px;
  height: 32px;
  background-color: #c4c4c4;
  border-radius: 50%;
}

.body {
  margin-left: 10px;
  margin-top: -1px;
}

.message p {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 18px;
}

.message-button {
  margin-bottom: 5px;
  margin-right: 5px;
}

.message ::v-deep strong,
.message ::v-deep b {
  font-weight: 600;
}

.timestamp {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: $text-secondary;
  letter-spacing: -0.02em;
}

.timestamp-icon {
  margin-right: 5px;
}
</style>
