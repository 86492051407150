<template>
  <div class="cancel-step-wrapper">
    <h2 class="title">{{ $t('used_to_find_swap_title') }}</h2>

    <div class="options-wrapper">
      <div
        v-for="reason in reasons"
        :key="reason.key"
        class="cancel-alternative"
        @click="handleCategoryClick(reason.key)"
      >
        {{ reason.title }}
      </div>
    </div>

    <link-button @click.native="closeFunction">{{
      $t('feedback_cancel_button')
    }}</link-button>
  </div>
</template>

<script>
import LinkButton from '../../Buttons/Link';

export default {
  name: 'ServiceUsedToFindSwap',

  components: {
    LinkButton
  },

  props: {
    closeFunction: {
      type: Function,
      required: true
    },
    goToStep: {
      type: Function,
      required: true
    },
    setSpecifiedCancelCategory: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      reasons: [
        {
          title: this.$t('used_to_find_swap_reason_1'),
          key: 'lagenhetsbyte'
        },
        {
          title: this.$t('used_to_find_swap_reason_2'),
          key: 'other-service'
        }
      ]
    };
  },

  methods: {
    handleCategoryClick(reason) {
      console.log(reason);
      if (reason === 'lagenhetsbyte') {
        this.setSpecifiedCancelCategory({ category: 'lagenhetsbyte' });
        this.goToStep({ step: 'congratulations' });
      } else {
        this.setSpecifiedCancelCategory({ category: 'other-service' });
        this.goToStep({ step: 'feedback', feedbackType: 'OTHER_SERVICE' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cancel-step-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  @media ($mobile) {
    padding-bottom: 50px;
  }
}

.title {
  color: $text-primary;
  @media ($mobile) {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  @media ($desktop) {
    margin-top: 40px;
    font-size: 1.8rem;
  }
}

.options-wrapper {
  margin: 30px 0;
  width: 100%;
  max-width: 500px;

  @media ($desktop) {
    margin: 40px 0;
  }
}

.cancel-alternative {
  cursor: pointer;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px 16px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  text-align: center;
  color: $text-primary;
  font-size: 1.1rem;
  font-weight: 600;

  transition: all 0.2s ease-in-out;

  @media ($desktop) {
    font-size: 1.2rem;
  }

  &:hover {
    background-color: $lighter-blue;
    color: white;
  }
}
</style>
