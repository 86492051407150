export const SMALL = '200x200';
export const MEDIUM = '400x400';
export const LARGE = '800x800';
export const XLARGE = '1600x1600';

export default {
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE
};
