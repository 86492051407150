var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RouterLink',{class:['search-card'],attrs:{"to":{
    query: {
      propositionPageId: _vm.proposition.propositionId
    }
  }}},[_c('div',{class:['image'],style:([
      _vm.proposition.residences[0].images.length
        ? {
            backgroundImage: ("url(" + (_vm.proposition.residences[0].images[0].fileName) + ")"),
            opacity: 1,
            backgroundSize: 'cover'
          }
        : ''
    ])},[_c('button',{class:['heart-btn', { 'heart-full': _vm.proposition.favourite }],on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavourite.apply(null, arguments)}}})]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"address"},[_c('div',{staticClass:"street-address"},[_vm._v(" "+_vm._s(_vm.proposition.residences[0].streetAddress)+" "),(_vm.proposition.verified)?_c('BaseIcon',{staticClass:"verified-badge",attrs:{"icon":"verified","width":18,"height":18}}):_vm._e()],1),(_vm.proposition.residences[1])?_c('span',[_vm._v("/"),_c('br'),_vm._v(_vm._s(_vm.proposition.residences[1].streetAddress))]):_vm._e()]),_c('div',{staticClass:"city"},[_vm._v(" "+_vm._s(_vm.proposition.residences[0].postalAddress)+" "),(_vm.proposition.residences[1])?_c('span',[_vm._v("/ "+_vm._s(_vm.proposition.residences[1].postalAddress))]):_vm._e()]),_c('div',{staticClass:"apartment-info"},[_c('p',{staticClass:"info-text info-icon room"},[_vm._v(" "+_vm._s(_vm.proposition.residences[0].rooms)+" "+_vm._s(_vm.$t('search_card_rooms'))+" ")]),_c('p',{staticClass:"info-text info-icon area"},[_vm._v(" "+_vm._s(_vm.proposition.residences[0].sqm)+" "+_vm._s(_vm.$t('search_card_sqm'))+" ")])]),_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.proposition.residences[0].rent)+_vm._s(_vm.$t('search_card_cost_per_month'))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }