function setAnimProgressBar(context, value) {
  context.commit('setAnimProgressBar', value);
}

function setAnimStatus(context, value) {
  context.commit('setAnimStatus', value);
}

function setAnimCurrentStep(context, value) {
  context.commit('setAnimCurrentStep', value);
}

function setIsSuccess(context, value) {
  context.commit('setIsSuccess', value);
}

export default {
  setAnimProgressBar,
  setAnimStatus,
  setAnimCurrentStep,
  setIsSuccess
};
