var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app",class:[
    { 'is-authenticated': _vm.isAuthenticated },
    { 'is-not-authenticated': !_vm.isAuthenticated },
    { 'is-freemium': _vm.primaryProposition.isFreemium },
    { 'is-trial': _vm.primaryProposition.isTrial },
    {
      'is-limited':
        _vm.primaryProposition.isFreemium || _vm.primaryProposition.isTrial
    }
  ],attrs:{"id":"app"}},[(!_vm.isMobile && _vm.showDesktopHeader && !_vm.isAppRequest)?_c('DesktopHeader'):_vm._e(),(_vm.isMobile && _vm.showMobileHeader && !_vm.isAppRequest)?_c('MobileHeader',{attrs:{"is-authenticated":_vm.isAuthenticated}}):_vm._e(),(
      _vm.primaryProposition.extraVisibility && !_vm.primaryPropositionIsPremiumOrFree
    )?_c('BoostBanner',{staticClass:"boost-banner-app"}):_vm._e(),(_vm.trialBannerOpen && !_vm.isAppRequest)?_c('TrialBanner'):_vm._e(),(
      _vm.conversionBannerOpen && _vm.primaryProposition.isFreemium && !_vm.isAppRequest
    )?_c('FreemiumBanner',{style:({ marginTop: _vm.freemiumBannerTopMargin })}):_vm._e(),(_vm.menuOpen && _vm.isMobile && !_vm.isAppRequest)?_c('MobileMenu'):_vm._e(),_c('transition',{attrs:{"name":_vm.transitionName,"mode":_vm.transitionMode}},[_c('router-view',{staticClass:"content"})],1),(_vm.isAuthenticated && _vm.appReady && _vm.showMobileTabbar && !_vm.isAppRequest)?_c('TabBar'):_vm._e(),_c('Toast'),(_vm.isImpersonated && !_vm.isMobile)?_c('ImpersonateBanner'):_vm._e(),(_vm.notificationCenterOpen && _vm.isMobile)?_c('NotificationCenterContainer'):_vm._e(),(_vm.showDarkOverlay)?_c('DarkOverlay'):_vm._e(),_c('CookieModal',{on:{"accepted-all-cookies":_vm.initGtm}}),_c('PageModalContainer'),(_vm.isVisible && _vm.proposition)?_c('StandardModal',{attrs:{"standard-modal-name":"ExtraVisibilityModal","no-shadow":true,"locked":!!_vm.$route.query.q},on:{"close":function($event){return _vm.setExtraVisibilityPaymentVisible(false)}}},[_c('ExtraVisibility',{attrs:{"slot":"content","proposition":_vm.proposition},slot:"content"})],1):_vm._e(),(_vm.showGlobalModal)?_c('GlobalModalsContainer'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }