<template>
  <div :class="['sponsored-propositions-cta', { flexible }]">
    <div class="content">
      <div class="text-content">
        <h4>{{ $t('boost_cta_title') }}</h4>
        <p>
          {{ $t('boost_cta_text_variant') }}
        </p>
      </div>
    </div>
    <BaseButton
      icon-file="icon-rocket-white"
      class="purple smaller cta-button"
      @click.stop="
        setExtraVisibilityPaymentProposition(primaryPropositionWithDetails);
        setExtraVisibilityPaymentVisible(true);
      "
      >{{ $t('boost_cta_button') }}</BaseButton
    >
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'SponsoredPropositionsCta',

  props: {
    // Horizontal scroll on desktop
    flexible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      primaryProposition: 'myPropositions/primaryProposition',
      userPropositions: 'myPropositions/propositions'
    }),

    primaryPropositionWithDetails() {
      return this.userPropositions.find(
        p =>
          p &&
          this.primaryProposition &&
          p.propositionId === this.primaryProposition.propositionId
      );
    }
  },

  methods: {
    ...mapMutations({
      setExtraVisibilityPaymentVisible: 'extraVisibility/setVisible',
      setExtraVisibilityPaymentProposition: 'extraVisibility/setProposition'
    })
  }
};
</script>

<style lang="scss" scoped>
.sponsored-propositions-cta {
  padding: 0 16px;
  position: relative;
  top: -73px;
  margin-bottom: -73px;

  @media ($mobile) {
    margin-right: -10px;
  }

  @media ($desktop) {
    &:not(.flexible) {
      padding: 0 25%;
    }
  }
}

.content {
  display: flex;
  background: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 36px;
  text-align: center;

  h4 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    margin: 0;
  }
}

.cta-button {
  display: flex !important;
  justify-content: center !important;
  position: relative;
  top: -20px;
  width: calc(100% - 118px);
  margin-left: 59px;
}
</style>
