<template>
  <div class="modal-content">
    <slot name="icon" />
    <h3 class="heading">
      <slot name="title" />
    </h3>
    <div v-if="$slots.subtitle" class="subtitle">
      <slot name="subtitle" />
    </div>
    <div class="text-content">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyModalContentNew'
};
</script>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon {
  margin-bottom: 26px;
}

.heading {
  color: #2c3e50;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: -0.48px;
  margin-top: 0;
  margin-bottom: 26px;
}

.subtitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  color: #878b9d;
  margin-bottom: 26px;
}

.text-content {
  margin-bottom: 34px;
}

.text-content p {
  margin-top: 0;
  line-height: 20px;
  margin-bottom: 0;
}

.text-content ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  list-style-type: none;
}

.text-content li {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.text-content li::before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-image: url('@/assets/svg/blue-check.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.text-content ::v-deep strong {
  font-weight: 600;
  color: #003384;
}

.text-content ::v-deep b {
  font-weight: 600;
}
</style>
