<template>
  <div>
    <section class="pay-proposition">
      <h1 class="title">{{ $t('payment_new_title') }}</h1>
      <BasePaymentNew
        :product="selectedProduct"
        :additional-products="selectedAdditionalProducts"
        :proposition-id="currentPropositionId"
        @loading="isLoading = $event"
        @success="handleSuccessfulPayment($event)"
      >
        <div v-if="$country.isFeatureEnabled('TRUSTPILOT')" class="trustbox">
          <TrustBox type="Horizontal"></TrustBox>
        </div>

        <div class="usp-wrapper">
          <div class="usp-title">{{ $t('payment_new_usp_title') }}</div>
          <ul>
            <li>{{ $t('payment_new_usp1') }}</li>
            <li>{{ $t('payment_new_usp2') }}</li>
            <li v-if="$country.isFeatureEnabled('SWAP_APPLICATION')">
              {{ $t('payment_new_usp3') }}
            </li>
            <li>{{ $t('payment_new_usp4') }}</li>
            <li>{{ $t('payment_new_usp5') }}</li>
          </ul>
        </div>
      </BasePaymentNew>
    </section>

    <Spinner
      v-if="isLoading || !currentProposition || !appReady"
      :full-screen="true"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import BasePaymentNew from '@/components/Payments/BasePaymentNew';
import Spinner from '@/components/Loading/Spinner';
import TrustBox from '@/components/TrustBox/TrustBox.vue';

export default {
  components: {
    BasePaymentNew,
    Spinner,
    TrustBox
  },

  data() {
    return {
      isLoading: true,
      warning: null
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('pay_proposition_activate_for')
      };
    }
  },

  computed: {
    ...mapGetters({
      propositions: 'myPropositions/propositions',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryProposition: 'myPropositions/primaryProposition',
      isLoadingUserPropositions: 'myPropositions/fetchingPropositions',
      isMobile: 'screenSize/isMobile',
      appReady: 'app/ready',
      selectedProduct: 'klarnaPayments/getSelectedProduct',
      selectedAdditionalProducts:
        'klarnaPayments/getSelectedAdditionalProducts',
      isAppRequest: 'app/isAppRequest'
    }),

    currentProposition() {
      if (
        !this.appReady ||
        this.isLoadingUserPropositions ||
        !this.$route.params.propositionId
      ) {
        return null;
      }

      const current = this.propositions.find(
        p => p.propositionId == this.propositionId
      );

      if (!current) {
        this.redirect(this.$t('path_my_propositions', this.$routeLocale));
        throw Error('Proposition not found');
      }

      if (current.inFreeArea) {
        this.redirect(
          `${this.$t('path_handle_subscription', this.$routeLocale)}/${
            current.propositionId
          }`
        );
      }

      if (current.pausedAt !== null) {
        this.redirect(this.$t('path_my_propositions', this.$routeLocale));
      }

      return current;
    },

    propositionId() {
      if (!this.$route.params.propositionId) {
        return '';
      }

      return this.$route.params.propositionId.replace(/^\D+/g, '');
    },

    currentPropositionId() {
      if (this.currentProposition) {
        return this.currentProposition.propositionId;
      }

      return null;
    }
  },

  watch: {
    '$route.params.propositionId'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchProducts({
          propositionId: newVal,
          productType: 'proposition'
        });
      }
    }
  },

  async created() {
    window.scrollTo(0, 0);

    if (this.currentProposition) {
      const { active, inFreeArea, isFreemium, isTrial } =
        this.currentProposition;

      if ((active && !isFreemium && !isTrial) || inFreeArea) {
        // The proposition is already paid for and is active, redirect to start
        this.redirect(this.$t('path_my_propositions', this.$routeLocale));
        return;
      }
    }

    // Redirect if no propositionId param
    if (!this.$route.params.propositionId && this.primaryPropositionId) {
      this.redirect(this.$t('path_my_propositions', this.$routeLocale));
    } else if (!this.$route.params.propositionId && !this.primaryPropositionId)
      // Redirect if no proposition param and no primary proposition
      this.redirect(this.$t('path_my_propositions', this.$routeLocale));
    else this.init();
  },

  destroyed() {
    this.temporaryHideTrialBanner({ hide: false });
  },

  methods: {
    redirect(url) {
      if (this.isAppRequest) {
        window.location.replace(url);
      } else {
        this.$router.replace(url);
      }
    },

    ...mapMutations({
      removeTrialData: 'trial/removeTrialData'
    }),

    ...mapActions({
      trackPaymentPageVisited: 'tracking/trackPaymentPageVisited',
      temporaryHideTrialBanner: 'trial/temporaryHideTrialBanner',
      fetchProducts: 'klarnaPayments/fetchProducts',
      setSelectedProductId: 'klarnaPayments/selectProductId',
      paymentSuccess: 'payment/success'
    }),

    handleSuccessfulPayment({ propositionId, product }) {
      this.removeTrialData();
      this.paymentSuccess({
        propositionId,
        product,
        notify: false,
        redirect: true
      });
    },

    async init() {
      this.isLoading = true;

      this.setSelectedProductId({ productId: null });
      await this.fetchProducts({
        propositionId: this.propositionId,
        productType: 'proposition'
      });

      this.temporaryHideTrialBanner({ hide: true });

      this.trackPaymentPageVisited({
        propositionId: this.propositionId
      });

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.pay-proposition {
  margin: 0 auto;
  max-width: 600px;
  padding-bottom: 80px;

  @media ($mobile) {
    padding: 20px;
    padding-bottom: 120px;
  }
}

.title {
  margin: auto;
  font-size: 26px;
  line-height: 32px;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;

  @media ($desktop) {
    font-size: 28px;
    margin-top: 0;
  }
}

.subtitle {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: #878b9d;
  font-size: 16px;
  line-height: 1.3;
  max-width: 400px;
  padding: 0 20px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.trustbox {
  margin-top: -8px;
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.usp-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 35px 20px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}

.usp-title {
  position: absolute;
  top: -11px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  white-space: nowrap;
}

.usp-wrapper ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  list-style-type: none;
}

.usp-wrapper li {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.usp-wrapper li::before {
  content: '';
  flex-shrink: 0;
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-image: url('@/assets/svg/blue-check.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
