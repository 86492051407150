import regex from './helpers/regex';

function validateSsn(value) {
  let valid = true;

  //  Check for correct length, remove characters other than numbers
  const onlyDigits = value.replace(/\D/g, '');
  if (onlyDigits.length !== 10 && onlyDigits.length !== 12) {
    valid = false;
  }

  //  Check for only numbers & dashes
  if (!/^[-0-9]*$/gm.test(value)) {
    valid = false;
  }

  return valid;
}

function validateEmail(value) {
  let valid = true;

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(value)) {
    valid = false;
  }

  return valid;
}

function validatePostalCode(value) {
  let valid = true;

  const re = /[0-9]/g;

  if (!re.test(value) || value.length !== 5) {
    valid = false;
  }

  return valid;
}

function validatePassword(value) {
  return Boolean(value && value.length < 5);
}

function required(value) {
  return Boolean(value);
}

function validatePhone(value) {
  const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return regex.test(value);
}

function validateResidenceDescription(value) {
  if (value === null) return false;

  // Remove all whitespaces from the value
  const strippedValue = value.replace(/\s+/g, '');

  let hasEmail = false;
  let hasNumber = false;

  // Check for email patterns
  if (strippedValue.match(regex.email)) {
    hasEmail = true;
  }

  // Check for phone number patterns
  if ([...strippedValue.matchAll(regex.phone)].length > 0) {
    hasNumber = true;
  }

  return hasEmail || hasNumber;
}

function validateNumber(value) {
  return /^\d+$/.test(value);
}

export default {
  ssn: value => validateSsn(value),
  email: value => validateEmail(value),
  zip: value => validatePostalCode(value),
  password: value => validatePassword(value),
  required: value => required(value),
  phone: value => validatePhone(value),
  number: value => validateNumber(value),
  residenceDescription: value => validateResidenceDescription(value),
  none: () => true
};
