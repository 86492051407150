<template>
  <div
    :style="{
      'background-image': `url(${content.BackgroundImage.formats.large.url})`
    }"
    class="hero"
  >
    <div class="hero-text">
      <h1>{{ content.Title }}</h1>
      <p>{{ content.Description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.hero {
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  grid-gap: 16px;
  margin-bottom: 16px;
  height: calc(100vh - 116px);
  background-size: cover;
  position: relative;

  @media ($desktop) {
    height: calc(100vh - 79px);
  }

  img {
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(55, 48, 36, 0.4);
  }
}

.hero-text {
  margin-top: -5%;
  text-align: center;
  color: #fff;
  max-width: 1190px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
  z-index: 1;

  h1 {
    font-family: $font-headings;
    font-size: 32px;
    line-height: 1.2;

    @media ($desktop) {
      font-size: 64px;
      padding: 0 40px;
    }
  }

  p {
    font-weight: 400;
    font-size: 18px;
    padding: 0 5%;
    line-height: 1.2;

    @media ($desktop) {
      font-size: 24px;
      padding: 0 20%;
    }
  }
}
</style>
