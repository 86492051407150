<template>
  <div
    :class="['full-width container', isLoading ? 'item-loading' : '']"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <div
      class="item-wrapper"
      :class="[
        { toned: toned, marked: marked, 'list-settings': isPartOfList },
        mode
      ]"
    >
      <router-link
        v-if="residencePickerEnabled"
        v-slot="{ href, navigate }"
        :to="link"
        custom
        class="full-width"
        :class="mode"
      >
        <a :href="href" @click="handleClick(navigate, $event)">
          <transition name="fade-text" mode="out-in">
            <MSearchListItemAd
              :key="selectedResidence"
              :index="0"
              :ad="item.residences[selectedResidence]"
              :item="item"
              :toned="toned"
              :mode="mode"
              @like="$emit('like', $event)"
              @likeDone="$emit('likeDone', $event)"
            />
          </transition>
        </a>
      </router-link>

      <router-link
        v-else
        v-slot="{ href, navigate }"
        :to="link"
        class="full-width"
        :class="mode"
        custom
      >
        <a :href="href" @click="handleClick(navigate, $event)">
          <transition name="fade" mode="out-in">
            <MSearchListItemAd
              v-for="(ad, index) in item.residences"
              :key="item.propositionId + index"
              :index="index"
              :ad="ad"
              :item="item"
              :toned="toned"
              :mode="mode"
              @like="$emit('like', $event)"
            />
          </transition>
        </a>
      </router-link>
    </div>
    <div
      v-if="residencePickerEnabled"
      class="proposition-picker-wrapper"
      :class="[
        { 'list-settings': isPartOfList, 'none-list-settings': !isPartOfList },
        mode
      ]"
    >
      <span class="proposition-picker-text"
        >{{ $t('search_two_to_one_picker') }}
      </span>
      <AButton
        :is-dark="selectedResidence === 0"
        :is-default="selectedResidence !== 0"
        :is-small="true"
        @click="selectedResidence = 0"
        >1</AButton
      >
      <AButton
        :is-dark="selectedResidence === 1"
        :is-default="selectedResidence !== 1"
        :is-small="true"
        :hover-effect="false"
        @click="selectedResidence = 1"
        >2</AButton
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MSearchListItemAd from './m-search-list-item-ad';
import AButton from '@/components/atomic/atoms/a-button';

export default {
  name: 'MSearchListItem',

  components: {
    MSearchListItemAd,
    AButton
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    toned: {
      type: Boolean,
      default: false
    },

    marked: {
      type: Boolean,
      default: false
    },

    mode: {
      type: String,
      default: 'list-desktop'
      // Modes: list-desktop, card-desktop, list-mobile, card-mobile,
    },

    isPartOfList: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedResidence: 0
    };
  },

  computed: {
    residencePickerEnabled() {
      return this.item.swapType == 21;
    },

    link() {
      return `${this.$routes.PROPOSITION}/${this.item.propositionId}`;
    },

    ...mapGetters({
      data: 'search/data',
      isMobile: 'screenSize/isMobile'
    })
  },

  methods: {
    handleClick(navigate, event) {
      this.$emit('click', parseInt(this.item.propositionId));
      navigate(event);
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: unset;
}

.item-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
}

.item-wrapper.list-settings:hover {
  background-color: $form-background-dark;
}

.item-wrapper.list-settings {
  margin-bottom: 5px;

  @media ($mobile) {
    margin-bottom: 20px;
  }
}

.marked {
  background-color: $form-background-dark;
}

.item-wrapper.list-desktop.list-settings {
  margin-bottom: 5px;
}

.item-wrapper.card-desktop.list-settings {
  margin-bottom: 10px;
}

.item-wrapper.list-mobile.list-settings,
.item-wrapper.card-mobile.list-settings {
  margin-bottom: 20px;
}

.full-width {
  width: 100%;
}

.proposition-picker-text {
  font-size: 12px;
  font-weight: 700;
  color: $brand-dark;
}

.proposition-picker-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  margin-top: 5px;
}

.proposition-picker-wrapper.list-settings {
  margin-bottom: 40px;
}

.proposition-picker-wrapper.card-desktop.list-settings {
  margin-bottom: 20px;
}

.proposition-picker-wrapper > * {
  margin-right: 10px;
}

.proposition-picker-wrapper.none-list-settings {
  background: rgba(241, 242, 246, 0.99);
  padding: 10px 0 10px 0;
}

.container {
  transition: opacity 0.1s ease;
}

.item-loading {
  opacity: 0.2;
  pointer-events: none;
}
</style>
