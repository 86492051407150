import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const START_ANIM_STATUS_TYPES = {
  STARTED: 'started',
  COMPLETED: 'completed'
};

const state = {
  currentStep: 1,
  isSuccess: false,
  statusTexts: [
    'start_step_1_status',
    'start_step_2_status',
    'start_step_3_status'
  ],
  imageSizes: ['lg', 'md', 'sm'],

  exampleFlatImagesSet1: [
    // {
    //   size: 'lg',
    //   state: 'dimmed',
    //   image: require('@/assets/img/example_flats/example_flat_1.png')
    // },
    // {
    //   size: 'md',
    //   state: 'dimmed',
    //   image: require('@/assets/img/example_flats/example_flat_2.png')
    // },

    {
      size: 'lg',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_3.png')
    },

    {
      size: 'sm',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_4.png')
    },

    {
      size: 'sm',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_5.png')
    },
    {
      size: 'lg',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_5.png')
    },
    {
      size: 'sm',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_6.png')
    },
    {
      size: 'md',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_7.png')
    },
    {
      size: 'lg',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_8.png')
    },
    {
      size: 'sm',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_9.png')
    },
    {
      size: 'lg',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_10.png')
    },
    {
      size: 'md',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_11.png')
    }
  ],
  exampleFlatImagesSet2: [
    // {
    //   size: 'md',
    //   state: 'dimmed',
    //   image: require('@/assets/img/example_flats/example_flat_12.png')
    // },
    // {
    //   size: 'lg',
    //   state: 'dimmed',
    //   image: require('@/assets/img/example_flats/example_flat_13.png')
    // },
    {
      size: 'sm',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_14.png')
    },
    {
      size: 'sm',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_15.png')
    },
    {
      size: 'md',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_16.png')
    },
    {
      size: 'lg',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_17.png')
    },
    {
      size: 'md',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_18.png')
    },
    {
      size: 'md',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_19.png')
    },
    {
      size: 'lg',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_20.png')
    },
    {
      size: 'sm',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_21.png')
    },
    {
      size: 'lg',
      state: 'dimmed',
      image: require('@/assets/img/example_flats/example_flat_22.png')
    }
  ],

  progressBar: {
    start: 0,
    percentage: 0,
    animDurationSec: 6
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
