import { getLanguageFromCountry, getValue } from '@/plugins/country/module';

export function formatCurrency(value, showSign = true) {
  if (value === null || value === undefined) value = 0;

  if (typeof value !== 'number') {
    value = value.toString().replace(/[^0-9]/g, '');
    value = parseFloat(value);
  }

  if (showSign) {
    return new Intl.NumberFormat(getLanguageFromCountry(), {
      style: 'currency',
      currency: getValue('CURRENCY'),
      minimumFractionDigits: 0
    }).format(value);
  }

  return new Intl.NumberFormat(getLanguageFromCountry(), {
    minimumFractionDigits: 0
  }).format(value);
}
