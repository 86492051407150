import { SearchFilter } from '@/models/search-filter';

export default {
  isLoading: false,
  isLoadingCoverage: false,
  data: null,
  filter: new SearchFilter(),
  activeFilterKeys: [],
  coverageData: null,
  searchBottomBarActive: false
};
