<template>
  <div v-if="showModal" class="getstarted-modal-wrapper">
    <transition name="tut-slide-up" appear>
      <div v-if="showModalContent" class="modal-content">
        <div class="modal-content-inner">
          <div class="top-section">
            <span
              v-if="showCloseButton"
              class="close-button"
              @click="$emit('close')"
            >
              <BaseIcon icon-file="close-cross-alt" class="close" />
            </span>
          </div>
          <div class="container">
            <slot />
            <div class="modal-header">
              <h4>
                {{
                  tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED
                    ? $t('tutorial_contextual_completed_success_title')
                    : $t('tutorial_contextual_get_started_title')
                }}
              </h4>
            </div>
            <div class="modal-body">
              <Confetti v-if="tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED" />
              <p
                v-html="
                  tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED
                    ? $t('tutorial_contextual_completed_success_description')
                    : $t('tutorial_contextual_get_started_description')
                "
              ></p>
            </div>
            <div class="modal-footer">
              <CtaButton @click="handleCloseOrNext">
                {{
                  tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED
                    ? $t('tutorial_contextual_button_continue')
                    : $t('tutorial_contextual_button_start')
                }}
              </CtaButton>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CtaButton from '@/components/Buttons/CtaButton';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Confetti from '../../ContextualTutorial/Confetti.vue';
import {
  TUTORIAL_STATUS_TYPES,
  TUTORIAL_STEPS_TYPES
} from '../../../store/modules/tutorial';

export default {
  name: 'ContextualTutModal',

  components: {
    CtaButton,
    Confetti
  },

  props: {
    showCloseButton: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showModalContent: false,
      showModal: false,
      TUTORIAL_STEPS_TYPES,
      TUTORIAL_STATUS_TYPES
    };
  },

  computed: {
    ...mapGetters({
      progressBar: 'tutorial/progressBar',
      tutStatus: 'tutorial/status',
      tutActiveStep: 'tutorial/activeStep'
    })
  },

  created() {
    const portrait = window.matchMedia('(orientation: portrait)');
    if (portrait.matches) {
      this.addDisableScroll('ContextualTutModal');
    }
  },

  mounted() {
    this.showModal = true;
    this.showModalContent = true;

    window.addEventListener('resize', this.onPortraitChange);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onPortraitChange);
  },

  methods: {
    ...mapActions({
      setTutActiveStep: 'tutorial/setTutActiveStep'
    }),

    ...mapMutations({
      addDisableScroll: 'ui/addDisableScroll',
      removeDisableScroll: 'ui/removeDisableScroll'
    }),

    onPortraitChange() {
      const portrait = window.matchMedia('(orientation: portrait)');

      if (
        this.tutActiveStep === TUTORIAL_STEPS_TYPES.HIGHLIGHT_SWAP ||
        this.tutActiveStep === TUTORIAL_STEPS_TYPES.FIRST_INTEREST
      ) {
        if (portrait.matches) {
          this.addDisableScroll('ContextualTutModal');
        } else {
          this.removeDisableScroll('ContextualTutModal');
        }
      }
      window.scrollTo(0, 0);
    },

    handleCloseOrNext() {
      if (this.tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED) {
        this.removeDisableScroll('ContextualTutModal');
        this.setTutActiveStep({ step: TUTORIAL_STEPS_TYPES.TUTORIAL_SUCCESS });
        this.$emit('completed');
        return;
      }

      this.showModalContent = false;
      this.$emit('nextHighlightSwap');
    }
  }
};
</script>

<style lang="scss">
.getstarted-modal-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(167deg, rgba(64, 159, 255, 0) 0%, #409fff 92.1%);
  z-index: 2;

  .modal-content {
    position: absolute;
    background-color: #ffffff;
    display: flex;
    min-height: 500px;
    height: 100%;
    top: 145px;
    width: 100%;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    justify-content: center;
    bottom: 0;

    .modal-content-inner {
      align-items: center;
    }

    @media only screen and (orientation: landscape) {
      overflow: auto;

      .modal-svg-get-started,
      .modal-svg-completed-success {
        background-image: url('@/assets/svg/tutorial-getstarted.svg');
        background-repeat: no-repeat;
        width: 148px;
        height: 141px;
      }
    }

    @media ($desktop) {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 80%;
      top: initial;
      max-width: 700px;
      max-height: 500px;
      border-radius: 32px;
      overflow: hidden;
    }

    @media ($mobile) {
      /* Smaller screens */
      @media only screen and (max-width: 375px) {
        top: 110px;
        .top-section {
          position: absolute;
        }
      }
      /* Landscape */
      @media only screen and (min-width: 480px) {
        top: -60px;
        height: 110%;
      }
    }

    .modal-content-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      min-width: 200px;
      padding-top: 30px;

      .top-section {
        width: 100%;
        display: flex;
        height: 40px;
        padding-right: 30px;
        align-items: center;

        .close-button {
          margin-top: 20px;
          margin-left: auto;

          .close {
            cursor: pointer;
            height: 16px;
            width: 16px;
          }
        }
      }

      .modal-header {
        h4 {
          font-size: 1.5em;
          color: #213a75;
          margin: 0;
          padding: 0;
        }
      }

      .modal-body {
        text-align: center;
        margin-bottom: 10px;
      }

      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 600px;
        min-width: 280px;
        margin-left: 40px;
        margin-right: 40px;
      }
    }
  }
}
</style>
