<template>
  <div class="wrapper">
    <div class="title">
      {{ $t('residence_switcher_desktop_switch_apt') }}
    </div>
    <div class="switcher-wrapper">
      <div
        :class="['residence', { active: currentResidenceIndex === 0 }]"
        @click="() => setCurrentResidence({ index: 0 })"
      >
        <div
          class="residence-image"
          :style="[
            currentProposition.residences[0].images.length
              ? {
                  backgroundImage: `url(${currentProposition.residences[0].images[0].fileName})`,
                  opacity: 1,
                  backgroundSize: 'cover'
                }
              : ''
          ]"
        />
        <div class="residence-info">
          <div class="residence-city">
            {{ currentProposition.residences[0].postalAddress }}
          </div>
          <div class="residence-address">
            {{ currentProposition.residences[0].streetAddress }}
          </div>
        </div>
      </div>
      <div
        :class="['residence', { active: currentResidenceIndex === 1 }]"
        @click="() => setCurrentResidence({ index: 1 })"
      >
        <div
          class="residence-image"
          :style="[
            currentProposition.residences &&
            currentProposition.residences[1] &&
            currentProposition.residences[1].images &&
            currentProposition.residences[1].images.length
              ? {
                  backgroundImage: `url(${currentProposition.residences[1].images[0].fileName})`,
                  opacity: 1,
                  backgroundSize: 'cover'
                }
              : ''
          ]"
        />
        <div
          v-if="
            currentProposition.residences && currentProposition.residences[1]
          "
          class="residence-info"
        >
          <div class="residence-city">
            {{ currentProposition.residences[1].postalAddress }}
          </div>
          <div class="residence-address">
            {{ currentProposition.residences[1].streetAddress }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesktopResidenceSwitcher',

  props: {
    setCurrentResidence: {
      type: Function,
      required: true
    },
    currentResidenceIndex: {
      type: Number,
      required: true
    },
    currentProposition: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px solid rgba(28, 45, 65, 0.103);
}
.title {
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.switcher-wrapper {
  width: 100%;
  display: flex;
}

.residence {
  cursor: pointer;
  padding: 10px;
  background-color: #ffffff;
  border-radius: $small-box-radius;
  display: flex;
  align-items: center;
  margin-right: 20px;
  opacity: 0.6;
  transition: all 0.2s ease-in-out;

  &.active {
    opacity: 1;
    background-color: #f1f2f6;
  }

  &-image {
    height: 50px;
    min-width: 50px;
    margin-right: 15px;
    border-radius: $small-box-radius;
    background-color: #dddcdb;
    background-size: contain;
    background-position: center;
    background-image: url('../../assets/svg/placeholder-img-small.svg');
  }

  &-address {
    font-weight: 700;
    font-size: 0.95rem;
  }

  &-city {
    color: $text-primary;
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 4px;
  }
}

.info-content {
  padding: 20px 12px;
  font-size: 1.1rem;
  color: $text-primary;
  font-weight: 600;
  line-height: 150%;
}

.residence-switch-info {
  font-size: 0.85rem;
  line-height: 150%;
  text-align: start;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
}
</style>
