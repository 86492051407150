<template>
  <div class="page-root">
    <div class="terms-wrapper">
      <h1>{{ $t('cookie_policy_title') }}</h1>
      <div class="terms-section">
        <div>
          <h2>{{ $t('cookies_what_info_title') }}</h2>
          <p v-html="$t('cookies_what_info')"></p>
        </div>

        <div>
          <h2>{{ $t('cookies_why_info_title') }}</h2>
          <p v-html="$t('cookies_why_info')"></p>
        </div>

        <div>
          <h2>{{ $t('decline_cookies_info_title') }}</h2>
          <p v-html="$t('decline_cookies_info')"></p>
        </div>

        <div class="cookie-settings-wrapper">
          <BaseButton
            class="cookie-settings-btn"
            @click="setCookiesModal(true)"
          >
            {{ $t('change_cookie_settings') }}
          </BaseButton>
        </div>
      </div>
    </div>

    <TheFooter :is-authenticated="isAuthenticated" />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import TheFooter from '@/components/TheFooter';

export default {
  name: 'CookiePolicy',

  components: {
    TheFooter
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    })
  },

  created() {
    this.setCookiesModal(false);
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    ...mapMutations({
      setCookiesModal: 'ui/setCookiesModal'
    })
  }
};
</script>

<style lang="scss" scoped>
.terms-wrapper {
  max-width: 900px;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 16px;

  font-size: 16px;
  line-height: 1.5;

  h1 {
    margin-top: 0px;
  }
}

.page-root {
  max-width: none;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 95px;

  @media ($desktop) {
    padding-bottom: 0;
    margin-top: 0;
  }
}
</style>
