<template>
  <OSearch />
</template>

<script>
import OSearch from '@/components/atomic/organisms/o-search';
export default {
  components: { OSearch },
  data() {
    return {};
  },
  head: {
    title() {
      return {
        inner: this.$t('meta_title_search')
      };
    }
  }
};
</script>
