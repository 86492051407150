<template>
  <transition-group name="swap-list" tag="div" class="swaps-container">
    <div
      v-for="(swap, index) in swapList"
      :key="swap.propositionId + swap.details.type"
      :ref="swap.propositionId + swap.details.type"
      class="swap-items-wrapper"
    >
      <div
        v-if="index === 0"
        :hidden="
          tutActiveStep === null ||
          tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED
        "
        :class="
          shouldRevealProgressBar && showContextualTutorial
            ? 'reveal-progress'
            : ''
        "
      >
        <div
          v-outside-click="handleClickOutsideProgress"
          class="progress-bar-wrapper"
          :style="{
            visibility: !isShowingSwapModal ? 'visible' : 'hidden',
            opacity: !isShowingSwapModal ? 1 : 0
          }"
        >
          <ProgressBar ref="progressBar" :percentage="progressBar" />

          <transition
            v-if="shouldRevealProgressBar && showContextualTutorial"
            name="tooltip-slide-up"
            appear
          >
            <div class="tooltip-container">
              <BaseTooltip @callback="handleTooltipCallback">{{
                $t('tutorial_contextual_continue_interest')
              }}</BaseTooltip>
              <BaseButton
                v-if="showTooltipButton"
                class="smaller next-button"
                @click="handleClickOutsideProgress"
              >
                <slot name="button">
                  {{ $t('tutorial_contextual_button_next') }}
                </slot>
              </BaseButton>
            </div>
          </transition>
        </div>
      </div>
      <BasePropositionCardNew
        v-if="isMobile && swap.type !== 'sponsoredPropositions'"
        :key="swap.propositionId + swap.details.type"
        :show-additional-images="!tutorialStarted"
        :show-tutorial-pointer="tutorialStarted && index === 0"
        :class="shouldRevealSwap && index === 0 ? 'reveal' : ''"
        :swap="swap"
        :disable-link="shouldDisableLink"
        :index="index"
        :is-blurred="
          (primaryProposition.isFreemium || primaryProposition.isTrial) &&
          swapFilterType === 'LAST_PART'
        "
        :show-interest-buttons-block="
          !(
            (primaryProposition.isFreemium || primaryProposition.isTrial) &&
            swapFilterType === 'LAST_PART'
          )
        "
      />
      <component
        :is="currentSwapItemComponent"
        v-if="!isMobile && swap.type !== 'sponsoredPropositions'"
        :key="swap.propositionId + swap.details.type"
        :show-tutorial-pointer="tutorialStarted && index === 0"
        :class="shouldRevealSwap && index === 0 ? 'reveal' : ''"
        :show-swap-details="handleShowSwapDetails"
        :swap="swap"
        :disable-link="shouldDisableLink"
        :toned="index % 2 !== 0"
        :blur-content="
          (primaryProposition.isFreemium || primaryProposition.isTrial) &&
          swapFilterType == 'LAST_PART'
        "
      />

      <SponsoredPropositions
        v-if="
          $country.isFeatureEnabled('EXTRA_VISIBILITY') &&
          sponsoredPropositions.length &&
          swap.type === 'sponsoredPropositions'
        "
        :propositions="sponsoredPropositions"
        :title="$t('proposition_extra_visibility_title')"
        class="sponsored-propositions"
      />
    </div>
  </transition-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import outsideClick from '@/directives/outside-click';
import {
  SORT_TYPES,
  SWAP_ITEM_TYPES,
  SWAP_FILTER_TYPES
} from '../../store/modules/swapList';
import SwapItem from './SwapItem';
import CompressedSwapItem from './CompressedSwapItem';
import StandardModal from '@/components/Modals/Standard';
import SponsoredPropositions from '@/components/SponsoredPropositions/SponsoredPropositions';
import ProgressBar from '@/components/ContextualTutorial/ProgressBar';
import BaseTooltip from '../BaseTooltip.vue';
import {
  TUTORIAL_STATUS_TYPES,
  TUTORIAL_STEPS_TYPES
} from '../../store/modules/tutorial';

export default {
  name: 'SwapContainer',

  components: {
    BaseTooltip,
    SwapItem,
    StandardModal,
    CompressedSwapItem,
    SponsoredPropositions,
    ProgressBar
  },

  directives: {
    outsideClick
  },

  props: {
    usedIn: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      sponsoredPosition: 11,
      SORT_TYPES,
      SWAP_ITEM_TYPES,
      SWAP_FILTER_TYPES,
      extraVisModalOpen: false,
      SwapItem,
      CompressedSwapItem,
      highlightProgress: false,
      TUTORIAL_STATUS_TYPES,
      TUTORIAL_STEPS_TYPES,
      allowClickOutside: false,
      tooltipTimeout: null,
      tooltipTimeoutMs: 5500,
      showTooltipButton: false,
      isShowingSwapModal: false
    };
  },

  computed: {
    ...mapGetters({
      swaps: 'swapList/swaps',
      premiumSwaps: 'swapList/premiumSwaps',
      isMobile: 'screenSize/isMobile',
      sponsoredPropositions: 'propositions/sponsoredPropositions',
      primaryProposition: 'myPropositions/primaryProposition',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      userPropositions: 'myPropositions/propositions',
      swapItemMode: 'swapList/swapItemMode',
      swapFilterType: 'swapList/swapFilterType',
      showContextualTutorial: 'tutorial/showContextualTutorial',
      tutInterestMarks: 'tutorial/tutInterestMarks',
      tutActiveStep: 'tutorial/activeStep',
      progressBar: 'tutorial/progressBar',
      tutStatus: 'tutorial/status',
      showGlobalModal: 'ui/showGlobalModal'
    }),

    shouldRevealProgressBar() {
      return this.tutActiveStep === TUTORIAL_STEPS_TYPES.CONTINUE_INTEREST;
    },

    shouldDisableLink() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutActiveStep === TUTORIAL_STEPS_TYPES.HIGHLIGHT_SWAP
      );
    },

    tutorialStarted() {
      return this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED;
    },

    shouldRevealSwap() {
      if (this.showGlobalModal) {
        return false;
      }

      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutActiveStep === TUTORIAL_STEPS_TYPES.HIGHLIGHT_SWAP
      );
    },

    currentSwapItemComponent() {
      const {
        isMobile,
        SwapItem,
        CompressedSwapItem,
        swapItemMode,
        SWAP_ITEM_TYPES
      } = this;

      if (!isMobile) return SwapItem;

      if (swapItemMode === SWAP_ITEM_TYPES.CARDS) {
        return SwapItem;
      }

      return CompressedSwapItem;
    },

    primaryPropositionWithDetails() {
      const { primaryProposition, userPropositions } = this;

      return userPropositions.find(
        p => p.propositionId === primaryProposition.propositionId
      );
    },

    swapList() {
      const list = [...this.swaps];
      list.splice(this.sponsoredPosition, 0, {
        type: 'sponsoredPropositions',
        propositionId: '123',
        details: {
          type: 'sponsoredPropositions',
          swapScore: 0
        }
      });
      return list;
    },

    firstAdditionalSwapIndex() {
      return this.swapList.findIndex(swap => !!swap.diff);
    }
  },

  watch: {
    $route(newRoute) {
      if (newRoute.query) {
        const queryParams = Object.keys(newRoute.query);
        this.isShowingSwapModal = queryParams.includes('propositionPageId');
      }
    }
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);

    if (this.$route.query) {
      const queryParams = Object.keys(this.$route.query);
      this.isShowingSwapModal = queryParams.includes('propositionPageId');
    }
    if (
      !this.sponsoredPropositions ||
      this.sponsoredPropositions.length === 0
    ) {
      this.getSponsoredPropositions();
    }
    this.fetchPremiumSwaps(this.primaryPropositionId);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
    clearTimeout(this.tooltipTimeout);
  },

  methods: {
    getOffset(el) {
      const rect = el.getBoundingClientRect();
      return rect.top + window.scrollY;
    },
    handleScroll() {
      if (this.$refs.progressBar && this.$refs.progressBar[0]) {
        const el = this.$refs.progressBar[0].$el;
        const pos = this.getOffset(el);

        if (window.top.scrollY >= pos && window.top.scrollY <= 900) {
          if (!el.classList.contains('fixed-mobile-progress-bar')) {
            el.classList.toggle('fixed-mobile-progress-bar', true);
          }
        } else {
          if (window.top.scrollY <= 200) {
            el.classList.toggle('fixed-mobile-progress-bar', false);
          }
        }
      }
    },

    onScroll() {
      let doScoll;

      window.onscroll = () => {
        clearTimeout(doScoll);
        doScoll = setTimeout(this.handleScroll, 100);
      };
    },

    ...mapActions({
      fetchSwapDetails: 'swapList/fetchSwapDetails',
      fetchPremiumSwaps: 'swapList/fetchPremiumSwaps',
      getSponsoredPropositions: 'propositions/getSponsoredPropositions',
      setShowContextualTutorial: 'tutorial/setShowContextualTutorial',
      setGlobalModal: 'ui/setGlobalModal',
      setEscClose: 'tutorial/setEscClose',
      removeDisableScroll: 'ui/removeDisableScroll'
    }),

    handleClickOutsideProgress() {
      if (this.allowClickOutside) {
        this.handleCloseTooltip();
      }
    },

    handleTooltipCallback() {
      // allow click outside and esc close
      this.allowClickOutside = true;
      this.setEscClose(true);
      this.tooltipTimeout = setTimeout(() => {
        this.handleCloseTooltip();
      }, this.tooltipTimeoutMs);
    },

    handleCloseTooltip() {
      if (this.tutActiveStep === TUTORIAL_STEPS_TYPES.CONTINUE_INTEREST) {
        this.removeDisableScroll('ContextualTutModal');
        this.setShowContextualTutorial(false);
        this.allowClickOutside = false;
        this.setEscClose(false);
      }
    },

    handleShowSwapDetails({ propositionId, type }) {
      this.fetchSwapDetails({ propositionId, type });
    }
  }
};
</script>

<style lang="scss" scoped>
.progress-bar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 199;
  top: -5px;
  & .progress-bar-content {
    opacity: 0.85;
  }
  @media ($desktop) {
    position: static;
  }
}

.tooltip-container {
  position: absolute;
  z-index: 200;
  display: flex;
  top: 43px;
  max-width: 191px;
  font-size: 12px;
  font-weight: 700;
  flex-direction: column;
  align-items: center;
}

.reveal {
  margin-top: 50px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation: slideUp 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.9s;
  border-radius: 10px;
  z-index: 200;
}

.reveal-progress {
  position: relative;
  z-index: 3000;
}

.swaps-container {
  position: relative;
}

.swap-items-wrapper {
  margin-bottom: 16px;

  @media ($desktop) {
    margin-bottom: 16px;
  }
}

.next-button {
  margin-top: 10px;
  font-size: 12px;
  color: #409fff;
  background-color: #ffffff;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sponsored-link {
  font-size: 0.8rem;
  color: $transfer-blue;
  text-decoration: underline;
  cursor: pointer;
}

.sponsored-propositions {
  @media ($mobile) {
    margin-left: -10px;
  }
}

.pulse-animation {
  transform: scale(1);
  animation: pulse 0.5s infinite;
}

@media ($mobile) {
  .reveal {
    top: -50px;
    position: relative;
    z-index: 200;
  }
  .fixed-mobile-progress-bar {
    position: fixed;
    animation: slideInProgressBar 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 0.2s;
    top: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slideInProgressBar {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0);
    transform: translateY(20px);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
  100% {
    opacity: 0.95;
    transform: translateY(0px);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0);
    transform: translateY(20px);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
