import { render, staticRenderFns } from "./PageModalContainer.vue?vue&type=template&id=55519a64&scoped=true"
import script from "./PageModalContainer.vue?vue&type=script&lang=js"
export * from "./PageModalContainer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55519a64",
  null
  
)

export default component.exports