<template>
  <div :class="['search-card']">
    <div :class="['image']"></div>

    <div class="card-content">
      <div class="city block">city</div>

      <div class="address block">address</div>
      <div class="apartment-info">
        <p class="info-text info-icon room block">rooms</p>
        <p class="info-text info-icon area block">sqm</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchCard'
};
</script>

<style lang="scss" scoped>
@keyframes load {
  from {
    opacity: 0.6;
  }

  50% {
    opacity: 0.3;
  }

  to {
    opacity: 0.6;
  }
}

.search-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  background-color: #fff;
  border-radius: 8px;
  transition: all 0.2s linear;
  overflow: hidden;
  user-select: none;
  margin-right: 1.5%;
  margin-left: 1.5%;
  animation: load 1.5s infinite;

  //  disables blue highlight on favourite tap
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  @media ($desktop) {
    max-width: 22%;
    min-width: 22%;
    margin-bottom: 4%;
  }

  @media ($small-desktop) {
    max-width: 30%;
    min-width: 30%;
  }

  @media ($mobile) {
    width: 100%;
    margin: 0 0 6% 0;
  }

  @media ($ipad) {
    max-width: 48%;
    min-width: 48%;
    margin: 0 1% 4% 1%;
  }
}

.image {
  height: 180px;
  width: 100%;
  border-radius: 8px;
  background-position: center;
  position: relative;
  background-size: cover;
  background-color: $gray;

  @media ($mobile) {
    height: 220px;
  }
}

.card-content {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 6px 0px;
  width: 100%;
}

.city {
  color: gray;
  display: flex;

  font-size: 14px;
  font-weight: 600;
  width: 100%;
}

.address {
  font-size: 20px;
  font-weight: 700;
}

.apartment-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 12px 0 4px 0;
}

.info-text {
  color: $gray;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  margin: 0 19px 0 19px;
  position: relative;
}

.block {
  display: block;
  position: relative;

  &::after {
    background-color: #8e8d8f;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 50;
  }
}
</style>
