import { lbNotificationsApi } from '@/utils/axiosConfig';

async function fetchUnseenNotificationsCount(propositionId) {
  const { data } = await lbNotificationsApi.get(
    `/notifications/${propositionId}/unseen`
  );

  return data;
}

async function fetchNotifications(propositionId) {
  const { data } = await lbNotificationsApi.get(
    `/notifications/${propositionId}`
  );

  return data;
}

async function updateReadStatus(id) {
  await lbNotificationsApi.post(`/notifications/${id}/read`);
}

export default {
  fetchUnseenNotificationsCount,
  fetchNotifications,
  updateReadStatus
};
