import { lbApi } from '../../utils/axiosConfig';
import { isDebug } from '../debug';

const eventTypes = {
  PAGEVIEW: 0,
  CLICK: 1,
  SEE: 2,
  EXPERIMENT: 3,
  EXPERIMENTS: 4
};

async function emitEvent(type, description, props) {
  try {
    if (type === eventTypes.CLICK && props && props.id) {
      setTriggerBy(props.id);
    }

    if (type === eventTypes.PAGEVIEW) {
      const triggerBy = popTriggerBy();
      if (triggerBy) {
        props = {
          ...props,
          triggerBy
        };
      }
    }

    if (isDebug()) {
      console.log('DEBUG: Event emitted: ', {
        t: type,
        d: description,
        p: props
      });
      return;
    }

    return lbApi.post(`/ev`, {
      t: type,
      d: description,
      p: props
    });
  } catch (e) {
    console.log(e);
  }
}

function setTriggerBy(id) {
  if (!localStorage) {
    return;
  }

  if (!id) {
    localStorage.removeItem('lb-ev-trigger');
    return;
  }

  localStorage.setItem('lb-ev-trigger', id);
}

function popTriggerBy() {
  if (!localStorage) {
    return null;
  }

  const trigger = localStorage.getItem('lb-ev-trigger');
  localStorage.removeItem('lb-ev-trigger');
  return trigger;
}

function setEventQueue(evQueue) {
  try {
    if (!localStorage) {
      return;
    }

    if (!evQueue || evQueue.length === 0) {
      localStorage.removeItem('lb-ev-data');
      return;
    }

    localStorage.setItem('lb-ev-data', JSON.stringify(evQueue));
  } catch (e) {
    console.log(e);
  }
}

function getEventQueue() {
  try {
    if (!localStorage) {
      return [];
    }

    const storedEvents = localStorage.getItem('lb-ev-data');
    let evQueue = [];
    if (storedEvents) {
      evQueue = JSON.parse(storedEvents);
    }
    return evQueue;
  } catch (e) {
    console.log(e);
    return [];
  }
}

function queueEvent(type, description, props) {
  try {
    const evQueue = getEventQueue();
    if (evQueue.length > 10) {
      return;
    }

    if (type === eventTypes.CLICK) {
      setTriggerBy(props.id);
    }

    evQueue.push({
      t: type,
      d: description,
      p: props
    });

    setEventQueue(evQueue);
  } catch (e) {
    console.log(e);
  }
}

function handleQueue() {
  try {
    let evQueue = getEventQueue();
    if (evQueue.length > 10) {
      setEventQueue(null);
      return;
    }

    if (evQueue.length === 0) {
      return;
    }

    let { t, d, p } = evQueue[0];

    emitEvent(t, d, p).finally(() => {
      evQueue.splice(0, 1);
      setEventQueue(evQueue);

      if (evQueue.length > 0) {
        setTimeout(handleQueue, 0);
      } else {
        popTriggerBy();
      }
    });
  } catch (e) {
    console.log(e);
  }
}

handleQueue();

export default {
  eventTypes,
  emitEvent,
  queueEvent
};
