var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data && _vm.data.rooms.length && !_vm.isLoading)?_c('section',{staticClass:"rooms-wrapper"},[(_vm.newLikesIsBlocked)?[_c('div',{staticClass:"blocked-banner"},[_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":"chat-unlock-blue","width":_vm.isMobile ? 24 : 32,"height":_vm.isMobile ? 24 : 32}}),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('rooms_banner_title')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('rooms_banner_text'))+" ")])])],1)]:_vm._e(),(
      _vm.showUnansweredChatsForPremiumOrFree ||
      _vm.showUnansweredChatsForUnlockedFreemium
    )?[_c('h4',{staticClass:"rooms-heading"},[_vm._v(_vm._s(_vm.$t('rooms_unanswered_title')))]),_vm._l((_vm.unansweredChats),function(room){return _c('Room',{key:room.id,staticClass:"room-unanswered",attrs:{"room":room,"candidate-id":_vm.candidateId,"is-mobile":_vm.isMobile,"delete-visible":_vm.openMenuRoom === room.id,"is-locked":_vm.isFreemiumOrTrial &&
        _vm.freemiumChatLocked &&
        (!_vm.unlockedRooms || !_vm.unlockedRooms.includes(room.id))},on:{"click":function($event){return _vm.$emit('selected', $event)},"delete":function($event){return _vm.$emit('delete', $event)},"menuOpen":_vm.handleMenuOpen}})}),(_vm.firstAnsweredIndex !== -1)?_c('h4',{staticClass:"rooms-heading"},[_vm._v(" "+_vm._s(_vm.$t('rooms_answered_title'))+" ")]):_vm._e()]:_vm._e(),_vm._l((_vm.answeredChats),function(room){return _c('Room',{key:room.id,staticClass:"room-answered",attrs:{"room":room,"candidate-id":_vm.candidateId,"is-mobile":_vm.isMobile,"delete-visible":_vm.openMenuRoom === room.id,"is-locked":_vm.isFreemiumOrTrial &&
      _vm.freemiumChatLocked &&
      (!_vm.unlockedRooms || !_vm.unlockedRooms.includes(room.id))},on:{"click":function($event){return _vm.$emit('selected', $event)},"delete":function($event){return _vm.$emit('delete', $event)},"menuOpen":_vm.handleMenuOpen}})}),(_vm.data.hasMore && !_vm.isLoadingMore)?_c('p',{staticClass:"load-more",on:{"click":function($event){return _vm.$emit('loadMore')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_room_show_more'))+" ")]):(_vm.isLoadingMore)?_c('div',{staticClass:"load-more-spinner"},[_c('div',{staticClass:"spinner-small-wrapper"},[_c('ASpinner',{attrs:{"classes":['loading-spinner']}})],1)]):_vm._e()],2):(_vm.isLoading)?_c('section',{staticClass:"rooms-wrapper"},[_c('section',{staticClass:"center"},[_c('div',{staticClass:"spinner-wrapper"},[_c('ASpinner',{attrs:{"classes":['loading-spinner']}})],1)])]):_c('section',{staticClass:"rooms-wrapper rooms-wrapper-no-conversations"},[_c('h2',[_vm._v(_vm._s(_vm.$t('chat_room_no_active')))])])}
var staticRenderFns = []

export { render, staticRenderFns }