import { authorizeKlarnaPayment, loadKlarnaWidget } from './helpers';
import { router } from '../../../main';

import services from './services';
import routeConstants from '../../../router/routes.constants';
import { lbAws, paymentApi } from '../../../utils/axiosConfig';

function loadKlarnaScript() {
  const src = 'https://x.klarnacdn.net/kp/lib/v1/api.js';

  const scriptsOnPage = Array.from(document.getElementsByTagName('script'));

  let shouldLoadScript = true;

  scriptsOnPage.forEach(s => {
    if (s.src === src) {
      shouldLoadScript = false;
    }
  });

  if (shouldLoadScript) {
    window.Logger.log('[KLARNA]: Loading script into head');
    const klarnaScript = document.createElement('script');
    klarnaScript.async = true;
    klarnaScript.src = src;
    document.head.append(klarnaScript);
  }
}

async function initializeTokenExists({ commit }) {
  const exists = await services.fetchTokenExists();
  const lastCheck = new Date().toISOString();

  commit('setTokenExists', {
    exists,
    lastCheck
  });
  commit('setButtonsEnabled', {
    enabled: true
  });
  commit('hasCheckedForToken', {
    hasChecked: true
  });

  if (exists) {
    commit('setAgreementChecked', {
      checked: true
    });
  }
}

function resetTokenCheck({ commit }) {
  commit('setTokenExists', {
    exists: null,
    lastCheck: null
  });
  commit('setButtonsEnabled', {
    enabled: false
  });
  commit('hasCheckedForToken', {
    hasChecked: false
  });
  commit('setAgreementChecked', {
    checked: false
  });
}

async function initializeKlarnaWidget({ commit, dispatch }) {
  window.Logger.log('[KLARNA]: Initializing Klarna widget');
  commit('setButtonsEnabled', {
    enabled: false
  });

  try {
    const session = await services.createSession();
    commit('setSession', {
      session: session.data
    });

    const widgetLoaded = await loadKlarnaWidget(session.data.client_token);

    dispatch('checkIfCanUpdateInitialSession');

    if (widgetLoaded) {
      commit('setButtonsEnabled', {
        enabled: true
      });
    } else {
      commit('setErrorMessage', {
        msg: 'error'
      });
      throw Error('Could not load Klarna session');
    }
  } catch (e) {
    commit('setErrorMessage', {
      msg: 'error'
    });
    window.Logger.error(
      'Something went wrong with initializing Klarna widget: ',
      e
    );
    throw Error(e);
  }
}

async function authorizeSession(
  { commit, dispatch, state, rootState },
  {
    propositionId,
    paymentType,
    onlyUpdateToken = false,
    renewSubscription = false
  }
) {
  if (!state.session || !state.buttonsEnabled || !state.agreementChecked) {
    return;
  }

  const { payload } = state.session;

  let klarnaAuth;

  try {
    klarnaAuth = await authorizeKlarnaPayment(payload);

    commit('setKlarnaAuthResponse', {
      klarnaAuth
    });
    commit('setKlarnaAuthorizationToken', {
      token: klarnaAuth.authorization_token
    });
  } catch (e) {
    commit('setErrorMessage', {
      msg: 'error'
    });

    throw Error('Could not authorize Klarna session', e);
  }

  if (renewSubscription) {
    const currentPropositionId =
      rootState.handleSubscription.currentProposition.propositionId;
    const productId =
      rootState.handleSubscription.currentSubscription.productId;

    await dispatch('renewSubscriptionWithKlarna', {
      propositionId: currentPropositionId,
      productId
    });

    return;
  }

  if (onlyUpdateToken) {
    await dispatch('updateCustomerToken', {
      token: klarnaAuth.authorization_token
    });
    return;
  }

  if (paymentType === 'proposition') {
    await dispatch('createPropositionPayment', {
      propositionId
    });
  } else if (paymentType === 'visibility') {
    await dispatch('createVisibilityPayment', {
      propositionId
    });
  }
}

async function updateCustomerToken(context, { token }) {
  context.commit('setLoading', {
    loading: true
  });
  context.commit('setButtonsEnabled', {
    enabled: false
  });

  try {
    const response = await services.createNewCustomerToken({
      sessionId: context.state.session.session_id,
      authToken: token
    });

    context.commit('hasCheckedForToken', {
      hasChecked: false
    });
    context.commit('setLoading', {
      loading: false
    });
    router.replace(routeConstants.UPDATE_TOKEN_DONE_KLARNA);

    return response;
  } catch (error) {
    context.commit('setLoading', {
      loading: false
    });
    window.Logger.log('error', error);
    context.commit('setErrorMessage', {
      msg: 'error'
    });
    throw Error('Could not update customer token: ' + error);
  }
}

async function renewSubscriptionWithKlarna(
  context,
  { propositionId, productId }
) {
  context.commit('setLoading', {
    loading: true
  });
  context.commit('setButtonsEnabled', {
    enabled: false
  });

  try {
    const response = await services.createNewCustomerToken({
      sessionId: context.state.session.session_id,
      authToken: context.state.klarnaToken
    });

    await lbAws.post(
      '/lord-commander/subscription/proposition/change',

      {
        propositionId,
        productId: parseInt(productId)
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    context.commit('hasCheckedForToken', {
      hasChecked: false
    });
    context.commit('setLoading', {
      loading: false
    });
    router.replace(routeConstants.AUTO_SUBSCRIPTION_DONE);

    return response;
  } catch (error) {
    context.commit('setLoading', {
      loading: false
    });
    window.Logger.log('[KLARNA] error', error);
    context.commit('setErrorMessage', {
      msg: 'error'
    });
    throw Error(
      'Could not create klarna token or change subscription: ' + error
    );
  }
}

async function createPropositionPayment(
  { rootGetters, state, commit, dispatch },
  { propositionId }
) {
  if (!state.buttonsEnabled || !state.agreementChecked || !propositionId) {
    window.Logger.log('Error in create payment, missing something');
    throw Error('Error in Klarna create payment, missing something');
  }

  commit('setButtonsEnabled', {
    enabled: false
  });
  commit('setLoading', {
    loading: true
  });

  const productId = state.selectedProduct.productId;

  window.Logger.log('Klarna payment props: ', {
    tokenExists: state.tokenExists,
    sessionId: state.session.session_id,
    klarnaToken: state.klarnaToken,
    klarnaAuthResponse: state.klarnaAuthResponse
  });
  try {
    let response;

    if (state.tokenExists) {
      try {
        response = await services.createPropositionPayment(
          propositionId,
          productId
        );
      } catch (e) {
        commit('setErrorMessage', {
          msg: 'error'
        });
        throw Error('Could not create proposition payment', e);
      }
    } else if (
      state.session.session_id &&
      state.klarnaToken &&
      state.klarnaAuthResponse.approved
    ) {
      try {
        response = await services.createFirstTimePropositionPayment(
          propositionId,
          productId,
          state.session.session_id,
          state.klarnaToken
        );
      } catch (e) {
        commit('setErrorMessage', {
          msg: 'error'
        });
        throw Error('Could not create first time proposition payment', e);
      }
    } else {
      commit('setLoading', {
        loading: false
      });
      commit('setButtonsEnabled', {
        enabled: true
      });
      window.Logger.log('Klarna widget closed');
      return;
    }

    //GTM TRACKING
    dispatch('getGtmPurchaseType').then(gtmPurchaseType => {
      dispatch(
        'gtm/purchaseProduct',
        {
          product: state.selectedProduct,
          action: gtmPurchaseType,
          what: 'proposition',
          propositionId: propositionId
        },
        {
          root: true
        }
      );
    });

    await dispatch('handleSubscription/refreshStates', propositionId, {
      root: true
    });

    await dispatch(
      'myPropositions/setPrimaryProposition',
      {
        primaryPropositionId: propositionId,
        skipSetRequest: true
      },
      {
        root: true
      }
    );

    // Set other state
    commit('hasCheckedForToken', {
      hasChecked: false
    });
    commit('setLoading', {
      loading: false
    });

    if (rootGetters['app/isAppRequest']) {
      location.href = routeConstants.PAYMENT_DONE_PROPOSITION;
    }

    router.replace(routeConstants.PAYMENT_DONE_PROPOSITION);

    return response;
  } catch (error) {
    commit('setLoading', {
      loading: false
    });
    window.Logger.log('error', error);
    throw Error('Could not create Klarna proposition order: ' + error);
  }
}

async function createVisibilityPayment(
  { rootGetters, state, commit, dispatch },
  { propositionId }
) {
  if (!state.buttonsEnabled || !state.agreementChecked || !propositionId) {
    window.Logger.log('Error in create payment, missing something');
    return;
  }

  commit('setLoading', {
    loading: true
  });

  commit('setButtonsEnabled', {
    enabled: false
  });

  window.Logger.log('Klarna payment props: ', {
    tokenExists: state.tokenExists,
    sessionId: state.session.session_id,
    klarnaToken: state.klarnaToken,
    klarnaAuthResponse: state.klarnaAuthResponse
  });

  const productId = state.selectedProduct.productId;
  try {
    let response;

    if (state.tokenExists) {
      response = await services.createVisibilityPayment({
        propositionId,
        productId
      });

      dispatch(
        'gtm/purchaseProduct',
        {
          product: state.selectedProduct,
          action: 'new',
          what: 'visibility',
          propositionId: propositionId
        },
        {
          root: true
        }
      );
    } else if (
      state.session.session_id &&
      state.klarnaToken &&
      state.klarnaAuthResponse.approved
    ) {
      response = await services.createFirstTimeVisibilityPayment({
        propositionId,
        productId,
        sessionId: state.session.session_id,
        authToken: state.klarnaToken
      });

      dispatch(
        'gtm/purchaseProduct',
        {
          product: state.selectedProduct,
          action: 'first',
          what: 'visibility',
          propositionId: propositionId
        },
        {
          root: true
        }
      );
    } else {
      commit('setLoading', {
        loading: false
      });
      commit('setButtonsEnabled', {
        enabled: true
      });
      window.Logger.log('Klarna widget closed');
      return;
    }

    commit('hasCheckedForToken', {
      hasChecked: false
    });
    commit('setLoading', {
      loading: false
    });

    if (rootGetters['app/isAppRequest']) {
      location.href = routeConstants.PAYMENT_DONE_VISIBILITY;
    }

    router.replace(routeConstants.PAYMENT_DONE_VISIBILITY);

    return response;
  } catch (error) {
    commit('setLoading', {
      loading: false
    });
    window.Logger.log('error', error);
    commit('setErrorMessage', {
      msg: 'error'
    });
    throw Error('Could not create Klarna extra visibility order: ', error);
  }
}

function setAgreementChecked({ commit }, { checked }) {
  commit('setAgreementChecked', {
    checked
  });
}

async function fetchProducts(
  { commit, state },
  { productType, propositionId, productTypes }
) {
  if (
    state.lastProductFetch &&
    state.lastProductFetch.productType === productType &&
    state.lastProductFetch.propositionId === propositionId
  ) {
    return;
  }

  let products = [];
  let defaultProduct;

  commit('setProducts', {
    products
  });

  commit('setSelectedProduct', null);

  if (productType === 'proposition' || productType === 'annonsforlangning') {
    products = await services.getPropositionProducts(propositionId, 'payment');

    defaultProduct = products.find(p => p.quantity === 60);
    if (!defaultProduct) {
      defaultProduct = products.find(p => p.quantity === 90);
    }
    if (!defaultProduct) {
      defaultProduct = products.find(p => p.quantity === 30);
    }
  } else if (
    productType === 'extraVisibility' ||
    productType === 'extrasynlighet'
  ) {
    products = await services.getProducts('extrasynlighet');
    defaultProduct = products.find(p => p.quantity === 14);
  } else if (productType) {
    products = await services.getProducts(productType);
  } else if (Array.isArray(productTypes)) {
    let tempProducts = await services.getProducts();
    tempProducts = tempProducts.filter(
      x =>
        productTypes.includes(x.category) && x.category !== 'annonsforlangning'
    );

    if (productTypes.includes('annonsforlangning')) {
      const propositionProducts = await services.getPropositionProducts(
        propositionId,
        'payment'
      );

      products = [...tempProducts, ...propositionProducts];
    } else {
      products = tempProducts;
    }
  }

  commit('setProducts', {
    products
  });

  commit('setLastProductFetch', {
    productType,
    propositionId,
    productTypes
  });

  if (!defaultProduct) {
    defaultProduct = products[0];
  }

  commit('setSelectedProduct', defaultProduct);
}

async function selectProductId({ commit }, { productId }) {
  commit('setSelectedProductId', {
    productId
  });
}

async function setSelectedAdditionalProducts({ commit }, products) {
  commit('setSelectedAdditionalProducts', products);
}

async function sendUpdateSession() {}

function updateSessionPayload(
  { commit },
  {
    order_lines,
    locale,
    order_amount,
    order_tax_amount,
    purchase_country,
    purchase_currency
  }
) {
  const newPayload = {
    locale,
    order_amount,
    order_lines,
    order_tax_amount,
    purchase_country,
    purchase_currency
  };

  commit('updateSessionPayload', {
    newPayload
  });
}

function checkIfCanUpdateInitialSession(context) {
  const { session_id } = context.state.session;
  const { selectedProduct } = context.state;

  if (!session_id || !selectedProduct.productId) {
    window.Logger.log('[KLARNA]: Not ready to update session', {
      session_id,
      selectedProduct
    });
    return;
  }

  window.Logger.log('[KLARNA]: Ready to update session', {
    session_id,
    selectedProduct
  });

  context.dispatch('sendUpdateSession', {
    sessionId: session_id,
    product: selectedProduct
  });
}

async function getGtmPurchaseType() {
  const { data } = await paymentApi.get('payment-history');

  if (!data || data.length === 0) {
    return 'first';
  }

  const paymentHistory = data.filter(
    x => x.paymentDays && x.paymentDays > 0 && x.paymentDate
  );

  // This will run after payment is alrady comlpeted, so 1 payment should already be here and still be considered as the first one.
  if (paymentHistory.length === 0 || paymentHistory.length === 1) {
    return 'first';
  }

  const isNew = await isLastPaymentOlderThan(12, paymentHistory);
  if (isNew) return 'new';

  return '';
}

async function isLastPaymentOlderThan(months, paymentHistory) {
  try {
    const payments = paymentHistory.sort(
      (a, b) => new Date(b.paymentDate) - new Date(a.paymentDate)
    );

    const monthsAgoDate = new Date(
      Date.now() - 1000 * 60 * 60 * 24 * (months * 30.416666667)
    );

    const lastPayment = payments[0];
    const lastPaymentDate = new Date(lastPayment.paymentDate);
    const paymentDaysInTime = lastPayment.paymentDays * 24 * 60 * 60 * 1000;
    const isLastPaidInactivatedPropositionDate = new Date(
      lastPaymentDate.getTime() + paymentDaysInTime
    );

    return monthsAgoDate > isLastPaidInactivatedPropositionDate;
  } catch (error) {
    console.error(error);
  }

  return false;
}

export default {
  loadKlarnaScript,
  createPropositionPayment,
  createVisibilityPayment,
  authorizeSession,
  updateSessionPayload,
  authorizeKlarnaPayment,
  initializeKlarnaWidget,
  initializeTokenExists,
  resetTokenCheck,
  setAgreementChecked,
  fetchProducts,
  selectProductId,
  updateCustomerToken,
  renewSubscriptionWithKlarna,
  checkIfCanUpdateInitialSession,
  sendUpdateSession,
  getGtmPurchaseType,
  isLastPaymentOlderThan,
  setSelectedAdditionalProducts
};
