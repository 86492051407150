<template>
  <div class="report-option">
    <BaseRadioButtonNew
      :value="value"
      :selected="selected"
      :padding-y="currentScreen === '1.4' && isMobile ? 18 : 20"
      :disabled="disabled"
      @change="$emit('change', value)"
      ><slot
    /></BaseRadioButtonNew>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseRadioButtonNew from '@/components/BaseRadioButtonNew.vue';

export default {
  name: 'ReportOption',

  components: {
    BaseRadioButtonNew
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      type: Number,
      default: 0,
      required: true
    },
    selected: {
      type: Number,
      default: 0,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      currentScreen: 'reportProposition/currentScreen',
      isMobile: 'screenSize/isMobile'
    })
  }
};
</script>

<style lang="scss" scoped>
.report-option {
  display: flex;
  align-items: center;
}
</style>
