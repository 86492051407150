<template>
  <div class="payment-card">
    <div class="card-content">
      <div class="card-presentation">
        <div class="img-placeholder"></div>
        <div class="card-info">
          <div>
            <p class="primary-text block">Blocktext blocktext</p>
            <p class="secondary-text block">blockaddress</p>
            <p class="secondary-text block">block block blocktext</p>
          </div>
          <p class="secondary-text block">blocktext block</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentCardLoader'
};
</script>

<style lang="scss" scoped>
@keyframes load {
  from {
    opacity: 0.6;
  }

  50% {
    opacity: 0.3;
  }

  to {
    opacity: 0.6;
  }
}

.payment-card {
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  margin: 30px 0;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.09);
  width: 100%;
  animation: load 1.5s infinite;
}

.repaid-banner {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(256, 256, 256, 0.7);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
}

.card-content {
  padding: 18px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  @media ($mobile) {
    padding: 12px;
    flex-direction: column;
  }
}

.card-presentation {
  display: flex;
}

.img-placeholder {
  height: 138px;
  width: 138px;
  border-radius: 2px;
  background-color: #d2d2d2;
  background-size: cover;
  background-position: center;

  @media ($mobile) {
    min-height: 75px;
    max-height: 75px;
    min-width: 75px;
    max-width: 75px;
  }
}

.card-info {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ($mobile) {
    padding: 0 10px;
  }
}

.selected-status {
  height: 30px;
  width: 140px;
  border: 1px solid rgba(151, 151, 151, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ($mobile) {
    display: none;
  }
}

.status-text {
  color: #8e8d8f;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  padding-right: 13px;

  &::after {
    content: '';
    position: absolute;
    top: 5px;
    right: 0px;
    height: 8px;
    width: 8px;
    background-color: #34c375;
    border-radius: 50%;
  }
}

.overdue-status {
  &::after {
    background-color: red;
  }
}

.secondary-text {
  margin: 6px 0 0 0;
  padding: 0;
  color: #8e8d8f;
  font-weight: 600;
  font-size: 14px;
}

.primary-text {
  margin: 0 0 0 0;
  padding: 0;
  color: #3c3c3f;
  font-weight: 700;
  font-size: 24px;

  @media ($mobile) {
    font-size: 16px;
  }
}

.block {
  display: block;
  position: relative;

  &::after {
    background-color: #8e8d8f;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 50;
  }
}
</style>
