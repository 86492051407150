<template>
  <div class="modal-nav">
    <span
      :class="[
        'nav-item',
        { 'is-active': pageModalActivePage === 'proposition' }
      ]"
      @click="setPageModalActivePage('proposition')"
      >{{ $t('modal_navigation_alt1') }}</span
    >
    <span
      :class="[
        'nav-item',
        { 'is-active': pageModalActivePage === 'swapdetails' }
      ]"
      @click="setPageModalActivePage('swapdetails')"
      >{{ $t('modal_navigation_alt2') }}</span
    >
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ModalNavigation',

  computed: {
    ...mapGetters({
      pageModalActivePage: 'ui/pageModalActivePage'
    })
  },

  methods: {
    ...mapActions({
      setPageModalActivePage: 'ui/setPageModalActivePage'
    })
  }
};
</script>

<style lang="scss" scoped>
.modal-nav {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: 1rem;
  height: 100%;
  user-select: none;
}

.nav-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.nav-item.is-active::after {
  content: '';
  display: block;
  position: absolute;
  border-bottom: 2px solid $main-blue;
  padding-bottom: 2px;
  width: calc(100% - 40px);
  left: 20px;
  bottom: 11px;
}
</style>
