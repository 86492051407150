<template>
  <transition name="toast-slide-up" mode="out-in">
    <div v-if="open" class="toast">
      <div>{{ title }}</div>
      <div v-if="showTimer" class="regret" @click="cancelToast">
        {{ $t('toast_regret') }} ({{ timer }})
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Toast',
  computed: {
    ...mapGetters({
      open: 'toast/open',
      title: 'toast/title',
      timer: 'toast/timer',
      showTimer: 'toast/showTimer'
    })
  },
  methods: {
    ...mapActions({
      cancelToast: 'toast/cancelToast',
      setToast: 'toast/setToast'
    })
  }
};
</script>

<style lang="scss" scoped>
.toast {
  padding: 12px 16px;
  border-radius: 6px;
  position: fixed;
  bottom: calc(60px + env(safe-area-inset-bottom)); // iphone x <= haxx
  left: 0;
  width: 100vw;
  z-index: 2000;
  color: white;
  background-color: #1b2d41;
  box-shadow: $standard-shadow;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: 600;

  @media ($desktop) {
    width: 350px;
    left: 50%;
    margin-left: -175px;
    bottom: 40px;
  }

  @media ($mobile) {
    width: 92vw;
    margin-left: 4vw;
  }
}
</style>
