<template>
  <div>
    <BaseOverlay
      @click.native="
        closeFunction();
        locked ? false : $emit('close');
      "
    />
    <transition :name="isMobile ? 'slide-up' : 'fade'" mode="out-in" appear>
      <div class="standard-modal">
        <div
          v-if="showHeader"
          :class="[
            'header',
            { 'no-shadow': noShadow },
            { 'with-border': withBorder }
          ]"
        >
          <div v-if="actionTitle" class="action-button" @click="actionFunction">
            {{ actionTitle }}
          </div>

          <div class="title">{{ title }}</div>
          <div
            class="close-btn"
            @click="
              closeFunction();
              locked ? false : $emit('close');
            "
          >
            <menu-icon icon="close"></menu-icon>
          </div>
        </div>
        <div class="standard-content">
          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MenuIcon from '../Icons/MenuIcon';

export default {
  name: 'ModalWithInternalOverlay',

  components: {
    MenuIcon
  },

  props: {
    closeFunction: {
      type: Function,
      default: () => {}
    },

    showHeader: {
      type: Boolean,
      default: true
    },

    title: {
      type: String,
      default: ''
    },

    noShadow: {
      type: Boolean,
      default: false
    },

    actionTitle: {
      type: String,
      default: ''
    },

    withBorder: {
      type: Boolean,
      default: false
    },

    actionFunction: {
      type: Function,
      default: () => {}
    },

    locked: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  },

  mounted() {
    this.setPageModalZIndex(2000);
    this.toggleDisableScroll('modal-with-internal-overlay');
  },

  destroyed() {
    this.setPageModalZIndex(null);
    this.toggleDisableScroll('modal-with-internal-overlay');
  },

  methods: {
    ...mapActions({
      setPageModalZIndex: 'ui/setPageModalZIndex',
      toggleDisableScroll: 'ui/toggleDisableScroll'
    })
  }
};
</script>

<style lang="scss" scoped>
.standard-modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: #fff;
  z-index: $modal-z-index + 1;
  color: $text-primary;
  overflow-y: hidden;

  @media ($desktop) {
    width: 700px;
    border-radius: 8px;
    top: 80px;
    left: calc(50% - 350px);
    height: 80%;
    max-height: 740px;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.2);
  }

  @media ($mobile) {
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  // IE11 hack
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow-y: scroll;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1000;

  &.with-border {
    border-bottom: 1px solid #ddd;
  }

  .on-prop-page & {
    margin-bottom: 0;
  }

  @media ($desktop) {
    margin-bottom: 15px;
    height: 60px;
    min-height: 60px;
    box-shadow: none;

    &.no-shadow {
      box-shadow: none;
    }
  }

  @media ($mobile) {
    // box-shadow: $standard-shadow;
    height: 50px;
    min-height: 50px;

    &.no-shadow {
      box-shadow: none;
    }
  }
}

.title {
  position: relative;
  top: 1px;
  font-weight: 600;
}

.close-btn {
  cursor: pointer;
  position: absolute;

  @media ($desktop) {
    right: 20px;
    top: 20px;
  }

  @media ($mobile) {
    top: 14px;
    right: 14px;
  }
}

.standard-content {
  overflow-y: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 0px;
  @media ($mobile) {
    padding: 0px;
  }
}

.action-button {
  position: absolute;
  font-size: 0.8rem;
  font-weight: 600;
  text-decoration: underline;

  @media ($desktop) {
    left: 20px;
    top: 20px;
  }

  @media ($mobile) {
    top: 17px;
    left: 14px;
  }
}
</style>
