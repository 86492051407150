<template>
  <AMap
    @ready="handleMapReady($event)"
    @created="handleMapCreated($event)"
  ></AMap>
</template>

<script>
import AMap from '@/components/atomic/atoms/a-map';

export default {
  name: 'SwapChainMap',
  components: {
    AMap
  },
  props: {
    swaps: {
      type: Array,
      required: true
    },
    // only purpose to update map on interest change
    interests: {
      type: Array,
      required: true
    },
    primaryPropositionId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      zoom: 6,
      map: {},

      activeMarkers: []
    };
  },

  methods: {
    handleMapCreated() {
      const markers = [];

      this.swaps.forEach(swap => {
        window.Logger.log(swap);
        let adress = '';
        if (this.primaryPropositionId === swap.propositionId.toString()) {
          adress = this.$t('map_your_residence');
        } else {
          adress = swap.residences[0].streetAddress;
        }
        markers.push({
          adress: adress,
          swapInterested: swap.interested,
          t: 'SI',
          id: swap.propositionId,
          ll: [
            swap.residences[0].coordinates.lng,
            swap.residences[0].coordinates.lat
          ]
        });
      });
      const markerPointsEvent = new CustomEvent('markerPoints', {
        detail: markers
      });
      document.dispatchEvent(markerPointsEvent);
    },

    handleMapReady() {
      const bound = new maplibregl.LngLatBounds();

      const bounds = this.swaps.map(swap => [
        swap.residences[0].coordinates.lng,
        swap.residences[0].coordinates.lat
      ]);

      bounds.forEach(b => {
        bound.extend(b);
      });

      if (!bound || Object.keys(bound).length === 0) return;

      const formatedBound = {
        ne: bound._ne,
        sw: bound._sw
      };
      document.dispatchEvent(
        new CustomEvent('boundingBox', {
          detail: {
            ...formatedBound,
            padding: 100
          }
        })
      );
    }
  }
};
</script>

<style>
/* 
IMPORTANT **********
These styles aren't 
scoped due to mapbox implementation,
so rules will apply globally
*/
.swap-chain-map {
  height: 100%;
  width: 100%;
}

.swap-chain-map-marker-inner-wrapper {
  height: 100%;
  width: 100%;
  padding: 7px 7px;
  display: flex;
  align-items: center;
  border-radius: 5px;

  position: relative;

  background-color: #2196d8;
}
.swap-chain-map-marker-inner-wrapper-not-interested {
  background-color: #f0706a;
}

.swap-chain-map-marker-icon {
  height: 17px;
  width: 17px;
  margin-right: 5px;

  background-size: contain;
  background-position: center;
  background-image: url('../../assets/svg/interestIcons/empty.svg');
}

.swap-chain-map-marker-icon-interested {
  background-image: url('../../assets/svg/interestIcons/interested-inverted.svg');
}

.swap-chain-map-marker-icon-not-interested {
  background-image: url('../../assets/svg/interestIcons/not-interested-inverted.svg');
}

.swap-chain-map-marker-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.swap-chain-marker-address-text {
  color: white;
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 2px;
}

.swap-chain-map-marker-interest-text {
  color: white;
  font-size: 0.6rem;
  font-weight: 600;
  line-height: 100%;
}

.swap-chain-map-marker-arrow {
  position: absolute;
  display: block;
  top: 90%;
  left: 30%;
  width: 1px;
  height: 1px;
  border-left: 15px solid rgba(0, 0, 0, 0);
  border-right: 15px solid rgba(0, 0, 0, 0);
  border-top: 10px solid #2196d8;
}
.swap-chain-map-marker-arrow-not-interested {
  border-top: 10px solid #f0706a;
}
</style>
