<template>
  <ul id="js-suggestions-field" class="suggestions-container">
    <template v-if="suggestions && suggestions.length > 0">
      <li
        v-for="(suggestion, index) in suggestions"
        :key="index"
        class="suggestion-item"
        @click.stop.prevent="e => handleClick({ suggestion, event: e })"
      >
        <BaseIcon v-if="showIcon" icon="map-pin" class="suggestion-icon" />
        <div>
          <div class="name">
            {{ suggestion.name }}
          </div>
          <div class="description">
            {{ suggestion.description }}
          </div>
        </div>
        <BaseIcon
          icon="chevron-right"
          :width="6"
          :height="11"
          style="margin-left: auto"
        />
      </li>
    </template>
    <li
      v-else-if="noResultsFound === true"
      key="no-results"
      class="suggestion-item no-results"
    >
      {{ noResultsMessage }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MapSuggestions',
  props: {
    highlightValue: {
      type: String,
      default: ''
    },
    suggestions: {
      type: Array,
      required: true
    },
    handleClick: {
      type: Function,
      required: true
    },
    noResultsFound: {
      type: Boolean,
      default: false
    },
    noResultsMessage: {
      type: String,
      default: '',
      required: true
    },
    showIcon: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.suggestions-container {
  -webkit-padding-start: 0;
  background-color: white;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  left: -1px;
  list-style-type: none;
  padding: 0;
  padding: none;
  position: absolute;
  top: 80px;
  width: calc(100% + 2px);
  border-radius: 4px;
  box-shadow: 0px 4px 12px 6px rgba(50, 50, 200, 0.07);
  overflow-y: scroll;
  max-height: 300px;
  z-index: 1999;

  .without-shadow & {
    border-radius: 0;
    box-shadow: none;
  }
}

.suggestion-item {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px;
  width: 100%;
  font-weight: 600;
  line-height: 1;
  list-style: none;
  cursor: pointer;
  z-index: 999;

  &:hover {
    background-color: #bfeefd;
  }
}

.suggestion-icon {
  margin-right: 10px;
}

.name {
  font-weight: 600;
  margin-bottom: 1px;
}

.description {
  font-size: 13px;
  font-weight: 600;
  color: #878b9d;
}

.no-results {
  line-height: 1.3;
}
</style>
