<template>
  <div class="modal-content">
    <slot name="icon" />
    <h3 class="fancy-heading">
      <span><slot name="title" /></span>
    </h3>
    <div class="text-content">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyModalContent'
};
</script>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.icon {
  margin-bottom: 30px;
}

.fancy-heading {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #003384;
  margin-top: 0;
  margin-bottom: 30px;
}

.fancy-heading span {
  line-height: 42px;
  background-color: #f5e6dc;
  padding: 4px 10px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.text-content p {
  margin-top: 0;
  line-height: 20px;
}

.text-content ::v-deep strong {
  font-weight: 600;
  color: #003384;
}

.text-content ::v-deep b {
  font-weight: 600;
}
</style>
