import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import country from '@/plugins/country/module';

function getInitialSwapItemType() {
  const savedType = localStorage.getItem('SWAP_ITEM_TYPE');

  if (savedType) return savedType;

  return SWAP_ITEM_TYPES.CARDS;
}

export const SWAP_ITEM_TYPES = {
  CARDS: 'CARDS',
  EXCEL: 'EXCEL'
};
export const SWAP_LIST_TYPES = {
  LIST: 'LIST',
  MAP: 'MAP'
};

export const SWAP_FILTER_TYPES = {
  ALL: 'ALL',
  FULL_INTEREST: 'FULL_INTEREST',
  NEW: 'NEW',
  UNANSWERED: 'UNANSWERED',
  UNANSWERED_NEW: 'UNANSWERED_NEW',
  ONLY_FULL_INTEREST: 'ONLY_FULL_INTEREST',
  INTEREST_MARKED: 'INTEREST_MARKED',
  MARKED: 'MARKED',
  SWAPLIST_MARKED: 'SWAPLIST_MARKED',
  LAST_PART: 'LAST_PART',
  FAVOURITES: 'FAVOURITES'
};

export const SWAP_VIEW_TABS = {
  SWAP: 'SWAP',
  FULL_INTEREST: 'FULL_INTEREST',
  SWAP_ALTERNATIVES: 'SWAP_ALTERNATIVES',
  SWAP_INFO: 'SWAP_INFO',
  CHAT: 'CHAT',
  MAP: 'MAP'
};
export const SORT_TYPES = {
  HIGHEST_SCORE: 'HIGHEST_SCORE',
  SQM: 'SQM',
  ROOMS: 'ROOMS',
  RENT: 'RENT',
  PERCENTAGE: 'PERCENTAGE',
  INTEREST_DATE: 'INTEREST_DATE',
  FLOOR: 'FLOOR',
  POSTAL_AREA: 'POSTAL_AREA',
  SEEN_AT: 'SEEN_AT',
  ADDRESS: 'ADDRESS',
  TYPE: 'TYPE'
};

export const ACTIVE_SORT_TYPES = {
  HIGHEST_SCORE: SORT_TYPES.HIGHEST_SCORE,
  SQM: SORT_TYPES.SQM,
  ROOMS: SORT_TYPES.ROOMS,
  RENT: SORT_TYPES.RENT,
  INTEREST_DATE: SORT_TYPES.INTEREST_DATE
};

export const SORT_DIRECTIONS = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING'
};

export const FETCH_MODE = {
  REPLACE: 0,
  MERGE: 1
};

export const FILTER_SETUP = {
  rent: {
    min: 1,
    max: 30000
  },
  rooms: {
    min: 1,
    max: 6
  },
  floor: {
    min: 0,
    max: 10
  },
  sqm: {
    min: 1,
    max: 300
  }
};

export const INITIAL_FILTER = {
  rent: {
    min: 1,
    max: 30000
  },
  rooms: {
    min: 1,
    max: 6
  },
  floor: {
    min: 0,
    max: 10
  },
  sqm: {
    min: 1,
    max: 300
  },
  percentage: undefined,
  elevator: undefined,
  balcony: undefined,
  bathtub: undefined,
  isCoop: undefined,
  isBecomingCoop: undefined,
  showDenied: false,
  isDirectSwap: undefined,
  isTriangleSwap: undefined,
  isNotExtendedSwap: undefined,
  isNew: undefined,
  isFavourite: undefined,
  postalAreas: []
};

const state = {
  swapItemMode: getInitialSwapItemType(),
  swapListType: SWAP_LIST_TYPES.LIST,
  swapFilterType: SWAP_FILTER_TYPES.UNANSWERED,
  sortType: SORT_TYPES.HIGHEST_SCORE,

  address: null,
  listPropositionId: null,

  swaps: [],
  premiumSwaps: [],

  currentPage: 1,
  totalPages: 1,

  areaGroups: [],

  loading: true,
  fullInterestSwaps: undefined,
  newSwaps: undefined,
  undistributed: 0,
  unmarkedSwaps: 0,
  filterCount: 0,
  preFilterCountTotal: undefined,
  preFilterCountUnmarked: undefined,
  postFilterCountTotal: 0,
  postFilterCountWithDiffs: 0,
  postFilterCountUnmarked: 0,
  swapInterestCounts: undefined,

  swapView: {
    isOpen: false,
    currentTab: SWAP_VIEW_TABS.SWAP,
    swapDetails: {},
    swapAlternatives: {},
    swapAlternativesLoading: false
  },

  filter: getFilterInitial(),
  sortDirection: SORT_DIRECTIONS.DESCENDING,
  searchTerm: '',

  filterSetup: getFilterSetup(),

  showSwapAddedBadge: false,

  queueCount: null,
  hasMore: false
};

function getFilterSetup() {
  if (!country.isCountry('se')) {
    FILTER_SETUP.rent.max = 3000;
  }

  return FILTER_SETUP;
}

function getFilterInitial() {
  if (!country.isCountry('se')) {
    INITIAL_FILTER.rent.max = 3000;
  }

  return INITIAL_FILTER;
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
