<template>
  <div
    id="proposition-wrapper"
    ref="propositionWrapper"
    :class="['proposition-wrapper', { 'in-modal': inModal }]"
  >
    <div
      v-if="shouldShowOnboardingBanner && currentPropositionIsPrimary"
      class="onboarding-wrapper"
    >
      <OnboardingBanner />
    </div>

    <ResidenceSwitcher
      v-if="
        currentProposition &&
        currentProposition.residences &&
        currentProposition.residences.length > 1 &&
        isMobile
      "
      :set-current-residence="handleSwitchResidence"
      :current-residence-index="currentResidenceIndex"
      :residence1="currentProposition.residences[0].streetAddress"
      :residence2="currentProposition.residences[1].streetAddress"
      style="margin: 5% auto; width: 90%"
    />

    <DesktopPropositionHero
      v-if="!isMobile && currentResidence && appReady"
      :coords="currentResidenceCoords"
      :residence="currentResidence"
      :proposition-id="currentProposition.propositionId"
      :residence-index="currentResidenceIndex"
      :in-modal="inModal"
      @open-gallery="openGalleryModal"
    />
    <TouchImageSlider
      v-else-if="
        isMobile &&
        currentResidence &&
        currentResidence.images.length &&
        appReady
      "
      :lock-scroll="lockScroll"
      :unlock-scroll="unlockScroll"
      :residence-index="currentResidenceIndex"
      :proposition-id="currentProposition.propositionId"
      :proposition="currentResidence"
      :images="currentResidence.images"
      :in-modal="inModal"
      :blur-images="blurGalleryImages"
      @open-gallery="openGalleryModal"
    />
    <ImagePlaceholder v-else :is-loading="!currentResidence || !appReady" />

    <div :class="[{ 'desktop-padding': !isMobile }]">
      <div class="row information-maring-wrapper">
        <div class="information-wrapper">
          <div v-if="currentProposition.inSwapProcess" class="infobox">
            <b>{{ $t('proposition_infobox_row_1') }}&nbsp;</b>
            {{ $t('proposition_infobox_row_2') }}
          </div>

          <DesktopResidenceSwitcher
            v-if="
              !isMobile &&
              currentProposition &&
              currentProposition.residences &&
              currentProposition.residences.length > 1
            "
            :set-current-residence="handleSwitchResidence"
            :current-residence-index="currentResidenceIndex"
            :current-proposition="currentProposition"
          />

          <div
            v-if="
              appReady &&
              isAuthenticated &&
              currentResidence &&
              !currentProposition.isOwner &&
              !primaryPropositionActive
            "
            class="infobox dark"
          >
            <div class="upgrade-icon" />

            <router-link
              :to="`${$routes.PAYMENT_PROPOSITION}/${primaryProposition.propositionId}`"
              class="payment-link"
            >
              <b class="bold" style="font-size: 0.95rem">
                {{ $t('proposition_ad_inactive') }}
              </b>
              {{ $t('proposition_activate') }}
            </router-link>
            <router-link
              :to="`${$routes.PAYMENT_PROPOSITION}/${primaryProposition.propositionId}`"
              class=""
            >
              <button
                class="activate-button"
                @click="trackPaymentClick('proposition-activate')"
              >
                {{ $t('proposition_activate_action') }}
              </button>
            </router-link>
          </div>

          <div
            v-if="
              currentProposition.isOwner &&
              currentProposition.showActivateUsersPropositionBanner
            "
            class="infobox dark"
          >
            <div class="upgrade-icon" />

            <router-link
              :to="`${$routes.PAYMENT_PROPOSITION}/${currentProposition.propositionId}`"
              class="payment-link"
            >
              <b class="bold" style="font-size: 0.95rem">{{
                $t('proposition_this_ad_inactive')
              }}</b
              >{{ $t('proposition_activate_again') }}
            </router-link>
          </div>

          <Description
            v-if="currentResidence"
            :residence="currentResidence"
            :is-user-proposition="currentProposition.isOwner"
            :proposition-no="currentProposition.propositionNo"
            :is-favourite="currentProposition.favourite"
            :proposition-id="currentProposition.propositionId"
            :swap-type="currentProposition.swapType"
            :scroll-to-map="scrollToMap"
            @toggleConversionModal="toggleConversionModal"
          >
          </Description>

          <DescriptionLoader v-else />

          <div
            v-if="!currentProposition.isOwner && isMobile && !!currentResidence"
            class="proposition-interest-wrapper-mobile"
          >
            <PropositionButtons
              :interested="currentProposition.interested"
              :mark-interest="handleMarkInterest"
              :is-loading="interestDataLoading"
              @toggleConversionModal="toggleConversionModal"
            />
          </div>

          <template v-if="isMobile">
            <ThreePartSwapNotification
              v-if="propositionViewModalType === 'triangleswap'"
              :proposition-id="currentProposition.propositionId"
              class="three-part-notification-mobile"
              :on-show-swap-button-pressed="
                () => setPageModalActivePage('swapdetails')
              "
            />
            <UserCardLoader v-if="showUserCardLoader" />
            <UserCard
              v-else-if="showUserCard"
              :user="currentUser"
              :proposition-id="currentProposition.propositionId"
              :open-contact-modal="handleAttemptOpenContactModal"
            />
          </template>
        </div>

        <PropositionInterestBox
          v-if="
            currentProposition &&
            !interestDataLoading &&
            !currentProposition.isOwner &&
            !isMobile &&
            (primaryPropositionId || !isAuthenticated)
          "
          :proposition-image="currentPropositionThumbnail"
          :user="currentUser"
          :proposition-id="currentProposition.propositionId"
          :primary-proposition-id="primaryPropositionId"
          :interested="currentProposition.interested"
          :mark-interest="handleMarkInterest"
          :open-contact-modal="handleAttemptOpenContactModal"
          :on-three-part-swap-link-click="
            () => setPageModalActivePage('swapdetails')
          "
          :proposition-address="currentProposition.residences[0].streetAddress"
          @toggleConversionModal="toggleConversionModal"
        />

        <PropositionInterestBoxLoader
          v-else-if="
            interestDataLoading && !currentProposition.isOwner && !isMobile
          "
        />
      </div>

      <div
        v-if="isMobile && currentResidence && currentResidenceCoords"
        class="section-wrapper full-width"
      >
        <div class="section-title full-width">
          {{ $t('proposition_area_title') }}
        </div>

        <MapAndStreetView
          v-if="appReady && isMobile && currentResidenceCoords"
          ref="mapview"
          :coords="currentResidenceCoords"
          :residence="currentResidence"
        />

        <div
          v-if="$country.isFeatureEnabled('PROPOSITIONS_CLOSE_BY')"
          class="section-wrapper"
        >
          <HittaAmenities
            v-if="currentProposition.propositionId"
            :proposition-id="currentProposition.propositionId"
            :residence-index="currentResidenceIndex"
            :coordinates="currentResidenceCoords"
          />
        </div>
      </div>

      <ViewsCounter
        v-if="currentProposition.pageVisits"
        :visits="currentProposition.pageVisits"
      />

      <div class="section-wrapper column">
        <div
          v-if="$country.isFeatureEnabled('PROPOSITIONS_CLOSE_BY') && !isMobile"
          class="area-info-container"
        >
          <div class="section-title">
            {{ $t('proposition_area_title') }}
          </div>
          <HittaAmenities
            v-if="currentProposition.propositionId"
            :proposition-id="currentProposition.propositionId"
            :residence-index="currentResidenceIndex"
            :coordinates="currentResidenceCoords"
          />
        </div>
        <div class="border-container">
          <Transition name="slide-down">
            <div
              v-if="showPropositionWishes || !isAuthenticated"
              class="proposition-wishes-wrapper"
            >
              <div v-if="currentProposition.wishes" class="section-title">
                {{ $t('proposition_wishes_title') }}
              </div>

              <ThreePartSwapNotification
                v-if="propositionViewModalType === 'triangleswap'"
                :proposition-id="currentProposition.propositionId"
                class="three-part-notification-mobile"
                :on-show-swap-button-pressed="
                  () => setPageModalActivePage('swapdetails')
                "
              />

              <PropositionWishes
                v-if="currentProposition.wishes && isMobile && appReady"
                :wishes="currentProposition.wishes"
                :swap-type="currentProposition.swapType"
              />

              <div
                v-else-if="currentProposition.wishes && !isMobile && appReady"
              >
                <PropositionWishes
                  :wishes="currentProposition.wishes"
                  :swap-type="currentProposition.swapType"
                />
              </div>
            </div>
          </Transition>
          <div
            v-if="!showPropositionWishes && isAuthenticated"
            class="toggle-show-wishes-button-wrapper"
          >
            <BaseButton
              class="grey outline"
              @click="toggleShowPropositionWishes"
            >
              {{ $t('proposition_wishes_title') }}</BaseButton
            >
          </div>
        </div>
        <ReportProposition v-if="isAuthenticated" />
      </div>

      <SponsoredPropositions
        v-if="
          $country.isFeatureEnabled('EXTRA_VISIBILITY') &&
          isAuthenticated &&
          sponsoredPropositions &&
          sponsoredPropositions.length
        "
        :propositions="sponsoredPropositions"
        :title="$t('proposition_extra_visibility_title')"
      />
    </div>

    <MConversionBanner
      v-if="!isAuthenticated"
      class="conversion-banner-proposition-page"
    />

    <TheFooter :is-authenticated="isAuthenticated" />

    <!-- MODALS -->
    <BaseModal
      v-if="showConversionModal"
      :title="$t('proposition_conversion_modal_title')"
      @close="toggleConversionModal"
    >
      <ConversionModalContent />
    </BaseModal>

    <ModalWithInternalOverlay
      v-if="contactModalOpen"
      :close-function="closeContactModal"
      :title="contactModalTitle"
      class="on-prop-page"
    >
      <div slot="content" class="messenger-wrapper">
        <Messages
          v-if="
            (!isFreemiumLocked && primaryProposition.isFreemium) ||
            primaryPropositionIsPremiumOrFree
          "
          :standalone="true"
          :proposition-ids="[
            primaryPropositionId,
            currentProposition.propositionId
          ]"
          :user-id="user.userId"
          :is-impersonated="user.isImpersonated"
          :is-mobile="isMobile"
          where="proposition"
          mode="plain"
          class="in-modal"
          @title-changed="setContactModalTitle"
        />
      </div>
    </ModalWithInternalOverlay>

    <TrialCTAModal
      v-if="trialCTAModalOpen || freemiumCTAModalOpen"
      :close-function="closeCTAModal"
      :payment-page-proposition-id="primaryPropositionId"
      where="proposition"
    />

    <PageModal
      v-if="showGalleryModal"
      :show-modal="showGalleryModal"
      :page-modal-name="'proposition-gallery'"
      @close="closeGalleryModal"
    >
      <PropositionGallery
        :images="currentPropositionViewImages"
        :proposition="currentPropositionView"
        :blur-images="blurGalleryImages"
      />
    </PageModal>
    <!-- MODALS END -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ResidenceSwitcher from '../../components/Proposition/ResidenceSwitcher';
import DesktopResidenceSwitcher from '../../components/Proposition/ResidenceSwitcherDesktop';
import TouchImageSlider from '../../components/ImageSlider/TouchSlider';
import DesktopPropositionHero from '../../components/Proposition/DesktopPropositionHero';
import ImagePlaceholder from '../../components/ImageSlider/ImagePlaceholder';
import Description from '../../components/Proposition/Description/Description';
import DescriptionLoader from '../../components/Proposition/Description/Loader';
import PropositionButtons from '../../components/Buttons/PropositionButtons';
import UserCard from '../../components/Proposition/UserCard/UserCard';
import UserCardLoader from '../../components/Proposition/UserCard/Loader';
import ViewsCounter from '../../components/Proposition/ViewCounter';
import HittaAmenities from '../../components/Proposition/HittaAmenities';
import MapAndStreetView from '../../components/Maps/MapAndStreetView';
import PropositionInterestBox from '../../components/Proposition/PropositionInterestBox/PropositionInterestBox';
import PropositionInterestBoxLoader from '../../components/Proposition/PropositionInterestBox/Loader';
import ModalWithInternalOverlay from '../../components/Modals/ModalWithInternalOverlay';
import Messages from '@/views/Chat/Messages';
import TrialCTAModal from '../../components/Modals/TrialCTAModal';
import OnboardingBanner from '../../components/Onboarding/OnboardingBanner';
import TheFooter from '../../components/TheFooter';
import BaseModal from '@/components/BaseModal';
import MConversionBanner from '@/components/atomic/molecules/m-conversion-banner';
import ConversionModalContent from '@/components/Proposition/ConversionModalContent';
import { NO_IMAGE } from '@/utils/helpers/default-values';
import descriptionWithMaxLength from '@/utils/descriptionWithMaxLength';
import SponsoredPropositions from '@/components/SponsoredPropositions/SponsoredPropositions';
import PageModal from '@/components/Modals/PageModal/PageModal';
import PropositionGallery from '../../components/Proposition/PropositionGallery';
import PropositionWishes from '../../components/Proposition/Wishes';
import events from '@/utils/helpers/events';
import ReportProposition from '../../components/Proposition/Report/ReportProposition.vue';
import {
  removeRightClickTracker,
  getRightClickTracker
} from '@/utils/helpers/rightClick';
import { lbApiBeta } from '../../utils/axiosConfig';
import ThreePartSwapNotification from '../../components/Proposition/ThreePartSwapNotification.vue';

export default {
  name: 'Proposition',

  components: {
    PropositionWishes,
    ResidenceSwitcher,
    DesktopResidenceSwitcher,
    TouchImageSlider,
    DesktopPropositionHero,
    MapAndStreetView,
    Description,
    DescriptionLoader,
    PropositionButtons,
    UserCard,
    UserCardLoader,
    ViewsCounter,
    ImagePlaceholder,
    PropositionInterestBox,
    PropositionInterestBoxLoader,
    HittaAmenities,
    TheFooter,
    ModalWithInternalOverlay,
    TrialCTAModal,
    OnboardingBanner,
    BaseModal,
    MConversionBanner,
    ConversionModalContent,
    Messages,
    SponsoredPropositions,
    PageModal,
    PropositionGallery,
    ReportProposition,
    ThreePartSwapNotification
  },

  props: {
    propositionId: {
      type: Number,
      default: null
    },
    inModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      scrollLocked: false,
      contactModalOpen: false,
      contactModalTitle: '',
      notesModalOpen: false,
      trialCTAModalOpen: false,
      freemiumCTAModalOpen: false,
      showConversionModal: false,
      showGalleryModal: false,
      showPropositionWishes: false,
      showModal: false,
      isFreemiumLocked: false,
      alternativeThreeParts: 0
    };
  },

  head: {
    title() {
      return {
        inner: this.currentTitle || ''
      };
    },

    meta() {
      return [
        {
          name: 'description',
          content: descriptionWithMaxLength(this.currentResidence.description),
          id: 'desc'
        },
        {
          property: 'og:title',
          content: this.currentTitle || '',
          id: 'ogtitle'
        },
        { property: 'og:url', content: window.location.href, id: 'ogurl' },
        { property: 'og:image', content: this.metaImage, id: 'ogimage' },
        {
          property: 'og:description',
          content: descriptionWithMaxLength(this.currentResidence.description),
          id: 'ogdesc'
        }
      ];
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      currentProposition: 'propositions/currentProposition',
      currentUser: 'propositions/currentUser',
      currentResidenceIndex: 'propositions/currentResidenceIndex',
      currentResidenceCoords: 'propositions/currentResidenceCoords',
      baseLoading: 'propositions/baseLoading',
      interestDataLoading: 'propositions/interestDataLoading',
      sponsoredPropositions: 'propositions/sponsoredPropositions',
      isCurrentVisited: 'propositions/isCurrentVisited',
      myPropositions: 'myPropositions/propositions',
      primaryProposition: 'myPropositions/primaryProposition',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryPropositionActive: 'myPropositions/primaryPropositionActive',
      blurGalleryImages: 'myPropositions/blurGalleryImages',
      userPropositions: 'myPropositions/propositions',
      appReady: 'app/ready',
      user: 'app/user',
      shouldShowOnboardingBanner: 'myPropositions/onboardingState',
      isAuthenticated: 'app/isAuthenticated',
      propositionViewModalId: 'ui/propositionViewModalId',
      propositionViewModalType: 'ui/propositionViewModalType',
      primaryPropositionIsPremiumOrFree:
        'myPropositions/primaryPropositionIsPremiumOrFree'
    }),

    propositionIds() {
      return [this.primaryPropositionId, this.currentProposition.propositionId];
    },

    showUserCard() {
      if (!this.currentProposition) {
        return false;
      }
      if (this.currentProposition.isOwner) {
        return false;
      }
      if (!this.currentUser) {
        return false;
      }
      if (!this.isAuthenticated) {
        return true;
      }
      if (!this.primaryPropositionId) {
        return false;
      }

      return true;
    },

    showUserCardLoader() {
      return !this.currentProposition;
    },

    hasAmenities() {
      const { currentResidence } = this;
      const amenities = [
        'balcony',
        'elevator',
        'fireplace',
        'bathtub',
        'isCondo',
        'isBecomingCondo'
      ];
      return amenities.some(amenity => currentResidence[amenity]);
    },

    currentResidence() {
      if (
        this.currentProposition &&
        this.currentProposition.residences &&
        this.currentProposition.residences.length
      ) {
        return this.currentProposition.residences[this.currentResidenceIndex];
      }

      return false;
    },

    currentPropositionView() {
      if (
        this.currentProposition &&
        this.currentProposition.residences &&
        this.currentProposition.residences.length
      ) {
        return this.currentProposition.residences[this.currentResidenceIndex];
      } else {
        return {};
      }
    },

    currentPropositionViewImages() {
      if (
        this.currentProposition &&
        this.currentProposition.residences &&
        this.currentProposition.residences.length
      ) {
        return this.currentProposition.residences[this.currentResidenceIndex]
          .images;
      } else {
        return [];
      }
    },

    currentPropositionThumbnail() {
      const { currentProposition } = this;

      if (!currentProposition.residences) return '';

      return currentProposition.residences[0] &&
        currentProposition.residences[0].images.length
        ? currentProposition.residences[0].images[0].fileName
        : '';
    },

    currentTitle() {
      const { currentResidence } = this;

      if (!currentResidence) return false;

      return `${currentResidence.rooms} ${this.$t('proposition_rooms')}, ${
        currentResidence.sqm
      } ${this.$t('proposition_sqm')} - ${
        currentResidence.streetAddress
      } ${this.$t('proposition_in')} ${
        currentResidence.postalAddress
      } ${this.$t('proposition_wish_to_swap')}`;
    },

    primaryPropositionWithDetails() {
      const { primaryProposition, userPropositions } = this;

      return userPropositions.find(
        p => p.propositionId === primaryProposition.propositionId
      );
    },

    currentPropositionIsPrimary() {
      const { currentProposition, primaryPropositionId } = this;
      return currentProposition.propositionId === primaryPropositionId;
    },

    metaImage() {
      if (this.currentResidence && this.currentResidence.images.length > 0) {
        return this.currentResidence.images[0].fileName;
      } else {
        return `${window.location.protocol}//${window.location.host}${NO_IMAGE}`;
      }
    }
  },

  watch: {
    $route(to, from) {
      const fromId = from.params.id;
      const toId = to.params.id;

      if (fromId != toId) {
        this.initializeNewProposition();
        this.updatePageTitle(toId);
      }

      if (from.query.propositionPageId && !to.query.propositionPageId) {
        this.handleGetProposition();
      }

      if (to.hash == '#gallery') {
        this.showGalleryModal = true;
      }

      if (from.hash == '#gallery') {
        this.showGalleryModal = false;
      }
    },

    propositionId() {
      this.handleGetProposition();
    },

    currentTitle(to) {
      if (to) {
        this.updatePageTitle(this.currentTitle);
      }
    },

    currentProposition(to, from) {
      if (!this.currentProposition || !this.currentProposition.propositionId) {
        return;
      }

      if (from.propositionId === to.propositionId) {
        return;
      }

      if (this.inModal) {
        document.getElementById('page-modal-content').scrollTop = 0;
      }

      const isPrerenderer = window.location.href.indexOf('#prerender') > -1;
      if (!isPrerenderer) {
        return;
      }

      let prerenderedState = document.getElementById('prerendered-proposition');

      if (!prerenderedState) {
        prerenderedState = document.createElement('script');
        prerenderedState.id = 'prerendered-proposition';
      }

      const stringifiedState = JSON.stringify({
        residenceIndex: this.currentResidenceIndex,
        proposition: this.currentProposition
      });

      prerenderedState.innerHTML = `window.prerenderedPropositionState = ${stringifiedState}`;

      document.head.append(prerenderedState);
      window.prerenderPaused = false;
    }
  },

  mounted() {
    // Support for both Page and Modal view
    const id = this.$route.params?.id || `${this.propositionViewModalId}`;
    events.emitEvent(events.eventTypes.PAGEVIEW, 'Proposition', {
      id
    });

    if (window.prerenderedPropositionState) {
      let prerenderedState;

      if (typeof window.prerenderedPropositionState === 'object') {
        prerenderedState = window.prerenderedPropositionState;
      } else {
        prerenderedState = JSON.parse(
          window.prerenderedPropositionState
            .replace(/\r\n/g, ' ')
            .replace(/\t/g, ' ')
        );
      }

      this.setPrerenderedProposition(prerenderedState);
      return;
    }

    this.handleGetProposition();
    this.initializeScrollLock();

    if (!this.inModal) window.scrollTo(0, 0);

    if (window.history.length === 1) {
      this.sendNewTabOpenedClickEvent();
    }
  },

  created() {
    window.prerenderPaused = true;
  },

  methods: {
    ...mapActions({
      setCurrentProposition: 'propositions/setCurrentProposition',
      setPrerenderedProposition: 'propositions/setPrerenderedProposition',
      getSponsoredPropositions: 'propositions/getSponsoredPropositions',
      updateCurrentVisited: 'propositions/updateCurrentVisited',
      setCurrentResidence: 'propositions/setCurrentResidence',
      markInterest: 'interests/markInterest',
      trackPropositionVisited: 'tracking/trackPropositionVisited',
      clearCurrentProposition: 'propositions/clearCurrentProposition',
      setGlobalModal: 'ui/setGlobalModal',
      setPageModalActivePage: 'ui/setPageModalActivePage'
    }),

    toggleShowPropositionWishes() {
      this.showPropositionWishes = !this.showPropositionWishes;
    },

    openGalleryModal() {
      this.$router.push({ path: this.$route.fullPath, hash: 'gallery' });
    },

    closeGalleryModal() {
      this.$router.go(-1);
    },

    trackPaymentClick(elementId) {
      events.emitEvent(events.eventTypes.CLICK, 'Payment', {
        id: elementId
      });
    },

    toggleConversionModal() {
      this.showConversionModal = !this.showConversionModal;
    },

    scrollToMap() {
      const ref = this.$refs.mapview;

      if (ref) {
        ref.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    },

    openContactModal() {
      events.emitEvent(events.eventTypes.CLICK, 'Start Chat', {
        id: 'chat-proposition'
      });
      this.contactModalOpen = true;
    },

    closeContactModal() {
      this.contactModalOpen = false;
    },

    closeCTAModal() {
      this.trialCTAModalOpen = false;
      this.freemiumCTAModalOpen = false;
    },

    handleSwitchResidence({ index }) {
      this.setCurrentResidence({ index });

      if (!this.inModal) {
        this.$router.replace(
          `${this.$t('path_proposition', this.$routeLocale)}/${
            this.currentProposition.propositionId
          }/${index + 1}`
        );
      }
    },

    async getRoom(propositionIds) {
      const { data } = await lbApiBeta.post(
        '/api/messenger/chat-room/find-or-mock',
        {
          propositionIds: propositionIds
        }
      );

      return data;
    },

    async handleAttemptOpenContactModal() {
      if (!this.isAuthenticated) {
        this.toggleConversionModal();
        return;
      }

      if (!this.primaryPropositionActive) {
        events.emitEvent(events.eventTypes.CLICK, 'Payment', {
          id: 'no-active-propositions-proposition'
        });

        this.$router.push(
          `${this.$routes.PAYMENT_PROPOSITION}/${this.primaryPropositionId}`
        );
        return;
      }

      if (this.primaryProposition.isTrial) {
        this.trialCTAModalOpen = true;
        return;
      }

      if (this.propositionIds && this.propositionIds.length > 0) {
        const room = await this.getRoom(this.propositionIds);
        if (this.primaryProposition.isFreemium && room.isFreemiumLocked) {
          this.handleFreemiumLocked(room.isFreemiumLocked);
          return;
        }
      } else {
        console.warn('propositionIds is empty');
        return;
      }

      this.openContactModal();
    },

    handleFreemiumLocked(isLocked) {
      this.isFreemiumLocked = isLocked;
      if (isLocked && this.primaryProposition.isFreemium) {
        this.freemiumCTAModalOpen = true;
        this.contactModalOpen = false;
      }
    },

    async handleGetProposition() {
      let id = null,
        residenceIndex = null;
      if (this.propositionId) {
        id = this.propositionId;
      } else {
        id = this.$route.params.id;
        residenceIndex = this.$route.params.residenceIndex;
      }

      await this.setCurrentProposition({
        propositionId: id,
        residenceIndex: residenceIndex || '1'
      });

      this.$emit('updateHead');

      setTimeout(() => {
        if (!this.inModal) this.getSponsoredPropositions(id);
      }, 500);
    },

    initializeNewProposition() {
      this.clearCurrentProposition();
      if (!this.inModal) window.scroll(0, 0);
      this.handleGetProposition();
    },

    changeProposition(propositionId) {
      this.$router.push(
        `${this.$t('path_proposition', this.$routeLocale)}/${propositionId}`
      );
    },

    handleSetVisited(visited) {
      const { id } = this.$route.params;

      this.updateCurrentVisited({ propositionId: id, visited });
    },

    handleMarkInterest(interested) {
      this.markInterest({
        targetPropositionId: this.currentProposition.propositionId,
        interested,
        where: 'proposition-box'
      });
    },

    lockScroll() {
      this.scrollLocked = true;
    },

    unlockScroll() {
      this.scrollLocked = false;
    },

    initializeScrollLock() {
      const preventDefaultIfLocked = e => {
        if (!this.scrollLocked) {
          return;
        }

        e = e || window.event;
        if (e.preventDefault) {
          e.preventDefault();
        }
        e.returnValue = false;
      };

      window.addEventListener('touchmove', preventDefaultIfLocked, {
        passive: false
      });
    },

    updatePageTitle(title) {
      if (!title) return;
      document.title = title;
    },

    sendNewTabOpenedClickEvent() {
      const ad = getRightClickTracker(
        this.propositionId || parseInt(this.$route.params.id)
      );

      if (ad) {
        events.emitEvent(events.eventTypes.CLICK, 'View Proposition', {
          src: ad.s,
          type: 'right-click',
          propositionId: ad.p
        });

        removeRightClickTracker(ad.p);
      }
    },

    setContactModalTitle(title) {
      this.contactModalTitle = title;
    }
  }
};
</script>

<style lang="scss" scoped>
.proposition-wrapper {
  position: relative;
  padding: 0;

  @media ($desktop) {
    max-width: none !important;
    width: 100% !important;
    padding: $desktop-menu-height 0 0 0 !important;

    &.in-modal {
      padding: 0 !important;
    }
  }
}

.toggle-show-wishes-button-wrapper {
  button {
    border-color: #bebfc8;

    &:hover {
      border-color: initial;
    }
  }
  margin-bottom: 20px;
}

.desktop-padding {
  max-width: 1200px;
  width: 95%;
  margin: 0 auto;
}

.trial-margin-wrapper {
  @media ($desktop) {
    padding-top: calc(
      #{$desktop-menu-height} + 103px
    ); // desktop-menu + trialbanner, sass variable messing up trial state
  }

  @media ($mobile) {
    // padding-top: 0;
    padding-bottom: 190px;
  }
}

.sticky-container {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 100px;
  width: 370px;
  height: fit-content;
  margin-top: 20px;

  .in-modal & {
    top: 20px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.information-maring-wrapper {
  @media ($desktop) {
    margin-top: 20px;
  }
}

.information-wrapper {
  width: 100%;
  @media ($desktop) {
    width: 66%;
  }
}

.section-wrapper {
  width: 90%;
  margin: 0 auto;
  @media ($desktop) {
    width: 100%;
  }

  &.full-width {
    width: 100%;
  }
}
.section-title {
  font-size: 1.3rem;
  font-weight: 700;
  margin: 20px 0 16px 0;
  line-height: 150%;

  &.flex-space-between {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ($mobile) {
      width: 90vw;
    }
  }

  & .extra-vis-button {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    color: $text-secondary;
    font-size: 0.7em;
    font-weight: 600;
  }
  @media ($mobile) {
    margin: 10px 0 16px 0;
  }

  &.full-width {
    width: 90%;
    margin: 20px auto 16px auto;
  }
}

.full-width-section {
  @media ($mobile) {
    width: 100%;
    & > .section-title {
      margin-left: 5vw;
    }
  }
}

.infobox {
  display: flex;
  flex-direction: row;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff8e6;
  border-color: #ffc212;
  color: #1c2d41;
  font-size: 14px;
  line-height: 1.4;
  margin: 0 0 10px 0;
  opacity: 1;
  padding: 8px 14px;
  transition: opacity 0.4s;
  width: 90%;
  margin: 0 auto;
  width: 100vw;
  font-weight: 600;
  align-items: center;

  @media ($desktop) {
    width: 100%;
    margin-top: 20px;
  }

  @media ($mobile) {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    display: inherit;
  }
}

.activate-button {
  width: 100%;
  border: 1px solid $transfer-blue;
  border-radius: $small-box-radius;
  background-color: none;
  background: none;
  color: $transfer-blue;
  font-weight: 700;
  font-size: 0.9rem;
  font-family: inherit;
  padding: 14px;
  margin-bottom: 8px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin-top: 15px;

  @media ($desktop) {
    width: 250px;
  }

  @media ($mobile) {
  }
}

.dark {
  background-color: #eff4fe;
  border-color: $transfer-blue;
  padding: 14px;
}

.border-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: 1px solid rgba(28, 45, 65, 0.103);
  margin-top: 10px;
  margin-bottom: 30px;
  padding-top: 20px;

  @media ($desktop) {
    border-bottom: 1px solid rgba(28, 45, 65, 0.103);
  }
}

.infobox b {
  @media ($mobile) {
    display: block;
  }
}

.payment-link {
  width: 100%;
  text-decoration: none;
  color: $transfer-blue;
  font-weight: 600;
}

.error-icon {
  min-height: 15px;
  min-width: 15px;
  background-size: contain;
  background-position: left;
  background-image: url('../../assets/svg/exclamation-mark.svg');
  margin-right: 10px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-image: none;
  }
}

.upgrade-icon {
  min-height: 25px;
  min-width: 25px;
  background-size: contain;
  background-position: left;
  background-image: url('../../assets/svg/upgrade-star.svg');
  margin-right: 10px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-image: none;
  }

  @media ($mobile) {
    margin-bottom: 10px;
  }
}

.bold {
  display: block;
}

.area-info-container {
  margin-top: 20px;
  border-top: 1px solid rgba(28, 45, 65, 0.103);
  padding-top: 30px;
}

.proposition-interest-wrapper-mobile {
  position: fixed;
  bottom: 58px;
  height: 59px;
  padding: 8px 16px;
  background-color: #f0f1f4;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 10;

  .in-modal & {
    bottom: 0;
  }

  .is-not-authenticated & {
    bottom: 0;
  }
}

.onboarding-wrapper {
  max-width: 1600px;
  width: 95%;
  margin: 20px auto;
}

.messenger-wrapper {
  height: 100%;
  padding: 20px;
  @media ($mobile) {
    padding: 0px;
  }
}

.conversion-banner-proposition-page {
  @media ($mobile) {
    margin-top: 40px;
  }
}

.three-part-notification-mobile {
  margin: 20px;
  margin-top: 0px;
}
</style>
