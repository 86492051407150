<template>
  <div class="area-map-wrapper">
    <div id="area-picker-map" class="map"></div>
  </div>
</template>

<script>
import turf from 'turf';
import { mapGetters } from 'vuex';
import { loadJs, loadCss } from '@/utils/maptiler';

export default {
  name: 'AreaPickerMap',

  props: {
    currentPolygon: {
      type: Object,
      required: true
    },
    zoom: {
      type: Number,
      required: true
    },
    center: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      map: null,
      ready: false
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  },
  watch: {
    currentPolygon() {
      if (!this.ready) {
        return;
      }

      this.updateAreas();
    },
    isMobile() {
      if (!this.ready) {
        return;
      }

      if (this.isMobile) {
        this.map.scrollZoom.disable();
        this.map.dragPan.disable();
      } else {
        this.map.scrollZoom.enable();
        this.map.dragPan.enable();
      }
    }
  },

  async mounted() {
    await Promise.all([loadJs(), loadCss()]);
    this.initializeMap();
  },

  methods: {
    async initializeMap() {
      const el = document.getElementById('area-picker-map');
      if (!el) {
        return;
      }

      if (!this.ready) {
        this.map = new maplibregl.Map({
          container: el,
          center: this.center,
          style:
            'https://api.maptiler.com/maps/bc61ba4d-d8c0-49f0-b6c2-52c6004aee6f/style.json?key=1CDi4tTTlJKFFQHgS5zi',
          zoom: this.zoom,
          attributionControl: false,
          logoPosition: 'bottom-right'
        });
      }

      this.map.addControl(
        new maplibregl.NavigationControl({
          showCompass: false
        })
      );

      if (this.isMobile) {
        this.map.scrollZoom.disable();
        this.map.dragPan.disable();
      }

      this.map.on('load', () => {
        this.ready = true;
        this.updateAreas();
      });
    },

    clearPolygons() {
      if (this.map.getLayer('layer')) {
        this.map.removeLayer('layer');
      }

      if (this.map.getLayer('borderlayer')) {
        this.map.removeLayer('borderlayer');
      }

      if (this.map.getSource('layer')) {
        this.map.removeSource('layer');
      }
    },

    updateAreas() {
      try {
        this.clearPolygons();

        // Reset map to default center if all polygons (areas) is removed
        if (
          this.currentPolygon.geometry &&
          this.currentPolygon.geometry.coordinates.length === 0
        ) {
          this.map.flyTo({
            center: this.center,
            zoom: this.zoom
          });
        }

        this.map.addSource('layer', {
          type: 'geojson',
          data: this.currentPolygon
        });

        this.map.addLayer({
          id: 'layer',
          type: 'fill',
          source: 'layer',
          paint: {
            'fill-color': '#bc4ae4',
            'fill-opacity': 0.2
          }
        });

        this.map.addLayer({
          id: 'borderlayer',
          type: 'line',
          source: 'layer',
          paint: {
            'line-dasharray': [2, 2],

            'line-width': 1,
            'line-color': '#6742d2',
            'line-opacity': 1
          }
        });

        const bounds = turf.bbox(this.currentPolygon);

        this.map.fitBounds(bounds, { padding: 20 });
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.area-map-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.map {
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  background-color: $bg-gray;
  outline: none;

  & > * {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
  }
}
</style>
