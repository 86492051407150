import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const getInitialState = () => ({
  products: {},
  offers: {},
  loading: true,
  cancelModalOpen: false,
  cancelReasons: {
    category: '',
    categoryIndex: null,
    specifiedCategory: '',
    specifiedCategoryIndex: null,
    feedback: '',
    propositionId: ''
  },
  changeSubscriptionLoading: false,
  cancelSubscriptionLoading: false,
  autoSubscriptionLoading: false,
  cancelImmediatelyChecked: false,

  cancelButtonReady: true,

  showCancelOffer: false,

  CANCEL_MODAL_STEPS: {
    start: 1,
    offer: 2,
    congratulations: 3,
    reasons: 4,
    feedback: 5,
    searchCoverage: 6,
    propositionPause: 7,
    serviceUsedToFindSwap: 8
  },
  currentStep: 1,
  feedbackType: 'DETAILS',
  offerType: 'SAVE'
});

const state = {
  ...getInitialState(),
  currentSubscription: {},
  currentProposition: {}
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
