<template>
  <article class="room-outer-wrapper">
    <div
      class="room-wrapper"
      :class="{
        dark: $route.params.roomId == room.id,
        unread: hasUnread,
        'slide-left': deleteVisible
      }"
    >
      <div v-if="!isMobile" class="room-image" @click="handleRoomClick()">
        <AImage
          :url="conversation.owner.residenceImage"
          alt="dwelling"
          loading="lazy"
        />
      </div>
      <div class="room-content">
        <div class="room-content-left" @click="handleRoomClick()">
          <h2 v-if="conversation" class="title text">
            {{ addressText }}
          </h2>
          <p class="text">
            {{ getLastMessage(room.lastMessage) }}
          </p>
          <div class="date">{{ roomUpdatedText }}</div>
        </div>
        <div v-if="!isLocked" class="room-content-right">
          <div v-if="hasUnread" class="unread-dot"></div>
          <button class="menu-button" @click="handleMenuBtnClick">
            <svg
              data-v-c1122e28=""
              width="4"
              height="16"
              viewBox="0 0 4 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                data-v-c1122e28=""
                d="M2 10C1.46957 10 0.96086 9.78929 0.585787 9.41421C0.210714 9.03914 0 8.53043 0 8C0 7.46957 0.210714 6.96086 0.585787 6.58579C0.96086 6.21071 1.46957 6 2 6C2.53043 6 3.03914 6.21071 3.41421 6.58579C3.78929 6.96086 4 7.46957 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10ZM2 4C1.46957 4 0.96086 3.78929 0.585787 3.41421C0.210714 3.03914 0 2.53043 0 2C0 1.46957 0.210714 0.960859 0.585787 0.585786C0.96086 0.210714 1.46957 0 2 0C2.53043 0 3.03914 0.210714 3.41421 0.585786C3.78929 0.960859 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4ZM2 16C1.46957 16 0.96086 15.7893 0.585787 15.4142C0.210714 15.0391 0 14.5304 0 14C0 13.4696 0.210714 12.9609 0.585787 12.5858C0.96086 12.2107 1.46957 12 2 12C2.53043 12 3.03914 12.2107 3.41421 12.5858C3.78929 12.9609 4 13.4696 4 14C4 14.5304 3.78929 15.0391 3.41421 15.4142C3.03914 15.7893 2.53043 16 2 16Z"
                fill="#2C3E50"
              ></path>
            </svg>
          </button>
        </div>
        <div v-else-if="room.isFreemiumLocked" class="room-content-right">
          <svg
            class="lock-icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 13C11.6955 12.9964 11.3973 13.0862 11.1454 13.2573C10.8936 13.4284 10.7001 13.6725 10.5912 13.9568C10.4823 14.2411 10.4631 14.552 10.5361 14.8476C10.6092 15.1431 10.7711 15.4092 11 15.61V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V15.61C13.2289 15.4092 13.3908 15.1431 13.4639 14.8476C13.5369 14.552 13.5177 14.2411 13.4088 13.9568C13.2999 13.6725 13.1064 13.4284 12.8546 13.2573C12.6027 13.0862 12.3045 12.9964 12 13ZM17 9V7C17 5.67392 16.4732 4.40215 15.5355 3.46447C14.5979 2.52678 13.3261 2 12 2C10.6739 2 9.40215 2.52678 8.46447 3.46447C7.52678 4.40215 7 5.67392 7 7V9C6.20435 9 5.44129 9.31607 4.87868 9.87868C4.31607 10.4413 4 11.2044 4 12V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V12C20 11.2044 19.6839 10.4413 19.1213 9.87868C18.5587 9.31607 17.7956 9 17 9ZM9 7C9 6.20435 9.31607 5.44129 9.87868 4.87868C10.4413 4.31607 11.2044 4 12 4C12.7956 4 13.5587 4.31607 14.1213 4.87868C14.6839 5.44129 15 6.20435 15 7V9H9V7ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V12C6 11.7348 6.10536 11.4804 6.29289 11.2929C6.48043 11.1054 6.73478 11 7 11H17C17.2652 11 17.5196 11.1054 17.7071 11.2929C17.8946 11.4804 18 11.7348 18 12V19Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
    </div>
    <div
      v-if="deleteVisible"
      v-outside-click="closeDeleteContainer"
      class="delete-container"
      @click="
        $emit('delete', {
          room: room,
          id: userConversation.id
        })
      "
    >
      <svg
        class="trash-icon"
        version="1.1"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#eee"
          d="M341,128V99c0-19.1-14.5-35-34.5-35H205.4C185.5,64,171,79.9,171,99v29H80v32h9.2c0,0,5.4,0.6,8.2,3.4c2.8,2.8,3.9,9,3.9,9  l19,241.7c1.5,29.4,1.5,33.9,36,33.9h199.4c34.5,0,34.5-4.4,36-33.8l19-241.6c0,0,1.1-6.3,3.9-9.1c2.8-2.8,8.2-3.4,8.2-3.4h9.2v-32  h-91V128z M192,99c0-9.6,7.8-15,17.7-15h91.7c9.9,0,18.6,5.5,18.6,15v29H192V99z M183.5,384l-10.3-192h20.3L204,384H183.5z   M267.1,384h-22V192h22V384z M328.7,384h-20.4l10.5-192h20.3L328.7,384z"
        ></path>
      </svg>
    </div>
  </article>
</template>

<script>
import outsideClick from '@/directives/outside-click';
import AImage from '@/components/atomic/atoms/a-image';
import { convertSystemChatMessage } from '../../utils/systemMessageConverter';

export default {
  components: { AImage },

  directives: {
    outsideClick
  },
  props: {
    room: {
      type: Object,
      default: () => {}
    },

    candidateId: {
      type: Number,
      default: null
    },

    isMobile: {
      type: Boolean,
      default: false
    },
    deleteVisible: {
      type: Boolean,
      default: false
    },
    isLocked: {
      type: [Boolean],
      default: false
    }
  },

  computed: {
    hasUnread() {
      if (!this.userConversation) return {};

      return this.userConversation.unreadCount;
    },

    userConversation() {
      return this.room.conversations.find(x => x.ownerId == this.candidateId);
    },

    roomUpdatedText() {
      if (this.$dayjs.tz(this.room.updatedAt) > this.$dayjs.tz()) {
        return this.$dayjs.tz().fromNow();
      }

      return this.$dayjs.tz(this.room.updatedAt).fromNow();
    },

    addressText() {
      return this.conversations.map(x => x.owner.address).join(', ');
    },

    conversation() {
      return this.conversations[0];
    },
    conversations() {
      const conversation = this.room.conversations.filter(
        x => x.ownerId !== this.candidateId
      );

      return conversation;
    }
  },

  methods: {
    handleRoomClick() {
      if (this.deleteVisible) return;

      this.$emit('click', {
        room: this.room,
        hasUnread: this.hasUnread
      });
    },
    handleMenuBtnClick(e) {
      e.stopPropagation();
      this.$emit('menuOpen', {
        id: this.room.id
      });
    },
    closeDeleteContainer() {
      this.$emit('menuOpen', {
        id: ''
      });
    },
    htmlDecode(content) {
      const el = document.createElement('div');
      el.innerHTML = content;
      return el.textContent;
    },
    getLastMessage(content) {
      try {
        const systemMessage = convertSystemChatMessage(content);
        return this.htmlDecode(systemMessage);
      } catch (e) {
        return this.htmlDecode(content);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h2,
p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

.room-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  height: 80px;
  width: 100%;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  transition: all 0.12s ease-in-out;
  background-color: white;
  z-index: 2;

  @media ($desktop) {
    grid-template-columns: 80px 1fr;
  }
}

.room-content-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px;
  font-weight: 400;
  color: #878b9d;
  max-width: 310px;
  width: 100%;
  overflow: hidden;

  @media ($desktop) {
    max-width: 295px;
  }
}

.room-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.title {
  color: #2c3e50;
  font-size: 14px;
  font-weight: 600;
}

.room-image {
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.date {
  font-size: 12px;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.dark {
  background-color: $form-background-dark !important;
}

.room-wrapper.unread {
  background-color: #e2f4fc;
}

.room-content-right {
  display: flex;
  align-items: center;
}

.unread-dot {
  height: 6px;
  width: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px;
}

.delete-container {
  background-color: red;
  height: 100%;
  width: 50px;
  position: absolute;
  right: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.menu-button {
  padding: 10px;
  margin-right: 10px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  > * {
    pointer-events: none;
  }
}

.slide-left {
  transform: translateX(-50px);
}

.room-outer-wrapper {
  display: flex;
  position: relative;
  cursor: pointer;
}

.trash-icon {
  position: absolute;
  width: 30px;
}

.lock-icon {
  margin-right: 10px;
}
</style>
