var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    { 'primary-button': !_vm.newPrimaryButton },
    { 'primary-button-new': _vm.newPrimaryButton },
    { 'full-width': _vm.fullWidth },
    { warn: _vm.warn },
    { square: _vm.square },
    { accent: _vm.accent },
    { rounded: _vm.rounded },
    { uppercase: _vm.uppercase },
    { 'small-desktop-aware': _vm.smallDesktopAware },
    { disabled: _vm.disabled },
    { inverted: _vm.invertColors }
  ],attrs:{"disabled":_vm.disabled}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }