<template>
  <section class="wrapper">
    <AImage :classes="['icon']" :url="icons.GENERAL.ADD_INVERTED" />

    <div class="text">
      <h3 class="title">
        {{ $t('banner_title') }}
      </h3>
      <p>{{ $t('banner_text') }}</p>
    </div>
    <AImage :classes="['icon']" :url="icons.GENERAL.ARROW_RIGHT" />
  </section>
</template>

<script>
import icons from '@/utils/icon-constants';
import AImage from '../atomic/atoms/a-image';

export default {
  name: 'Banner',
  components: { AImage },
  data() {
    return {
      icons: icons
    };
  }
};
</script>

<style lang="scss" scoped>
p,
h3 {
  margin: 0;
  padding: 0;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 22px 22px;
  background-color: $accent-green;
  color: white;
  font-size: 14px;
}
.title {
  font-weight: 600;
  margin-bottom: 10px;
}

.icon {
  margin: 0;
  padding: 0;
  height: 30px;
  width: 30px;
  background-position: center;
  background-size: contain;
}
.text {
  padding: 0 15px;
  width: 100%;
}
</style>
