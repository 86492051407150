<template>
  <div class="progress-bar-content">
    <span v-if="showIcon">
      <BaseIcon icon-file="flash" class="flash" />
    </span>

    <div
      class="progress-bar-bg"
      :class="progress === '60%' || progress === '100%' ? 'pulsate' : ''"
    >
      <div
        class="progress-start"
        :style="{
          width: '0%',
          background: progress === '100%' && '#32de84'
        }"
        :class="percentage === 0 ? 'right-border-radius' : ''"
      />
      <div
        :class="[
          'progress',
          shouldAnimate ? 'progress-animation' : '',
          'left-border-radius'
        ]"
        :style="{
          'max-width': percentage + '%',
          background: progress === '100%' && '#32de84'
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: 0
    },
    showIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shouldAnimate: true
    };
  },
  computed: {
    progress() {
      return String(parseInt(0) + parseInt(this.percentage)) + '%';
    }
  },
  watch: {
    percentage(newVal) {
      this.updateAnimationFlag(newVal);
    }
  },
  mounted() {
    this.updateAnimationFlag(this.percentage);
  },
  methods: {
    updateAnimationFlag(currentPercentage) {
      if (!window.progressAnimations) {
        window.progressAnimations = [];
      }
      if (!window.progressAnimations.includes(currentPercentage)) {
        this.shouldAnimate = true;
        window.progressAnimations.push(currentPercentage);
      } else {
        this.shouldAnimate = false;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.left-border-radius {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.right-border-radius {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.progress-bar-content {
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background: #ffffff;
  border-radius: 20px;
  height: 20px;
  width: 176px;
  padding-left: 10px;
  padding-right: 10px;
  gap: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .progress-bar-bg {
    position: relative;
    display: flex;
    height: 4px;
    width: 100%;
    border-radius: 4px;
    background: #e8e8e8;
  }

  .progress-start {
    transition: all 0.5s;
    position: relative;
    height: 4px;
    width: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #409fff;
  }

  .progress {
    transition: all 0s;
    position: relative;
    height: 4px;
    width: 0;
    max-width: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #409fff;
    animation: progress 0s;
    animation-fill-mode: forwards;
  }

  .progress-animation {
    transition: all 0.5s;
    animation: progress 6s;
    animation-fill-mode: forwards;
  }

  .pulsate {
    animation: pulse 2s ease-in-out 2;
    animation-delay: 2s;
  }

  .flash {
    height: 13px;
    width: 7px;
  }

  @keyframes progress {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 1;
      width: 99%;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      opacity: 0.8;
      width: 100%;
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      opacity: 1;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
}
</style>
