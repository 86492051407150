<template>
  <div class="trial-modal">
    <div class="info-part">
      <div class="info-part-header">
        <div class="info-part-title">
          {{ $t('no_active_modal_header') }}
        </div>
      </div>
      <div class="info-part-body">
        <div class="info-part-usp">
          <div class="info-part-list-header">
            {{ $t('no_active_modal_text') }}
          </div>
          <div class="info-part-list">
            <div class="info-part-list-item">
              {{ $t('trial_cta_modal_list_1') }}
            </div>
            <div class="info-part-list-item">
              {{ $t('trial_cta_modal_list_2') }}
            </div>
            <div class="info-part-list-item">
              {{ $t('trial_cta_modal_list_3') }}
            </div>
            <div :class="[showGreenCheckMark ? 'info-part-list-item' : '']">
              {{ $t('trial_cta_modal_list_4') }}
            </div>
          </div>
        </div>
        <template v-if="$country.isCountry('se')">
          <div class="info-part-title info-part-title--with-margin">
            {{ $t('no_active_modal_title2') }}
          </div>
          <TrustBox
            v-if="$country.isFeatureEnabled('TRUSTPILOT')"
            type="Mini"
          />
        </template>
      </div>

      <div class="button-wrapper">
        <primary-button
          :new-primary-button="true"
          :full-width="true"
          @click.native="goToPaymentPage"
        >
          {{ $t('activate_ad') }}
        </primary-button>
        <!-- <div class="button-info">{{ $t('no_active_modal_button_info') }}</div> -->
      </div>
    </div>

    <div v-if="!isMobile" class="image-part"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PrimaryButton from '../Buttons/Primary';
import events from '@/utils/helpers/events';
import TrustBox from '../TrustBox/TrustBox.vue';

export default {
  name: 'NoActiveModal',

  components: {
    PrimaryButton,
    TrustBox
  },

  props: {
    closeFunction: {
      type: Function,
      required: false,
      default: () => {}
    },
    paymentPagePropositionId: {
      type: String,
      required: true
    },
    inFreeArea: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    }),

    showGreenCheckMark() {
      return this.$country.isCountry('de');
    }
  },

  mounted() {
    events.emitEvent(events.eventTypes.SEE, 'Paywall', {
      trigger: 'no-active-propositions',
      src: this.source
    });
  },

  methods: {
    goToPaymentPage() {
      events.emitEvent(events.eventTypes.CLICK, 'Payment', {
        id: 'no-active-propositions-chat'
      });

      this.$router.push(
        `${
          this.inFreeArea
            ? this.$t('my_propositions_card_my_account')
            : this.$t('my_propositions_card_payment')
        }/${this.paymentPagePropositionId}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.trial-modal {
  display: grid;
  grid-template-columns: 1;
  position: relative;
  max-width: none;
  padding: 0;

  @media ($desktop) {
    top: 79px;
    width: 100vw;
    height: calc(100vh - 79px);
    grid-template-columns: 432px 1fr;
    margin-top: 0 !important;
  }
}

.info-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.info-part-header {
  padding: 3rem 2.5rem;

  @media ($mobile) {
    background-image: url('../../assets/img/trial-title-img.jpg');
    background-size: cover;
    color: #fff;
    display: flex;
    align-items: flex-end;
    padding: 6rem 30px 20px;
    margin-bottom: 0.25rem;
    min-height: 300px;
  }
}

.info-part-title {
  font-family: $font-headings;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
}

.info-part-title--with-margin {
  margin-bottom: 4rem;
}

.info-part-body {
  width: 100%;
  padding: 1.5rem;
  padding-bottom: 14rem;

  @media ($desktop) {
    padding-top: 0;
    padding-bottom: 10rem;
  }
}

.info-part-usp {
  padding: 0 1.5rem;
  margin-bottom: 4rem;
}

.info-part-list-header {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: -0.01em;
  margin-bottom: 2rem;
  text-align: center;
}

.info-part-list {
  @media ($desktop) {
    padding: 0 25px;
  }
}

.info-part-list-item {
  display: flex;
  align-items: center;
  margin: 12px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
  color: #878b9d;
  position: relative;

  &::before {
    content: '';
    height: 22px;
    min-width: 22px;
    margin-right: 10px;
    background-size: contain;
    background-position: center;
    background-image: url('../../assets/svg/list-checkbox.svg');
  }
}

.google-reviews {
  width: 143px;
  height: 67px;
  margin: auto;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/svg/google-reviews.svg');
}

.button-wrapper {
  position: fixed;
  bottom: calc(56px + env(safe-area-inset-bottom));
  left: 0;
  width: 100%;
  padding: 13px 20px;
  background: rgba(243, 243, 243, 0.5);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);

  @media ($desktop) {
    width: 432px;
    bottom: 0;
    padding: 13px 48px;
  }
}

.button-info {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4;
  color: #878b9d;
  text-align: center;
  margin-top: 8px;
  margin-left: 26px;
  margin-right: 26px;
}

.image-part {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/img/trial-img-2.jpg');
}
</style>
