<template>
  <Spinner v-if="isLoading" />
  <div v-else class="info-modal-container">
    <div class="overlay" @click="$emit('close')"></div>
    <transition name="pop-in" appear>
      <div class="info-modal">
        <div class="info-modal-body">
          <slot />
        </div>
        <div class="info-modal-action-container">
          <!-- Primary action -->
          <router-link
            v-if="primaryActionLink"
            :to="primaryActionLink"
            class="primary-action"
          >
            <BaseIcon icon-file="icon-stars-green" />
            {{ primaryActionText }}</router-link
          >
          <button
            v-else-if="primaryActionText"
            class="primary-action"
            @click.stop.prevent="$emit('primaryActionClick')"
          >
            <BaseIcon icon-file="icon-stars-green" />
            {{ primaryActionText }}
          </button>

          <!-- Secondary action -->
          <router-link
            v-if="secondaryActionLink"
            :to="secondaryActionLink"
            class="secondary-action"
          >
            <BaseIcon icon-file="stars-white" />
            {{ secondaryActionText }}</router-link
          >
          <button
            v-else-if="secondaryActionText"
            class="secondary-action"
            @click.stop.prevent="$emit('secondaryActionClick')"
          >
            <BaseIcon icon-file="stars-white" />
            {{ secondaryActionText }}
          </button>

          <!-- Tertiary action -->
          <router-link
            v-if="tertiaryActionLink"
            :to="tertiaryActionLink"
            class="tertiary-action"
            @click.native="tertiaryLinkClicked"
          >
            {{ tertiaryActionText }}</router-link
          >

          <!-- Confirm button -->
          <button v-if="showCloseButton" @click="$emit('close')">
            {{ $t('modal_action_confirm') }}
          </button>
        </div>
        <!-- Close button -->
        <div
          v-if="secondaryActionLink"
          class="info-modal-secondary-close"
          @click="$emit('close')"
        >
          {{ $t('general_close') }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Spinner from '@/components/Loading/Spinner';

export default {
  name: 'BaseInfoModal',

  components: { Spinner },

  props: {
    primaryActionText: {
      type: String,
      default: ''
    },
    primaryActionLink: {
      type: String,
      default: ''
    },
    secondaryActionText: {
      type: String,
      default: ''
    },
    secondaryActionLink: {
      type: String,
      default: ''
    },
    tertiaryActionText: {
      type: String,
      default: ''
    },
    tertiaryActionLink: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    $route(newVal, oldVal) {
      // Prevent closing on query change
      if (newVal.path !== oldVal.path) {
        this.$emit('close');
      }
    }
  },

  created() {
    this.$emit('open');
  },

  methods: {
    tertiaryLinkClicked() {
      this.$emit('tertiary-clicked');
    }
  }
};
</script>

<style lang="scss" scoped>
.info-modal-container {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-modal {
  position: fixed;
  width: 90%;
  max-width: 350px;
  border-radius: 10px;
  background-color: #fff;
  z-index: 3000;
}

.info-modal-body {
  max-height: 90vh;
  padding: 20px;
  overflow-y: auto;
}

.info-modal-action-container button,
.info-modal-action-container a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent;
  border: 0;
  border-radius: 0px 0px 10px 10px;
  text-align: center;
  font-weight: 600;
  padding-top: 17px;
  padding-bottom: 18px;
  border-top: 1px solid #f1f2f6;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #e9efff;
  }
}

.info-modal-action-container .primary-action {
  color: #2ec973;
}

.info-modal-action-container .secondary-action {
  background-color: #2ec973;
  color: #fff;
  margin-bottom: -1px;

  &:hover,
  &:focus {
    background-color: #25ac61;
  }
}

.tertiary-action {
  // color: #2ec973;
}

.info-modal-action-container .icon {
  margin-right: 5px;
}

.info-modal-secondary-close {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  cursor: pointer;
}

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
</style>
