<template>
  <span v-if="notifications" class="badge-count">
    {{ notifications > 99 ? '99+' : notifications }}
  </span>
</template>

<script>
export default {
  name: 'BadgeCount',
  props: {
    notifications: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
.badge-count {
  background: $main-blue;
  color: #fff;
  font-weight: 600;
  text-align: center;

  &.inline {
    border-radius: 6px;
    display: inline-block;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    margin-left: 0;
    padding: 0 4px;
    min-width: 20px;

    &.left-auto {
      margin-left: auto;
    }

    @media ($desktop) {
      &.left-5x {
        margin-left: 5px;
      }
    }
  }

  &.absolute {
    position: absolute;
    top: calc(20% - 14px);
    left: calc(50% + 17px);
    transform: translateX(-50%);
    height: 14px;
    min-width: 14px;
    padding: 0 3px;
    font-size: 10px;
    line-height: 14px;
    border-radius: 17px;
  }
}
</style>
