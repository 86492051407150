<template>
  <article class="article">
    <img :src="largestPossibleThumbnail" alt="" class="article__image" />
    <div class="article__content">
      <div v-if="article.HideDate !== true" class="article__date">
        {{ formattedDate }}
      </div>
      <h1 class="article__title">{{ article.title }}</h1>
      <div class="article__lead">
        <strong>{{ article.lead }}</strong>
      </div>
      <div class="article__body">
        <div class="editor-content" v-html="article.body"></div>
        <MSocialShareButtons
          v-if="recievedArticleData"
          :page-url="pageUrl"
          :article-title="article.title"
          :article-description="article.lead"
        />
      </div>
    </div>
  </article>
</template>

<script>
import axios from 'axios';
import MSocialShareButtons from '@/components/atomic/molecules/m-social-share-buttons';
import descriptionWithMaxLength from '@/utils/descriptionWithMaxLength';

export default {
  name: 'OBlogPost',

  components: {
    MSocialShareButtons
  },

  data() {
    return {
      article: {},
      error: null,
      recievedArticleData: false
    };
  },

  head: {
    title() {
      return {
        inner: this.article.title || ''
      };
    },

    meta() {
      return [
        {
          name: 'description',
          content: descriptionWithMaxLength(this.article.lead),
          id: 'desc'
        },
        {
          property: 'og:title',
          content: this.article.title || '',
          id: 'ogtitle'
        },
        { property: 'og:url', content: window.location.href, id: 'ogurl' },
        {
          property: 'og:image',
          content: this.largestPossibleThumbnail,
          id: 'ogimage'
        },
        {
          property: 'og:description',
          content: descriptionWithMaxLength(this.article.lead),
          id: 'ogdesc'
        }
      ];
    }
  },

  computed: {
    formattedDate() {
      const date = new Date(this.article.published_at);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(
        this.$country.getLanguageFromCountry(),
        options
      );
    },

    largestPossibleThumbnail() {
      if (this.article.image) {
        if (this.article.image.formats.large !== undefined) {
          return this.article.image.formats.large.url;
        } else if (this.article.image.formats.medium !== undefined) {
          return this.article.image.formats.medium.url;
        } else if (this.article.image.formats.small !== undefined) {
          return this.article.image.formats.small.url;
        } else {
          return this.article.image.formats.thumbnail.url;
        }
      }
      return null;
    },

    pageUrl() {
      return window.location.href;
    }
  },

  watch: {
    $route(to, from) {
      if (to !== from) {
        this.fetchBlogpost();
      }
    }
  },

  created() {
    this.fetchBlogpost();
  },

  methods: {
    async fetchBlogpost() {
      try {
        const response = await axios.get(
          'https://cms.lagenhetsbyte.se/blogposts/' + this.$route.params.id
        );
        this.article = response.data;
        this.$emit('updateHead');
        this.recievedArticleData = true;
      } catch (error) {
        this.error = error;
      }

      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.article {
  max-width: none;
  width: auto;
  margin-bottom: 100px;

  @media ($desktop) {
    margin-bottom: 150px;
  }
}

.article__image {
  width: 100%;
  max-height: 70vh;
  margin-bottom: 2rem;
  object-fit: cover;

  @media ($desktop) {
    margin-bottom: 4rem;
    max-height: 80vh;
  }
}

.article__content {
  margin: 0 1rem;

  @media ($desktop) {
    margin: 0 auto;
    max-width: 675px;
  }
}

.article__date {
  color: gray;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}

.article__title {
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 1.5rem;
  margin-top: 0;
}

.article__lead {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0;
  margin-bottom: 1.5rem;
}

.article__body {
  line-height: 1.5;
}
</style>
