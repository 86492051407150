<template>
  <div class="boost-container">
    <div class="boost-banner" @click="toggleContent">
      <BaseIcon icon-file="icon-rocket-white" class="rocket-icon" />
      <div>{{ $t('boost_banner_title') }}</div>
      <BaseIcon
        icon-file="icon-chevron-down"
        :class="['arrow-icon', { rotate: contentIsVisible }]"
      />
    </div>
    <Transition name="slide-down">
      <div v-if="contentIsVisible && statisticsData" class="boost-content">
        <div class="items">
          <div class="item">
            <div class="heading">{{ $t('boost_banner_box_1') }}</div>
            <div class="stat">
              +{{ statisticsData.views.toLocaleString('sv-SE') }}
            </div>
          </div>

          <div class="item">
            <div class="heading">{{ $t('boost_banner_box_2') }}</div>
            <div class="stat">{{ statisticsData.interestMarks }}</div>
          </div>

          <div class="item">
            <div class="heading">{{ $t('boost_banner_box_3') }}</div>
            <div class="stat">{{ statisticsData.clicks }}</div>
          </div>
        </div>
        <div class="active-until">
          <BaseIcon icon-file="icon-rocket-purple" class="active-until-icon" />
          {{ $t('boost_banner_active_until') }}
          {{
            $dayjs(primaryProposition.extraVisibility.endDate).format('D MMMM')
          }}
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { lbApiBeta } from '../../utils/axiosConfig';
import events from '@/utils/helpers/events';

export default {
  name: 'BoostBanner',

  data() {
    return {
      contentIsVisible: false,
      statisticsData: null
    };
  },

  computed: {
    ...mapGetters({
      primaryProposition: 'myPropositions/primaryProposition'
    })
  },

  created() {
    this.getStatValues();
  },

  methods: {
    toggleContent() {
      this.contentIsVisible = !this.contentIsVisible;
      if (this.contentIsVisible) {
        events.emitEvent(events.eventTypes.CLICK, 'Boost Statistics', {
          src: 'boost-banner'
        });

        this.getStatValues();
      }
    },

    async getStatValues() {
      try {
        const primaryPropositionId = await this.$store.dispatch(
          'myPropositions/fetchPrimaryPropositionId'
        );
        const { data } = await lbApiBeta.get(
          `/api/boost-summary?propositionId=${primaryPropositionId}`
        );

        this.statisticsData = data;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.boost-container {
  width: 100%;
  z-index: 1;
}

.boost-banner {
  display: flex;
  align-items: center;
  padding: 13px;
  background-color: #9f41e9;
  color: #fff;
  font-weight: 600;
  user-select: none;
  cursor: pointer;

  @media ($desktop) {
    justify-content: center;
  }
}

.rocket-icon {
  margin-right: 10px;
}

.arrow-icon {
  transition: 0.3s;
  margin-left: 10px;

  @media ($mobile) {
    margin-left: auto;
  }

  &.rotate {
    transform: rotate(180deg);
  }
}

.boost-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5e6dc;

  @media ($desktop) {
    padding-left: 25%;
    padding-right: 25%;
  }
}

.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  padding: 13px;
}

.item {
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 16px 0;

  .heading {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.01em;
    margin-bottom: 5px;
  }

  .stat {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;

    color: #9f41e9;
  }
}

.active-until {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-top: 7px;
  margin-bottom: 20px;
}

.active-until-icon {
  margin-right: 4px;
}
</style>
