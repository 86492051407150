<template>
  <div class="impressum-page">
    <div class="page-header">
      <div class="page-container">
        <h1 class="page-title">{{ $t('the_footer_impressum') }}</h1>
        <div class="impressum-container">
          <div class="address-container">
            <p>{{ $t('full_company_name') }}</p>
            <p>{{ $t('full_company_address') }}</p>
            <p>{{ $t('full_company_postal_code') }}</p>
          </div>
          <div>
            <div class="po-box-container">
              <p>
                <b>{{ $t('po_box_title') }}</b>
              </p>
              <p>{{ $t('company_location') }}</p>
              <p>{{ $t('company_address') }}</p>
              <p>{{ $t('company_postal_code') }}</p>
            </div>
            <p v-if="$country.isCountry('de')">
              <span><b>Vertreten durch: </b></span>
              <span>{{ $t('represented_by') }}</span>
            </p>
            <p>
              <span
                ><b>{{ $t('vat_number_title') }} </b></span
              >
              <span>{{ $t('vat_number') }}</span>
            </p>
            <p>
              <span
                ><b>{{ $t('website_title') }} </b></span
              >
              <a :href="$t('website')" target="_blank">{{
                $t('website_name')
              }}</a>
            </p>
            <span
              ><b>{{ $t('the_footer_email_label') }}</b></span
            >
            <a
              :href="`mailto:${$t('the_footer_email', $routeLocale)}`"
              class="support-email"
            >
              {{ $t('the_footer_email', $routeLocale) }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="page-container">
      <AccordionGroup
        v-for="(accordionGroup, index) in content.Accordion"
        :key="index"
        :content="accordionGroup"
      />
    </div>

    <div v-if="content.BottomPanel" class="page-container">
      <div
        class="contact-customer-service editor-content"
        v-html="content.BottomPanel"
      ></div>
    </div>

    <TheFooter :is-authenticated="isAuthenticated" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import TheFooter from '@/components/TheFooter';
import AccordionGroup from '@/components/ContentBlocks/AccordionGroup';

export default {
  name: 'Impressum',

  components: {
    TheFooter,
    AccordionGroup
  },

  data() {
    return {
      content: {}
    };
  },

  head: {
    title() {
      return {
        inner: this.content.PageTitle || ''
      };
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    })
  },

  async created() {
    window.scrollTo(0, 0);

    try {
      const response = await axios.get(
        `https://cms.lagenhetsbyte.se/impressum?_locale=${this.$routeLocale}`
      );
      this.content = response.data;
      this.$emit('updateHead');
    } catch (error) {
      //
    }
  }
};
</script>

<style lang="scss" scoped>
.impressum-page {
  max-width: none;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;

  @media ($desktop) {
    padding-top: 80px;
    padding-bottom: 0;
  }
}

.page-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  img {
    width: 100%;
  }
}

.page-header {
  padding: 40px 0;
  color: $text-primary;
  min-height: 218px;

  @media ($desktop) {
    padding: 50px 0;
  }
}

.page-title {
  margin-bottom: -10px;
  margin-top: 0;
  color: $text-primary;
  text-transform: uppercase;
}

.address-container,
.po-box-container {
  p {
    margin: 0px !important;
  }
}

.po-box-container {
  margin-top: 16px;
}

.lead {
  max-width: 900px;
  font-weight: 600;
}

.contact-customer-service {
  padding: 20px;
  margin-top: 50px;
  background-color: #f1f2f6;
  border-radius: 10px;

  @media ($desktop) {
    padding: 30px 40px;
    margin-top: 60px;
  }
}

.contact-customer-service ::v-deep > *:not(hr) {
  max-width: 800px;
}
</style>
