<template>
  <div class="dynamic-page">
    <component
      :is="blockType(block.__component)"
      v-for="(block, index) in content.dynamic"
      :key="index"
      :content="block"
    />

    <TheFooter :is-authenticated="isAuthenticated" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import TheFooter from '@/components/TheFooter';
import OneImage from '@/components/ContentBlocks/OneImage';
import TwoImages from '@/components/ContentBlocks/TwoImages';
import ThreeImages from '@/components/ContentBlocks/ThreeImages';
import Hero from '@/components/ContentBlocks/Hero';
import ImageAndText from '@/components/ContentBlocks/ImageAndText';
import TextBlock from '@/components/ContentBlocks/TextBlock';
import StatisticsBlock from '@/components/ContentBlocks/StatisticsBlock';
import HeadingsAndText from '@/components/ContentBlocks/HeadingsAndText';
import Reviews from '@/components/ContentBlocks/Reviews';
import Employees from '@/components/ContentBlocks/Employees';

export default {
  name: 'DynamicPage',

  components: {
    OneImage,
    TwoImages,
    ThreeImages,
    Hero,
    ImageAndText,
    TextBlock,
    TheFooter,
    StatisticsBlock,
    HeadingsAndText,
    Reviews,
    Employees
  },

  data() {
    return {
      content: {}
    };
  },

  head: {
    title() {
      return {
        inner: this.content.PageTitle || ''
      };
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    })
  },

  async created() {
    try {
      const response = await axios.get(
        `https://cms.lagenhetsbyte.se/about-us-page`
      );
      this.content = response.data;
      this.$emit('updateHead');
    } catch (error) {
      //
    }
  },

  methods: {
    blockType(type) {
      switch (type) {
        case 'general.image':
          return 'OneImage';
        case 'general.two-images':
          return 'TwoImages';
        case 'general.three-images':
          return 'ThreeImages';
        case 'general.hero':
          return 'Hero';
        case 'general.image-and-text':
          return 'ImageAndText';
        case 'general.richtext2':
          return 'TextBlock';
        case 'general.headings-and-text':
          return 'HeadingsAndText';
        case 'general.statistics':
          return 'StatisticsBlock';
        case 'general.user-reviews':
          return 'Reviews';
        case 'general.employees':
          return 'Employees';
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dynamic-page {
  max-width: none;
  width: 100%;
  padding-bottom: 95px;
  font-size: 18px;
  line-height: 1.5;

  @media ($desktop) {
    padding-top: 80px;
    padding-bottom: 0;
  }
}
</style>
