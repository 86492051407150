function paymentHistory(state) {
  return state.paymentHistory;
}

function isLoading(state) {
  return state.loading;
}

export default {
  paymentHistory,
  isLoading
};
