<template>
  <div class="payment-wrapper">
    <SideMenu :is-loading="false" />

    <section class="content-wrapper">
      <h1>{{ $t('payment_pending_page_title') }}</h1>

      <section class="payment-cards-wrapper">
        <section
          v-for="item in activeOrders"
          :key="item.order.orderId"
          class="payment-card"
        >
          <h2>{{ getProducTitle(item.product.name) }}</h2>
          <p class="subtitle">
            {{ getSubtitle(item.order.propositionId, item.product.category) }}
          </p>

          <p>
            {{ $t('payment_pending') }}
          </p>

          <p class="capitalize secondary-text">
            {{ $t('payment_card_provider_via') }}
            {{ item.order.paymentMethod.toLowerCase() }}
          </p>

          <p class="secondary-text">
            {{
              new Date(item.order.createdAt).toLocaleDateString(
                $country.getLanguageFromCountry()
              )
            }}
          </p>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { subscribe, unsubscribe } from '../../utils/socket';
import SideMenu from '@/components/MyPropositions/SideMenu';

export default {
  components: { SideMenu },

  data() {
    return {
      items: null
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('payment_pending_page_title')
      };
    }
  },

  computed: {
    ...mapState('payment', ['activeOrders']),
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      propositions: 'myPropositions/propositions'
    })
  },

  beforeDestroy() {
    unsubscribe('new-payment', this.handleNewPayment);
  },

  async created() {
    subscribe('new-payment', this.handleNewPayment);
    this.getSetActiveOrders();
  },

  methods: {
    ...mapActions({
      paymentSuccess: 'payment/success',
      getSetActiveOrders: 'payment/getSetActiveOrders'
    }),

    async handleNewPayment(data) {
      this.paymentSuccess({ ...data, notify: true, redirect: true });
    },

    getSubtitle(propositionId, productType) {
      let text = this.$t('payment_history_address');

      if (propositionId && productType === 'annonsforlangning') {
        const proposition = this.propositions.find(
          x => x.propositionId == propositionId
        );

        if (proposition && proposition.residences[0]) {
          text = proposition.residences[0].streetAddress;
        }
      }

      return text;
    },

    getProducTitle(name) {
      return name.replace(/\(.*\)/, '').trim();
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-wrapper {
  display: flex;
  justify-content: space-around;

  @media ($mobile) {
    flex-direction: column;
  }
}

.content-wrapper {
  padding-left: 60px;
  width: calc(75% - 30px);

  @media ($mobile) {
    width: 100%;
    padding: 20px;
  }
}

.payment-card {
  padding: 18px;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  margin: 30px 0;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.09);
  width: 100%;

  @media ($mobile) {
    margin: 15px 0;
  }
}

.capitalize {
  text-transform: capitalize;
}

.secondary-text {
  color: #8e8d8f;
  font-weight: 600;
  font-size: 14px;
}

.subtitle {
  font-weight: bold;
}

.payment-cards-wrapper {
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  color: #3c3c3f;

  @media ($mobile) {
    font-size: 18px;
  }
}
</style>
