<template>
  <div class="content-wrapper">
    <h2 class="h2">{{ $t('logout_logging_out') }}</h2>
    <p>{{ $t('logout_please_wait') }}</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { authenticationApi } from '@/utils/axiosConfig';
import { log } from '@/utils/logger';
export default {
  async created() {
    try {
      await authenticationApi.post('auth/logout');
    } catch (error) {
      log(`Failed to logout: ${error}`, true);
    }

    localStorage.clear();
    await this.initAppData();
    window.location.href = '/';
  },

  methods: {
    ...mapActions({
      initAppData: 'app/initAppData'
    })
  }
};
</script>

<style scoped>
.content-wrapper {
  text-align: center;
}
</style>
