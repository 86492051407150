<template>
  <th class="wrapper">
    <div
      :class="[
        'sortable-wrapper',
        { 'flex-left': flexLeft },
        { 'flex-right': flexRight }
      ]"
    >
      <div class="title">{{ title }}</div>
    </div>
  </th>
</template>

<script>
export default {
  name: 'SwapListTitleSortable',

  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    flexLeft: {
      type: Boolean,
      default: false
    },
    flexRight: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.sortable-wrapper {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  &.flex-left {
    justify-content: flex-start;
  }

  &.flex-right {
    justify-content: flex-end;
  }
}

.title {
  white-space: nowrap;
}
</style>
