<template>
  <div class="sort-options">
    <div class="direction-box">
      {{ $t('sort_options_sort') }}
      <span class="direction-label" @click.stop="toggleSortDirection">{{
        readableDirectionType
      }}</span>
      {{ $t('sort_options_on') }}
    </div>
    <div
      v-for="sortOption in Object.keys(ACTIVE_SORT_TYPES)"
      :key="sortOption"
      :class="['sort-option', { selected: sortOption === sortType }]"
      @click="$emit('reorder', sortOption)"
    >
      <div class="title-wrapper">
        <div class="sort-radio">
          <div v-if="sortOption == sortType" class="sort-radio-selected"></div>
        </div>
        <div class="sort-title">{{ getReadableSortType(sortOption) }}</div>
      </div>
      <div class="sort-description">{{ getDescription(sortOption) }}</div>
    </div>
  </div>
</template>

<script>
import {
  SORT_TYPES,
  ACTIVE_SORT_TYPES,
  SORT_DIRECTIONS
} from '../../store/modules/swapList';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SortOptions',

  props: {},

  data() {
    return {
      SORT_TYPES: SORT_TYPES,
      ACTIVE_SORT_TYPES: ACTIVE_SORT_TYPES
    };
  },

  computed: {
    ...mapGetters({
      sortType: 'swapList/sortType',
      sortDirection: 'swapList/sortDirection'
    }),

    readableDirectionType() {
      return this.sortDirection === SORT_DIRECTIONS.DESCENDING
        ? this.$t('sort_options_descending')
        : this.$t('sort_options_ascending');
    }
  },

  methods: {
    ...mapActions({
      toggleSortDirection: 'swapList/toggleSortDirection'
    }),

    getReadableSortType(sortType) {
      switch (sortType) {
        case SORT_TYPES.HIGHEST_SCORE:
          return this.$t('filter_container_type_score');
        case SORT_TYPES.SQM:
          return this.$t('sort_options_type_sqm');
        case SORT_TYPES.FLOOR:
          return this.$t('sort_options_type_floor');
        case SORT_TYPES.ROOMS:
          return this.$t('sort_options_type_num_of_rooms');
        case SORT_TYPES.RENT:
          return this.$t('sort_options_type_rent');
        case SORT_TYPES.POSTAL_AREA:
          return this.$t('sort_options_type_postal_area');
        case SORT_TYPES.PERCENTAGE:
          return this.$t('sort_options_type_interest');
        case SORT_TYPES.SEEN_AT:
          return this.$t('sort_options_type_match_date');
        case SORT_TYPES.ADDRESS:
          return this.$t('sort_options_type_address');
        case SORT_TYPES.TYPE:
          return this.$t('sort_options_type_swaptype');
        case SORT_TYPES.INTEREST_DATE:
          return this.$t('sort_options_type_interest_date');
        default:
          return '';
      }
    },

    getDescription(sortType) {
      switch (sortType) {
        case SORT_TYPES.HIGHEST_SCORE:
          return this.$t('sort_options_desc_score');
        case SORT_TYPES.SQM:
          return this.$t('sort_options_desc_sqm');
        case SORT_TYPES.FLOOR:
          return this.$t('sort_options_desc_floor');
        case SORT_TYPES.ROOMS:
          return this.$t('sort_options_desc_num_of_rooms');
        case SORT_TYPES.RENT:
          return this.$t('sort_options_desc_rent');
        case SORT_TYPES.POSTAL_AREA:
          return this.$t('sort_options_desc_postal_area');
        case SORT_TYPES.PERCENTAGE:
          return this.$t('sort_options_desc_interest');
        case SORT_TYPES.SEEN_AT:
          return this.$t('sort_options_desc_match_date');
        case SORT_TYPES.ADDRESS:
          return this.$t('sort_options_desc_address');
        case SORT_TYPES.TYPE:
          return this.$t('sort_options_desc_swaptype');
        case SORT_TYPES.INTEREST_DATE:
          return this.$t('sort_options_desc_interest_date');
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-option {
  padding: 16px 20px;
  border-top: 1px solid #ddd;
  cursor: pointer;
}

.title-wrapper {
  display: flex;
}

.direction-box {
  padding: 18px 8px;
  text-align: center;
  background-color: $info-gray;

  font-weight: 600;
  font-size: 1.1rem;
}

.direction-label {
  color: $transfer-blue;
  text-decoration: underline;
  position: relative;
  margin-right: 16px;
  font-weight: 700;
  cursor: pointer;

  &::after {
    position: absolute;
    right: -14px;
    top: 7px;
    content: '';
    background-image: url('../../assets/svg/shift-blue.svg');
    background-position: center;
    background-size: contain;
    transform: rotate(90deg);
    height: 12px;
    width: 12px;
  }
}

.sort-radio {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sort-radio-selected {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: $transfer-blue;
}

.sort-title {
  font-weight: 700;
  font-size: 1rem;
}

.sort-description {
  font-weight: 600;
  font-size: 0.9rem;
  padding-left: 28px;
}
</style>
