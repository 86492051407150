<template>
  <div class="main-wrapper">
    <section v-if="success === null">
      <Spinner />
    </section>
    <section v-else-if="success === true">
      <h1>{{ $t('unsubscribe_search_coverage_title') }}</h1>
      <p class="main-text">{{ $t('unsubscribe_search_coverage_info_3') }}</p>

      <BaseButton :link="$routes.SEARCH">{{
        $t('unsubscribe_search_coverage_new_search')
      }}</BaseButton>
    </section>
    <section v-else-if="success === false">
      <h1>{{ $t('unsubscribe_search_coverage_not_found_title') }}</h1>

      <p class="main-text">
        {{ $t('unsubscribe_search_coverage_not_found_text_1') }}
        {{ $t('unsubscribe_search_coverage_not_found_text_2') }}
      </p>

      <BaseButton :link="$routes.SEARCH">{{
        $t('unsubscribe_search_coverage_not_found_new_search')
      }}</BaseButton>
    </section>
  </div>
</template>

<script>
import { searchApi } from '@/utils/axiosConfig';
import Spinner from '@/components/Loading/Spinner';
import { mapActions } from 'vuex';
export default {
  components: {
    Spinner
  },

  data() {
    return {
      success: null
    };
  },

  computed: {
    coverageId() {
      return this.$route.query.coverageId || this.$route.params.coverageId;
    },

    hash() {
      return this.$route.query.hash || this.$route.params.hash;
    }
  },

  created() {
    if (this.coverageId && this.hash) {
      this.unsubscribe();
    } else {
      this.$router.replace(this.$routes.SEARCH);
    }
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast'
    }),

    async unsubscribe() {
      try {
        await searchApi.delete(`/coverage/${this.coverageId}/${this.hash}`);
        this.success = true;
      } catch (error) {
        this.success = false;
      }
    }
  }
};
</script>

<style scoped>
.main-wrapper {
  text-align: center;
}

section {
  padding: 10px;
}

.main-text {
  margin-top: 40px;
  margin-bottom: 60px;
}
</style>
