import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  baseLoading: true,
  hittaLoading: true,
  interestDataLoading: true,
  currentProposition: {},
  currentResidenceIndex: 0,
  sponsoredPropositions: [],
  // currently viewed proposition
  isCurrentVisited: false,
  propositionsSiteCount: 0
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
