<template>
  <div ref="return-later-wrapper" class="return-later-wrapper">
    <div class="return-later-circle">
      <BaseIcon :icon-file="'return-later-icon'" class="return-later-icon" />
    </div>
    <h2>
      {{ $t('return_later_for_more_swaps_message') }}
    </h2>
  </div>
</template>

<script>
import events from '@/utils/helpers/events';

export default {
  name: 'ReturnLaterMessage',
  props: {
    distributed: {
      type: [Number],
      default: 0
    },
    undistributed: {
      type: [Number],
      default: 0
    }
  },
  data() {
    return {
      isSeen: false
    };
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      if (this.isSeen) {
        return;
      }

      const rect = this.$refs['return-later-wrapper'].getBoundingClientRect();
      const inView =
        rect.top <
        (window.innerHeight || document.documentElement.clientHeight);

      if (inView) {
        this.isSeen = true;
        events.emitEvent(events.eventTypes.SEE, 'Return Later Message', {
          distributed: this.distributed,
          undistributed: this.undistributed
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.return-later-wrapper {
  display: flex;
  position: relative;
  width: 250px;
  max-width: 100%;
  margin: 75px auto 75px auto;
  flex-direction: column;
  align-items: center;

  @media ($desktop) {
    text-align: center;
  }
}

.return-later-wrapper h2 {
  position: absolute;
  bottom: 13px;
  line-height: 25px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  width: 250px;
  font-size: 14px;

  @media ($desktop) {
    font-size: 18px;
    width: 320px;
    line-height: 32px;
    bottom: 5px;
  }
}

.return-later-circle {
  background: rgba(0, 0, 0, 0.01);
  width: 235px;
  height: 235px;
  border-radius: 100%;
  border: 25px solid rgba(0, 0, 0, 0.03);

  @media ($desktop) {
    width: 275px;
    height: 275px;
  }
}

.return-later-icon {
  margin-top: -15px;
  margin-left: 50px;
  --icon-width: 120px !important;
  --icon-height: 120px !important;

  @media ($desktop) {
    margin-top: -21px;
    margin-left: 60px;
    --icon-width: 150px !important;
    --icon-height: 150px !important;
  }
}
</style>
