export function getCookies() {
  const cookies = {};

  try {
    if (!document.cookie) return cookies;

    const arr = document.cookie.split(';');
    arr.map(x => {
      x = x.trim();

      const start = x.indexOf('=');
      const key = x.substring(0, start);
      const value = x.substring(start + 1);

      cookies[key] = value;
    });

    return cookies;
  } catch (error) {
    console.error('Clould not get cookies', error.message);
  }

  return cookies;
}
