<template>
  <div id="tooltip" :class="variant === 'dark' ? 'tooltip dark' : 'tooltip'">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseToolTip',

  props: {
    variant: {
      type: String,
      default: 'base',
      required: false
    }
  },

  mounted() {
    this.$emit('callback');
  }
};
</script>

<style lang="scss" scoped>
.tooltip {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  position: relative;
  width: inherit;
  height: 74px;
  color: #fff;
  padding: 11px;
  text-align: center;
  line-height: 150%;
  border-radius: 5px;
  background: #409fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  user-select: none;
}

.tooltip:after {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -5px;
  top: -20%;
  transform: rotate(90deg);
  border: 8px solid #409fff;
  border-color: transparent #409fff transparent transparent;
  background: linear-gradient(
    360deg,
    hsla(210, 100%, 63%, 1) 0%,
    hsla(210, 100%, 63%, 1) 50%,
    hsla(210, 100%, 74%, 1) 100%
  );
  background: -moz-linear-gradient(
    360deg,
    hsla(210, 100%, 63%, 1) 0%,
    hsla(210, 100%, 63%, 1) 50%,
    hsla(210, 100%, 74%, 1) 100%
  );
  background: -webkit-linear-gradient(
    360deg,
    hsla(210, 100%, 63%, 1) 0%,
    hsla(210, 100%, 63%, 1) 50%,
    hsla(210, 100%, 74%, 1) 100%
  );
}
.dark {
  background: #213a75;
  &:after {
    content: '';
    border: 8px solid #213a75;
    border-color: transparent #213a75 transparent transparent;
  }
}
</style>
