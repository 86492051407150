var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-checkbox",class:{
    'is-active': _vm.isChecked,
    'is-disabled': _vm.disabled,
    'has-error': !_vm.valid,
    'is-required': _vm.required
  }},[_c('label',{staticClass:"base-checkbox-container"},[_c('input',{staticClass:"base-checkbox-input",attrs:{"type":"checkbox","name":_vm.name,"disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":_vm.isChecked},on:{"change":_vm.inputHandler}}),_vm._t("checkmark",function(){return [_c('div',{staticClass:"base-checkbox-checkmark",class:{
          'is-active': _vm.isChecked,
          secondary: _vm.secondary,
          sm: _vm.size === 'sm',
          md: _vm.size === 'md'
        }})]},null,{ isChecked: _vm.isChecked, disabled: _vm.disabled }),_vm._t("label",function(){return [(_vm.label)?_c('div',{staticClass:"base-checkbox-label",class:[
          {
            'variant-label-light': _vm.variant === 'label-light'
          }
        ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]},null,{ label: _vm.label, isChecked: _vm.isChecked, disabled: _vm.disabled })],2),_c('div',{staticClass:"base-checkbox-message"},[_c('transition',{attrs:{"name":"fade"}},[(!_vm.disabled)?_vm._t(_vm.computedMessageSlotName,function(){return [_c('div',{class:_vm.computedMessageClass},[_vm._v(_vm._s(_vm.computedMessage))])]},null,{ computedMessage: _vm.computedMessage }):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }