export const NO_IMAGE = require('@/assets/svg/skyline-placeholder-img.svg');
export const NO_IMAGE_SMALL = require('@/assets/svg/placeholder-img-small.svg');
export const NO_IMAGE_PIC_1 = require('@/assets/img/no-image1.png');
export const NO_IMAGE_PIC_2 = require('@/assets/img/no-image2.png');
export const NO_IMAGE_PIC_3 = require('@/assets/img/no-image3.png');

export default {
  NO_IMAGE,
  NO_IMAGE_SMALL,
  NO_IMAGE_PIC_1,
  NO_IMAGE_PIC_2,
  NO_IMAGE_PIC_3
};
