import {
  lbAws,
  lbPropositionApi,
  paymentApi
} from '../../../utils/axiosConfig';
import { router } from '../../../main';
import services from './services';
import routes from '../../../router/routes.constants';
import i18n, { routeLocale } from '@/i18n';
import { isFeatureEnabled } from '@/plugins/country/module';
import { isCountry } from '@/plugins/country/module';
import productServices from '../klarnaPayments/services';
import { log } from '../../../utils/logger';

async function getCurrentProposition(context, { propositionId }) {
  context.commit('setLoading', { loading: true });

  let propositions;

  if (
    context.rootState.myPropositions &&
    context.rootState.myPropositions.propositions &&
    context.rootState.myPropositions.propositions.length >= 1
  ) {
    window.Logger.log(
      '[handleSubscriptions]: Getting current propositions from store'
    );
    propositions = context.rootState.myPropositions.propositions;
  } else {
    window.Logger.log(
      '[handleSubscriptions]: Getting current propositions from api'
    );

    propositions = await context.dispatch(
      'myPropositions/fetchUserPropositions',
      null,
      { root: true }
    );
  }

  const currentProposition = propositions.find(
    proposition => propositionId && proposition.propositionId == propositionId
  );

  if (!currentProposition) {
    window.Logger.error(
      'Could not find current proposition in myPropositions',
      propositionId
    );
    return;
  }

  const { data } = await paymentApi.get(
    `/subscriptions/proposition/${propositionId}`
  );

  const subscription = data;

  window.Logger.log({ subscription }, { currentProposition });

  context.commit('setCurrentSubscription', { subscription });
  context.commit('setCurrentProposition', { currentProposition });

  context.dispatch('getProducts');

  context.commit('setLoading', { loading: false });
}

async function getProducts(context) {
  const propositionId = context.state.currentProposition.propositionId;
  if (!isFeatureEnabled('PAYMENT')) {
    context.commit('setProducts', {
      products: [
        {
          active: true,
          productId: 1000,
          quantity: 30
        }
      ]
    });
    return;
  }

  const products = await productServices.getPropositionProducts(propositionId);
  context.commit('setProducts', { products });
  context.dispatch('setOffers');
}

function transformPrice(price, months) {
  return Math.floor(price / months);
}

function setOffers(context) {
  const { days } = context.state.currentSubscription;
  const { products } = context.state;
  let offer1 = {};
  let offer2 = {};

  let product30;
  let product90;
  let product180;

  const p30 = products.find(p => p.quantity === 30);
  if (p30) {
    product30 = {
      id: p30.productId,
      price: p30.priceInclVat
    };
  }

  const p90 = products.find(p => p.quantity === 90);
  if (p90) {
    product90 = {
      id: p90.productId,
      price: p90.priceInclVat
    };
  }

  const p180 = products.find(p => p.quantity === 180);
  if (p180) {
    product180 = {
      id: p180.productId,
      price: p180.priceInclVat
    };
  }

  switch (days) {
    case 30:
      offer1 = product180
        ? {
            subtitle: i18n.t('offer_subtitle', routeLocale),
            offerTitle: i18n.t('offer_item_title_180', routeLocale),
            offerDays: '180',
            savings: Math.round(
              product30.price - transformPrice(product180.price, 6)
            ),
            subscriptionPrice: `${transformPrice(product180.price, 6)} ${i18n.t(
              'offer_currency_per_month',
              routeLocale
            )}`,
            isCheapest: true,
            productId: product180.id
          }
        : null;
      offer2 = product90
        ? {
            offerTitle: i18n.t('offer_item_title_90', routeLocale),
            offerDays: '90',
            savings: Math.round(
              product30.price - transformPrice(product90.price, 3)
            ),
            subscriptionPrice: `${transformPrice(product90.price, 3)} ${i18n.t(
              'offer_currency_per_month',
              routeLocale
            )}`,
            productId: product90.id
          }
        : null;
      break;

    case 60:
      offer1 = product180
        ? {
            subtitle: i18n.t(
              'offer_alternative_subscription_title',
              routeLocale
            ),
            offerTitle: i18n.t('offer_item_title_180', routeLocale),
            offerDays: '180',
            subscriptionPrice: `${transformPrice(product180.price, 6)} ${i18n.t(
              'offer_currency_per_month',
              routeLocale
            )}`,
            isCheapest: true,
            productId: product180.id
          }
        : null;
      offer2 = product30
        ? {
            offerTitle: i18n.t('offer_item_title_30', routeLocale),
            offerDays: '30',
            subscriptionPrice: `${product30.price} ${i18n.t(
              'offer_currency_per_month',
              routeLocale
            )}`,
            productId: product30.id
          }
        : null;
      break;
    case 90:
      offer1 = product180
        ? {
            subtitle: i18n.t(
              'offer_alternative_subscription_title',
              routeLocale
            ),
            offerTitle: i18n.t('offer_item_title_180', routeLocale),
            offerDays: '180',
            savings:
              transformPrice(product90.price, 2) -
              transformPrice(product180.price, 6),
            subscriptionPrice: `${transformPrice(product180.price, 6)} ${i18n.t(
              'offer_currency_per_month',
              routeLocale
            )}`,
            isCheapest: true,
            productId: product180.id
          }
        : null;
      offer2 = product30
        ? {
            offerTitle: i18n.t('offer_item_title_30', routeLocale),
            offerDays: '30',
            subscriptionPrice: `${product30.price} ${i18n.t(
              'offer_currency_per_month',
              routeLocale
            )}`,
            productId: product30.id
          }
        : null;
      break;

    case 180:
      offer1 = product90
        ? {
            subtitle: i18n.t(
              'offer_shorter_alternative_subscription_title',
              routeLocale
            ),
            offerTitle: i18n.t('offer_item_title_90', routeLocale),
            offerDays: '90',
            subscriptionPrice: `${transformPrice(product90.price, 3)} ${i18n.t(
              'offer_currency_per_month',
              routeLocale
            )}`,
            productId: product90.id
          }
        : null;
      offer2 = product30
        ? {
            offerTitle: i18n.t('offer_item_title_30', routeLocale),
            offerDays: '30',
            subscriptionPrice: `${product30.price} ${i18n.t(
              'offer_currency_per_month',
              routeLocale
            )}`,
            productId: product30.id
          }
        : null;

      break;

    default:
      break;
  }

  context.commit('setOffers', { offer1, offer2 });
}

async function changeSubscription(
  context,
  { newPrice, productId, currentProductId, changeContext }
) {
  context.commit('setChangeSubscriptionLoading', { loading: true });
  const propositionId = context.state.currentProposition.propositionId;

  try {
    await paymentApi.put(`/subscriptions/proposition/${propositionId}/change`, {
      productId
    });

    if (newPrice) {
      context.dispatch(
        'gtm/acceptPropositionWinbackOffer',
        {
          value: newPrice,
          productId,
          propositionId
        },
        { root: true }
      );
    }

    context.dispatch(
      'tracking/trackChangedSubscription',
      {
        propositionId,
        currentProductId,
        productId,
        changeContext
      },
      { root: true }
    );

    router.replace(routes.CHANGE_SUBSCRIPTION_DONE);
    context.commit('setChangeSubscriptionLoading', { loading: false });
  } catch (e) {
    window.Logger.error(e);
    context.commit('setChangeSubscriptionLoading', { loading: false });
  }
}

function blockButton(context) {
  context.commit('setCancelButtonReady', { ready: false });
}

function accountType(paymentMethod) {
  if (paymentMethod === 'Complimentary') return 'free';
  if (paymentMethod === 'Trial') return 'trial';
  else return 'paying';
}
async function sendFeedback(context) {
  const feedback = { ...context.state.cancelReasons };

  // convert number to string for cost feedback
  feedback.feedback = `${feedback.feedback}`;

  if (feedback.feedback === '') delete feedback.feedback;
  if (feedback.specifiedCategoryIndex === null)
    delete feedback.specifiedCategoryIndex;
  if (feedback.specifiedCategory === null || feedback.specifiedCategory === '')
    delete feedback.specifiedCategory;
  feedback.accountType = accountType(
    context.state?.currentSubscription?.paymentMethod
  );

  try {
    await paymentApi.post(
      `subscriptions/proposition/${feedback.propositionId}/cancel-reason`,
      feedback
    );
  } catch (error) {
    log(`Error sending feedback reason: ${error.toString()}`, true);
  }

  try {
    context.dispatch(
      'gtm/cancelledPropositionReason',
      {
        ...feedback,
        propositionId: context.state.currentProposition.propositionId
      },
      { root: true }
    );
  } catch (error) {
    console.log('error in track', error);
  }
}

async function cancelSubscription(
  context,
  { cancelImmediately = true, swapped = false }
) {
  const { propositionId } = context.state.currentProposition;

  context.commit('setCancelSubscriptionLoading', { loading: true });

  if (cancelImmediately && !swapped) {
    context.commit('setAutoSubscriptionLoading', { loading: true });
  } else {
    context.commit('setCancelSubscriptionLoading', { loading: true });
  }

  try {
    await paymentApi.put(`/subscriptions/proposition/${propositionId}/cancel`, {
      propositionId,
      cancellationCause: 0,
      // default to true if no checkbox is shown
      cancelImmediately
    });

    lbAws
      .post(`/email-service/cancel-scheduled-emails`, {
        email: context.rootState.app.user.email
      })
      .catch(error => {
        console.error('Could not cancel emails:', error.toString());
      });

    context.dispatch(
      'gtm/cancelPurchasedProduct',
      { propositionId: propositionId, what: 'proposition' },
      { root: true }
    );

    await context.dispatch('refreshStates', propositionId);

    context.commit('setCancelButtonReady', { ready: true });
    context.commit('setCancelSubscriptionLoading', { loading: false });
    context.commit('setAutoSubscriptionLoading', { loading: false });

    router.replace(
      `${i18n.t('path_subscription_cancelled', routeLocale)}?swapped=${swapped}`
    );
  } catch (e) {
    window.Logger.log(e);
    context.commit('setCancelButtonReady', { ready: true });

    context.commit('setCancelSubscriptionLoading', { loading: false });
    context.commit('setAutoSubscriptionLoading', { loading: false });

    throw Error(e);
  }
}

async function activateFreeSubscription(context) {
  const { propositionId } = context.state.currentProposition;

  context.commit('setAutoSubscriptionLoading', { loading: true });

  try {
    await paymentApi.put(
      `/subscriptions/proposition/${propositionId}/activate`
    );

    await context.dispatch('refreshStates', propositionId);

    context.commit('setAutoSubscriptionLoading', { loading: false });

    router.replace(routes.AUTO_SUBSCRIPTION_DONE);
  } catch (error) {
    window.Logger.log(error);
    context.commit('setAutoSubscriptionLoading', { loading: false });

    throw error;
  }
}

async function activateAutoSubscription(context) {
  const propositionId = context.state.currentProposition.propositionId;
  const productId = parseInt(context.state.currentSubscription.productId);

  context.commit('setAutoSubscriptionLoading', { loading: true });

  try {
    await lbAws.post('/lord-commander/subscription/proposition/change', {
      propositionId,
      productId
    });

    await context.dispatch('refreshStates', propositionId);

    router.replace(routes.AUTO_SUBSCRIPTION_DONE);

    context.commit('setAutoSubscriptionLoading', { loading: false });
  } catch (e) {
    window.Logger.error(e);
    context.commit('setAutoSubscriptionLoading', { loading: false });
  }
}

async function pauseSubscription(context) {
  const propositionId = context.state.currentProposition.propositionId;
  context.commit('setCancelSubscriptionLoading', { loading: true });
  await services.pauseSubscription({ propositionId });
  context.commit('setCancelSubscriptionLoading', { loading: false });
  router.push(routes.MY_PROPOSITIONS);
}

async function resumeSubscription(
  context,
  { propositionId, reloadPropositions = false }
) {
  if (reloadPropositions) {
    context.commit(
      'myPropositions/setPropositionLoading',
      { propositionId, loading: true },
      { root: true }
    );
  }

  await services.resumeSubscription({ propositionId });
  await context.dispatch('refreshStates', propositionId);
}

async function activateFreemium({ dispatch }, propositionId) {
  await paymentApi.put(`/subscriptions/proposition/${propositionId}/activate`);
  await dispatch('refreshStates', propositionId);
}

function resetInitialState({ commit }) {
  commit('resetInitialState');
}

function goToStep(context, { step, feedbackType }) {
  context.commit('setCurrentStep', { step });

  if (feedbackType) {
    context.commit('setFeedbackType', { feedbackType });
  }
}

function setCancelImmediatelyChecked(context, { checked }) {
  context.commit('setCancelImmediatelyChecked', { checked });
}

function openCancelModal(context, { changeSubscription }) {
  context.commit('openCancelModal', { changeSubscription });
}

function closeCancelModal(context) {
  context.commit('closeCancelModal');
}

function setCancelCategory(context, { category, categoryIndex }) {
  context.commit('setCancelCategory', { category, categoryIndex });
}

function setSpecifiedCancelCategory(context, { category, reasonIndex }) {
  context.commit('setSpecifiedCancelCategory', { category, reasonIndex });
}

function setCancelFeedback(context, { feedback }) {
  context.commit('setCancelFeedback', { feedback });
}

async function checkCancelOffer(context) {
  if (!isCountry('se')) {
    context.commit('showCancelOffer', { show: false });
    return;
  }
  const isClaimed = await services.checkCancelOffer();

  const currentSubscriptionProductId =
    context.state?.currentSubscription?.productId;

  const acceptedProductIds = [
    '1030',
    '1060',
    '1180',
    '5030',
    '2030',
    '2060',
    '2180'
  ];

  const shouldShow = acceptedProductIds.includes(currentSubscriptionProductId);

  context.commit('showCancelOffer', { show: !isClaimed && shouldShow });
}

async function acceptCancelOffer(context) {
  context.commit('setCancelSubscriptionLoading', { loading: true });

  await services.acceptOffer();
  context.commit('setCancelSubscriptionLoading', { loading: false });

  router.push(routes.SUBSCRIPTION_OFFER_ACCEPTED);
}

async function refreshStates(context, propositionId) {
  try {
    const propositionResponse = await lbPropositionApi.get(
      '/propositions/user'
    );
    const fetchedPropositions = propositionResponse.data;

    window.Logger.log('fetchedPropositions:' + fetchedPropositions);

    context.commit(
      'myPropositions/setPropositions',
      { propositions: fetchedPropositions },
      {
        root: true
      }
    );

    let primaryPropositionId = propositionId;

    const primaryProposition = fetchedPropositions.find(x => x.isPrimary == 1);
    if (!primaryProposition) {
      primaryPropositionId = primaryProposition.propositionId;
    }

    if (!primaryPropositionId) {
      // All ways to find a primary propositionId failed.
      throw Error(
        `No primary proposition was found (${
          fetchedPropositions && fetchedPropositions.length
            ? `returned: ${fetchedPropositions.length}`
            : 'returned 0 or null'
        })`
      );
    }

    window.Logger.log('primary propositionId: ' + primaryPropositionId);

    // Set the paid proposition to primary proposition and handle all kinds of state
    await context.dispatch(
      'myPropositions/setPrimaryProposition',
      { primaryPropositionId, skipSetRequest: true },
      { root: true }
    );
  } catch (error) {
    // State must be updated, so if anything fails, then hard reload
    log(error, true);
    location.reload();
  }
}

export default {
  getCurrentProposition,
  sendFeedback,
  openCancelModal,
  closeCancelModal,
  setCancelCategory,
  setSpecifiedCancelCategory,
  setCancelFeedback,
  changeSubscription,
  cancelSubscription,
  getProducts,
  setOffers,
  setCancelImmediatelyChecked,
  activateAutoSubscription,
  goToStep,
  resetInitialState,
  activateFreeSubscription,
  blockButton,
  pauseSubscription,
  resumeSubscription,
  checkCancelOffer,
  acceptCancelOffer,
  refreshStates,
  activateFreemium
};
