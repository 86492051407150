<template>
  <div v-if="appReady" class="desktop-menu">
    <template v-if="!isAuthenticated">
      <router-link class="desktop-menu__item" :to="$routes.SEARCH">
        {{ $t('menu_items_link_4') }}
      </router-link>

      <a
        v-if="$country.isFeatureEnabled('HOW_IT_WORKS')"
        class="desktop-menu__item"
        :href="$routes.HOW_IT_WORKS"
      >
        {{ $t('menu_items_link_6') }}
      </a>

      <a class="desktop-menu__item" :href="$routes.HELP">
        {{ $t('menu_items_link_5') }}
      </a>

      <a
        v-if="$country.isFeatureEnabled('SWAP_APPLICATION')"
        class="desktop-menu__item"
        :href="$routes.SWAP_APPLICATION"
      >
        {{ $t('menu_items_link_7') }}
      </a>

      <router-link class="desktop-menu__item" :to="$routes.LOGIN">
        {{ $t('menu_items_link_8') }}
      </router-link>

      <a class="desktop-menu__item" :href="$routes.GET_STARTED">
        <PrimaryButton :accent="true">
          {{ $t('menu_items_link_9') }}
        </PrimaryButton>
      </a>
    </template>

    <template v-else>
      <router-link class="desktop-menu__item" :to="$routes.SWAP_LIST">
        {{ $t('menu_items_link_1') }}
        <BadgeCount :notifications="notifications.newSwaps" class="inline" />
      </router-link>

      <router-link
        v-if="matchesViewABTest"
        class="desktop-menu__item"
        :to="$routes.MATCHES"
      >
        {{ $t('menu_items_link_13') }}
        <BadgeCount
          :notifications="
            notifications && notifications.unreadAndUpdatedMatches
          "
          class="inline"
        />
      </router-link>

      <router-link v-else class="desktop-menu__item" :to="$routes.SWAPS">
        {{ $t('menu_items_link_12') }}
        <BadgeCount
          :notifications="swapInterestCountsUnseenTotal"
          class="inline"
        />
        <transition name="plus-one">
          <div v-if="showSwapAddedBadge" class="swap-added-icon">+1</div>
        </transition>
      </router-link>

      <router-link
        v-if="matchesViewABTest"
        class="desktop-menu__item"
        :to="$routes.MY_PROPOSITIONS"
      >
        {{ $t('tab_bar_my_ads') }}
      </router-link>

      <router-link v-else class="desktop-menu__item" :to="$routes.MESSAGES">
        {{ $t('tab_bar_messages') }}
        <BadgeCount
          :notifications="notifications.unreadMessages"
          class="inline"
        />
      </router-link>

      <router-link
        v-if="!$country.isFeatureEnabled('PAYMENT')"
        class="desktop-menu__item"
        :to="$routes.MY_PROPOSITIONS"
      >
        {{ $t('tab_bar_my_ads') }}
      </router-link>

      <router-link
        v-if="
          !primaryPropositionIsPremiumOrFree &&
          $country.isFeatureEnabled('PAYMENT')
        "
        :to="$routes.PAYMENT_PROPOSITION + '/' + primaryPropositionId"
        class="desktop-menu__item"
      >
        <div class="item-with-icon">
          <BaseIcon
            :icon-file="'stars-green'"
            :width="24"
            :height="24"
            class="icon"
          />
          {{ $t('menu_items_get_premium') }}
        </div>
      </router-link>

      <span
        v-if="
          primaryPropositionIsPremiumOrFree &&
          $country.isFeatureEnabled('PAYMENT')
        "
        class="desktop-menu__item"
        @click="handleBoostClick"
      >
        <div class="item-with-icon">
          <BaseIcon
            icon-file="icon-rocket-purple"
            :width="24"
            :height="24"
            class="icon"
          />
          {{
            hasBoost ? $t('menu_items_your_boost') : $t('menu_items_get_boost')
          }}
        </div>
      </span>

      <div id="js-notification-toggle" class="notification-center">
        <div
          class="notification-toggle-icon-container"
          @click="handleNotificationCenterClick"
        >
          <BaseIcon
            :icon="notificationCenterIcon"
            :width="40"
            :height="40"
            :class="{ 'is-open': notificationCenterOpen }"
            class="notification-toggle-icon"
          />
          <div class="notifications-count">
            <BadgeCount
              :notifications="unseenNotificationsCount"
              class="inline"
            />
          </div>
        </div>
        <NotificationCenterContainer
          v-if="notificationCenterOpen && !isMobile"
        />
      </div>

      <div id="js-popup-toggle" class="menu-container" @click="toggleMenu">
        <BaseIcon
          icon-file="hamburger"
          :width="16"
          :height="16"
          class="hamburger-icon"
        />
        <ProfileAvatar class="desktop-avatar" :size="30" :avatar="userAvatar">
          <NotificationDot
            v-if="matchesViewABTest && notifications.unreadMessages > 0"
          />
        </ProfileAvatar>
      </div>
    </template>
  </div>
</template>

<script>
import NotificationDot from '../Icons/NotificationDot';
import BadgeCount from '../Icons/BadgeCount';
import ProfileAvatar from '../Icons/ProfileAvatar';
import PrimaryButton from '../Buttons/Primary';
import NotificationCenterContainer from '@/components/NotificationCenter/NotificationCenterContainer';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import events from '@/utils/helpers/events';

export default {
  name: 'MenuItems',

  components: {
    NotificationDot,
    BadgeCount,
    ProfileAvatar,
    PrimaryButton,
    NotificationCenterContainer
  },

  props: {
    userAvatar: {
      type: String,
      default: ''
    },
    notifications: {
      type: Object,
      required: true
    },
    isAuthenticated: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      appReady: 'app/ready',
      notificationCenterOpen: 'menu/isNotificationCenterOpen',
      unseenNotificationsCount: 'notificationCenter/unseenNotificationsCount',
      isMobile: 'screenSize/isMobile',
      swapInterestCountsUnseenTotal: 'swapList/swapInterestCountsUnseenTotal',
      showSwapAddedBadge: 'swapList/showSwapAddedBadge',
      primaryProposition: 'myPropositions/primaryProposition',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryPropositionIsPremiumOrFree:
        'myPropositions/primaryPropositionIsPremiumOrFree'
    }),

    matchesViewABTest() {
      return this.$growthbook.isFeatureFlagEnabled('matches-view');
    },

    hasBoost() {
      return this.primaryProposition.extraVisibility;
    },

    notificationCenterIcon() {
      if (this.notificationCenterOpen) {
        return 'bell-large-blue';
      } else {
        return 'bell-large';
      }
    }
  },

  methods: {
    ...mapActions({
      toggleMenu: 'menu/toggleMenu',
      toggleNotificationCenter: 'menu/toggleNotificationCenter',
      setGlobalModal: 'ui/setGlobalModal'
    }),

    ...mapMutations({
      setExtraVisibilityPaymentVisible: 'extraVisibility/setVisible',
      setExtraVisibilityPaymentProposition: 'extraVisibility/setProposition'
    }),

    handleBoostClick() {
      if (this.hasBoost) {
        this.setGlobalModal('BoostInfo');
        events.emitEvent(events.eventTypes.CLICK, 'Boost Statistics', {
          src: 'boost-info-menu'
        });
      } else {
        this.setExtraVisibilityPaymentProposition(this.primaryProposition);
        this.setExtraVisibilityPaymentVisible(true);
      }
    },

    handleNotificationCenterClick(e) {
      e.stopPropagation();
      this.toggleNotificationCenter();
    }
  }
};
</script>

<style lang="scss" scoped>
.desktop-menu {
  display: none;

  @media ($desktop) {
    align-items: center;
    display: flex;
  }

  &__item {
    border-bottom: 2px solid $transparent;
    color: $text-primary;
    font-size: 14px;
    font-weight: 600;
    height: $desktop-menu-height;
    line-height: $desktop-menu-height;
    margin-left: 10px;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;

    @media (max-width: 1100px) {
      padding: 0 8px;
      font-size: 0.8rem;
    }

    &:hover {
      border-bottom: 2px solid $main-blue;
    }
  }
}

.item-with-icon {
  display: flex;
  align-items: center;

  .icon {
    margin-right: 10px;
  }
}

.notification-center {
  position: relative;
}

.notification-toggle-icon-container {
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  margin-left: 15px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #e8e8e8;
  }
}

.notification-toggle-icon {
  transition: transform 0.1s ease-in-out;

  &.is-open {
    transform: rotate(20deg);
  }
}

.menu-container {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 12px;
  background: transparent;
  cursor: pointer;
  margin: 0;
  text-align: inherit;
  background-color: #fff;
  border: 1px solid rgba(28, 45, 65, 0.103);
  border-radius: 21px;
  transition: box-shadow 0.2s ease;
  margin-left: 22px;

  &:focus,
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  }
}

.desktop-avatar {
  border-radius: 50%;
  margin-left: 12px;
}

.active {
  border-bottom: 2px solid $main-blue;
}

.swap-added-icon {
  visibility: hidden;
  position: absolute;
  top: 15px;
  right: 0;
  width: 12px;
  height: 12px;
  background-color: #2ec973;
  border-radius: 50%;
  font-size: 8px;
  color: #2ec973;
  display: flex;
  place-items: center;
}

.plus-one-enter-active {
  visibility: visible;
  animation-name: plus-one;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
}

.notifications-count {
  position: absolute;
  right: -12px;
}

@keyframes plus-one {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(4);
    border: none;
    color: #fff;
    padding-left: 2px;
  }
  100% {
    transform: scale(1);
  }
}
</style>
