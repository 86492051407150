<template>
  <div class="checkbox-wrapper">
    <input
      :id="id"
      :name="id"
      :class="[
        'checkbox',
        { 'prevent-overflow': preventOverflow },
        { 'radio-style': radioStyle }
      ]"
      type="checkbox"
      :checked="value"
      @change.prevent="handleInput"
    />

    <label :style="labelStyle" :for="id">
      {{ label }}
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',
  props: {
    id: {
      type: String,
      required: true
    },
    handleUpdate: {
      type: Function,
      default: () => {}
    },
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    labelStyle: {
      type: Object,
      default: () => {}
    },
    preventOverflow: {
      type: Boolean,
      default: false
    },
    radioStyle: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleInput({ target }) {
      this.$emit('change', target.checked);
      this.handleUpdate(target.checked);
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  &:checked,
  &:not(:checked) {
    left: -9999px;

    position: absolute;

    &.prevent-overflow {
      top: -9999px;
    }
  }
  &:checked + label,
  &:not(:checked) + label {
    color: #2c3e50;
    cursor: pointer;
    display: inline-block;
    line-height: 26px;
    padding-left: 38px;
    position: relative;
  }
  &:checked + label:before,
  &:not(:checked) + label:before {
    background: #fff;
    border-radius: 3px;
    border: 1px solid #ddd;
    content: '';
    height: 26px;
    left: 0;
    position: absolute;
    top: 0;
    width: 26px;
  }
  &:checked + label:after,
  &:not(:checked) + label:after {
    background-color: #1195da;
    border-radius: 3px;
    content: '';
    height: 26px;
    left: 0px;
    position: absolute;
    top: 0px;
    transition: all 0.2s ease;
    width: 26px;
  }
  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  &:checked + label:after {
    background-image: url('../../../assets/svg/check-mark-white.svg');
    background-position: center 0px;
    background-size: 18px;
  }
}

.checkbox.radio-style {
  &:checked + label:before,
  &:not(:checked) + label:before {
    border-radius: 50px;
  }

  &:checked + label:after,
  &:not(:checked) + label:after {
    border-radius: 50px;
  }

  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    transform: scale(0.7);
  }
  &:checked + label:after {
    background-image: none;
    background-position: center 0px;
    background-size: 18px;
  }
}
</style>
