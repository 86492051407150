import endpoints from '@/utils/endpoints-constants';
import { searchApi } from '@/utils/axiosConfig';
import i18n, { routeLocale } from '@/i18n';
import axios from 'axios';
import { currentCountry } from '@/plugins/country/module';
import { searchTypes } from '../../../models/search-filter';

export function getGeolocation(geolocationId) {
  try {
    return axios.get(
      `${i18n.t(
        'url_geolocation2',
        routeLocale
      )}/location/${currentCountry}/${geolocationId}`
    );
  } catch (e) {
    console.error(e);
  }
}

export function getGeolocationsFromIds(geolocationIds) {
  try {
    return axios.post(
      `${i18n.t('url_geolocation2', routeLocale)}/search/${currentCountry}/ids`,
      {
        ids: geolocationIds
      }
    );
  } catch (e) {
    console.error(e);
  }
}

export function searchGeolocation(searchTerm) {
  try {
    return axios.get(
      `${i18n.t(
        'url_geolocation2',
        routeLocale
      )}/search/${currentCountry}/${encodeURI(searchTerm)}`
    );
  } catch (e) {
    console.error(e);
  }
}

export function searchMapboxAddress(searchTerm, country = currentCountry) {
  return axios.get(
    `https://api.maptiler.com/geocoding/${encodeURI(
      searchTerm
    )}json?language=${country}&key=jTrvi1p07Kys0YeUMuBV'`
  );
}

export async function getRelatedLocalities(localityId) {
  try {
    const result = await axios.get(
      `${i18n.t(
        'url_geolocation2',
        routeLocale
      )}/search/${currentCountry}/relatives/${localityId}`
    );

    return result.data;
  } catch (error) {
    // Empty catch block
  }

  return null;
}

export function addCoverage(coverage) {
  return searchApi.post(endpoints.API.SEARCH_COVERAGES, coverage);
}

export function removeCoverage(coverageId) {
  return searchApi.delete(`${endpoints.API.SEARCH_COVERAGES}/${coverageId}`);
}

export function getCoverageData() {
  return searchApi.get(endpoints.API.SEARCH_COVERAGES);
}

export function getSearchPropositions(searchObject) {
  return searchApi.post(endpoints.API.SEARCH_SEARCH, searchObject);
}

export function getSearchProposition(propositionId) {
  return searchApi.get(`${endpoints.API.SEARCH_PROPOSITION}/${propositionId}`);
}

export function mergeData(oldData, newData) {
  return {
    ...oldData,
    meta: newData.meta,
    results: [...(oldData.results || []), ...(newData.results || [])]
  };
}

export async function getCoverageGeolocations(coverages) {
  const geolocationIds = coverages
    .map(x => {
      const r = x.body.residence;
      const w = x.body.wish;

      if (r && r.geolocations) return r.geolocations;
      else if (w && w.geolocations) return w.geolocations;
    })
    .filter(x => x)
    .flat();

  const geolocationResult = await getGeolocationsFromIds(geolocationIds);
  if (geolocationResult.data && geolocationResult.data.length > 0) {
    const geolocations = geolocationResult.data;

    for (const coverage of coverages) {
      const type = coverage.body.residence
        ? searchTypes.RESIDENCE
        : searchTypes.WISH;

      let coverageGeolocationIds = [];

      if (coverage.body[type] && coverage.body[type].geolocations) {
        coverageGeolocationIds = coverage.body[type].geolocations;

        if (coverageGeolocationIds.length > 0) {
          const newGeolocation = geolocations.filter(geolocation =>
            coverageGeolocationIds.some(id => geolocation.id === id)
          );

          coverage.body[type].geolocations = newGeolocation;
        }
      }
    }
  }

  return coverages;
}
