function swapDetails(state) {
  return state.swapDetails;
}

function activeSwap(state) {
  return state.activeSwap;
}
function position(state) {
  return state.position;
}

export default { swapDetails, activeSwap, position };
