function setTokenExists(state, { exists, lastCheck }) {
  state.tokenExists = exists;
  state.lastTokenCheck = lastCheck;
  state.errorMessage = '';
}
function setSession(state, { session }) {
  state.session = session;
  state.errorMessage = '';
}
function setButtonsEnabled(state, { enabled }) {
  state.buttonsEnabled = enabled;
  state.errorMessage = '';
}
function setKlarnaAuthorizationToken(state, { token }) {
  state.klarnaToken = token;
  state.errorMessage = '';
}

function setKlarnaAuthResponse(state, { klarnaAuth }) {
  state.klarnaAuthResponse = klarnaAuth;
  state.errorMessage = '';
}

function setErrorMessage(state, { msg }) {
  state.errorMessage = msg;
}
function setAgreementChecked(state, { checked }) {
  state.agreementChecked = checked;
}
function setProducts(state, { products }) {
  state.all = products;
  state.errorMessage = '';
}

function setSelectedProductId(state, { productId }) {
  if (!productId) return;
  const product = state.all.find(p => p.productId == productId);
  state.selectedProduct = product;
}

function setSelectedAdditionalProducts(state, products) {
  if (!products) return;
  state.selectedAdditionalProducts = products;
}

function setSelectedProduct(state, product) {
  state.selectedProduct = product;
}

function setLastProductFetch(state, lastProductFetch) {
  state.lastProductFetch = lastProductFetch;
}

function setLoading(state, { loading }) {
  state.isCreatingPayment = loading;
}

function hasCheckedForToken(state, { hasChecked }) {
  state.hasCheckedForToken = hasChecked;
}

function updateSessionPayload(state, { newPayload }) {
  state.session.payload = newPayload;
}

export default {
  setAgreementChecked,
  setErrorMessage,
  setKlarnaAuthorizationToken,
  setKlarnaAuthResponse,
  setButtonsEnabled,
  setSession,
  setTokenExists,
  setProducts,
  setSelectedProductId,
  setSelectedProduct,
  setSelectedAdditionalProducts,
  setLoading,
  hasCheckedForToken,
  updateSessionPayload,
  setLastProductFetch
};
