<template>
  <section class="conversion-modal-content">
    <div class="conversion-modal-content-become-member">
      <h1>{{ $t('conversion_modal_content_heading') }}</h1>
      <ul class="conversion-modal-content-list">
        <li>{{ $t('conversion_modal_content_list_1') }}</li>
        <li>{{ $t('conversion_modal_content_list_2') }}</li>
        <li>{{ $t('conversion_modal_content_list_3') }}</li>
      </ul>
      <a
        :href="$routes.GET_STARTED"
        class="conversion-modal-content-button-container"
        @click="trackClick"
      >
        <AButton class="is-secondary" :is-rounded="true" :is-fullwidth="true">
          {{ $t('conversion_modal_content_button_1') }}
        </AButton>
      </a>
    </div>
    <div class="conversion-modal-content-already-member">
      <h1>
        {{ $t('conversion_modal_content_text_1') }}<br />
        {{ $t('conversion_modal_content_text_2') }}
      </h1>
      <a
        :href="$routes.LOGIN"
        class="conversion-modal-content-button-container"
      >
        <AButton :is-rounded="true" :is-fullwidth="true">
          {{ $t('conversion_modal_content_button_2') }}
        </AButton>
      </a>
    </div>
  </section>
</template>

<script>
import AButton from '@/components/atomic/atoms/a-button';
import events from '@/utils/helpers/events';

export default {
  name: 'ConversionModalContent',
  components: {
    AButton
  },
  methods: {
    trackClick() {
      events.queueEvent(events.eventTypes.CLICK, 'Proposition CTA', {
        id: `conversion-modal`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.conversion-modal-content {
  text-align: center;

  h1 {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.conversion-modal-content-button-container {
  display: block;
  margin: 0 1rem;
}

.conversion-modal-content-list {
  list-style-type: none;
  margin-bottom: 2rem;
  padding: 0 25px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;

  li {
    position: relative;
    margin-bottom: 12px;
  }

  li::before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: -25px;
    margin-top: -1px;
    top: 0;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../assets/svg/list-item-checkbox.svg');
  }
}

.conversion-modal-content-become-member {
  margin-bottom: 50px;
}

.conversion-modal-content-already-member {
  margin-bottom: 10px;
}

.conversion-modal-banner-proposition-page {
  margin-top: 5rem;
}
</style>
