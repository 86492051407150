export const constants = {
  DISABLED_FEATURES: {
    PAYMENT: { fr: true, dk: true },
    SWAP_APPLICATION: { nl: true, fr: true, de: true, dk: true },
    FILTERS_COOP: { nl: true, fr: true, de: true, dk: true },
    EXTRA_VISIBILITY: { fr: true, dk: true },
    PROPOSITIONS_CLOSE_BY: { fr: true },
    HOW_IT_WORKS: { nl: true, fr: true, de: true, dk: true },
    AMENITIES: { fr: true, dk: true },
    SITEGAINER: { nl: true, fr: true, de: true, dk: true },
    CONDO: { nl: true, de: true, dk: true },
    BANKID: { nl: true, de: true, dk: true },
    FREEMIUM: {},
    TRUSTPILOT: { nl: true, de: true, dk: true }
  },

  KEY_VALUES: {
    LANGUAGE: {
      se: 'sv',
      nl: 'nl',
      de: 'de',
      fr: 'fr',
      dk: 'da'
    },

    DOMAIN: {
      se: 'lagenhetsbyte.se',
      nl: 'woningruil24.nl',
      de: 'wohnungsswap.de',
      fr: 'echangeappartement.fr',
      dk: 'hjembytte.dk'
    },

    CURRENCY: {
      se: 'SEK',
      nl: 'EUR',
      fr: 'EUR',
      de: 'EUR',
      dk: 'DKK'
    },

    GOOGLE_TAG_MANAGER: {
      se: 'GTM-MT4HNZJ',
      nl: 'GTM-TSSB6WD',
      de: 'GTM-MSNFSK6',
      fr: 'GTM-KS7FCGJ',
      dk: 'GTM-T4P8J9ZS'
    },

    MIXPANEL: {
      se: 'de75e43464e7b16f2badc16098587171',
      nl: 'b8f3df59c65d266262e15ba62e774743',
      de: '',
      fr: ''
    },

    SENTRY: {
      se: 'https://14f0416558fa444cae5b626c3274c27d@o75495.ingest.sentry.io/1483693',
      nl: 'https://e54bbdbcf58343c7a2827cdf2556c211@o774700.ingest.sentry.io/5796561',
      fr: 'https://e54bbdbcf58343c7a2827cdf2556c211@o774700.ingest.sentry.io/5796561',
      de: 'https://e54bbdbcf58343c7a2827cdf2556c211@o774700.ingest.sentry.io/5796561'
    },

    TRUSTPILOT: {
      se: '567710d20000ff000586e10c',
      nl: '63fbe60353044ed29109e113',
      de: '63e36e342ba9106ee584074b',
      fr: ''
    },

    MAX_RENT: {
      se: 30000,
      dk: 30000,
      nl: 3000,
      fr: 3000,
      de: 3000
    },

    MAP_SETTINGS: {
      se: {
        center: [12.8, 63.11],
        zoom: 4
      },

      nl: {
        center: [5.2, 52.19],
        zoom: 7
      },

      fr: {
        center: [2.1, 47.1],
        zoom: 6.5
      },

      de: {
        center: [10.69, 50.77],
        zoom: 5.5
      },

      dk: {
        center: [10.29, 56.27],
        zoom: 6.9
      }
    }
  }
};

export const currentCountry = getCountry();

export function isFeatureDisabled(feature) {
  if (!feature) return false;
  feature = feature.toUpperCase();

  const featureObject = constants.DISABLED_FEATURES[feature];
  if (!featureObject) return null;

  const countrySpecificValue = featureObject[currentCountry];
  if (!countrySpecificValue) return false;

  if (typeof countrySpecificValue === 'function') {
    return countrySpecificValue();
  }

  return countrySpecificValue;
}

export function isFeatureEnabled(feature) {
  return !isFeatureDisabled(feature);
}

export function getValue(key) {
  key = key.toUpperCase();

  const values = constants.KEY_VALUES[key];
  if (!values) return null;

  if (values[currentCountry]) return values[currentCountry];
  if (values[currentCountry] === undefined && values['default'])
    return values['default'];

  return null;
}

export function isCountry(country) {
  return currentCountry === country.toLowerCase();
}

export function getCountry() {
  //Return country from env if exist
  const countryFromEnv = process.env.VUE_APP_COUNTRY;
  if (countryFromEnv) return countryFromEnv.toLowerCase();

  // Return top-domain.
  if (window.location.hostname.includes('.')) {
    const splittedHostname = window.location.hostname.split('.');
    const topLevelDomain = splittedHostname[splittedHostname.length - 1];
    if (constants.KEY_VALUES.LANGUAGE[topLevelDomain.toLowerCase()])
      return topLevelDomain.toLowerCase();
  }

  // Default to se (if localhost for example)
  return 'se';
}

export function getCountryUpperCase() {
  return getCountry().toLocaleUpperCase();
}

export function getLanguageFromCountry(country) {
  if (!country) country = currentCountry;
  return constants.KEY_VALUES.LANGUAGE[country.toLowerCase()];
}

export default {
  isFeatureDisabled,
  isFeatureEnabled,
  getValue,
  getCountry,
  getLanguageFromCountry,
  isCountry,
  constants,
  currentCountry
};
