import store from './store';

function doCheck() {
  return store.getters['app/ready'];
}

export default async function checkAppReady() {
  return new Promise(resolve => {
    if (doCheck()) {
      resolve(true);
    } else {
      const interval = setInterval(() => {
        if (doCheck()) {
          clearInterval(interval);
          resolve(true);
        }
      }, 50);
    }
  });
}
