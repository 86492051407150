<template>
  <div class="header-wrapper">
    <BaseModal
      v-if="showConversionModal"
      :title="$t('proposition_conversion_modal_title')"
      @close="toggleConversionModal"
    >
      <ConversionModalContent />
    </BaseModal>
    <div
      v-if="displayMode === 'PLACEHOLDER'"
      :class="[
        'image-area',
        displayMode === 'PLACEHOLDER' ? 'no-pictures' : ''
      ]"
    >
      <div class="gallery">
        <div v-for="i in [1, 2, 3]" :key="i" class="gallery-placeholder">
          <div class="placeholder-image"></div>
        </div>
      </div>
      <div class="large-image-area placeholder">
        <div class="placeholder-image large"></div>
        <div>{{ $t('proposition_missing_image_text') }}</div>
      </div>
    </div>
    <component
      :is="imagesWrapperTag"
      v-else
      class="image-area"
      :to="imageUrl"
      @click="handleImageClick()"
    >
      <div v-if="galleryImages.length" class="gallery">
        <div
          v-for="(img, index) in galleryImages"
          :key="img.index"
          :class="[
            'gallery-image-wrapper',
            { 'half-size': galleryImages.length <= 2 }
          ]"
        >
          <img class="gallery-image" :src="img.fileName" />
          <div
            v-if="hasAccess && index === galleryImages.length - 1"
            class="gallery-image-overlay"
          >
            <div>
              {{
                $t('desktop_proposition_hero_show_more_images', {
                  images: images.length
                })
              }}
            </div>
          </div>
          <PropositionGalleryCTA
            v-if="!hasAccess && index === galleryImages.length - 1"
          />
        </div>
      </div>

      <div
        v-if="displayMode === 'DUO_STANDING'"
        :key="propositionId"
        :class="['large-image-area', { 'fill-width': !galleryImages.length }]"
      >
        <img
          v-if="sortedMeasured[0]"
          :src="sortedMeasured[0].fileName"
          class="standing"
        />
        <img
          v-if="sortedMeasured[1]"
          :src="sortedMeasured[1].fileName"
          class="standing"
        />
      </div>

      <div
        v-if="displayMode === 'SOLO_STANDING'"
        :class="['large-image-area', { 'fill-width': !galleryImages.length }]"
      >
        <div class="standing-wrapper">
          <div
            class="blurred-background"
            :style="[
              {
                backgroundImage: sortedMeasured[0]
                  ? `url(${sortedMeasured[0].fileName})`
                  : '',
                opacity: 0.2
              }
            ]"
          ></div>
          <img
            v-if="sortedMeasured[0]"
            :src="sortedMeasured[0].fileName"
            class="standing sharp-edges"
          />
        </div>
      </div>

      <div
        v-if="displayMode === 'SOLO_LAYING'"
        :class="['large-image-area', { 'fill-width': !galleryImages.length }]"
      >
        <img
          v-if="sortedMeasured[0]"
          :src="sortedMeasured[0].fileName"
          class="laying"
        />
      </div>
    </component>
    <div class="map-area">
      <div v-if="!coords" class="map-loading-area block-pulse"></div>
      <MapAndStreetView
        v-else-if="coords"
        :coords="coords"
        :residence="residence"
      />
      <div v-else class="missing-map">
        <div class="missing-map-icon"></div>
        <div>{{ $t('desktop_proposition_hero_missing_map_info') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MapAndStreetView from '../Maps/MapAndStreetView';
import BaseModal from '@/components/BaseModal';
import ConversionModalContent from '@/components/Proposition/ConversionModalContent';
import PropositionGalleryCTA from '@/components/PropositionGalleryCTA';

const DISPLAY_MODES = {
  PLACEHOLDER: 'PLACEHOLDER',
  DUO_STANDING: 'DUO_STANDING',
  SOLO_STANDING: 'SOLO_STANDING',
  SOLO_LAYING: 'SOLO_LAYING'
};

export default {
  name: 'DesktopPropositionHero',

  components: {
    MapAndStreetView,
    BaseModal,
    ConversionModalContent,
    PropositionGalleryCTA
  },

  props: {
    coords: {
      type: [Object, Boolean],
      default: false
    },

    residence: {
      type: Object,
      required: true
    },

    propositionId: {
      type: String,
      required: true
    },

    residenceIndex: {
      type: Number,
      required: true
    },

    inModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      measuredImages: [],
      showConversionModal: false
    };
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated',
      allowAccess: 'app/allowAccess'
    }),

    imageUrl() {
      if (this.hasAccess) {
        const index = this.residenceIndex === 0 ? 1 : 2;
        return `${this.$routes.PROPOSITION_GALLERY}/${this.propositionId}/${index}`;
      }

      return '';
    },

    imagesWrapperTag() {
      return this.inModal || !this.hasAccess ? 'div' : 'router-link';
    },

    images() {
      return this.residence.images;
    },
    imageCount() {
      return this.images.length;
    },
    hasAccess() {
      return this.allowAccess || this.isAuthenticated;
    },
    galleryImages() {
      const { images, imageCount, displayMode } = this;

      let galleryImages = [];

      if (displayMode === DISPLAY_MODES.PLACEHOLDER) return galleryImages;

      if (displayMode === DISPLAY_MODES.DUO_STANDING && imageCount > 2) {
        galleryImages = images.slice(2, 5);

        return galleryImages;
      }

      // If we get here, we have a solo image
      galleryImages = images.slice(1, 4);
      return galleryImages;
    },

    displayMode() {
      const { sortedMeasured: measuredImages, imageCount } = this;
      const firstIsStanding = measuredImages[0] && measuredImages[0].isStanding;
      const secondIsStanding =
        measuredImages[1] && measuredImages[1].isStanding;

      // no images, display placeholders
      if (imageCount === 0) return DISPLAY_MODES.PLACEHOLDER;

      // 1 standing
      if (firstIsStanding && !secondIsStanding)
        return DISPLAY_MODES.SOLO_STANDING;

      // 2 standing
      if (firstIsStanding && secondIsStanding) {
        return DISPLAY_MODES.DUO_STANDING;
      }

      return DISPLAY_MODES.SOLO_LAYING;
    },

    sortedMeasured() {
      const measuredCopy = [...this.measuredImages];
      return measuredCopy.sort((a, b) => (a.index > b.index ? 1 : -1));
    }
  },

  watch: {
    residenceIndex() {
      this.measureImages();
    },
    propositionId() {
      window.Logger.log('New proposition, updating images');
      this.measureImages();
    }
  },

  mounted() {
    this.measureImages();
  },

  methods: {
    handleImageClick() {
      if (!this.hasAccess) {
        this.showConversionModal = true;
      }

      if (this.inModal) {
        this.$emit('open-gallery');
      }
    },

    measureImages() {
      const { images } = this;

      const loopStop = images.length > 2 ? 2 : images.length;

      const measured = [];

      for (let i = 0; i < loopStop; i++) {
        let img = document.createElement('IMG');

        img.onload = function (loadedImage) {
          let width, height;

          if (loadedImage.path) {
            width = loadedImage.path[0].width;
            height = loadedImage.path[0].height;
          } else if (loadedImage.target) {
            width = loadedImage.target.width;
            height = loadedImage.target.height;
          }

          measured.push({
            width,
            height,
            isStanding: height > width,
            fileName: images[i].fileName,
            index: i
          });
        };

        img.src = images[i].fileName;
      }

      this.measuredImages = measured;
    },

    toggleConversionModal() {
      this.showConversionModal = !this.showConversionModal;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  width: 95%;
  height: 25vw;
  min-height: 370px;
  margin: 0 auto;
  margin-top: 15px;
  max-width: 1600px;
  display: flex;
}

.image-area {
  cursor: pointer;

  width: 60%;
  min-width: 60%;
  display: flex;
  justify-content: space-evenly;
  &.no-pictures {
    cursor: default;
  }
}

.map-area {
  max-width: 40%;
  min-width: 40%;
  min-height: 100%;
}
.map-loading-area {
  height: 100%;
  width: 100%;
  background-color: #ddd;
  border-radius: 5px;
}

.gallery {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $small-box-radius;
}

.gallery-image-wrapper {
  position: relative;
  width: 100%;
  height: 32.6%;
  border-radius: $small-box-radius;

  &.half-size {
    height: 49.5%;
  }
}

.gallery-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  font-weight: 700;
  font-size: 0.9rem;
  background-color: rgba(44, 62, 80, 0.8);
  word-break: break-word;
  text-align: center;
}

.large-image-area {
  width: 78%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;

  &.fill-width {
    width: 98%;
  }

  &.placeholder {
    flex-direction: column;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 0.85rem;
    background-color: #f7f7f9;
    color: #2c3e5091;
  }

  @supports (-ms-ime-align: auto) {
    margin-left: 1%;
  }

  @media screen and (-ms-high-contrast: none) {
    margin-left: 1%;
  }
}

.blurred-background {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  // -webkit-filter: blur(5px) opacity(0.7); /* Safari 6.0 - 9.0 */
  // filter: blur(5px) opacity(0.7);
  background-size: cover;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: inherit;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    -webkit-filter: blur(7px);
    -moz-filter: blur(7px);
    -o-filter: blur(7px);
    -ms-filter: blur(7px);
    filter: blur(7px);
    transform: scale(1.1);
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f1f2f659;
  }
}

.standing-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

.standing {
  width: 49.5%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  z-index: 2;

  &.sharp-edges {
    border-radius: 0;
  }
}

.laying {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $small-box-radius;
}

.missing-map {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f7f7f9;
  border-radius: 5px;
  font-weight: 600;
}
.missing-map-icon {
  background-image: url('../../assets/svg/icons/map.svg');
  background-position: center;
  background-size: contain;
  height: 80px;
  width: 80px;
  margin-bottom: 20px;
}

.placeholder-image {
  background-image: url('../../assets/img/skyline-3-small-opaque.png');
  background-position: center;
  background-size: contain;
  height: 40%;
  width: 50%;
  opacity: 0.6;
}

.gallery-placeholder {
  width: 100%;
  height: 32.6%;
  border-radius: 5px;
  background-color: #f7f7f9;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
