<template>
  <div class="filter-checkbox-wrapper" @click.stop="toggle">
    <div class="title">{{ title }}</div>
    <div class="filter-checkbox">
      <transition name="scale" mode="out-in">
        <div v-if="checked" class="filter-checkbox-checked"></div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterCheckbox',

  props: {
    title: {
      type: String,
      required: true
    },
    checked: {
      type: [Boolean],
      default: false
    },
    toggle: {
      type: Function,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-checkbox {
  height: 26px;
  width: 26px;
  min-width: 26px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  overflow: hidden;
  margin-left: 6px;

  &-wrapper {
    margin: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &-checked {
    width: 26px;
    height: 26px;
    background-color: $transfer-blue;
    background-position: 40%;
    background-size: contain;
    background-image: url('../../../assets/svg/check-mark-white.svg');
    transition: all 0.2s ease-in-out;
  }
}

.title {
  font-weight: 600;
  font-size: 0.9rem;
}
</style>
