export function randomUuid() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

export function truncateText(value, limit) {
  // Regular expression to match HTML entities
  const entityPattern = /&[#a-zA-Z0-9]+;/g;

  // Split the string into parts (text and entities)
  let parts = value.split(entityPattern);
  let match;
  let entities = [];
  while ((match = entityPattern.exec(value)) !== null) {
    entities.push(match[0]);
  }

  // Rebuild the string, checking the length without entities
  let rebuilt = '';
  let currentLength = 0;
  for (let i = 0; i < parts.length; i++) {
    if (currentLength + parts[i].length > limit) {
      // If adding this part exceeds the limit, truncate this part and add ellipsis
      rebuilt += parts[i].substring(0, limit - currentLength) + '...';
      break;
    } else {
      // Add the text part and the next entity (if exists)
      rebuilt += parts[i];
      currentLength += parts[i].length;
      if (entities[i]) {
        if (currentLength + entities[i].length > limit) {
          // If adding the next entity exceeds the limit, add ellipsis instead
          rebuilt += '...';
          break;
        } else {
          // Add the entity
          rebuilt += entities[i];
          currentLength += entities[i].length;
        }
      }
    }
  }

  return rebuilt.length < value.length ? rebuilt : value;
}

export default { randomUuid, truncateText };
