<template>
  <div class="no-result">
    <BaseIcon
      :icon-file="noResultContent.icon"
      :width="117"
      :height="117"
      class="no-results-icon"
    />
    <h1 class="heading">{{ noResultContent.title }}</h1>
    <p class="text-body">
      {{ noResultContent.bodyText }}
    </p>
    <BaseButton
      v-if="shouldShowEditWishes"
      :link="`${$routes.EDIT_PROPOSITION}/${primaryPropositionId}`"
      class="go-to-edit-wishes-button"
      >{{ $t('swap_container_button') }}</BaseButton
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SWAP_FILTER_TYPES } from '@/store/modules/swapList';

export default {
  name: 'NoResultSwaps',

  data() {
    return {
      SWAP_FILTER_TYPES
    };
  },

  computed: {
    ...mapGetters({
      swapFilterType: 'swapList/swapFilterType',
      filterCount: 'swapList/filterCount',
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    }),

    shouldShowEditWishes() {
      return (
        this.swapFilterType === SWAP_FILTER_TYPES.UNANSWERED_NEW ||
        this.swapFilterType === SWAP_FILTER_TYPES.UNANSWERED
      );
    },

    noResultContent() {
      switch (this.swapFilterType) {
        case SWAP_FILTER_TYPES.ONLY_FULL_INTEREST:
          return {
            icon: 'stars-matt',
            title: this.$t('swaps_no_result_title'),
            bodyText: this.$t('swaps_no_result_full_desc')
          };
        case SWAP_FILTER_TYPES.LAST_PART:
          return {
            icon: 'icon-key-line',
            title: this.$t('swaps_no_result_title'),
            bodyText: this.$t('swaps_no_result_last_desc')
          };
        case SWAP_FILTER_TYPES.INTEREST_MARKED:
        case SWAP_FILTER_TYPES.MARKED:
        case SWAP_FILTER_TYPES.SWAPLIST_MARKED:
          return {
            icon: 'icon-hand-thumb-up',
            title: this.$t('swaps_no_result_title'),
            bodyText: this.$t('swaps_no_result_interest_desc')
          };
        case SWAP_FILTER_TYPES.FAVOURITES:
          return {
            icon: 'icon-heart-bold',
            title: this.$t('swaps_no_result_title'),
            bodyText: this.$t('swaps_no_result_fav_desc')
          };
        case SWAP_FILTER_TYPES.UNANSWERED:
        case SWAP_FILTER_TYPES.UNANSWERED_NEW:
        case SWAP_FILTER_TYPES.ALL:
          return {
            icon: 'stars-matt',
            title: this.$t('swaps_no_result_unanswered_title'),
            bodyText: this.$t('swaps_no_result_unanswered_desc')
          };
        case SWAP_FILTER_TYPES.NEW:
          return {
            icon: 'stars-matt',
            title: this.$t('no_result_nothing_new')
          };
        default:
          return {};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.no-result {
  display: grid;
  place-items: center;
  text-align: center;
  padding: 20px;
  padding-bottom: 25px;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 10px;

  @media ($desktop) {
    padding-top: 70px;
  }
}

.heading {
  font-family: $font-headings;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  margin-top: 30px;
  margin-bottom: 10px;
}

.text-body {
  margin: 0;
  line-height: 1.5;
}

.go-to-edit-wishes-button {
  margin-top: 24px;
}
</style>
