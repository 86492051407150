<template>
  <div :class="['indicator-wrapper', { vertical }]">
    <div
      v-for="(interest, i) in interests"
      :key="i"
      :class="[
        'indicator',
        {
          small,
          'list-size': listSize,
          interested: interest === true,
          'not-interested': interest === false
        }
      ]"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'InterestIndicators',

  props: {
    interests: {
      type: Array,
      required: true
    },
    vertical: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    listSize: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.indicator-wrapper {
  display: flex;

  &.vertical {
    flex-direction: column;
  }
}

.indicator {
  height: 19px;
  width: 19px;
  margin-right: 3px;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/svg/interestIcons/empty.svg');

  &.small {
    height: 14px;
    width: 14px;
  }

  &.list-size {
    height: 15px;
    width: 15px;
  }

  &.interested {
    background-image: url('../../assets/svg/interestIcons/interested-green.svg');
  }

  &.not-interested {
    background-image: url('../../assets/svg/interestIcons/not-interested.svg');
  }
}
</style>
