import { getInitialState } from './index';

function setLoading(state, { loading }) {
  state.loading = loading;
}

function setChangeSubscriptionLoading(state, { loading }) {
  state.changeSubscriptionLoading = loading;
}

function setCancelSubscriptionLoading(state, { loading }) {
  state.cancelSubscriptionLoading = loading;
}

function setAutoSubscriptionLoading(state, { loading }) {
  state.autoSubscriptionLoading = loading;
}

function setCurrentProposition(state, { currentProposition }) {
  state.currentProposition = currentProposition;
  state.cancelReasons.propositionId = currentProposition.propositionId;
}

function setCurrentSubscription(state, { subscription }) {
  state.currentSubscription = subscription;
}

function setProducts(state, { products }) {
  state.products = products;
}

function openCancelModal(state, { changeSubscription }) {
  state.cancelModalOpen = true;

  if (changeSubscription) {
    state.currentStep = state.CANCEL_MODAL_STEPS['offer'];
    state.offerType = 'CHANGE';
  } else {
    state.currentStep = state.CANCEL_MODAL_STEPS['start'];
    state.offerType = 'SAVE';
  }
}

function closeCancelModal(state) {
  state.cancelModalOpen = false;
}

function setCancelCategory(state, { category, categoryIndex }) {
  state.cancelReasons.category = category;
  state.cancelReasons.categoryIndex = categoryIndex;
}

function setSpecifiedCancelCategory(state, { category, reasonIndex }) {
  state.cancelReasons.specifiedCategory = category;
  state.cancelReasons.specifiedCategoryIndex = reasonIndex;
}

function setCancelFeedback(state, { feedback }) {
  state.cancelReasons.feedback = feedback;
}

function setOffers(state, { offer1, offer2 }) {
  state.offers = { offer1, offer2 };
}

function setCancelImmediatelyChecked(state, { checked }) {
  state.cancelImmediatelyChecked = checked;
}

function setCurrentStep(state, { step }) {
  state.currentStep = state.CANCEL_MODAL_STEPS[step];
}

function setFeedbackType(state, { feedbackType }) {
  state.feedbackType = feedbackType;
}

function setOfferType(state, { offerType }) {
  state.offerType = offerType;
}

function resetInitialState(state) {
  const { currentProposition, currentSubscription } = state;

  Object.assign(state, {
    ...getInitialState(),
    currentProposition,
    currentSubscription
  });
}

function setCancelButtonReady(state, { ready }) {
  state.cancelButtonReady = ready;
}

function showCancelOffer(state, { show }) {
  state.showCancelOffer = show;
}

export default {
  setLoading,
  setCurrentProposition,
  setCurrentSubscription,
  openCancelModal,
  closeCancelModal,
  setCancelCategory,
  setCancelFeedback,
  setSpecifiedCancelCategory,
  setProducts,
  setOffers,
  setChangeSubscriptionLoading,
  setCancelSubscriptionLoading,
  setAutoSubscriptionLoading,
  setCancelImmediatelyChecked,
  setCurrentStep,
  setFeedbackType,
  setOfferType,
  resetInitialState,
  setCancelButtonReady,
  showCancelOffer
};
