import { render, staticRenderFns } from "./a-dot-separator.vue?vue&type=template&id=4448dde2&scoped=true"
import script from "./a-dot-separator.vue?vue&type=script&lang=js"
export * from "./a-dot-separator.vue?vue&type=script&lang=js"
import style0 from "./a-dot-separator.vue?vue&type=style&index=0&id=4448dde2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4448dde2",
  null
  
)

export default component.exports