import Vue from 'vue';

const toggleMenu = state => {
  state.menuOpen = !state.menuOpen;
};

const closeMenu = state => {
  state.menuOpen = false;
};

const toggleNotificationCenter = state => {
  state.notificationCenterOpen = !state.notificationCenterOpen;
};

const openNotificationCenter = state => {
  state.notificationCenterOpen = true;
};

const closeNotificationCenter = state => {
  state.notificationCenterOpen = false;
};

function setMenuNotifications(state, notifications) {
  Vue.set(state, 'notifications', notifications);
}

function setChatOwnerId(state, newOwnerId) {
  state.chatOwnerId = newOwnerId;
}

function setAttentionDot(state, { show }) {
  state.showAttentionDot = show;
}

function showMobileTabBar(state) {
  state.showMobileTabBar = true;
}

function hideMobileTabBar(state) {
  state.showMobileTabBar = false;
}

function setStickyTabBar(state, value) {
  state.stickyTabBar = value;
}

export default {
  toggleMenu,
  closeMenu,
  toggleNotificationCenter,
  openNotificationCenter,
  closeNotificationCenter,
  setMenuNotifications,
  setAttentionDot,
  showMobileTabBar,
  hideMobileTabBar,
  setStickyTabBar,
  setChatOwnerId
};
