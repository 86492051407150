function setAnimProgressBar(state, value) {
  state.progressBar = value;
}

function setAnimStatus(state, value) {
  state.status = value;
}

function setAnimCurrentStep(state, value) {
  state.currentStep = value;
}

function setIsSuccess(state, value) {
  state.isSuccess = value;
}

export default {
  setAnimProgressBar,
  setAnimStatus,
  setAnimCurrentStep,
  setIsSuccess
};
