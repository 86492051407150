<template>
  <div class="social-share-buttons">
    <ShareNetwork
      network="facebook"
      class="social-button social-button--facebook"
      :url="pageUrl"
      :title="articleTitle"
      :description="articleDescription"
    >
      <img :src="icons.facebook" class="icon icon-fb" />
      {{ $t('social_share_buttons_facebook') }}
    </ShareNetwork>
    <ShareNetwork
      network="messenger"
      class="social-button social-button--messenger"
      :url="pageUrl"
      :title="articleTitle"
    >
      <img :src="icons.messenger" class="icon icon-fb" />
      {{ $t('social_share_buttons_messenger') }}
    </ShareNetwork>
    <ShareNetwork
      network="linkedin"
      class="social-button social-button--linkedin"
      :url="pageUrl"
      :title="articleTitle"
    >
      <img :src="icons.linkedin" class="icon icon-fb" />
      {{ $t('social_share_buttons_linkedin') }}
    </ShareNetwork>
    <ShareNetwork
      network="email"
      class="social-button social-button--email"
      :url="pageUrl"
      :title="articleTitle"
    >
      <img :src="icons.email" class="icon icon-fb" />
      {{ $t('social_share_buttons_email') }}
    </ShareNetwork>
  </div>
</template>

<script>
export default {
  name: 'MSocialSHareButtons',
  props: {
    pageUrl: {
      type: String,
      default: ''
    },
    articleTitle: {
      type: String,
      default: ''
    },
    articleDescription: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      icons: {
        facebook: require('../../../assets/svg/icon-logo-facebook.svg'),
        messenger: require('../../../assets/svg/icon-messenger.svg'),
        linkedin: require('../../../assets/svg/icon-logo-linkedin.svg'),
        email: require('../../../assets/svg/icon-mail-outline.svg')
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.social-share-buttons {
  margin-left: -5px;
  margin-top: 3rem;

  @media ($desktop) {
    margin-top: 4rem;
  }
}

.social-button {
  display: inline-block;
  margin: 5px;
  padding: 8px 11px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
  vertical-align: middle;
  text-decoration: none;
  font-weight: 600;

  @media ($desktop) {
    padding: 8px 12px;
  }

  .icon {
    margin-right: 4px;
    display: inline-block;
    width: 18px;
    height: 18px;
    position: relative;
    top: -1px;
  }
}

.social-button--facebook {
  background-color: #3b5998;

  &:hover {
    background-color: darken(#3b5998, 10%);
  }
}

.social-button--messenger {
  background-color: #0099ff;

  &:hover {
    background-color: darken(#0099ff, 10%);
  }

  img {
    padding: 1px;
  }
}

.social-button--linkedin {
  background-color: #007bb6;

  &:hover {
    background-color: darken(#007bb6, 10%);
  }
}

.social-button--email {
  background-color: #f1c40f;

  &:hover {
    background-color: darken(#f1c40f, 5%);
  }
}
</style>
