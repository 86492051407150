<template>
  <svg role="img" :class="['menu-icon', icon, { mask }]">
    <use
      :xlinkHref="`${icons[icon]}#${icon}`"
      :href="`${icons[icon]}#${icon}`"
    />
    <!-- Test -->
  </svg>
</template>

<script>
export default {
  name: 'MenuIcon',
  props: {
    icon: { type: String, default: '' },
    mask: { type: Boolean, default: true }
  },
  data: function () {
    return {
      icons: {
        add: require('../../assets/svg/icons/add-square.svg'),
        blockedHouse: require('../../assets/svg/icons/blocked-house.svg'),
        close: require('../../assets/svg/icons/close.svg'),
        contract: require('../../assets/svg/icons/check-badge.svg'),
        edit: require('../../assets/svg/icons/cog.svg'),
        heart: require('../../assets/svg/icons/love-hat.svg'),
        help: require('../../assets/svg/icons/help-wheel.svg'),
        logout: require('../../assets/svg/icons/lock-2.svg'),
        messages: require('../../assets/svg/icons/conversation-chat.svg'),
        realEstate: require('../../assets/svg/icons/real-estate-house.svg'),
        rocket: require('../../assets/svg/icons/space-rocket-earth.svg'),
        searchSquare: require('../../assets/svg/icons/search-square.svg'),
        search: require('../../assets/svg/icons/search-bar-1.svg'),
        subscription: require('../../assets/svg/icons/rss.svg'),
        swap: require('../../assets/svg/icons/bytesforslag.svg'),
        trash: require('../../assets/svg/icons/trash.svg'),
        wallet: require('../../assets/svg/icons/money-wallet-1.svg'),
        login: require('../../assets/svg/icons/unlock-2.svg'),
        lightbulb: require('../../assets/svg/icons/lightbulb.svg')
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.menu-icon {
  height: 20px;
  width: 20px;
  background-position: center;
  background-size: contain;

  &.mask {
    fill: $text-primary;
  }

  &.realEstate,
  &.blockedHouse,
  &.swap,
  &.login,
  &.lightbulb {
    fill: none;
    stroke: $text-primary;
    stroke-width: 1.5px;
  }
}
</style>
