<template>
  <transition :name="TRANSITION_NAME" mode="out-in">
    <!-- <proposition-pause
      v-if="currentStep === STEPS['propositionPause']"
      :closeFunction="closeFunction"
      :goToStep="goToStep"
      :pauseSubscription="pauseSubscription"
      :cancelSubscriptionLoading="cancelSubscriptionLoading"
    /> -->

    <Start
      v-if="currentStep === STEPS['start']"
      :close-function="closeFunction"
      :set-cancel-category="setCancelCategory"
      :go-to-step="goToStep"
      :free="isFree"
      :trial="isTrial"
    />

    <Reasons
      v-if="currentStep === STEPS['reasons']"
      :close-function="closeFunction"
      :set-specified-cancel-category="setSpecifiedCancelCategory"
      :go-to-step="goToStep"
    />

    <ServiceUsedToFindSwap
      v-if="currentStep === STEPS['serviceUsedToFindSwap']"
      :close-function="closeFunction"
      :set-specified-cancel-category="setSpecifiedCancelCategory"
      :go-to-step="goToStep"
    />

    <Feedback
      v-if="currentStep === STEPS['feedback']"
      :close-function="closeFunction"
      :set-cancel-feedback="setCancelFeedback"
      :go-to-step="goToStep"
      :feedback="feedback"
      :send-feedback="sendFeedback"
      :cancel-subscription="cancelSubscription"
      :cancel-subscription-loading="cancelSubscriptionLoading"
      :cancel-immediately-checked="cancelImmediatelyChecked"
      :set-cancel-immediately-checked="setCancelImmediatelyChecked"
      :feedback-type="feedbackType"
      :free="isFree"
      :trial="isTrial"
    />

    <SearchCoverage
      v-if="currentStep === STEPS['searchCoverage']"
      :close-function="closeFunction"
      :go-to-step="goToStep"
      :send-feedback="sendFeedback"
    />

    <Congratulations
      v-if="currentStep === STEPS['congratulations']"
      :close-function="closeFunction"
      :go-to-step="goToStep"
      :send-feedback="sendFeedback"
      :cancel-subscription="cancelSubscription"
      :cancel-subscription-loading="cancelSubscriptionLoading"
    />

    <Offer
      v-if="currentStep === STEPS['offer']"
      :offer-type="offerType"
      :change-subscription="changeSubscription"
      :change-subscription-loading="changeSubscriptionLoading"
      :offers="offers"
      :go-to-step="goToStep"
      :set-specified-cancel-category="setSpecifiedCancelCategory"
      :close-function="closeFunction"
    />
  </transition>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import Start from './CancelSteps/Start';
import Reasons from './CancelSteps/Reasons';
import ServiceUsedToFindSwap from './CancelSteps/ServiceUsedToFindSwap';
import Feedback from './CancelSteps/Feedback';
import SearchCoverage from './CancelSteps/SearchCoverage';
import Congratulations from './CancelSteps/Congratulations';
import Offer from './CancelSteps/Offer';
// import PropositionPause from './CancelSteps/PropositionPause';

export default {
  name: 'CancelSubscription',

  components: {
    Start,
    Reasons,
    ServiceUsedToFindSwap,
    Feedback,
    SearchCoverage,
    Congratulations,
    Offer
    // PropositionPause
  },

  props: {
    closeFunction: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      TRANSITION_NAME: ''
    };
  },

  computed: {
    ...mapState('payment', ['hasPaymentToken']),
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      feedback: 'handleSubscription/feedback',
      offers: 'handleSubscription/offers',
      changeSubscriptionLoading: 'handleSubscription/changeSubscriptionLoading',
      cancelSubscriptionLoading: 'handleSubscription/cancelSubscriptionLoading',
      cancelImmediatelyChecked: 'handleSubscription/cancelImmediatelyChecked',
      currentSubscription: 'handleSubscription/currentSubscription',
      STEPS: 'handleSubscription/CANCEL_MODAL_STEPS',
      currentStep: 'handleSubscription/currentStep',
      feedbackType: 'handleSubscription/feedbackType',
      offerType: 'handleSubscription/offerType'
    }),
    isFree() {
      return this.currentSubscription.paymentMethod === 'Complimentary';
    },
    isTrial() {
      return this.currentSubscription.paymentMethod === 'Trial';
    }
  },
  mounted() {
    if (!this.hasPaymentToken) {
      this.goToStep({ step: 'start' });
    }
    setTimeout(() => this.setTransition(), 500);
  },
  methods: {
    ...mapActions({
      setSpecifiedCancelCategory:
        'handleSubscription/setSpecifiedCancelCategory',
      setCancelCategory: 'handleSubscription/setCancelCategory',
      setCancelFeedback: 'handleSubscription/setCancelFeedback',
      sendFeedback: 'handleSubscription/sendFeedback',
      changeSubscription: 'handleSubscription/changeSubscription',
      cancelSubscription: 'handleSubscription/cancelSubscription',
      setCancelImmediatelyChecked:
        'handleSubscription/setCancelImmediatelyChecked',
      goToStep: 'handleSubscription/goToStep',
      pauseSubscription: 'handleSubscription/pauseSubscription'
    }),

    setTransition() {
      this.TRANSITION_NAME = 'cancel';
    }
  }
};
</script>

<style lang="scss" scoped>
.cancel-enter-active,
.cancel-leave-active {
  transition-duration: 500ms;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.cancel-enter {
  opacity: 0;
  transform: translateX(100%);
}

.cancel-leave-active {
  opacity: 0;
  transform: translateX(-100%);
}
</style>
