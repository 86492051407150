<template>
  <img
    v-if="isMobile && url"
    ref="image"
    :class="classes"
    v-bind="$attrs"
    :style="spreadedStyles"
    :src="url"
    v-on="$listeners"
    @load="$emit('done')"
  />
  <div v-else-if="isMobile && !url" class="placeholder-container">
    <img
      ref="image"
      :class="['placeholder-img', classes]"
      v-bind="$attrs"
      :style="spreadedStyles"
      :src="url"
      v-on="$listeners"
      @load="$emit('done')"
    />
    <p>{{ $t('proposition_missing_image_text') }}</p>
  </div>
  <img
    v-else
    ref="image"
    v-bind="$attrs"
    :class="classes"
    :style="spreadedStyles"
    :src="url || NO_IMAGE_SMALL"
    v-on="$listeners"
    @load="$emit('done')"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { NO_IMAGE, NO_IMAGE_SMALL } from '@/utils/helpers/default-values';

export default {
  name: 'AImage',
  props: {
    url: {
      type: String,
      required: false,
      default: null
    },

    classes: {
      type: Array,
      default: () => [],
      required: false
    },

    styles: {
      type: Array,
      default: () => [],
      required: false
    }
  },

  data() {
    return {
      NO_IMAGE,
      NO_IMAGE_SMALL
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    }),
    spreadedStyles() {
      return [...this.styles];
    }
  }
};
</script>

<style lang="scss" scoped>
.placeholder-container {
  position: relative;
  background-color: #dddcdb;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;

  .placeholder-img {
    background-position: 50%;
    background-color: #dddcdb;
    background-size: 50%;
    background-image: url('../../../assets/svg/skyline-placeholder-img.svg');
    aspect-ratio: 2 / 0.9;
    object-fit: cover;
    display: block;
    height: 100%;
  }

  p {
    font-size: 12px;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 70%;
    z-index: 1;
  }
}
</style>
