import axios from 'axios';
import { currentCountry } from '@/plugins/country/module';
import { isDebug } from './debug';
import store from '@/store/store';

let user = null;

export async function log(text, isError) {
  try {
    if (!user) {
      user = store.getters['app/user'];
    }

    const isBot = getIsBot(navigator.userAgent);

    text = JSON.stringify(text, Object.getOwnPropertyNames(text));
    text = `
     ${user ? `User: ${user.userId}` : ''}
     Location: ${window.location.href}
     Bot: ${isBot}
     Agent: ${navigator.userAgent}
     ${text} `;
    if (isDebug()) {
      console.log(`DEBUG MODE: ${text}`);
      return;
    }

    await axios.post(
      'https://log-service.ok5u73j44fl2u.eu-north-1.cs.amazonlightsail.com/api/log',
      {
        service: 'authenticated-frontend-web',
        text: text,
        severity: isError ? 1 : -1,
        date: new Date(),
        country: currentCountry
      },
      {
        headers: { token: 'WW6CgJWsVlI14DfrPz1yQZOev9li3GECagk8y22cnGAx8sZDfW' }
      }
    );
  } catch (error) {
    console.error('Failed to send log');
  }
}

const crawlers = [
  /bot/,
  /spider/,
  /crawl/,
  /APIs-Google/,
  /AdsBot/,
  /Googlebot/,
  /mediapartners/,
  /Google Favicon/,
  /FeedFetcher/,
  /Google-Read-Aloud/,
  /DuplexWeb-Google/,
  /googleweblight/,
  /bing/,
  /yandex/,
  /baidu/,
  /duckduck/,
  /yahoo/,
  /ecosia/,
  /ia_archiver/,
  /facebook/,
  /instagram/,
  /pinterest/,
  /reddit/,
  /slack/,
  /twitter/,
  /whatsapp/,
  /youtube/,
  /semrush/,
  /Chrome-Lighthouse/,
  /Google Page Speed Insights/,
  /HeadlessChrome/,
  /Google-AMPHTML/,
  /GTmetrix/
];
const regex = new RegExp(crawlers.map(r => r.source).join('|'), 'i');

export function getIsBot(userAgent) {
  return regex.test(userAgent);
}
