var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tutStatus !== _vm.TUTORIAL_STATUS_TYPES.STARTED)?_c('div',{staticClass:"toggle-wrapper"},[_c('div',{class:[
      'toggle-option',
      { selected: _vm.swapFilterType === _vm.SWAP_FILTER_TYPES.UNANSWERED }
    ],on:{"click":function () { return _vm.setSwapType({
          swapFilterType: _vm.SWAP_FILTER_TYPES.UNANSWERED,
          update: true
        }); }}},[(_vm.preFilterCountUnmarked != undefined)?_c('div',{staticClass:"toggle-option-badge"},[_vm._v(" "+_vm._s(_vm.preFilterCountUnmarked)+" ")]):_vm._e(),_c('div',{staticClass:"toggle-option-text"},[_vm._v(" "+_vm._s(_vm.$t('swap_list_toggle_all'))+" ")])]),_c('div',{class:[
      'toggle-option',
      { selected: _vm.swapFilterType === _vm.SWAP_FILTER_TYPES.NEW }
    ],on:{"click":function () { return _vm.setSwapType({ swapFilterType: _vm.SWAP_FILTER_TYPES.NEW, update: true }); }}},[(_vm.newSwapsCount != undefined)?_c('div',{class:['toggle-option-badge', { highlighted: _vm.newSwapsCount > 0 }]},[_vm._v(" "+_vm._s(_vm.newSwapsCount)+" ")]):_vm._e(),_c('div',{staticClass:"toggle-option-text"},[_vm._v(" "+_vm._s(_vm.$t('swap_list_toggle_new'))+" ")])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }