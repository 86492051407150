export default {
  install(Vue, options) {
    const Logger = {};
    const noop = function () {};
    ['log', 'debug', 'info', 'warn', 'error'].forEach(level => {
      Logger[level] = options.isDebug
        ? window.console[level].bind(window.console)
        : noop;
    });
    window.Logger = Logger;
    Vue.prototype.$l = Logger;
    Vue.l = Logger;
  }
};
