<template>
  <div class="pagination-wrapper">
    <div class="pagination">
      <div
        v-if="currentPage !== 1"
        class="arrow left"
        @click="() => handleArrowClick('down')"
      ></div>
      <div
        v-for="i in renderedPages"
        :key="i"
        :class="['page', { active: i === currentPage }]"
        @click="() => onPageClick(i)"
      >
        {{ i }}
      </div>
      <div
        v-if="currentPage !== maxPage && maxPage !== 0"
        class="arrow right"
        @click="() => handleArrowClick('up')"
      ></div>
    </div>

    <div class="page-count">
      {{ $t('pagination_showing_page') }}
      <span class="bold">{{ renderedPages[0] }}</span>
      {{ $t('pagination_to') }}
      <span class="bold">{{ renderedPages[renderedPages.length - 1] }}</span>
      {{ $t('pagination_of') }}
      <span class="bold">{{ maxPage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    maxPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    onPageClick: {
      type: Function,
      required: true
    }
  },

  computed: {
    renderedPages() {
      const { currentPage, maxPage } = this;
      const renderedPages = [];

      let startLoop = currentPage - 2;
      let stopLoop = currentPage + 2;

      // Don't go over max page
      if (stopLoop > maxPage) {
        const diff = stopLoop - maxPage;
        stopLoop = maxPage;
        startLoop = startLoop - diff;
      }

      for (let i = startLoop; i <= stopLoop; i++) {
        // dont go under first page
        if (i < 1) {
          stopLoop++;
        } else {
          // Dont go over max page
          if (i <= maxPage) {
            renderedPages.push(i);
          }
        }
      }
      return renderedPages;
    }
  },

  methods: {
    handleArrowClick(direction) {
      if (direction === 'down') {
        this.onPageClick(this.currentPage - 1);
      } else {
        this.onPageClick(this.currentPage + 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 40px;

  @media ($mobile) {
    padding-bottom: 120px;
  }
}

.page-count {
  font-size: 0.9rem;
  font-weight: 600;
  color: $text-secondary;
}

.bold {
  font-weight: 700;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.page {
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border-radius: 50%;
  margin: 0 3px;
  background-color: #f1f1f1;
  color: $text-primary;

  &.active {
    background-color: $transfer-blue;
    color: #fff;
  }
}

.dots {
  height: 40px;
  width: 20px;
  display: flex;
  margin: 0 3px;

  justify-content: center;
  align-items: flex-end;
  font-weight: 700;
  font-size: 1.2rem;
}

.arrow {
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 3px;
  background-color: #f1f1f1;
  background-position: center;
  background-size: 40%;
  background-image: url('../../assets/svg/arrow-down-black.svg');

  &.left {
    transform: rotate(90deg);
  }
  &.right {
    transform: rotate(270deg);
  }
}
</style>
