import store from '../store/store';

function isInside(path, elements) {
  return Boolean(
    elements.find(element => {
      if (Array.isArray(path)) return path.indexOf(element) !== -1;
      return path === element;
    })
  );
}

function handleAction(action) {
  if (typeof action === 'function') action();
  else store.dispatch(action);
}

function handleClickOutside({ event: e, elements = [], action }) {
  if (e.path || e.composedPath) {
    const path = Array.from(e.path || (e.composedPath && e.composedPath()));
    if (!path || isInside(path, elements)) return;
    handleAction(action);
  } else {
    const app = document.getElementById('app');
    let path = e.target;

    while (path) {
      if (isInside(path, elements)) return;
      if (path === app) break;
      path = path.parentElement;
    }
    handleAction(action);
  }
}

export default handleClickOutside;
