function setTrialData(state, { data }) {
  let [y, m, d] = data.split('-');
  if (m.length === 1) {
    m = `0${m}`;
  }
  if (d.length === 1) {
    d = `0${d}`;
  }

  const date = [y, m, d].join('-');

  const trialEndDate = new Date(date);

  state.trialBanner = true;
  state.trialEndDate = trialEndDate;
}

function removeTrialData(state) {
  state.trialBanner = false;
  state.trialEndDate = null;
}

function setDisplayedOutdatedTrial(state, { displayedOutdatedTrial }) {
  state.displayedOutdatedTrial = displayedOutdatedTrial;
}

function removeDisplayedOutdatedTrial(state) {
  state.displayedOutdatedTrial = null;
}

function temporaryHideTrialBanner(state, { hide }) {
  state.temporaryHideTrialBanner = hide;
}

export default {
  setTrialData,
  removeTrialData,
  setDisplayedOutdatedTrial,
  removeDisplayedOutdatedTrial,
  temporaryHideTrialBanner
};
