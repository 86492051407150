<template>
  <div
    id="map"
    ref="map"
    :class="[fullScreenMode ? 'full-screen' : '']"
    @click="$emit('click')"
  ></div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getGeolocation } from '@/store/modules/search/services';
import { loadJs, loadCss } from '@/utils/maptiler';

export default {
  props: {
    highlightedMarkerId: {
      type: [String, Number],
      default: () => ''
    },
    fullScreenMode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      searchArea: null,
      markers: {},
      highlightedMarker: null,
      beforeInitEvents: [],
      ready: false
    };
  },
  computed: {
    ...mapGetters({
      rootFilter: 'search/rootFilter'
    })
  },
  watch: {
    highlightedMarkerId: {
      handler: 'highlightMarker'
    }
  },

  created() {
    document.addEventListener('boundingBox', this.updateMapBox);

    document.addEventListener('markerPoints', e =>
      this.updateMarkerPoints(e.detail)
    );

    // tell parent that we are ready to receive events (even before map is ready)
    this.$emit('created');
  },

  async mounted() {
    await Promise.all([loadJs(), loadCss()]);
    this.initializeMap();
  },

  beforeDestroy() {
    document.removeEventListener('boundingBox', this.updateMapBox);

    document.removeEventListener('markerPoints', e =>
      this.updateMarkerPoints(e.detail)
    );

    this.ready = false;
  },

  methods: {
    async setPolygonLayer(id) {
      const res = await getGeolocation(id);
      const g = this.map.getSource('geolocationPolygon');
      if (
        g &&
        this.isCoordinatesSame(
          g._data.coordinates,
          res.data.geoJson.coordinates
        )
      ) {
        return;
      }

      this.clearLayers();

      this.map.addSource('geolocationPolygon', {
        type: 'geojson',
        data: res.data.geoJson
      });

      this.map.addLayer({
        id: 'geolocationLayer',
        type: 'line',
        source: 'geolocationPolygon',
        layout: {},
        paint: {
          'line-dasharray': [3, 1],
          'line-width': 2,
          'line-opacity': 0.7
        }
      });
    },

    setMarkerInfo(markerElement, marker, p) {
      markerElement.addEventListener('mouseleave', e => {
        this.$emit('markerLeave', { event: e, point: p });
      });

      markerElement.addEventListener('mouseenter', e => {
        this.$emit('markerEnter', { event: e, point: p });
      });

      marker.setLngLat(p.ll);
      marker.addTo(this.map);

      const lngLat = marker.getLngLat();

      if (lngLat) {
        const latRounded = lngLat.lat.toFixed(4);
        const zIndexNum = latRounded.toString().replace('.', '');
        markerElement.style.zIndex = `-${zIndexNum}`;
      }

      this.markers[p.id] = marker;
    },

    setRegularMarker(markerElement, p, markerGroups) {
      const latLngKey = `${Math.round(p.ll[0] * 10000) / 10000}_${
        Math.round(p.ll[1] * 10000) / 10000
      }`;
      const existing = markerGroups[latLngKey];
      if (existing) {
        existing.propositionIds.push(p.id);
        existing.markerElement.innerHTML = `<div class="marker"></div><span class="multipleProspects">${existing.propositionIds.length}</span>`;
        return true;
      }

      markerElement.innerHTML = `<div class="marker"></div>`;

      markerElement.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();

        this.$emit('click', {
          event: e,
          propositionIds: markerGroups[latLngKey].propositionIds
        });
      });

      markerGroups[latLngKey] = {
        markerElement,
        propositionIds: [p.id]
      };
    },

    setClusterMarker(markerElement, p) {
      markerElement.innerHTML = `<div class="clsm"><span>${p.c}</span></div>`;
    },

    setInfoMarker(markerElement, p) {
      markerElement.innerHTML = `
          <div class="swap-chain-map-marker-inner-wrapper ${
            p.swapInterested === false
              ? 'swap-chain-map-marker-inner-wrapper-not-interested'
              : ''
          }">
            <div class="swap-chain-map-marker-icon ${
              p.swapInterested == true
                ? 'swap-chain-map-marker-icon-interested'
                : ''
            } ${
        p.swapInterested == false
          ? 'swap-chain-map-marker-icon-not-interested'
          : ''
      }"></div>
            <div class="swap-chain-map-marker-column">
              <div class="swap-chain-marker-address-text">${p.adress}</div>
              <div class="swap-chain-map-marker-interest-text">${
                p.swapInterested === true
                  ? this.$t('swap_chain_map_is_interested')
                  : p.swapInterested === false
                  ? this.$t('swap_chain_map_answered_no')
                  : this.$t('swap_chain_map_waiting_for_answer')
              }</div>
            </div>
            <div class="swap-chain-map-marker-arrow ${
              p.swapInterested === false
                ? 'swap-chain-map-marker-arrow-not-interested'
                : ''
            }"></div>
          </div>
          `;
    },
    isCoordinatesSame(arr1, arr2) {
      const a1 = arr1 ? arr1.flat() : [];
      const a2 = arr2 ? arr2.flat() : [];
      return a1.length === a2.length;
    },

    highlightMarker(id) {
      if (this.highlightedMarker) {
        this.highlightedMarker.classList.remove('highlight', 'highlight-group');
      }

      if (!id) return;

      if (this.markers[id]) {
        this.highlightedMarker = this.markers[id].getElement();
        if (this.highlightedMarker.firstChild.classList.contains('clsm')) {
          this.highlightedMarker.classList.add('highlight-group');
        } else {
          this.highlightedMarker.classList.add('highlight');
        }
      }
    },

    clearLayers() {
      if (this.map.getLayer('geolocationLayer')) {
        this.map.removeLayer('geolocationLayer');
      }

      if (this.map.getSource('geolocationPolygon')) {
        this.map.removeSource('geolocationPolygon');
      }
    },

    updateMarkerPoints(markerPoints) {
      if (!this.ready) {
        // map is not ready, save the function for later
        this.beforeInitEvents.push(() => {
          this.updateMarkerPoints(markerPoints);
        });

        return;
      }

      if (!markerPoints) return;

      this.clearMarkers();

      const { filter, type } = this.rootFilter;

      if (
        filter.geolocations &&
        filter.geolocations.length > 0 &&
        type !== 'wish'
      ) {
        this.setPolygonLayer(filter.geolocations[0].id);
      } else {
        this.clearLayers();
      }

      let markerGroups = {};

      for (const p of markerPoints) {
        const marker = new maplibregl.Marker();
        const markerElement = marker.getElement();

        // Info marker
        if (p.t === 'SI') {
          this.setInfoMarker(markerElement, p);
        }
        // Cluster marker
        else if (p.t === 'C') {
          this.setClusterMarker(markerElement, p);
        }
        // Regular marker
        else {
          const shouldContinue = this.setRegularMarker(
            markerElement,
            p,
            markerGroups
          );
          if (shouldContinue) {
            continue;
          }
        }

        this.setMarkerInfo(markerElement, marker, p);
      }
    },

    updateMapBox(event) {
      const newMapProps = event.detail;
      if (!this.ready) {
        // map is not ready, save the function for later
        this.beforeInitEvents.push(() => {
          this.updateMapBox({ detail: newMapProps });
        });

        return;
      }

      if (!newMapProps) return;

      // prevent map jumping
      const current = this.map.getBounds();
      if (
        current &&
        current._sw.lng === newMapProps.sw.lng &&
        current._sw.lat === newMapProps.sw.lat &&
        current._ne.lng === newMapProps.ne.lng &&
        current._ne.lat === newMapProps.ne.lat
      ) {
        return;
      }

      this.map.fitBounds(
        [
          newMapProps.sw.lng,
          newMapProps.sw.lat,
          newMapProps.ne.lng,
          newMapProps.ne.lat
        ],
        {
          padding: newMapProps.padding || 15
        }
      );
    },

    clearMarkers() {
      for (const key of Object.keys(this.markers)) {
        this.markers[key].remove();
      }

      this.markers = {};
    },

    initializeMap() {
      this.ready = false;

      const countrySpecificMapSettings = this.$country.getValue('MAP_SETTINGS');

      this.map = new maplibregl.Map({
        container: 'map',
        style:
          'https://api.maptiler.com/maps/bc61ba4d-d8c0-49f0-b6c2-52c6004aee6f/style.json?key=1CDi4tTTlJKFFQHgS5zi',
        center: countrySpecificMapSettings.center,
        zoom: countrySpecificMapSettings.zoom
      });

      this.map.addControl(
        new maplibregl.NavigationControl({
          showCompass: false
        }),
        'top-right'
      );

      this.map.on('load', e => {
        // run the functions that was saved when map wasnt ready
        this.ready = true;

        for (const func of this.beforeInitEvents) {
          func();
        }

        this.$nextTick(() => {
          this.$emit('ready', e);
        });
      });

      this.map.on('zoomend', e => {
        if (e.originalEvent) {
          this.$emit('zoomend', e);
        }
      });

      this.map.on('zoomstart', e => {
        if (e.originalEvent) {
          this.$emit('zoomstart', e);
        }
      });

      this.map.on('moveend', e => {
        if (e.originalEvent) {
          if (e.originalEvent.type === 'resize') {
            return;
          }

          this.$emit('moveend', e);
        }
      });

      this.map.on('movestart', e => {
        if (e.originalEvent) {
          if (e.originalEvent.type === 'resize') {
            return;
          }

          this.$emit('movestart', e);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.clsm {
  border: 2px solid #2c3e50;
  background-color: white;
  display: inline-block;
  line-height: 0px;
  border-radius: 100%;
  font-size: 14px;
  padding: 3px;
}

.clsm span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 8px;
  margin-right: 8px;
}

/* .highlight > svg > g > :nth-child(2) {
  fill: red;
} */

.marker {
  width: 28px;
  height: 39px;
  cursor: pointer;
  background-size: contain;
  background-position: center;
  background-image: url('../../../assets/svg/marker-new.svg');
  pointer-events: none;
}

.multipleProspects {
  height: 16px;
  width: 16px;
  position: absolute;
  top: -0.4rem;
  right: -0.4rem;
  background-color: $main-blue;
  border-radius: 50%;
  line-height: 16px;
  text-align: center;
  color: #fff;
  font-size: 11px;
}

.highlight-group,
.highlight {
  z-index: 1;
}

.highlight-group > .clsm {
  background-color: $main-blue;
  border-color: white;
  color: white;
  transform: scale(1);
}

.highlight > .marker {
  width: 42px;
  height: 60px;

  background-image: url('../../../assets/svg/marker-active.svg');
}

.highlight {
  top: -10px !important;
}

#map {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;

  .mapboxgl-canvas {
    z-index: -999999999;
  }
  &.full-screen {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

@media ($mobile) {
  #map .mapboxgl-ctrl-top-right {
    display: none;
  }
}
</style>
