<template>
  <div
    class="base-textarea"
    :class="{
      'has-text': !!value,
      invalid: !valid
    }"
  >
    <label class="base-textarea-label" :for="name">
      <!-- @slot Custom textarea label -->
      <slot name="label" v-bind="{ label }">{{ label }}</slot>
    </label>
    <div class="base-textarea-wrapper" @click="handleTextAreaClick">
      <textarea
        v-bind="$attrs"
        :id="name"
        :ref="name"
        cols="30"
        :rows="rows"
        :valid="valid"
        :value="value"
        :errorMessage="errorMessage"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        :name="name"
        :style="{ maxHeight: maxHeight, resize: resize }"
        @onfocus="onFocus($event)"
        @onblur="onBlur($event)"
        @keyup="onChange($event)"
        v-on="listeners"
      />
    </div>

    <transition name="sf-fade">
      <slot
        v-if="!valid && errorMessage"
        name="error-message"
        v-bind="{ errorMessage }"
      >
        <div class="base-textarea-error-message">{{ errorMessage }}</div>
      </slot>
    </transition>
    <slot
      v-if="valid && !errorMessage && description"
      v-bind="{ errorMessage }"
      name="textarea-description"
    >
      <div class="base-textarea-description">{{ description }}</div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'TextArea',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    description: {
      type: String,
      default: ''
    },
    // Validate value of form input
    valid: {
      type: Boolean,
      default: true
    },
    // Error message value of form input. It will be appeared if `valid` is `false`.
    errorMessage: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false,
      description: 'Native input required attribute'
    },
    disabled: {
      type: Boolean,
      default: false,
      description: 'Native input disabled attribute'
    },
    rows: {
      type: Number,
      default: 15
    },
    maxHeight: {
      type: String,
      default: 'inherit'
    },
    resize: {
      type: String,
      default: 'both'
    }
  },
  data() {
    return {
      inputType: ''
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: event => this.$emit('input', event.target.value)
      };
    }
  },

  created() {
    this.inputType = this.type;
  },
  methods: {
    onChange: function () {
      this.$emit('onChange');
    },
    onBlur: function () {
      this.$emit('onBlur');
    },
    onFocus: function () {
      this.$emit('onFocus');
    },
    handleTextAreaClick() {
      try {
        this.$refs[this.name].focus();
      } catch (error) {
        // Do noting
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.base-textarea {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.base-textarea-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  overflow: hidden;

  .invalid & {
    border-color: #ff3843;
  }
}

.base-textarea-label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
  color: #878b9d;

  .invalid & {
    color: #ff3843;
  }
}

textarea {
  padding: 20px;
  margin: 0;
  border: 0;
  background-color: transparent;
  font-family: inherit;
  font-weight: inherit;
  font-size: 1rem;
  outline: 0;
  width: 100%;
  color: #000;
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  background-color: transparent;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

textarea::-webkit-input-placeholder,
textarea::placeholder {
  color: #878b9d;
}

.base-textarea-error-message {
  position: relative;
  margin: 3px 0;
  font-weight: 600;
  font-size: 0.8rem;
  color: #ff3843;
}

.base-textarea-description {
  margin-top: 3px;
  color: #878b9d;
  font-size: 14px;
}
</style>
