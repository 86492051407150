import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';

function setPropositionUser(state, { user }) {
  Vue.set(state.currentProposition, 'user', user);
}

function setCurrentProposition(state, { proposition, residenceIndex }) {
  state.currentResidenceIndex = residenceIndex;
  state.currentProposition = proposition;
  state.baseLoading = false;
}

function mapExtrasToProposition(state, { views, user }) {
  const currentPropositionCopy = cloneDeep(state.currentProposition);

  currentPropositionCopy.totalVisits = views.toString();
  currentPropositionCopy.user = user;

  state.currentProposition = currentPropositionCopy;
}

function mapAmenitiesDataToProposition(state, { hittaData }) {
  const currentPropositionCopy = { ...state.currentProposition };

  hittaData.amenities.residences.forEach((data, index) => {
    currentPropositionCopy.residences[index] = {
      ...currentPropositionCopy.residences[index],
      amenities: data
    };
  });

  state.currentProposition = currentPropositionCopy;

  state.hittaLoading = false;
}

function mapFavouriteDataToProposition(state, { favouriteData }) {
  const currentPropositionCopy = { ...state.currentProposition };

  currentPropositionCopy.favourite = favouriteData;

  state.currentProposition = currentPropositionCopy;
}

function hittaFailed(state) {
  state.hittaLoading = false;
}

function mapInterestDataToProposition(state, { interestData }) {
  const currentPropositionCopy = { ...state.currentProposition };

  const updatedCurrentPropositionCopy = {
    ...currentPropositionCopy,
    ...interestData
  };

  state.currentProposition = updatedCurrentPropositionCopy;
  state.interestDataLoading = false;
}

function stopInterestLoading(state) {
  state.interestDataLoading = false;
}

function noNeedForExtrasLoading(state) {
  state.hittaLoading = false;
  state.interestDataLoading = false;
}

function setIsLoading(state) {
  state.baseLoading = true;
  state.hittaLoading = true;
  state.interestDataLoading = true;
}

function setSponsoredPropositions(state, { sponsored }) {
  state.sponsoredPropositions = sponsored;
}

function setCurrentVisited(state, { visited }) {
  const currentCopy = { ...state.currentProposition };
  currentCopy.isVisited = visited;
  state.currentProposition = currentCopy;
}

function setCurrentResidence(state, { index }) {
  state.currentResidenceIndex = index;
}

function setSwapInterest(state, { targetPropositionId, interested }) {
  if (!state.currentProposition || !state.currentProposition.propositionId) {
    return;
  }

  if (
    targetPropositionId.toString() ===
    state.currentProposition.propositionId.toString()
  ) {
    state.currentProposition.interested = interested;
  }
}

function clearCurrentProposition(state) {
  state.currentProposition = {};
}

function setCurrentFavourite(state, { favourite }) {
  const currentPropositionCopy = { ...state.currentProposition };

  currentPropositionCopy.favourite = favourite;

  state.currentProposition = currentPropositionCopy;
}

function setPropositionsSiteCount(state, propositionsSiteCount) {
  state.propositionsSiteCount = propositionsSiteCount;
}

export default {
  setPropositionUser,
  setCurrentProposition,
  mapExtrasToProposition,
  setIsLoading,
  setSponsoredPropositions,
  setCurrentVisited,
  setCurrentResidence,
  setSwapInterest,
  clearCurrentProposition,
  noNeedForExtrasLoading,
  mapAmenitiesDataToProposition,
  hittaFailed,
  mapInterestDataToProposition,
  stopInterestLoading,
  setCurrentFavourite,
  mapFavouriteDataToProposition,
  setPropositionsSiteCount
};
