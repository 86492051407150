import { INITIAL } from '@/utils/values/slider-filter-constants';

export const searchTypes = {
  RESIDENCE: 'residence',
  WISH: 'wish'
};

export class SearchFilter {
  constructor(search) {
    if (!search) {
      search = {
        filter: {}
      };
    }

    this.type = search.type || searchTypes.RESIDENCE;
    const initialConstants = INITIAL[this.type.toUpperCase()];

    this.filter = {
      rooms: {
        min: initialConstants.ROOMS.MIN,
        max: initialConstants.ROOMS.MAX
      },
      sqm: {
        min: initialConstants.SQM.MIN,
        max: initialConstants.SQM.MAX
      },
      rent: {
        min: initialConstants.RENT.MIN,
        max: initialConstants.RENT.MAX
      }
    };

    this.filter.geolocations = [];
    this.filter.geolocationsSource = null;
    this.filter.freetext = null;

    this.filter.floor = null;

    this.filter.isBecomingCondo = false;
    this.filter.isCondo = false;
    this.filter.hasElevator = false;
    this.filter.hasFireplace = false;
    this.filter.hasBathtub = false;
    this.filter.hasBalcony = false;
    this.filter.hasImage = false;

    this.swapTypes = search.swapTypes || [11];
    this.page = search.page || 1;
    this.pageSize = search.pageSize || 10;
    this.searchArea = search.searchArea || null;
    this.map = search.map || null;

    if (!search.filter) return;

    this.filter.geolocations = search.filter.geolocations || [];
    this.filter.geolocationsSource = search.filter.geolocationsSource || null;
    this.filter.freetext = search.filter.freetext || null;

    this.filter.sqm = getRangeOrValue(search.filter.sqm, this.filter.sqm);
    this.filter.rooms = getRangeOrValue(search.filter.rooms, this.filter.rooms);
    this.filter.rent = getRangeOrValue(search.filter.rent, this.filter.rent);

    this.filter.floor =
      search.filter.floor || search.filter.floor === 0
        ? search.filter.floor
        : null;

    this.filter.isBecomingCondo = search.filter.isBecomingCondo || false;
    this.filter.isCondo = search.filter.isCondo || false;
    this.filter.hasElevator = search.filter.hasElevator || false;
    this.filter.hasFireplace = search.filter.hasFireplace || false;
    this.filter.hasBathtub = search.filter.hasBathtub || false;
    this.filter.hasBalcony = search.filter.hasBalcony || false;
    this.filter.hasImage = search.filter.hasImage || false;
  }
}

export function getRangeOrValue(val, defaultVal) {
  if (typeof val === 'object' && (val.min || val.min === 0) && val.max) {
    return val;
  } else if (typeof val === 'object' && (val.min || val.min === 0)) {
    return { min: val.min, max: defaultVal.max };
  } else if (typeof val === 'number' && val !== null) {
    return {
      min: val,
      max: null
    };
  } else return defaultVal;
}

export function convertToSearchFilter(coverage) {
  const coverageCopy = JSON.parse(JSON.stringify(coverage));

  const filter = coverageCopy.body;
  filter.page = 1;

  if (coverageCopy.body[searchTypes.RESIDENCE]) {
    filter.type = searchTypes.RESIDENCE;
    filter.filter = coverageCopy.body[searchTypes.RESIDENCE];
    delete filter[searchTypes.RESIDENCE];
  } else {
    filter.type = searchTypes.WISH;
    filter.filter = coverageCopy.body[searchTypes.WISH];
    delete filter[searchTypes.WISH];
  }

  return new SearchFilter(filter);
}

export function convertToCoverage(searchFilter) {
  searchFilter = JSON.parse(JSON.stringify(searchFilter));

  if (searchFilter.type === searchTypes.RESIDENCE) {
    if (searchFilter.filter.sqm.max === INITIAL.RESIDENCE.SQM.MAX) {
      delete searchFilter.filter.sqm.max;
    }

    if (searchFilter.filter.rooms.max === INITIAL.RESIDENCE.ROOMS.MAX) {
      delete searchFilter.filter.rooms.max;
    }

    if (searchFilter.filter.rent.max === INITIAL.RESIDENCE.RENT.MAX) {
      delete searchFilter.filter.rent.max;
    }
  }

  const searchObject = {
    page: searchFilter.page || 1,
    pageSize: searchFilter.pageSize || 10,
    swapTypes: searchFilter.swapTypes,
    orderBy: searchFilter.orderBy || [],
    searchArea: searchFilter.searchArea
  };

  if (searchFilter.map) searchObject.map = searchFilter.map;
  if (searchFilter.fromMapInteraction)
    searchObject.fromMapInteraction = searchFilter.fromMapInteraction;

  searchObject[searchFilter.type] = {
    ...searchFilter.filter
  };

  // Clean unused filter properties
  if (searchObject) {
    for (const key in searchObject[searchFilter.type]) {
      const value = searchObject[searchFilter.type][key];
      if (!value && value !== 0) delete searchObject[searchFilter.type][key];
    }

    if (
      searchObject[searchFilter.type].geolocations &&
      searchObject[searchFilter.type].geolocations.length > 0
    )
      searchObject[searchFilter.type].geolocations = searchObject[
        searchFilter.type
      ].geolocations.map(x => x.id);
    else if (
      searchObject[searchFilter.type].geolocations &&
      searchObject[searchFilter.type].geolocations.length === 0
    )
      delete searchObject[searchFilter.type].geolocations;
  }

  if (searchFilter.type === searchTypes.WISH)
    searchObject[searchFilter.type] = convertToWish(
      searchObject[searchFilter.type]
    );

  return searchObject;
}

export function convertToWish(filter) {
  filter = {
    ...filter
  };

  for (const key of Object.keys(filter)) {
    const val = filter[key];

    if (val && (val.max || val.max === 0)) filter[key] = val.max;
    else if (val && (val.min || val.min === 0)) filter[key] = val.min;
  }

  //if (!filter.sqm || (filter.sqm && filter.sqm < 15)) filter.sqm = 15;

  return filter;
}

export function getActiveFilterKeys(filter) {
  const defaultFilter = new SearchFilter({
    type: filter.type
  });

  const activeKeys = Object.keys(filter.filter).filter(
    key =>
      JSON.stringify(filter.filter[key]) !==
        JSON.stringify(defaultFilter.filter[key]) &&
      key !== 'geolocationsSource'
  );

  // Check if selected swap types is the same as default filter
  if (
    filter.swapTypes.length &&
    defaultFilter.swapTypes.toString() !== filter.swapTypes.toString()
  ) {
    activeKeys.push('swapTypes');
  }

  return activeKeys;
}
