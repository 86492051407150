<template>
  <div class="blog-collection-page">
    <template v-if="$country.isCountry('se')">
      <section class="blog-collection-section">
        <OBlogCollection
          initial-category="Bostadsbytarskolan"
          :show-highlighted-article="true"
          :initial-articles-to-load="7"
          :title="$t('blog_collection_section1_title')"
          :description="$t('blog_collection_section1_desc')"
        />
      </section>
      <section
        class="blog-collection-section blog-collection-section--highlighted"
      >
        <OBlogCollection
          initial-category="Vi som bytt lägenhet"
          :initial-articles-to-load="6"
          :title="$t('blog_collection_section2_title')"
          :description="$t('blog_collection_section2_desc')"
        />
      </section>
      <section class="blog-collection-section">
        <OBlogCollection
          :show-category-filter="true"
          :show-load-more-button="true"
          :show-highlighted-article="false"
          :initial-articles-to-load="6"
          :articles-to-load="9"
        />
      </section>

      <MConversionBanner v-if="!isAuthenticated" style="margin-top: 6rem" />

      <TheFooter :is-authenticated="isAuthenticated" />
    </template>
    <template v-else>
      <section class="blog-collection-section">
        <OBlogCollection
          :show-category-filter="false"
          :show-load-more-button="true"
          :show-highlighted-article="true"
          :initial-articles-to-load="7"
          :articles-to-load="9"
        />
      </section>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OBlogCollection from '@/components/atomic/organisms/o-blog-collection';
import MConversionBanner from '@/components/atomic/molecules/m-conversion-banner';
import TheFooter from '../../components/TheFooter';
import descriptionWithMaxLength from '@/utils/descriptionWithMaxLength';

export default {
  name: 'BlogCollection',
  components: {
    OBlogCollection,
    MConversionBanner,
    TheFooter
  },
  head: {
    title() {
      return {
        inner: this.$t('blog_collection_meta_title')
      };
    },
    meta() {
      return [
        {
          name: 'description',
          content: descriptionWithMaxLength(
            this.$t('blog_collection_meta_description')
          ),
          id: 'desc'
        },
        {
          property: 'og:title',
          content: this.$t('blog_collection_meta_title')
        },
        { property: 'og:url', content: window.location.href },
        {
          property: 'og:description',
          content: descriptionWithMaxLength(
            this.$t('blog_collection_meta_description')
          )
        }
      ];
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    })
  }
};
</script>

<style lang="scss" scoped>
.blog-collection-page {
  max-width: none;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 95px;

  @media ($desktop) {
    padding-bottom: 0;
    margin-top: 0;
  }
}

.blog-collection-section {
  margin-bottom: 3rem;

  @media ($desktop) {
    margin-bottom: 4rem;
  }
}

.blog-collection-section--highlighted {
  background-color: #f1f2f6;
  padding-top: 3rem;
  padding-bottom: 1rem;

  @media ($desktop) {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
}
</style>
