export function resizeImage(url, resolution) {
  if (!url) return '';
  if (!resolution) return '';

  const matches = url.match(/\/([0-9]{3,4}x{1}[0-9]{3,4})\//);
  const currentSize = matches[1];
  if (!currentSize) return '';
  else return url.replace(currentSize, resolution);
}

export default resizeImage;
