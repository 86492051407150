<template>
  <div class="dots">
    <template v-if="dots > 5">
      <div
        v-for="(dot, index) in isVisible"
        :key="index"
        :class="[
          'dot',
          {
            active: dot === active,
            smaller:
              (isVisible[isVisible.length - 2] === dot && active < dots - 3) ||
              (isVisible[1] === dot && active > 3),
            smallest:
              (isVisible[isVisible.length - 1] === dot && active < dots - 2) ||
              (isVisible[0] === dot && active > 2)
          }
        ]"
      ></div>
    </template>
    <template v-else>
      <div
        v-for="(dot, index) in dots"
        :key="index"
        :class="[
          'dot',
          {
            active: dot === active
          }
        ]"
      ></div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    dots: {
      type: Number,
      required: true
    },
    active: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    isVisible: [1, 2, 3, 4, 5]
  }),

  watch: {
    active(newVal, oldVal) {
      if (newVal > oldVal) {
        this.shouldBeVisible('next');
      } else {
        this.shouldBeVisible('prev');
      }
    }
  },

  methods: {
    shouldBeVisible(direction) {
      if (direction === 'next') {
        if (
          this.active > this.isVisible[this.isVisible.length - 3] &&
          this.active < this.dots - 1 &&
          this.active > this.isVisible[this.isVisible.length - 1] - 2
        ) {
          this.isVisible.push(this.isVisible[this.isVisible.length - 1] + 1);
        }

        if (
          this.isVisible.length > 7 ||
          (this.isVisible.length > 5 &&
            this.active > this.isVisible[this.isVisible.length - 1] - 2)
        ) {
          this.isVisible.shift();
        }
      }

      if (direction === 'prev') {
        if (this.active > 2 && this.active < this.isVisible[0] + 2) {
          this.isVisible.unshift(this.isVisible[0] - 1);
        }

        if (
          this.isVisible.length > 7 ||
          (this.active < this.isVisible[0] + 2 && this.isVisible.length > 5)
        ) {
          this.isVisible.pop();
        }
      }
    }
  }
};
</script>

<style lans="scss" scoped>
.dots {
  display: flex;
  align-items: center;
}

.dot {
  height: 6px;
  width: 6px;
  margin: 2px;
  background-color: #fff;
  opacity: 0.4;
  border-radius: 50%;
  /* transition: width 400ms ease-out, height 400ms ease-out; */
  transition: all 400ms ease-out;

  white-space: nowrap;
}

.dot.smaller {
  height: 4px;
  width: 4px;
}

.dot.smallest {
  height: 3px;
  width: 3px;
}

.dot.active {
  opacity: 1;
}
</style>
