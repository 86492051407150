import { render, staticRenderFns } from "./PropositionMap.vue?vue&type=template&id=6f7f3dfa"
import script from "./PropositionMap.vue?vue&type=script&lang=js"
export * from "./PropositionMap.vue?vue&type=script&lang=js"
import style0 from "./PropositionMap.vue?vue&type=style&index=0&id=6f7f3dfa&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports