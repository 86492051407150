function setLoading(state, { loading }) {
  state.loading = loading;
}

function setPaymentHistory(state, { payments }) {
  state.paymentHistory = payments;
}

export default {
  setLoading,
  setPaymentHistory
};
