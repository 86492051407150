import { lbAws } from '@/utils/axiosConfig';

async function pauseSubscription({ propositionId }) {
  try {
    await lbAws.post('/lord-commander/proposition/pause', {
      propositionId
    });
  } catch (e) {
    window.Logger.log('Failed to pause subscription');
    throw Error(e);
  }
}

async function resumeSubscription({ propositionId }) {
  try {
    await lbAws.post('/lord-commander/proposition/resume', {
      propositionId
    });
  } catch (e) {
    window.Logger.log('Failed to resume subscription');
    throw Error(e);
  }
}

async function checkCancelOffer() {
  try {
    const {
      data: {
        data: { isClaimed }
      }
    } = await lbAws.get('/campaign-service/check-cancel-subscription');

    return isClaimed;
  } catch (e) {
    window.Logger.log(e);
    return false;
  }
}

async function acceptOffer() {
  try {
    await lbAws.post('/campaign-service/claim-cancel-subscription', {});
    return true;
  } catch (e) {
    window.Logger.log(e);
    return false;
  }
}

export default {
  pauseSubscription,
  resumeSubscription,
  checkCancelOffer,
  acceptOffer
};
