<template>
  <button
    class="pill"
    :class="{ 'is-selected': isSelected }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'BasePill',
  props: {
    isSelected: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.pill {
  display: inline-block;
  padding: 11px 20px;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.01em;
  border-radius: 25px;
  color: #2c3e50;
  text-align: center;
  background-color: transparent;
  border: 2px solid #e8e8e8;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  user-select: none;
  -webkit-appearance: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    &:not([disabled]) {
      border-color: darken(#e8e8e8, 10%);
    }
  }
}

.pill.is-selected {
  background-color: #ebf5ff;
  border-color: $main-blue;

  &:hover {
    border-color: darken($main-blue, 10%);
  }
}

.pill[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>
