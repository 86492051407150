import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const TUTORIAL_STATUS_TYPES = {
  STARTED: 'started',
  COMPLETED: 'completed'
};

export const TUTORIAL_STEPS_TYPES = {
  HIGHLIGHT_SWAP: 'highlightSwap',
  FIRST_INTEREST: 'firstInterest',
  FIRST_INTEREST_MODAL: 'firstInterestModal',
  CONTINUE_INTEREST: 'continueInterest',
  TUTORIAL_SUCCESS: 'tutorialSuccess'
};

const state = {
  status: null,
  activeStep: null,
  progressBar: 0,
  escClose: false,
  tutInterestMarks: 0,
  showContextualTutorial: false
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
