<template>
  <div class="counter-container">
    <div class="icon" />
    <div class="info-wrapper">
      <div class="title">
        {{ $t('view_counter_viewed_times', { visits: visits }) }}
      </div>
      <!-- <div class="subtitle">
        Har visats {{ visits }} gånger sedan den blev inlagd.
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewCounter',

  props: {
    visits: {
      type: [String, Number],
      default: '500+'
    }
  },

  computed: {
    visitsTitle() {
      const visits = parseInt(this.visits, 10);

      if (visits < 500) {
        return this.$t('view_counter_visits_1');
      } else if (visits < 2500) {
        return this.$t('view_counter_visits_2');
      } else if (visits < 5000) {
        return this.$t('view_counter_visits_3');
      } else {
        return this.$t('view_counter_visits_4');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.counter-container {
  margin: 50px 0;
  border-bottom: 1px solid rgba(28, 45, 65, 0.103);
  padding: 30px 20px;
  display: flex;
  align-items: center;
  padding-left: 0px;

  @media ($desktop) {
    border-bottom: none;
    margin: 20px 0;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 10px;
  }

  @media ($mobile) {
    margin: 30px 0;
    padding-left: 5vw;
  }
}

.icon {
  height: 30px;
  width: 30px;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/svg/lightbulb.svg');
  margin-right: 10px;
}

.title {
  font-weight: 700;
  font-size: 0.95rem;
  color: $text-primary;
  margin-bottom: 2px;
}
.subtitle {
  color: $text-primary;
  font-weight: 600;
  font-size: 0.95rem;
}
</style>
