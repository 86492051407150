<template>
  <div class="text-block editor-content" v-html="content.Richtext"></div>
</template>

<script>
export default {
  name: 'TextBlock',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.text-block {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 0 16px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
</style>
