<template>
  <div class="change-payment">
    <SideMenu />

    <div class="content-container">
      <h2>
        {{ $t('change_payment_method_title') }}
      </h2>
      <p class="text">{{ $t('change_payment_method_text') }}</p>

      <BasePayment
        :pay-text="$t('change_payment_method_action')"
        :change-payment-method="true"
        @loading="isLoading = $event"
        @success="handleSuccess($event)"
        @error="getSetHasPaymentToken()"
      />

      <Spinner v-if="isLoading" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Spinner from '@/components/Loading/Spinner';
import BasePayment from '@/components/Payments/BasePayment';
import SideMenu from '@/components/MyPropositions/SideMenu';

export default {
  name: 'ChangePaymentMethod',

  components: {
    Spinner,
    BasePayment,
    SideMenu
  },

  data() {
    return {
      isLoading: true
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('change_payment_method_title')
      };
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  },

  methods: {
    ...mapActions({ getSetHasPaymentToken: 'payment/getSetHasPaymentToken' }),
    handleSuccess() {
      this.getSetHasPaymentToken();
      this.$router.replace(this.$routes.UPDATE_TOKEN_DONE_KLARNA);
    }
  }
};
</script>

<style lang="scss" scoped>
.change-payment {
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  @media ($desktop) {
    flex-direction: row;
  }
}

.content-container {
  padding-left: 60px;
  width: calc(75% - 30px);

  @media ($mobile) {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 10px;

    @media ($mobile) {
      margin-top: 20px;
      font-size: 18px;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 1.5;

    @media ($mobile) {
      font-size: 14px;
    }
  }
}

.agreement-checkbox-wrapper {
  padding-top: 10px;
  display: flex;
}

.terms-link {
  margin-top: 3.5px;
  margin-left: 3px;
}

.button-wrapper {
  display: inline-block;
  margin: 40px auto;

  @media ($mobile) {
    display: flex;
  }
}
</style>
