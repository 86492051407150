<template>
  <div class="sponsored-proposition-item">
    <BasePropositionCardNew
      :swap="proposition"
      :show-swap-chain-block="false"
      :show-additional-images="false"
      :show-two-to-one-block="false"
      :show-boost-icon="false"
      :used-in-boost-block="true"
      :show-favourite-button="false"
      style="box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)"
    />
  </div>
</template>

<script>
export default {
  name: 'SponsoredPropositionsItem',

  props: {
    proposition: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.sponsored-proposition-item {
  margin-bottom: 0;
  scroll-snap-align: center;
}
</style>
