var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-propositions-card"},[(_vm.proposition.loading)?_c('Spinner'):_vm._e(),(_vm.proposition.pausedAt !== null)?_c('div',{staticClass:"pause-overlay"},[_c('div',{staticClass:"pause-title"},[_vm._v(" "+_vm._s(_vm.isActivating ? _vm.$t('my_propositions_card_activating') : _vm.$t('my_propositions_card_ad_paused'))+" ")]),(!_vm.isActivating)?_c('div',{staticClass:"pause-subtitle"},[_vm._v(" "+_vm._s(_vm.$t('my_propositions_card_pause_subtitle'))+" ")]):_vm._e(),(!_vm.isActivating)?_c('PrimaryButton',{nativeOn:{"click":function($event){return _vm.handleResumeProposition.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('my_propositions_card_action_activate')))]):_vm._e()],1):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.showConfirmDelete)?_c('ConfirmModal',{attrs:{"no-callback":_vm.closeDeleteModal,"yes-callback":function () { return _vm.handleDelete(_vm.proposition.propositionId); },"confirm-text":((_vm.$t('my_propositions_card_delete_ad_question')) + " " + (_vm.proposition.residences[0].streetAddress) + "?"),"info-text":_vm.$t('my_propositions_card_delete_ad_text')}}):_vm._e()],1),_vm._l((_vm.proposition.residences),function(residence,index){return _c('router-link',{key:residence.streetAddress + _vm.proposition.propositionId + index,staticClass:"card-content",attrs:{"to":((_vm.$t('path_proposition', _vm.$routeLocale)) + "/" + (_vm.proposition.propositionId))}},[_c('div',{staticClass:"card-presentation"},[_c('div',{staticClass:"img",class:[
          residence.images && residence.images.length <= 0
            ? 'fallback-img'
            : ''
        ],style:([
          residence.images && residence.images.length
            ? {
                backgroundImage: ("url(" + (residence.images[0].fileName) + ")"),
                opacity: 1
              }
            : ''
        ])}),_c('div',{staticClass:"card-info"},[_c('div',[_c('p',{staticClass:"primary-text"},[_vm._v(_vm._s(residence.streetAddress))]),_c('p',{staticClass:"secondary-text"},[_vm._v(_vm._s(_vm.getArea(residence)))]),(
              _vm.isPrimaryProposition &&
              _vm.activePropositions.length > 1 &&
              _vm.proposition.active
            )?_c('div',{staticClass:"using-as-indicator"},[_vm._v(" "+_vm._s(_vm.$t('my_propositions_card_using_as'))+" ")]):_vm._e()]),(!_vm.isMobile)?_c('p',{staticClass:"secondary-text"},[_vm._v(" "+_vm._s(_vm.$t('my_propositions_card_ad_num'))+" "+_vm._s(_vm.proposition.propositionNo)+" ")]):_vm._e(),(_vm.proposition.residences.length === index + 1)?_c('p',{staticClass:"secondary-text"},[_vm._v(" "+_vm._s(_vm.$t('my_propositions_card_created'))+" "+_vm._s(_vm.proposition.createdAt)+" ")]):_vm._e()])])])}),(
      _vm.$country.isFeatureEnabled('EXTRA_VISIBILITY') &&
      _vm.proposition.active &&
      !_vm.proposition.isFreemium &&
      !_vm.proposition.isTrial &&
      !_vm.proposition.extraVisibility
    )?_c('div',{staticClass:"sponsored-propositions-cta"},[_c('div',{staticClass:"text-content"},[_c('h4',[_vm._v(_vm._s(_vm.$t('my_prop_card_boost_title')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('my_prop_card_boost_text_variant'))+" ")])]),_c('BaseButton',{staticClass:"purple smaller cta-button",attrs:{"icon-file":"icon-rocket-white"},on:{"click":function($event){$event.stopPropagation();_vm.setExtraVisibilityPaymentProposition(_vm.proposition);
        _vm.setExtraVisibilityPaymentVisible(true);}}},[_vm._v(_vm._s(_vm.$t('my_prop_card_boost_button')))])],1):(_vm.proposition.active && _vm.proposition.extraVisibility)?_c('div',{staticClass:"active-until"},[_c('BaseIcon',{staticClass:"active-until-icon",attrs:{"icon-file":"icon-rocket-purple"}}),_vm._v(" "+_vm._s(_vm.$t('boost_banner_active_until'))+" "+_vm._s(_vm.$dayjs.tz(_vm.proposition.extraVisibility.endDate).format('D MMMM'))+" ")],1):_vm._e(),_c('div',{staticClass:"card-options"},[_c('BaseButton',{staticClass:"card-option-button smaller",attrs:{"link":((_vm.$t('path_edit_proposition', _vm.$routeLocale)) + "/" + (_vm.proposition.propositionId))}},[_vm._v(_vm._s(_vm.$t('my_propositions_card_edit')))]),_c('div',{staticClass:"cta-btns-container"},[(_vm.proposition.active && !_vm.myVisitorsBlockedForLimited)?_c('router-link',{staticClass:"card-option flex-end-desktop",attrs:{"to":((_vm.$t('path_my_visitors', _vm.$routeLocale)) + "/" + (_vm.proposition.propositionId))}},[_vm._v(" "+_vm._s(_vm.$t('my_propositions_card_visitors'))+" ")]):(_vm.proposition.active && _vm.myVisitorsBlockedForLimited)?_c('div',{staticClass:"card-option flex-end-desktop",on:{"click":_vm.showMyVisitorsModal}},[_vm._v(" "+_vm._s(_vm.$t('my_propositions_card_visitors'))+" ")]):_vm._e(),(_vm.proposition.active)?_c('router-link',{staticClass:"card-option",attrs:{"to":((_vm.$t('path_handle_subscription', _vm.$routeLocale)) + "/" + (_vm.proposition.propositionId))}},[_vm._v(" "+_vm._s(_vm.$t('my_propositions_card_subscription'))+" ")]):_vm._e()],1),(!_vm.proposition.active && !_vm.paymentActive)?_c('button',{staticClass:"card-option smaller warning flex-end-desktop",attrs:{"warn":true},on:{"click":_vm.showDeleteModal}},[_vm._v(" "+_vm._s(_vm.$t('my_propositions_card_delete'))+" ")]):_vm._e(),(
        !_vm.proposition.active &&
        !_vm.paymentActive &&
        !_vm.proposition.freemiumAvailable
      )?_c('button',{staticClass:"card-option smaller success",on:{"click":function () {
          _vm.events.emitEvent(_vm.events.eventTypes.CLICK, 'Payment', {
            id: 'my-propositions-card'
          });

          _vm.routeToPath(
            ("/" + (_vm.proposition.inFreeArea
                ? _vm.$t('my_propositions_card_my_account')
                : _vm.$t('my_propositions_card_payment')) + "/" + (_vm.proposition.propositionId))
          );
        }}},[_vm._v(" "+_vm._s(_vm.$t('pay_proposition_activate'))+" ")]):(
        !_vm.proposition.active && !_vm.paymentActive && _vm.proposition.freemiumAvailable
      )?_c('button',{staticClass:"card-option smaller success",on:{"click":function($event){return _vm.showFreemiumCtaModal(_vm.proposition.propositionId)}}},[_vm._v(" "+_vm._s(_vm.$t('pay_proposition_activate'))+" ")]):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }