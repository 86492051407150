<template>
  <div class="confirm-wrapper">
    <div class="confirm-modal">
      <div class="confirm-text">
        {{ confirmText }}
      </div>
      <p v-if="infoText" class="info-text">{{ infoText }}</p>
      <div class="confirm-button-wrapper">
        <BaseButton class="smaller warning" @click.native="yesCallback">{{
          $t('confirm_modal_yes')
        }}</BaseButton>
        <BaseButton class="smaller" :warn="true" @click.native="noCallback">{{
          $t('confirm_modal_no')
        }}</BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ConfirmModal',

  props: {
    confirmText: {
      type: String,
      required: true
    },
    infoText: {
      type: String,
      default: ''
    },
    noCallback: {
      type: Function,
      required: true
    },
    yesCallback: {
      type: Function,
      required: true
    }
  },
  mounted() {
    this.showDarkOverlay();
  },

  destroyed() {
    this.hideDarkOverlay();
  },
  methods: {
    ...mapActions({
      hideDarkOverlay: 'overlay/hide',
      showDarkOverlay: 'overlay/show'
    })
  }
};
</script>

<style lang="scss">
.confirm-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: $modal-z-index;
}
.confirm-modal {
  padding: 18px;
  border-radius: 8px;
  background-color: #fff;
  max-width: 500px;

  @media ($mobile) {
    max-width: 96%;
  }
}

.confirm-text {
  font-weight: 600;
}

.confirm-button-wrapper {
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;

  & > *:first-child {
    margin-right: 28px;
  }
}
</style>
