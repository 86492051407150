<template>
  <div
    v-if="tutStatus !== TUTORIAL_STATUS_TYPES.STARTED"
    class="toggle-wrapper"
  >
    <div
      :class="[
        'toggle-option',
        { selected: swapFilterType === SWAP_FILTER_TYPES.UNANSWERED }
      ]"
      @click="
        () =>
          setSwapType({
            swapFilterType: SWAP_FILTER_TYPES.UNANSWERED,
            update: true
          })
      "
    >
      <div
        v-if="preFilterCountUnmarked != undefined"
        class="toggle-option-badge"
      >
        {{ preFilterCountUnmarked }}
      </div>
      <div class="toggle-option-text">
        {{ $t('swap_list_toggle_all') }}
      </div>
    </div>

    <div
      :class="[
        'toggle-option',
        { selected: swapFilterType === SWAP_FILTER_TYPES.NEW }
      ]"
      @click="
        () =>
          setSwapType({ swapFilterType: SWAP_FILTER_TYPES.NEW, update: true })
      "
    >
      <div
        v-if="newSwapsCount != undefined"
        :class="['toggle-option-badge', { highlighted: newSwapsCount > 0 }]"
      >
        {{ newSwapsCount }}
      </div>
      <div class="toggle-option-text">
        {{ $t('swap_list_toggle_new') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SWAP_FILTER_TYPES } from '../../store/modules/swapList';
import { TUTORIAL_STATUS_TYPES } from '../../store/modules/tutorial';

export default {
  name: 'MobileSwapStatusFilter',

  data() {
    return {
      SWAP_FILTER_TYPES: SWAP_FILTER_TYPES,
      TUTORIAL_STATUS_TYPES
    };
  },

  computed: {
    ...mapGetters({
      swapFilterType: 'swapList/swapFilterType',
      preFilterCountTotal: 'swapList/preFilterCountTotal',
      fullInterestSwapsCount: 'swapList/fullInterestSwapsCount',
      preFilterCountUnmarked: 'swapList/preFilterCountUnmarked',
      newSwapsCount: 'swapList/newSwapsCount',
      tutStatus: 'tutorial/status'
    })
  },

  methods: {
    ...mapActions({
      setSwapType: 'swapList/setSwapType'
    })
  }
};
</script>

<style lang="scss" scoped>
.toggle-wrapper {
  position: relative;
  display: grid;
  gap: 10px;
  width: 100%;
  padding: 10px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.toggle-option {
  cursor: pointer;
  outline: none;
  font-weight: 700;
  padding-top: 9px;
  padding-bottom: 5px;
  border-bottom: 4px solid #d9d9d9;
  font-size: 10px;
  width: 100%;
  background-color: #fff;
  color: #2c3e50;
  text-align: center;
  height: 66px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
}

.toggle-option.selected {
  border-color: $transfer-blue;
}

.toggle-option-text {
  height: 24px;
  display: flex;
  align-items: center;
}

.toggle-option-badge {
  display: grid;
  place-items: center;
  min-width: 24px;
  height: 24px;
  padding: 0 9px;
  border-radius: 24px;
  margin-bottom: 3px;
  background-color: #e8e8e8;
  color: #2c3e50;
  font-size: 12px;
}

.toggle-option-badge.highlighted {
  background-color: $transfer-blue;
  color: #fff;
  box-shadow: 0px 0px 8px rgba(64, 159, 255, 0.33);
}
</style>
