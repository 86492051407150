<template>
  <div class="extra-visibility-wrapper">
    <div class="extra-visibility">
      <div class="icon-visibility" />

      <h2 class="title">
        {{ $t('extra_visibility_title') }}
        {{ proposition.residences[0].streetAddress }}
      </h2>
      <h3 class="subtitle">{{ $t('extra_visibility_subtitle') }}</h3>

      <div class="product-wrapper">
        <p class="info-text">
          {{ $t('extra_visibility_info_text') }}
        </p>

        <BasePayment
          v-if="!isFetchingProducts"
          :product="selectedProduct"
          :proposition-id="proposition.propositionId"
          :pay-text="$t('extra_visibility_confirm')"
          @loading="isLoading = $event"
          @success="handleSuccessfulPayment($event)"
        />
      </div>
    </div>

    <div class="extra-visibility-info-wrapper">
      <div class="extra-visibility-info">
        <h2>{{ $t('extra_visibility_extra_visibility_title_1') }}</h2>

        <p>
          {{ $t('extra_visibility_list_info') }}
        </p>

        <div class="divider" />

        <h2>{{ $t('extra_visibility_extra_visibility_title_2') }}</h2>

        <ul>
          <li>{{ $t('extra_visibility_list_2') }}</li>

          <li>{{ $t('extra_visibility_list_3') }}</li>
        </ul>
      </div>
    </div>

    <spinner v-if="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BasePayment from '@/components/Payments/BasePayment';

import Spinner from '@/components/Loading/Spinner';

export default {
  name: 'ExtraVisibility',

  components: {
    Spinner,
    BasePayment
  },

  data() {
    return {
      isLoading: false,
      isFetchingProducts: false
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      products: 'klarnaPayments/getProducts',
      selectedProduct: 'klarnaPayments/getSelectedProduct',
      propositions: 'myPropositions/propositions'
    }),

    proposition() {
      const current = this.propositions.find(
        p => p.propositionId == this.propositionId
      );

      if (!current) {
        window.location.replace(this.$t('path_my_propositions'));
      }

      return current;
    },

    propositionId() {
      return this.$route.params.propositionId.replace(/^\D+/g, '');
    }
  },

  beforeDestroy() {
    this.showMobileHeader(true);
  },

  created() {
    this.hideMobileTabBar();
    this.showMobileHeader(false);
    this.temporaryHideTrialBanner({ hide: true });
  },

  async mounted() {
    this.isLoading = true;

    this.isFetchingProducts = true;
    await this.fetchProducts({ productType: 'extraVisibility' });
    this.isFetchingProducts = false;

    // Don't stop load if payment result is working
    if (!this.$route.query.q) {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      fetchProducts: 'klarnaPayments/fetchProducts',
      triggerGTMProductPurchase: 'gtm/purchaseProduct',
      temporaryHideTrialBanner: 'trial/temporaryHideTrialBanner',
      hideMobileTabBar: 'menu/hideMobileTabBar',
      showMobileHeader: 'ui/showMobileHeader',
      paymentSuccess: 'payment/success'
    }),

    handleSuccessfulPayment({ propositionId, product }) {
      this.paymentSuccess({
        propositionId,
        product,
        notify: false,
        redirect: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.extra-visibility {
  width: 100%;
  padding: 0 20px;
  color: $text-primary;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;

  @media ($mobile) {
    padding: 20px;
  }
}

.icon-visibility {
  height: 90px;
  width: 90px;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/svg/tasklist.svg');
}

.title {
  text-align: center;
  font-size: 1.8rem;
  margin-block-end: 10px;

  @media ($mobile) {
    font-size: 1.2rem;
  }
}

.subtitle {
  text-align: center;
  margin-block-start: 0;
  font-size: 1rem;
  color: $text-secondary;
}

#klarna-container {
  width: 100%;
  max-width: 480px;
}

.button-wrapper {
  display: inline-block;
  margin: 20px auto;
}

.product-wrapper {
  font-family: inherit;
  font-weight: 600;
  width: 100%;
  max-width: 480px;
}

.choice {
  flex: 1;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px 20px;
  border: 2px solid $text-secondary;
  border-radius: 5px;
  background-color: white;
  outline: none;
  color: $text-primary;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 60px 0;
  }

  &-icon {
    height: 40px;
    width: 40px;
    background-size: contain;
    background-position: center;

    &-7 {
      background-image: url('../../assets/svg/tasklist_7.svg');
    }

    &-7.selected {
      background-image: url('../../assets/svg/tasklist_7_blue.svg');
    }

    &-14 {
      background-image: url('../../assets/svg/tasklist_14.svg');
    }

    &-14.selected {
      background-image: url('../../assets/svg/tasklist_14_blue.svg');
    }
  }

  &-title {
    font-weight: 600;
    font-size: 1.1rem;
    margin: 10px 0 5px 0;
  }

  &-price {
    color: $text-primary;
  }

  &-selected {
    border: 2px solid $main-blue;
    color: $main-blue;
    background-color: #f2fafd;
  }
}

.order-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: $accent-green;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  border: none;

  &-disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  @media ($mobile) {
    position: fixed;
  }
}

.info-text {
  display: block;
  margin-bottom: 24px;
  text-align: center;
  line-height: 150%;
  font-weight: 600;
  color: $text-secondary;
}

.agreement-checkbox-wrapper {
  padding-top: 10px;
  display: flex;
}

.terms-link {
  margin-top: 3.5px;
  margin-left: 3px;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 40px 0;
  background-color: #c9cbd6;
}

.extra-visibility-info {
  padding-bottom: 120px;
  color: $text-info;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;

  &-wrapper {
    padding: 40px;
    width: 100%;
    background-color: $info-gray;
  }

  & > h2 {
    font-weight: 700;
    font-size: 1.4rem;
  }

  & > p,
  & > ul > li {
    font-weight: 600;
  }
}
</style>
