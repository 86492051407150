<template>
  <div class="user-card-container block-pulse">
    <div class="info-area">
      <div class="user-img"></div>
      <div class="activity-indicator-area">
        <div class="activity-status block">blocktext</div>
      </div>
    </div>

    <div class="contact-area">
      <button class="contact-button block">blocktext</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserCardLoader'
};
</script>

<style lang="scss" scoped>
.user-card-container {
  position: relative;
  width: 90%;
  margin: 14px auto;
  background-color: #f1f1f1;
  border-radius: $small-box-radius;
  padding: 12px;

  @media ($desktop) {
    width: 100%;
  }
}

.info-area {
  display: flex;
  align-items: center;
}

.user-img {
  height: 50px;
  width: 50px;
  min-width: 40px;
  border-radius: 50%;
  background-color: #ddd;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
}

.user-name {
  font-size: 0.95rem;
  font-weight: 700;
  margin-bottom: 5px;
}

.activity-indicator-area {
  display: flex;
  flex-direction: column;
}

.activity-status {
  font-size: 0.8rem;
  font-weight: 600;
  color: $text-secondary;
}

.contact-area {
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-button {
  width: 100%;
  border: 1px solid $black;
  border-radius: $small-box-radius;
  background-color: rgba(0, 0, 0, 0);
  font-weight: 600;
  font-size: 1rem;
  font-family: inherit;
  padding: 14px;
  margin-bottom: 8px;
}

.contact-rating {
  font-size: 0.8rem;
  font-weight: 600;
  color: $text-secondary;
}
</style>
