<template>
  <div ref="wrapper" class="proposition-map-streetview-wrapper">
    <div v-if="showSwitcher" class="proposition-map-switcher">
      <a-button class="button--rounded" @click="openStreetViewWindow()">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.5999 0H6.40007C5.52023 0 4.79999 0.71952 4.79999 1.60008V12.8002C4.79999 13.6795 5.52023 14.4 6.40007 14.4H17.5999C18.4797 14.4 19.2 13.6795 19.2 12.8002V1.60008C19.2 0.71952 18.4797 0 17.5999 0ZM17.5999 12.8002H6.40007V1.60008H17.5999V12.8002ZM7.99988 10.35L8.84684 11.1998L14.7991 5.24853L14.7984 7.19997L16 7.20069L15.9984 4.00005C15.9991 3.55869 15.6415 3.20085 15.1992 3.20013H11.9992V4.40013L13.9492 4.40085L7.99988 10.35ZM7.99992 17.6001V15.9998H9.6V18.3998C9.6 18.8397 9.23976 19.2 8.80008 19.2H0.79992C0.36024 19.2 0 18.8397 0 18.3998V10.4001C0 9.95998 0.36024 9.59998 0.79992 9.59998H3.19992V11.1998H1.60008V17.6001H7.99992Z"
            fill="white"
          />
        </svg>
        <span class="mgl-10">{{ $t('map_and_streetview_streetview') }}</span>
      </a-button>
    </div>
    <div
      v-if="mapMode === 'static-map' && coords"
      ref="propositionMapEl"
      class="proposition-map-wrapper"
      @touchmove="initializeInteractiveMap($event)"
      @touchstart="initializeInteractiveMap($event)"
      @mousedown="initializeInteractiveMap($event)"
      @wheel="initializeInteractiveMap($event)"
    >
      <div
        class="static-map"
        :style="{
          width: '100%',
          height: '100%',
          background: `url(${mapUrl}) center center`,
          backgroundSize: 'cover'
        }"
      >
        <svg
          class="static-marker"
          display="block"
          width="27px"
          height="41px"
          viewBox="0 0 27 41"
        >
          <g fill-rule="nonzero">
            <g transform="translate(3.0, 29.0)" fill="#000000">
              <ellipse
                opacity="0.04"
                cx="10.5"
                cy="5.80029008"
                rx="10.5"
                ry="5.25002273"
              ></ellipse>
              <ellipse
                opacity="0.04"
                cx="10.5"
                cy="5.80029008"
                rx="10.5"
                ry="5.25002273"
              ></ellipse>
              <ellipse
                opacity="0.04"
                cx="10.5"
                cy="5.80029008"
                rx="9.5"
                ry="4.77275007"
              ></ellipse>
              <ellipse
                opacity="0.04"
                cx="10.5"
                cy="5.80029008"
                rx="8.5"
                ry="4.29549936"
              ></ellipse>
              <ellipse
                opacity="0.04"
                cx="10.5"
                cy="5.80029008"
                rx="7.5"
                ry="3.81822308"
              ></ellipse>
              <ellipse
                opacity="0.04"
                cx="10.5"
                cy="5.80029008"
                rx="6.5"
                ry="3.34094679"
              ></ellipse>
              <ellipse
                opacity="0.04"
                cx="10.5"
                cy="5.80029008"
                rx="5.5"
                ry="2.86367051"
              ></ellipse>
              <ellipse
                opacity="0.04"
                cx="10.5"
                cy="5.80029008"
                rx="4.5"
                ry="2.38636864"
              ></ellipse>
            </g>
            <g fill="#3FB1CE">
              <path
                d="M27,13.5 C27,19.074644 20.250001,27.000002 14.75,34.500002 C14.016665,35.500004 12.983335,35.500004 12.25,34.500002 C6.7499993,27.000002 0,19.222562 0,13.5 C0,6.0441559 6.0441559,0 13.5,0 C20.955844,0 27,6.0441559 27,13.5 Z"
              ></path>
            </g>
            <g opacity="0.25" fill="#000000">
              <path
                d="M13.5,0 C6.0441559,0 0,6.0441559 0,13.5 C0,19.222562 6.7499993,27 12.25,34.5 C13,35.522727 14.016664,35.500004 14.75,34.5 C20.250001,27 27,19.074644 27,13.5 C27,6.0441559 20.955844,0 13.5,0 Z M13.5,1 C20.415404,1 26,6.584596 26,13.5 C26,15.898657 24.495584,19.181431 22.220703,22.738281 C19.945823,26.295132 16.705119,30.142167 13.943359,33.908203 C13.743445,34.180814 13.612715,34.322738 13.5,34.441406 C13.387285,34.322738 13.256555,34.180814 13.056641,33.908203 C10.284481,30.127985 7.4148684,26.314159 5.015625,22.773438 C2.6163816,19.232715 1,15.953538 1,13.5 C1,6.584596 6.584596,1 13.5,1 Z"
              ></path>
            </g>
            <g transform="translate(6.0, 7.0)" fill="#FFFFFF"></g>
            <g transform="translate(8.0, 8.0)">
              <circle
                fill="#000000"
                opacity="0.25"
                cx="5.5"
                cy="5.5"
                r="5.4999962"
              ></circle>
              <circle fill="#FFFFFF" cx="5.5" cy="5.5" r="5.4999962"></circle>
            </g>
          </g>
        </svg>
      </div>
    </div>
    <div
      v-if="mapMode === 'map' && coords"
      ref="propositionMapEl"
      class="proposition-map-wrapper"
    >
      <proposition-map
        :coords="coords"
        :residence="residence"
        @on-init="mapInitialized($event)"
      />
    </div>
  </div>
</template>

<script>
import PropositionMap from './PropositionMap';
import AButton from '@/components/atomic/atoms/a-button';
import { lbStreetviewApi } from '../../utils/axiosConfig';
import { getStreetviewEndpoint } from '../../utils/getEndpoint';

export default {
  name: 'MapAndStreetView',

  components: {
    PropositionMap,
    AButton
  },
  props: {
    residence: {
      type: Object,
      required: true
    },
    coords: {
      type: [Object, Boolean],
      required: true
    }
  },

  data() {
    return {
      streetViewUrl: null,
      staticMapClickEvent: null,
      mapMode: 'static-map'
    };
  },

  computed: {
    showSwitcher() {
      const { residence } = this;

      if (
        residence.streetAddress &&
        residence.zipCode &&
        residence.streetAddress.replace(/[^0-9]/g, '') &&
        this.streetViewUrl
      )
        return true;

      return false;
    },
    mapUrl() {
      if (!this.coords) {
        return '';
      }
      return `${getStreetviewEndpoint()}/map/${this.coords.lng},${
        this.coords.lat
      },13,600,600`;
    }
  },

  created() {
    this.getStreetViewStatus();
  },

  methods: {
    mapInitialized(mapElement) {
      if (this.staticMapEvent) {
        mapElement
          .querySelector('.mapboxgl-canvas-container')
          .dispatchEvent(this.staticMapEvent);
      }
    },
    initializeInteractiveMap(ev) {
      this.mapMode = 'map';
      this.staticMapEvent = new ev.constructor(ev.type, ev);
    },
    async getStreetViewStatus() {
      try {
        if (
          !this.residence.streetAddress ||
          !this.residence.zipCode ||
          !this.residence.streetAddress.replace(/[^0-9]/g, '')
        ) {
          return false;
        }

        const result = await lbStreetviewApi.post('/streetview/status', {
          address: `${this.residence.streetAddress},${this.residence.zipCode}`,
          country: this.$country.currentCountry
        });

        if (result.data.available) {
          this.streetViewUrl = result.data.url;
        }
      } catch (error) {
        console.error('getStreetViewStatus:', error);
      }
    },
    openStreetViewWindow() {
      window.open(this.streetViewUrl, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
/*
IMPORTANT **********
These styles aren't
scoped due to mapbox implementation,
so rules will apply globally
*/

.proposition-map-streetview-wrapper {
  height: 270px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
  @media ($desktop) {
    border-radius: $small-box-radius;
    height: 100%;
  }
}

.proposition-map-wrapper {
  height: 270px;
  width: 100%;
  @media ($desktop) {
    height: 100%;
  }
}

.proposition-street-view-wrapper {
  height: 270px;
  width: 100%;
  @media ($desktop) {
    height: 100%;
  }
}

.proposition-map-switcher {
  z-index: 99;
  display: flex;
  position: absolute;
  top: 15px;
  justify-content: center;
  align-items: center;
}

.proposition-map-button {
  border: none;
  background-color: $transfer-blue;
  padding: 10px 10px;
  border-radius: 22px;
  margin: 0 5px;
  font-weight: 600;
  font-family: inherit;
  outline: none;
  transition: all 0.2s ease-in-out;
  font-size: 0.8rem;
  width: 90px;
  cursor: pointer;
  color: white;

  // &.active {
  //   background-color: $text-primary;
  //   color: #fff;
  // }
}

.mgl-10 {
  margin-left: 10px;
}

.static-marker {
  position: absolute;
  left: calc(50% - 13px);
  top: calc(50% - 35px);
}

.static-map {
  width: 100%;
  height: 100%;
}

.static-map:hover {
  cursor: grab;
}
</style>
