import { lbAws } from '../../../utils/axiosConfig';
import { addDataLayer } from '@/utils/gtm';
import { log } from '../../../utils/logger';

const trackingURL = '/tracking-commands/track';

async function changedSubscription({
  propositionId,
  currentProductId,
  productId,
  changeContext
}) {
  try {
    addDataLayer({
      category: 'subscription',
      action: 'change',
      propositionId: propositionId,
      from: currentProductId.toString(),
      to: productId.toString()
    });

    await lbAws.post(trackingURL, {
      schemaName: 'subscription-changed',
      schemaVersion: '1.0.0',
      payload: {
        propositionId,
        from: currentProductId.toString(),
        to: productId.toString(),
        context: changeContext
      }
    });
  } catch (error) {
    log(`changedSubscription: ${error}`);
  }
}

async function propositionVisited({
  userPropositionId,
  visitedPropositionId,
  device
}) {
  try {
    await lbAws.post(trackingURL, {
      schemaName: 'proposition-page-visited',
      schemaVersion: '1.0.0',
      payload: {
        visitorPropositionId: userPropositionId.toString(),
        visitedPropositionId: visitedPropositionId.toString(),
        device
      }
    });
  } catch (e) {
    log(`propositionVisited: ${e}`);
  }
}

async function clickedPropositionPageTrialCTA({
  variant,
  propositionId,
  device
}) {
  try {
    await lbAws.post(trackingURL, {
      schemaName: 'clicked-proposition-page-trial-cta',
      schemaVersion: '1.0.0',
      payload: {
        propositionId: propositionId.toString(),
        variant,
        device
      }
    });
  } catch (e) {
    log(`clickedPropositionPageTrialCTA: ${e}`);
  }
}

export default {
  changedSubscription,
  propositionVisited,
  clickedPropositionPageTrialCTA
};
