<template>
  <div class="wrapper">
    <div class="inner-wrapper">
      <div class="progress-wrapper">
        <svg class="progress-ring" width="80" height="80">
          <circle
            ref="circle"
            class="progress-ring__circle"
            stroke="rgba(256,256,256,.2)"
            stroke-width="8"
            fill="transparent"
            r="36"
            cx="40"
            cy="40"
          />
          <circle
            ref="circle"
            class="progress-ring__circle"
            stroke="white"
            stroke-width="8"
            fill="transparent"
            r="36"
            cx="40"
            cy="40"
            :stroke-dasharray="`${circumference} ${circumference}`"
            :stroke-dashoffset="`${offset}`"
          />
        </svg>
        <div class="progress">{{ displayNumber }}%</div>
      </div>

      <div class="text-content">
        <div class="title">{{ $t('onboarding_banner_title') }}</div>
        <div class="text" v-html="text" />
      </div>
    </div>

    <div class="button-wrapper">
      <primary-button
        :full-width="isMobile"
        :rounded="true"
        :invert-colors="true"
        @click.native="goToEditProposition"
      >
        {{ $t('onboarding_banner_finish') }}
      </primary-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PrimaryButton from '../Buttons/Primary';

export default {
  name: 'OnboardingBanner',

  components: {
    PrimaryButton
  },

  data() {
    return {
      radius: 36,
      circumference: 36 * 2 * Math.PI,
      hasLoaded: false,
      displayNumber: 0
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      onboardingState: 'myPropositions/onboardingState',
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    }),

    text() {
      const { onboardingState, primaryPropositionId } = this;

      let todos = [];

      if (!onboardingState.hasImages)
        todos.push({
          text: this.$t('onboarding_banner_add_pictures'),
          href: `${this.$t(
            'path_edit_proposition_photos',
            this.$routeLocale
          )}/${primaryPropositionId}/1`
        });
      if (!onboardingState.hasDescription)
        todos.push({
          text: this.$t('onboarding_banner_add_description'),
          href: `${this.$t(
            'path_edit_proposition_description',
            this.$routeLocale
          )}/${primaryPropositionId}/1`
        });
      if (this.$country.isCountry('se') && !onboardingState.hasLandlord)
        todos.push({
          text: this.$t('onboarding_banner_add_landlord'),
          href: `${this.$t(
            'path_edit_proposition_details',
            this.$routeLocale
          )}/${primaryPropositionId}/1`
        });

      return `${this.$t('onboarding_banner_you_need_to')} ${todos
        .map((t, index) => {
          return `<a style="color:rgb(204, 231, 252)" href="${t.href}">${
            t.text
          }</a href>${
            index === todos.length - 1
              ? ''
              : index === todos.length - 2
              ? this.$t('onboarding_banner_and')
              : ', '
          }`;
        })
        .join('')} ${this.$t('onboarding_banner_in_your_ad')}`;
    },

    offset() {
      const { completedPercent, circumference } = this;
      if (!completedPercent) return circumference;
      return circumference - (completedPercent / 100) * circumference;
    },

    completedPercent() {
      // wait for load to animate circle
      if (!this.hasLoaded || !this.onboardingState) return 0;

      const items = Object.keys(this.onboardingState);
      const count = items.length;
      const completedCount = items.filter(key => {
        const value = this.onboardingState[key];
        return value;
      }).length;
      const percent = Math.round((completedCount / count) * 100);

      return percent;
    }
  },

  watch: {
    completedPercent() {
      clearInterval(this.interval);

      if (this.completedPercent == this.displayNumber) {
        return;
      }

      this.interval = window.setInterval(() => {
        if (this.displayNumber != this.completedPercent) {
          var change = (this.completedPercent - this.displayNumber) / 10;

          change = change >= 0 ? Math.ceil(change) : Math.floor(change);

          this.displayNumber = this.displayNumber + change;
        }
      }, 40);
    }
  },

  mounted() {
    this.getMeasurements();
  },

  methods: {
    getMeasurements() {
      const { circle } = this.$refs;

      const r = circle.r.baseVal.value;
      const circumference = r * 2 * Math.PI;

      this.radius = r;
      this.circumference = circumference;

      setTimeout(() => {
        this.hasLoaded = true;
      }, 500);
    },

    goToEditProposition() {
      this.$router.push(
        `${this.$t('path_edit_proposition', this.$routeLocale)}/${
          this.primaryPropositionId
        }`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  border-radius: 24px;
  background-color: $transfer-blue;
  color: white;
  padding: 15px 30px;

  @media ($mobile) {
    flex-direction: column;
    padding: 20px;
  }
}

.inner-wrapper {
  display: flex;
  align-items: center;

  @media ($desktop) {
    max-width: 70%;
  }

  @media ($mobile) {
    align-items: flex-start;
    width: 100%;
  }
}

.progress-wrapper {
  margin-right: 24px;
  position: relative;

  @media ($mobile) {
    margin-right: 16px;
  }
}

.progress-ring__circle {
  transition: 0.7s stroke-dashoffset;
  // axis compensation
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.text-content {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 6px;

  @media ($mobile) {
    font-size: 1rem;
    margin-bottom: 12px;
  }
}

.text {
  font-weight: 600;
  color: rgb(194, 221, 252);
  line-height: 130%;

  ::v-deep a {
    text-decoration: underline;
  }
}

.button-wrapper {
  min-width: 206px;
  @media ($desktop) {
    margin-left: auto;
  }

  @media ($mobile) {
    margin-top: 12px;
    width: 100%;
  }
}
</style>
