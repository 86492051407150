import { render, staticRenderFns } from "./FreemiumBanner.vue?vue&type=template&id=51b7d0cd&scoped=true"
import script from "./FreemiumBanner.vue?vue&type=script&lang=js"
export * from "./FreemiumBanner.vue?vue&type=script&lang=js"
import style0 from "./FreemiumBanner.vue?vue&type=style&index=0&id=51b7d0cd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51b7d0cd",
  null
  
)

export default component.exports