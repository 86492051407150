<template>
  <div class="container">
    <div class="inner-container">
      <h1>{{ $t('swap_chain_title') }}</h1>
      <SwapDetailsWithChat
        :proposition-id-b-part="Number(params.propositionIdBPart)"
        :proposition-id-c-part="Number(params.propositionIdCPart)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SwapDetailsWithChat from '@/components/SwapDetails/SwapDetailsWithChat';

export default {
  components: {
    SwapDetailsWithChat
  },

  head: {
    title() {
      return {
        inner: this.$t('meta_title_swap_chain')
      };
    }
  },

  computed: {
    params() {
      return this.$route.params;
    }
  },

  beforeDestroy() {
    this.closeSwapDetails();
  },

  methods: {
    ...mapActions({
      closeSwapDetails: 'swapList/closeSwapDetails'
    })
  }
};
</script>

<style lang="scss" scoped>
.content {
  max-width: 1000px;
}

h1 {
  margin-left: 20px;
}
</style>
