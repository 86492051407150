<template>
  <div
    v-outside-click="close"
    class="accordion-wrapper"
    :class="{ 'hide-borders': hideBorders }"
    v-bind="$attrs"
  >
    <div
      class="accordion-title"
      :class="{ 'arrow-left': arrowLeft }"
      @click.stop.prevent="handleArrowClick($event)"
    >
      <div class="accordion-title-left">
        <span v-if="icon && !locked" class="accordion-icon-wrapper">
          <AImage :url="icon" :classes="['accordion-icon']"
        /></span>
        <span v-if="icon" class="accordion-title-separator"></span>
        <span v-if="title">{{ title }}</span>
        <slot name="title"></slot>
      </div>

      <div class="accordion-title-right">
        <span
          class="accordion-icon-wrapper"
          :class="{ 'arrow-reverse': arrowReverse }"
        >
          <AImage
            v-if="!locked && !hideArrow"
            :url="icons.GENERAL.ARROW_DOWN"
            :classes="['accordion-arrow-icon', { rotated: isActive }]"
        /></span>
      </div>
    </div>
    <transition
      mode="out-in"
      name="accordion-fade"
      @after-enter="$emit('transition-complete')"
      @after-leave="$emit('transition-complete')"
    >
      <div v-if="isActive" class="accordion-body">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import icons from '@/utils/icon-constants';
import AImage from './a-image';
import outsideClick from '@/directives/outside-click';

export default {
  name: 'AAccordion',

  components: { AImage },

  directives: { outsideClick },

  props: {
    activeOnInit: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: null
    },

    icon: { type: String, required: false, default: '' },

    hideBorders: {
      type: Boolean,
      default: false
    },

    closeOnOutsideClick: {
      type: Boolean,
      default: false
    },

    watchActive: {
      type: Boolean,
      default: false
    },

    arrowLeft: {
      type: Boolean,
      default: false
    },

    arrowReverse: {
      type: Boolean,
      default: false
    },

    locked: {
      type: Boolean,
      default: false
    },

    hideArrow: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      icons: icons,
      isActive: false
    };
  },

  watch: {
    activeOnInit(newVal, oldVal) {
      if (newVal !== oldVal && this.watchActive) {
        this.isActive = newVal;
        this.$emit('change', newVal);
      }
    }
  },

  created() {
    this.isActive = this.activeOnInit;
    if (this.activeOnInit) {
      this.$emit('change', true);
    }
  },

  methods: {
    handleArrowClick(e) {
      if (this.locked) {
        return;
      }

      this.isActive = !this.isActive;
      this.$emit('change', this.isActive);
      this.$emit('click', e);
    },
    close(e) {
      if (this.locked) {
        return;
      }

      this.$emit('outside', e);

      if (this.closeOnOutsideClick) {
        this.isActive = false;
        this.$emit('change', false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  border-radius: $standard-radius;
  padding: 0 15px;
}

.accordion-title {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  color: #2c3e50;
  font-weight: 600;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.accordion-title.arrow-left {
  justify-content: flex-start;
}

.accordion-body {
  padding: 15px 0px;
  overflow: hidden;
}

.accordion-title-separator {
  margin: 0 15px;
  height: 15px;
  border-left: 1px solid #e8e8e8;
}

.accordion-arrow-icon {
  display: inline-flex;
  height: 13px;
  width: 13px;
  background-position: center;
  background-size: contain;
  transition: transform 0.2s ease-in-out;
}

.accordion-icon {
  display: inline-flex;
  height: 18px;
  width: 18px;
  background-position: center;
  background-size: contain;
}

.accordion-icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.rotated {
  transform: rotate(-180deg);
  transition: transform 0.2s ease-in-out;
}

.accordion-title-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-fade-enter-active,
.accordion-fade-leave-active {
  transition: max-height 0.1s ease-out, padding 0.1s ease-out;
  max-height: 200px;
  padding: 15px 0;
}

.accordion-fade-enter,
.accordion-fade-leave-to {
  max-height: 0px;
  padding: 0;
}

.hide-borders {
  border: none !important;
  box-shadow: none !important;
  padding: 0;
}

.accordion-title-right {
  margin-left: 10px;
}

.arrow-reverse {
  transform: rotate(180deg);
}
</style>
