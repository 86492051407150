<template>
  <section>
    <div class="amenities-wrapper">
      <div v-if="commutes && commutes.length > 0" class="section">
        <div class="section-title">
          {{ $t('find_amenities_commutes') }}
        </div>

        <div v-for="(commute, index) in commutes" :key="index" class="amenity">
          <div :class="commute.type" class="amenity-image" />
          <div class="amenity-info">
            <div class="amenity-title">
              {{ getCommuteName(commute.type) }}
            </div>
            <div class="amenity-address">
              {{ commute.name }}
              ({{ commute.distance }} {{ $t('general_meter') }})
            </div>
          </div>
        </div>
      </div>

      <div v-if="restaurants" class="section">
        <div class="section-title">{{ $t('find_amenities_restaurants') }}</div>

        <div
          v-for="(restaurant, index) in restaurants"
          :key="index"
          class="amenity"
        >
          <div
            class="amenity-image restaurant"
            :style="[
              restaurant.streetViewImage
                ? {
                    backgroundImage: `url(${restaurant.streetViewImage})`,
                    opacity: 1,
                    backgroundSize: 'cover'
                  }
                : ''
            ]"
          />
          <div class="amenity-info">
            <div class="amenity-title">
              {{ restaurant.displayName }}
            </div>
            <div class="amenity-address">
              {{ restaurant.address }}
              <span v-if="restaurant.distance"
                >({{ restaurant.distance }} {{ $t('general_meter') }})</span
              >
            </div>
          </div>
        </div>
      </div>

      <div v-if="schools" class="section">
        <div class="section-title">{{ $t('find_amenities_schools') }}</div>

        <div v-for="(school, index) in schools" :key="index" class="amenity">
          <div
            class="amenity-image school"
            :style="[
              school.streetViewImage
                ? {
                    backgroundImage: `url(${school.streetViewImage})`,
                    opacity: 1,
                    backgroundSize: 'cover'
                  }
                : ''
            ]"
          />
          <div class="amenity-info">
            <div class="amenity-title">
              {{ school.displayName }}
            </div>
            <div class="amenity-address">
              {{ school.address }}
              <span v-if="school.distance"
                >({{ school.distance }} {{ $t('general_meter') }})</span
              >
            </div>
          </div>
        </div>
      </div>

      <div v-if="stores" class="section">
        <div class="section-title">{{ $t('find_amenities_stores') }}</div>

        <div v-for="(store, index) in stores" :key="index" class="amenity">
          <div
            class="amenity-image store"
            :style="[
              store.streetViewImage
                ? {
                    backgroundImage: `url(${store.streetViewImage})`,
                    opacity: 1,
                    backgroundSize: 'cover'
                  }
                : ''
            ]"
          />
          <div class="amenity-info">
            <div class="amenity-title">
              {{ store.displayName }}
            </div>
            <div class="amenity-address">
              {{ store.address }}
              <span v-if="store.distance"
                >({{ store.distance }} {{ $t('general_meter') }})</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { lbStreetviewApi } from '../../utils/axiosConfig';
export default {
  name: 'HittaAmenities',

  props: {
    propositionId: {
      type: [String, Number],
      required: true
    },

    residenceIndex: {
      type: [String, Number],
      default: 0
    },

    coordinates: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      amenities: null
    };
  },

  computed: {
    restaurants() {
      if (!this.amenities || !this.amenities.restaurants) return;
      return this.amenities.restaurants.slice(0, 2);
    },
    schools() {
      if (!this.amenities || !this.amenities.education) return;

      return this.amenities.education.slice(0, 2);
    },
    stores() {
      if (!this.amenities || !this.amenities.groceryStores) return;

      return this.amenities.groceryStores.slice(0, 2);
    },
    commutes() {
      if (!this.amenities || !this.amenities.commutes) return;

      return this.amenities.commutes.slice(0, 2);
    }
  },

  watch: {
    residenceIndex(oldVal, newVal) {
      if (oldVal == newVal) {
        return;
      }

      this.getAmenities();
    }
  },

  created() {
    this.getAmenities();
  },

  methods: {
    async getAmenities() {
      try {
        const { data } = await lbStreetviewApi.get('/amenities', {
          params: { ...this.coordinates, country: this.$country.getCountry() }
        });

        this.amenities = data;
      } catch (error) {
        // Do nothing
      }
    },

    getCommuteName(commuteType) {
      switch (commuteType) {
        case 'bus':
          return this.$t('find_amenities_commute_type_bus');
        case 'rail':
          return this.$t('find_amenities_commute_type_rail');
        case 'tram':
          return this.$t('find_amenities_commute_type_tram');
        case 'ferry':
          return this.$t('find_amenities_commute_type_ferry');
        case 'water':
          return this.$t('find_amenities_commute_type_ferry');
        case 'metro':
          return this.$t('find_amenities_commute_type_metro');
        default:
          return this.$t('find_amenities_commute_type_other');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.amenities-wrapper {
  @media ($desktop) {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  @media ($mobile) {
    margin-top: 20px;
  }
}

.section {
  margin-bottom: 20px;

  &-title {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 20px;
  }

  @media ($desktop) {
    width: 100%;
    max-width: 50%;
    padding-right: 30px;

    &:last-of-type {
      padding-right: 0px;
    }
  }
}

.amenity {
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
  padding-bottom: 20px;

  @media ($mobile) {
    padding-bottom: 0px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(28, 45, 65, 0.103);
    @media ($mobile) {
      border-bottom: none;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
  }

  &-image {
    height: 40px;
    width: 40px;
    min-width: 40px;
    margin-right: 10px;
    background-color: #f8f8fa;
    background-size: 50%;
    border-radius: 3px;
    margin-bottom: 10px;
    background-position: center;

    &.store {
      background-image: url('../../assets/svg/amenitiesIcons/store.svg');
    }

    &.restaurant {
      background-image: url('../../assets/svg/amenitiesIcons/food.svg');
    }

    &.school {
      background-image: url('../../assets/svg/amenitiesIcons/school.svg');
    }

    &.bus {
      background-image: url('../../assets/svg/amenitiesIcons/bus.svg');
    }

    &.metro {
      background-image: url('../../assets/svg/amenitiesIcons/metro.svg');
    }

    &.rail {
      background-image: url('../../assets/svg/amenitiesIcons/rail.svg');
    }

    &.ferry {
      background-image: url('../../assets/svg/amenitiesIcons/ferry.svg');
    }

    &.water {
      background-image: url('../../assets/svg/amenitiesIcons/ferry.svg');
    }

    &.tram {
      background-image: url('../../assets/svg/amenitiesIcons/tram.svg');
    }

    &.other {
      background-image: url('../../assets/svg/icon-map-pin-alt.svg');
    }
  }

  &-title {
    font-weight: 600;
    font-size: 0.85rem;
    margin-bottom: 5px;
    line-height: 150%;
  }

  &-address {
    font-weight: 600;
    color: $text-secondary;
    font-size: 0.8rem;
  }
}
</style>
