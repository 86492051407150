import i18n, { routeLocale } from '@/i18n';
const instance_id = Math.floor(Math.random() * 1000000).toString();

function loadKlarnaWidget(clientToken) {
  return new Promise(resolve => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    window.Klarna.Payments.init({
      client_token: clientToken
    });

    // @ts-ignore
    // eslint-disable-next-line no-undef
    window.Klarna.Payments.load(
      {
        container: '#klarna-container',
        payment_method_categories: ['pay_now', 'pay_later'],
        instance_id
      },
      {
        locale: i18n.t('locale_language', routeLocale)
      },
      result => {
        resolve(result.show_form);
      }
    );
  });
}

function authorizeKlarnaPayment(payload) {
  return new Promise(resolve => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    window.Klarna.Payments.authorize(
      {
        payment_method_category: 'pay_now',
        instance_id
      },
      payload,
      result => {
        window.Logger.log('Klarna auth response: ', result);
        resolve(result);
      }
    );
  });
}

export { loadKlarnaWidget, authorizeKlarnaPayment };
