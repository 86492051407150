import i18n from '@/i18n';
import routeConstants from '@/router/routes.constants';
import { router } from '@/main';
import { subscribe } from '@/utils/socket';
import { paymentApi } from '@/utils/axiosConfig';
import { log } from '../../../utils/logger';
import { isFeatureEnabled } from '../../../plugins/country/module';

async function init({ dispatch }) {
  dispatch('getSetHasPaymentToken');

  subscribe('new-payment', async ({ product, propositionId }) => {
    log('new-payment event');
    dispatch('handleSubscription/refreshStates', propositionId, {
      root: true
    });
    dispatch('track', { product, propositionId });
    dispatch('getSetHasPaymentToken');
  });

  subscribe('added-payment-order-pending', () => {
    dispatch('getSetHasPaymentToken');
  });
}

async function success(
  { dispatch },
  { propositionId, product, redirect, notify }
) {
  if (notify) {
    dispatch('notify', { product });
  }

  if (!redirect) {
    return;
  }

  dispatch('handleSubscription/refreshStates', propositionId, {
    root: true
  });

  if (product.category === 'annonsforlangning') {
    dispatch('redirect', routeConstants.PAYMENT_DONE_PROPOSITION);
  } else if (product.category === 'extrasynlighet') {
    dispatch('redirect', routeConstants.PAYMENT_DONE_VISIBILITY);
  }
}

async function getSetHasPaymentToken({ commit }) {
  try {
    if (!isFeatureEnabled('PAYMENT')) {
      return;
    }

    const { data } = await paymentApi.get(`/has-payment-token`);
    commit('setHasPaymentToken', data);
  } catch (error) {
    console.error(error);
  }
}

async function getPaymentTokenProvider() {
  try {
    const { data } = await paymentApi.get('/payment-token-provider');
    return data;
  } catch (error) {
    this.handleError(error);
  }

  return null;
}

async function getSetActiveOrders({ commit }) {
  try {
    const { data } = await paymentApi.get(`/status/active`);
    commit('setActiveOrders', data);
  } catch (error) {
    console.error(error);
  }
}

async function notify({ dispatch }, { product }) {
  const text = product.name.replace(/\(.*\)/, '').trim();

  dispatch(
    'toast/setToast',
    {
      title: i18n.t('payment_success', {
        productText: `"${text}"`
      }),
      timer: 10
    },
    { root: true }
  );
}

async function track({ dispatch }, { product, propositionId }) {
  const type = await dispatch('klarnaPayments/getGtmPurchaseType', null, {
    root: true
  });

  const trackingProductTypes = {
    annonsforlangning: 'proposition',
    extrasynlighet: 'visibility'
  };

  dispatch(
    'gtm/purchaseProduct',
    {
      product: product,
      action: type,
      what: trackingProductTypes[product.category],
      propositionId: propositionId
    },
    {
      root: true
    }
  );
}

async function redirect({ rootGetters }, url) {
  if (rootGetters['app/isAppRequest']) {
    location.href = url;
    return;
  }

  router.replace(url);
}

export default {
  success,
  redirect,
  notify,
  track,
  init,
  getSetHasPaymentToken,
  getSetActiveOrders,
  getPaymentTokenProvider
};
