import { REPORT_PROPOSITION_PATHWAYS } from './index';

function setCurrentScreen(state, value) {
  state.currentScreen = value;
}

function setPreviousScreen(state, value) {
  state.previousScreen = value;
}

function setOptionSelected(state, { option, value }) {
  state.OPTION[option].selected = value;
}

function setAllowContact(state, value) {
  state.allowContact = value;
}

function setBlockProposition(state, value) {
  state.blockProposition = value;
}

function setFormFilled(state, value) {
  state.formFilled = value;
}

function setTextareaReportDescription(state, value) {
  state.textareaReportDescription = value;
}

function resetSelected(state) {
  state.OPTION[REPORT_PROPOSITION_PATHWAYS.INITIAL_1].selected = null;
  state.OPTION[REPORT_PROPOSITION_PATHWAYS.OTHER_1].selected = null;
  state.OPTION[REPORT_PROPOSITION_PATHWAYS.IMAGES_1].selected = null;
  state.OPTION[REPORT_PROPOSITION_PATHWAYS.FAULTY_1].selected = null;
}

export default {
  setCurrentScreen,
  setPreviousScreen,
  setOptionSelected,
  setAllowContact,
  setBlockProposition,
  setFormFilled,
  setTextareaReportDescription,
  resetSelected
};
