<template>
  <div class="buttons-wrapper">
    <div
      :class="['interest-button', { interested: interested === true }]"
      @click="$emit('interested', true)"
    >
      {{ $t('interest_button_yes') }}
    </div>
    <div
      :class="['interest-button', { 'not-interested': interested === false }]"
      @click="$emit('interested', false)"
    >
      {{ $t('interest_button_no') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    interested: {
      type: [Boolean, null],
      default: null
    },
    targetPropositionId: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons-wrapper {
  display: flex;
}

.interest-button {
  cursor: pointer;
  width: 45px;
  padding: 4px 0;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 3px;
  margin-left: 4px;
  border: 1px solid $transfer-blue;
  color: $transfer-blue;
  text-align: center;
}

.interested {
  background-color: $transfer-green;
  border: 1px solid $transfer-green;
  color: white;
}

.not-interested {
  background-color: $sweet-red;
  border: 1px solid $sweet-red;

  color: white;
}
</style>
