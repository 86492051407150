<template>
  <div class="page-container">
    <section class="login-wrapper">
      <div v-if="isResetPasswordVisible" class="content-wrapper">
        <div class="content-header">
          <h2 class="content-title">
            {{ $t('login_reset_new_password_title') }}
          </h2>
        </div>
        <template v-if="!resetPasswordSuccess">
          <form class="form-wrapper" @submit.stop.prevent="resetPassword()">
            <BaseInput
              v-model="password"
              name="resetPasswordFirst"
              type="password"
              icon="lock"
              :has-show-password="true"
              :disabled="isLoading"
              :label="$t('login_form_password')"
              :placeholder="$t('login_form_password')"
            />

            <BaseInput
              v-model="passwordRepeat"
              name="resetPasswordSecond"
              type="password"
              icon="lock"
              :has-show-password="true"
              :disabled="isLoading"
              :label="$t('login_form_password_repeat')"
              :placeholder="$t('login_form_password_repeat')"
            />

            <input type="submit" hidden />

            <p class="message">{{ errorMessage }}</p>

            <div class="u-center-items">
              <BaseButton
                type="submit"
                :disabled="isLoading || !password || !passwordRepeat"
                :is-loading="isLoading"
                class="action-button"
                >{{ $t('login_reset_action') }}</BaseButton
              >
            </div>
          </form>
        </template>
        <template v-else>
          <p>
            {{ $t('login_reset_new_password_success') }}
          </p>
        </template>
        <p class="secondary-action">
          <router-link
            class="simple-button"
            :to="$t('path_login', $routeLocale)"
            @click="
              isResetPasswordEmailVisible = false;
              successVisible = false;
              resetPasswordEmailSent = false;
            "
          >
            {{ $t('login_switch_to_login_action') }}
          </router-link>
        </p>
      </div>

      <div v-else-if="isResetPasswordEmailVisible" class="content-wrapper">
        <div class="content-header">
          <h2 class="content-title">{{ $t('login_reset_title') }}</h2>
          <p v-if="!resetPasswordEmailSent">
            {{ $t('login_reset_info') }}
          </p>
        </div>
        <template v-if="!resetPasswordEmailSent">
          <form class="form-wrapper" @submit.stop.prevent="sendResetLink()">
            <BaseInput
              id="resetEmail"
              v-model="username"
              name="resetEmail"
              type="email"
              icon="envelope"
              :disabled="isLoading"
              :label="$t('login_form_username')"
              :placeholder="$t('login_form_username')"
              autofocus
            />

            <input type="submit" hidden />

            <p class="message">{{ errorMessage }}</p>

            <div class="u-center-items">
              <BaseButton
                type="submit"
                :disabled="isLoading || !username"
                :is-loading="isLoading"
                class="action-button"
                >{{ $t('login_reset_action') }}</BaseButton
              >
            </div>
          </form>
        </template>
        <template v-else>
          <div class="body-text">
            <p>
              {{ $t('login_reset_success_info') }}
            </p>
            <p>
              {{ $t('login_reset_success_note') }}
            </p>
          </div>
        </template>
        <div class="u-center-items">
          <p class="secondary-action">
            {{ $t('login_go_back_to') }}
            <button
              class="simple-button"
              @click="
                isResetPasswordEmailVisible = false;
                errorMessage = '';
                resetPasswordEmailSent = false;
                routeToLogin();
              "
            >
              {{ $t('login_switch_to_login_action') }}
            </button>
          </p>
        </div>
      </div>

      <div
        v-else-if="$country.isFeatureEnabled('BANKID') && isBankIdVisible"
        class="bankid-form"
      >
        <BankId
          :collect-path="'/auth/bankid'"
          :auth-path="'/bankid/start'"
          :qr-path="'/bankid/qr'"
          :cancel-path="'/bankid/cancel'"
          :base-url="baseUrl"
          class="non-modal"
          @success="handleLoginSuccess($event)"
          @animatingSuccess="animatingSuccess = true"
        ></BankId>
        <div v-if="!animatingSuccess" class="u-center-items">
          <p class="secondary-action">
            {{ $t('login_go_back_to') }}
            <button
              v-if="$country.isFeatureEnabled('BANKID')"
              class="simple-button"
              @click="
                isBankIdVisible = !isBankIdVisible;
                errorMessage = '';
              "
            >
              {{ $t('login_switch_to_login_password_action') }}
            </button>
          </p>
        </div>
      </div>

      <div
        v-else-if="!isBankIdVisible || !$country.isFeatureEnabled('BANKID')"
        class="content-wrapper"
      >
        <div class="content-header">
          <h2 class="content-title">{{ $t('login_title') }}</h2>
          <p>{{ $t('login_subtitle') }}</p>
        </div>

        <div class="button-wrapper">
          <div class="button-container">
            <FacebookLoginButton
              :on-begin-authorization="handleBeginAuthorization"
              :on-authorization-failed="handleSSOLoginError"
              :on-authorization-successful="handleLoginSuccess"
            ></FacebookLoginButton>

            <GoogleLoginButton
              :on-begin-authorization="handleBeginAuthorization"
              :on-authorization-failed="handleSSOLoginError"
              :on-authorization-successful="handleLoginSuccess"
            ></GoogleLoginButton>

            <AppleLoginButton
              :on-begin-authorization="handleBeginAuthorization"
              :on-authorization-failed="handleSSOLoginError"
              :on-authorization-successful="handleLoginSuccess"
            ></AppleLoginButton>
          </div>
        </div>

        <div class="or-delimiter">
          <span>{{ $t('login_or_delimiter') }}</span>
        </div>

        <form class="form-wrapper bankid-form" @submit.stop.prevent="login()">
          <div class="input-container">
            <BaseInput
              v-model="username"
              name="loginEmailInput"
              type="email"
              icon="envelope"
              :disabled="isLoading"
              :has-show-password="false"
              :label="$t('login_form_username')"
              :placeholder="$t('login_form_username')"
            />
          </div>
          <div class="input-container">
            <div
              tabindex="-1"
              class="simple-button forgot-pw"
              @click="
                isResetPasswordEmailVisible = true;
                errorMessage = '';
                routeToPasswordReset();
              "
            >
              {{ $t('login_switch_to_reset_action') }}
            </div>

            <BaseInput
              v-model="password"
              name="passwordLogin"
              type="password"
              icon="lock"
              :has-show-password="true"
              :disabled="isLoading"
              :label="$t('login_form_password')"
              :placeholder="$t('login_form_password')"
            />
          </div>
          <input type="submit" hidden />

          <p class="message">{{ errorMessage }}</p>

          <div class="u-center-items">
            <BaseButton
              type="submit"
              :disabled="isLoading"
              :is-loading="isLoading"
              class="action-button"
              >{{ $t('login_password_action') }}</BaseButton
            >
          </div>
        </form>

        <div class="u-center-items">
          <p
            v-if="$country.isFeatureEnabled('BANKID')"
            class="secondary-action"
          >
            Har du verifierat kontot?
            <button
              class="simple-button"
              @click="
                isBankIdVisible = !isBankIdVisible;
                errorMessage = '';
              "
            >
              {{ $t('login_switch_to_login_bankid_action') }}
            </button>
          </p>

          <p class="secondary-action">
            {{ $t('login_no_account') }}
            <a
              :href="$t('path_add_proposition', $routeLocale)"
              class="simple-button"
              >{{ $t('login_register_here') }}</a
            >
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BankId from '@/components/BankId/BankId';
import FacebookLoginButton from '@/components/SSOButtons/FacebookLoginButton';
import GoogleLoginButton from '@/components/SSOButtons/GoogleLoginButton';
import AppleLoginButton from '@/components/SSOButtons/AppleLoginButton';
import { getAuthenticationEndpoint } from '@/utils/getEndpoint';
import { mapActions, mapGetters } from 'vuex';
import { authenticationApi } from '@/utils/axiosConfig';
import { log } from '@/utils/logger';

export default {
  components: {
    BankId,
    FacebookLoginButton,
    GoogleLoginButton,
    AppleLoginButton
  },

  data() {
    return {
      username: '',
      password: '',
      passwordRepeat: '',
      isBankIdVisible: false,
      baseUrl: getAuthenticationEndpoint(),
      errorMessage: '',
      isLoading: false,
      isResetPasswordEmailVisible: false,
      resetPasswordEmailSent: false,
      resetPasswordSuccess: false,
      animatingSuccess: false
    };
  },

  head: {
    title() {
      return {
        inner: this.passwordResetToken
          ? this.$t('login_reset_new_password_title')
          : this.$t('login_page_meta_title')
      };
    }
  },

  computed: {
    ...mapGetters({
      appReady: 'app/ready',
      user: 'app/user',
      isMobile: 'screenSize/isMobile',
      isAuthenticated: 'app/isAuthenticated',
      primaryPropositionActive: 'myPropositions/primaryPropositionActive'
    }),

    passwordResetToken() {
      return this.$route.query.api_reset_token;
    },

    isResetPasswordVisible() {
      return !!this.passwordResetToken;
    }
  },

  async created() {
    if (this.$route.path.includes(this.$t('path_reset_password'))) {
      this.isResetPasswordEmailVisible = true;
    }

    await this.waitForAppReady();
    if (this.isAuthenticated && !this.passwordResetToken) {
      this.reRoute();
    }
  },

  mounted() {
    try {
      const el = document.getElementById('emailInput');
      el.focus();
    } catch {
      //Fail
    }
  },

  methods: {
    routeToPasswordReset() {
      this.$router.replace({ path: this.$routes.LOGIN_RESET_PASSWORD });
    },

    routeToLogin() {
      this.$router.replace(this.$routes.LOGIN);
    },

    ...mapActions({
      initAppData: 'app/initAppData',
      setToast: 'toast/setToast'
    }),

    async waitForAppReady() {
      while (!this.appReady) {
        await new Promise(resolve => setTimeout(resolve, 50));
      }
    },

    handleBeginAuthorization() {
      this.errorMessage = '';
      this.isLoading = true;
    },

    async login() {
      try {
        this.handleBeginAuthorization();
        const result = await authenticationApi.post('auth', {
          username: this.username,
          password: this.password
        });

        this.handleLoginSuccess(result);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Handle wrong username/password errors here
          this.handleLoginError();
        } else {
          // Handle general errors here
          this.handleGeneralError();
          this.handleLoginError(true);
        }
      }
    },

    handleGeneralError() {
      this.setToast({
        timer: 10,
        title: this.$t('toast_general_error_message')
      });
    },

    async sendResetLink() {
      try {
        this.errorMessage = '';
        this.isLoading = true;
        this.resetPasswordEmailSent = false;

        await authenticationApi.post('auth/reset-password', {
          username: this.username
        });

        this.resetPasswordEmailSent = true;
      } catch (error) {
        this.errorMessage = this.$t('login_reset_error');
      }

      this.isLoading = false;
    },

    async resetPassword() {
      try {
        this.errorMessage = '';
        this.isLoading = true;
        this.resetPasswordSuccess = false;

        if (this.password !== this.passwordRepeat) {
          this.errorMessage = this.$t('login_reset_new_password_diff_error');
          this.isLoading = false;
          return;
        }

        await authenticationApi.post(
          `auth/reset-password/${this.passwordResetToken}`,
          {
            password: this.password
          }
        );

        this.passwordRepeat = '';
        this.password = '';
        this.resetPasswordSuccess = true;
      } catch (error) {
        this.errorMessage = this.$t('login_reset_new_password_error');
      }

      this.isLoading = false;
    },

    handleSSOLoginError(response) {
      switch (response.type) {
        case 'ACCOUNT_EXISTS':
          this.errorMessage = this.$t('login_wrong_login_method_message', {
            provider: this.$t(
              `login_method_name_${response.provider.toLowerCase()}`
            )
          });
          break;
        case 'UNAUTHORIZED':
          this.errorMessage = this.$t('login_wrong_creds_message');
          break;
        default:
          this.errorMessage = this.$t('login_error_message');
          break;
      }
      this.isLoading = false;
    },

    handleLoginError(hideLoginError) {
      this.password = '';

      if (!hideLoginError) {
        this.errorMessage = this.$t('login_wrong_creds_message');
      }

      this.isLoading = false;

      try {
        const el = document.getElementById('passwordLogin');
        this.$nextTick(() => {
          el.focus();
        });
      } catch {
        // Fail
      }
    },

    async handleLoginSuccess() {
      await this.initAppData();

      if (this.isAuthenticated) {
        this.reRoute();

        this.username = '';
        this.password = '';
        this.animatingSuccess = false;
      } else {
        this.handleLoginError(true);
        log(
          `Login was successful, but isAuthenticated is still false. Bankid: ${this.isBankIdVisible}, Email: ${this.username}`,
          true
        );
      }
    },

    reRoute() {
      const returnUrl = this.$route.query.returnUrl;
      if (returnUrl) {
        this.$router.replace(returnUrl);
      } else if (this.primaryPropositionActive) {
        this.$router.replace(
          this.$t('path_swap_suggestions', this.$routeLocale)
        );
      } else {
        this.$router.replace(
          this.$t('path_my_propositions', this.$routeLocale)
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  padding-bottom: 0;
  margin: 0;
  max-width: none;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: calc(100vh - 60px - env(safe-area-inset-bottom));
  background-image: url('@/assets/img/login-bg.jpg');
  background-position: top;
  background-size: contain;

  @media ($desktop) {
    height: 100vh;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-image: url('@/assets/img/login-bg-desktop.png');
  }
}

.or-delimiter {
  position: relative;
  display: block;
  text-align: center;
  border-top: 1px solid #ccc;
  margin: 40px 0 20px 0;
}

.or-delimiter span {
  position: absolute;
  top: -10px;
  background: white;
  width: 50px;
  left: calc(50% - 25px);
}

.login-wrapper {
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  padding: 28px;
  min-height: 76vh;
  background-color: #fff;

  @media ($desktop) {
    max-width: 375px;
    border-radius: 15px;
    padding: 28px 35px;
    min-height: 50vh;
    margin-top: -80px;
  }
}

.form-wrapper {
  width: 100%;

  @media ($mobile) {
    width: unset;
  }
}

.secondary-action {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.simple-button {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: $main-blue !important;
  font-weight: 600;
  padding: 0px 3px;
}

.simple-button:visited {
  color: inherit;
}

.message {
  min-height: 15px;
  margin-top: -15px;
  color: $sweet-red;
  font-size: 12px;
  text-align: center;
}

.content-title {
  margin-top: 0;
  margin-bottom: 12px;
  text-align: center;
}

.content-header p {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 14px;
  text-align: center;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    @media ($mobile) {
      width: 70%;
      max-width: 70%;
    }
  }
}

.input-container {
  position: relative;
}

.forgot-pw {
  position: absolute;
  right: 0;
  font-size: 14px;
  z-index: 2;
}

.action-button {
  margin-top: 5px;
  margin-bottom: 30px;
}

.bankid-form {
  width: 100%;
}

.body-text {
  font-size: 14px;
  margin-bottom: 40px;
}
</style>
