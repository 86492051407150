<template>
  <div class="boost-summary">
    <BoostInfoContent />
  </div>
</template>

<script>
import BoostInfoContent from '../../components/Modals/BoostInfoContent.vue';

export default {
  name: 'BoostSummary',

  components: {
    BoostInfoContent
  },

  head: {
    title() {
      return {
        inner: 'Sammanfattning av din Boost'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.boost-summary {
  max-width: none;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  padding-top: 20px;

  @media ($desktop) {
    padding-top: 110px;
    padding-bottom: 0;
  }
}
</style>
