<template>
  <div
    :class="{ 'choice-wrapper': button, 'is-active': active && button }"
    @click="$emit('change', value)"
  >
    <div :class="{ 'choice-wrapper-content': button }">
      <div class="radio-wrapper" :class="classes">
        <input
          :id="id"
          :name="name"
          :checked="active"
          :value="value"
          type="radio"
          class="radio"
          :class="{ large: classes && classes.includes('large') }"
          @change="$emit('change', $event.target.value)"
        />
        <label :for="id" class="text-unselectable">
          <slot />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { randomUuid } from '@/utils/helpers/string';

export default {
  name: 'ARadioButton',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    active: {
      type: Boolean,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    classes: {
      type: Array,
      default: () => [],
      required: false
    },
    styles: {
      type: Array,
      default: () => [],
      required: false
    },
    button: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      id: randomUuid()
    };
  }
};
</script>

<style lang="scss" scoped>
.choice-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 25px;
  border: 1px solid #d6dae9;
  background-color: $form-background-light;
}

.choice-wrapper-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 15px 0 15px;
}

.is-active {
  background-color: $form-background-active;
  border: 1px solid $transfer-blue;
}

.radio-wrapper {
  display: flex;
  font-size: 14px;
  line-height: 22.59px;
}

//large
.radio-wrapper.large {
  margin-top: 15px;
}

.radio {
  &:checked,
  &:not(:checked) {
    left: -9999px;
    position: absolute;
  }

  &:checked + label,
  &:not(:checked) + label {
    color: #2c3e50;
    cursor: pointer;
    display: inline-block;
    line-height: 14px;
    padding-left: 23px;
    position: relative;
  }

  &:checked + label::before,
  &:not(:checked) + label::before {
    background: #fff;
    border-radius: 50%;
    border: 2px solid $transfer-blue;
    content: '';
    width: 14px;
    height: 14px;
    left: 0;
    position: absolute;
    top: 0;
  }

  &:checked + label::after,
  &:not(:checked) + label::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    left: 1px;
    top: 1px;
    border-radius: 50%;
    transition: all 0.2s ease;
  }

  &:not(:checked) + label::after {
    opacity: 0;
    transform: scale(0);
  }

  &:checked + label::after {
    opacity: 1;
    transform: scale(1);
    left: 4px;
    top: 4px;
    border-radius: 50%;
    background-color: $transfer-blue;
    background-position: 50%;
    background-size: contain;
    transition: all 0.2s ease-in-out;
    backface-visibility: hidden;
  }
}

//large
.radio.large {
  &:checked + label,
  &:not(:checked) + label {
    padding-left: 45px;
  }

  &:checked + label::before,
  &:not(:checked) + label::before {
    width: 25px;
    height: 25px;
    left: 1px;
    top: 6px;
  }

  &:checked + label::after,
  &:not(:checked) + label::after {
    width: 15px;
    height: 15px;
  }

  &:checked + label::after {
    left: 6px;
    top: 11px;
  }
}
</style>
