const matchesInActiveTab = state => state.matches[state.activeTab];
const isLoadingMatches = state => state.isLoadingMatches;
const isLoadingStatusCounts = state => state.isLoadingStatusCounts;
const isLoadingMatchesPage = state =>
  (state.isLoadingMatches || state.isLoadingStatusCounts) ?? true;
const activeTab = state => state.activeTab;
const initialMatchStatusCounts = state => state.initialMatchStatusCounts;
const matchStatusCounts = state => state.matchStatusCounts;
const newMessagesAndUpdatedCount = state =>
  state.initialMatchStatusCounts?.unread +
  state.initialMatchStatusCounts?.updated;

export default {
  matchesInActiveTab,
  isLoadingMatches,
  isLoadingStatusCounts,
  isLoadingMatchesPage,
  activeTab,
  initialMatchStatusCounts,
  matchStatusCounts,
  newMessagesAndUpdatedCount
};
