<template>
  <section v-if="data && data.rooms.length && !isLoading" class="rooms-wrapper">
    <template v-if="newLikesIsBlocked">
      <div class="blocked-banner">
        <BaseIcon
          icon-file="chat-unlock-blue"
          :width="isMobile ? 24 : 32"
          :height="isMobile ? 24 : 32"
          class="icon"
        />
        <div>
          <strong>{{ $t('rooms_banner_title') }}</strong>
          <p>
            {{ $t('rooms_banner_text') }}
          </p>
        </div>
      </div>
    </template>

    <template
      v-if="
        showUnansweredChatsForPremiumOrFree ||
        showUnansweredChatsForUnlockedFreemium
      "
    >
      <h4 class="rooms-heading">{{ $t('rooms_unanswered_title') }}</h4>
      <Room
        v-for="room of unansweredChats"
        :key="room.id"
        :room="room"
        :candidate-id="candidateId"
        :is-mobile="isMobile"
        :delete-visible="openMenuRoom === room.id"
        :is-locked="
          isFreemiumOrTrial &&
          freemiumChatLocked &&
          (!unlockedRooms || !unlockedRooms.includes(room.id))
        "
        class="room-unanswered"
        @click="$emit('selected', $event)"
        @delete="$emit('delete', $event)"
        @menuOpen="handleMenuOpen"
      />

      <h4 v-if="firstAnsweredIndex !== -1" class="rooms-heading">
        {{ $t('rooms_answered_title') }}
      </h4>
    </template>

    <Room
      v-for="room of answeredChats"
      :key="room.id"
      :room="room"
      :candidate-id="candidateId"
      :is-mobile="isMobile"
      :delete-visible="openMenuRoom === room.id"
      :is-locked="
        isFreemiumOrTrial &&
        freemiumChatLocked &&
        (!unlockedRooms || !unlockedRooms.includes(room.id))
      "
      class="room-answered"
      @click="$emit('selected', $event)"
      @delete="$emit('delete', $event)"
      @menuOpen="handleMenuOpen"
    />

    <p
      v-if="data.hasMore && !isLoadingMore"
      class="load-more"
      @click="$emit('loadMore')"
    >
      {{ $t('chat_room_show_more') }}
    </p>

    <div v-else-if="isLoadingMore" class="load-more-spinner">
      <div class="spinner-small-wrapper">
        <ASpinner :classes="['loading-spinner']"></ASpinner>
      </div>
    </div>
  </section>
  <section v-else-if="isLoading" class="rooms-wrapper">
    <section class="center">
      <div class="spinner-wrapper">
        <ASpinner :classes="['loading-spinner']"></ASpinner>
      </div>
    </section>
  </section>
  <section v-else class="rooms-wrapper rooms-wrapper-no-conversations">
    <h2>{{ $t('chat_room_no_active') }}</h2>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ASpinner from '@/components/atomic/atoms/a-spinner';
import Room from './Room';

export default {
  components: {
    Room,
    ASpinner
  },

  props: {
    data: {
      type: [Object, null],
      default: () => {}
    },

    candidateId: {
      type: [Number, String],
      default: null
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    isLoadingMore: {
      type: Boolean,
      default: false
    },

    isMobile: {
      type: Boolean,
      default: false
    },

    isFreemiumOrTrial: {
      type: Boolean,
      default: false
    },

    unlockedRooms: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      openMenuRoom: ''
    };
  },

  computed: {
    ...mapGetters({
      primaryProposition: 'myPropositions/primaryProposition'
    }),
    unansweredChats() {
      return this.data.rooms.filter(room => room.unansweredFullSwap);
    },

    answeredChats() {
      return !this.isFreemiumOrTrial
        ? this.data.rooms.filter(room => !room.unansweredFullSwap)
        : this.data.rooms;
    },

    freemiumChatLocked() {
      const roomLocked = this.data.rooms.filter(room => room.isFreemiumLocked);
      return roomLocked;
    },

    firstAnsweredIndex() {
      // Only show "Answered" title if there are answered rooms loaded
      return this.data.rooms.findIndex(room => !room.unansweredFullSwap);
    },

    newLikesIsBlocked() {
      return (
        this.unansweredChats.length >= this.data.maxUnanswered &&
        !this.isFreemiumOrTrial
      );
    },

    showUnansweredChatsForPremiumOrFree() {
      return this.unansweredChats.length > 0 && !this.isFreemiumOrTrial;
    },

    showUnansweredChatsForUnlockedFreemium() {
      return (
        this.unansweredChats.length > 0 &&
        this.primaryProposition.isFreemium &&
        !this.freemiumChatLocked
      );
    }
  },

  mounted() {
    try {
      this.$nextTick(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      });
    } catch (error) {
      //  Empty catch
    }
  },

  methods: {
    handleMenuOpen(data) {
      this.openMenuRoom = '';
      const roomId = data.id;
      if (roomId) {
        this.openMenuRoom = roomId;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.rooms-wrapper {
  height: 100%;
  width: 100%;
}

.room-unanswered:first-of-type,
.room-answered:first-of-type {
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}

.load-more {
  text-align: center;
  padding: 20px 0;
  cursor: pointer;
  margin: 0px;
  color: $main-blue;
}

.load-more-spinner {
  display: flex;
  justify-content: center;
  padding: 25px 0;
}

.spinner-small-wrapper {
  display: flex;
  width: 20px;
  height: 20px;
}

.spinner-wrapper {
  display: flex;
  width: 40px;
  height: 40px;
}

.loading-spinner {
  border-top-color: $main_blue !important;
}

.center {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;

  @media ($mobile) {
    height: calc(100vh - 112px);
  }
}

.rooms-wrapper-no-conversations {
  padding: 20px;
  margin-top: 5vh;
  text-align: center;
}

.blocked-banner {
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 14px;
  line-height: 1.4;
  color: $text-primary;

  @media ($desktop) {
    margin-left: 0;
  }

  .icon {
    display: grid;
    place-items: center;
    margin-right: 15px;
    flex-shrink: 0;
  }

  p,
  strong {
    margin: 0;
  }

  strong {
    font-weight: 600;
    line-height: 1.2;
    display: inline-block;
    margin-bottom: 2px;
  }
}

.rooms-heading {
  margin: 20px 10px;
  margin-top: 32px;
  font-size: 16px;
  font-weight: 600;

  @media ($desktop) {
    margin-left: 0;
  }
}
</style>
