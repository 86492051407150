<template>
  <span
    class="notification-dot attention-dot"
    :style="{ height: `${size}px`, width: `${size}px` }"
  ></span>
</template>

<script>
export default {
  name: 'NotificationDot',
  props: {
    size: {
      type: Number,
      default: 12
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-dot {
  position: absolute;
  border-radius: 50%;
  right: -5px;
  background-color: $main-blue;
  animation: pulse 2s infinite;
  border: 2px solid white;
  top: -1px;

  &.menu-item-visible {
    top: 24px;
    @media ($large-desktop) {
      right: 2px;
    }
  }
}
</style>
