<template>
  <transition name="scale" appear>
    <div
      v-outside-click="handleClose"
      class="animated-checkbox-wrapper flex-center"
      :class="{ box: !hideBox }"
      @animationend="done()"
    >
      <slot />
      <div v-if="showAnimation" class="flex-center">
        <svg
          class="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            class="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            class="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      </div>
    </div>
  </transition>
</template>

<script>
import outsideClick from '@/directives/outside-click';

export default {
  name: 'MAnimatedCheckbox',

  directives: { outsideClick },
  props: {
    duration: {
      type: Number,
      required: false,
      default: 0
    },

    showAnimation: {
      type: Boolean,
      required: false,
      default: true
    },

    hideBox: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      timerId: null,
      animationsDone: 0
    };
  },

  mounted() {
    if (this.duration !== 0)
      this.timerId = setTimeout(() => this.$emit('close'), this.duration);
  },

  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
    this.$emit('close');
  },

  methods: {
    handleClose() {
      this.$emit('close');
    },

    done() {
      this.animationsDone++;
      if (this.animationsDone >= 4) this.$emit('done');
    }
  }
};
</script>

<style lang="scss" scoped>
.mgt-20 {
  margin-top: 20px;
}

.box {
  background: rgba(241, 242, 246, 0.8);
  backdrop-filter: blur(4px);
}

.animated-checkbox-wrapper {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
// Colors
$green: $transfer-green;
$white: #fff;

// Misc
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $green;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $green;
  fill: none;
  animation: stroke 0.6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s $curve 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $green;
  }
}
</style>
