import { lbApiBeta } from '../../../utils/axiosConfig';

async function markInterest({
  propositionId,
  propositionId2,
  interest,
  source
}) {
  try {
    const { data } = await lbApiBeta.post('/api/v2/interest/mark-interest', {
      propositionId,
      propositionId2,
      interest,
      source
    });
    return data;
  } catch (e) {
    throw Error(e);
  }
}

async function setInterestLevel({
  propositionId,
  propositionId2,
  propositionId3,
  interestLevel
}) {
  try {
    const { data } = await lbApiBeta.post('/api/v2/interest/level', {
      propositionId,
      propositionId2,
      propositionId3,
      interestLevel
    });
    return data;
  } catch (e) {
    throw Error(e);
  }
}

export default {
  markInterest,
  setInterestLevel
};
