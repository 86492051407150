import { lbApiBeta } from '@/utils/axiosConfig';

async function readUpdatedMatch(propositionId, matchId) {
  try {
    const { data: match } = await lbApiBeta.get(
      `/api/matches/read/${propositionId}/${matchId}`
    );
    return match;
  } catch (e) {
    window.Logger.log('FaTaL eRroR: ', e);
  }
}

async function fetchMatches(propositionId, matchStatus) {
  try {
    const { data: activeMatches } = await lbApiBeta.get(
      `/api/matches/${matchStatus}/${propositionId}`
    );
    return activeMatches;
  } catch (e) {
    window.Logger.log('FaTaL eRroR: ', e);
  }
}

async function refetchSingleMatch(myPropositionId, matchId) {
  try {
    const { data: match } = await lbApiBeta.get(
      `/api/matches/${myPropositionId}/${matchId}`
    );
    return match;
  } catch (e) {
    window.Logger.log('FaTaL eRroR: ', e);
  }
}

async function fetchInitialMatchStatusCounts(propositionId, matchId) {
  const matchIdParam = matchId ? `?matchId=${matchId}` : '';
  try {
    const { data: counts } = await lbApiBeta.get(
      `/api/matches/summary/${propositionId}${matchIdParam}`
    );
    return counts;
  } catch (e) {
    window.Logger.log('FaTaL eRroR: ', e);
  }
}

export default {
  readUpdatedMatch,
  fetchMatches,
  refetchSingleMatch,
  fetchInitialMatchStatusCounts
};
