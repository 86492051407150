function descriptionWithMaxLength(description) {
  // Remove line breaks
  const desc = description ? description.replace(/\r?\n|\r/g, '') : ' ';
  if (desc.length > 155) {
    return desc.substr(0, 155);
  }
  return desc;
}

export default descriptionWithMaxLength;
