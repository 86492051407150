<template>
  <div class="my-propositions-card">
    <div class="card-content">
      <div class="card-presentation">
        <div class="img"></div>

        <div class="card-info">
          <div>
            <p class="secondary-text block">textblock</p>
            <p class="primary-text block">textblock textblock</p>
            <p class="secondary-text block">texttext</p>
          </div>
          <p class="secondary-text block">textblock</p>
        </div>
      </div>
    </div>

    <div v-if="isMobile" class="mobile-extra-visibility"></div>

    <div class="card-options">
      <a class="card-option block">Redigera</a>
      <a v-if="!isMobile" class="card-option block desktop-only">Visa annons</a>
      <a class="card-option block">Hantera abonnemang</a>
      <a v-if="!isMobile" class="primary-button block">
        Beställ extra synlighet
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MyPropositionsCardLoader',

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  }
};
</script>

<style lang="scss" scoped>
@keyframes load {
  from {
    opacity: 0.6;
  }

  50% {
    opacity: 0.3;
  }

  to {
    opacity: 0.6;
  }
}

.my-propositions-card {
  border-radius: 8px;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.09);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px 0;
  position: relative;
  user-select: none;
  width: 100%;
  animation: load 1.5s infinite;
}

.card-content {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 18px;

  @media ($mobile) {
    padding: 22px 12px;
    padding-top: 12px;
  }
}

.card-presentation {
  display: flex;
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;

  @media ($mobile) {
    padding: 0 10px;
  }
}

.img {
  background-color: #8e8d8f;

  background-position: center;
  background-size: cover;
  border-radius: 2px;
  height: 138px;
  width: 138px;

  @media ($mobile) {
    max-height: 75px;
    max-width: 75px;
    min-height: 75px;
    min-width: 75px;
  }
}

.mobile-extra-visibility {
  background-image: linear-gradient(90deg, #b6b6b6 2%, #e0e0e0 100%);
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  width: 100%;
  font-size: 14px;
}

.card-options {
  align-items: center;
  border-top: 1px solid rgba(151, 151, 151, 0.18);
  display: flex;
  height: 69px;
  padding: 0px 18px;
  width: 100%;

  @media ($mobile) {
    justify-content: space-around;
  }

  .primary-button {
    margin-left: auto;
  }
}

.card-option {
  color: $main-blue;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  margin-right: 20px;

  @media ($mobile) {
    font-weight: 700;
    font-size: 14px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
}

.secondary-text {
  color: #8e8d8f;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0 0 0;
  padding: 0;

  @media ($mobile) {
    font-size: 12px;
  }
}

.primary-button {
  background-color: $main-blue;
  border-radius: 8px;
  color: white;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.primary-text {
  color: #3c3c3f;
  font-size: 24px;
  font-weight: 700;
  margin: 4px 0 0 0;
  padding: 0;

  @media ($mobile) {
    font-size: 16px;
  }
}

.block {
  display: block;
  position: relative;

  &::after {
    background-color: #8e8d8f;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 50;
  }
}
</style>
