<template>
  <div class="collection-container">
    <div class="title-and-description">
      <h2>{{ title }}</h2>
      <p>{{ description }}</p>
    </div>
    <div v-if="showCategoryFilter" class="categories">
      <div
        :class="{ 'is-active': !selectedCategory }"
        class="categories__button"
        @click="handleCategoryClick(null)"
      >
        {{ $t('o_blog_collection_filter_all') }}
      </div>
      <div
        v-for="category in categories"
        :key="category.id"
        :class="{ 'is-active': category.name == selectedCategory }"
        class="categories__button"
        @click="handleCategoryClick(category.name)"
      >
        {{ category.name }}
      </div>
    </div>
    <div class="articles">
      <MCard
        v-for="(article, index) in articles"
        :key="article.id"
        :article="article"
        :class="{ 'card--large': index === 0 && showHighlightedArticle }"
        :highlighted-article="index === 0 && showHighlightedArticle"
      />
    </div>
    <div class="button-container">
      <BaseButton
        v-if="showLoadMoreButton && articlesLoaded < articlesCount"
        class="button button--rounded"
        @click="getBlogposts(articlesToLoad)"
      >
        {{ $t('o_blog_collection_load_more_button') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MCard from '@/components/atomic/molecules/m-card';

export default {
  name: 'OBlog',
  components: {
    MCard
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    initialCategory: {
      type: String,
      default: null
    },
    showCategoryFilter: {
      type: Boolean,
      default: false
    },
    showLoadMoreButton: {
      type: Boolean,
      default: false
    },
    showHighlightedArticle: {
      type: Boolean,
      default: false
    },
    initialArticlesToLoad: {
      type: Number,
      default: 7
    },
    articlesToLoad: {
      type: Number,
      default: 9
    }
  },
  data() {
    return {
      articles: [],
      categories: [],
      selectedCategory: this.initialCategory,
      articlesCount: 0,
      error: null,
      articlesLoaded: 0
    };
  },
  created() {
    this.getBlogposts(this.initialArticlesToLoad);
    this.getCategories();
  },
  methods: {
    handleCategoryClick(category) {
      this.selectedCategory = category;
      this.articles = [];
      this.articlesLoaded = 0;
      this.getBlogposts(this.initialArticlesToLoad);
    },
    async getBlogposts(articlesToLoad) {
      const categoryFilterQuery = this.selectedCategory
        ? `&categories.name=${this.selectedCategory}`
        : '';

      // Get blogposts
      try {
        const response = await axios.get(
          `https://cms.lagenhetsbyte.se/blogposts?_sort=published_at:DESC&_limit=${articlesToLoad}&_start=${this.articlesLoaded}&_locale=${this.$routeLocale}${categoryFilterQuery}`
        );
        this.articles.push(...response.data);
        this.articlesLoaded += articlesToLoad;
      } catch (error) {
        this.error = error;
      }

      // Get blogposts count
      try {
        const response = await axios.get(
          `https://cms.lagenhetsbyte.se/blogposts/count?_locale=${this.$routeLocale}${categoryFilterQuery}`
        );
        this.articlesCount = response.data;
      } catch (error) {
        this.error = error;
      }
    },
    async getCategories() {
      try {
        const response = await axios.get(
          `https://cms.lagenhetsbyte.se/categories?_locale=${this.$routeLocale}`
        );
        this.categories = response.data;
      } catch (error) {
        //
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.collection-container {
  padding-left: 1rem;
  padding-right: 1rem;

  @media ($desktop) {
    max-width: 1200px;
    width: 90%;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.title-and-description {
  margin-bottom: 1.5rem;

  @media ($desktop) {
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1.75rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;

    @media ($desktop) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    margin-top: 0;
    max-width: 700px;

    @media ($desktop) {
      font-size: 1.1rem;
    }
  }
}

.categories {
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  overflow-x: scroll;

  @media ($desktop) {
    margin-top: 0;
    flex-wrap: wrap;
    overflow-x: auto;
  }
}

.categories__button {
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 16px;
  border: 1px solid #b0b0b0;
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  @media ($desktop) {
    font-size: 14px;
    font-weight: 600;
    padding: 12px 20px 11px;
  }
}

.categories__button:hover,
.categories__button:focus {
  border-color: #969696;
}

.categories__button.is-active {
  border: 2px solid gray;
}

.articles {
  display: grid;
  grid-template-columns: 1;
  column-gap: 1rem;

  @media ($desktop) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.button-container {
  text-align: center;
}
</style>
