<template>
  <button :class="['link-button', { 'link-warn': warn }]">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'LinkButton',

  props: {
    warn: {
      type: Boolean,
      default: false
    },
    handleClick: {
      type: Function,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.link-button {
  -webkit-appearance: none;
  background-color: transparent;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 39px;
  outline: none;
  text-align: center;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
  color: $main-blue;
  padding: 0 15px;

  &.full-width {
    width: 100%;
  }

  &:hover {
    opacity: 0.6;
  }

  &:active {
    outline: none;
  }

  &.warn {
    background-color: red;
  }
}

.link-warn:hover {
  border: 1px solid red;
}
</style>
