import axios from 'axios';
import store from '../store/store';

import {
  getLbApiEndpoint,
  getBetaEndpoint,
  getAWSEndpoint,
  getLbPropositionApiEndpoint,
  getLbUserApiEndpoint,
  getNotificationsEndpoint,
  getSearchEndpoint,
  getStreetviewEndpoint,
  getAuthenticationEndpoint,
  getPaymentEndpoint,
  getGrowthbookEndpoint,
  getAdmin2ApiEndpoint
} from './getEndpoint';
import { log } from './logger';
import { getCountry } from '../plugins/country/module';

const authenticationApi = axios.create({
  baseURL: getAuthenticationEndpoint(),
  withCredentials: true,
  headers: { country: getCountry() }
});

const searchApi = axios.create({
  baseURL: getSearchEndpoint(),
  withCredentials: true,
  'content-Type': 'application/json'
});

const paymentApi = axios.create({
  baseURL: getPaymentEndpoint(),
  withCredentials: true,
  'content-Type': 'application/json'
});
addAuthInterceptor(paymentApi);

const lbApi = axios.create({
  baseURL: getLbApiEndpoint(),
  withCredentials: true,
  'content-Type': 'application/json',
  credentials: 'same-origin',
  'cache-Control': 'no-cache'
});
addAuthInterceptor(lbApi);

const lbUserApi = axios.create({
  baseURL: getLbUserApiEndpoint(),
  withCredentials: true,
  'content-Type': 'application/json'
});

const lbPropositionApi = axios.create({
  baseURL: getLbPropositionApiEndpoint(),
  withCredentials: true,
  'content-Type': 'application/json'
});
addAuthInterceptor(lbPropositionApi);

const lbApiBeta = axios.create({
  baseURL: getBetaEndpoint(),
  withCredentials: true,
  'content-Type': 'application/json',
  credentials: 'same-origin'
});
addAuthInterceptor(lbApiBeta);

const lbAws = axios.create({
  baseURL: getAWSEndpoint(),
  withCredentials: true,
  'content-Type': 'application/json',
  credentials: 'same-origin'
});
addAuthInterceptor(lbAws);

const lbStreetviewApi = axios.create({
  baseURL: getStreetviewEndpoint(),
  withCredentials: false,
  'content-Type': 'application/json',
  credentials: 'same-origin'
});

const lbNotificationsApi = axios.create({
  baseURL: getNotificationsEndpoint(),
  withCredentials: true,
  'content-Type': 'application/json',
  credentials: 'same-origin'
});
addAuthInterceptor(lbNotificationsApi);

const growthbookApi = axios.create({
  baseURL: getGrowthbookEndpoint(),
  'content-Type': 'application/json',
  timeout: 5000
});

const lbAdmin2Api = axios.create({
  baseURL: getAdmin2ApiEndpoint(),
  withCredentials: true,
  'content-Type': 'application/json',
  credentials: 'same-origin'
});
addAuthInterceptor(lbAdmin2Api);

function addAuthInterceptor(instance) {
  instance.interceptors.response.use(
    response => response,
    async e => {
      if (e && e.response && e.response.status && e.response.status === 401) {
        try {
          await authenticationApi.post('auth/logout');
          localStorage.clear();
          await store.dispatch('app/checkAuth', null, { root: true });
        } catch (error) {
          log(`Failed to logout: ${error}`, true);
        }
      }

      return Promise.reject(e);
    }
  );
}

export {
  lbApi,
  lbApiBeta,
  lbUserApi,
  lbPropositionApi,
  lbAws,
  lbStreetviewApi,
  searchApi,
  lbNotificationsApi,
  authenticationApi,
  paymentApi,
  growthbookApi,
  lbAdmin2Api
};
