function showMobileHeader(context, value) {
  context.commit('toggleMobileHeader', value);
}

function showDesktopHeader(context, value) {
  context.commit('toggleDesktopHeader', value);
}

function setGlobalModal(context, value) {
  context.commit('setGlobalModal', value);
}

function setCookiesModal(context, value) {
  context.commit('setCookiesModal', value);
}

function openPropositionModal(
  context,
  { propositionId, type, trigger = null }
) {
  context.commit('setPropositionViewModalId', { propositionId, type, trigger });
}

function closePropositionModal(context) {
  context.commit('pageModalActivePage', 'proposition');

  context.commit('setPropositionViewModalId', {
    propositionId: null,
    type: null,
    trigger: null
  });
}

// change name to tab
function setPageModalActivePage(context, value) {
  context.commit('pageModalActivePage', value);
}

function setPageModalZIndex(context, value) {
  context.commit('pageModalZIndex', value);
}

function toggleDisableScroll(context, val) {
  context.commit('toggleDisableScroll', val);
}

function setFreemiumBannerTopMargin(context, val) {
  context.commit('freemiumBannerTopMargin', val);
}

export default {
  showMobileHeader,
  showDesktopHeader,
  setGlobalModal,
  setCookiesModal,
  openPropositionModal,
  closePropositionModal,
  setPageModalActivePage,
  setPageModalZIndex,
  toggleDisableScroll,
  setFreemiumBannerTopMargin
};
