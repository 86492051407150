import { render, staticRenderFns } from "./Messages.vue?vue&type=template&id=2d7709b8&scoped=true"
import script from "./Messages.vue?vue&type=script&lang=js"
export * from "./Messages.vue?vue&type=script&lang=js"
import style0 from "./Messages.vue?vue&type=style&index=0&id=2d7709b8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d7709b8",
  null
  
)

export default component.exports