<template>
  <div>
    <div
      v-if="open"
      class="start-animation-wrapper fade-in"
      :style="{
        animationDuration: `${modalAnimMs}ms`
      }"
    >
      <div class="start-animation-modal">
        <div class="status-heading">
          <span ref="statusText" class="status-heading-text">
            {{ currentText }}
          </span>
        </div>
        <ProgressBar
          ref="progressBar"
          :percentage="isSuccess ? 100 : progressBarPercentage"
          class="progress-bar fade-in"
        />
        <div ref="linearGradientTop" class="linear-gradient-top" />
        <StartInfo
          :style="{
            display: !isSuccess ? 'none' : 'flex'
          }"
          :propositions-site-count="formattedPropositionsSiteCount"
          @close="handleCloseAnimation"
        />
        <div
          v-if="!isSuccess"
          ref="cardAnimationWrapper"
          class="card-animation-wrapper"
        >
          <div class="card-animation-left-container">
            <div ref="cardAnimationLeft" class="cards-list">
              <div
                v-for="(exampleImage, index) in exampleFlatImagesSet1"
                :key="index"
                class="card-wrapper"
              >
                <div
                  ref="card"
                  class="card"
                  :data-id="index"
                  :class="exampleImage.state"
                  :style="{
                    'animation-duration': `${index / 3}s`
                  }"
                >
                  <div
                    class="image"
                    :class="exampleImage.size"
                    :style="{
                      'background-image': `url(${exampleImage.image})`
                    }"
                  />
                </div>
                <div
                  ref="skeleton"
                  class="text-skeleton-wrapper"
                  :class="exampleImage.state"
                  :style="{
                    'animation-delay': `{${index}s`,
                    'animation-duration': `${index / 3}s`
                  }"
                >
                  <div
                    class="text-skeleton1"
                    :class="
                      exampleImage.state === 'dimmed'
                        ? 'highlighted-animation-skeleton'
                        : ''
                    "
                  />
                  <div
                    class="text-skeleton2"
                    :class="
                      exampleImage.state === 'dimmed'
                        ? 'highlighted-animation-skeleton'
                        : ''
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-animation-right-container">
            <div ref="cardAnimationRight" class="cards-list">
              <div
                v-for="(exampleImage, index) in exampleFlatImagesSet2"
                :key="index"
                class="card-wrapper"
              >
                <div
                  ref="card"
                  class="card"
                  :data-id="index"
                  :class="exampleImage.state"
                  :style="{
                    'animation-duration': `${index / 3}s`
                  }"
                >
                  <div
                    class="image"
                    :class="exampleImage.size"
                    :style="{
                      'background-image': `url(${exampleImage.image})`
                    }"
                  />
                </div>
                <div
                  ref="skeleton"
                  class="text-skeleton-wrapper"
                  :class="exampleImage.state"
                  :style="{
                    'animation-delay': `{${index}s`,
                    'animation-duration': `${index / 3}s`
                  }"
                >
                  <div
                    class="text-skeleton1"
                    :class="
                      exampleImage.state === 'dimmed'
                        ? 'highlighted-animation-skeleton'
                        : ''
                    "
                  />
                  <div
                    class="text-skeleton2"
                    :class="
                      exampleImage.state === 'dimmed'
                        ? 'highlighted-animation-skeleton'
                        : ''
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref="linearGradientBottom" class="linear-gradient-bottom" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartAnimation',
  components: {
    ProgressBar,
    StartInfo
  },

  data: () => ({
    open: true,
    timeoutNextStep: null,
    timeoutSuccess: null,
    progressBarPercentage: 0,
    startAnimationCompleted: false,
    modalAnimMs: 200
  }),

  computed: {
    ...mapGetters({
      disableScroll: 'ui/disableScroll',
      currentStep: 'startAnimation/currentStep',
      statusTexts: 'startAnimation/statusTexts',
      exampleFlatImagesSet1: 'startAnimation/exampleFlatImagesSet1',
      exampleFlatImagesSet2: 'startAnimation/exampleFlatImagesSet2',
      imageSizes: 'startAnimation/imageSizes',
      isSuccess: 'startAnimation/isSuccess',
      progressBar: 'startAnimation/progressBar',
      isMobile: 'screenSize/isMobile',
      trialDaysLeft: 'trial/trialDaysLeft',
      user: 'app/user',
      propositionsSiteCount: 'propositions/propositionsSiteCount'
    }),

    isLastStep() {
      return this.currentStep === 3;
    },

    formattedPropositionsSiteCount() {
      return this.propositionsSiteCount
        ? this.propositionsSiteCount.toLocaleString()
        : '15 000';
    },

    animationSpeed() {
      return this.isMobile ? 6 : 8;
    },

    cards() {
      return this.$refs.card;
    },

    skeletons() {
      return this.$refs.skeleton;
    },

    currentText() {
      return this.$t(this.statusTexts[this.currentStep - 1]);
    }
  },

  beforeMount() {
    // fetch propositions site count
    this.getPropositionsSiteCount();

    window.addEventListener('keyup', event => {
      this.onEscapeKeyUp(event);
    });
  },

  mounted() {
    if (!this.open || this.isSuccess) return;

    // Disable scroll
    this.addDisableScroll('StartAnimation');

    // Start animation
    this.animateCardsSequence();
  },

  destroyed() {
    window.removeEventListener('keyup', this.onEscapeKeyUp);
    this.removeDisableScroll('StartAnimation');
  },

  created() {
    this.runAnimationInterval();
  },

  beforeDestroy() {
    clearInterval(this.runAnimationInterval);
    clearTimeout(this.timeoutNextStep);
    clearTimeout(this.timeoutSuccess);
  },

  methods: {
    ...mapActions({
      getPropositionsSiteCount: 'propositions/getPropositionsSiteCount',
      setAnimCurrentStep: 'startAnimation/setAnimCurrentStep',
      setAnimProgressBar: 'startAnimation/setAnimProgressBar',
      setIsSuccess: 'startAnimation/setIsSuccess'
    }),

    ...mapMutations({
      addDisableScroll: 'ui/addDisableScroll',
      removeDisableScroll: 'ui/removeDisableScroll'
    }),

    async animateCardsSequence() {
      // Refs
      const statusText = this.$refs.statusText;
      const cardAnimationWrapper = this.$refs.cardAnimationWrapper;
      const cardsLeftColumn = this.$refs.cardAnimationLeft;
      const cardsRightColumn = this.$refs.cardAnimationRight;

      // Fire off initial animations
      this.animate(statusText, 'slide-in-from-left', 1, 1.5);

      // Animate right cards from bottom
      this.animateCardsFromBottom(
        cardsRightColumn,
        this.animate,
        0.2,
        this.animationSpeed
      );

      // 3s then next step
      this.timeoutNextStep = setTimeout(() => {
        this.setAnimCurrentStep(2);
        this.reflow('slide-in-from-left', this.$refs.statusText);
      }, 3000);

      // Animate left cards from bottom
      await this.animateCardsFromBottom(
        cardsLeftColumn,
        this.animate,
        0,
        this.animationSpeed
      );

      this.setAnimCurrentStep(3);

      this.reflow('slide-in-from-left', this.$refs.statusText);
      cardAnimationWrapper.classList.add('fade-out');

      // 2s then success
      this.timeoutSuccess = setTimeout(() => {
        this.setIsSuccess(true);
        if (this.isSuccess) {
          this.animateFadeInfoItems();
        }
      }, 2000);
    },

    async animateFadeInfoItems() {
      // Access ChildRefs (StartInfo)
      const childRefs = this.$children[1].$refs;
      if (childRefs === undefined) return;
      // Initially hide items and CTA button
      childRefs.infoListItem1.style.opacity = 0;
      childRefs.infoListItem2.style.opacity = 0;
      childRefs.infoListItem3.style.opacity = 0;
      childRefs.ctaButton.style.opacity = 0;

      // Fade in greeting text
      await this.animate(childRefs.greetingText, 'slide-in-from-left', 0, 0.5);

      const revealDuration = 0.35;
      const revealDelay = 0;

      // Info texts
      await this.animate(
        childRefs.infoListItem1,
        'slide-fade-from-bottom',
        revealDelay,
        revealDuration
      );
      await this.animate(
        childRefs.infoListItem2,
        'slide-fade-from-bottom',
        revealDelay,
        revealDuration
      );
      await this.animate(
        childRefs.infoListItem3,
        'slide-fade-from-bottom',
        revealDelay,
        revealDuration
      );
      // Finally fade in CTA button
      await this.animate(
        childRefs.ctaButton,
        'slide-fade-from-bottom',
        revealDelay,
        revealDuration
      );

      this.startAnimationCompleted = true;
    },

    onEscapeKeyUp(event) {
      if (
        event.which === 27 &&
        this.isLastStep &&
        this.startAnimationCompleted
      ) {
        this.handleCloseAnimation();
      }
    },

    handleCloseAnimation() {
      this.open = false;
      // route to swap list
      this.$router.push(`${this.$t('path_swap_list', this.$routeLocale)}`);
    },

    reflow(animation, el) {
      if (el === undefined) return;
      el.classList.remove(animation);
      // reflow to restart animation
      void el.offsetWidth;
      el.classList.add(animation);
      el.style.animationDelay = '0s';
    },

    runAnimationInterval() {
      const offset = 300;
      let progressBarValue = 0;
      let duration = this.animationSpeed * (1000 + offset);
      let tick = duration / 100;
      const interval = setInterval(
        () => {
          if (this.isLastStep) {
            // speed up progress right at the end
            tick = duration / 20;
          }
          if (progressBarValue < duration) {
            progressBarValue += tick;
            this.progressBarPercentage = parseInt(
              (progressBarValue / duration) * 100
            );
          } else {
            clearInterval(interval);
            return;
          }

          if (this.$refs.linearGradientTop === undefined) return;

          const linearGradientTopY =
            this.$refs.linearGradientTop.getBoundingClientRect().y;
          const linearGradientBottomY =
            this.$refs.linearGradientBottom.getBoundingClientRect().y;

          if (this.cards === undefined) return;

          this.cards.forEach((card, index) => {
            const id = card.dataset.id;
            const isOdd = index % 2 === 0;
            const y = card.getBoundingClientRect().y;
            if (
              y < linearGradientBottomY &&
              y > linearGradientTopY + 100 &&
              isOdd &&
              this.currentStep === 2
            ) {
              this.animate(card, 'highlighted-animation', parseInt(id));
              card.classList.add('highlighted');
              this.skeletons[index].classList.remove('dimmed');
            }
          });
        },
        tick,
        duration,
        progressBarValue
      );
    },

    animateCardsFromBottom: async (cards, animate, delay, speed) => {
      await animate(
        cards,
        'slide-in-cards-from-bottom-animation',
        delay ?? 0,
        speed ?? 6
      );
    },

    animate(el, animation, animationDelay = 0, speed = 6) {
      if (el === undefined) return;
      return new Promise(resolve => {
        function handleAnimationEnd() {
          resolve(el);
          el.removeEventListener('animationend', handleAnimationEnd);
        }
        el.addEventListener('animationend', handleAnimationEnd, {
          once: true
        });
        el.classList.contains(animation) && el.classList.remove(animation);
        el.classList.add(animation);
        el.style.animationDelay = `${animationDelay}s`;
        el.style.animationDuration = `${speed}s`;
      });
    }
  }
};

import { mapGetters, mapActions, mapMutations } from 'vuex';
import ProgressBar from '@/components/Abundance/ProgressBar.vue';
import StartInfo from './StartInfo.vue';
</script>

<style lang="scss" scoped>
$blue-color: #409fff;
$black-color: #2c3e50;

.dimmed {
  transition: all 3s ease-in-out;
  opacity: 0.3;
}

.highlighted {
  opacity: 1;
  transition: all 1s;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 6px;
}

.cards-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: max-content;
  position: fixed;
  top: 100%;
}

.progress-bar {
  position: relative;
  margin-top: 60px;
  z-index: 3;
}

.start-animation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 350px;
  position: absolute;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1000;

  .start-animation-modal {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    height: 100%;
    position: relative;
    background: #eee;
    overflow: hidden;
    padding: 10px 5px;

    @media ($desktop) {
      padding: 20px 40px;
      gap: 8px;
      border-radius: 16px;
      width: 100%;
      height: 100%;
      max-width: 900px;
      max-height: 700px;
      box-shadow: rgba(0, 0, 0, 0.08) 0 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0 10px 10px -5px;
    }
  }
}

.text-skeleton1 {
  width: 80%;
  height: 9px;
  border-radius: 4px;
  background: rgba(44, 62, 80, 0.75);
}

.text-skeleton2 {
  width: 70%;
  height: 8px;
  border-radius: 4px;
  background: rgba(44, 62, 80, 0.75);
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  @media ($desktop) {
    max-width: 450px;
  }
}

.text-skeleton-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 10px;
  margin-bottom: 20px;

  @media ($desktop) {
    margin-bottom: 32px;
  }
}

.linear-gradient-top {
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
  height: 138px;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;

  @media ($mobile) and (orientation: landscape) {
    height: 30px;
  }
}

.linear-gradient-bottom {
  position: absolute;
  bottom: 0;
  left: 1px;
  width: 100%;
  height: 153px;
  z-index: 1;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);

  @media ($mobile) and (orientation: landscape) {
    height: 50px;
  }
}

.status-heading {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  left: 0;
  height: 90px;
  width: 100%;
  background: white;
  line-height: 130%;
  margin-bottom: 15px;
  margin-top: 5px;
  user-select: none;
  z-index: 2;
}

.status-heading-text {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  color: $black-color;
}

.card-animation-wrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eee;
  overflow: hidden;

  @media ($desktop) {
    gap: 20px;
    padding: 0 20px;
  }
}

.card-animation-left-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 20px 0;
  margin: 0 5px;

  @media ($desktop) {
    margin: 0 20px;
  }
}

.card-animation-right-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 20px 0;
  margin: 0 5px;
  position: relative;
  top: -20px;

  @media ($desktop) {
    margin: 0 20px;
  }
}

.card {
  display: flex;
  justify-content: center;
  position: relative;
  height: max-content;
  justify-content: flex-start;
  width: 100%;
  color: white;
  border-radius: 16px;

  .image {
    display: flex;
    height: 100%;
    width: 100%;
    opacity: 1;
    border-radius: 16px;
    background: lightgray;
    background-size: cover;
    background-repeat: no-repeat;
    background: lightgray 50% / cover no-repeat, #d9d9d9;

    &.lg {
      height: 256px;
      width: 100%;

      @media ($desktop) {
        aspect-ratio: 3/4;
        height: 404px;
        background-position: 40% 50%;
      }
    }
    &.md {
      height: 176px;
      width: 100%;
      background-position: 20% 50%;

      @media ($desktop) {
        aspect-ratio: 3/4;
        height: 320px;
      }
    }
    &.sm {
      height: 120px;
      width: 100%;
      background-position: 0% 50%;

      @media ($desktop) {
        aspect-ratio: 3/4;
        height: 256px;
      }
    }
  }
}

.slide-in-from-left {
  animation: slide-in-from-left 1.5s cubic-bezier(0.35, 1, 0.45, 1) both;
  animation-delay: 0.5s;
}

.slide-in-cards-from-bottom-animation {
  position: relative;
  animation: slide-in-cards-from-bottom ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0;
}

.highlighted-animation {
  animation: highlighted-animation 2s infinite;
  animation-fill-mode: forwards;
}

.highlighted-animation-skeleton {
  animation: highlighted-animation-skeleton 8s infinite;
  animation-fill-mode: forwards;
  animation-delay: 4s;
}

.slide-fade-from-bottom {
  animation: slide-fade-from-bottom 1s cubic-bezier(0.35, 1, 0.45, 1) both;
}

.fade-in {
  animation: fade-in 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.fade-out {
  animation: fade-out 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.5s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-in-from-left {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes highlighted-animation {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.02);
  }

  70% {
    transform: scale(1);
  }

  90% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes highlighted-animation-skeleton {
  0% {
    transform: scale(1);
    transform-origin: 0% 0%;
  }

  50% {
    transform: scale(0.98);
    transform-origin: 0% 0%;
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slide-in-cards-from-bottom {
  to {
    transform: translateY(calc(-100% - 80px));
  }
}
</style>
