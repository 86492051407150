<template>
  <div class="report-success-wrapper">
    <div class="icon-container">
      <BaseIcon
        icon-file="check-mark-circle-dark-blue"
        :width="45"
        :height="45"
        class="icon"
      />
    </div>
    <div
      class="report-success-heading"
      v-html="$t('report_label_success_heading')"
    />
    <div
      class="report-success-thank-you"
      v-html="$t('report_description_success')"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'ReportSuccess',

  data() {
    return {
      showBlockPropositionCheckbox: false
    };
  },

  computed: {
    ...mapGetters({
      blockProposition: 'reportProposition/blockProposition'
    })
  },
  methods: {
    ...mapActions({
      setBlockProposition: 'reportProposition/setBlockProposition'
    }),
    handleBlockPropositionToggle() {
      this.setBlockProposition(!this.blockProposition);
    }
  }
};
</script>

<style lang="scss" scoped>
.report-success-wrapper {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100%;
  height: 100%;

  .report-success-heading {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 130%;
    margin: 1.5rem 0 0.75rem 0;
    color: #213a75;
  }

  .report-success-thank-you {
    color: #2c3e50;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    padding: 0 0 2rem 0;

    @media ($desktop) {
      padding: 0 6rem;
    }
  }

  .icon-container {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  .checkbox-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
  }
}
</style>
