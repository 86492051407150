<template>
  <div class="flex-center bankid-outerwrapper">
    <section class="bankid-wrapper mg-all-10">
      <div :class="{ disabled: isDisabled }"></div>
      <div class="bankid-selector">
        <BaseImage
          v-if="!successVisible"
          class="bankid-image"
          :url="icons.GENERAL.BANKID"
        ></BaseImage>
        <MAnimatedCheckbox
          v-else
          :show-animation="true"
          :hide-box="true"
          @done="$emit('success', responseData)"
        ></MAnimatedCheckbox>
      </div>

      <div
        v-if="isAuthenticatingWithQr || isAuthenticatingAutostart"
        class="authenticating-container"
      >
        <p class="bankid-info">
          <span v-if="isAuthenticatingWithQr">
            Starta BankID appen i din mobil skanna QR-koden.
          </span>
          <span v-else>
            Starta BankID appen i din mobil och följ instruktionerna.
          </span>
        </p>
        <div v-if="!qr" class="waiting-wrapper flex-center flex-col">
          <div class="waiting-spinner">
            <ALoadingSpinner />
          </div>
        </div>

        <BaseImage v-if="qr" :url="qr" class="qr" />

        <BaseButton
          class="cancel-auth-button grey outline"
          @click="bankidCancel(orderRef)"
          >Avbryt</BaseButton
        >
      </div>
      <template v-else>
        <p class="bankid-info">
          För att kunna logga in med BankID måste du först verfiera dig i
          inloggat läge med lösenord.
        </p>

        <section class="full-width input-wrapper">
          <BaseButton
            :disabled="isInvalid"
            :is-loading="isAuthenticatingWithQr"
            :class="{ 'grey outline': isMobileAgent }"
            class="full-width"
            @click="bankidStartQr()"
            >Starta mobilt BankID</BaseButton
          >

          <p class="bankid-text mb-10">Eller</p>

          <BaseButton
            :disabled="isInvalid"
            :is-loading="isAuthenticatingAutostart"
            class="mb-10 full-width"
            :class="{ 'grey outline': !isMobileAgent }"
            @click="bankidAutostart()"
            >Starta BankID på denna enhet</BaseButton
          >
        </section>

        <p class="text-error">
          <span v-if="bankidMessage">
            {{ bankidMessage }}
          </span>
        </p>

        <slot></slot>
      </template>
    </section>
  </div>
</template>

<script>
import MAnimatedCheckbox from '@/components/atomic/molecules/m-animated-checkbox-box';
import ALoadingSpinner from '@/components/atomic/atoms/a-loading-spinner.vue';

import icons from '@/utils/icon-constants';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { isDebug } from '../../utils/debug';

export default {
  components: {
    MAnimatedCheckbox,
    ALoadingSpinner
  },

  props: {
    isDisabled: {
      type: [Boolean, null],
      default: null
    },

    authPath: {
      type: String,
      default: ''
    },

    qrPath: {
      type: String,
      default: ''
    },

    collectPath: {
      type: String,
      default: ''
    },

    cancelPath: {
      type: String,
      default: ''
    },

    baseUrl: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      axiosInstance: null,
      bankidMessage: null,
      isAuthenticatingAutostart: false,
      isAuthenticatingWithQr: false,
      orderRef: null,
      successVisible: false,
      responseData: null,
      icons,
      isMobileAgent: false,
      autoStartToken: null,
      abortController: new AbortController(),
      qr: null,
      qrInterval: null
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    }),

    isInvalid() {
      return (
        this.isAuthenticatingWithQr ||
        this.isAuthenticatingAutostart ||
        this.successVisible
      );
    },

    startUrl() {
      const returnUrl = `${window.location.href}#safari`;

      if (this.isIphoneMode) {
        return `https://app.bankid.com/?autostarttoken=${this.autoStartToken}&redirect=${returnUrl}`;
      }

      return `bankid:///?autostarttoken=${this.autoStartToken}&redirect=null`;
    },

    isIphoneMode() {
      const ua = window.navigator.userAgent;
      const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      const webkit = !!ua.match(/WebKit/i);
      return iOS && webkit && !ua.match(/CriOS/i);
    }
  },

  async created() {
    const config = {
      baseURL: this.baseUrl,
      withCredentials: true,
      'content-Type': 'application/json',
      headers: {
        country: this.$country.currentCountry
      }
    };

    this.isMobileAgent = this.isMobile;
    this.axiosInstance = axios.create(config);

    if (this.isIphoneMode) {
      this.$router.replace(`#safari`);
    }
  },

  methods: {
    async apiCall(path) {
      try {
        const res = await this.axiosInstance.get(`${this.baseUrl}${path}`, {
          signal: this.abortController.signal
        });

        return { status: res.status, data: res.data };
      } catch (error) {
        if (error.response && error.response.status === 0) {
          // Prolly aborted by safari
          return false;
        } else if (
          this.abortController &&
          this.abortController.signal &&
          !this.abortController.signal.aborted
        ) {
          throw error;
        } else {
          return;
        }
      }
    },

    async getQr() {
      const { data } = await this.apiCall(
        this.addToPath(this.qrPath, this.orderRef)
      );

      return data.url;
    },

    async bankidCancel(orderRef) {
      this.qr = null;
      await this.axiosInstance.get(this.addToPath(this.cancelPath, orderRef));
      this.bankidMessage = 'Åtgärden avbruten.';
      this.isAuthenticatingAutostart = false;
      this.isAuthenticatingWithQr = false;
    },

    async getBankIdOrder(qr) {
      this.orderRef = null;
      this.autoStartToken = null;

      let {
        data: { orderRef, autoStartToken }
      } = await this.axiosInstance.get(this.authPath, {
        params: { qr: qr ? 'true' : '' }
      });

      this.orderRef = orderRef;
      this.autoStartToken = autoStartToken;
    },

    async bankidStartQr() {
      try {
        this.qr = null;
        this.bankidMessage = null;
        this.isAuthenticatingWithQr = true;

        await this.getBankIdOrder(true);
        this.collect(true);
      } catch (error) {
        this.handleError(error);
      }
    },

    async bankidAutostart() {
      try {
        this.qr = null;
        this.bankidMessage = null;
        this.isAuthenticatingAutostart = true;

        await this.getBankIdOrder();
        this.collect();

        this.$nextTick(() => {
          window.location.href = this.startUrl;
        });
      } catch (error) {
        this.handleError(error);
      }
    },

    handleSuccess(responseData) {
      this.$emit('animatingSuccess', true);
      this.responseData = responseData;
      this.successVisible = true;

      this.isAuthenticatingAutostart = false;
      this.isAuthenticatingWithQr = false;
      this.orderRef = null;
      this.autoStartToken = null;
    },

    handleError(error) {
      if (isDebug()) {
        console.log(error);
      }

      if (this.isAuthenticatingWithQr || this.isAuthenticatingAutostart) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.readableMessage
        ) {
          this.bankidMessage = error.response.data.readableMessage;
        } else {
          this.bankidMessage = 'Ett oväntat fel inträffade, försök igen.';
        }
      }

      this.isAuthenticatingAutostart = false;
      this.isAuthenticatingWithQr = false;
    },

    addToPath(url, toAdd) {
      if (!url.endsWith('/')) return url + '/' + toAdd;
      return url + toAdd;
    },

    async collect(fetchQr) {
      if (this.responseData) {
        // Ignore if success
        return;
      }

      if (fetchQr || this.qr) {
        this.qr = await this.getQr();
      }

      try {
        const response = await this.apiCall(
          this.addToPath(this.collectPath, this.orderRef)
        );

        if (response.status === 204 || response === false) {
          // Wait between every poll
          await new Promise(resolve => setTimeout(resolve, 1200));
          return await this.collect();
        }

        this.handleSuccess({
          ...response.data,
          statusCode: response.statusCode
        });
      } catch (error) {
        if (
          (error.response && error.response.status === 0) ||
          error.code === 'ECONNABORTED'
        ) {
          return await this.collect();
        }

        this.handleError(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  @media ($mobile) {
    margin-bottom: 40px;
  }
}

.subtitle {
  margin-bottom: 100px;

  @media ($mobile) {
    margin-bottom: 80px;
  }
}

.login-wrapper {
  display: flex;
  flex-direction: column;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bankid-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  position: relative;
  overflow: hidden;
}

.bankid-image {
  height: 48px;
  width: 52px;
}

.bankid-info {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 14px;
  text-align: center;
}

.bankid-selector {
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bankid-outerwrapper {
  width: 100%;
}

.disabled {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}

.bankid-text {
  color: $gray;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.waiting-wrapper {
  min-height: 200px;
  background-color: white;
  color: $gray;
  margin-top: -30px;
  border-radius: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.waiting-spinner {
  height: 50px;
  width: 50px;
}

.field-wrapper {
  margin-bottom: 10px !important;
}

.flex-center {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.text-error {
  color: $sweet-red;
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
}

.autostart-wrapper {
  width: 100%;
}

.full-width {
  width: 100%;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media ($mobile) {
    flex-direction: column-reverse;
  }
}

.authenticating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cancel-auth-button {
  margin-bottom: 30px;
}

.qr {
  margin-bottom: 20px;
  height: 212px;
  width: 212px;
}
</style>
