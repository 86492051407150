<template>
  <div class="modal-container">
    <div class="overlay" @click="$emit('close')"></div>
    <transition name="pop-in" appear>
      <div class="modal">
        <div class="modal-header">
          <div class="modal-title">
            {{ title }}
          </div>
          <div
            v-if="showCloseBtn"
            class="modal-close-button"
            @click="$emit('close')"
          >
            <AImage :url="icons.GENERAL.CLOSE" :classes="['icon-close']" />
          </div>
        </div>
        <div class="modal-body">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import icons from '@/utils/icon-constants';
import AImage from '@/components/atomic/atoms/a-image';

export default {
  name: 'BaseModal',
  components: { AImage },
  props: {
    title: {
      type: String,
      default: ''
    },
    showCloseBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      icons: icons
    };
  }
};
</script>

<style lang="scss" scoped>
.modal-container {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: fixed;
  width: 96%;
  max-width: 400px;
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
  z-index: 3000;
}

.modal-header {
  position: relative;
  padding: 21px;
  border-bottom: 1px solid #e8e8e8;
}

.modal-title {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
}

.modal-close-button {
  position: absolute;
  right: 20px;
  top: calc(50% - 11px);
}

.icon-close {
  width: 22px;
  height: 22px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.modal-body {
  max-height: 90vh;
  padding: 20px 40px;
  overflow-y: auto;

  @media ($desktop) {
    padding: 20px 56px;
  }
}

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
</style>
