<template>
  <div :class="['toggle-wrapper']">
    <button
      :disabled="isLoading"
      :class="['toggle-option', { 'selected-no': interested === false }]"
      @click.stop="() => attemptToggleInterest(false)"
    >
      {{ $t('interest_toggle_no') }}
    </button>
    <button
      :disabled="isLoading"
      :class="['toggle-option', { 'selected-yes': interested === true }]"
      @click.stop="() => attemptToggleInterest(true)"
    >
      <BaseIcon
        :icon-file="interested ? 'icon-check3-white' : 'icon-check3'"
        :width="20"
        :height="20"
        class="button-icon"
      />
      {{ $t('interest_toggle_yes') }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { TUTORIAL_STATUS_TYPES } from '../../store/modules/tutorial';

export default {
  name: 'InterestToggle',

  props: {
    interested: {
      type: Boolean,
      default: null
    },
    toggleInterest: {
      type: Function,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    tutHasMarkedInterest: false,
    TUTORIAL_STATUS_TYPES
  }),

  computed: {
    ...mapGetters({
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryPropositionActive: 'myPropositions/primaryPropositionActive',
      isAuthenticated: 'app/isAuthenticated',
      tutStatus: 'tutorial/status',
      tutInterestMarks: 'tutorial/tutInterestMarks'
    }),
    isTutorialLike() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutInterestMarks < 3
      );
    }
  },

  methods: {
    ...mapActions({
      incrementTutInterestMarks: 'tutorial/incrementTutInterestMarks'
    }),

    attemptToggleInterest(interest) {
      if (this.isTutorialLike && !this.tutHasMarkedInterest) {
        this.incrementTutInterestMarks(1);
        this.tutHasMarkedInterest = true;
      }

      if (!this.isAuthenticated) {
        this.$emit('toggleConversionModal');
        return;
      }

      if (!this.primaryPropositionActive) {
        this.$router.push(
          `${this.$routes.PAYMENT_PROPOSITION}/${this.primaryPropositionId}`
        );
        return;
      }

      if (this.interested !== interest) {
        this.toggleInterest(interest);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$interest-red: #ff3843;
$interest-green: #2ec973;

.toggle-wrapper {
  display: flex;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
}

.toggle-option {
  display: flex;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  background-color: #fff;
  color: $text-primary;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  &:first-of-type {
    border-radius: 5px 0px 0px 5px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: none;
  }

  &:last-of-type {
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #ccc;
  }

  &:disabled {
    cursor: not-allowed;
    color: #435261;
  }
}

.selected-no {
  background-color: $interest-red;
  border-color: $interest-red !important;
  color: #fff;
}

.selected-yes {
  background-color: $interest-green;
  border-color: $interest-green !important;
  color: #fff;
}

.button-icon {
  margin-right: 5px;
}
</style>
