export function isDebug() {
  const subdomains = window.location.hostname.split('.');
  const hasWww = subdomains[0] === 'www';
  const isSubdomain = subdomains.length > 2;

  return (
    (isSubdomain && !hasWww) ||
    window.location.hostname.startsWith('localhost') ||
    process.env.NODE_ENV == 'development'
  );
}
