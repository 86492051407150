<template>
  <div class="my-visitors">
    <h1 class="page-title">{{ $t('my_visitors_title') }}</h1>
    <template v-if="error">
      <p>{{ $t('toast_general_error_message') }}</p>
    </template>
    <template v-else-if="loading">
      <h4>{{ $t('general_loading') }}</h4>
    </template>
    <template v-else-if="displayedVisitors.length">
      <BasePropositionCard
        v-for="visitor in displayedVisitors"
        :key="visitor.propositionId"
        :swap="visitor"
        source="my-visitors"
      >
        {{ $t('my_visitors_last_visit') }}:
        {{
          $dayjs
            .tz(new Date(visitor.lastVisitedDate))
            .format('D MMMM YYYY, HH:mm')
        }}
      </BasePropositionCard>
      <div
        v-if="visitors.length > displayedVisitors.length"
        class="load-more-container"
      >
        <BaseButton @click="handleIncreaseClick">{{
          $t('my_visitors_load_more')
        }}</BaseButton>
      </div>
    </template>
    <template v-else>
      <h4>{{ $t('my_visitors_no_visits') }}</h4>
    </template>
  </div>
</template>

<script>
import { lbPropositionApi } from '@/utils/axiosConfig';

export default {
  name: 'MyVisitors',

  data() {
    return {
      visitors: [],
      visitorsToDisplay: 10,
      displayedVisitors: [],
      error: false,
      loading: false
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('my_visitors_title')
      };
    }
  },

  async created() {
    await this.getVisitors();
    this.addVisitorsToDisplay();
  },

  methods: {
    async getVisitors() {
      try {
        this.loading = true;
        const response = await lbPropositionApi.get(
          `/views/v2/${this.$route.params.propositionId}`
        );

        this.visitors = response.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
    },

    addVisitorsToDisplay() {
      this.displayedVisitors = this.visitors.slice(0, this.visitorsToDisplay);
    },

    handleIncreaseClick() {
      this.visitorsToDisplay += 20;
      this.addVisitorsToDisplay();
    }
  }
};
</script>

<style lang="scss" scoped>
.my-visitors {
  max-width: 1000px;
  padding-left: 16px;
  padding-right: 16px;

  @media ($desktop) {
    padding-left: 0;
    padding-right: 0;
  }
}

.page-title {
  margin-top: 32px;
  margin-bottom: 20px;
  font-size: 26px;

  @media ($desktop) {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 32px;
  }
}

.load-more-container {
  display: grid;
  place-items: center;
  margin-top: 40px;
}
</style>
