import 'sanitize.css';
import Vue from 'vue';
import App from './App';
import logger from './plugins/logger';
import country from './plugins/country/install-vue';
import growthbook from './plugins/growthbook/growthbook-install';
import dayjs from './plugins/dayjs/install';
import { isFeatureEnabled } from '@/plugins/country/module';
import initRouter from './router/router';
import routes from './router/routes.constants';
import store from './store/store';
import i18n, { routeLocale } from '@/i18n';
import VueHead from 'vue-head';
import VueSocialSharing from 'vue-social-sharing';
import './utils/registerBaseComponents';
import { isDebug } from './utils/debug';
import { log } from './utils/logger';

export const router = initRouter();

if (isFeatureEnabled('SITEGAINER')) {
  const sitegainerScript = document.createElement('script');
  sitegainerScript.src = 'https://cdn-sitegainer.com/sitegainer_5619006.js';
  document.head.appendChild(sitegainerScript);
}

// To be able to see trustpilot widgets on release. Prod is handled in GTM.
if (isFeatureEnabled('TRUSTPILOT') && isDebug()) {
  let trustBoxScript = document.createElement('script');
  trustBoxScript.setAttribute(
    'src',
    '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
  );
  document.head.appendChild(trustBoxScript);
}

Vue.use(logger, {
  isDebug: isDebug()
});

Vue.use(country);
Vue.use(dayjs);

Vue.use(VueHead, {
  separator: '-',
  complement: i18n.t('meta_title_complement', routeLocale)
});

Vue.use(VueSocialSharing);
Vue.use(growthbook);

window.isDev = isDebug();

// eslint-disable-next-line
if (isDebug()) console.log('>>> Debug-mode <<<');

Vue.config.productionTip = false;
Vue.prototype.$routes = routes;

Vue.config.errorHandler = error => {
  if (error && error.stack) {
    log(`Unhandled exception: ${error.toString()} ${error.stack.toString()}`);
  } else {
    log(`Unhandled exception: ${error.toString()} `);
  }
};

window.onunhandledrejection = error => {
  if (error.reason == 'Error: Network Error') return;

  log(`Uncaught (in promise): ${error.reason}`);
};

window.onerror = (message, source, lineno, colno, error) => {
  if (error && error.stack) {
    log(`Js error : ${error.toString()} ${error.stack.toString()}`);
  } else if (error) {
    log(`Js error : ${error.toString()} `);
  } else {
    log(`Js error : ${message}`);
  }
};

export const vue = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: createElement => createElement(App)
});
