<template>
  <div class="change-payment">
    <h1 class="title">{{ $t('change_to_klarna_title') }}</h1>

    <p class="text">{{ $t('change_to_klarna_text') }}</p>

    <div id="klarna-container"></div>

    <div class="agreement-checkbox-wrapper">
      <CheckBox
        id="termsCheckbox"
        ref="termsCheckbox"
        type="checkbox"
        :label="$t('change_to_klarna_checkbox_label')"
        :value="agreementChecked"
        :handle-update="handleCheck"
      ></CheckBox>
      <a
        href="https://www.lagenhetsbyte.se/allmanna-villkor"
        rel="noopener noreferrer"
        target="_blank"
        class="terms-link"
      >
        {{ $t('change_to_klarna_terms') }}
      </a>
    </div>

    <div class="button-wrapper">
      <primary-button
        :disabled="!agreementChecked"
        :accent="true"
        :full-width="isMobile"
        @click.native="handleUpdateTokenClick"
      >
        {{ $t('change_to_klarna_action') }}
      </primary-button>
    </div>

    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import CheckBox from '../../components/FormElements/Checkbox/Checkbox';
import PrimaryButton from '../../components/Buttons/Primary';
import Spinner from '../../components/Loading/Spinner';

export default {
  name: 'ChangePaymentMethod',

  components: {
    CheckBox,
    PrimaryButton,
    Spinner
  },
  head: {
    title() {
      return {
        inner: this.$t('change_to_klarna_title')
      };
    }
  },
  computed: {
    ...mapGetters({
      agreementChecked: 'klarnaPayments/getAgreementChecked',
      isMobile: 'screenSize/isMobile',
      isLoading: 'klarnaPayments/isCreatingPayment',
      currentSubscription: 'handleSubscription/currentSubscription',
      currentProposition: 'handleSubscription/currentProposition',
      user: 'app/user'
    })
  },
  mounted() {
    if (
      !this.currentSubscription.productId ||
      !this.currentProposition.propositionId
    ) {
      this.$router.replace(this.$t('path_my_propositions'));
      return;
    }

    this.loadKlarnaScript();

    this.attemptInitializeKlarnaWidget();
  },
  methods: {
    ...mapActions({
      loadKlarnaScript: 'klarnaPayments/loadKlarnaScript',
      setAgreementChecked: 'klarnaPayments/setAgreementChecked',
      buttonsEnabled: 'klarnaPayments/getButtonsEnabled',
      errorMessage: 'klarnaPayments/errorMessage',
      initializeKlarnaWidget: 'klarnaPayments/initializeKlarnaWidget',
      authorizeSession: 'klarnaPayments/authorizeSession'
    }),

    handleCheck(checked) {
      this.setAgreementChecked({ checked });
    },

    handleUpdateTokenClick: function () {
      this.authorizeSession({ renewSubscription: true });
    },

    attemptInitializeKlarnaWidget: function () {
      this.initializeKlarnaWidget();
    }
  }
};
</script>

<style lang="scss" scoped>
.change-payment {
  margin: 0 auto;
  max-width: 600px;
  padding-top: 180px;

  @media ($mobile) {
    padding: 20px;
    padding-bottom: 120px;
  }
}

.title {
  @media ($mobile) {
    font-size: 1.5rem;
    max-width: 80%;
    margin: 0 auto 15px auto;
    text-align: center;
  }
}

.agreement-checkbox-wrapper {
  padding-top: 10px;
  display: flex;
}

.terms-link {
  margin-top: 3.5px;
  margin-left: 3px;
}

.button-wrapper {
  display: inline-block;
  margin: 40px auto;

  @media ($mobile) {
    display: flex;
  }
}

.text {
  font-weight: 600;
  text-align: center;
  line-height: 150%;
  margin: 20px 0;

  &:last-of-type {
    margin-bottom: 40px;
  }

  @media ($desktop) {
    text-align: left;
  }
}
</style>
