<template>
  <div ref="paginationWrapper" class="pagination-wrapper">
    <div class="pagination-navigation-wrapper">
      <button :class="['next-page-btn', { centered: true }]">
        <BaseSpinner v-if="isLoading" class="spinner-loader" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InfiniteScrollPagination',
  props: {
    onPageClick: {
      type: Function,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      if (this.isLoading) {
        return;
      }

      const rect = this.$refs.paginationWrapper.getBoundingClientRect();
      const inView =
        rect.top <
        (window.innerHeight || document.documentElement.clientHeight);

      if (inView) {
        this.onPageClick();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 40px;
}

.pagination-navigation-wrapper {
  display: flex;

  @media ($desktop) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  @media ($mobile) {
    flex-direction: column;
    align-items: center;
  }
}

.next-page-btn {
  background-color: #fff;
  -webkit-appearance: none;
  border-radius: 6px;
  border: 1px solid rgba(135, 139, 157, 0.5);
  box-sizing: border-box;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  color: $text-primary;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;

  .spinner-loader {
    border-color: rgba(135, 139, 157, 0.5) !important;
    border-top-color: white !important;
    height: 20px !important;
    width: 20px !important;
  }

  &.centered {
    margin: 0 auto;
  }
}

.page-indicator {
  padding: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
