import { lbAws, paymentApi } from '../../../utils/axiosConfig';
import { getCookies } from '../../../utils/helpers/cookie';

async function fetchTokenExists() {
  const response = await lbAws.get(`/klarna-query/has-token`);

  if (response.status === 200) {
    const { data } = response.data;
    return data;
  }

  return false;
}

async function createSession() {
  const response = await lbAws.post(`/klarna-commands/create-session`, {});

  const { data } = response;
  return data;
}

async function createPropositionPayment(propositionId, productId) {
  const asclid = getCookies().asclid || null;

  const response = await lbAws.post(
    `/lord-commander/proposition/payment/create`,
    {
      propositionId,
      productId,
      asclid
    }
  );

  const { data } = response;
  return data;
}

async function createFirstTimePropositionPayment(
  propositionId,
  productId,
  sessionId,
  authToken
) {
  const asclid = getCookies().asclid || null;

  const response = await lbAws.post(
    `/lord-commander/proposition/payment/create-first-time`,
    {
      propositionId,
      productId,
      authToken,
      sessionId,
      asclid
    }
  );

  const { data } = response;
  return data;
}

async function createVisibilityPayment({ propositionId, productId }) {
  const response = await lbAws.post('lord-commander/extra-visibility/order', {
    productId,
    propositionId
  });

  const { data } = response;

  return data;
}

async function createFirstTimeVisibilityPayment({
  propositionId,
  productId,
  sessionId,
  authToken
}) {
  const response = await lbAws.post(
    'lord-commander/extra-visibility/order-first-time',
    {
      productId,
      propositionId,
      sessionId,
      authToken
    }
  );

  const { data } = response;

  return data;
}

async function getPropositionProducts(propositionId, requestReason = '') {
  const { data } = await paymentApi.get(`/products/${propositionId}`, {
    headers: {
      requestReason // For tracking
    }
  });

  if (!data) {
    return [];
  }

  return data.map(p => {
    if (p.subscriptionProduct) {
      p.subscriptionProduct = productAdapter(p.subscriptionProduct);
    }

    return productAdapter(p);
  });
}

function productAdapter(p) {
  return {
    ...p,
    priceInclVat: p.priceWithVAT,
    id: p.productId
  };
}

async function getProducts(category) {
  try {
    const { data } = await paymentApi.get('/products', {
      params: { category: category }
    });

    return data.map(p => ({ ...p, productId: p.id }));
  } catch (error) {
    window.Logger.log(error);
  }
}

async function createNewCustomerToken({ authToken, sessionId }) {
  const response = await lbAws.post('klarna-commands/create-customer-token', {
    sessionId,
    authToken
  });

  return response;
}

async function updateSessionProduct({ sessionId, product }) {
  const response = await lbAws.post('klarna-commands/update-session', {
    sessionId,
    product
  });

  return response;
}

export default {
  createFirstTimePropositionPayment,
  createPropositionPayment,
  fetchTokenExists,
  createSession,
  getProducts,
  getPropositionProducts,
  createVisibilityPayment,
  createFirstTimeVisibilityPayment,
  createNewCustomerToken,
  updateSessionProduct
};
