var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('section',{staticClass:"login-wrapper"},[(_vm.isResetPasswordVisible)?_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content-header"},[_c('h2',{staticClass:"content-title"},[_vm._v(" "+_vm._s(_vm.$t('login_reset_new_password_title'))+" ")])]),(!_vm.resetPasswordSuccess)?[_c('form',{staticClass:"form-wrapper",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.resetPassword()}}},[_c('BaseInput',{attrs:{"name":"resetPasswordFirst","type":"password","icon":"lock","has-show-password":true,"disabled":_vm.isLoading,"label":_vm.$t('login_form_password'),"placeholder":_vm.$t('login_form_password')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('BaseInput',{attrs:{"name":"resetPasswordSecond","type":"password","icon":"lock","has-show-password":true,"disabled":_vm.isLoading,"label":_vm.$t('login_form_password_repeat'),"placeholder":_vm.$t('login_form_password_repeat')},model:{value:(_vm.passwordRepeat),callback:function ($$v) {_vm.passwordRepeat=$$v},expression:"passwordRepeat"}}),_c('input',{attrs:{"type":"submit","hidden":""}}),_c('p',{staticClass:"message"},[_vm._v(_vm._s(_vm.errorMessage))]),_c('div',{staticClass:"u-center-items"},[_c('BaseButton',{staticClass:"action-button",attrs:{"type":"submit","disabled":_vm.isLoading || !_vm.password || !_vm.passwordRepeat,"is-loading":_vm.isLoading}},[_vm._v(_vm._s(_vm.$t('login_reset_action')))])],1)],1)]:[_c('p',[_vm._v(" "+_vm._s(_vm.$t('login_reset_new_password_success'))+" ")])],_c('p',{staticClass:"secondary-action"},[_c('router-link',{staticClass:"simple-button",attrs:{"to":_vm.$t('path_login', _vm.$routeLocale)},on:{"click":function($event){_vm.isResetPasswordEmailVisible = false;
            _vm.successVisible = false;
            _vm.resetPasswordEmailSent = false;}}},[_vm._v(" "+_vm._s(_vm.$t('login_switch_to_login_action'))+" ")])],1)],2):(_vm.isResetPasswordEmailVisible)?_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content-header"},[_c('h2',{staticClass:"content-title"},[_vm._v(_vm._s(_vm.$t('login_reset_title')))]),(!_vm.resetPasswordEmailSent)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('login_reset_info'))+" ")]):_vm._e()]),(!_vm.resetPasswordEmailSent)?[_c('form',{staticClass:"form-wrapper",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.sendResetLink()}}},[_c('BaseInput',{attrs:{"id":"resetEmail","name":"resetEmail","type":"email","icon":"envelope","disabled":_vm.isLoading,"label":_vm.$t('login_form_username'),"placeholder":_vm.$t('login_form_username'),"autofocus":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('input',{attrs:{"type":"submit","hidden":""}}),_c('p',{staticClass:"message"},[_vm._v(_vm._s(_vm.errorMessage))]),_c('div',{staticClass:"u-center-items"},[_c('BaseButton',{staticClass:"action-button",attrs:{"type":"submit","disabled":_vm.isLoading || !_vm.username,"is-loading":_vm.isLoading}},[_vm._v(_vm._s(_vm.$t('login_reset_action')))])],1)],1)]:[_c('div',{staticClass:"body-text"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('login_reset_success_info'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('login_reset_success_note'))+" ")])])],_c('div',{staticClass:"u-center-items"},[_c('p',{staticClass:"secondary-action"},[_vm._v(" "+_vm._s(_vm.$t('login_go_back_to'))+" "),_c('button',{staticClass:"simple-button",on:{"click":function($event){_vm.isResetPasswordEmailVisible = false;
              _vm.errorMessage = '';
              _vm.resetPasswordEmailSent = false;
              _vm.routeToLogin();}}},[_vm._v(" "+_vm._s(_vm.$t('login_switch_to_login_action'))+" ")])])])],2):(_vm.$country.isFeatureEnabled('BANKID') && _vm.isBankIdVisible)?_c('div',{staticClass:"bankid-form"},[_c('BankId',{staticClass:"non-modal",attrs:{"collect-path":'/auth/bankid',"auth-path":'/bankid/start',"qr-path":'/bankid/qr',"cancel-path":'/bankid/cancel',"base-url":_vm.baseUrl},on:{"success":function($event){return _vm.handleLoginSuccess($event)},"animatingSuccess":function($event){_vm.animatingSuccess = true}}}),(!_vm.animatingSuccess)?_c('div',{staticClass:"u-center-items"},[_c('p',{staticClass:"secondary-action"},[_vm._v(" "+_vm._s(_vm.$t('login_go_back_to'))+" "),(_vm.$country.isFeatureEnabled('BANKID'))?_c('button',{staticClass:"simple-button",on:{"click":function($event){_vm.isBankIdVisible = !_vm.isBankIdVisible;
              _vm.errorMessage = '';}}},[_vm._v(" "+_vm._s(_vm.$t('login_switch_to_login_password_action'))+" ")]):_vm._e()])]):_vm._e()],1):(!_vm.isBankIdVisible || !_vm.$country.isFeatureEnabled('BANKID'))?_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content-header"},[_c('h2',{staticClass:"content-title"},[_vm._v(_vm._s(_vm.$t('login_title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('login_subtitle')))])]),_c('div',{staticClass:"button-wrapper"},[_c('div',{staticClass:"button-container"},[_c('FacebookLoginButton',{attrs:{"on-begin-authorization":_vm.handleBeginAuthorization,"on-authorization-failed":_vm.handleSSOLoginError,"on-authorization-successful":_vm.handleLoginSuccess}}),_c('GoogleLoginButton',{attrs:{"on-begin-authorization":_vm.handleBeginAuthorization,"on-authorization-failed":_vm.handleSSOLoginError,"on-authorization-successful":_vm.handleLoginSuccess}}),_c('AppleLoginButton',{attrs:{"on-begin-authorization":_vm.handleBeginAuthorization,"on-authorization-failed":_vm.handleSSOLoginError,"on-authorization-successful":_vm.handleLoginSuccess}})],1)]),_c('div',{staticClass:"or-delimiter"},[_c('span',[_vm._v(_vm._s(_vm.$t('login_or_delimiter')))])]),_c('form',{staticClass:"form-wrapper bankid-form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.login()}}},[_c('div',{staticClass:"input-container"},[_c('BaseInput',{attrs:{"name":"loginEmailInput","type":"email","icon":"envelope","disabled":_vm.isLoading,"has-show-password":false,"label":_vm.$t('login_form_username'),"placeholder":_vm.$t('login_form_username')},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('div',{staticClass:"input-container"},[_c('div',{staticClass:"simple-button forgot-pw",attrs:{"tabindex":"-1"},on:{"click":function($event){_vm.isResetPasswordEmailVisible = true;
              _vm.errorMessage = '';
              _vm.routeToPasswordReset();}}},[_vm._v(" "+_vm._s(_vm.$t('login_switch_to_reset_action'))+" ")]),_c('BaseInput',{attrs:{"name":"passwordLogin","type":"password","icon":"lock","has-show-password":true,"disabled":_vm.isLoading,"label":_vm.$t('login_form_password'),"placeholder":_vm.$t('login_form_password')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('input',{attrs:{"type":"submit","hidden":""}}),_c('p',{staticClass:"message"},[_vm._v(_vm._s(_vm.errorMessage))]),_c('div',{staticClass:"u-center-items"},[_c('BaseButton',{staticClass:"action-button",attrs:{"type":"submit","disabled":_vm.isLoading,"is-loading":_vm.isLoading}},[_vm._v(_vm._s(_vm.$t('login_password_action')))])],1)]),_c('div',{staticClass:"u-center-items"},[(_vm.$country.isFeatureEnabled('BANKID'))?_c('p',{staticClass:"secondary-action"},[_vm._v(" Har du verifierat kontot? "),_c('button',{staticClass:"simple-button",on:{"click":function($event){_vm.isBankIdVisible = !_vm.isBankIdVisible;
              _vm.errorMessage = '';}}},[_vm._v(" "+_vm._s(_vm.$t('login_switch_to_login_bankid_action'))+" ")])]):_vm._e(),_c('p',{staticClass:"secondary-action"},[_vm._v(" "+_vm._s(_vm.$t('login_no_account'))+" "),_c('a',{staticClass:"simple-button",attrs:{"href":_vm.$t('path_add_proposition', _vm.$routeLocale)}},[_vm._v(_vm._s(_vm.$t('login_register_here')))])])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }