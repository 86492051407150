<template>
  <div
    ref="avatar"
    :class="['avatar', { 'box-shadow': boxShadow }]"
    @click="handleClick"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MenuAvatar',
  props: {
    avatar: {
      type: String,
      default: ''
    },
    boxShadow: {
      type: Boolean,
      default: true
    },
    handleClick: {
      type: Function,
      default: () => {}
    },
    size: {
      type: Number,
      default: 24
    }
  },
  watch: {
    avatar() {
      this.setAvatar();
    }
  },
  mounted() {
    const { avatar } = this.$refs;
    this.setAvatar();
    avatar.style.height = `${this.size}px`;
    avatar.style.width = `${this.size}px`;
  },
  methods: {
    setAvatar() {
      const { avatar } = this.$refs;
      if (!this.avatar) return;
      avatar.style.backgroundImage = `url(${this.avatar})`;
      avatar.style.backgroundColor = 'transparent';
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  background-color: $bg-gray;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
  position: relative;

  &.box-shadow {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05);
  }
}
</style>
