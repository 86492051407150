<template>
  <article v-if="filter" class="wrapper" :class="{ toned: toned }">
    <section class="inner-wrapper">
      <div class="list-content">
        <div class="title">
          {{
            isTypeResidence
              ? $t('search_list_item_i_search_for_short')
              : $t('search_list_item_others_search_for_short')
          }}
        </div>

        <div class="mgt-10">
          <span v-if="filter.rooms">
            <span class="text-light">{{
              $t('search_list_item_at_least_room')
            }}</span>

            <span v-if="rooms">
              <strong
                >{{ rooms }}{{ $t('search_list_item_room_prefix') }}</strong
              >
            </span>
            <span v-else>
              <strong
                >{{ filter.rooms.min
                }}{{ $t('search_list_item_room_prefix') }} -
                {{ filter.rooms.max
                }}{{ $t('search_list_item_room_prefix') }}</strong
              >
            </span>
          </span>

          <span v-if="filter.sqm">
            <span class="text-light">, </span>
            <span v-html="sqmHtml"></span>
          </span>

          <span v-if="filter.rent">
            <span class="text-light"> {{ $t('general_for') }} </span>
            <span v-html="rentHtml"></span>
          </span>
        </div>

        <div v-if="hasAmenities" class="mgt-10">
          <span class="text-light">
            {{ $t('search_coverage_must_include') }}
          </span>
          <strong>
            <span v-html="amenities"></span>
          </strong>
        </div>

        <div class="mgt-10 text-small">
          <span class="capitalize">
            <strong>
              {{ geolocationValue }}
            </strong>
          </span>
        </div>
      </div>

      <section class="buttons">
        <div class="center">
          <div class="clickable" @click="handleSearch(item)">
            <svg
              class=""
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="30" cy="30" r="30" fill="#409FFF" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M34.3257 32.8987L39.7057 38.2787C39.8948 38.468 40.001 38.7246 40.0009 38.9921C40.0008 39.2596 39.8945 39.5161 39.7052 39.7052C39.516 39.8943 39.2594 40.0005 38.9919 40.0004C38.7244 40.0003 38.4678 39.894 38.2787 39.7047L32.8987 34.3247C31.2905 35.5704 29.268 36.1566 27.2429 35.9641C25.2177 35.7716 23.342 34.8148 21.9972 33.2884C20.6525 31.7619 19.9397 29.7806 20.004 27.7473C20.0683 25.714 20.9047 23.7816 22.3431 22.3431C23.7816 20.9047 25.714 20.0683 27.7473 20.004C29.7806 19.9397 31.7619 20.6525 33.2884 21.9972C34.8148 23.342 35.7716 25.2177 35.9641 27.2429C36.1566 29.268 35.5704 31.2905 34.3247 32.8987H34.3257ZM28.0007 33.9997C29.592 33.9997 31.1182 33.3676 32.2434 32.2424C33.3686 31.1172 34.0007 29.591 34.0007 27.9997C34.0007 26.4084 33.3686 24.8823 32.2434 23.7571C31.1182 22.6319 29.592 21.9997 28.0007 21.9997C26.4094 21.9997 24.8833 22.6319 23.7581 23.7571C22.6329 24.8823 22.0007 26.4084 22.0007 27.9997C22.0007 29.591 22.6329 31.1172 23.7581 32.2424C24.8833 33.3676 26.4094 33.9997 28.0007 33.9997Z"
                fill="white"
              />
            </svg>
          </div>

          <div
            class="clickable icon-padding"
            :class="{ gray: menuVisible }"
            @click.stop="menuVisible = !menuVisible"
          >
            <svg
              width="4"
              height="16"
              viewBox="0 0 4 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 10C1.46957 10 0.96086 9.78929 0.585787 9.41421C0.210714 9.03914 0 8.53043 0 8C0 7.46957 0.210714 6.96086 0.585787 6.58579C0.96086 6.21071 1.46957 6 2 6C2.53043 6 3.03914 6.21071 3.41421 6.58579C3.78929 6.96086 4 7.46957 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10ZM2 4C1.46957 4 0.96086 3.78929 0.585787 3.41421C0.210714 3.03914 0 2.53043 0 2C0 1.46957 0.210714 0.960859 0.585787 0.585786C0.96086 0.210714 1.46957 0 2 0C2.53043 0 3.03914 0.210714 3.41421 0.585786C3.78929 0.960859 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4ZM2 16C1.46957 16 0.96086 15.7893 0.585787 15.4142C0.210714 15.0391 0 14.5304 0 14C0 13.4696 0.210714 12.9609 0.585787 12.5858C0.96086 12.2107 1.46957 12 2 12C2.53043 12 3.03914 12.2107 3.41421 12.5858C3.78929 12.9609 4 13.4696 4 14C4 14.5304 3.78929 15.0391 3.41421 15.4142C3.03914 15.7893 2.53043 16 2 16Z"
                :fill="menuVisible ? '#878b9d' : '#2C3E50'"
              />
            </svg>
          </div>
          <hr />
        </div>
      </section>
    </section>
    <MAnimatedCheckbox
      v-if="menuVisible"
      class="menu-wrapper"
      :show-animation="false"
      @close="menuVisible = false"
    >
      <ul class="menu">
        <li class="danger" @click.stop.prevent="$emit('delete', item)">
          <div v-if="deleting" class="spinner">
            <ASpinner />
          </div>
          <span v-else>{{ $t('search_coverage_remove') }}</span>
        </li>
        <li @click="handleSearch(item)">
          {{ $t('search_coverage_search') }}
        </li>
        <li
          @click="
            menuVisible = false;
            $emit('close', item);
          "
        >
          {{ $t('search_coverage_close') }}
        </li>
      </ul>
    </MAnimatedCheckbox>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import icons from '@/utils/icon-constants';
import { formatCurrency } from '@/filters/currency';
import { searchTypes } from '@/models/search-filter';
import sliderConstants from '@/utils/values/slider-filter-constants';
import MAnimatedCheckbox from '@/components/atomic/molecules/m-animated-checkbox-box';
import ASpinner from '@/components/atomic/atoms/a-spinner';
import { convertToSearchFilter } from '@/models/search-filter';

export default {
  name: 'MSearchCoveregeListItem',

  components: {
    ASpinner,
    MAnimatedCheckbox
  },

  filters: {
    formatCurrency
  },

  props: {
    item: {
      type: Object,
      required: false,
      default: () => {}
    },
    toned: {
      type: Boolean,
      default: false
    },
    deleting: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      searchTypes,
      icons: icons,
      menuVisible: false,
      sliderConstants
    };
  },

  computed: {
    sliderDefaults() {
      return sliderConstants.DEFAULT[
        this.isTypeResidence
          ? this.searchTypes.RESIDENCE.toUpperCase()
          : this.searchTypes.WISH.toUpperCase()
      ];
    },

    sqmHtml() {
      const input = this.filter.sqm;
      let html = '';

      if (typeof input === 'object' && input.min && input.max === null)
        html += `<span><strong>${input.min}</strong></span>`;
      else if (
        typeof input === 'object' &&
        input.min !== null &&
        input.max !== null
      ) {
        let maxValue = input.max;

        if (input.max === this.sliderDefaults.SQM.MAX)
          maxValue = this.$t('general_max');

        html += `<span class="text-light">${this.$t(
          'general_between'
        )}</span> <span><strong>${input.min} - ${maxValue}</strong></span>`;
      } else if (typeof input === 'number')
        html += `<span><strong>${input}</strong></span>`;

      html += `<strong><span> m<sup> 2</sup></span></strong>`;

      return html;
    },

    rentHtml() {
      const input = this.filter.rent;
      let html = '';

      if (typeof input === 'object' && input.min && input.max === null)
        html += `<span><strong>${this.formatCurrency(
          input.min,
          false
        )}</strong></span>`;
      else if (
        typeof input === 'object' &&
        input.min !== null &&
        input.max !== null
      ) {
        let maxValue = this.formatCurrency(input.max, false);

        if (input.max === this.sliderDefaults.RENT.MAX)
          maxValue = this.$t('general_max');

        html += `<span class="text-light">${this.$t(
          'general_between'
        )}</span> <span><strong>${this.formatCurrency(
          input.min,
          false
        )} - ${maxValue}</strong></span>`;
      } else if (typeof input === 'number')
        html += `<span><strong>${this.formatCurrency(
          input,
          false
        )}</strong></span>`;

      html += `<strong><span> ${this.$t(
        'search_filters_cost_per_month'
      )}</span></strong>`;

      return html;
    },

    geolocationValue() {
      if (this.isTypeResidence && this.filter.geolocations.length > 0)
        return this.filter.geolocations.map(x => x.name).join(', ');
      else if (!this.isTypeResidence && this.filter.geolocationsSource)
        return this.filter.geolocationsSource;
      else if (this.filter.freetext)
        return `${this.$t('search_coverage_freetext_search')} ${
          this.filter.freetext
        }`;
      else if (this.rootFilter.searchArea)
        return this.$t('general_map_territory');

      return '';
    },

    isTypeResidence() {
      return !!this.item.body[this.searchTypes.RESIDENCE];
    },

    activeAmenitiesKeys() {
      return Object.keys(this.filter).filter(
        x =>
          (x.startsWith('has') || x.startsWith('is')) && this.filter[x] === true
      );
    },

    hasAmenities() {
      return this.activeAmenitiesKeys.length > 0;
    },

    amenities() {
      const strings = [];

      if (this.filter.hasImage)
        strings.push(this.$t('search_filters_choice_4_short').toLowerCase());
      if (this.filter.isBecomingCondo)
        strings.push(this.$t('search_filters_choice_9').toLowerCase());
      if (this.filter.isCondo)
        strings.push(this.$t('wishes_is_condo').toLowerCase());
      if (this.filter.hasElevator)
        strings.push(this.$t('search_filters_choice_5_short').toLowerCase());
      if (this.filter.hasFireplace)
        strings.push(this.$t('search_filters_choice_13_short').toLowerCase());
      if (this.filter.hasHasBathtub)
        strings.push(this.$t('search_filters_choice_14_short').toLowerCase());
      if (this.filter.hasBalcony)
        strings.push(this.$t('search_filters_choice_6_short').toLowerCase());

      if (strings.length > 1) {
        const firstPart = strings
          .slice(0, -1)
          .join('<span class="text-light">, </span>');

        const lastPart = `<span class="text-light"> ${this.$t(
          'general_and'
        )} </span> ${
          strings[strings.length - 1]
        }<span class="text-light">.</span>`;

        return firstPart + lastPart;
      }

      return strings.join() + '<span class="text-light">.</span>';
    },

    rooms() {
      let rooms = 0;

      if (typeof this.filter.rooms === 'number') rooms = this.filter.rooms;
      else if (
        typeof this.filter.rooms === 'object' &&
        this.filter.rooms.max === null
      )
        rooms = this.filter.rooms.min;
      else return null;

      if (rooms === 0) return 1;
      return rooms;
    },

    rootFilter() {
      return convertToSearchFilter(this.item);
    },

    filter() {
      return this.rootFilter.filter;
    },

    date() {
      return new Date(this.item.createdAt).toLocaleDateString(
        this.$country.getLanguageFromCountry()
      );
    },

    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  },

  methods: {
    formatCurrency,

    handleSearch(item) {
      this.$emit('search', item);
    },
    ...mapActions({})
  }
};
</script>

<style lang="scss" scoped>
.danger {
  color: $sweet-red;
}

.menu {
  width: 170px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu > * {
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b3b3b3;
  border-radius: 8px;
  margin-bottom: 6px;
}

.menu > *:hover {
  @media ($desktop), ($small-desktop) {
    background-color: #e5e5e5a1;
    border-radius: 5px;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toned {
  background-color: #f8f8fa;
}

section {
  margin: 0;
  padding: 0;
}

.inner-wrapper {
  display: flex;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid $info-gray;
}

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 20px;
  font-size: 14px;

  @media ($desktop) {
    min-height: 90px;
  }
}

.text-small {
  font-size: 12px;
}

.list-content-end {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}

.text-light {
  font-style: normal;
  font-weight: normal;
  color: #878b9d;
}

.mgt-4 {
  margin-top: 4px;
}

.mgt-10 {
  margin-top: 10px;
}

.mgr-7 {
  margin-right: 7px;
}

.mgr-24 {
  margin-right: 24px;
}

.icon-padding {
  padding: 12px;

  @media ($mobile) {
    margin-left: 6px;
  }

  @media ($desktop) {
    margin-left: 12px;
  }
}

.full-width {
  width: 100%;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ($desktop) {
    width: 20%;
  }

  @media ($mobile) {
    margin-left: 5px;
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
}

#spinner {
  border-color: rgba(45, 45, 45, 0.3) !important;
  border-top-color: white !important;
}

.menu-wrapper {
  position: fixed;
  z-index: 99;
  width: 200px;
  top: calc(50% - 50px);
  left: calc(50% - 100px);
}
</style>

<style>
.text-light {
  font-style: normal;
  font-weight: normal;
  color: #878b9d;
}
</style>
