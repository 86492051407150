<template>
  <div class="pagination-wrapper">
    <div class="title">
      {{ $t('flow_pagination_showing') }}
      <span class="bold">{{ currentMin }}</span>
      {{ $t('flow_pagination_to') }}
      <span class="bold">{{ currentMax }}</span>
      {{ $t('flow_pagination_of') }}
      <span class="bold">{{ itemCount }}</span>
    </div>
    <div class="progress-bar">
      <div ref="fill" class="progress-fill"></div>
    </div>

    <div class="pagination-navigation-wrapper">
      <div v-if="!isMobile" ref="pageSizePicker" class="page-picker-wrapper">
        <div class="page-indicator" @click="openPageSizePicker">
          {{ currentPageSize }} {{ $t('flow_pagination_per_page') }}
          <div class="arrow-icon"></div>
        </div>
        <div
          v-if="pageSizePickerOpen"
          id="js-page-size-selector"
          :class="['page-selector']"
        >
          <div ref="pageSizeSelector" class="page-wrapper">
            <div
              v-for="alternative in pageSizes"
              :key="alternative"
              class="page"
              @click="onSelectPageSize(alternative)"
            >
              {{ alternative }}
            </div>
          </div>
        </div>
      </div>

      <button
        :class="[
          'next-page-btn',
          { disabled: currentPage === maxPage, centered: false }
        ]"
        :disabled="currentPage === maxPage"
        @click="handleNextPageClick"
      >
        <BaseSpinner v-if="isLoading" class="spinner-loader" />
        <template v-else>{{
          currentPage === maxPage
            ? $t('flow_pagination_you_last_page')
            : $t('flow_pagination_show_more_suggestions')
        }}</template>
      </button>

      <div ref="pagePicker" class="page-picker-wrapper">
        <div
          :class="['arrow-down-wrapper', { disabled: currentPage === 1 }]"
          @click="handleArrowClick('down')"
        >
          <div class="arrow-down"></div>
        </div>

        <div class="page-indicator" @click="openPagePicker">
          {{ currentPage }} {{ $t('flow_pagination_of') }} {{ maxPage }}
          <div class="arrow-icon"></div>
        </div>

        <div
          :class="['arrow-up-wrapper', { disabled: currentPage === maxPage }]"
          @click="handleArrowClick('up')"
        >
          <div class="arrow-up"></div>
        </div>

        <div
          v-if="pagePickerOpen"
          id="js-page-selector"
          :class="['page-selector', { 'needs-scroll': maxPage > 6 }]"
        >
          <div
            v-if="maxPage > 6"
            class="selector-scroll up"
            @click.stop="scrollSelector('up')"
          ></div>
          <div
            v-if="maxPage > 6"
            class="selector-scroll down"
            @click.stop="scrollSelector('down')"
          ></div>
          <div ref="pageSelector" class="page-wrapper">
            <div
              v-for="page in maxPage"
              :key="page"
              class="page"
              @click="goToPage(page)"
            >
              {{ $t('flow_pagination_page') }} {{ page }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="isMobile"
        ref="pageSizePicker"
        class="page-picker-wrapper page-size"
      >
        <div class="page-indicator" @click="openPageSizePicker">
          {{ currentPageSize }} {{ $t('flow_pagination_per_page') }}
          <div class="arrow-icon"></div>
        </div>
        <div
          v-if="pageSizePickerOpen"
          id="js-page-size-selector"
          :class="['page-selector']"
        >
          <div ref="pageSizeSelector" class="page-wrapper">
            <div
              v-for="alternative in pageSizes"
              :key="alternative"
              class="page"
              @click="onSelectPageSize(alternative)"
            >
              {{ alternative }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import handleClickoutside from '../../utils/handleClickOutside';

export default {
  name: 'Pagination',

  props: {
    maxPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    currentPageSize: {
      type: Number,
      required: true
    },
    itemCount: {
      type: Number,
      required: true
    },
    onPageClick: {
      type: Function,
      required: true
    },
    onSelectPageSize: {
      type: Function,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      pagePickerOpen: false,
      pageSizePickerOpen: false,
      pageSizes: [25, 50, 75, 100]
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    }),

    renderedPages() {
      const { currentPage, maxPage } = this;
      const renderedPages = [];

      let startLoop = currentPage - 2;
      let stopLoop = currentPage + 2;

      // Don't go over max page
      if (stopLoop > maxPage) {
        const diff = stopLoop - maxPage;
        stopLoop = maxPage;
        startLoop = startLoop - diff;
      }

      for (let i = startLoop; i <= stopLoop; i++) {
        // dont go under first page
        if (i < 1) {
          stopLoop++;
        } else {
          // Dont go over max page
          if (i <= maxPage) {
            renderedPages.push(i);
          }
        }
      }
      return renderedPages;
    },

    currentMin() {
      const { currentPage, currentPageSize } = this;
      return (currentPage - 1) * currentPageSize + 1;
    },
    currentMax() {
      const { currentPage, currentPageSize, itemCount } = this;
      const max = currentPage * currentPageSize;
      if (max > itemCount) return itemCount;
      return max;
    }
  },

  watch: {
    currentPage() {
      this.updateFillProgress();
    }
  },

  mounted() {
    this.updateFillProgress();

    document.addEventListener('mouseup', this.closeOnClickOutside);
  },

  beforeUpdate() {
    this.updateFillProgress();
  },

  beforeDestroy() {
    document.removeEventListener('mouseup', this.closeOnClickOutside);
  },

  methods: {
    ...mapActions({
      setGlobalModal: 'ui/setGlobalModal'
    }),

    handleNextPageClick() {
      this.handleArrowClick('up');
    },

    scrollSelector(direction) {
      const { pageSelector } = this.$refs;
      const { scrollTop } = pageSelector;

      if (direction === 'up') {
        pageSelector.scrollTop = `${scrollTop - 72}`;
      } else {
        pageSelector.scrollTop = `${scrollTop + 72}`;
      }
    },

    handleArrowClick(direction) {
      if (direction === 'down') {
        this.onPageClick(this.currentPage - 1);
      } else {
        this.onPageClick(this.currentPage + 1);
      }
    },

    goToPage(page) {
      if (page !== this.currentPage) {
        this.onPageClick(page);
      }
    },

    updateFillProgress() {
      const { currentPage, maxPage } = this;
      const { fill } = this.$refs;
      if (!fill) {
        return;
      }

      const progress = Math.round((currentPage / maxPage) * 100);
      fill.style.width = `${progress}%`;
    },

    openPagePicker() {
      this.pagePickerOpen = true;
    },
    closePickers() {
      this.pagePickerOpen = false;
      this.pageSizePickerOpen = false;
    },
    openPageSizePicker() {
      this.pageSizePickerOpen = true;
    },

    closeOnClickOutside(e) {
      const pageSelector = document.getElementById('js-page-selector');
      const pageSizeSelector = document.getElementById('js-page-size-selector');
      handleClickoutside({
        event: e,
        elements: [pageSelector, pageSizeSelector],
        action: () => this.closePickers()
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 40px;
}

.bold {
  font-weight: 700;
}

.progress {
  &-bar {
    margin: 30px 0;
    width: 80%;
    height: 10px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &-fill {
    height: 100%;
    width: 30%;
    border-radius: 12px;
    background-color: $transfer-blue;
  }
}

.pagination-navigation-wrapper {
  display: flex;

  @media ($desktop) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  @media ($mobile) {
    flex-direction: column;
    align-items: center;
  }
}

.flexer {
  width: 125px;
}

.next-page-btn {
  background-color: #fff;
  -webkit-appearance: none;
  border-radius: 6px;
  border: 1px solid rgba(135, 139, 157, 0.5);
  box-sizing: border-box;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  color: $text-primary;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  margin: auto;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    outline: none;
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &.centered {
    margin: 0 auto;
  }

  .spinner-loader {
    border-color: rgba(135, 139, 157, 0.5) !important;
    border-top-color: white !important;
    height: 20px !important;
    width: 20px !important;
  }
}

.page-picker-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(135, 139, 157, 0.5);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  height: 40px;

  @media ($mobile) {
    margin-top: 30px;
  }

  &.page-size {
    @media ($mobile) {
      background-color: rgba(0, 0, 0, 0);
      border: 0;
      box-shadow: 0 0 0 0;
      color: $transfer-blue;
      font-weight: 600;
    }
  }
}

.arrow-down-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}
.arrow-down {
  height: 15px;
  width: 20px;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/svg/arrow-down-black.svg');
  transform: rotate(90deg);
  opacity: 0.5;
}

.arrow-up-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}
.arrow-up {
  height: 15px;
  width: 20px;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/svg/arrow-down-black.svg');
  transform: rotate(-90deg);
  opacity: 0.5;
}

.arrow-icon {
  height: 10px;
  width: 10px;
  margin-left: 6px;
  margin-top: 2px;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/svg/arrow-down-black.svg');
  opacity: 0.5;
}

.page-indicator {
  padding: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.page-selector {
  width: 100%;
  position: absolute;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(135, 139, 157, 0.5);
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;

  &.needs-scroll {
    padding: 26px 0;
  }
}
.page-wrapper {
  overflow-y: auto;
  max-height: 216px;
}

.selector-scroll {
  cursor: pointer;
  height: 26px;
  width: 100%;
  position: absolute;
  background-color: white;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/arrow-down-black.svg');
  border-top: 1px solid rgba(135, 139, 157, 0.5);

  &.up {
    transform: rotate(180deg);
    top: 0;
    left: 0;
  }
  &.down {
    bottom: 0;
    left: 0;
  }
}

.page {
  width: 100%;
  padding: 6px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background-color: $transfer-blue;
    color: #fff;
  }
}
</style>
