<template>
  <transition :name="'universal-slide-up'" mode="out-in">
    <div class="stepper-modal">
      <div :class="['header', { 'no-shadow': noShadow }]">
        {{ title }}
        <div class="close-btn" @click="closeFunction">
          <menu-icon icon="close"></menu-icon>
        </div>
      </div>
      <div class="stepper-content">
        <slot name="content"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import MenuIcon from '../Icons/MenuIcon';

export default {
  name: 'StepperModal',

  components: {
    MenuIcon
  },

  props: {
    closeFunction: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    noShadow: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.stepper-modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: #fff;
  z-index: $modal-z-index;
  color: $text-primary;
  overflow-y: hidden;

  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;

  // IE11 hack
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow-y: scroll;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  position: relative;

  font-weight: 600;
  font-size: 1.3rem;

  z-index: 1000;

  @media ($desktop) {
    margin-bottom: 15px;
    height: 80px;
    min-height: 80px;
    box-shadow: $standard-shadow;
    justify-content: flex-start;
    padding-left: 40px;
    font-size: 1.5rem;

    &.no-shadow {
      box-shadow: none;
    }
  }

  @media ($mobile) {
    box-shadow: $standard-shadow;
    height: 50px;
    min-height: 50px;

    &.no-shadow {
      box-shadow: none;
    }
  }
}

.stepper-content {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  -ms-overflow-style: none;
  backface-visibility: hidden;
}

.close-btn {
  cursor: pointer;
  position: absolute;

  @media ($desktop) {
    right: 25px;
    top: 25px;
  }

  @media ($mobile) {
    top: 12px;
    right: 14px;
  }
}
</style>
