<template>
  <div class="user-card">
    <ProfileAvatar class="user-avatar" :size="50" :avatar="user.avatar" />
    <div class="user-info-box">
      <p class="user-info large">{{ user.name }}</p>
      <p class="user-info small">{{ user.email }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProfileAvatar from '../Icons/ProfileAvatar';

export default {
  name: 'UserCard',

  components: {
    ProfileAvatar
  },

  computed: {
    ...mapGetters({
      user: 'app/user'
    })
  }
};
</script>

<style lang="scss" scoped>
.user-card {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  display: flex;
  height: 100px;
  width: 100%;
}

.user-avatar {
  cursor: default;
  margin-left: 20px;
  margin-right: 10px;
}

.user-info-box {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user-info {
  margin: 0;
  padding: 0;
}

.small {
  color: $text-secondary;
  font-size: 12px;
  font-weight: 600;
}

.large {
  font-weight: 700;
}
</style>
