<template>
  <div class="wrapper">
    <Article v-if="!hasAccess" class="wishes-cta">
      <div class="wishes-cta-wrapper">
        <div>
          <h1 class="wishes-cta-center">
            {{ $t('proposition_not_logged_title') }}
          </h1>
          <ul class="wishes-cta-list">
            <li>{{ $t('proposition_not_logged_in_list_1') }}</li>
            <li>{{ $t('proposition_not_logged_in_list_2') }}</li>
            <li>{{ $t('proposition_not_logged_in_list_3') }}</li>
          </ul>
          <a :href="$routes.GET_STARTED">
            <AButton
              class="is-secondary"
              :is-rounded="true"
              :is-fullwidth="true"
            >
              {{ $t('proposition_not_logged_in_cta') }}
            </AButton>
          </a>
        </div>
        <div class="wishes-cta-center">
          <h3 class="wishes-cta-login-title">
            {{ $t('proposition_not_logged_in_text_1') }}
          </h3>
          <a :href="$routes.LOGIN" class="wishes-cta-login">
            {{ $t('proposition_not_logged_in_text_2') }}
          </a>
        </div>
      </div>
    </Article>
    <div
      v-for="(wish, index) in filteredWishes"
      :key="index"
      :class="['wishes', !hasAccess ? 'wishes--disabled' : '']"
    >
      <div v-if="index > 0 && swapType !== 12" class="alternative-title">
        {{ $t('wishes_alt_wish') }}
      </div>
      <div v-if="index > 1 && swapType === 12" class="alternative-title">
        {{ $t('wishes_alt_wish') }}
      </div>
      <div v-if="index === 0 && swapType === 12" class="alternative-title">
        {{ $t('wishes_wished_1') }}
      </div>
      <div v-if="index === 1 && swapType === 12" class="alternative-title">
        {{ $t('wishes_wished_2') }}
      </div>

      <div class="section">
        <div class="section-title">{{ $t('wishes_rooms_min') }}</div>
        <div class="section-info">
          {{ wish.rooms.min }} {{ $t('wishes_rooms_min') }}
        </div>
      </div>

      <div class="section">
        <div class="section-title">{{ $t('wishes_sqm_min') }}</div>
        <div class="section-info">
          <template v-if="wish.sqm.min == '0'">{{
            $t('wishes_sqm_no_choice')
          }}</template>
          <template v-else> {{ wish.sqm.min }} {{ $t('wishes_sqm') }}</template>
        </div>
      </div>

      <div v-if="wish.rent" class="section">
        <div class="section-title">{{ $t('wishes_rent_max') }}</div>
        <div class="section-info">
          {{ spacedMaxRent(wish.rent) }} {{ $t('wishes_currency_short') }}
        </div>
      </div>

      <div v-if="wish.fee" class="section">
        <div class="section-title">{{ $t('wishes_cost_per_month_max') }}</div>
        <div class="section-info">
          {{ spacedMaxRent(wish.fee) }} {{ $t('wishes_currency_short') }}
        </div>
      </div>

      <div class="section">
        <div class="section-title">{{ $t('wishes_requirements') }}</div>
        <div class="section-info">
          {{ wish.balcony ? $t('wishes_balcony') : '' }}
          {{ wish.isBecomingCondo ? $t('wishes_is_becoming_condo') : '' }}
          {{ wish.elevator ? $t('wishes_elevator') : '' }}
          {{
            !wish.balcony && !wish.isCondo && !wish.elevator
              ? $t('wishes_no_special')
              : ''
          }}
        </div>
      </div>

      <div class="section">
        <div class="section-title">{{ $t('wishes_other_prefs') }}</div>
        <div class="section-info">
          {{ wish.bathtub ? $t('wishes_bathtub') : '' }}
          {{ wish.isCondo ? $t('wishes_is_condo') : '' }}
          {{ wish.fireplace ? $t('wishes_fireplace') : '' }}
          {{
            !wish.bathtub && !wish.isBecomingCondo && !wish.fireplace
              ? $t('wishes_no_spec_prefs')
              : ''
          }}
        </div>
      </div>

      <AViewPort
        v-if="isAuthenticated"
        mode="in"
        :offset="0"
        :stop="true"
        @change="loadGeolocationDetails(index, 0, geolocationPageSize)"
      />

      <div
        v-if="
          convertedAreas[index] && Object.keys(convertedAreas[index]).length
        "
        class="section"
        @click="expandAreas(index)"
      >
        <div class="section-title">{{ $t('wishes_areas') }}</div>
        <div class="section-areas-container">
          <div
            ref="areas"
            :class="['section-areas', { 'is-truncated': isTruncated[index] }]"
          >
            <div
              v-for="(areaTypes, i) in convertedAreas[index]"
              :key="i"
              class="section-area-group"
            >
              <div class="section-area-group-title">
                {{ getGeoTypeText(areaTypes[0].type) }}
              </div>

              <div v-for="(area, j) in areaTypes" :key="j" class="section-area">
                <strong class="section-area-name">{{ area.name }}</strong
                >, {{ area.description }}
              </div>
            </div>
          </div>
          <button v-if="isTruncated[index]" class="section-areas-expand-button">
            {{ $t('wishes_show_all') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AViewPort from '@/components/atomic/atoms/a-viewport';
import AButton from '@/components/atomic/atoms/a-button';
import { getGeolocationsFromIds } from '../../store/modules/search/services';

export default {
  name: 'PropositionWishes',
  components: {
    AViewPort,
    AButton
  },
  props: {
    wishes: {
      type: Array,
      required: true
    },
    swapType: {
      type: Number,
      required: true,
      validator: function (value) {
        return [21, 12, 11].includes(value);
      }
    }
  },

  data() {
    return {
      alternativeWishesShown: true,
      wishesGeolocationsDetails: [],
      isExpanded: [],
      areasHeight: [0, 0],
      geolocationPageSize: 15
    };
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated',
      allowAccess: 'app/allowAccess'
    }),
    hasAccess() {
      return this.allowAccess || this.isAuthenticated;
    },
    spacedMaxRent() {
      return rent => {
        return this.formatCurrency(rent.max);
      };
    },

    convertedAreas() {
      return this.wishesGeolocationsDetails.map(details => {
        const gatheredAreas = {};

        if (details) {
          details.forEach(geoDetails => {
            if (gatheredAreas[geoDetails.type]) {
              gatheredAreas[geoDetails.type].push(geoDetails);
            } else {
              gatheredAreas[geoDetails.type] = [geoDetails];
            }
          });
        }

        return gatheredAreas;
      });
    },

    filteredWishes() {
      const { wishes, alternativeWishesShown, swapType } = this;

      if (alternativeWishesShown) return wishes;

      if (swapType === 12) {
        return wishes.filter((_, index) => index <= 1);
      }

      return [wishes[0]];
    },

    isTruncated() {
      return [
        this.wishes[0] &&
          this.wishes[0].geolocations.length >= 12 &&
          !this.isExpanded[0],
        this.wishes[1] &&
          this.wishes[1].geolocations.length >= 12 &&
          !this.isExpanded[1]
      ];
    }
  },

  watch: {
    convertedAreas: {
      handler: function () {
        this.$nextTick(() => {
          this.setAreasHeight();
        });
      },
      deep: true
    }
  },

  mounted() {
    this.setAreasHeight();
  },

  methods: {
    async loadGeolocationDetails(index, skip, size) {
      const geolocationsDetails = this.wishesGeolocationsDetails;

      const geolocationIds = [...this.wishes[index].geolocations];
      const { data } = await getGeolocationsFromIds(
        geolocationIds.splice(skip, size)
      );

      if (!geolocationsDetails[index]) {
        geolocationsDetails[index] = [];
      }

      geolocationsDetails[index].push(...data);

      this.wishesGeolocationsDetails = [...geolocationsDetails];
    },

    formatCurrency(currency) {
      return currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },

    showAlternativeWishes() {
      this.alternativeWishesShown = true;
    },

    getGeoTypeText(type) {
      const types = {
        County: this.$t('wish_area_county'),
        PostalArea: this.$t('wish_area_postal_area'),
        PostalCode: this.$t('wish_area_postal_code'),
        Municipality: this.$t('wish_area_municipality'),
        PostalCodeDistrict: this.$t('wish_area_postal_code_district'),
        ThreeDigitPostalCode: this.$t('wish_area_three_digit_postal_code'),
        Urban: this.$t('wish_area_urban'),
        District: this.$t('wish_area_district')
      };
      return types[type];
    },

    expandAreas(index) {
      if (this.isExpanded[index]) {
        return;
      }

      this.$set(this.isExpanded, index, true);
      this.loadGeolocationDetails(
        index,
        this.geolocationPageSize,
        this.wishes[index].geolocations.length - 1
      );
    },

    setAreasHeight() {
      if (!this.$refs.areas || this.$refs.areas.length === 0) {
        return;
      }

      this.$set(this.areasHeight, 0, this.$refs.areas[0].clientHeight);

      if (this.$refs.areas[1]) {
        this.$set(this.areasHeight, 1, this.$refs.areas[1].clientHeight);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}

.section {
  margin: 20px 0;

  &-title {
    text-transform: uppercase;
    color: $text-secondary;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 200%;
  }
}

.section-info {
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;
}

.alternative-title {
  font-weight: 700;
  font-size: 1rem;
  margin-top: 40px;
}

.area-button {
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  color: $transfer-blue;
  margin: 0;
  padding: 0;
  font-weight: 600;
  cursor: pointer;
}

.section-areas-container {
  margin-bottom: 2rem;

  @media ($desktop) {
    margin-bottom: 3rem;
  }
}

.section-areas {
  &.is-truncated {
    height: 300px;
    overflow: hidden;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      height: 200px;
      width: 100%;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }
  }
}

.section-areas-expand-button {
  background: transparent;
  border: 0;
  font-weight: 600;
  font-size: 15px;
  color: $transfer-blue;
  margin-top: 35px;
  cursor: pointer;
}

.section-area-group {
  line-height: 1.5;
  margin-bottom: 10px;
}

.section-area-group-title {
  font-weight: 600;
  line-height: 1.5;
}

.section-area {
  font-size: 15px;
}

.section-area-name {
  font-weight: 600;
}

.wishes--disabled {
  -webkit-filter: blur(7px);
  -moz-filter: blur(7px);
  -o-filter: blur(7px);
  -ms-filter: blur(7px);
  filter: blur(7px);
  user-select: none;
  pointer-events: none;
}

.wishes-cta {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wishes-cta-wrapper {
  width: 400px;
  max-width: 95%;
  background-color: #fff;
  z-index: 4;
  padding: 20px 56px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.3);
  @media ($mobile) {
    padding: 20px;
  }
}

.wishes-cta-list {
  list-style-type: none;
  margin-bottom: 2rem;
  padding: 0 25px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;

  li {
    position: relative;
    margin-bottom: 12px;
  }

  li::before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: -25px;
    margin-top: -1px;
    top: 0;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../assets/svg/list-item-checkbox.svg');
  }
}

.wishes-cta-center {
  text-align: center;
}

.wishes-cta-login-title {
  margin: 25px 0 5px;
}

.wishes-cta-login {
  color: $main-blue;
  text-decoration: none;
}
</style>
