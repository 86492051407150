var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showGlobalModal === 'ChatLocked')?_c('BaseInfoModalNew',{on:{"close":function($event){return _vm.setGlobalModal(false)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"primary-action full-width",attrs:{"link":((_vm.$t(
          'path_payment_proposition',
          _vm.$routeLocale
        )) + "/" + _vm.primaryPropositionId),"icon-file":"stars-white-filled"}},[_vm._v(_vm._s(_vm.$t('modal_chat_locked_button2')))])]},proxy:true}],null,false,749317300)},[_c('FancyModalContentNew',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":"illustration-lock","width":91,"height":95}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('modal_chat_locked_title')))]},proxy:true},{key:"body",fn:function(){return [_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t('modal_chat_locked_usp1')))]),_c('li',[_vm._v(_vm._s(_vm.$t('modal_chat_locked_usp2')))]),_c('li',[_vm._v(_vm._s(_vm.$t('modal_chat_locked_usp3')))]),(_vm.$country.isFeatureEnabled('SWAP_APPLICATION'))?_c('li',[_vm._v(" "+_vm._s(_vm.$t('modal_chat_locked_usp4'))+" ")]):_vm._e()]),(_vm.$country.isFeatureEnabled('TRUSTPILOT'))?_c('TrustBox',{key:"chatlocked",staticStyle:{"margin-top":"25px"},attrs:{"type":"Micro Trustscore"}}):_vm._e()]},proxy:true}],null,false,2549503832)})],1):_vm._e(),(_vm.showGlobalModal === 'SwapsLocked')?_c('BaseInfoModalNew',{on:{"close":function($event){return _vm.setGlobalModal(false)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"primary-action full-width",attrs:{"link":((_vm.$t(
          'path_payment_proposition',
          _vm.$routeLocale
        )) + "/" + _vm.primaryPropositionId),"icon-file":"stars-white-filled"}},[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_button')))])]},proxy:true}],null,false,4194461534)},[_c('FancyModalContentNew',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":"illustration-stars","width":72,"height":98}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('modal_swaps_locked_title')))]},proxy:true},{key:"body",fn:function(){return [_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp1')))]),_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp2')))]),_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp3')))]),(_vm.$country.isFeatureEnabled('SWAP_APPLICATION'))?_c('li',[_vm._v(" "+_vm._s(_vm.$t('modal_swaps_locked_usp4'))+" ")]):_vm._e()]),(_vm.$country.isFeatureEnabled('TRUSTPILOT'))?_c('TrustBox',{key:"swapslocked",staticStyle:{"margin-top":"25px"},attrs:{"type":"Micro Trustscore"}}):_vm._e()]},proxy:true}],null,false,1475886580)})],1):_vm._e(),(_vm.showGlobalModal === 'GetUnlimitedLikes')?_c('BaseInfoModalNew',{on:{"close":function($event){return _vm.setGlobalModal(false)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"primary-action full-width",attrs:{"link":((_vm.$t(
          'path_payment_proposition',
          _vm.$routeLocale
        )) + "/" + _vm.primaryPropositionId),"icon-file":"stars-white-filled"}},[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_button')))])]},proxy:true}],null,false,4194461534)},[_c('FancyModalContentNew',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":"illustration-stars","width":72,"height":98}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('modal_unlimited_title')))]},proxy:true},{key:"body",fn:function(){return [_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp1')))]),_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp2')))]),_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp3')))]),(_vm.$country.isFeatureEnabled('SWAP_APPLICATION'))?_c('li',[_vm._v(" "+_vm._s(_vm.$t('modal_swaps_locked_usp4'))+" ")]):_vm._e()]),(_vm.$country.isFeatureEnabled('TRUSTPILOT'))?_c('TrustBox',{key:"unlimitedlikes",staticStyle:{"margin-top":"25px"},attrs:{"type":"Micro Trustscore"}}):_vm._e()]},proxy:true}],null,false,486265395)})],1):_vm._e(),(_vm.showGlobalModal === 'SeeAllSwapSuggestions')?_c('BaseInfoModalNew',{on:{"close":function($event){return _vm.setGlobalModal(false)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"primary-action full-width",attrs:{"link":((_vm.$t(
          'path_payment_proposition',
          _vm.$routeLocale
        )) + "/" + _vm.primaryPropositionId),"icon-file":"stars-white-filled"}},[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_button')))])]},proxy:true}],null,false,4194461534)},[_c('FancyModalContentNew',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":"illustration-stars","width":72,"height":98}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('modal_see_all_title')))]},proxy:true},{key:"body",fn:function(){return [_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp1')))]),_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp2')))]),_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp3')))]),(_vm.$country.isFeatureEnabled('SWAP_APPLICATION'))?_c('li',[_vm._v(" "+_vm._s(_vm.$t('modal_swaps_locked_usp4'))+" ")]):_vm._e()]),(_vm.$country.isFeatureEnabled('TRUSTPILOT'))?_c('TrustBox',{key:"seeallswapsuggestions",staticStyle:{"margin-top":"25px"},attrs:{"type":"Micro Trustscore"}}):_vm._e()]},proxy:true}],null,false,1385110558)})],1):_vm._e(),(_vm.showGlobalModal === 'NoLikesLeft')?_c('BaseInfoModalNew',{on:{"close":_vm.handleCloseNoLikesLeft},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"primary-action full-width",attrs:{"link":((_vm.$t(
          'path_payment_proposition',
          _vm.$routeLocale
        )) + "/" + _vm.primaryPropositionId),"icon-file":"stars-white-filled"}},[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_button')))])]},proxy:true}],null,false,4194461534)},[_c('FancyModalContentNew',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":"exclamation","width":68,"height":75}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('modal_noleft_title')))]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(_vm._s(_vm.$t('modal_noleft_subtitle')))]},proxy:true},{key:"body",fn:function(){return [_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp1')))]),_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp2')))]),_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp3')))]),(_vm.$country.isFeatureEnabled('SWAP_APPLICATION'))?_c('li',[_vm._v(" "+_vm._s(_vm.$t('modal_swaps_locked_usp4'))+" ")]):_vm._e()]),(_vm.$country.isFeatureEnabled('TRUSTPILOT'))?_c('TrustBox',{key:"seeallswapsuggestions",staticStyle:{"margin-top":"25px"},attrs:{"type":"Micro Trustscore"}}):_vm._e()]},proxy:true}],null,false,3624329989)})],1):_vm._e(),(_vm.showGlobalModal === 'ActivateOrPay')?_c('BaseInfoModal',{attrs:{"is-loading":!_vm.selectedProduct || _vm.isLoading,"primary-action-text":_vm.$t('current_subscription_activate_freemium'),"secondary-action-text":_vm.$t('modal_action_become_member', {
        price: _vm.propositionProductPrice
      }),"secondary-action-link":((_vm.$t(
      'path_payment_proposition',
      _vm.$routeLocale
    )) + "/" + _vm.propositionIdQuery)},on:{"primaryActionClick":function($event){return _vm.handleActivateFreemiumClick(_vm.propositionIdQuery)},"close":function($event){return _vm.handleActivateOrPayClose()}}},[_c('FancyModalContent',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":"icon-stars","width":72,"height":72}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('freemium_banner_header')))]},proxy:true},{key:"body",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.$t('freemium_banner_cta')))])]},proxy:true}],null,false,3925220476)})],1):_vm._e(),(_vm.showGlobalModal === 'FirstYesInterest')?_c('BaseInfoModal',{attrs:{"show-close-button":true},on:{"close":_vm.onModalClosed}},[_c('FancyModalContent',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":"icon-stars","width":72,"height":72}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('modal_first_like_title'))+" ")]},proxy:true},{key:"body",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.$t('modal_first_like_body')))])]},proxy:true}],null,false,3051967813)})],1):_vm._e(),(_vm.showGlobalModal === 'FirstNoInterest')?_c('BaseInfoModal',{attrs:{"show-close-button":true},on:{"close":_vm.onModalClosed}},[_c('FancyModalContent',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":"eye-closed","width":72,"height":72}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('modal_first_dislike_title')))]},proxy:true},{key:"body",fn:function(){return [_c('p',[_vm._v(" "+_vm._s(_vm.$t('modal_first_dislike_body'))+" ")])]},proxy:true}],null,false,3970161634)})],1):_vm._e(),(_vm.showGlobalModal === 'NewInterestLimited')?_c('BaseInfoModal',{attrs:{"tertiary-action-text":_vm.matchesViewABTest
        ? _vm.$t('blocked_modal_button')
        : _vm.$t('limited_modal_action'),"tertiary-action-link":_vm.matchesViewABTest ? _vm.$t('path_matches') : _vm.$t('path_messages')},on:{"close":function($event){return _vm.setGlobalModal(false)},"tertiary-clicked":_vm.chatLinkClicked}},[_c('FancyModalContent',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":"icon-stars","width":72,"height":72}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('limited_modal_title')))]},proxy:true},{key:"body",fn:function(){return [_c('p',[_vm._v(" "+_vm._s(_vm.$t('limited_modal_text'))+" ")])]},proxy:true}],null,false,176557256)})],1):_vm._e(),(_vm.showGlobalModal === 'BoostInfo')?_c('StandardModal',{attrs:{"standard-modal-name":"BoostInfoModal"},on:{"close":function($event){return _vm.setGlobalModal(false)}}},[_c('BoostInfoContent',{attrs:{"slot":"content"},slot:"content"})],1):_vm._e(),(_vm.showGlobalModal === 'MyVisitorsLocked')?_c('BaseInfoModalNew',{on:{"close":function($event){return _vm.setGlobalModal(false)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('BaseButton',{staticClass:"primary-action full-width",attrs:{"link":((_vm.$t(
          'path_payment_proposition',
          _vm.$routeLocale
        )) + "/" + _vm.primaryPropositionId),"icon-file":"stars-white-filled"}},[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_button')))])]},proxy:true}],null,false,4194461534)},[_c('FancyModalContentNew',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BaseIcon',{staticClass:"icon",attrs:{"icon-file":"illustration-stars","width":72,"height":98}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('modal_my_visitors_locked_title')))]},proxy:true},{key:"body",fn:function(){return [_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp5')))]),_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp1')))]),_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp2')))]),_c('li',[_vm._v(_vm._s(_vm.$t('modal_swaps_locked_usp3')))]),(_vm.$country.isFeatureEnabled('SWAP_APPLICATION'))?_c('li',[_vm._v(" "+_vm._s(_vm.$t('modal_swaps_locked_usp4'))+" ")]):_vm._e()]),(_vm.$country.isFeatureEnabled('TRUSTPILOT'))?_c('TrustBox',{key:"swapslocked",staticStyle:{"margin-top":"25px"},attrs:{"type":"Micro Trustscore"}}):_vm._e()]},proxy:true}],null,false,213042935)})],1):_vm._e(),(_vm.showGlobalModal === 'ReportModal')?_c('ReportModalWrapper',{on:{"close":function($event){return _vm.setGlobalModal(false)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }