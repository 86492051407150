import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/store';
import routes from './routes';
import routeConstants from './routes.constants';
import { router } from '@/main';
// import { isCountry } from '../plugins/country/module';

export default function initRouter() {
  Vue.use(VueRouter);

  const router = new VueRouter({
    mode: 'history',
    history: true,
    routes: getEnableRoutesOnly(routes),
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active'
  });

  router.beforeEach(async (to, from, next) => {
    store.dispatch('menu/closeMenu');

    if (to.query.propositionPageId) {
      store.dispatch('ui/openPropositionModal', {
        propositionId: Number(to.query.propositionPageId),
        type: to.query.type
      });
    } else if (from.query.propositionPageId) {
      store.dispatch('ui/closePropositionModal', false);
    }

    if (to.path !== from.path) {
      store.commit('ui/removeAllDisableScroll');
    }

    await checkAuthentication(to, next);
  });

  return router;
}

let readyCheckIval;
async function checkAuthentication(to, next) {
  const requireAuthentication = !to.meta || !to.meta.public;

  if (!requireAuthentication) {
    next();
    return;
  }

  clearInterval(readyCheckIval);
  readyCheckIval = setInterval(() => {
    const isReady = store.getters['app/ready'];
    if (isReady) {
      clearInterval(readyCheckIval);

      const isAuthenticated = store.getters['app/isAuthenticated'];
      if (!isAuthenticated && to.path !== routeConstants.LOGIN) {
        router.replace(`${routeConstants.LOGIN}?returnUrl=${to.path}`);
      }
      next();
    }
  }, 10);
}

function getEnableRoutesOnly(routesArray) {
  return routesArray.filter(route => !route.meta || !route.meta.disabled);
}
