<template>
  <div class="blog-post-page">
    <OBlogPost />
    <MConversionBanner v-if="!isAuthenticated" />
    <TheFooter :is-authenticated="isAuthenticated" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OBlogPost from '@/components/atomic/organisms/o-blog-post';
import MConversionBanner from '@/components/atomic/molecules/m-conversion-banner';
import TheFooter from '../../components/TheFooter';

export default {
  components: {
    OBlogPost,
    MConversionBanner,
    TheFooter
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    })
  },
  created() {
    window.scrollTo(0, 0);
  }
};
</script>

<style lang="scss" scoped>
.blog-post-page {
  max-width: none;
  width: 100%;
  padding-bottom: 95px;

  @media ($desktop) {
    padding-top: 80px;
    padding-bottom: 0;
  }
}
</style>
