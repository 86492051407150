export default {
  SCREENS: state => state.SCREENS,
  currentScreen: state => state.currentScreen,
  previousScreen: state => state.previousScreen,
  allowContact: state => state.allowContact,
  blockProposition: state => state.blockProposition,
  formFilled: state => state.formFilled,
  textareaReportDescription: state => state.textareaReportDescription,
  getScreenInfo: state => value => {
    return state.SCREENS[value];
  },
  getOptionState: state => value => {
    return state.OPTION[value];
  }
};
