<template>
  <span
    :class="[`icon icon--${icon}`, direction, { center: center }]"
    v-on="hasClickEvent ? { click: handleClick } : {}"
  >
    <component :is="icon" ref="icon" />
  </span>
</template>

<script>
import Arrow from './Arrow';
import Chevron from './Chevron';
import Close from './Close';

export default {
  name: 'Icon',
  components: {
    Arrow,
    Chevron,
    Close
  },
  props: {
    color: {
      type: String,
      default: '#2c3e50'
    },
    direction: {
      type: String,
      enum: ['up', 'right', 'down', 'left'],
      default: ''
    },
    handleClick: {
      type: Function,
      default: undefined
    },
    height: {
      type: Number,
      default: undefined
    },
    icon: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 10
    },
    strokeWidth: {
      type: Number,
      default: 1.5
    },
    width: {
      type: Number,
      default: undefined
    },
    center: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasClickEvent() {
      return typeof this.handleClick === 'function';
    }
  },

  mounted() {
    const { color, height, size, strokeWidth, width } = this;
    const icon = this.$refs.icon.$el;
    icon.style.color = color;
    icon.style.height = `${height ? height : size}px`;
    icon.style.width = `${width ? width : size}px`;
    icon.style.strokeWidth = `${strokeWidth}px`;
  }
};
</script>

<style lang="scss" scoped>
.center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.icon {
  display: inline-block;

  &--chevron {
    &.down svg {
      margin: 2px 0 2px 2px;
    }
  }

  &.up {
    transform: rotateZ(270deg);
  }

  &.right {
    transform: rotateZ(0deg);
  }

  &.down {
    transform: rotateZ(90deg);
  }

  &.left {
    transform: rotateZ(180deg);
  }
}
</style>
