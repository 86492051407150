import { lbApiBeta } from '../../../utils/axiosConfig';

async function fetchFavourites(context) {
  try {
    context.commit('setLoading', { loading: true });
    const { data: propositions } = await lbApiBeta.get(
      '/api/v2/favourite/all/propositions'
    );
    context.commit('setFavourites', {
      data: propositions.map(p => {
        p.favourite = true;
        return p;
      })
    });
    context.commit('setLoading', { loading: false });

    window.Logger.log('Updated favourites', propositions);
  } catch (e) {
    window.Logger.log(e);
  }
}

async function unmarkFavourite(context, { propositionId }) {
  if (!propositionId) return;

  // Set proposition to loading and not favourite
  context.commit('unmarkFavourite', { propositionId });

  context.dispatch(
    'propositions/favouriteSocketUpdate',
    {
      targetPropositionId: propositionId,
      favourite: false
    },
    { root: true }
  );

  context.dispatch(
    'swapList/favouriteSocketUpdate',
    {
      targetPropositionId: propositionId,
      favourite: false
    },
    { root: true }
  );

  try {
    const res = await lbApiBeta.post('/api/v2/favourite', {
      isFavourite: false,
      propositionId: propositionId.toString()
    });
    //context.commit('removeFavourite', { propositionId });
    window.Logger.log(res);
  } catch (e) {
    window.Logger.error(e);

    // Update UI to reflect failed update
    context.commit('markFavourite', { propositionId });
    context.dispatch(
      'propositions/favouriteSocketUpdate',
      {
        targetPropositionId: propositionId,
        favourite: true
      },
      { root: true }
    );
  }
}

async function markFavourite(context, { propositionId }) {
  // Set proposition to loading and not favourite
  context.commit('markFavourite', { propositionId });
  context.dispatch(
    'propositions/favouriteSocketUpdate',
    {
      targetPropositionId: propositionId,
      favourite: true
    },
    { root: true }
  );

  context.dispatch(
    'swapList/favouriteSocketUpdate',
    {
      targetPropositionId: propositionId,
      favourite: true
    },
    { root: true }
  );

  try {
    const res = await lbApiBeta.post('/api/v2/favourite', {
      isFavourite: true,
      propositionId: propositionId.toString()
    });

    window.Logger.log(res);
  } catch (e) {
    window.Logger.error(e);
    // Update UI to reflect failed update
    context.commit('unmarkFavourite', {
      propositionId
    });

    context.dispatch(
      'propositions/favouriteSocketUpdate',
      {
        targetPropositionId: propositionId,
        favourite: false
      },
      { root: true }
    );
  }
}

export default {
  fetchFavourites,
  unmarkFavourite,
  markFavourite
};
