<template>
  <div
    ref="swapItem"
    :class="['swap-item', { toned }, { 'blur-content': blurContent }]"
    @click.stop="goToProposition"
  >
    <div
      v-for="(residence, index) in swap.residences"
      :key="residence.streetAddress"
      class="swap-item-header"
      :style="[index === 1 && { marginTop: '6px' }]"
    >
      <div class="swap-item-image-wrapper">
        <div
          v-if="swap.inSwapProcess && index === 0"
          :class="'swap-type-banner swap-process'"
        >
          {{ $t('compressed_swap_item_banner') }}
        </div>

        <div
          class="img-placeholder"
          :style="
            residence.pictures && residence.pictures[0]
              ? {
                  backgroundImage: `url(${residence.pictures[0]})`,
                  backgroundSize: 'cover',
                  opacity: 1
                }
              : ''
          "
        ></div>
      </div>

      <div class="swap-info-wrapper">
        <div class="info-top-wrapper">
          <div class="address-info">
            <div class="address">
              {{ residence.streetAddress }}
              <BaseIcon
                v-if="swap.verified"
                icon="verified"
                :width="18"
                :height="18"
                class="verified-badge"
              />
            </div>

            <div class="postal-area">
              {{ residence.postalArea }}
            </div>
          </div>

          <div v-if="index === 0" class="icon-area">
            <div
              v-if="isUserList"
              :class="['favourite-icon', { marked: swap.isFavourite }]"
              @click.stop.prevent="handleMarkFavourite"
            ></div>
          </div>
        </div>

        <div class="residence-info-bar">
          <span :class="{ 'diff-value': swap.diff && swap.diff.rooms }">
            <span class="extra-bold">{{ residence.rooms }}</span>
            {{ $tc('compressed_swap_item_rooms', Number(residence.rooms)) }}
          </span>

          &#8729;

          <span :class="{ 'diff-value': swap.diff && swap.diff.sqm }">
            <span class="extra-bold">{{ residence.sqm }}</span>
            {{ $t('compressed_swap_item_sqm') }}
          </span>

          &#8729;

          <span :class="{ 'diff-value': swap.diff && swap.diff.floor }">
            {{
              residence.floor !== 0 && residence.floor !== 999
                ? $t('compressed_swap_item_floor')
                : ''
            }}
            <span class="extra-bold">{{
              formattedFloor(residence.floor)
            }}</span>
          </span>

          <br />

          <span :class="{ 'diff-value': swap.diff && swap.diff.rent }">
            <span class="extra-bold">{{ spacedRent(residence.rent) }}</span>
            {{ $t('compressed_swap_item_cost_per_month') }}
          </span>

          <div v-if="hasDiffedConveniences" class="diff-conveniences-wrapper">
            <span>{{ $t('swap_item_missing') }}</span>
            {{ diffedConveniencesDescription }}
          </div>
        </div>
      </div>
    </div>

    <div class="swap-item-body">
      <div class="interest-bar">
        <div class="interest-area-and-button">
          <div class="interest-area">
            <interest-indicators :interests="interests" />

            <div class="interest-progress">{{ swapStatusText }}</div>
          </div>

          <div class="action-button" @click.stop="handleShowSwapDetails">
            {{ $t('compressed_swap_item_action') }}
          </div>
        </div>

        <template v-if="isUserList">
          <InterestButtonsFreemium
            :interested="interests[0]"
            :target-proposition-id="swap.propositionId.toString()"
            :swap="swap"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import InterestIndicators from './InterestIndicators';
import InterestButtonsFreemium from './InterestButtonsFreemium';
import events from '@/utils/helpers/events';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CompressedSwapItem',

  components: {
    InterestIndicators,
    InterestButtonsFreemium
  },

  props: {
    showSwapDetails: {
      type: Function,
      required: true
    },
    swap: {
      type: Object,
      required: true
    },
    toned: {
      type: Boolean,
      required: true
    },
    blurContent: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentResidence: 0,
      scrollLocked: false
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      isUserList: 'swapList/isUserList',
      isImpersonated: 'app/isImpersonated',
      isTrial: 'trial/trialBannerOpen',
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    }),

    hasDiffedConveniences() {
      return this.diffedConveniencesDescription !== '';
    },

    diffedConveniencesDescription() {
      if (!this.swap.diff) {
        return '';
      }

      const conveniences = [];
      if (this.swap.diff.elevator) {
        conveniences.push(this.$t('conveniences_elevator'));
      }

      if (this.swap.diff.balcony) {
        conveniences.push(this.$t('conveniences_balcony'));
      }

      if (this.swap.diff.becomingCoop) {
        conveniences.push(this.$t('conveniences_becoming_condo'));
      }

      return conveniences.join(' ∙ ');
    },

    interests() {
      return this.swap.details.interests;
    },

    swapStatusText() {
      const interestedCount = this.interests.filter(Boolean).length;
      const interestsLength = this.interests.length;

      return `${interestedCount} ${this.$t(
        'swap_item_of_interested'
      )} ${interestsLength}`;
    },

    swapChat() {
      return this.swap.chat;
    },

    swapLabel() {
      const { swapType } = this.swap;

      switch (swapType) {
        case 21:
          return this.$t('general_2_for_1');
        case 12:
          return this.$t('general_1_for_2');
        default:
          return this.$t('general_1_for_1');
      }
    },
    typeColor() {
      const { swapType } = this.swap;

      switch (swapType) {
        case 21:
          return 'pink';
        case 12:
          return 'yellow';
        default:
          return 'blue';
      }
    },

    isTriangle() {
      return this.swap.details.interests.length >= 3;
    }
  },

  methods: {
    ...mapActions({
      markFavourite: 'favourites/markFavourite',
      unmarkFavourite: 'favourites/unmarkFavourite',
      setGlobalModal: 'ui/setGlobalModal'
    }),

    handleMarkFavourite() {
      if (this.swap.isFavourite) {
        this.unmarkFavourite({ propositionId: this.swap.propositionId });
      } else {
        this.markFavourite({ propositionId: this.swap.propositionId });
      }
    },

    goToProposition() {
      if (this.blurContent) {
        this.setGlobalModal('SwapsLocked');

        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'swap-locked',
          src: 'swap-list'
        });
      } else {
        this.$router.push(
          `${this.$t('path_proposition', this.$routeLocale)}/${
            this.swap.propositionId
          }`
        );
      }
    },

    handleShowSwapDetails() {
      this.showSwapDetails({
        propositionId: this.swap.propositionId,
        type: this.swap.details.type
      });
    },

    formattedFloor(floor) {
      switch (floor) {
        case 0:
          return this.$t('general_ground_floor_short');
        case 999:
          return this.$t('general_attic_short');
        default:
          return floor;
      }
    },

    spacedRent(rent) {
      return rent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  }
};
</script>

<style lang="scss" scoped>
.swap-item {
  background-color: #fff;
  width: 100vw;
  padding: 12px 15px;
  margin-left: -15px;

  padding-bottom: 12px;
  border-bottom: 1px solid #f1f2f6;

  &.toned {
    background-color: rgba(241, 242, 246, 0.35);
  }
}

.swap-item-header {
  cursor: pointer;
  display: flex;
  -webkit-tap-highlight-color: transparent;
}

.icon-area {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.proposition-type {
  padding: 5px 8px;
  border-radius: 12px;
  font-weight: 700;
  white-space: nowrap;
  font-size: 0.6rem;

  &.pink {
    background-color: #fce5f6;
    color: #ed63d2;
  }
  &.blue {
    background-color: #e7f3ff;
    color: $transfer-blue;
  }
  &.yellow {
    color: #ffc212;
    background-color: #fff8e6;
  }
}

.underline {
  text-decoration: underline;
}

.favourite-icon {
  height: 20px;
  width: 20px;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/heart-empty.svg');
  margin: 0 6px;

  &.marked {
    background-image: url('../../assets/svg/heart-full.svg');
  }
}

.swap-item-image-wrapper {
  position: relative;
  height: 90px;
  width: 30%;
  min-width: 30%;
  margin-right: 6px;
}

.swap-type-banner {
  position: absolute;
  top: 9px;
  left: 0;
  padding: 4px;
  padding-left: 6px;
  background-color: $dark-blue;
  color: #fff;
  z-index: 1;
  font-weight: 600;
  font-size: 0.5rem;
  border-top-right-radius: $small-box-radius;
  border-bottom-right-radius: $small-box-radius;

  &.swap-process {
    background-color: $transfer-blue;
  }
}

.img-placeholder {
  background-size: contain;
  background-color: #ebebeb;
  background-position: center;
  opacity: 0.8;
  background-image: url('../../assets/svg/skyline-placeholder-img.svg');
  width: 100%;
  height: 100%;
  border-radius: $small-box-radius;
}

.swap-info-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.info-top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.address {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
}

.verified-badge {
  margin-left: 6px;
}

.postal-area {
  margin-top: 3px;
  font-size: 0.8rem;
  color: $text-secondary;
  font-weight: 600;
}

.residence-info-bar {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin-top: auto;
  padding: 6px 0 0 0;
}

.interest-bar {
  padding: 8px 0 0 0;
  display: flex;
  flex-direction: column;
}

.interest-area-and-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.interest-area {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.interest-progress {
  margin-left: 3px;
  font-weight: 600;
  color: $text-primary;
  font-size: 0.7rem;
}

.amenities-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-button {
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 700;
  color: $transfer-blue;
  padding: 4px;
}

.extra-bold {
  font-weight: 700;
}

.red {
  color: red;
}

.diff-value {
  color: #db8e00;
}

.diff-conveniences-wrapper {
  color: #db8e00;
  font-weight: 600;
  margin-top: 5px;
}

.diff-conveniences-wrapper span {
  font-weight: 400;
}

.blur-content {
  .swap-item-slider,
  .swap-item-header,
  .residence-info-bar,
  .desktop-body-center-info,
  .amenities-bar,
  .action-button,
  .interest-buttons {
    filter: blur(4px);
    pointer-events: none;
    user-select: none;
  }
}
</style>
