var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"amenities-wrapper"},[(_vm.commutes && _vm.commutes.length > 0)?_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-title"},[_vm._v(" "+_vm._s(_vm.$t('find_amenities_commutes'))+" ")]),_vm._l((_vm.commutes),function(commute,index){return _c('div',{key:index,staticClass:"amenity"},[_c('div',{staticClass:"amenity-image",class:commute.type}),_c('div',{staticClass:"amenity-info"},[_c('div',{staticClass:"amenity-title"},[_vm._v(" "+_vm._s(_vm.getCommuteName(commute.type))+" ")]),_c('div',{staticClass:"amenity-address"},[_vm._v(" "+_vm._s(commute.name)+" ("+_vm._s(commute.distance)+" "+_vm._s(_vm.$t('general_meter'))+") ")])])])})],2):_vm._e(),(_vm.restaurants)?_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t('find_amenities_restaurants')))]),_vm._l((_vm.restaurants),function(restaurant,index){return _c('div',{key:index,staticClass:"amenity"},[_c('div',{staticClass:"amenity-image restaurant",style:([
            restaurant.streetViewImage
              ? {
                  backgroundImage: ("url(" + (restaurant.streetViewImage) + ")"),
                  opacity: 1,
                  backgroundSize: 'cover'
                }
              : ''
          ])}),_c('div',{staticClass:"amenity-info"},[_c('div',{staticClass:"amenity-title"},[_vm._v(" "+_vm._s(restaurant.displayName)+" ")]),_c('div',{staticClass:"amenity-address"},[_vm._v(" "+_vm._s(restaurant.address)+" "),(restaurant.distance)?_c('span',[_vm._v("("+_vm._s(restaurant.distance)+" "+_vm._s(_vm.$t('general_meter'))+")")]):_vm._e()])])])})],2):_vm._e(),(_vm.schools)?_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t('find_amenities_schools')))]),_vm._l((_vm.schools),function(school,index){return _c('div',{key:index,staticClass:"amenity"},[_c('div',{staticClass:"amenity-image school",style:([
            school.streetViewImage
              ? {
                  backgroundImage: ("url(" + (school.streetViewImage) + ")"),
                  opacity: 1,
                  backgroundSize: 'cover'
                }
              : ''
          ])}),_c('div',{staticClass:"amenity-info"},[_c('div',{staticClass:"amenity-title"},[_vm._v(" "+_vm._s(school.displayName)+" ")]),_c('div',{staticClass:"amenity-address"},[_vm._v(" "+_vm._s(school.address)+" "),(school.distance)?_c('span',[_vm._v("("+_vm._s(school.distance)+" "+_vm._s(_vm.$t('general_meter'))+")")]):_vm._e()])])])})],2):_vm._e(),(_vm.stores)?_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t('find_amenities_stores')))]),_vm._l((_vm.stores),function(store,index){return _c('div',{key:index,staticClass:"amenity"},[_c('div',{staticClass:"amenity-image store",style:([
            store.streetViewImage
              ? {
                  backgroundImage: ("url(" + (store.streetViewImage) + ")"),
                  opacity: 1,
                  backgroundSize: 'cover'
                }
              : ''
          ])}),_c('div',{staticClass:"amenity-info"},[_c('div',{staticClass:"amenity-title"},[_vm._v(" "+_vm._s(store.displayName)+" ")]),_c('div',{staticClass:"amenity-address"},[_vm._v(" "+_vm._s(store.address)+" "),(store.distance)?_c('span',[_vm._v("("+_vm._s(store.distance)+" "+_vm._s(_vm.$t('general_meter'))+")")]):_vm._e()])])])})],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }