const status = state => state.status;
const currentStep = state => state.currentStep;
const progressBar = state => state.progressBar;
const exampleFlatImagesSet1 = state => state.exampleFlatImagesSet1;
const exampleFlatImagesSet2 = state => state.exampleFlatImagesSet2;
const statusTexts = state => state.statusTexts;
const imageSizes = state => state.imageSizes;
const isSuccess = state => state.isSuccess;

export default {
  status,
  statusTexts,
  currentStep,
  progressBar,
  imageSizes,
  exampleFlatImagesSet1,
  exampleFlatImagesSet2,
  isSuccess
};
