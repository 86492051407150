import { io } from 'socket.io-client';
import i18n, { routeLocale } from '@/i18n';
import { getCountry } from '../plugins/country/module';

const url = i18n.t('url_socket', routeLocale);
let client = null;
const noInstanceActions = [];

const debugSocket = false;

export async function connect() {
  try {
    if (!client) {
      if (debugSocket) window.Logger.log('creating socket instance');

      client = io(url, {
        withCredentials: true,
        extraHeaders: {
          country: getCountry()
        }
      });

      noInstanceActions.forEach(func => func());
    }

    if (debugSocket) {
      window.Logger.log('setting socket listners');

      client.onAny((channel, data) => {
        window.Logger.log('socket debug:', channel, data);
      });

      client.on('connect_error', data => {
        window.Logger.log('connect_error', data);
      });
    }

    client.on('connect', () => {
      window.Logger.log('>>> socket connected <<<');

      const engine = client.io.engine;

      if (debugSocket) {
        engine.once('upgrade', () => {
          window.Logger.log('socket.io upgraded to:', engine.transport.name);
        });

        engine.on('packet', ({ type, data }) => {
          window.Logger.log('socket packet:', type, data);
        });

        engine.on('packetCreate', ({ type, data }) => {
          window.Logger.log('socket packetCreate:', type, data);
        });

        engine.on('drain', () => {
          window.Logger.log('socket drain');
        });

        engine.on('close', data => {
          window.Logger.log('socket closed:', data);
        });
      }
    });

    client.io.on('disconnect', data => {
      window.Logger.log('>>> socket disconnected <<<', data);
    });

    client.io.on('reconnect', () => {
      window.Logger.log('>>> socket reconnected <<<');
    });

    if (debugSocket) {
      client.io.on('error', error => {
        window.Logger.log('socket error:', error);
      });

      client.io.on('reconnect_attempt', () => {
        window.Logger.log('reconnect_attempt');
      });

      client.io.on('reconnect_failed', data => {
        window.Logger.log('reconnect_failed', data);
      });
    }
  } catch (error) {
    window.Logger.log('socketio error:', error);
  }
}

export function disconnect() {
  try {
    if (client) client.disconnect();
  } catch (error) {
    console.error(error);
  }
}

export function subscribe(channel, fn) {
  try {
    if (!client) {
      noInstanceActions.push(() => subscribe(channel, fn));
      return;
    }

    client.on(channel, fn);
  } catch (error) {
    console.error('could not subscribe:', error);
  }
}

export function unsubscribeAll(channel) {
  try {
    client.removeAllListeners(channel);
  } catch (error) {
    console.error(error);
  }
}

export function unsubscribe(channel, fn) {
  try {
    client.off(channel, fn);
  } catch (error) {
    console.error(error);
  }
}

export function emit(channel, data) {
  try {
    client.emit(channel, data);
    return true;
  } catch (error) {
    console.error(error);
  }

  return false;
}

export default {
  connect,
  disconnect,
  subscribe,
  unsubscribe,
  emit,
  unsubscribeAll
};
