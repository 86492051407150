import { dayjs } from '@/plugins/dayjs/install';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

const unseenNotificationsCount = state => state.unseenNotificationsCount;

const getNotifications = state => state.notifications;

const getNotificationsCount = state => state.notifications.length;

const getTodaysNotifications = state => {
  // now must be newer than notifications date
  const now = dayjs().add(5, 'minute');
  const yesterday = dayjs().subtract(1, 'day');

  return state.notifications.filter(notification =>
    dayjs.tz(notification.createdAt).isBetween(now, yesterday)
  );
};

const getLastWeeksNotifications = state => {
  const yesterday = dayjs().subtract(1, 'day');
  const oneWeekAgo = dayjs().subtract(1, 'week');

  return state.notifications.filter(notification =>
    dayjs.tz(notification.createdAt).isBetween(yesterday, oneWeekAgo)
  );
};

const getLastMonthsNotifications = state => {
  const oneWeekAgo = dayjs().subtract(1, 'week');
  const oneMonthAgo = dayjs().subtract(1, 'month');

  return state.notifications.filter(notification =>
    dayjs.tz(notification.createdAt).isBetween(oneWeekAgo, oneMonthAgo)
  );
};

const isLoading = state => state.loading;

export default {
  unseenNotificationsCount,
  getNotifications,
  getTodaysNotifications,
  getNotificationsCount,
  getLastWeeksNotifications,
  getLastMonthsNotifications,
  isLoading
};
