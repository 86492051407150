<template>
  <div>
    <div class="thank-you-page">
      <div class="illustration" />
      <h1 class="title">
        {{ $t('confirmation_proposition_title') }}
      </h1>
      <p class="text">
        {{ $t('confirmation_proposition_text') }}
      </p>

      <router-link :to="$routes.SWAP_LIST">
        <primary-button>
          {{ $t('confirmation_proposition_confirm') }}
        </primary-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PrimaryButton from '../../components/Buttons/Primary';
import { getCookies } from '../../utils/helpers/cookie';

export default {
  name: 'PaymentDone',

  components: {
    PrimaryButton
  },
  data() {
    return {
      siteGainerIntervalId: null
    };
  },
  head: {
    title() {
      return {
        inner: this.$t('meta_title_payment_done')
      };
    }
  },
  computed: {
    ...mapGetters({
      user: 'app/user'
    })
  },
  mounted() {
    this.handleTracking();
    this.temporaryHideTrialBanner({ hide: true });
  },

  destroyed() {
    this.temporaryHideTrialBanner({ hide: false });

    if (this.siteGainerIntervalId) {
      clearInterval(this.siteGainerIntervalId);
    }
  },

  methods: {
    ...mapActions({
      temporaryHideTrialBanner: 'trial/temporaryHideTrialBanner',
      isFirstTimePayment: 'paymentHistory/isFirstTimePayment'
    }),

    handleTracking() {
      try {
        const cookies = getCookies();
        const consent = cookies['lb-cc'];

        if (consent !== 'false') {
          /* Sitegainer: Track final conversion */
          this.siteGainerIntervalId = setInterval(() => {
            window.Logger && window.Logger.log('Checking sitegainer');
            if (typeof window.sitegainer !== 'undefined') {
              window.sitegainer.pushGoal('order');
              window.Logger && window.Logger.log('Pushed');
              clearInterval(this.siteGainerIntervalId);
            }
          }, 1000);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.thank-you-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding-top: 180px;

  margin: 0 auto;
  @media ($mobile) {
    padding: 20px;
  }
}

.illustration {
  height: 150px;
  width: 150px;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/baloons.svg');
  margin-bottom: 20px;

  @media ($mobile) {
    height: 100px;
    width: 100px;
  }
}

.title {
  text-align: center;
}

.text {
  font-weight: 600;
  color: $text-secondary;
  text-align: center;
  line-height: 150%;
  margin: 0;

  &:last-of-type {
    margin-bottom: 40px;
  }
}
</style>
