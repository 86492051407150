<template>
  <div>
    <BaseOverlay v-if="showModal && isMobile" @click.native="$emit('close')" />
    <Transition mode="out-in" appear>
      <div
        v-if="showModal"
        class="page-modal"
        :style="{ zIndex: pageModalZIndex }"
      >
        <div class="header">
          <div class="close-icon" @click="$emit('close')">
            <BaseIcon icon-file="icon-arrow-left" :width="24" :height="24" />
          </div>
          <slot name="nav"></slot>
        </div>
        <div id="page-modal-content" class="page-content">
          <slot></slot>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'PageModal',

  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    pageModalName: {
      type: String,
      default: '',
      required: true
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      pageModalZIndex: 'ui/pageModalZIndex'
    })
  },

  mounted() {
    this.addDisableScroll(this.pageModalName);
  },

  destroyed() {
    this.removeDisableScroll(this.pageModalName);
  },

  methods: {
    ...mapMutations({
      addDisableScroll: 'ui/addDisableScroll',
      removeDisableScroll: 'ui/removeDisableScroll'
    })
  }
};
</script>

<style lang="scss" scoped>
$header-height: 50px;

.page-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #fff;
  z-index: 170;

  @media ($desktop) {
    top: 79px;
    z-index: 10;
  }
}

.header {
  height: $header-height;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid rgba(28, 45, 65, 0.103);
  z-index: 3;

  @media ($desktop) {
    border-top: 1px solid rgba(28, 45, 65, 0.103);
  }
}

.close-icon {
  height: 100%;
  border-right: 1px solid rgba(28, 45, 65, 0.103);
  padding: 0 16px;
  display: grid;
  place-items: center;
  cursor: pointer;
  z-index: 2;
}

.page-content {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: $header-height;

  @media ($mobile) {
    scrollbar-width: none;
  }

  @media ($desktop) {
    height: calc(100vh - 79px);
  }
}

@media ($mobile) {
  .page-content::-webkit-scrollbar {
    display: none;
  }
}
</style>
