import { lbApiBeta } from '@/utils/axiosConfig';
import { SWAP_FILTER_TYPES } from './index';

async function fetchInterestCounts(propositionId) {
  try {
    const { data } = await lbApiBeta.get(
      `api/swaps/interest-counts/${propositionId}`
    );
    return data;
  } catch (error) {
    console.error(error);
  }

  return null;
}

function mapSwapResponse(swapData) {
  const mappedData = {
    address: swapData.address,
    fullInterestSwaps: swapData.fullInterestSwaps,
    newSwaps: swapData.newSwaps,
    undistributed: swapData.undist,
    page: swapData.page,
    totalPages: swapData.totalPages,
    swaps: swapData.swaps,
    unmarkedSwaps: swapData.unmarkedSwaps,
    listPropositionId: swapData.propositionId,
    preFilterCount: swapData.preFilterCount,
    postFilterCount: swapData.postFilterCount,
    areaGroups: swapData.areaGroups,
    swapInterestCounts: swapData.swapInterestCounts,
    queueCount: swapData.queueCount,
    totalSwaps: swapData.totalSwaps
  };

  window.Logger.log(mappedData);
  return mappedData;
}

async function fetchPremiumSwaps(propositionId) {
  try {
    const { data: premiumSwaps } = await lbApiBeta.get(
      `/api/swaps/premium/${propositionId}`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );
    return premiumSwaps;
  } catch (e) {
    window.Logger.log('FaTaL eRroR: ', e);
  }
}

async function fetchUnansweredSwaps(filterObj, page) {
  try {
    const { propositionId, pageSize } = filterObj;
    const { data: swapData } = await lbApiBeta.get(
      `api/swaps/propositions/${propositionId}?skip=${page}&take=${pageSize}`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );
    return mapSwapResponse(swapData);
  } catch (e) {
    window.Logger.log('FaTaL eRroR: ', e);
  }
}

async function fetchFullInterestSwaps(filterObj, page) {
  try {
    const { propositionId, sortBy, pageSize } = filterObj;
    const { data: swapData } = await lbApiBeta.get(
      `api/swaps/full-interest/${propositionId}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy.key}&sortDesc=${sortBy.descending}`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );
    return mapSwapResponse(swapData);
  } catch (e) {
    window.Logger.log('FaTaL eRroR: ', e);
  }
}

async function fetchWaitingForOwnerSwaps(filterObj, page) {
  try {
    const { propositionId, sortBy, pageSize } = filterObj;
    const { data: swapData } = await lbApiBeta.get(
      `api/swaps/waiting-for-owner/${propositionId}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy.key}&sortDesc=${sortBy.descending}`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );
    return mapSwapResponse(swapData);
  } catch (e) {
    window.Logger.log('FaTaL eRroR: ', e);
  }
}

async function fetchOwnerMarkedSwaps(filterObj, page) {
  try {
    const { propositionId, sortBy, pageSize } = filterObj;
    const { data: swapData } = await lbApiBeta.get(
      `api/swaps/marked/${propositionId}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy.key}&sortDesc=${sortBy.descending}`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );
    return mapSwapResponse(swapData);
  } catch (e) {
    window.Logger.log('FaTaL eRroR: ', e);
  }
}

async function fetchOwnerInterestMarksSwaps(filterObj, page) {
  try {
    const { propositionId, sortBy, pageSize } = filterObj;
    const { data: swapData } = await lbApiBeta.get(
      `api/swaps/owner-interest-marks/${propositionId}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy.key}&sortDesc=${sortBy.descending}`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );
    return mapSwapResponse(swapData);
  } catch (e) {
    window.Logger.log('FaTaL eRroR: ', e);
  }
}

async function fetchSwaps({ filterObj, propositionId, page }) {
  try {
    if (filterObj.filter.type === SWAP_FILTER_TYPES.UNANSWERED_NEW) {
      return await fetchUnansweredSwaps(filterObj, page);
    }

    if (filterObj.filter.type === SWAP_FILTER_TYPES.ONLY_FULL_INTEREST) {
      return await fetchFullInterestSwaps(filterObj, page);
    }

    if (filterObj.filter.type === SWAP_FILTER_TYPES.MARKED) {
      return await fetchOwnerMarkedSwaps(filterObj, page);
    }

    if (filterObj.filter.type === SWAP_FILTER_TYPES.INTEREST_MARKED) {
      return await fetchOwnerInterestMarksSwaps(filterObj, page);
    }

    if (filterObj.filter.type === SWAP_FILTER_TYPES.LAST_PART) {
      return await fetchWaitingForOwnerSwaps(filterObj, page);
    }

    const { data: swapData } = await lbApiBeta.post(
      'api/swaplist/search',
      {
        ...filterObj,
        propositionId,
        page
      },
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );
    return mapSwapResponse(swapData);
  } catch (e) {
    window.Logger.log('FaTaL eRroR: ', e);
  }

  return null;
}

async function fetchBlockedPropositions(propositionId) {
  const { data } = await lbApiBeta.get('/api/swap-block', {
    params: { propositionId: propositionId }
  });

  return data;
}

async function blockSwapParty({ propositionId, blocked }) {
  try {
    const data = await lbApiBeta.put(
      '/api/swap-block',
      {
        propositionId: propositionId,
        blocked: blocked
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return data;
  } catch (e) {
    window.Logger.log('Error blocking triangle swap party: ', e);
  }
}

async function getTriangleSwapDetails({
  userPropositionId,
  propositionId,
  propositionId2 = null,
  showDenied
}) {
  try {
    const { data: swapDetails } = await lbApiBeta.get(
      `/api/triangleswap/${userPropositionId}/${propositionId}${
        propositionId2 ? '/' + propositionId2 : ''
      }`,
      { params: { showDenied: showDenied || false } }
    );

    return swapDetails;
  } catch (e) {
    window.Logger.log('Error fetching triangle swap details: ', e);
  }
}

async function getDirectSwapDetails({ userPropositionId, propositionId }) {
  try {
    const { data: swapDetails } = await lbApiBeta.get(
      `/api/directswap/${userPropositionId}/${propositionId}`
    );

    return swapDetails;
  } catch (e) {
    window.Logger.log('Error fetching direct swap details: ', e);
  }
}

async function getSwapDetails(propositionIds) {
  try {
    if (propositionIds > 2) {
      return getTriangleSwapDetails({
        userPropositionId: propositionIds[0],
        propositionId: propositionIds[1],
        propositionId2: propositionIds[2]
      });
    } else {
      return getDirectSwapDetails({
        userPropositionId: propositionIds[0],
        propositionId: propositionIds[1]
      });
    }
  } catch {
    return null;
  }
}

async function getTriangleSwapAlternatives({
  userPropositionId,
  propositionId,
  page,
  pageSize,
  showDenied
}) {
  try {
    const { data: alternatives } = await lbApiBeta.get(
      `/api/triangleswap/${userPropositionId}/${propositionId}/alternatives`,
      { params: { showDenied: showDenied || false, pageSize, page } }
    );

    window.Logger.log({ alternatives });
    return alternatives;
  } catch (e) {
    window.Logger.log('Error fetching swap alternatives: ', e);
  }
}

export default {
  fetchSwaps,
  fetchPremiumSwaps,
  getTriangleSwapDetails,
  getDirectSwapDetails,
  getTriangleSwapAlternatives,
  blockSwapParty,
  fetchInterestCounts,
  fetchBlockedPropositions,
  getSwapDetails
};
