import { render, staticRenderFns } from "./ChatInterestSelect.vue?vue&type=template&id=dcce6212&scoped=true"
import script from "./ChatInterestSelect.vue?vue&type=script&lang=js"
export * from "./ChatInterestSelect.vue?vue&type=script&lang=js"
import style0 from "./ChatInterestSelect.vue?vue&type=style&index=0&id=dcce6212&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcce6212",
  null
  
)

export default component.exports