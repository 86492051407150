import { parseJwt } from '@/utils/jwt';

function checkPaymentRedirect(context) {
  const isReactNative = window.originalPostMessage;
  if (isReactNative) {
    return;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const q = urlParams.get('q');

  if (!q) {
    return;
  }

  const { productType, propositionId } = parseJwt(q);

  if (productType == 'extrasynlighet' && !isReactNative) {
    const propositions = context.rootGetters['myPropositions/propositions'];
    const proposition = propositions.find(
      x => x.propositionId == propositionId
    );

    if (!proposition) {
      return;
    }

    context.commit('setProposition', proposition);
    context.commit('setVisible', true);
  }
}

export default { checkPaymentRedirect };
