<template>
  <div
    :class="['list-type-toggle', 'is-active-' + selectedListType.toLowerCase()]"
  >
    <div
      :class="[
        'toggle-item toggle-item-card',
        { 'is-active': selectedListType == 'CARD' }
      ]"
      @click="setActiveItem('CARD')"
    >
      <BaseIcon
        :icon-file="
          selectedListType == 'CARD' ? 'icon_card_dark' : 'icon_card_light'
        "
      />
    </div>
    <!-- <div
      :class="[
        'toggle-item toggle-item-list',
        { 'is-active': selectedListType == 'LIST' }
      ]"
      @click="setActiveItem('LIST')"
    >
      <BaseIcon
        :icon-file="
          selectedListType == 'LIST' ? 'icon_list_dark' : 'icon_list_light'
        "
      />
    </div> -->
    <div
      v-if="isUserList && isSwapList"
      :class="[
        'toggle-item toggle-item-map',
        { 'is-active': selectedListType == 'MAP' }
      ]"
      @click="setActiveItem('MAP')"
    >
      <BaseIcon
        :icon-file="
          selectedListType == 'MAP' ? 'icon_map_dark' : 'icon_map_light'
        "
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SWAP_LIST_TYPES, SWAP_ITEM_TYPES } from '../../store/modules/swapList';

export default {
  name: 'ListTypeToggle',

  props: {
    isSwapList: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      isUserList: 'swapList/isUserList',
      swapItemMode: 'swapList/swapItemMode',
      swapListType: 'swapList/swapListType'
    }),

    selectedListType() {
      if (this.swapListType === 'LIST' && this.swapItemMode === 'CARDS') {
        return 'CARD';
      } else if (
        this.swapListType === 'LIST' &&
        this.swapItemMode === 'EXCEL'
      ) {
        return 'LIST';
      } else {
        // If this.swapListType === 'MAP'
        return 'MAP';
      }
    }
  },

  methods: {
    ...mapActions({
      setSwapListType: 'swapList/setSwapListType',
      toggleSwapItemMode: 'swapList/toggleSwapItemMode'
    }),

    setActiveItem(value) {
      if (value === 'CARD') {
        this.setSwapListType({ type: SWAP_LIST_TYPES.LIST });
        this.toggleSwapItemMode({ mode: SWAP_ITEM_TYPES.CARDS });
      } else if (value === 'LIST') {
        this.setSwapListType({ type: SWAP_LIST_TYPES.LIST });
        this.toggleSwapItemMode({ mode: SWAP_ITEM_TYPES.EXCEL });
      } else {
        this.setSwapListType({ type: SWAP_LIST_TYPES.MAP });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-type-toggle {
  display: flex;
  background: #e6e8ed;
  padding: 4px;
  border-radius: 40px;
}

.list-type-toggle:after {
  content: '';
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  transition: transform 0.2s ease-in-out;
}

.list-type-toggle.is-active-card:after {
  transform: translateX(0);
}

.list-type-toggle.is-active-list:after {
  transform: translateX(40px);
}

.list-type-toggle.is-active-map:after {
  transform: translateX(80px);
}

.toggle-item {
  display: grid;
  place-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  z-index: 1;

  &:last-of-type {
    margin-right: 0;
  }
}
</style>
