import Vue from 'vue';
import { matchesInitialState } from '@/store/modules/matches';

function setMatches(state, { matches, matchStatus }) {
  state.matches[matchStatus] = matches;
}

function setLoadingMatches(state, { isLoadingMatches }) {
  state.isLoadingMatches = isLoadingMatches;
}

function setLoadingStatusCounts(state, { isLoadingStatusCounts }) {
  state.isLoadingStatusCounts = isLoadingStatusCounts;
}

function setActiveTab(state, activeTab) {
  state.activeTab = activeTab;
}

function setInitialMatchStatusCounts(state, counts) {
  state.initialMatchStatusCounts = counts;
}

function setMatchStatusCounts(state, counts) {
  state.matchStatusCounts = counts;
}

function updateLastMessage(state, data) {
  const allMatches = Object.values(state.matches);
  allMatches.forEach(matchesInTab => {
    if (matchesInTab === null) return;

    const matchIndex = matchesInTab.findIndex(
      match => match.chatRoom.id == data.chatRoomId
    );
    if (matchIndex === -1) return;

    const currentMatch = matchesInTab[matchIndex];

    const conversation = currentMatch.chatRoom?.conversations.find(
      conversation => conversation.ownerId === data.senderId
    );
    const address = conversation ? conversation.owner.address : 'SYSTEM';

    Vue.set(currentMatch, 'lastMessage', {
      message: data.content,
      senderName: address,
      sentAt: data.createdAt
    });

    // Add unread count if the sender is not the current user
    if (
      currentMatch.chatRoom.conversations[0].ownerId !== data.senderId &&
      data.senderId !== -1
    ) {
      Vue.set(currentMatch, 'unreadCount', currentMatch.unreadCount + 1);
    }
  });
}

function resetReadCount(state, selectedMatch) {
  const allMatches = Object.values(state.matches);
  if (!allMatches) {
    return;
  }

  allMatches.forEach(matchesInTab => {
    if (matchesInTab === null) return;

    const match = matchesInTab.find(match => match.id === selectedMatch.id);
    Vue.set(match, 'unreadCount', 0);
  });
}

function resetMatches(state) {
  state.matches = { ...matchesInitialState };
}

export default {
  setMatches,
  setLoadingMatches,
  setLoadingStatusCounts,
  setActiveTab,
  setInitialMatchStatusCounts,
  setMatchStatusCounts,
  updateLastMessage,
  resetReadCount,
  resetMatches
};
