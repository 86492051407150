<template>
  <div class="edit-proposition-details-container">
    <div class="edit-proposition-details">
      <BaseBackButton :link="editPropositionPath" class="back-button" />
      <h1 v-if="proposition" class="page-heading">
        {{ currentResidence.streetAddress }}
      </h1>

      <template v-if="propositionDetails">
        <div class="form-group">
          <BaseInput
            id="rent"
            v-model.number="propositionDetails.rent"
            :valid="!showErrors || isNumeric(propositionDetails.rent)"
            name="rent"
            type="number"
            :label="$t('edit_prop_details_rent')"
            :placeholder="$t('edit_prop_details_rent')"
            :suffix="$t('general_currency_per_month_short')"
            class="form-group-item"
          />

          <BaseInput
            id="rooms"
            v-model.number="propositionDetails.rooms"
            :valid="!showErrors || isNumeric(propositionDetails.rooms)"
            name="rooms"
            type="number"
            :label="$t('edit_prop_details_rooms')"
            :placeholder="$t('edit_prop_details_rooms')"
            :suffix="$tc('general_rooms', 1)"
            class="form-group-item"
          />

          <BaseInput
            id="sqm"
            v-model.number="propositionDetails.sqm"
            :valid="!showErrors || isNumeric(propositionDetails.sqm)"
            name="sqm"
            type="number"
            :label="$t('edit_prop_details_sqm')"
            :placeholder="$t('edit_prop_details_sqm')"
            :suffix="$t('general_sqm_short')"
            class="form-group-item"
          />

          <div>
            <BaseInput
              id="floor"
              ref="floorInput"
              v-model.number="propositionDetails.floor"
              :valid="!showErrors || isNumeric(propositionDetails.floor)"
              name="floor"
              type="number"
              :label="$t('edit_prop_details_floor')"
              :placeholder="$t('edit_prop_details_floor')"
              :suffix="$t('edit_prop_details_steps_short')"
              class="form-group-item"
            />
            <BaseCheckbox
              id="isBottomFloor"
              :label="$t('edit_prop_details_bottom_floor')"
              :value="propositionDetails.floor === 0"
              :selected="propositionDetails.floor === 0"
              class="floor-checkbox"
              @change="handleBottomFloorToggle"
            />
          </div>
        </div>

        <BaseInput
          id="landlord"
          v-model="propositionDetails.landlordName"
          name="landlord"
          type="text"
          :valid="!(hasContactInfo && hasTriedToSave)"
          :label="$t('edit_prop_details_landlord')"
          :placeholder="$t('edit_prop_details_landlord')"
          :error-message="$t('edit_description_error_contact_info')"
          @onChange="handleInput"
        />

        <div class="amenities">
          <h4>{{ $t('edit_prop_details_facilities') }}</h4>
          <BaseCheckbox
            :label="$t('edit_prop_details_bathtub')"
            :value="propositionDetails.bathtub"
            :selected="propositionDetails.bathtub"
            class="checkbox"
            @change="propositionDetails.bathtub = !propositionDetails.bathtub"
          />

          <BaseCheckbox
            :label="$t('edit_prop_details_balcony')"
            :value="propositionDetails.balcony"
            :selected="propositionDetails.balcony"
            class="checkbox"
            @change="propositionDetails.balcony = !propositionDetails.balcony"
          />

          <template v-if="$country.isCountry('se')">
            <BaseCheckbox
              label="Bildad bostadsrättsförening"
              :value="propositionDetails.formedCooperative"
              :selected="propositionDetails.formedCooperative"
              class="checkbox"
              @change="
                propositionDetails.formedCooperative =
                  !propositionDetails.formedCooperative
              "
            />

            <BaseCheckbox
              label="Blivande bostadsrätt"
              :value="propositionDetails.becomingCooperative"
              :selected="propositionDetails.becomingCooperative"
              class="checkbox"
              @change="
                propositionDetails.becomingCooperative =
                  !propositionDetails.becomingCooperative
              "
            />
          </template>

          <BaseCheckbox
            :label="$t('edit_prop_details_elevator')"
            :value="propositionDetails.elevator"
            :selected="propositionDetails.elevator"
            class="checkbox"
            @change="propositionDetails.elevator = !propositionDetails.elevator"
          />

          <BaseCheckbox
            :label="$t('edit_prop_details_fireplace')"
            :value="propositionDetails.fireplace"
            :selected="propositionDetails.fireplace"
            class="checkbox"
            @change="
              propositionDetails.fireplace = !propositionDetails.fireplace
            "
          />
        </div>

        <div class="button-container">
          <BaseButton @click="handleSaveClick">
            <template v-if="isSaving">{{
              $t('edit_user_credentials_loading')
            }}</template>
            <template v-else>{{
              $t('edit_user_credentials_save')
            }}</template></BaseButton
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { lbApiBeta } from '../../utils/axiosConfig';
import formValidations from '../../utils/formValidations';

export default {
  name: 'EditPropositionDetails',

  data() {
    return {
      propositionId: null,
      residenceIndex: null,
      proposition: null,
      showErrors: false,
      isSaving: false,
      propositionDetails: null,
      hasContactInfo: false,
      hasTriedToSave: false,
      inputHasErrors: false
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('edit_prop_details_page_meta') || ''
      };
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      userPropositions: 'myPropositions/propositions'
    }),

    editPropositionPath() {
      return this.$routes.EDIT_PROPOSITION + '/' + this.propositionId;
    },

    currentResidence() {
      return this.proposition.residences[this.residenceIndex - 1];
    },

    isValid() {
      return (
        this.isNumeric(this.propositionDetails.rent) &&
        this.isNumeric(this.propositionDetails.rooms) &&
        this.isNumeric(this.propositionDetails.sqm) &&
        this.isNumeric(this.propositionDetails.floor)
      );
    }
  },

  watch: {
    'propositionDetails.landlordName'(newValue) {
      this.hasContactInfo = formValidations.residenceDescription(
        newValue.trim()
      );
    }
  },

  async created() {
    this.propositionId = this.$route.params.propositionId;
    this.residenceIndex = this.$route.params.residenceIndex;

    window.scrollTo(0, 0);

    await this.getProposition();

    this.getDetails();
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast',
      fetchPropositions: 'myPropositions/fetchPropositions'
    }),

    handleInput() {
      if (this.hasTriedToSave) {
        this.inputHasErrors = this.hasContactInfo;

        if (!this.hasContactInfo) {
          this.hasTriedToSave = false;
        }
      }
    },

    async getProposition() {
      await this.fetchPropositions();

      this.proposition = this.userPropositions.find(
        id => id.propositionId == this.propositionId
      );
    },

    handleBottomFloorToggle() {
      if (this.propositionDetails.floor != 0) {
        this.propositionDetails.floor = 0;
      } else {
        this.propositionDetails.floor = null;
        this.$refs.floorInput.$refs.floor.focus();
      }
    },

    isNumeric(input) {
      return input - 0 == input && ('' + input).trim().length > 0;
    },

    handleSaveClick() {
      this.hasTriedToSave = true;

      if (this.hasContactInfo) {
        this.inputHasErrors = true;

        this.isSaving = false;
      } else if (this.isValid && !this.hasContactInfo) {
        this.inputHasErrors = false;

        this.updateDetails();
        this.isSaving = true;
      } else {
        this.showErrors = true;
      }
    },

    async getDetails() {
      try {
        const { data } = await lbApiBeta.get(
          `/api/v2/proposition/${this.propositionId}/residence/${this.residenceIndex}`
        );

        this.propositionDetails = data;
      } catch (e) {
        console.log(e);
      }
    },

    async updateDetails() {
      await lbApiBeta
        .post(
          `/api/v2/proposition/${this.propositionId}/residence/${this.residenceIndex}`,
          this.propositionDetails
        )
        .then(() => {
          this.setToast({ title: this.$t('toast_changes_saved') });

          this.showErrors = false;

          setTimeout(() => {
            this.$router.push(this.editPropositionPath);
          }, 1500);
        })
        .catch(() => {
          this.setToast({
            title: this.$t('toast_general_error_message'),
            timer: 5
          });
        })
        .then(() => {
          this.isSaving = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-proposition-details-container {
  max-width: none;
  width: 100%;
  background-color: #f8f8fa;
  min-height: 100vh;
}

.edit-proposition-details {
  padding: 0 10px;

  @media ($small-desktop) {
    width: 96%;
  }

  @media ($desktop) {
    max-width: 700px;
    width: 90%;
    margin: 0 auto;
  }
}

.edit-proposition-details .back-button {
  margin-left: -5px;
  margin-top: 20px;

  @media ($desktop) {
    margin-left: 0;
  }
}

.page-heading {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 28px;

  @media ($mobile) {
    margin-top: 24px;
    font-size: 26px;
  }
}

.button-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 200px;

  @media ($desktop) {
    margin-bottom: 50px;
  }
}

.form-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.floor-checkbox {
  margin-top: -10px;
  margin-bottom: 1rem;
}

.amenities {
  margin: 2rem 0;
}

.checkbox {
  margin-bottom: 1rem;
}
</style>
