<template>
  <div class="edit-user-credentials-container">
    <div class="edit-user-credentials">
      <TabNavigation class="tab-navigation">
        <TabNavigationItem @click.native="goToEditProfile">{{
          $t('popup_menu_item_2')
        }}</TabNavigationItem>
        <TabNavigationItem :active="true">{{
          $t('popup_menu_item_9')
        }}</TabNavigationItem>
      </TabNavigation>

      <h4 class="h4">{{ $t('edit_user_credentials_email') }}</h4>

      <BaseInput
        id="email"
        v-model="localUser.email"
        name="email"
        type="email"
        :label="$t('edit_user_credentials_email_label')"
        :placeholder="$t('edit_user_credentials_email_label')"
        icon="envelope"
        :valid="!someValueHasBeenChanged || isEmailValid"
        :error-message="$t('edit_user_credentials_email_error')"
        class="base-input-alt"
        @input="handleInputChange"
      />

      <hr />

      <template v-if="!user.verified">
        <h4 class="h4">{{ $t('edit_user_credentials_change_password') }}</h4>

        <BaseInput
          id="oldPassword"
          v-model="localUser.oldPassword"
          name="oldPassword"
          type="password"
          :label="$t('edit_user_credentials_old_password')"
          :placeholder="$t('edit_user_credentials_old_password')"
          icon="lock"
          :has-show-password="true"
          class="base-input-alt"
          @input="handleInputChange"
        />
        <BaseInput
          id="newPassword"
          v-model="localUser.newPassword"
          name="newPassword"
          type="password"
          :label="$t('edit_user_credentials_new_password')"
          :placeholder="$t('edit_user_credentials_new_password')"
          icon="lock"
          :has-show-password="true"
          class="base-input-alt"
          @input="handleInputChange"
        />

        <p class="input-info">{{ $t('edit_user_credentials_pw_info') }}</p>

        <BaseButton
          icon="thumb-up"
          class="secondary save-button"
          :disabled="!someValueHasBeenChanged || !isEmailValid"
          @click="handleSaveClick()"
        >
          <template v-if="isSaving">{{
            $t('edit_user_credentials_loading')
          }}</template>
          <template v-else>{{ $t('edit_user_credentials_save') }}</template>
        </BaseButton>
      </template>
      <template v-else>
        <h4 class="h4">{{ $t('login_reset_new_password_title') }}</h4>
        <form @submit.stop.prevent="updateBankIdUser()">
          <BaseInput
            id="newPassword"
            v-model="localUser.newPassword"
            name="newPassword"
            type="password"
            :label="$t('edit_user_credentials_new_password')"
            :placeholder="$t('edit_user_credentials_new_password')"
            icon="lock"
            :has-show-password="true"
            class="base-input-alt"
          />

          <BaseInput
            id="newPassword"
            v-model="localUser.newPasswordRepeat"
            name="newPassword"
            type="password"
            :label="$t('login_form_password_repeat')"
            :placeholder="$t('login_form_password_repeat')"
            icon="lock"
            :has-show-password="true"
            class="base-input-alt"
          />
          <input type="submit" hidden />

          <BaseButton
            icon="thumb-up"
            class="secondary save-button"
            type="submit"
          >
            <template v-if="isSaving">{{
              $t('edit_user_credentials_loading')
            }}</template>
            <template v-else>{{ $t('edit_user_credentials_save') }}</template>
          </BaseButton>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import { authenticationApi, lbApiBeta } from '../../utils/axiosConfig';
import { mapGetters, mapActions } from 'vuex';
import formValidations from '@/utils/formValidations';
import TabNavigation from '@/components/Navigation/TabNavigation/TabNavigation';
import TabNavigationItem from '@/components/Navigation/TabNavigation/TabNavigationItem';

export default {
  name: 'EditUserCredentials',

  components: { TabNavigation, TabNavigationItem },

  data() {
    return {
      localUser: {
        currentEmail: '',
        email: '',
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: ''
      },
      someValueHasBeenChanged: false,
      isSaving: false,
      errorMessage: ''
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('meta_title_edit_user_credentials')
      };
    }
  },

  computed: {
    ...mapGetters({
      user: 'app/user'
    }),

    isEmailValid() {
      return formValidations.email(this.localUser.email);
    },
    hasEmailChanged() {
      return this.currentEmail !== this.localUser.email;
    }
  },

  created() {
    this.getUserData();

    window.scrollTo(0, 0);
  },

  // mounted() {
  //   const tabNavigation = document.querySelector('.tab-navigation');
  //   tabNavigation.scrollLeft = tabNavigation.scrollWidth;
  // },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast',
      updateUser: 'app/updateUser'
    }),

    goToEditProfile() {
      this.$router.push(this.$routes.EDIT_PROFILE);
    },

    async handleSaveClick() {
      this.isSaving = true;

      await lbApiBeta
        .post('/api/editprofile/credentials', this.localUser)
        .then(response => {
          if (response.data.success) {
            this.setToast({ title: this.$t('toast_changes_saved') });

            this.updateUser({
              email: this.localUser.email
            });
          } else {
            this.setToast({ title: this.$t(response.data.error) });
          }

          this.someValueHasBeenChanged = false;
        })
        .catch(() => {
          this.setToast({
            title: this.$t('toast_general_error_message')
          });
        })
        .then(() => {
          this.localUser.newPassword = '';
          this.localUser.oldPassword = '';
          this.isSaving = false;
        });
    },

    async updateEmail() {
      const newEmail = this.localUser.email.trim();
      const { data } = await lbApiBeta.post('/api/editprofile/email', {
        email: newEmail
      });

      if (!data.success) {
        if (data.error === 'EMAIL_EXISTS') {
          this.setToast({
            title: this.$t('edit_user_credentials_error_email_exists'),
            timer: 20
          });
        } else {
          this.setToast({
            title: this.$t('toast_general_error_message')
          });
        }

        return false;
      }

      this.currentEmail = this.updateUser({
        email: newEmail
      });
      this.currentEmail = newEmail;
      return true;
    },

    async updateBankIdUser() {
      try {
        this.errorMessage = '';
        this.isSaving = true;

        if (this.hasEmailChanged) {
          const success = await this.updateEmail();
          if (!success) {
            this.isSaving = false;
            return;
          }
        }

        if (!this.localUser.newPassword && !this.localUser.newPasswordRepeat) {
          this.setToast({ title: this.$t('toast_changes_saved') });
          this.isSaving = false;
          return;
        }

        if (this.localUser.newPassword !== this.localUser.newPasswordRepeat) {
          this.setToast({
            title: this.$t('login_reset_new_password_diff_error')
          });
          this.isSaving = false;
          return;
        }

        await authenticationApi.post(`auth/change-password`, {
          password: this.localUser.newPassword
        });

        this.setToast({ title: this.$t('toast_changes_saved') });

        this.localUser.newPassword = '';
        this.localUser.newPasswordRepeat = '';
      } catch (error) {
        this.setToast({
          title: this.$t('login_reset_new_password_error')
        });
      }

      this.isSaving = false;
    },

    handleInputChange() {
      this.someValueHasBeenChanged = true;
    },

    async getUserData() {
      const response = await lbApiBeta.get('/api/editprofile/credentials');

      if (response.status !== 200) {
        return;
      }

      this.localUser = response.data;
      this.currentEmail = this.localUser.email;
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-user-credentials-container {
  max-width: none;
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f9;

  @media ($desktop) {
    padding-top: 90px;
  }
}

.edit-user-credentials {
  max-width: 400px;
  width: 90%;
  margin: 0 auto;

  @media ($small-desktop) {
    width: 96%;
  }
}

.tab-navigation {
  margin-bottom: 50px;

  @media ($mobile) {
    margin-left: -20px;
    width: calc(100% + 40px);
  }
}

.base-input-alt {
  margin-bottom: 24px !important;
}

.save-button {
  margin-top: 20px;

  @media ($desktop) {
    margin-top: 40px;
  }
}

.input-info {
  margin-top: -19px;
}

p {
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
}
</style>
