<template>
  <component
    :is="linkComponentTag"
    v-bind="urlTag"
    class="base-link"
    :target="target"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'BaseLink',
  props: {
    link: {
      type: [String, Object],
      default: ''
    },
    isExternal: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: ''
    }
  },
  computed: {
    urlTag() {
      return this.isExternal ? { href: this.link } : { to: this.link };
    },
    linkComponentTag() {
      return this.isExternal ? 'a' : 'router-link';
    }
  }
};
</script>

<style lang="scss" scoped>
.base-link {
  display: inline-block;
  color: $main-blue;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid $main-blue;

  &:focus,
  &:hover {
    color: #2b8cee;
    border-color: #2b8cee;
  }

  &:visited {
    color: $main-blue;
  }
}
</style>
