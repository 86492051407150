<template>
  <!-- if internal (SPA) link -->
  <router-link v-if="!external" :to="href" class="menu-item">
    <menu-icon :icon="iconClass" class="icon" />
    {{ title }}
    <badge-count :notifications="badge" class="inline left-auto" />
  </router-link>

  <!-- if external link -->
  <a v-else :href="href" class="menu-item">
    <menu-icon :icon="iconClass" class="icon" />
    {{ title }}
    <badge-count :notifications="badge" class="inline left-auto" />
  </a>
</template>

<script>
import MenuIcon from '../Icons/MenuIcon';
import BadgeCount from '../Icons/BadgeCount';

export default {
  name: 'MenuItem',

  components: {
    MenuIcon,
    BadgeCount
  },

  props: {
    href: {
      type: String,
      required: true
    },
    iconClass: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    badge: {
      type: Number,
      default: 0
    },
    external: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-item {
  align-items: center;
  color: #2c3e50;
  display: flex;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 52px;
  padding-left: 22px;
  padding: 0 20px;
  position: relative;
  text-decoration: none;
  width: 100%;

  & div {
    background-position: center;
    background-size: cover;
    height: 20px;
    margin-right: 15px;
    width: 20px;
  }

  &:hover {
    background-color: #f1f1f1;

    .icon {
      color: #2196d8;
      fill: #2196d8;
    }

    .icon.realEstate {
      fill: none;
      stroke: #2196d8;
    }
  }
}

.icon {
  margin-right: 10px;
}

.active {
  border-left: 2px solid $main-blue;
  font-weight: 700;
  padding-left: 20px;
}
</style>
