<template>
  <div class="boost-info">
    <div v-if="statisticsData" class="top">
      <h2 class="title">
        <template v-if="statisticsData.isActive">
          {{ $t('boost_info_title') }}
        </template>
        <template v-else>
          {{ $t('boost_info_title_passed') }}
        </template>
      </h2>

      <div class="text-wrapper">
        <p class="info-text">
          <template v-if="statisticsData.isActive">
            {{ $t('boost_info_text') }}
          </template>
          <template v-else>
            {{ $t('boost_info_text_passed') }}
          </template>
        </p>
      </div>
      <BaseButton
        v-if="!statisticsData.isActive"
        icon-file="icon-rocket-white"
        class="purple cta-button"
        @click.stop="
          setExtraVisibilityPaymentProposition(primaryProposition);
          setExtraVisibilityPaymentVisible(true);
        "
        >{{ $t('boost_cta_button_again') }}</BaseButton
      >
    </div>

    <div class="stats-container">
      <div v-if="statisticsData" class="boost-stats">
        <div class="items">
          <div class="item">
            <div class="heading">{{ $t('boost_banner_box_1') }}</div>
            <div class="stat">
              +{{ statisticsData.views.toLocaleString('sv-SE') }}
            </div>
          </div>

          <div class="item">
            <div class="heading">{{ $t('boost_banner_box_2') }}</div>
            <div class="stat">{{ statisticsData.interestMarks }}</div>
          </div>

          <div class="item">
            <div class="heading">{{ $t('boost_banner_box_3') }}</div>
            <div class="stat">{{ statisticsData.clicks }}</div>
          </div>
        </div>
        <div v-if="primaryProposition.extraVisibility" class="active-until">
          <BaseIcon icon-file="icon-rocket-purple" class="active-until-icon" />
          {{ $t('boost_banner_active_until') }}
          {{
            $dayjs(primaryProposition.extraVisibility.endDate).format('D MMMM')
          }}
        </div>
      </div>

      <div class="list-content">
        <div class="list-info">
          <h2>{{ $t('boost_info_usp_title') }}</h2>

          <ul>
            <li>
              <div>
                <b>{{ $t('boost_info_usp_label1') }}</b> -
                {{ $t('boost_info_usp_text1') }}
              </div>
            </li>
            <li>
              <div>
                <b>{{ $t('boost_info_usp_label2') }}</b> -
                {{ $t('boost_info_usp_text2') }}
              </div>
            </li>
            <li>
              <div>
                <b>{{ $t('boost_info_usp_label3') }}</b> -
                {{ $t('boost_info_usp_text3') }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { lbApiBeta } from '../../utils/axiosConfig';

export default {
  name: 'BoostInfoContent',

  data() {
    return {
      statisticsData: null
    };
  },

  computed: {
    ...mapGetters({
      primaryProposition: 'myPropositions/primaryProposition',
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    })
  },

  created() {
    this.getStatValues();
  },

  methods: {
    ...mapMutations({
      setExtraVisibilityPaymentVisible: 'extraVisibility/setVisible',
      setExtraVisibilityPaymentProposition: 'extraVisibility/setProposition'
    }),

    toggleContent() {
      this.contentIsVisible = !this.contentIsVisible;
      if (this.contentIsVisible) {
        this.getStatValues();
      }
    },

    async getStatValues() {
      try {
        const { data } = await lbApiBeta.get(
          `/api/boost-summary?propositionId=${this.primaryPropositionId}`
        );

        this.statisticsData = data;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
  padding: 0 16px;
  margin-bottom: 40px;
  color: $text-primary;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  font-family: 'OktaNeue';
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #2c3e50;
  margin-top: 20px;
  margin-bottom: 25px;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
}

.info-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #2c3e50;
  margin-top: 0;
  margin-bottom: 10px;
  max-width: 350px;
}

.cta-button {
  margin-top: 24px;
}

.stats-container {
  width: 100%;
  padding-top: 7px;
  background-color: #f5e6dc;
}

.boost-stats {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ($desktop) {
    padding-left: 25%;
    padding-right: 25%;
  }
}

.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  padding: 13px;
}

.item {
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 16px 0;

  .heading {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.01em;
    margin-bottom: 5px;
  }

  .stat {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;

    color: #9f41e9;
  }
}

.active-until {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-top: 7px;
  margin-bottom: 20px;
}

.active-until-icon {
  margin-right: 4px;
}

.list-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 0 250px;
  width: 100%;
  background-color: #f5e6dc;

  @media ($desktop) {
    padding: 60px 40px 250px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 133px;
    background-image: url('../../assets/svg/line-purple.svg');
    background-size: contain;

    @media (min-width: 400px) {
      background-size: 100% 80%;
    }
  }

  @media ($mobile) {
    &::after {
      content: '';
      position: absolute;
      bottom: 50px;
      left: 0;
      width: 216px;
      height: 140px;
      background-image: url('../../assets/svg/rocket-with-line.svg');
      background-size: contain;
    }
  }
}

.list-info {
  position: relative;
  color: #2c3e50;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;

  @media ($desktop) {
    &::after {
      content: '';
      position: absolute;
      bottom: -170px;
      left: 6px;
      width: 216px;
      height: 131px;
      background-image: url('../../assets/svg/rocket-with-line.svg');
      background-size: contain;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 30px;
  }

  ul {
    margin: 0;
    margin-bottom: 12px;
    padding: 0 30px;

    @media ($desktop) {
      padding-right: 0;
    }
  }

  li {
    list-style-type: none;
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #2c3e50;
    margin-bottom: 20px;

    b {
      font-weight: 600;
    }

    &::before {
      flex-shrink: 0;
      margin-right: 16px;
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background-size: contain;
      background-image: url('../../assets/svg/dot-purple.svg');
    }
  }
}
</style>
