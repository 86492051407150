var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('residence_switcher_desktop_switch_apt'))+" ")]),_c('div',{staticClass:"switcher-wrapper"},[_c('div',{class:['residence', { active: _vm.currentResidenceIndex === 0 }],on:{"click":function () { return _vm.setCurrentResidence({ index: 0 }); }}},[_c('div',{staticClass:"residence-image",style:([
          _vm.currentProposition.residences[0].images.length
            ? {
                backgroundImage: ("url(" + (_vm.currentProposition.residences[0].images[0].fileName) + ")"),
                opacity: 1,
                backgroundSize: 'cover'
              }
            : ''
        ])}),_c('div',{staticClass:"residence-info"},[_c('div',{staticClass:"residence-city"},[_vm._v(" "+_vm._s(_vm.currentProposition.residences[0].postalAddress)+" ")]),_c('div',{staticClass:"residence-address"},[_vm._v(" "+_vm._s(_vm.currentProposition.residences[0].streetAddress)+" ")])])]),_c('div',{class:['residence', { active: _vm.currentResidenceIndex === 1 }],on:{"click":function () { return _vm.setCurrentResidence({ index: 1 }); }}},[_c('div',{staticClass:"residence-image",style:([
          _vm.currentProposition.residences &&
          _vm.currentProposition.residences[1] &&
          _vm.currentProposition.residences[1].images &&
          _vm.currentProposition.residences[1].images.length
            ? {
                backgroundImage: ("url(" + (_vm.currentProposition.residences[1].images[0].fileName) + ")"),
                opacity: 1,
                backgroundSize: 'cover'
              }
            : ''
        ])}),(
          _vm.currentProposition.residences && _vm.currentProposition.residences[1]
        )?_c('div',{staticClass:"residence-info"},[_c('div',{staticClass:"residence-city"},[_vm._v(" "+_vm._s(_vm.currentProposition.residences[1].postalAddress)+" ")]),_c('div',{staticClass:"residence-address"},[_vm._v(" "+_vm._s(_vm.currentProposition.residences[1].streetAddress)+" ")])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }