<template>
  <div
    class="user-activity-details"
    :class="{
      'two-part': swapStyle === 'two-part',
      'three-part': swapStyle === 'three-part'
    }"
  >
    <div class="activity-column">
      <div class="activity-title">{{ $t('chat_last_active') }}</div>
      <div class="activity-value">{{ getLastActiveAt(lastActive) }}</div>
    </div>

    <div class="activity-column">
      <div class="activity-title">{{ $t('chat_response_frequency') }}</div>
      <div class="activity-value">
        {{ responseFrequency ? `${responseFrequency}%` : '-' }}
      </div>
    </div>

    <div class="activity-column">
      <div class="activity-title">{{ $t('chat_response_time') }}</div>
      <div class="activity-value">{{ getAvgResponseTime(responseTime) }}</div>
    </div>
  </div>
</template>

<script>
import { lbApiBeta } from '../../../utils/axiosConfig';

export default {
  props: {
    propositionId: {
      type: String,
      required: true
    },
    swapStyle: {
      type: String,
      required: false,
      default: null // two-part or three-part
    }
  },

  data() {
    return {
      lastActive: null,
      responseFrequency: null,
      responseTime: null
    };
  },

  watch: {
    async propositionId(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.init();
      }
    }
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      lbApiBeta.get(`/activity/${this.propositionId}`).then(res => {
        if (!res.data) {
          return;
        }

        this.lastActive = res.data.lastLogin;
        this.responseFrequency = res.data.responseFrequency;
        this.responseTime = res.data.avgResponseTime;
      });
    },

    getLastActiveAt(timeStamp) {
      if (!timeStamp) {
        return '-';
      }

      const lastActiveMs = new Date(timeStamp).getTime();
      const nowMs = new Date().getTime();

      const diff = nowMs - lastActiveMs;

      const hours = (diff / 1000 / 60 / 60).toFixed();
      if (isNaN(hours)) {
        return '-';
      }

      if (hours < 1) {
        return this.$t('swap_overview_active_now');
      } else if (hours > 24) {
        const days = (hours / 24).toFixed();
        return `${days} ${
          days > 1
            ? this.$t('swap_overview_days')
            : this.$t('swap_overview_day')
        } ${this.$t('swap_overview_ago')}`;
      } else {
        return `${hours} ${
          hours > 1
            ? this.$t('swap_overview_hours')
            : this.$t('swap_overview_hour')
        }  ${this.$t('swap_overview_ago')}`;
      }
    },

    getAvgResponseTime() {
      if (this.responseTime === null) {
        return '-';
      }

      const hours = (this.responseTime / 60 / 60).toFixed();
      if (isNaN(hours)) {
        return '-';
      }

      if (hours < 1) {
        return this.$t('user_card_within_an_hour');
      } else if (hours < 25) {
        return `${this.$t('user_card_within')} ${hours} ${this.$t(
          'user_card_hours'
        )}`;
      } else {
        const days = (hours / 24).toFixed();
        return `${this.$t('user_card_within')} ${days} ${
          days > 1 ? this.$t('user_card_days') : this.$t('user_card_day')
        }`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-activity-details {
  text-align: center;
  margin-top: 10px !important;
  padding: 8px 14px !important;
  background: #fafafa;
  border-top: 1px solid rgba(28, 45, 65, 0.08);
  border-bottom: 1px solid rgba(28, 45, 65, 0.08);
  margin-bottom: -1px !important;
}

.user-activity-details.two-part {
  text-align: left;
}

.user-activity-details.three-part {
  text-align: left;
}

.activity-value {
  display: inline-block;
  font-size: 0.7rem;
  font-weight: bold;

  @media ($desktop) {
    margin-top: 4px;
  }
}

.activity-title-icon {
  display: inline-block;
}

.activity-column {
  display: inline-block;
  width: 33%;
  font-size: 0.8rem;
  max-width: 125px;
  vertical-align: top;
  @media ($mobile) {
    width: 100%;
    max-width: 90px;
  }
}

.user-activity-details.three-part .activity-column {
  @media ($mobile) {
    margin-bottom: 5px;
  }
}

.activity-title {
  color: #878b9d;
  font-size: 0.6rem;
}
</style>
