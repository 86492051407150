<template>
  <div class="menu-wrapper">
    <StickySidebar key="sticky-sidebar" :top-spacing="100">
      <transition-group name="shuffle" tag="div" class="menu-sticky">
        <PropositionSwitcher
          v-if="appReady && activePropositions.length > 1"
          key="proposition-switcher"
          class="proposition-switcher-wrapper"
        />

        <div key="menu-container" class="menu-container">
          <router-link
            class="menu-link"
            :to="$t('path_my_propositions', $routeLocale)"
          >
            <MenuIcon icon="realEstate" :mask="false" class="icon" />
            {{ $t('side_menu_my_ads') }}
          </router-link>

          <router-link
            v-if="$country.isFeatureEnabled('PAYMENT')"
            class="menu-link icon-payments"
            :to="$routes.PAYMENT_HISTORY"
          >
            <MenuIcon icon="wallet" :mask="false" class="icon" />
            {{ $t('side_menu_payment_history') }}
          </router-link>

          <router-link
            class="menu-link icon-blocked"
            :to="$t('path_removed_suggestions', $routeLocale)"
          >
            <MenuIcon icon="blockedHouse" :mask="false" class="icon" />
            {{ $t('side_menu_removed_swap_suggestions') }}
          </router-link>

          <!-- <a
            class="menu-link icon-add"
            :href="$t('path_add_proposition', $routeLocale)"
          >
            <MenuIcon icon="add" :mask="false" class="icon" />
            {{ $t('side_menu_add_ad') }}
          </a> -->
        </div>
      </transition-group>
    </StickySidebar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import MenuIcon from '../Icons/MenuIcon';
import PropositionSwitcher from '../../components/PropositionSwitcher/PropositionSwitcher';
import StickySidebar from '@/components/StickySidebar';

export default {
  name: 'SideMenu',

  components: {
    MenuIcon,
    PropositionSwitcher,
    StickySidebar
  },

  props: {
    hideCta: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      hasPendingOrders: false
    };
  },

  computed: {
    ...mapState('payment', ['hasPaymentToken']),
    ...mapGetters({
      appReady: 'app/ready',
      isMobile: 'screenSize/isMobile',
      propositions: 'myPropositions/propositions'
    }),

    activePropositions() {
      if (!this.propositions) return [];
      return this.propositions.filter(proposition => proposition.active);
    }
  },

  async created() {}
};
</script>

<style lang="scss" scoped>
.menu-wrapper {
  @media ($desktop) {
    width: 25%;
  }
}

.menu-sticky {
  display: flex;
  flex-direction: column;
  @media ($desktop) {
    flex: 1;
    align-self: start;
  }
}

.proposition-switcher-wrapper {
  margin-bottom: 10px;
}

.menu-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  @media ($mobile) {
    @include content();
    -ms-overflow-style: -ms-autohiding-scrollbar; /* [4] */
    -webkit-overflow-scrolling: touch; /* [3] */
    display: block;
    order: 1;
    overflow-x: auto; /* [2] */
    padding: 23px 5% 30px;
    scrollbar-width: none;
    white-space: nowrap; /* [1] */
    width: 100%;

    > * + * {
      margin-left: 30px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.menu-link {
  align-items: center;
  color: $text-primary;
  display: flex;
  font-weight: 400;
  margin-bottom: 35px;
  text-decoration: none;
  width: 100%;

  @media ($mobile) {
    display: initial;
    font-size: 14px;
    font-weight: 400;

    &.selected {
      border-bottom: 2px solid #2196d8;
      color: #2196d8;
      font-weight: 600;
      padding-bottom: 7px;
    }
  }
}

.active {
  font-weight: 600;
}

.icon {
  margin-right: 10px;

  @media ($mobile) {
    display: none;
  }

  &-klarna {
    background-image: url('../../assets/svg/klarna.svg');
    background-position: center;
    background-size: contain;
    width: 20px;
    height: 20px;
  }
}

.primary-button {
  background: #fff;
  border: 1px solid #ddd;
  color: $text-primary;
  font-size: 16px;
  font-weight: 600;
  height: inherit;

  @media ($mobile) {
    background: #fff;
    border: 0;
    color: $text-secondary;
    font-size: 14px;
    font-weight: 600;
    height: inherit;
    padding: 0;
  }
}

.text-important {
  color: $accent-green;
}
</style>
