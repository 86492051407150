const progressBar = state => state.progressBar;
const showContextualTutorial = state => state.showContextualTutorial;
const tutInterestMarks = state => state.tutInterestMarks;
const activeStep = state => state.activeStep;
const status = state => state.status;
const debug = state => state.debug;

const escClose = state => state.escClose;

export default {
  progressBar,
  showContextualTutorial,
  tutInterestMarks,
  activeStep,
  status,
  debug,
  escClose
};
