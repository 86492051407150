<template>
  <div>
    <PropositionPage
      v-if="pageModalActivePage === 'proposition'"
      :proposition-id="propositionViewModalId"
      :in-modal="true"
    />
    <SwapView v-if="pageModalActivePage === 'swapdetails'" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PropositionPage from '@/views/Proposition/Proposition';
import SwapView from '@/components/SwapList/SwapView';

export default {
  name: 'PropositionAndSwapDetails',

  components: {
    PropositionPage,
    SwapView
  },

  props: {
    propositionViewModalId: {
      type: Number,
      default: null
    },
    propositionViewModalType: {
      type: String,
      default: null
    }
  },

  computed: {
    ...mapGetters({
      pageModalActivePage: 'ui/pageModalActivePage',
      swapListType: 'swapList/swapListType'
    })
  },

  watch: {
    pageModalActivePage() {
      if (this.pageModalActivePage === 'swapdetails') {
        this.handleSwapDetailsClick();
      }
    }
  },

  mounted() {
    this.hideMobileTabBar();
  },

  beforeDestroy() {
    if (this.swapListType !== 'MAP') {
      this.showMobileTabBar();
    }
  },

  methods: {
    ...mapActions({
      fetchSwapDetails: 'swapList/fetchSwapDetails',
      hideMobileTabBar: 'menu/hideMobileTabBar',
      showMobileTabBar: 'menu/showMobileTabBar'
    }),

    handleSwapDetailsClick() {
      this.fetchSwapDetails({
        propositionId: this.propositionViewModalId,
        type: this.propositionViewModalType
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
