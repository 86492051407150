<template>
  <transition name="spring" mode="out-in" appear>
    <div id="js-popup-menu" class="popup-menu">
      <UserCard :user-avatar="user.avatar" />

      <template
        v-if="$country.isFeatureEnabled('BANKID') && isAuthenticated && user"
      >
        <router-link
          v-if="!user.verified"
          :to="$routes.EDIT_PROFILE"
          class="verify-action"
        >
          <BaseIcon
            icon="verified"
            :width="20"
            :height="20"
            class="verified-badge"
          />
          <span>Verifiera dig med BankID</span>
        </router-link>
      </template>

      <MenuItems />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import UserCard from '../PopupMenuContent/UserCard';
import MenuItems from '../PopupMenuContent/MenuItems';
import handleClickoutside from '../../utils/handleClickOutside';

export default {
  name: 'PopupMenu',
  components: {
    UserCard,
    MenuItems
  },

  computed: {
    ...mapGetters({
      menuOpen: 'menu/isMenuOpen',
      user: 'app/user',
      isAuthenticated: 'app/isAuthenticated'
    })
  },

  mounted: function () {
    document.addEventListener('mouseup', this.closeOnClickOutside);
  },

  beforeDestroy: function () {
    document.removeEventListener('mouseup', this.closeOnClickOutside);
  },

  methods: {
    closeOnClickOutside(e) {
      const menu = document.getElementById('js-popup-menu');
      const menuToggle = document.getElementById('js-popup-toggle');
      handleClickoutside({
        event: e,
        elements: [menu, menuToggle],
        action: 'menu/closeMenu'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-menu {
  background-color: #fff;
  border-top: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 35px rgb(0 0 0 / 15%);
  overflow: hidden;
  left: calc(100% - 20px);
  position: absolute;
  top: 76px;
  transform: translateX(-100%);
  width: 320px;
  user-select: none;

  @media ($small-desktop) {
    left: calc(100% - 15px);
  }
}

.spring-enter-active,
.spring-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.spring-enter,
.spring-leave-active {
  opacity: 0;
}

.verify-action {
  display: flex;
  align-items: center;
  padding: 14px 12px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
  border: 1px solid #f1f2f6;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #2c3e50;
}

.verified-badge {
  margin-right: 6px;
}
</style>
