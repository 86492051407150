<template>
  <div class="responsive-modal-content">
    <div class="header">
      <div class="heading"><slot name="heading" /></div>
    </div>

    <div class="body">
      <slot name="body" />
    </div>
    <div class="footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportScreenLayout'
};
</script>

<style lang="scss" scoped>
.responsive-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  .scroll-container::-webkit-scrollbar {
    display: none;
  }

  @media ($mobile) {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @media ($mobile) and (orientation: landscape) {
    max-height: 23.5rem;
  }

  /* for smaller screen in portrait (device-aspect-ratio: 40/71) */
  @media ($smaller-screen) and (device-aspect-ratio: 40/71) and (orientation: portrait) {
    max-height: 32rem;
  }

  @media ($smaller-screen) and (orientation: landscape) {
    max-height: 20rem;
  }

  .header {
    display: flex;
    justify-content: center;
    margin: 2rem 1.5rem 1rem 1.5rem;

    .heading {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 130%;
      text-align: center;
      color: #213a75;
    }
  }

  .body {
    padding: 0 1rem;
  }

  .footer {
    padding: 1.25rem;
    border-top: 1px solid #ebebeb;
    position: sticky;
    bottom: 0;
    background: #ffffff;

    button {
      border: 2px solid;
      font-size: 16px;
    }

    @media ($desktop) {
      padding: 1.25rem 0;
      margin-top: 1.5rem;
      margin-left: 0.8rem;
      margin-right: 0.8rem;
    }

    @media ($mobile) and (orientation: landscape) {
      margin-top: 0;
    }
  }

  @media ($desktop) {
    padding: 0.5rem 3rem 1.5rem 3rem;
  }
}
</style>
