import vue from 'vue';
import { SearchFilter, getActiveFilterKeys } from '@/models/search-filter';

const mutations = {
  setGeolocations(state, geolocations) {
    state.filter.filter.geolocations = geolocations;
  },

  setLoading(state, val) {
    vue.set(state, 'isLoading', val);
  },

  setLoadingCoverage(state, val) {
    vue.set(state, 'isLoadingCoverage', val);
  },

  removeCoverageItem(state, coverageId) {
    const index = state.coverageData.results.findIndex(
      x => x.id === coverageId
    );

    if (index !== -1) vue.delete(state.coverageData.results, index);
  },

  addCoverageItem(state, coverage) {
    if (!state.coverageData) {
      state.coverageData = { results: [] };
    }

    state.coverageData.results.push(coverage);
  },

  setData(state, data) {
    vue.set(state, 'data', data);
  },

  setPoints(state, points) {
    if (!state.data) state.data = {};
    vue.set(state.data, 'points', points);
  },

  setCoverageData(state, data) {
    vue.set(state, 'coverageData', data);
  },

  setItem(state, item) {
    if (state.data && state.data.results) {
      const index = state.data.results.findIndex(
        currentItem => currentItem.propositionId == item.propositionId
      );

      if (index !== -1) vue.set(state.data.results, index, item);
      else Logger.log('setItem: Could not find item', item);
    }
  },

  setFilterDefaultByKey(state, key) {
    const defaultFilter = new SearchFilter({
      type: state.filter.type
    });

    if (key === 'swapTypes' || key === 'searchArea')
      vue.set(state.filter, key, defaultFilter[key]);
    else vue.set(state.filter.filter, key, defaultFilter.filter[key]);

    countActiveFilterKeys(state);
  },

  setFilter(state, obj) {
    if (state.filter.filter) {
      vue.set(state.filter, 'filter', {
        ...state.filter.filter,
        ...obj
      });
    }

    countActiveFilterKeys(state);
  },

  setRootFilter(state, obj) {
    if (state.filter) {
      vue.set(state, 'filter', {
        ...state.filter,
        ...obj
      });
    }

    countActiveFilterKeys(state);
  },

  resetFilter(state, type) {
    const newFilter = new SearchFilter({
      type: type || state.filter.type
    });

    vue.set(state, 'filter', newFilter);

    countActiveFilterKeys(state);
  },

  setSearchBottomBar(state, isActive) {
    state.searchBottomBarActive = isActive;
  }
};

function countActiveFilterKeys(state) {
  if (!state.filter || !state.filter.filter)
    vue.set(state, 'activeFilterKeys', []);
  else vue.set(state, 'activeFilterKeys', getActiveFilterKeys(state.filter));
}

export default mutations;
