<template>
  <article class="card">
    <router-link
      class="card__link"
      :to="$routes.NEWS + '/' + encodedTitle + '/' + article.id"
    >
      <div class="card__left-column">
        <img :src="largestPossibleThumbnail" alt="" class="card__image" />
      </div>
      <div class="card__right-column">
        <!-- <div class="card__date">{{ formattedDate }}</div> -->
        <h3 class="card__title">{{ article.title }}</h3>
        <p v-if="article.lead" class="card__lead">
          {{
            highlightedArticle
              ? truncateText(article.lead, 350)
              : truncateText(article.lead, 145)
          }}
        </p>
      </div>
    </router-link>
  </article>
</template>

<script>
export default {
  name: 'MCard',
  props: {
    article: {
      type: Object,
      default: null
    },
    highlightedArticle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedDate() {
      const date = new Date(this.article.published_at);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(
        this.$country.getLanguageFromCountry(),
        options
      );
    },
    encodedTitle() {
      if (!this.article) return '';
      return this.getSlug(this.article.title);
    },
    largestPossibleThumbnail() {
      if (this.article.image.formats.medium !== undefined) {
        return this.article.image.formats.medium.url;
      } else if (this.article.image.formats.small !== undefined) {
        return this.article.image.formats.small.url;
      } else {
        return null;
      }
    }
  },
  methods: {
    truncateText(text, length) {
      if (text.length <= length) {
        return text;
      }
      return text.slice(0, length) + '...';
    },
    getSlug(text) {
      return text
        .toString()
        .normalize('NFKD')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-');
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  grid-column: span 12;
  margin-bottom: 3rem;

  @media ($desktop) {
    grid-column: span 4;
  }
}

.card--large {
  grid-column: span 12;

  @media ($desktop) {
    margin-bottom: 28px;
  }
}

.card--large .card__link {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 1rem;
}

.card--large .card__left-column {
  grid-column: span 12;

  @media ($desktop) {
    grid-column: span 7 / span 7;
  }
}

.card--large .card__right-column {
  grid-column: span 12;

  @media ($desktop) {
    grid-column: span 5 / span 5;
    padding-top: 32px;
    padding-left: 16px;
  }
}

.card__link,
.card__link:visited {
  display: block;
  color: #1c2d41;
  text-decoration: none;
}

.card__image {
  width: 100%;
  margin-bottom: 14px;

  @media ($desktop) {
    height: 260px;
    object-fit: cover;
  }
}

.card--large .card__image {
  height: auto;
  margin-bottom: 20px;
}

.card__date {
  color: gray;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 4px;
}

.card--large .card__date {
  font-size: 16px;
  margin-bottom: 8px;
}

.card__title {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 6px;
  margin-top: 0;
}

.card--large .card__title {
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 12px;
}

.card__lead {
  font-size: 14px;
  line-height: 1.8;
  margin: 0;
}

.card--large .card__lead {
  font-size: 16px;
}
</style>
