<template>
  <button
    :class="[
      { 'primary-button': !newPrimaryButton },
      { 'primary-button-new': newPrimaryButton },
      { 'full-width': fullWidth },
      { warn: warn },
      { square: square },
      { accent: accent },
      { rounded },
      { uppercase },
      { 'small-desktop-aware': smallDesktopAware },
      { disabled },
      { inverted: invertColors }
    ]"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'PrimaryButton',

  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    warn: {
      type: Boolean,
      default: false
    },
    square: {
      type: Boolean,
      default: false
    },
    accent: {
      type: Boolean,
      default: false
    },
    smallDesktopAware: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    invertColors: {
      type: Boolean,
      default: false
    },
    newPrimaryButton: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.primary-button {
  background-color: $transfer-blue;
  -webkit-appearance: none;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    outline: none;
  }

  &.warn {
    background-color: red;
  }

  &.disabled {
    cursor: default;
    opacity: 0.7;
  }
}

.full-width {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.square {
  border-radius: 0;
}

.rounded {
  border-radius: 25px;
}

.accent {
  background-color: $accent-green;
}

.small-desktop-aware {
  @media ($small-desktop) {
    font-size: 12px;
    padding: 0 15px;
  }
}

.inverted {
  color: $transfer-blue;
  background-color: white;
}

.primary-button-new {
  display: inline-block;
  margin: 0;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-right: 36px;
  padding-left: 36px;
  border-radius: 25px;
  color: #fff;
  background-color: #2ec973;
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  border-width: 0;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #25ac61;
  }

  &:active {
    background-color: #2ec973;
  }
}
</style>
