<template>
  <div class="conversion-banner">
    <div class="conversion-banner-image">
      <!-- <img src="../assets/img/doggy.png" alt=""> -->
    </div>
    <div class="conversion-banner-content">
      <h2>{{ $t('conversion_banner_heading') }}</h2>
      <ul class="conversion-banner-list">
        <li>{{ $t('conversion_banner_list_1') }}</li>
        <li>{{ $t('conversion_banner_list_2') }}</li>
        <li>{{ $t('conversion_banner_list_3') }}</li>
      </ul>
      <BaseButton
        :link="$t('path_add_proposition', $routeLocale)"
        :is-external="true"
        class="full-width conversion-button"
      >
        {{ $t('conversion_banner_button') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConversionBanner'
};
</script>

<style lang="scss" scoped>
.conversion-banner {
  display: grid;
  margin-top: 80px;
  margin-bottom: 60px;
  background-color: #2c3e50;
  color: #fff;

  @media ($desktop) {
    max-width: 1200px;
    width: 90%;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr 1fr;
    border-radius: 4px;
    overflow: hidden;
  }
}

.conversion-banner-image {
  background-image: url('../../../assets/img/tjej_comp.jpg');
  background-size: cover;
  background-position: top;
  height: 100%;

  @media ($mobile) {
    height: 250px;
  }
}

.conversion-banner-content {
  width: 90%;
  max-width: 400px;
  margin: auto;
  padding: 2.5rem 1.5rem;
  font-size: 14px;
  font-weight: 600;

  @media ($desktop) {
    padding: 3rem 2.5rem;
  }

  h2 {
    font-size: 20px;
    margin-top: 0;
  }
}

.conversion-banner-list {
  list-style-type: none;
  margin-bottom: 2rem;
  padding: 0 25px;

  li {
    position: relative;
    margin-bottom: 0.5rem;
  }

  li::before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: -25px;
    margin-top: -2px;
    top: 0;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../assets/svg/check-mark-green.svg');
  }
}

.conversion-button {
  margin-bottom: 0.5rem;
}
</style>
