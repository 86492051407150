<template>
  <div class="interest-level-select-wrapper" v-bind="$attrs" v-on="$listeners">
    <span v-if="color !== null" class="dot" :class="{ [color]: color }"></span>
    <span :class="{ selected: selected }"> <slot></slot> </span>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.interest-level-select-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
}

.dot {
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-right: 10px;
  border-radius: 50%;
}

.interest-level-select-wrapper > .selected {
  font-weight: 600;
}

.dot.blue {
  background-color: $transfer-blue;
}

.dot.orange {
  background-color: orange;
}

.dot.red {
  background-color: $sweet-red;
}

.dot.green {
  background-color: $transfer-green;
}
</style>
