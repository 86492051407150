<template>
  <div class="full-interest-wrapper">
    <div class="title">{{ $t('swap_full_interest_title') }} &#x1f44d;</div>
    <div class="subtitle">{{ $t('swap_full_interest_subtitle') }}</div>
    <interest-indicators
      :interests="isTriangle ? [true, true, true] : [true, true]"
    />
    <div class="stepper-wrapper">
      <div class="stepper">
        <div class="stepper-flow">
          <div class="stepper-step">1</div>
          <div class="stepper-line" />
        </div>
        <div class="stepper-item">
          <div class="stepper-item-title">
            {{ $t('swap_full_interest_step1_title') }}
          </div>
          <div
            class="stepper-item-content"
            v-html="$t('swap_full_interest_step1_body')"
          />
        </div>
      </div>
      <div v-if="$country.isFeatureEnabled('SWAP_APPLICATION')" class="stepper">
        <div class="stepper-flow">
          <div class="stepper-step">2</div>
          <div class="stepper-line" />
        </div>
        <div class="stepper-item">
          <div class="stepper-item-title">
            {{ $t('swap_full_interest_step2_title') }}
          </div>
          <div class="stepper-item-content">
            {{ $t('swap_full_interest_step2_body1') }}
            <a :href="$routes.SWAP_APPLICATION">{{
              $t('swap_full_interest_step2_body2')
            }}</a
            >.
            {{ $t('swap_full_interest_step2_body3') }}
          </div>
        </div>
      </div>
      <div class="stepper">
        <div class="stepper-flow">
          <div class="stepper-step">
            {{ $country.isFeatureEnabled('SWAP_APPLICATION') ? '3' : '2' }}
          </div>
        </div>
        <div class="stepper-item">
          <div class="stepper-item-title">
            {{ $t('swap_full_interest_step3_title') }}
          </div>
          <div
            class="stepper-item-content"
            v-html="$t('swap_full_interest_step3_body')"
          />
        </div>
      </div>
    </div>

    <div class="swap-info">
      {{ $t('swap_full_interest_swap_info') }} {{ fullSince }}
    </div>
  </div>
</template>

<script>
import InterestIndicators from './InterestIndicators';
export default {
  name: 'SwapFullInterest',

  components: {
    InterestIndicators
  },

  props: {
    isTriangle: {
      type: Boolean,
      required: true,
      default: false
    },
    fullSinceDate: {
      type: String,
      required: true,
      default: ''
    }
  },

  computed: {
    fullSince() {
      if (!this.fullSinceDate) return '';

      return this.fullSinceDate.split('T')[0];
    }
  }
};
</script>

<style lang="scss" scoped>
.full-interest-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 8px;
}
.subtitle {
  font-weight: 600;
  color: $text-secondary;
  margin-bottom: 8px;
}

.stepper-wrapper {
  padding: 20px 20px 5px 20px;
}

.stepper {
  display: flex;
  width: 100%;
  margin-top: 3px;
}

.stepper-flow {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stepper-step {
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  margin-bottom: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $transfer-blue;
  background-color: $baby-blue;
  border-radius: 50%;
  font-weight: 700;
  color: $transfer-blue;
}
.stepper-line {
  height: 100%;
  width: 3px;
  background-color: rgba(50, 50, 50, 0.1);
}
.stepper-item {
  width: 100%;
  padding: 4px 0 20px 14px;

  &-title {
    font-weight: 700;
    margin-top: 2px;
    color: $transfer-blue;
  }

  &-content {
    font-weight: 600;
    margin-top: 6px;
    font-size: 0.85rem;
    line-height: 130%;
  }
}

.stepper-item-content {
  p {
    margin-top: 0;
  }
}

.swap-info {
  font-weight: 600;
  font-size: 0.8rem;
  text-align: center;
}

.discount-label {
  color: #878b9d;
}
</style>
