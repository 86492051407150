<template>
  <component
    :is="blurContent || disableLink ? 'div' : 'router-link'"
    ref="swapItem"
    :to="{
      query: {
        propositionPageId: swap.propositionId,
        type: swap.details.type
      }
    }"
    :class="['swap-item', { 'blur-content': blurContent }]"
    @click="handlePropositionCardClick"
    @click.native="handlePropositionCardClick"
    @click.right.native="handlePropositionCardRightClick"
  >
    <div class="swap-item-slider">
      <div v-if="swap.fromBoost" class="info-from-boost">
        <BaseIcon
          icon-file="icon-rocket-white"
          :width="18"
          :height="18"
          class="icon"
        />
        {{
          swap.details.fullSwapSince
            ? $t('proposition_card_full_int_boost')
            : $t('proposition_card_int_boost')
        }}
      </div>

      <div
        v-if="swap.inSwapProcess && !swap.fromBoost"
        :class="['swap-type-banner swap-process']"
      >
        {{ $t('swap_item_in_swap_process') }}
      </div>

      <div v-if="swap.boosted" class="boost-icon">
        <BaseIcon icon-file="icon-rocket-white" />
      </div>

      <SwapListSlider
        v-if="
          currentShownResidence.imageCount > 0 && currentShownResidence.image
        "
        :residence-index="currentResidence"
        :primary-image="currentShownResidence.image"
        :image-count="currentShownResidence.imageCount"
        :proposition-id="swap.propositionId"
        :lock-scroll="lockScroll"
        :unlock-scroll="unlockScroll"
        :proposition="currentShownResidence"
        @clickImage="goToEditPropositionImages"
      />
      <div v-else class="placeholder-container">
        <div class="img-placeholder"></div>
        <p>{{ $t('proposition_missing_image_text') }}</p>
      </div>
    </div>

    <div v-if="isMobile" class="swap-item-header">
      <div class="address-info">
        <div class="address">
          {{ currentShownResidence.streetAddress }}
          <BaseIcon
            v-if="swap.verified"
            icon="verified"
            :width="18"
            :height="18"
            class="verified-badge"
          />
        </div>

        <div v-if="is2to1" class="address secondary">
          & &nbsp;
          <span class="underline" @click.prevent.stop="switchCurrentShown">{{
            currentSecondaryResidence.streetAddress
          }}</span>
          <div class="shift-icon"></div>
        </div>

        <div
          :class="[
            { 'diff-value': swap.diff && swap.diff.geolocation },
            'postal-area'
          ]"
        >
          {{ currentShownResidence.postalArea }}{{ is2to1 ? ' & ' : ''
          }}{{ is2to1 ? currentSecondaryResidence.postalArea : '' }}
          {{
            isImpersonated && swap.diff && swap.diff.geolocationDepth
              ? `[${swap.diff.geolocationId} (${swap.diff.geolocationDepth})]`
              : ''
          }}
        </div>
      </div>

      <div class="icon-area">
        <div
          v-if="isUserList"
          :class="['favourite-icon', { marked: swap.isFavourite }]"
          @click.stop.prevent="handleMarkFavourite"
        ></div>
      </div>
    </div>

    <div class="swap-item-body">
      <div v-if="!isMobile" class="swap-item-header">
        <div class="address-info">
          <div class="address">
            {{ currentShownResidence.streetAddress }}
            <BaseIcon
              v-if="swap.verified"
              icon="verified"
              :width="18"
              :height="18"
              class="verified-badge"
            />
          </div>
          <div v-if="is2to1" class="address secondary">
            & &nbsp;
            <span class="underline" @click.prevent.stop="switchCurrentShown">{{
              currentSecondaryResidence.streetAddress
            }}</span>
            <div class="shift-icon"></div>
          </div>

          <div
            :class="[
              { 'diff-value': swap.diff && swap.diff.geolocation },
              'postal-area'
            ]"
          >
            {{ currentShownResidence.postalArea }}{{ is2to1 ? ' & ' : ''
            }}{{ is2to1 ? currentSecondaryResidence.postalArea : '' }}
            {{
              isImpersonated && swap.diff && swap.diff.geolocationDepth
                ? `[${swap.diff.geolocationId} (${swap.diff.geolocationDepth})]`
                : ''
            }}
          </div>
        </div>

        <div class="icon-area">
          <div
            v-if="swap.details.isNew && distributedSwapsEnabled"
            class="new-swap-badge"
          >
            {{ $t('swap_list_new_label') }}
          </div>
          <div
            v-else-if="isUserList && showFavouriteButton"
            :class="['favourite-icon', { marked: swap.isFavourite }]"
            @click.stop.prevent="handleMarkFavourite"
          ></div>
        </div>
      </div>

      <div v-if="isMobile" class="interest-bar-and-buttons">
        <div :class="'interest-bar'">
          <div class="interest-area">
            <InterestIndicators :interests="interests" />

            <div class="interest-progress">{{ swapStatusText }}</div>
          </div>

          <template v-if="isUserList">
            <InterestButtonsFreemium
              :interested="interests[0]"
              :target-proposition-id="swap.propositionId.toString()"
              :swap="swap"
              :blur-content="blurContent"
            />
          </template>
        </div>

        <div class="residence-info-bar">
          <span :class="{ 'diff-value': swap.diff && swap.diff.rooms }">
            <span class="extra-bold">{{ currentShownResidence.rooms }}</span>
            {{ $tc('general_rooms', Number(currentShownResidence.rooms)) }}
          </span>

          &#8729;

          <span :class="{ 'diff-value': swap.diff && swap.diff.sqm }">
            <span class="extra-bold">{{ currentShownResidence.sqm }}</span>
            {{ $t('swap_item_sqm') }}
          </span>

          &#8729;

          <span :class="{ 'diff-value': swap.diff && swap.diff.floor }">
            {{
              currentShownResidence.floor !== 0 &&
              currentShownResidence.floor !== 999
                ? $t('swap_item_floor')
                : ''
            }}
            <span class="extra-bold">{{ formattedFloor }}</span>
          </span>

          <br />

          <span :class="{ 'diff-value': swap.diff && swap.diff.rent }">
            <span class="extra-bold">{{ spacedRent }}</span>
            {{ $t('swap_item_cost_per_month') }}
          </span>

          <br />

          <div
            v-if="hasDiffedConveniences"
            style="margin-top: 10px"
            class="diff-conveniences-wrapper"
          >
            <span>{{ $t('swap_item_missing') }}</span>
            {{ diffedConveniencesDescription }}
          </div>
        </div>
      </div>

      <div v-else class="desktop-body-center">
        <div class="desktop-body-center-info">
          <!-- <div v-if="isImpersonated" class="extra-bold red">
            {{ $t('swap_item_ad_num') }} {{ swap.propositionNo }}
          </div> -->
          <div v-if="isImpersonated && swap.score" class="extra-bold red">
            Swap-score: {{ swap.score }} (geo:
            {{ swap.suggestedLocationScore }}, group: {{ swap.groupScore
            }}{{ `, penalty: ${swap.penaltyScore}` }})
          </div>
          <div v-if="isImpersonated && swap.activatedAt" class="extra-bold red">
            Aktiverad: {{ new Date(swap.activatedAt).toLocaleDateString() }}
          </div>
          <span class="extra-bold">{{ currentShownResidence.rooms }}</span>
          {{ $tc('general_rooms', Number(currentShownResidence.rooms)) }}
          <span v-if="swap.diff && swap.diff.rooms" class="diff-value"
            >(-{{ swap.diff.rooms }}
            {{ $tc('general_rooms', Number(swap.diff.rooms)) }})</span
          >
          &#8729;

          <span class="extra-bold">{{ currentShownResidence.sqm }}</span>
          {{ $t('swap_item_sqm') }}
          <span v-if="swap.diff && swap.diff.sqm" class="diff-value"
            >(-{{ swap.diff.sqm }}{{ $t('swap_item_sqm') }})</span
          >
          &#8729;

          {{
            currentShownResidence.floor !== 0 &&
            currentShownResidence.floor !== 999
              ? $t('swap_item_floor')
              : ''
          }}
          <span
            :class="[
              { 'diff-value': swap.diff && swap.diff.floor },
              'extra-bold'
            ]"
            >{{ formattedFloor }}</span
          >
          <br />

          <span class="extra-bold">{{ spacedRent }}</span>
          {{ $t('swap_item_cost_per_month') }}
          <span v-if="swap.diff && swap.diff.rent" class="diff-value"
            >(+{{ swap.diff.rent }}{{ $t('general_currency_short') }})</span
          >

          <br />

          <div v-if="hasDiffedConveniences" class="diff-value">
            {{ $t('swap_item_missing') }}
            {{ diffedConveniencesDescription }}
          </div>
        </div>

        <div class="interest-bar">
          <div class="interest-area">
            <InterestIndicators :interests="interests" />
            <div class="interest-progress">{{ swapStatusText }}</div>
          </div>
          <template v-if="isUserList">
            <InterestButtonsFreemium
              :interested="interests[0]"
              :target-proposition-id="swap.propositionId.toString()"
              :swap="swap"
              :blur-content="blurContent"
              :context="context"
            />
          </template>
        </div>
      </div>

      <div class="amenities-bar">
        <AmenitiesLabels
          :swap="swap"
          :conversation-open="swapChat !== null"
          :has-new-chat="swapChat && swapChat.unreadCount > 0"
          :residence="currentShownResidence"
        />

        <template
          v-if="
            swap.details.percentage === 100 && isUserList && !matchesViewABTest
          "
        >
          <BaseButton
            class="chat-button"
            @click.prevent.stop="openPropositionModalTab('chatTab')"
            ><template v-if="!swap.chat">
              {{ $t('swap_item_start_chat') }}
            </template>
            <template v-else-if="swap.chat && !swap.chat.unreadCount">
              {{ $t('swap_item_open_chat') }}
            </template>
            <template v-else>
              {{
                $t('swap_item_chat_x_unread', { unread: swap.chat.unreadCount })
              }}
            </template></BaseButton
          >
        </template>
      </div>
      <div
        v-if="showTutorialPointer"
        :style="
          hasAmenities
            ? 'position: absolute; right: 0; bottom: 38px'
            : 'position: relative; left: 17px;'
        "
      >
        <InterestPointer />
      </div>
    </div>
  </component>
</template>

<script>
import SwapListSlider from '../ImageSlider/SwapListSlider';
import InterestIndicators from './InterestIndicators';
import InterestButtonsFreemium from './InterestButtonsFreemium';
import AmenitiesLabels from './AmenitiesLabels';
import events from '@/utils/helpers/events';
import { mapActions, mapGetters } from 'vuex';
import { SWAP_VIEW_TABS } from '@/store/modules/swapList';
import { addRightClickTracker } from '@/utils/helpers/rightClick';
import InterestPointer from '../ContextualTutorial/InterestPointer.vue';
import BadgeCount from '../Icons/BadgeCount';

export default {
  name: 'SwapItem',

  components: {
    SwapListSlider,
    InterestIndicators,
    InterestButtonsFreemium,
    AmenitiesLabels,
    InterestPointer,
    BadgeCount
  },

  props: {
    swap: {
      type: Object,
      required: true
    },
    blurContent: {
      type: Boolean,
      default: false
    },
    disableLink: {
      type: Boolean,
      default: false
    },
    context: {
      type: String,
      default: null
    },
    showFavouriteButton: {
      type: Boolean,
      default: true
    },
    showTutorialPointer: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentResidence: 0,
      scrollLocked: false,
      SWAP_VIEW_TABS: SWAP_VIEW_TABS
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      isUserList: 'swapList/isUserList',
      isImpersonated: 'app/isImpersonated',
      isTrial: 'trial/trialBannerOpen',
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    }),

    matchesViewABTest() {
      return this.$growthbook.isFeatureFlagEnabled('matches-view');
    },

    hasAmenities() {
      const amenities = [
        'balcony',
        'elevator',
        'fireplace',
        'bathtub',
        'isCondo',
        'isBecomingCondo'
      ];
      return amenities.some(amenity => this.currentShownResidence[amenity]);
    },

    residence1() {
      return this.swap.residences[0];
    },

    residence2() {
      return this.swap.residences[1];
    },

    distributedSwapsEnabled() {
      return this.$growthbook.isFeatureFlagEnabled('distributed-swaps-2');
    },

    interests() {
      return this.swap.details.interests;
    },

    hasDiffedConveniences() {
      return this.diffedConveniencesDescription !== '';
    },

    diffedConveniencesDescription() {
      if (!this.swap.diff) {
        return '';
      }

      const conveniences = [];
      if (this.swap.diff.elevator) {
        conveniences.push(this.$t('conveniences_elevator'));
      }

      if (this.swap.diff.balcony) {
        conveniences.push(this.$t('conveniences_balcony'));
      }

      if (this.swap.diff.becomingCoop) {
        conveniences.push(this.$t('conveniences_becoming_condo'));
      }

      return conveniences.join(', ');
    },

    swapStatusText() {
      const interestedCount = this.interests.filter(Boolean).length;
      const interestsLength = this.interests.length;

      // You are not interested
      if (this.interests[0] === false) {
        return this.$t('swap_item_you_not_interested');
      }
      // Noone interested
      else if (interestedCount === 0) {
        return this.$t('swap_item_no_interest');
      }
      // Full interest
      else if (interestedCount === interestsLength) {
        return this.$t('swap_item_full_interest');
      }
      // Direct swap
      else if (interestsLength === 2) {
        if (this.interests[0]) {
          return this.$t('swap_item_you_interested');
        } else {
          return this.$t('swap_item_other_interested');
        }
      }
      // Triangle swap
      else {
        return `${interestedCount} ${this.$t('swap_item_x_of_y_interested')}`;
      }
    },

    swapChat() {
      return this.swap.chat;
    },

    spacedRent() {
      return this.currentShownResidence.rent
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },

    currentShownResidence() {
      if (!this.is2to1) {
        return this.residence1;
      } else {
        return this.swap.residences[this.currentResidence];
      }
    },

    currentSecondaryResidence() {
      if (this.currentResidence === 0) {
        return this.residence2;
      } else {
        return this.residence1;
      }
    },

    swapLabel() {
      const { swapType } = this.swap;

      switch (swapType) {
        case 21:
          return this.$t('general_2_for_1');
        case 12:
          return this.$t('general_1_for_2');
        default:
          return this.$t('general_1_for_1');
      }
    },

    typeColor() {
      const { swapType } = this.swap;

      switch (swapType) {
        case 21:
          return 'pink';
        case 12:
          return 'yellow';
        default:
          return 'blue';
      }
    },

    is2to1() {
      return this.swap.residences.length > 1;
    },

    isTriangle() {
      return this.swap.details.interests.length >= 3;
    },

    isManual() {
      return this.swap.details.type === 'manual';
    },

    formattedFloor() {
      switch (this.currentShownResidence.floor) {
        case 0:
          if (this.$country.isCountry('nl')) {
            return this.$t('general_ground_floor_detailed');
          }
          return this.$t('general_ground_floor_short');
        case 999:
          return this.$t('general_attic_short');
        default:
          return this.currentShownResidence.floor;
      }
    }
  },

  mounted() {
    if (this.isMobile) {
      this.initializeScrollLock();
    }
  },

  methods: {
    ...mapActions({
      markFavourite: 'favourites/markFavourite',
      unmarkFavourite: 'favourites/unmarkFavourite',
      setGlobalModal: 'ui/setGlobalModal',
      setCurrentSwapViewTab: 'swapList/setCurrentSwapViewTab',
      setPageModalActivePage: 'ui/setPageModalActivePage',
      fetchSwapDetails: 'swapList/fetchSwapDetails'
    }),

    handleMarkFavourite() {
      if (this.swap.isFavourite) {
        events.emitEvent(events.eventTypes.CLICK, 'Favourite Unmark', {
          src: `swaplist`
        });
        this.unmarkFavourite({ propositionId: this.swap.propositionId });
      } else {
        events.emitEvent(events.eventTypes.CLICK, 'Favourite Mark', {
          src: `swaplist`
        });
        this.markFavourite({ propositionId: this.swap.propositionId });
      }
    },

    handlePropositionCardRightClick() {
      addRightClickTracker(this.swap.propositionId, 'swaplist');
    },

    triggerPropositionClickedEvent() {
      events.emitEvent(events.eventTypes.CLICK, 'View Proposition', {
        src: `swaplist`,
        propositionId: this.swap.propositionId
      });
    },

    handlePropositionCardClick() {
      if (this.blurContent) {
        this.setGlobalModal('SwapsLocked');

        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'swap-locked',
          src: 'swap-list'
        });
      } else {
        this.triggerPropositionClickedEvent();
      }
    },

    openPropositionModalTab(tab) {
      if (tab === 'chatTab' || tab === 'swapchainTab') {
        // Get swap details
        this.fetchSwapDetails({
          propositionId: this.swap.propositionId,
          type: this.swap.details.type
        });

        // Set active page modal tab to details
        this.setPageModalActivePage('swapdetails');

        if (tab === 'chatTab') {
          // Set current swap view tab to chat
          this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.CHAT });
        }

        if (tab === 'swapchainTab') {
          // Set current swap view tab to swap chain
          this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP });
        }
      } else {
        this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP });
        this.triggerPropositionClickedEvent();
      }

      this.$router.push({
        query: {
          propositionPageId: this.swap.propositionId,
          type: this.swap.details.type
        }
      });
    },

    switchCurrentShown() {
      if (this.currentResidence === 0) {
        this.currentResidence = 1;
      } else {
        this.currentResidence = 0;
      }
    },

    lockScroll() {
      this.scrollLocked = true;
    },

    unlockScroll() {
      this.scrollLocked = false;
    },

    initializeScrollLock() {
      const preventDefaultIfLocked = e => {
        if (!e.cancelable) {
          return false;
        }

        if (!this.scrollLocked) {
          return;
        }

        e = e || window.event;
        if (e.preventDefault) {
          e.preventDefault();
        }
        e.returnValue = false;
      };

      window.addEventListener('touchmove', preventDefaultIfLocked, {
        passive: false
      });
    },

    goToEditPropositionImages() {
      this.$router.push({
        path: `${this.$t('path_edit_proposition_photos', this.$routeLocale)}/${
          this.primaryPropositionId
        }/1`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.swap-item {
  background-color: #fff;
  color: $text-primary;

  @media ($desktop) {
    display: flex;
    border: 1px solid rgba(28, 45, 65, 0.15);
    border-radius: $small-box-radius;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    padding: 16px;
  }

  @media ($mobile) {
    padding-bottom: 20px;
    border-bottom: 1px solid #f1f2f6;
  }

  &-body {
    @media ($desktop) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.swap-item:not(.blur-content):hover {
  @media ($desktop) {
    border: 1px solid $transfer-blue;
    cursor: pointer;
  }
}

.desktop-body-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &-info {
    font-weight: 600;
    font-size: 0.85rem;
    margin-top: 10px;
    line-height: 160%;
  }
}

.swap-item-header {
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  -webkit-tap-highlight-color: transparent;

  @media ($mobile) {
    margin-top: 10px;
    margin-bottom: 8px;
  }
}

.icon-area {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.proposition-type {
  padding: 5px 8px;
  border-radius: 12px;
  font-weight: 700;
  white-space: nowrap;
  font-size: 0.6rem;

  &.pink {
    background-color: #fce5f6;
    color: #ed63d2;
  }
  &.blue {
    background-color: #e7f3ff;
    color: $transfer-blue;
  }
  &.yellow {
    color: #ffc212;
    background-color: #fff8e6;
  }
}

.underline {
  text-decoration: underline;
}

.favourite-icon {
  height: 20px;
  width: 20px;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/heart-empty.svg');
  margin: 0 6px;

  &.marked {
    background-image: url('../../assets/svg/heart-full.svg');
  }
}

.info-from-boost {
  position: absolute;
  top: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  z-index: 1;
  width: 100%;
  background-color: #9f41e9;
  color: #fff;
  padding: 4px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    margin-right: 3px;
  }
}

.boost-icon {
  display: grid;
  place-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: #9f41e9;
  z-index: 1;
}

.swap-item-slider {
  position: relative;

  @media ($desktop) {
    height: 180px;
    margin: 0 16px 0 0;
    width: 40%;
    min-width: 40%;
    max-width: 25vw;
  }
  @media ($mobile) {
    height: 210px;
    width: 100%;
  }
}

.swap-type-banner {
  position: absolute;
  top: 18px;
  left: 0;
  padding: 6px;
  padding-left: 12px;
  background-color: $dark-blue;
  color: #fff;
  z-index: 1;
  font-weight: 600;
  font-size: 0.8rem;
  border-top-right-radius: $small-box-radius;
  border-bottom-right-radius: $small-box-radius;

  &.swap-process {
    background-color: $transfer-blue;

    &.has-manual-banner {
      top: 50px;
    }
  }
}

.placeholder-container {
  position: relative;
  background-color: #dddcdb;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $small-box-radius;

  .img-placeholder {
    background-size: 50%;
    background-color: #dddcdb;
    background-position: 50%;
    background-image: url('../../assets/svg/skyline-placeholder-img.svg');
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: inherit;

    @media ($small-desktop) {
      background-size: 60%;
    }

    @media ($desktop) {
      height: 180px;
    }
  }

  p {
    font-size: 12px;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 70%;
    z-index: 1;

    @media ($small-desktop) {
      top: 69%;
      font-size: 11px;
    }
  }
}

.profile-picture {
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  background-image: url('../../assets/img/nopic-square.png');
}

.address {
  font-size: 1.1rem;
  font-weight: 700;
  display: flex;
  align-items: center;

  &.secondary {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
  }
}

.verified-badge {
  margin-left: 6px;
  margin-top: -1px;
}

.shift-icon {
  height: 13px;
  width: 15px;
  margin-left: 6px;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/shift.svg');
}

.postal-area {
  margin-top: 3px;
  font-size: 0.8rem;
  color: $text-secondary;
  font-weight: 600;

  @media ($desktop) {
    margin-top: 1px;
  }
}

.residence-info-bar {
  font-size: 0.85rem;
  line-height: 18px;
  padding: 6px 0;

  @media ($desktop) {
    border-bottom: 1px solid #ddd;
  }
}

.interest-bar-and-buttons {
  display: flex;
  flex-direction: column-reverse;
}

.interest-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0 6px 0;
  padding-bottom: 8px;
  margin-top: 6px;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.interest-area {
  display: flex;
  align-items: center;
}

.interest-progress {
  margin-left: 3px;
  font-weight: 600;
  color: $text-primary;
  font-size: 0.7rem;
}

.amenities-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;

  @media ($desktop) {
    border-top: 1px solid #f1f2f6;
  }
}

.extra-bold {
  font-weight: 600;
}

.red {
  color: red;
}

.diff-value {
  color: #db8e00;
}

.diff-conveniences-wrapper {
  color: #db8e00;
  font-weight: 600;
}

.diff-conveniences-wrapper span {
  font-weight: 400;
}

.blur-content {
  .swap-item-slider,
  .swap-item-header,
  .residence-info-bar,
  .desktop-body-center-info,
  .amenities-bar {
    filter: blur(4px);
    pointer-events: none;
    user-select: none;
  }
}

.chat-button {
  padding: 10px 15px !important;
  font-size: 12px !important;
}

.new-swap-badge {
  margin-right: 4px;
  background: $main-blue;
  color: #fff;
  padding: 4px 8px;
  font-size: 10px;
  line-height: 9px;
  border-radius: 6px;
  height: 18px;
  margin-top: -20px;
  font-weight: bold;
}
</style>
