<template>
  <div class="report-describe-form-wrapper">
    {{ $t('report_label_describe_more') }}
    <BaseTextArea
      :rows="6"
      :maxlength="reportTextMaxLength"
      :value="textareaReportDescription"
      :placeholder="$t('report_label_describe_experience')"
      :valid="true"
      max-height="40rem"
      resize="none"
      @input="handleReportDescriptionChange($event)"
    />
    <div class="checkbox-container">
      <BaseCheckbox
        :label="$t('report_label_allowing_follow_ups')"
        variant="label-light"
        size="sm"
        :value="allowContact"
        :selected="allowContact"
        @change="handleAllowContactToggle"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'lodash';

export default {
  name: 'ReportDescribeScreen',

  data: () => ({
    reportTextMaxLength: 2500
  }),

  computed: {
    ...mapGetters({
      allowContact: 'reportProposition/allowContact',
      textareaReportDescription: 'reportProposition/textareaReportDescription'
    })
  },

  methods: {
    ...mapActions({
      setAllowContact: 'reportProposition/setAllowContact',
      setTextareaReportDescription:
        'reportProposition/setTextareaReportDescription',
      setFormFilled: 'reportProposition/setFormFilled'
    }),
    handleAllowContactToggle() {
      this.setAllowContact(!this.allowContact);
    },
    handleReportDescriptionChange(value) {
      this.setTextareaReportDescription(value);
      debounce(() => {
        this.setFormFilled(value !== '');
      }, 500)();
    }
  }
};
</script>

<style lang="scss" scoped>
.report-describe-form-wrapper {
  margin-top: 1rem;
  width: 100%;
  height: 100%;
}

.checkbox-container {
  margin-top: 4rem;
  padding: 1rem 0.2rem;
  border-top: 1px solid #ebebeb;
}
</style>
