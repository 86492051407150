<template>
  <div class="residence-description block-pulse">
    <div class="address-box">
      <p class="sub-text block">blocktext</p>
      <div class="head-wrap">
        <h2 class="address block">blocktext</h2>

        <button class="like-btn block">blocktext</button>
      </div>
    </div>
    <div class="apartment-info">
      <p class="info-text block">
        blocktext blocktext blocktext blocktext blocktext blocktext
      </p>
    </div>
    <div class="bottom">
      <p class="sub-text block">blocktext</p>
    </div>

    <div class="more-info-container">
      <p class="host block">blocktext</p>
    </div>

    <proposition-description-loader />
  </div>
</template>

<script>
import PropositionDescriptionLoader from './PropositionDescriptionLoader';
export default {
  name: 'DescriptionLoader',

  components: {
    PropositionDescriptionLoader
  }
};
</script>

<style lang="scss" scoped>
.residence-description {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  flex-direction: column;

  @media ($desktop) {
    width: 100%;
  }
}

.more-info-container {
  border-top: 1px solid rgba(28, 45, 65, 0.103);
  margin-bottom: 20px;

  @media ($mobile) {
    margin-bottom: 10px;
  }
}

.more-info-container .host {
  background-size: contain;
  background-position: left;
  background-image: url('../../../assets/svg/host.svg');
  padding-left: 20px;
}
.more-info-container .ad-id {
  background-size: contain;
  background-position: left;
  background-image: url('../../../assets/svg/ad-id.svg');
  padding-left: 20px;
}

.more-info-container p {
  display: inline-block;
  color: $text-secondary;
  font-size: 0.95rem;
  font-weight: 600;
  margin-right: 15px;

  @media ($mobile) {
    font-size: 0.85rem;
  }
}

.address-box {
  margin-bottom: 5px;
}

.head-wrap {
  @media ($desktop) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.sub-text {
  font-weight: 600;
  font-size: 0.95rem;
  color: $text-secondary;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

.address {
  margin: 0;
}

.apartment-info {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin: 10px 0 9px 0;
}

.info-text {
  color: $text-primary;
  font-size: 0.95rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
  position: relative;
}

.like-btn {
  height: 17px;
  background-position: left;
  background-size: contain;
  background-image: url('../../../assets/svg/heart-empty.svg');
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin-right: 0px;
  outline: none;
  padding-left: 27px;
  cursor: pointer;
  font-size: 0.95rem;

  &.is-favourite {
    background-image: url('../../../assets/svg/heart-full.svg');
  }
}

.bottom {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media ($mobile) {
    &::after {
    }
  }
}

.bottom .sub-text {
  color: $text-primary;
}

.view-on-map {
  font-weight: 600;
  font-size: 0.95rem;
  color: $main-blue;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  margin-top: 5px;
  text-decoration: none;
  display: block;
}
</style>
