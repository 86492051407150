<template>
  <RouterLink
    :to="{
      query: {
        propositionPageId: proposition.propositionId
      }
    }"
    :class="['search-card']"
  >
    <div
      :class="['image']"
      :style="[
        proposition.residences[0].images.length
          ? {
              backgroundImage: `url(${proposition.residences[0].images[0].fileName})`,
              opacity: 1,
              backgroundSize: 'cover'
            }
          : ''
      ]"
    >
      <button
        :class="['heart-btn', { 'heart-full': proposition.favourite }]"
        @click.stop="toggleFavourite"
      ></button>
    </div>

    <div class="card-content">
      <div class="address">
        <div class="street-address">
          {{ proposition.residences[0].streetAddress }}
          <BaseIcon
            v-if="proposition.verified"
            icon="verified"
            :width="18"
            :height="18"
            class="verified-badge"
          />
        </div>
        <span v-if="proposition.residences[1]"
          >/<br />{{ proposition.residences[1].streetAddress }}</span
        >
      </div>

      <div class="city">
        {{ proposition.residences[0].postalAddress }}
        <span v-if="proposition.residences[1]"
          >/ {{ proposition.residences[1].postalAddress }}</span
        >
      </div>

      <div class="apartment-info">
        <p class="info-text info-icon room">
          {{ proposition.residences[0].rooms }} {{ $t('search_card_rooms') }}
        </p>
        <p class="info-text info-icon area">
          {{ proposition.residences[0].sqm }} {{ $t('search_card_sqm') }}
        </p>
      </div>

      <div class="price">
        {{ proposition.residences[0].rent
        }}{{ $t('search_card_cost_per_month') }}
      </div>
    </div>
  </RouterLink>
</template>

<script>
import { mapActions } from 'vuex';
import events from '@/utils/helpers/events';

export default {
  name: 'SearchCard',

  props: {
    proposition: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions({
      unmarkFavourite: 'favourites/unmarkFavourite',
      markFavourite: 'favourites/markFavourite'
    }),

    toggleFavourite: function () {
      if (this.proposition.favourite) {
        events.emitEvent(events.eventTypes.CLICK, 'Favourite Unmark', {
          src: `favourites-card`
        });
        this.unmarkFavourite({ propositionId: this.proposition.propositionId });
      } else {
        events.emitEvent(events.eventTypes.CLICK, 'Favourite Mark', {
          src: `favourites-card`
        });
        this.markFavourite({ propositionId: this.proposition.propositionId });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  background-color: #fff;
  border-radius: 8px;
  transition: all 0.2s linear;
  overflow: hidden;
  user-select: none;
  margin-right: 1.5%;
  margin-left: 1.5%;

  //  disables blue highlight on favourite tap
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  @media ($desktop) {
    max-width: 22%;
    min-width: 22%;
    margin-bottom: 4%;
  }

  @media ($small-desktop) {
    max-width: 30%;
    min-width: 30%;
  }

  @media ($mobile) {
    width: 100%;
    margin: 0 0 6% 0;
  }

  @media ($ipad) {
    max-width: 48%;
    min-width: 48%;
    margin: 0 1% 4% 1%;
  }
}

.image {
  height: 180px;
  width: 100%;
  border-radius: 8px;
  background-position: center;
  background-image: url('../../assets/svg/skyline-placeholder-img.svg');
  position: relative;
  background-size: contain;
  background-color: #ebebeb;

  @media ($mobile) {
    height: 220px;
  }
}

.card-content {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 6px 0px;
  width: 100%;
}

.city {
  color: gray;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  width: 100%;
}

.heart-btn {
  border: 0;
  padding: 0;
  -webkit-appearance: none;
  outline: none;
  background: none;
  width: 28px;
  height: 28px;
  background-image: url('../../assets/svg/heart-empty.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 10px;
  right: 15px;
}

.heart-full {
  cursor: pointer;
  background-image: url('../../assets/svg/heart-full.svg');
}

.address {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 2px;
}

.street-address {
  display: flex;
  align-items: center;
  color: $text-primary;
}

.verified-badge {
  margin-left: 6px;
}

.apartment-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 12px 0 4px 0;
}

.info-text {
  color: $gray;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  margin: 0 19px 0 19px;
  position: relative;
}

.info-icon {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -19px;
    background-size: cover;
    background-position: center;
    width: 15px;
    height: 15px;
  }

  &.room {
    &::after {
      background-image: url('../../assets/svg/double-bed.svg');
    }
  }

  &.area {
    &::after {
      background-image: url('../../assets/svg/shape.svg');
    }
  }
}

.price {
  color: $gray;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
}
</style>
