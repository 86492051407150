export const ONE_TO_ONE = require('@/assets/svg/swap-icons/one_to_one.svg');
export const ONE_TO_TWO = require('@/assets/svg/swap-icons/one_to_two.svg');
export const TWO_TO_ONE = require('@/assets/svg/swap-icons/two_to_one.svg');

export const BALCONY = require('@/assets/svg/filter-icons/balcony.svg');
export const FLOOR = require('@/assets/svg/filter-icons/floor.svg');
export const IMAGE = require('@/assets/svg/filter-icons/image.svg');
export const ELEVATOR = require('@/assets/svg/filter-icons/elevator.svg');
export const FAVORITE = require('@/assets/svg/new-fav.svg');
export const MARKED_FAVORITE = require('@/assets/svg/heart-full.svg');
export const BRF = require('@/assets/svg/filter-icons/brf.svg');
export const BURGER = require('@/assets/svg/burger.svg');
export const ADD_INVERTED = require('@/assets/svg/add-inverted.svg');
export const ARROW_RIGHT = require('@/assets/svg/arrow-right.svg');
export const ARROW_RIGHT_ALT = require('@/assets/svg/arrow-right-alt.svg');
export const ARROW_DOWN = require('@/assets/svg/arrow-down.svg');
export const CLOCK = require('@/assets/svg/clock.svg');
export const BUBBLE = require('@/assets/svg/bubble.svg');
export const ROOMS = require('@/assets/svg/rooms.svg');
export const SQM = require('@/assets/svg/sqm.svg');
export const RENT = require('@/assets/svg/rent.svg');
export const AMENITIES = require('@/assets/svg/gear.svg');
export const PROPOSITION_TYPE = require('@/assets/svg/house.svg');
export const SEARCH = require('@/assets/svg/search-new.svg');
export const CLOSE_WHITE = require('@/assets/svg/close-white.svg');
export const CLOSE = require('@/assets/svg/close.svg');
export const TRASH = require('@/assets/svg/close-white.svg');
export const LIST_SMALL = require('@/assets/svg/list-small.svg');
export const LIST_SMALL_WHITE = require('@/assets/svg/list-small-white.svg');
export const LIST_BIG = require('@/assets/svg/list-big.svg');
export const FILTERS = require('@/assets/svg/filters.svg');
export const RELOAD = require('@/assets/svg/reload.svg');
export const MAP = require('@/assets/svg/map.svg');
export const MARKER = require('@/assets/svg/marker-new.svg');
export const BANKID = require('@/assets/svg/bankid.svg');
export const KLARNA_LOGO = require('@/assets/svg/klarna-logo.svg');
export const TWO_PART_SWAP = require('@/assets/svg/2-part-swap-icon.svg');
export const THREE_PART_SWAP = require('@/assets/svg/3-part-swap-icon.svg');
export const BACK_ARROW = require('@/assets/svg/back-arrow.svg');

export default {
  SWAP: {
    ONE_TO_ONE,
    ONE_TO_TWO,
    TWO_TO_ONE
  },
  FILTER: {
    BALCONY,
    FLOOR,
    IMAGE,
    ELEVATOR,
    FAVORITE,
    MARKED_FAVORITE,
    BRF,
    ROOMS,
    SQM,
    RENT,
    AMENITIES,
    PROPOSITION_TYPE,
    FILTERS
  },
  GENERAL: {
    BURGER,
    ADD_INVERTED,
    ARROW_RIGHT,
    ARROW_RIGHT_ALT,
    ARROW_DOWN,
    CLOCK,
    BUBBLE,
    SEARCH,
    CLOSE_WHITE,
    CLOSE,
    TRASH,
    LIST_SMALL,
    LIST_SMALL_WHITE,
    LIST_BIG,
    RELOAD,
    MAP,
    MARKER,
    BANKID,
    KLARNA_LOGO,
    THREE_PART_SWAP,
    TWO_PART_SWAP,
    BACK_ARROW
  }
};
