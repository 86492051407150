<template>
  <div>
    <BaseOverlay
      @click.native="
        closeFunction();
        $emit('close');
      "
    />

    <div class="trial-modal">
      <div class="info-part">
        <div class="info-part-header">{{ $t('trial_cta_modal_title') }}</div>

        <div class="info-part-list-header">
          {{ $t('trial_cta_modal_list_title') }}
        </div>

        <div class="info-part-list">
          <div class="info-part-list-item">
            {{ $t('trial_cta_modal_list_1') }}
          </div>
          <div class="info-part-list-item">
            {{ $t('trial_cta_modal_list_2') }}
          </div>
          <div class="info-part-list-item">
            {{ $t('trial_cta_modal_list_3') }}
          </div>
        </div>

        <TrustBox
          v-if="$country.isFeatureEnabled('TRUSTPILOT')"
          type="Horizontal"
        />

        <div class="button-wrapper">
          <primary-button :accent="true" @click.native="goToPaymentPage">{{
            $t('trial_cta_modal_action_confirm')
          }}</primary-button>
          <div class="close-link" @click="handleClose()">
            {{ $t('trial_cta_modal_action_cancel') }}
          </div>
        </div>
      </div>

      <div v-if="!isMobile" class="image-part">
        <div class="image-text">
          {{ $t('trial_cta_modal_image_text') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PrimaryButton from '../Buttons/Primary';
import events from '@/utils/helpers/events';
import TrustBox from '../TrustBox/TrustBox.vue';

export default {
  name: 'TrialCTAModal',

  components: {
    PrimaryButton,
    TrustBox
  },

  props: {
    closeFunction: {
      type: Function,
      required: false,
      default: () => {}
    },
    paymentPagePropositionId: {
      type: String,
      required: true
    },
    where: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  },

  mounted() {
    this.setPageModalZIndex(2000);
    this.trialMessageClicked({ where: this.where });

    events.emitEvent(events.eventTypes.SEE, 'Paywall', {
      trigger: 'chat',
      src: this.where
    });
  },

  destroyed() {
    this.setPageModalZIndex(null);
  },

  methods: {
    ...mapActions({
      trialMessageClicked: 'gtm/trialMessageClicked',
      setPageModalZIndex: 'ui/setPageModalZIndex'
    }),

    goToPaymentPage() {
      events.emitEvent(events.eventTypes.CLICK, 'Payment', {
        id: `${this.where}-unlock-chat`
      });

      this.$router.push(
        `${this.$routes.PAYMENT_PROPOSITION}/${this.paymentPagePropositionId}`
      );
    },

    handleClose() {
      this.$emit('close');

      if (this.closeFunction) {
        this.closeFunction();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.trial-modal {
  position: fixed;
  background-color: #fff;
  z-index: 1002;
  overflow: hidden;

  @media ($desktop) {
    top: 100px;
    left: calc(50vw - 400px);
    height: 450px;
    width: 800px;
    border-radius: 8px;
    display: flex;
    box-shadow: $standard-shadow;
  }
  @media ($mobile) {
    top: 0;
    left: 0;
    right: 0;
    bottom: calc(95px + env(safe-area-inset-bottom));
    width: 100vw;
    padding: 20px 15px;
  }
}

.info-part {
  @media ($desktop) {
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 20px;
  }

  @media ($mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      margin: 20px 0;
    }
  }

  &-header {
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
  }

  &-list-header {
    font-weight: 700;
    font-size: 1.2rem;
    text-align: center;
  }

  &-list-item {
    margin: 8px 0;
    font-weight: 600;
    position: relative;

    &-info {
      font-size: 0.8rem;
      margin-top: -5px;
      font-weight: 600;
    }

    &::before {
      position: absolute;
      content: '';
      height: 25px;
      width: 25px;
      left: -27px;
      top: -2px;
      background-size: contain;
      background-position: center;
      background-image: url('../../assets/svg/check-mark-green.svg');
    }
  }
}

.button-wrapper {
  text-align: center;
  & > * {
    margin: 6px 0;
  }
}

.close-link {
  cursor: pointer;
  font-weight: 600;
}

.image-part {
  width: 400px;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-image: url('../../assets/img/trial-img.jpg');
}

.image-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 35px;
}
</style>
