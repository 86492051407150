<template>
  <div class="swap-list-item block-pulse">
    <div class="column">
      <div :class="['favourite-icon block']"></div>
    </div>

    <div class="column image-address-column">
      <div class="swap-list-item-image"></div>
      <div class="address-info-wrapper">
        <div class="address-info">
          <div class="address block">address</div>
          <div class="action-button block">actionbtn</div>
        </div>
      </div>
    </div>

    <div class="column"><div class="block">0</div></div>

    <div class="column"><div class="block">0</div></div>

    <div class="column"><div class="block">0</div></div>

    <div class="column rent-column">
      <div class="block">0kr</div>
    </div>

    <div class="column postal-area-column">
      <div class="block">postalarea</div>
    </div>

    <div class="column interest-column">
      <div class="interest-area">
        <div class="interest-progress block">0%</div>
      </div>
    </div>

    <div class="column min-width-95">
      <div class="block">btns</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwapListItemLoading'
};
</script>

<style lang="scss" scoped>
$row-height: 50px;

.swap-list-item {
  display: table-row;
}

.swap-list-item > * {
  border-bottom: 1px solid #ddd;
}

.column {
  display: table-cell;
  vertical-align: middle;
  color: $text-info;
  padding: 10px 0;
  font-weight: 600;
  font-size: 10px;
}

.column.image-address-column {
  width: 370px;
  text-align: left;
  color: $text-primary;
}

.column.postal-area-column {
  word-break: break-word;
  margin: 0 auto;
  width: 210px;
  padding: 0px 10px;
  text-align: center;
}

.column.interest-column {
  width: 110px;
}

.column.rent-column {
  width: 150px;
}

.swap-list-item-image {
  width: calc(#{$row-height} * 1.4);
  height: $row-height;
  border-radius: 10px;
  float: left;
  background-position: center;
  background-size: contain;
  background-color: #ebebeb;

  background-image: url('../../../assets/svg/skyline-placeholder-img.svg');
}

.address-info-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  height: $row-height;
  max-width: 155px;
}

.address-info {
  margin-left: 10px;
}

.icon-area {
  position: relative;
}

.underline {
  text-decoration: underline;
}

.favourite-icon {
  cursor: pointer;
  height: 15px;
  width: 15px;
  background-position: center;
  background-size: contain;
  background-image: url('../../../assets/svg/heart-empty.svg');
}

.swap-item-slider {
  height: 220px;
  width: 100%;
}

.profile-picture {
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  background-image: url('../../../assets/img/nopic-square.png');
}

.address {
  cursor: pointer;
  font-weight: 700;

  &.secondary {
    display: flex;
    align-items: center;
    font-size: 8px;
  }
}

.small-text {
  font-size: 8px;
}

.interest-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0 6px 0;
}

.interest-area {
  display: flex;
  align-items: center;
  float: left;
}

.interest-progress {
  margin-left: 3px;
  font-weight: 600;
  color: $text-secondary;
  font-size: 8px;
}

.action-button {
  cursor: pointer;
  color: $transfer-blue;
  margin-top: 5px;
}

.extra-bold {
  font-weight: 700;
}

.min-width-95 {
  min-width: 95px;
}
</style>
