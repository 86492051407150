<template>
  <transition
    :name="disableAnimation ? '' : 'universal-slide-up'"
    mode="out-in"
    appear
  >
    <div ref="basePanel" class="panel">
      <div class="panel-slot-content">
        <slot />
      </div>
      <BaseButton class="smaller panel-button" @click="$emit('close')">
        <slot name="button">
          {{ $t('base_panel_close') }}
        </slot>
      </BaseButton>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BasePanel',
  props: {
    disableAnimation: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.panel {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  padding-bottom: calc(94px + env(safe-area-inset-bottom));
  background-color: #fff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  z-index: 1000;

  @media ($mobile) {
    position: fixed;
    width: 100vw;
    height: 100vh;
  }
}

.panel-slot-content {
  width: 100%;
}

.panel-button {
  position: fixed;
  bottom: calc(20px + env(safe-area-inset-bottom));
}
</style>
