<template>
  <div class="wrapper-all text-unselectable" :class="wrapperAllClasses">
    <div class="wrapper" :class="[{ rounded: isRounded }, ...wrapperClasses]">
      <div
        class="choice-wrapper clickable"
        :class="[inactive.classes, { opacity: inactive.icon }]"
        @click="$emit('change', { ...inactive, isActive: false })"
      >
        <span v-if="inactive.icon" class="icon-field">
          <AImage
            :classes="['icon-list-view', 'icon-size']"
            :url="inactive.icon"
          />
        </span>
        <span v-if="inactive.text" class="text">
          {{ inactive.text }}
        </span>
      </div>

      <div
        class="choice-wrapper clickable"
        :class="[active.classes, { opacity: active.icon }]"
        @click="$emit('change', { ...active, isActive: true })"
      >
        <span v-if="active.icon" class="icon-field">
          <AImage
            :classes="['icon-list-view', 'icon-size']"
            :url="active.icon"
          />
        </span>

        <span v-if="active.text" class="text">
          {{ active.text }}
        </span>
      </div>

      <div
        class="checked"
        :class="{
          left: !isActive,
          right: isActive,
          rounded: isRounded,
          'bg-white': active.icon
        }"
      >
        <span v-if="active.icon && inactive.icon" class="icon-field">
          <AImage
            :classes="['icon-list-view', 'icon-size']"
            :url="isActive ? active.icon : inactive.icon"
          />
        </span>

        <span v-if="active.text && inactive.text" class="text">
          {{ isActive ? active.text : inactive.text }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import AImage from '@/components/atomic/atoms/a-image';

export default {
  name: 'AToggleChoice',

  components: { AImage },

  props: {
    active: {
      type: Object,
      default: () => ({
        text: 'Aktiv',
        active: false,
        classes: []
      }),
      required: false
    },

    inactive: {
      type: Object,
      default: () => ({
        text: 'Inaktiv',
        active: true,
        classes: []
      }),
      required: false
    },

    isActive: {
      type: Boolean,
      required: true
    },

    isRounded: {
      type: Boolean,
      default: false,
      required: false
    },

    wrapperAllClasses: {
      type: [Array, Object],
      required: false,
      default: () => []
    },

    wrapperClasses: {
      type: [Array, Object],
      required: false,
      default: () => []
    }
  },

  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
$color: white;
$radius: 9px;

.wrapper-all {
  position: relative;
  height: 40px;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #e8e8e8;
  border-radius: 11px;
}

.choice-wrapper {
  box-sizing: border-box !important;
  color: $brand-light;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
}

.right {
  left: 50%;
}

.left {
  left: 0%;
}

.text {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
}

.icon-field {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checked {
  position: absolute;
  height: calc(100% - 8px);
  margin-left: 4px;
  margin-right: 4px;
  width: calc(50% - 8px);
  border-radius: 9px;
  background-color: $brand-dark;
  color: $color;
  transition: 0.3s;
}

.rounded {
  border-radius: 20px;
}

.icon-list-view {
  background-position: center;
  background-size: contain;
}

.icon-size {
  width: 32px;
  height: 32px;
}

.bg-white {
  background-color: white;
}

.opacity {
  opacity: 0.5;
}
</style>
