<template>
  <div class="info-part">
    <div class="info-part-header">
      {{ $t('swap_view_chat_pay_title') }}
    </div>

    <div class="info-part-list-header">
      {{ $t('swap_view_chat_pay_subtitle') }}
    </div>

    <div class="info-part-list">
      <div class="info-part-list-item">
        {{ $t('swap_view_chat_pay_list_item1') }}
      </div>
      <div class="info-part-list-item">
        {{ $t('swap_view_chat_pay_list_item2') }}
      </div>
      <div class="info-part-list-item">
        {{ $t('swap_view_chat_pay_list_item3') }}
      </div>
      <div v-if="$country.isCountry('se')" class="info-part-list-item-info">
        {{ $t('swap_view_chat_pay_list_item4') }}
      </div>
    </div>

    <TrustBox
      v-if="$country.isFeatureEnabled('TRUSTPILOT')"
      type="Horizontal"
    />

    <div class="button-wrapper">
      <primary-button :accent="true" @click.native="goToPaymentPage">
        {{ $t('swap_view_chat_pay_action') }}
      </primary-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PrimaryButton from '@/components/Buttons/Primary.vue';
import events from '@/utils/helpers/events';
import TrustBox from '@/components/TrustBox/TrustBox.vue';

export default {
  name: 'SwapViewChatPay',

  components: {
    PrimaryButton,
    TrustBox
  },

  props: {
    paymentPagePropositionId: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  },

  mounted() {
    this.trialMessageClicked({ where: 'swapView' });
    events.emitEvent(events.eventTypes.SEE, 'Paywall', {
      trigger: 'chat',
      src: 'swap-view'
    });
  },

  methods: {
    ...mapActions({
      trialMessageClicked: 'gtm/trialMessageClicked'
    }),

    goToPaymentPage() {
      events.emitEvent(events.eventTypes.CLICK, 'Payment', {
        id: 'swap-view-unlock-chat'
      });

      this.$router.push(
        `${this.$routes.PAYMENT_PROPOSITION}/${this.paymentPagePropositionId}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.info-part {
  @media ($desktop) {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 20px;
  }

  @media ($mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      margin: 20px 0;
    }
  }

  &-header {
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
  }

  &-list-header {
    font-weight: 700;
    font-size: 1.2rem;
    text-align: center;
  }

  &-list-item {
    margin: 8px 0;
    font-weight: 600;
    position: relative;

    &-info {
      font-size: 0.8rem;
      margin-top: -5px;
    }

    &::before {
      position: absolute;
      content: '';
      height: 25px;
      width: 25px;
      left: -25px;
      top: -2px;
      background-size: contain;
      background-position: center;
      background-image: url('../../assets/svg/check-mark-green.svg');
    }
  }
}

.button-wrapper {
  text-align: center;
  & > * {
    margin: 5px 0;
  }
}
</style>
