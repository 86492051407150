<template>
  <div class="flex">
    <div class="pill-wrapper" :class="mode">
      <APill
        v-if="item.swapType == 11"
        :is-active="true"
        :is-rounded="false"
        :is-small="mode !== 'list-desktop'"
        :is-extra-small="mode === 'list-desktop'"
        :class="['one-to-one']"
      >
        {{ $t('general_1_for_1') }}
      </APill>

      <APill
        v-else-if="item.swapType == 12"
        :is-active="true"
        :is-rounded="false"
        :is-small="mode !== 'list-desktop'"
        :is-extra-small="mode === 'list-desktop'"
        :class="['one-to-two']"
      >
        {{ $t('general_1_for_2') }}
      </APill>

      <APill
        v-else-if="item.swapType == 21"
        :is-active="true"
        :is-rounded="false"
        :is-small="mode !== 'list-desktop'"
        :is-extra-small="mode === 'list-desktop'"
        :class="['two-to-one']"
      >
        {{ $t('general_2_for_1') }}
      </APill>
    </div>

    <div
      v-if="mode !== 'list-mobile'"
      class="proposition-pill-wrapper"
      :class="mode"
    >
      <APill
        :is-active="true"
        :is-rounded="false"
        :is-small="mode !== 'list-desktop'"
        :is-extra-small="mode === 'list-desktop'"
        :class="['proposition-pill']"
      >
        {{ item.propositionNo }}
      </APill>
    </div>
  </div>
</template>

<script>
import APill from '@/components/atomic/atoms/a-pill';
export default {
  components: {
    APill
  },

  props: {
    ad: {
      type: Object,
      default: null
    },

    item: {
      type: Object,
      default: null
    },

    mode: {
      type: String,
      default: 'list-desktop'
      // Modes: list-desktop, card-desktop, list-mobile, card-mobile,
    }
  },

  computed: {
    isListMode() {
      return this.mode.includes('list-');
    }
  }
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}

.proposition-pill-wrapper {
  display: flex;
  flex-grow: 1;
}

.pill-wrapper.list-desktop > :first-child,
.pill-wrapper.card-mobile > :first-child,
.pill-wrapper.card-desktop > :first-child {
  margin-right: 10px;
}

.proposition-pill-wrapper.card-mobile {
  justify-content: flex-end;
  margin-right: 10px;
}

.proposition-pill {
  background-color: #e8e8e8 !important;
  border-color: #e8e8e8 !important;
  color: $brand-light !important;
  cursor: auto !important;
}

.one-to-one {
  background-color: $transfer-blue !important;
  border-color: $transfer-blue !important;
  color: white !important;
  cursor: auto !important;
}

.two-to-one {
  background-color: #ed63d2 !important;
  border-color: #ed63d2 !important;
  color: white !important;
  cursor: auto !important;
}

.one-to-two {
  background-color: #ffc212 !important;
  border-color: #ffc212 !important;
  color: $brand-dark !important;
  cursor: auto !important;
}
</style>
