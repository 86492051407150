var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gallery-wrapper"},[_c('div',{staticClass:"header-area"},[_c('h1',{staticClass:"address"},[_c('a',{attrs:{"href":"javascript:history.back()"}},[_c('span',[_vm._v(_vm._s(_vm.currentAddress || _vm.$t('proposition_gallery_loading')))])])])]),(
      _vm.currentProposition &&
      _vm.currentProposition.residences &&
      _vm.currentProposition.residences.length
    )?[(!_vm.isMobile)?_c('div',{staticClass:"gallery-area-desktop"},[_c('div',{staticClass:"current-image-wrapper"},[(_vm.images.length >= 2)?_c('router-link',{staticClass:"scroll left",attrs:{"to":_vm.getImageUrl(_vm.previousIndex),"replace":""}}):_vm._e(),(_vm.blurGalleryImages && _vm.currentImageIndex >= 2)?_c('div',{staticClass:"overlay"}):_vm._e(),(_vm.blurGalleryImages && _vm.currentImageIndex >= 2)?_c('div',{staticClass:"blur-msg-wrapper"},[_c('div',{staticClass:"blur-msg-click-container",on:{"click":function($event){$event.stopPropagation();return _vm.goToEditPropositionImages.apply(null, arguments)}}},[_c('BaseIcon',{attrs:{"icon-file":"blur-img-eye","width":48,"height":46}}),_c('div',{staticClass:"blur-msg"},[_c('p',{staticClass:"unblock-text"},[_vm._v(" "+_vm._s(_vm.$t('unlock_blur_msg'))+" ")]),_c('div',{staticClass:"upload-images-text"},[_c('span',[_c('u',[_vm._v(_vm._s(_vm.$t('proposition_gallery_blur_link_msg')))])]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('proposition_gallery_blur_msg'))+" ")])])])],1)]):_vm._e(),_c('img',{staticClass:"current-image",class:{
            'blurred-background-image':
              _vm.blurGalleryImages && _vm.currentImageIndex >= 2
          },attrs:{"src":_vm.currentShownImage}}),(_vm.images.length >= 2)?_c('router-link',{staticClass:"scroll right",attrs:{"to":_vm.getImageUrl(_vm.nextIndex),"replace":""}}):_vm._e()],1),_c('div',{staticClass:"thumbnails"},_vm._l((_vm.images),function(image,index){return _c('router-link',{key:image.fileName + index,class:[
            'thumbnail',
            {
              active: index == _vm.currentImageIndex,
              'blur-thumbnail': index >= 2 && _vm.blurGalleryImages
            }
          ],style:([{ backgroundImage: ("url(" + (image.fileName) + ")"), opacity: 1 }]),attrs:{"to":_vm.getImageUrl(index),"replace":""}})}),1)]):_c('div',{staticClass:"gallery-area-mobile"},_vm._l((_vm.images),function(img,index){return _c('div',{key:img.fileName,staticClass:"full-screen-image-mobile-container"},[_c('img',{staticClass:"full-screen-image-mobile",class:{
            'blurred-background-image': _vm.blurGalleryImages && index >= 2
          },attrs:{"src":img.fileName}}),(_vm.blurGalleryImages && index >= 2)?_c('div',{staticClass:"overlay"}):_vm._e(),(_vm.blurGalleryImages && index >= 2)?_c('div',{staticClass:"blur-msg-wrapper"},[_c('div',{staticClass:"blur-msg-click-container",on:{"click":_vm.goToEditPropositionImages}},[_c('BaseIcon',{attrs:{"icon-file":"blur-img-eye","width":48,"height":46}}),_c('div',{staticClass:"blur-msg"},[_c('p',{staticClass:"unblock-text"},[_vm._v(" "+_vm._s(_vm.$t('unlock_blur_msg'))+" ")]),_c('div',{staticClass:"upload-images-text"},[_c('span',[_c('u',[_vm._v(_vm._s(_vm.$t('proposition_gallery_blur_link_msg')))])]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('proposition_gallery_blur_msg'))+" ")])])])],1)]):_vm._e()])}),0)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }