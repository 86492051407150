function title(state) {
  return state.title;
}
function timer(state) {
  return state.timer;
}
function showTimer(state) {
  return state.showTimer;
}
function open(state) {
  return state.open;
}

export default { title, timer, showTimer, open };
