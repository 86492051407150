<template>
  <div class="toggle-wrapper">
    <div
      :class="[
        'indicator',
        { 'offset-right': currentSwapViewTab === SWAP_VIEW_TABS.MAP }
      ]"
    />

    <div
      :class="[
        'toggle-option',
        {
          selected:
            currentSwapViewTab === SWAP_VIEW_TABS.SWAP ||
            currentSwapViewTab === SWAP_VIEW_TABS.SWAP_ALTERNATIVES
        }
      ]"
      @click="() => setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP })"
    >
      {{ $t('non_user_swap_view_toggle_the_swap') }}
    </div>

    <div
      :class="[
        'toggle-option',
        { selected: currentSwapViewTab === SWAP_VIEW_TABS.MAP }
      ]"
      @click="() => setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.MAP })"
    >
      {{ $t('non_user_swap_view_toggle_map') }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SWAP_VIEW_TABS } from '../../store/modules/swapList';
export default {
  name: 'NonUserSwapViewTabs',

  props: {
    chatDot: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      SWAP_VIEW_TABS: SWAP_VIEW_TABS
    };
  },

  computed: {
    ...mapGetters({
      currentSwapViewTab: 'swapList/currentSwapViewTab'
    })
  },

  methods: {
    ...mapActions({
      setCurrentSwapViewTab: 'swapList/setCurrentSwapViewTab'
    })
  }
};
</script>

<style lang="scss" scoped>
.toggle-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f1f2f6;
  width: 100%;
  border-radius: 8px;
}

.indicator {
  position: absolute;
  top: 7%;
  left: 1%;
  width: 49%;
  height: 85%;
  border-radius: 8px;
  background-color: #fff;
  z-index: 0;
  transition: all 0.2s ease-in-out;
}

.offset-right {
  left: 50%;
}

.toggle-option {
  cursor: pointer;
  outline: none;
  font-weight: 600;
  padding: 10px 5px;
  white-space: nowrap;
  font-size: 0.8rem;
  width: 50%;
  color: $text-secondary;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
}

.unread-dot {
  height: 7px;
  width: 7px;
  animation: pulse 1.7s infinite;
  margin-left: 5px;
  border-radius: 50%;
  background-color: $transfer-blue;
}

.selected {
  color: $text-primary;
}
</style>
