<template>
  <div class="accordion-group">
    <h3 class="group-title">{{ content.AccordionGroupTitle }}</h3>
    <BaseAccordion
      v-slot="{ isOpen }"
      class="accordion"
      :accordion-id="accordionId"
    >
      <BaseAccordionItem
        v-for="(item, itemIndex) in content.AccordionItem"
        :key="itemIndex"
        :item-id="`${accordionId}-${itemIndex}`"
        :is-open="isOpen.includes(`${accordionId}-${itemIndex}`)"
        :scroll-to-open-accordion="true"
      >
        <template #header>{{ item.Title }}</template>
        <template #body>
          <span v-html="item.Description"></span>
        </template>
      </BaseAccordionItem>
    </BaseAccordion>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {}
    },

    accordionId: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion-group {
  margin-bottom: 2rem;
}

.group-title {
  font-size: 22px;
  font-weight: 600;
  margin-top: 50px;
  line-height: 1.2;
}
</style>
