<template>
  <div class="static-page">
    <div class="page-container">
      <!-- <h1 class="page-title">{{ content.PageTitle }}</h1> -->
      <div v-html="content.PageContent"></div>
    </div>

    <TheFooter :is-authenticated="isAuthenticated" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import TheFooter from '@/components/TheFooter';

export default {
  name: 'StaticPage',

  components: {
    TheFooter
  },

  data() {
    return {
      content: {}
    };
  },

  head: {
    title() {
      return {
        inner: this.content.PageTitle || ''
      };
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    })
  },

  async created() {
    const slug = this.$route.params.slug;
    window.scrollTo(0, 0);

    try {
      const response = await axios.get(
        `https://cms.lagenhetsbyte.se/static-pages/${slug}?_locale=${this.$routeLocale}`
      );
      this.content = response.data;
      this.$emit('updateHead');
    } catch (error) {
      //
    }
  }
};
</script>

<style lang="scss" scoped>
.static-page {
  max-width: none;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;

  @media ($desktop) {
    padding-top: 120px;
    padding-bottom: 0;
  }
}

.page-container {
  max-width: 900px;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;

  img {
    width: 100%;
  }
}
</style>
