<template>
  <transition
    :name="animate && message.senderId !== -1 ? 'pop-in' : null"
    appear
  >
    <article
      :class="{ 'current-user': isCurrentUser(message.senderId) }"
      class="message-wrapper"
    >
      <div
        v-if="
          message.senderId === -1 &&
          messageType === 'MATCH_CLOSED' &&
          usedInMatches
        "
        class="system-notification-wrapper"
      >
        <template v-if="parsedMessage.closedByUser === true">
          <h3>Det här matchningen är avslutad</h3>
          <p>
            Matchningen har avslutats och chatten är nu stängd för nya
            meddelanden. Om matchningen återigen blir intressant för dig kan du
            ändra din intressestatus igen direkt här i chatten.
          </p>
        </template>
        <template v-else>
          <h3>Det här matchningen är avslutad</h3>
          <p>
            Tyvärr nådde matchningen inte hela vägen den här gången och chatten
            är nu stängd för nya meddelanden.
          </p>
        </template>
        <span class="chat-notification-date">{{
          messageCreatedText(message)
        }}</span>
      </div>
      <div
        v-else-if="message.senderId === -1 && messageType !== 'MATCH_CLOSED'"
        class="chat-notification-wrapper"
      >
        <div class="notification-label">Status</div>
        <div class="chat-notification">
          <div
            class="dot system-message-dot"
            :class="{
              [systemMessageInterestColor(message.content)]:
                systemMessageInterestColor(message.content)
            }"
          ></div>
          {{ getNotificationMessage(message.content) }}
        </div>
        <p class="chat-explanation">
          {{
            messageType === 'USER_LEFT' || messageType === 'MARKED_NO'
              ? $t('chat_notification_left_chat_explanation')
              : ''
          }}
        </p>
        <span class="chat-notification-date">{{
          messageCreatedText(message)
        }}</span>
      </div>

      <div v-if="message.senderId !== -1" class="message-image-wrapper">
        <div
          v-if="
            !isCurrentUser(message.senderId) && isInterestDotVisible(message)
          "
          class="dot"
          :class="{
            [interestColor(message)]: interestColor(message)
          }"
        ></div>
        <div class="message-image">
          <router-link
            :is="owner(message).inactivatedAt ? 'div' : 'router-link'"
            v-if="!isCurrentUser(message.senderId)"
            class="swap-party-link"
            :to="`${$t('path_proposition', $routeLocale)}/${
              owner(message).propositionId
            }`"
            :target="!isMobile && '_blank'"
            @click.native="handleUserClicked(owner(message).propositionId)"
            @click.right.native="
              handlePropositionCardRightClick(owner(message).propositionId)
            "
          >
            <img :src="owner(message).residenceImage" />
          </router-link>
        </div>
      </div>

      <div v-if="message.senderId !== -1" class="message-content">
        <div
          :class="{
            'current-user': isCurrentUser(message.senderId),
            'third-party': isThirdParty(message.senderId)
          }"
          class="message-bubble"
        >
          <h2 v-if="owner(message)" class="bubble-title">
            <span>{{ owner(message).displayName }}</span
            >,
            <span>{{ owner(message).address }}</span>
          </h2>
          <p class="message-text" v-html="message.content"></p>
        </div>
        <div
          :class="{ 'current-user': isCurrentUser(message.senderId) }"
          class="date"
        >
          <span class="date-text">{{ messageCreatedText(message) }}</span>
          <template v-if="message.readBy">
            <span class="read-wrapper">
              <span
                v-for="participantId in message.readBy.filter(
                  r => !isCurrentUser(r) && r !== message.senderId
                )"
                :key="participantId"
                class="read-text"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="-20 -20 160 160"
                >
                  <path
                    stroke="#66bb6a"
                    stroke-width="32"
                    stroke-linecap="square"
                    d="M112.5 11.5L42 86.4 12 57"
                    fill="none"
                  />
                </svg>
                {{
                  !owners[participantId]
                    ? participantId.toString()
                    : owners[participantId].displayName ||
                      owners[participantId].address
                }}
              </span>
            </span>
          </template>
        </div>
      </div>
    </article>
  </transition>
</template>

<script>
import events from '@/utils/helpers/events';
import { addRightClickTracker } from '@/utils/helpers/rightClick';
import { convertSystemChatMessage } from '../../utils/systemMessageConverter';

export default {
  props: {
    message: {
      type: [Object],
      default: () => {}
    },

    owners: {
      type: [Object],
      default: () => {}
    },

    userId: {
      type: [String, Number],
      default: 0
    },

    isMobile: {
      type: [Boolean],
      default: false
    },

    ownerInterests: {
      type: Object,
      default: () => {}
    },

    animate: {
      type: Boolean,
      default: false
    },

    usedInMatches: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      messageType: ''
    };
  },
  computed: {
    messageCreatedText() {
      return message => {
        if (this.$dayjs.tz(message.createdAt) > this.$dayjs.tz()) {
          return this.$dayjs.tz().fromNow();
        } else {
          return this.$dayjs.tz(message.createdAt).fromNow();
        }
      };
    },

    parsedMessage() {
      if (this.message.senderId === -1) {
        return JSON.parse(this.message.content);
      } else {
        return this.message;
      }
    }
  },

  methods: {
    isInterestDotVisible(message) {
      const { interested, interestLevel } = this.ownerInterest(message);
      return (
        interested === false || (interested === true && interestLevel !== null)
      );
    },

    isCurrentUser(participantId) {
      if (this.owners[participantId] && this.owners[participantId].userId) {
        return this.owners[participantId].userId == this.userId;
      }

      return false;
    },

    isThirdParty(senderId) {
      if (!this.owners) {
        return false;
      }

      const participants = Object.values(this.owners).sort(
        (a, b) => a.index - b.index
      );

      if (participants.length < 3) {
        return false;
      }

      return participants[participants.length - 1] == senderId;
    },

    owner(message) {
      return this.owners[message.senderId];
    },

    interestColor(message) {
      const interest = this.ownerInterest(message);

      if (!interest) {
        return {};
      }

      const { interestLevel, interested } = interest;

      if (interestLevel === 0) {
        return 'green';
      } else if (interestLevel === 1) {
        return 'blue';
      } else if (interestLevel === 2) {
        return 'orange';
      } else if (interested === false) {
        return 'red';
      } else {
        return '';
      }
    },

    systemMessageInterestColor(msgContent) {
      const obj = JSON.parse(msgContent);

      if (obj.type == 'INTEREST_LEVEL_0' || obj.type == 'MARKED_YES') {
        return 'green';
      } else if (obj.type == 'INTEREST_LEVEL_1') {
        return 'blue';
      } else if (obj.type == 'INTEREST_LEVEL_2') {
        return 'orange';
      } else if (
        obj.type == 'INTEREST_LEVEL_3' ||
        obj.type == 'USER_LEFT' ||
        obj.type == 'MARKED_NO'
      ) {
        return 'red';
      } else {
        return '';
      }
    },

    ownerInterest(message) {
      const owner = this.owners[message.senderId];

      if (!owner || !this.ownerInterests) {
        return {};
      }

      return this.ownerInterests[owner.propositionId] || {};
    },

    getNotificationMessage(content) {
      const parsedContent = JSON.parse(content);
      this.messageType = parsedContent.type;

      return convertSystemChatMessage(content);
    },

    triggerPropositionClickedEvent(propositionId) {
      events.emitEvent(events.eventTypes.CLICK, 'View Proposition', {
        src: 'chat-message',
        propositionId: propositionId
      });
    },

    handlePropositionCardRightClick(propositionId) {
      addRightClickTracker(propositionId, 'chat-message');
    },

    handleUserClicked(propositionIds) {
      this.triggerPropositionClickedEvent(propositionIds);
    }
  }
};
</script>

<style lang="scss" scoped>
h2,
p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: $main-blue;
}

.system-notification-wrapper {
  margin-bottom: 18px;
  padding: 16px;
  border-radius: 8px;
  background: #fce4e4;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);

  @media ($desktop) {
    margin-bottom: 24px;
  }

  > * {
    margin: 0;
  }

  h3 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
  }
}

.top-label {
  margin-bottom: 12px;

  h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3;
    color: #878b9d;
  }
}

.chat-notification-wrapper {
  position: relative;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 18px;
  padding: 16px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #409fff;
  background: #fff;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.notification-label {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 8px;
  color: #858585;
  font-size: 10px;
  font-weight: 600;
}

.chat-notification {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.14px;
  display: inline-block;
}

.chat-explanation {
  font-size: 11px;
}

.chat-notification-date {
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #888c9e;
  margin-top: 2px;
}

.message-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 14px;
  margin-top: 15px;
}

.current-user.message-wrapper {
  display: flex;
  justify-content: flex-end;
}

.message-content {
  margin-left: 10px;
  max-width: 100%;
}

.message-image {
  display: flex;
  align-items: center;
  border-radius: 50%;
  display: inline-block;
  height: 50px;
  width: 50px;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
}

.message-bubble {
  background-color: #e2f4fc;
  border-radius: 8px;
  padding: 8px 16px;
}

.message-bubble.current-user {
  background-color: #f5f5f5;
}

.message-bubble.third-party {
  background-color: #e8f8ee;
}

.bubble-title {
  color: #2c3e50;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.message-text {
  line-height: 20px;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.read-text {
  white-space: nowrap;
  margin-left: 5px;
}

.current-user.date {
  justify-content: end;
  text-align: right;
}

.read-text > * {
  margin-right: 0;
}

.date {
  font-size: 12px;
  color: #878b9d;
  padding: 5px;
  margin-right: 5px;
}

.read-wrapper {
  margin-left: 8px;
}

.dot {
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 25px;
  position: absolute;
  bottom: -3px;
  right: 0px;
  border: 2px solid;
  border-color: white;
  z-index: 1;
}

.system-message-dot {
  position: relative;
  top: 2.5px;
  right: 4px;
  width: 14px;
  height: 14px;
}

.dot.blue {
  background-color: $transfer-blue;
}

.dot.orange {
  background-color: orange;
}

.dot.red {
  background-color: $sweet-red;
}

.dot.green {
  background-color: $transfer-green;
}

.message-image-wrapper {
  width: 52px;
  height: 50px;
  position: relative;
}
</style>
