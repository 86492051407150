<template>
  <div
    ref="mapWrapper"
    class="map-wrapper"
    @touchstart="$emit('touchstart', $event)"
  >
    <slot></slot>
    <AMap
      ref="AMap"
      :highlighted-marker-id="highlightedMarkerId"
      :full-screen-mode="fullScreenMode"
      @ready="handleReady($event)"
      @created="$emit('created')"
      @zoomstart="zooming = true"
      @zoomend="handleZoomEnd($event)"
      @moveend="handleMoveEnd($event)"
      @movestart="handleMoveStart($event)"
      @markerEnter="$emit('markerEnter', $event)"
      @markerLeave="$emit('markerLeave', $event)"
      @click="$emit('click', $event)"
    ></AMap>
    <div class="top-panel">
      <div v-show="isSearching" class="spinner-wrapper">
        <a-spinner :classes="['map-search-spinner']" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import icons from '@/utils/icon-constants';
import debounce from 'lodash.debounce';
import AMap from '@/components/atomic/atoms/a-map';
import ASpinner from '@/components/atomic/atoms/a-spinner';

export default {
  components: {
    AMap,
    ASpinner
  },
  props: {
    isSearching: {
      type: Boolean,
      default: false
    },

    highlightedMarkerId: {
      type: [String, Number],
      default: null
    },

    rootFilter: {
      type: Object,
      default: () => {}
    },

    isAuthenticated: {
      type: Boolean,
      default: false
    },

    searchArea: {
      type: Object,
      default: () => {}
    },
    fullScreenMode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      lastZoom: 0,
      icons,
      touched: false,
      zooming: false
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      filter: 'search/filter'
    })
  },
  created() {
    this.manualMapSearchDebounced = debounce(this.manualMapSearch, 400);
  },
  mounted() {
    this.resizeMap();
    window.addEventListener('resize', this.resizeMap);
    document.addEventListener('newSize', this.resizeMap);
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeMap);
    document.removeEventListener('newSize', this.resizeMap);
  },

  methods: {
    resizeMap() {
      if (!this.isMobile) {
        this.$refs.mapWrapper.style.height = null;
        return;
      }

      this.$nextTick(() => {
        // Remove height for header (60) and tab bar (56)
        let height = window.innerHeight - 60 - 56 - 110;
        this.$refs.mapWrapper.style.height = height + 'px';
      });
    },

    handleReady() {
      this.$emit('ready');
    },

    handleZoomEnd() {
      const mapDetails = this.getMapDetails();

      let diff = Math.abs(this.lastZoom - mapDetails.zoom);
      if (diff <= 0.5) return;

      this.lastZoom = mapDetails.zoom;

      this.manualMapSearchDebounced();
    },

    handleMoveEnd(e) {
      if (this.zooming) {
        this.zooming = false;
        return;
      }

      this.touched = true;

      this.$emit('moveend', e);
      this.manualMapSearchDebounced();
    },

    handleMoveStart(e) {
      this.manualMapSearchDebounced.cancel();
      this.$emit('movestart', e);
    },

    manualMapSearch() {
      if (!this.filter.geolocationsSource && !this.filter.freetext) {
        this.$emit('filter', {
          freetext: null,
          geolocations: [],
          geolocationsSource: null
        });
        this.$emit('rootFilter', {
          searchArea: this.getMapDetails()
        });
        this.emitSearch({
          skipTouchedMutation: false,
          scrollToTop: !this.isMobile,
          fromMapInteraction: true
        });
      } else {
        this.emitSearch({
          skipTouchedMutation: false,
          scrollToTop: !this.isMobile
        });
      }
    },

    emitSearch(options) {
      this.$emit('rootFilter', {
        map: this.getMapDetails(),
        searchArea: this.getMapDetails()
      });

      this.$emit('search', options);

      if (!options.skipTouchedMutation) {
        this.touched = false;
      }
    },

    getMapDetails() {
      const map = this.$refs.AMap.map;
      const bounds = map.getBounds();
      const zoom = map.getZoom();

      return {
        bounds: {
          sw: bounds._sw,
          ne: bounds._ne
        },
        zoom: zoom
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.spinner-wrapper {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin-top: 10px;
}

.map-search-spinner {
  border-top-color: $main_blue !important;
}

.map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  @media ($mobile) {
    position: relative;
  }
}

.top-panel {
  position: absolute;
  width: 100%;
  z-index: 0;
  text-align: center;
  top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ($mobile) {
    top: 70px;
  }
}

.icon {
  height: 15px;
  width: 15px;
}

.icon-wrapper {
  height: 20px;
  width: 20px;
  margin: 0px;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
