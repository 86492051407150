<template>
  <button
    :class="['secondary-button', { 'full-width': fullWidth }, { warn: warn }]"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'SecondaryButton',

  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    warn: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.secondary-button {
  -webkit-appearance: none;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  padding: 12px 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  color: $lighter-blue;

  &.full-width {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    outline: none;
  }

  &.warn {
    background-color: red;
  }
}
</style>
