<template>
  <div class="residence-description">
    <div class="address-box">
      <div class="head-wrap">
        <p class="sub-text">{{ residence.postalAddress }}</p>

        <div class="mobile-like-and-prop-type-wrap">
          <template v-if="isMobile">
            <!-- Ta bort favorit -->
            <button
              v-if="!isUserProposition && isFavourite"
              :class="['like-btn', { 'is-favourite': isFavourite }]"
              @click.stop="toggleFavourite"
            ></button>
            <!-- Favorit -->
            <button
              v-if="!isUserProposition && !isFavourite"
              :class="['like-btn', { 'is-favourite': isFavourite }]"
              @click.stop="toggleFavourite"
            ></button>
          </template>

          <div v-if="isMobile" :class="['proposition-type', typeColor]">
            {{ swapLabel }}
          </div>
        </div>
      </div>
      <div class="head-wrap">
        <h1 class="address">{{ residence.streetAddress }}</h1>

        <div v-if="!isMobile" class="head-details">
          <button
            v-if="!isUserProposition && isFavourite"
            :class="['like-btn', { 'is-favourite': isFavourite }]"
            @click.stop="toggleFavourite"
          >
            <!-- Ta bort favorit -->
          </button>
          <button
            v-if="!isUserProposition && !isMobile && !isFavourite"
            :class="['like-btn', { 'is-favourite': isFavourite }]"
            @click.stop="toggleFavourite"
          >
            <!-- Favorit -->
          </button>

          <div :class="['proposition-type', typeColor]">
            {{ swapLabel }}
          </div>
        </div>
      </div>
      <a v-if="isMobile" class="view-on-map" @click="scrollToMap">{{
        $t('proposition_description_view_on_map')
      }}</a>
    </div>
    <div class="apartment-info">
      <p class="info-text">
        {{ residence.rooms }} {{ $t('proposition_description_room') }} &#8729;
        {{ residence.sqm }} {{ $t('proposition_description_sqm') }} &#8729;
        {{ readableFloor }}
      </p>
    </div>
    <div class="bottom">
      <p class="sub-text">
        {{ spacedRent }} {{ $t('proposition_description_cost_per_month') }}
      </p>
      <p v-if="!isMobile" class="proposition-number">
        {{ $t('proposition_description_prop_num') }}
        <b>{{ propositionNo }}</b>
      </p>
    </div>

    <div v-if="hasAmenities" class="section-wrapper separator">
      <conveniences :residence="residence" />
    </div>

    <proposition-description
      :description="residence.description"
      :type="residence.type"
      :landlord="residence.landlord"
      :proposition-no="propositionNo"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PropositionDescription from './PropositionDescription';
import Conveniences from '../../../components/Proposition/Conveniences';
import events from '@/utils/helpers/events';

export default {
  name: 'Description',

  components: {
    PropositionDescription,
    Conveniences
  },

  props: {
    residence: {
      type: Object,
      required: true
    },
    isUserProposition: {
      type: Boolean,
      required: false
    },
    propositionNo: {
      type: String,
      required: true
    },
    propositionId: {
      type: String,
      required: true
    },
    isFavourite: {
      type: Boolean
    },
    swapType: {
      type: Number,
      required: true,
      validator: function (value) {
        return [11, 21, 12].includes(value);
      }
    },
    scrollToMap: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      canClickFavourite: true
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      isAuthenticated: 'app/isAuthenticated'
    }),

    swapLabel() {
      const { swapType } = this;
      switch (swapType) {
        case 21:
          return this.$t('general_2_for_1');
        case 12:
          return this.$t('general_1_for_2');
        default:
          return this.$t('general_1_for_1');
      }
    },
    typeColor() {
      const { swapType } = this;
      switch (swapType) {
        case 21:
          return 'pink';
        case 12:
          return 'yellow';
        default:
          return 'blue';
      }
    },

    hasAmenities() {
      const amenities = [
        'balcony',
        'elevator',
        'fireplace',
        'bathtub',
        'isCondo',
        'isBecomingCondo'
      ];

      return amenities.some(amenity => this.residence[amenity]);
    },

    readableFloor() {
      if (this.residence.floor === 0)
        return this.$t('proposition_description_floor_0');
      if (this.residence.floor === 999)
        return this.$t('proposition_description_floor_roof');

      return `${this.$t('proposition_description_floor')} ${
        this.residence.floor
      }`;
    },
    spacedRent() {
      return this.residence.rent
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  },
  methods: {
    ...mapActions({
      markFavourite: 'favourites/markFavourite',
      unmarkFavourite: 'favourites/unmarkFavourite'
    }),
    toggleFavourite() {
      if (!this.isAuthenticated) {
        events.emitEvent(events.eventTypes.SEE, 'Conversion Modal', {
          src: `favourite-mark-proposition`
        });
        this.$emit('toggleConversionModal');
        return;
      }

      if (!this.canClickFavourite) return;

      this.canClickFavourite = false;

      const { propositionId } = this;

      if (this.isFavourite) {
        events.emitEvent(events.eventTypes.CLICK, 'Favourite Unmark', {
          src: `proposition`
        });
        window.Logger.log('unmark favourite', propositionId);
        this.unmarkFavourite({ propositionId });
      } else {
        events.emitEvent(events.eventTypes.CLICK, 'Favourite Mark', {
          src: `proposition`
        });
        window.Logger.log('mark favourite', propositionId);
        this.markFavourite({ propositionId });
      }

      setTimeout(() => (this.canClickFavourite = true), 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.residence-description {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  flex-direction: column;

  @media ($desktop) {
    width: 100%;
  }
}

.more-info-container {
  border-top: 1px solid rgba(28, 45, 65, 0.103);
  margin-bottom: 20px;

  @media ($mobile) {
    margin-bottom: 10px;
  }
}

.more-info-container .host {
  background-size: contain;
  background-position: left;
  background-image: url('../../../assets/svg/host.svg');
  padding-left: 20px;
  @media ($desktop) {
    padding-left: 25px;
  }
}
.more-info-container .type {
  background-size: contain;
  background-position: left;
  background-image: url('../../../assets/svg/residenceType.svg');
  padding-left: 20px;
  @media ($desktop) {
    padding-left: 25px;
  }
}

.more-info-container p {
  display: inline-block;
  color: $text-secondary;
  font-size: 1rem;
  font-weight: 600;
  margin-right: 15px;

  @media ($mobile) {
    font-size: 0.85rem;
  }
}

.address-box {
  margin-bottom: 5px;
}

.head-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.head-details {
  display: flex;
  align-items: center;
}

.sub-text {
  font-weight: 600;
  font-size: 0.95rem;
  color: $text-secondary;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;

  @media ($desktop) {
    font-size: 1rem;
  }
}

.address {
  font-size: 1.5rem;
  margin: 0;
  @media ($desktop) {
    font-size: 1.6rem;
  }
}

.apartment-info {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin: 15px 0 0px 0;

  @media ($mobile) {
    width: 100%;
    margin-top: 7px;
  }
}

.info-text {
  color: $text-primary;
  font-size: 1rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
  position: relative;
}

.mobile-like-and-prop-type-wrap {
  display: flex;
}

.like-btn {
  height: 17px;
  background-position: left;
  background-size: contain;
  background-image: url('../../../assets/svg/heart-empty.svg');
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin-right: 0px;
  outline: none;
  padding-left: 27px;
  cursor: pointer;
  font-size: 0.9rem;
  padding-right: 0px;
  margin-top: 2px;

  &.is-favourite {
    background-image: url('../../../assets/svg/heart-full.svg');
  }
}

.proposition-type {
  padding: 5px 8px;
  border-radius: 12px;
  font-weight: 700;
  white-space: nowrap;
  font-size: 0.7rem;
  margin-left: 8px;

  @media ($mobile) {
    margin-bottom: 6px;
  }

  &.pink {
    background-color: #fce5f6;
    color: #ed63d2;
  }
  &.blue {
    color: $transfer-blue;
    background-color: #e7f3ff;
  }
  &.yellow {
    color: #ffc212;
    background-color: #fff8e6;
  }
}

.bottom {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  @media ($mobile) {
    margin-top: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(28, 45, 65, 0.103);

    & > p {
      margin: 3px 0;
    }
  }
}

.bottom .sub-text {
  color: $text-primary;
  @media ($mobile) {
    font-size: 1rem;
  }
}

.view-on-map {
  font-weight: 600;
  font-size: 0.95rem;
  color: $main-blue;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  margin-top: 5px;
  text-decoration: none;
  display: block;
}
.section-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 20px 0 16px 0;

  &.flex-space-between {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ($mobile) {
      width: 90vw;
    }
  }

  & .extra-vis-button {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    color: $text-secondary;
    font-size: 0.85em;
    font-weight: 600;
  }
  @media ($mobile) {
    margin: 10px 0 16px 0;
    font-size: 0.85rem;
  }
}

.separator {
  padding-bottom: 30px;
  padding-top: 30px;
  border-top: 1px solid rgba(28, 45, 65, 0.103);

  @media ($mobile) {
    padding-bottom: 0px;
    border-top: 0;
    padding-top: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(28, 45, 65, 0.103);
  }
}
.proposition-number {
  font-size: 0.9rem;
  font-weight: 600;

  @media ($mobile) {
    padding-top: 20px;
  }
}
</style>
