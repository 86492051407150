<template>
  <div class="input-container">
    <p v-if="title" class="title">{{ title }}</p>
    <p v-if="description" class="description">{{ description }}</p>

    <div
      :class="[
        'input-field-wrapper',
        { valid: !hideCheckMark && isValid && value, error: showErrorMessage }
      ]"
    >
      <MapSuggestions
        v-if="hasFocus && value.length >= 3"
        :suggestions="suggestions"
        :highlight-value="value"
        :handle-click="suggestionCallback"
        :no-results-found="noResultsFound"
        :no-results-message="noResultsMessage"
        :show-icon="showResultsIcon"
      />

      <div v-if="loading" class="loading">
        <svg
          class="spinner"
          :style="{ height: `24px`, width: `24px` }"
          viewBox="0 0 50 50"
        >
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke="#93bfec"
            stroke-width="5"
          />
        </svg>
      </div>

      <BaseInput
        ref="mapInput"
        :name="id"
        :icon="inputIcon"
        :label="inputLabel"
        :placeholder="placeholder"
        :type="type"
        :value="value"
        :autofocus="autoFocus"
        :valid="showErrors && !value ? false : true"
        autocomplete="off"
        @blur="setBlur"
        @focus="setFocus"
        @input="handleInput"
        @keydown="() => $emit('keydown')"
        @input-icon-clicked="() => $emit('input-icon-clicked')"
      />
    </div>

    <SelectedSuggestions
      v-if="selectedSuggestions"
      :selected-suggestions="selectedSuggestions"
      :deselect-suggestion="deselectSuggestion"
    />

    <span v-if="showErrorMessage" class="error-message">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
import MapSuggestions from './MapSuggestions';
import SelectedSuggestions from './MapSelectedSuggestions';

export default {
  name: 'MapInputField',
  components: {
    MapSuggestions,
    SelectedSuggestions
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    handleUpdate: {
      type: Function,
      required: true
    },
    setFocus: {
      type: Function,
      default: () => {}
    },
    isValid: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    inputIcon: {
      type: String,
      default: ''
    },
    inputLabel: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    noAutoComplete: {
      type: Boolean,
      default: false
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    isDirty: {
      type: Boolean,
      default: false
    },
    isTouched: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    hasFocus: {
      type: Boolean,
      default: false
    },
    setBlur: {
      type: Function,
      default: () => undefined
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    suggestions: {
      type: Array,
      default: () => undefined
    },
    suggestionCallback: {
      type: Function,
      default: () => undefined
    },
    selectedSuggestions: {
      type: Array,
      default: () => undefined
    },
    deselectSuggestion: {
      type: Function,
      default: () => undefined
    },
    hideCheckMark: {
      type: Boolean,
      default: false
    },
    onChangeCallback: {
      type: Function,
      default: () => undefined
    },
    suffix: {
      type: String,
      default: ''
    },
    noResultsFound: {
      type: Boolean,
      default: false
    },
    noResultsMessage: {
      type: String,
      default: ''
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    showResultsIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showErrorMessage() {
      const { isValid, hasFocus, isTouched, isDirty } = this;
      return !isValid && !hasFocus && (isTouched || isDirty);
    }
  },
  methods: {
    handleInput(value) {
      this.handleUpdate(value);
      this.onChangeCallback(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.input-container {
  position: relative;
}

.description {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 6px 0;
  color: #878b9d;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  position: absolute;
  right: 24px;
  top: calc(50% - 12px);
}

.spinner {
  animation: rotate 2s linear infinite;
  & .path {
    animation: dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150; // ie11 fallback
    stroke-dashoffset: -35; // ie11 fallback
    stroke-linecap: round;
    stroke: #93bfec;
  }
}
</style>
