<template>
  <button :class="['menu-option', { selected: active }]">
    <p class="menu-option-text">
      <slot />
      <span v-if="showCount" class="menu-option-count">({{ count }})</span>
    </p>
  </button>
</template>

<script>
export default {
  name: 'TabNavigationItem',

  props: {
    active: {
      type: Boolean,
      default: false
    },
    showCount: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-option {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 12px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
  color: #a1a7ae;
  background-color: #fff;
  text-align: center;
  border: none;
  white-space: nowrap;
  cursor: pointer;

  @media ($desktop) {
    background-color: #f7f7f9;
    margin-right: 16px;
  }
}

.menu-option.selected {
  color: #409fff;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: #409fff;
  }
}

.menu-option-text {
  font-weight: 600;
  margin: 0;
}

.menu-option-count {
  display: inline-block;
  margin-left: 1px;
}
</style>
