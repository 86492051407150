<template>
  <input
    v-bind="settings"
    :class="classes"
    :style="styles"
    :value="value"
    v-on="listeners"
  />
</template>

<script>
export default {
  name: 'AInput',
  props: {
    settings: {
      type: Object,
      default: () => {
        return {};
      },
      required: false
    },
    classes: {
      type: Array,
      default: () => [],
      required: false
    },
    styles: {
      type: Array,
      default: () => [],
      required: false
    },
    value: {
      type: [String, Number],
      default: '',
      required: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: event => this.$emit('input', event.target.value)
      };
    }
  }
};
</script>
