const mobileMediaQuery = window.matchMedia('(max-width: 768px)');
const smallDesktopQuery = window.matchMedia(
  '(min-width: 769px) and (max-width: 1279px)'
);

async function checkScreenSize({ state, commit }) {
  let newSize = 'DESKTOP';

  if (mobileMediaQuery.matches) {
    newSize = 'MOBILE';
  } else if (smallDesktopQuery.matches) {
    newSize = 'DESKTOP_SMALL';
  }

  if (state.screenSize !== newSize) {
    commit('setScreenSize', newSize);
    document.dispatchEvent(new CustomEvent('new-size', { detail: newSize }));
  }
}

export default {
  checkScreenSize
};
