import { render, staticRenderFns } from "./Swaps.vue?vue&type=template&id=91dac306&scoped=true"
import script from "./Swaps.vue?vue&type=script&lang=js"
export * from "./Swaps.vue?vue&type=script&lang=js"
import style0 from "./Swaps.vue?vue&type=style&index=0&id=91dac306&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91dac306",
  null
  
)

export default component.exports