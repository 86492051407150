<template>
  <div class="cancel-step-wrapper">
    <spinner v-if="cancelSubscriptionLoading" />
    <div class="congratulations-illustration"></div>
    <h2 class="title larger">{{ $t('congratulations_title') }}</h2>

    <p class="subtitle">{{ $t('congratulations_subtitle_1') }}</p>
    <p class="subtitle">{{ $t('congratulations_subtitle_2') }}</p>

    <div class="button-wrapper">
      <primary-button
        :disabled="cancelSubscriptionLoading"
        @click.native="handleCancelClick"
        >{{ $t('congratulations_confirm_button') }}</primary-button
      >

      <link-button @click.native="closeFunction">{{
        $t('congratulations_cancel_button')
      }}</link-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import PrimaryButton from '../../Buttons/Primary';
import LinkButton from '../../Buttons/Link';
import Spinner from '../../Loading/Spinner';

export default {
  name: 'CancelSubscriptionCongratulations',

  components: {
    PrimaryButton,
    LinkButton,
    Spinner
  },

  props: {
    closeFunction: {
      type: Function,
      required: true
    },
    cancelSubscription: {
      type: Function,
      required: true
    },
    cancelSubscriptionLoading: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    ...mapActions({
      sendFeedback: 'handleSubscription/sendFeedback'
    }),

    handleCancelClick: function () {
      this.sendFeedback();
      this.cancelSubscription({ swapped: true });
    }
  }
};
</script>

<style lang="scss" scoped>
.cancel-step-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  max-width: 800px;
  margin: 0 auto;

  @media ($mobile) {
    padding-bottom: 50px;
  }
}

.title {
  color: $text-primary;
  @media ($mobile) {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  @media ($desktop) {
    margin-top: 40px;
    font-size: 1.8rem;
  }
}

.larger {
  font-size: 1.4rem;

  @media ($desktop) {
    font-size: 2rem;
  }
}

.subtitle {
  color: $text-secondary;
  font-weight: 600;
  max-width: 490px;
  text-align: center;

  font-size: 1rem;
  line-height: 24px;
}

.congratulations-illustration {
  height: 140px;
  width: 140px;
  background-size: contain;
  background-position: center;
  background-image: url('../../../assets/svg/baloons.svg');
  margin-top: 20px;
  @media ($mobile) {
    height: 150px;
    width: 150px;
  }
}

.button-wrapper {
  margin: 30px 0;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 10px;
  }
}
</style>
