<template>
  <div class="toggle-wrapper">
    <div
      :class="[
        'indicator',
        { 'offset-middle': currentSwapViewTab === SWAP_VIEW_TABS.CHAT },
        { 'offset-right': currentSwapViewTab === SWAP_VIEW_TABS.MAP }
      ]"
    />
    <!-- <div
      v-else
      :class="[
        'indicator indicator-small',
        { 'offset-1': currentSwapViewTab === SWAP_VIEW_TABS.SWAP },
        { 'offset-2': currentSwapViewTab === SWAP_VIEW_TABS.CHAT },
        { 'offset-3': currentSwapViewTab === SWAP_VIEW_TABS.MAP }
      ]"
    />

    <div
      v-if="isFullInterest"
      :class="[
        'toggle-option',
        { selected: currentSwapViewTab === SWAP_VIEW_TABS.FULL_INTEREST }
      ]"
      @click="
        () => setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.FULL_INTEREST })
      "
    >
      {{ $t('swap_view_toggle_full') }}
    </div> -->

    <div
      :class="[
        'toggle-option',
        {
          selected:
            currentSwapViewTab === SWAP_VIEW_TABS.SWAP ||
            currentSwapViewTab === SWAP_VIEW_TABS.SWAP_ALTERNATIVES
        }
      ]"
      @click="() => setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP })"
    >
      {{ $t('swap_view_toggle_the_swap') }}
    </div>

    <div
      :class="[
        'toggle-option',
        { selected: currentSwapViewTab === SWAP_VIEW_TABS.CHAT }
      ]"
      @click="() => setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.CHAT })"
    >
      {{ $t('swap_view_toggle_chat') }}
      <div v-if="chatDot" class="unread-dot" />
    </div>

    <div
      :class="[
        'toggle-option',
        { selected: currentSwapViewTab === SWAP_VIEW_TABS.MAP }
      ]"
      @click="() => setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.MAP })"
    >
      {{ $t('swap_view_toggle_map') }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SWAP_VIEW_TABS } from '../../store/modules/swapList';
export default {
  name: 'SwapViewTabs',

  props: {
    chatDot: {
      type: Boolean,
      default: false
    },
    isFullInterest: {
      type: Boolean,
      default: false
    },
    hasActiveChat: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      SWAP_VIEW_TABS: SWAP_VIEW_TABS
    };
  },
  computed: {
    ...mapGetters({
      currentSwapViewTab: 'swapList/currentSwapViewTab'
    })
  },

  methods: {
    ...mapActions({
      setCurrentSwapViewTab: 'swapList/setCurrentSwapViewTab'
    })
  }
};
</script>

<style lang="scss" scoped>
.toggle-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f1f2f6;
  width: 100%;
  border-radius: 8px;
}

.indicator {
  position: absolute;
  top: 7%;
  left: 3px;
  width: 33.3%;
  height: 85%;
  border-radius: 8px;
  background-color: #fff;
  z-index: 0;
  transition: all 0.2s ease-in-out;

  &-small {
    left: 0.5%;
    width: 25%;
  }
}

.offset-1 {
  left: 25%;
}

.offset-2 {
  left: 50%;
}

.offset-3 {
  left: 74.5%;
}

.offset-middle {
  left: 33.3%;
}

.offset-right {
  left: 66.3%;
}

.toggle-option {
  cursor: pointer;
  outline: none;
  font-weight: 600;
  padding: 10px 5px;
  white-space: nowrap;
  font-size: 0.8rem;
  width: 50%;
  color: $text-secondary;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
}

.unread-dot {
  height: 7px;
  width: 7px;
  animation: pulse 1.7s infinite;
  margin-left: 5px;
  border-radius: 50%;
  background-color: $transfer-blue;
}

.selected {
  color: $text-primary;
}
</style>
