<template>
  <div class="cta-box-overlay">
    <div class="cta-box">
      <p>{{ $t('gallery_not_logged_in') }}</p>
      <PrimaryButton :accent="true" class="cta-btn" @click.native="goToSignup">
        {{ $t('gallery_not_logged_button') }}
      </PrimaryButton>
    </div>
  </div>
</template>
<script>
import PrimaryButton from '@/components/Buttons/Primary';

export default {
  components: {
    PrimaryButton
  },
  methods: {
    goToSignup() {
      window.open(this.$t('path_get_started', this.$routeLocale));
    }
  }
};
</script>
<style lang="scss" scoped>
p {
  margin: 0;
}
.cta-box-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  font-weight: 700;
  font-size: 0.9rem;
  background-color: rgba(44, 62, 80, 0.8);
  word-break: break-word;
  text-align: center;
  z-index: 2;
}

.cta-box {
  font-size: 12px;
  padding: 0 10px;
  line-height: 1.5;
  @media ($mobile) {
    font-size: 16px;
  }
}
.cta-btn {
  margin: 5px auto 0;
  display: block;
  font-size: 12px;
  height: auto;
  white-space: nowrap;
  max-width: 95%;
  padding: 0 10px;
  @media ($mobile) {
    margin: 15px auto 0;
    font-size: 16px;
  }
}
</style>
