import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { getValue } from '../country/module';

async function init() {
  const currentLang = getValue('LANGUAGE');

  await import(`dayjs/locale/${currentLang}.js`);

  dayjs.locale(currentLang);

  dayjs.extend(relativeTime);
  dayjs.extend(localeData);
  dayjs.extend(localizedFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const defaultTimezone = 'Europe/Stockholm';
  dayjs.tz.setDefault(defaultTimezone);
  console.log('Timezone:', defaultTimezone);
}

init();

export { dayjs };

export default {
  install(Vue) {
    Vue.prototype.$dayjs = dayjs;
  }
};
