import socket from '@/utils/socket';
import { router } from '../../../main';
import routesConstants from '../../../router/routes.constants';
import { authenticationApi } from '../../../utils/axiosConfig';
import services from './services';
import { setCookie } from '../../../utils/cookies';
import {
  initGrowthbook,
  setGrowthbookAttributes
} from '../../../plugins/growthbook/growthbook';

async function checkIfAuthenticated(context) {
  const user = await services.fetchUser();
  if (user) {
    context.commit('setAuthenticated', { isAuthenticated: true });
    context.commit('setUser', user);
    context.commit('setImpersonated', {
      isImpersonated: user.isImpersonated || false
    });
    setGrowthbookAttributes();
    console.log(user);
    return true;
  }

  context.commit('setAuthenticated', { isAuthenticated: false });
  return false;
}

async function checkAuth(context) {
  await context.dispatch('initAppData');

  const isAuthenticated = context.getters['isAuthenticated'];
  if (
    !isAuthenticated &&
    router.currentRoute.fullPath !== routesConstants.LOGIN
  ) {
    router.replace(routesConstants.LOGIN);
  }
}

async function initAppData(context) {
  context.dispatch('screenSize/checkScreenSize', null, { root: true });
  // 1. Check if the user is authenticated
  await context.dispatch('checkIfAuthenticated');

  await initGrowthbook();

  if (!context.state.isAuthenticated) {
    // Proposition page in modal reroute
    const propositionPageId = new URL(location.href).searchParams.get(
      'propositionPageId'
    );
    if (propositionPageId) {
      location.href = `${routesConstants.PROPOSITION}/${propositionPageId}`;
      return;
    }

    window.Logger.log('>>> Not authenticated, setting appready to true <<<');
    const accessOverridable = await context.dispatch('isAccessOverridable');
    context.commit('setIsAccessOverridable', accessOverridable);

    context.commit('appReady');
    return;
  }

  socket.connect();

  context.dispatch('payment/init', null, { root: true });
  context.dispatch('interests/init', null, { root: true });

  // 2. get primary proposition and minimal my propositions
  const [primaryProposition, myPropositions] = await Promise.all([
    context.dispatch('myPropositions/fetchPrimaryProposition', null, {
      root: true
    }),
    context.dispatch('myPropositions/fetchPropositions', null, { root: true })
  ]);

  context.dispatch('extraVisibility/checkPaymentRedirect', null, {
    root: true
  });

  context.commit(
    'myPropositions/setPrimaryProposition',
    {
      primaryProposition
    },
    { root: true }
  );

  setGrowthbookAttributes(null, true);

  // 3. Fire app data ready, if primary proposition doesn't exists and end there
  if (!primaryProposition) {
    context.commit('appReady');
    return;
  }

  context.dispatch('swapList/getSwapInterestCounts', null, { root: true });

  // 4. set trial banner
  context.dispatch(
    'trial/setTrialBanner',
    {
      primaryPropositionId: primaryProposition.propositionId,
      propositions: myPropositions
    },
    {
      root: true
    }
  );
  context.dispatch(
    'trial/setOutdatedTrials',
    { propositions: myPropositions },
    { root: true }
  );

  // 5. Get notification center notifications
  context.dispatch('notificationCenter/init', null, { root: true });

  context.dispatch('menu/init', primaryProposition.propositionId, {
    root: true
  });
  context.dispatch('myPropositions/init', null, { root: true });

  // 7. Fire app data ready
  context.commit('appReady');
}

async function removeImpersonation(context) {
  let isImpersonated = false;
  const dotDomain = location.hostname.replace('www', '');
  setCookie('isImpersonated', false, 0, dotDomain);

  context.commit('setImpersonated', { isImpersonated });
}

async function updateUser(context, user) {
  context.commit('updateUser', user);
}

async function isAccessOverridable() {
  try {
    await authenticationApi.get('/auth/preview');
    return true;
  } catch (error) {
    // do nothing
  }

  return false;
}

async function addAccountInGdprDeleteJobList() {
  try {
    await authenticationApi.post('gdpr-removal');
    return true;
  } catch (error) {
    return false;
  }
}

export default {
  removeImpersonation,
  initAppData,
  checkIfAuthenticated,
  updateUser,
  checkAuth,
  isAccessOverridable,
  addAccountInGdprDeleteJobList
};
