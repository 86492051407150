<template>
  <div class="image-and-text-block">
    <img
      :src="content.Image.formats.small.url"
      alt=""
      :class="content.ReverseBlock ? 'reversed' : ''"
    />
    <div class="text-area editor-content" v-html="content.Richtext"></div>
  </div>
</template>

<script>
export default {
  name: 'ImageAndText',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.image-and-text-block {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;
  margin-bottom: 16px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 16px;
  padding-left: 16px;

  @media ($large-screen) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    margin-bottom: 32px;
  }

  img {
    width: 100%;
  }
}

.text-area ::v-deep h1 {
  font-family: $font-headings;
  font-weight: 900;
  font-size: 32px;
  line-height: 120%;
  color: #213975;
  margin-bottom: 16px;

  @media ($desktop) {
    font-size: 48px;
  }
}

.reversed {
  @media ($large-screen) {
    order: 2;
  }
}

.text-area {
  display: grid;
  align-content: center;
  padding: 16px 0;

  @media ($large-screen) {
    padding: 32px 0;
  }
}
</style>
