<template>
  <Spinner v-if="isLoading" />
  <div v-else class="info-modal-container">
    <div class="overlay" @click="$emit('close')"></div>
    <transition name="pop-in" appear>
      <div class="info-modal">
        <div class="info-modal-body">
          <slot />
        </div>
        <div class="info-modal-action-container">
          <slot name="actions" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Spinner from '@/components/Loading/Spinner';

export default {
  name: 'BaseInfoModal',

  components: { Spinner },

  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    $route(newVal, oldVal) {
      // Prevent closing on query change
      if (newVal.path !== oldVal.path) {
        this.$emit('close');
      }
    }
  },

  created() {
    this.$emit('open');
  },

  methods: {
    tertiaryLinkClicked() {
      this.$emit('tertiary-clicked');
    }
  }
};
</script>

<style lang="scss" scoped>
.info-modal-container {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-modal {
  position: fixed;
  width: 90%;
  max-width: 350px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  z-index: 3000;
}

.info-modal-body {
  max-height: 90vh;
  overflow-y: auto;
}

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
</style>
