const getters = {
  isLoading: state => state.isLoading,
  data: state => state.data,
  rootFilter: state => state.filter,
  filter: state => state.filter.filter,
  coverageData: state => state.coverageData,
  isLoadingCoverage: state => state.isLoadingCoverage,
  activeFilterKeys: state => state.activeFilterKeys
};

export default getters;
