import i18n, { routeLocale } from '@/i18n';
import { getValue } from '../plugins/country/module';
import { isDebug } from './debug';
import { getCookies } from '@/utils/helpers/cookie';

const domain = getValue('DOMAIN');
const isDev = isDebug();

function getAuthenticationEndpoint() {
  if (!isDev) {
    return `https://auth.${domain}`;
  }

  return `https://auth-dev.${domain}`;
}

function getLbApiEndpoint() {
  if (!isDev) {
    return `https://www.${domain}`;
  }
  // else if (window.location.hostname.startsWith('local.')) {
  //   return `http://local.${domain}:3000`;
  // }
  else if (!window.location.hostname.startsWith('release.')) {
    return `https://proxy.${domain}`;
  } else {
    return `https://release.${domain}`;
  }
}

function getPaymentEndpoint() {
  if (isDev) {
    const cookies = getCookies();
    console.log('getPaymentEndpoint:', cookies.paymentEndpointType);

    if (!cookies.paymentEndpointType || cookies.paymentEndpointType === 'dev') {
      return `https://payment-dev.${domain}`;
    } else if (cookies.paymentEndpointType === 'prod') {
      return `https://payment.${domain}`;
    }
  }

  return `https://payment.${domain}`;
}

function getSearchEndpoint() {
  if (isDev) {
    return `https://search-dev.${domain}`;
  }

  return `https://search.${domain}`;
}

function getBetaEndpoint() {
  const { hostname } = window.location;
  if (hostname.indexOf('local.') === 0) {
    //const url = `https://${hostname.replace('local.', 'localapi.')}`;
    const url = `https://${hostname}`;
    if (checkIfLocalUrlExists(url)) {
      return url;
    }
  }

  if (isDev) {
    return i18n.t('url_release_api', routeLocale);
  }

  return i18n.t('url_api', routeLocale);
}

function getAWSEndpoint() {
  return i18n.t('url_aws', routeLocale);
}

function getStreetviewEndpoint() {
  return `https://streetview2.lagenhetsbyte.se`;
}

function getLbPropositionApiEndpoint() {
  if (i18n.t('url_proposition2', routeLocale) !== 'url_proposition2') {
    return i18n.t('url_proposition2', routeLocale);
  }
  return i18n.t('url_proposition', routeLocale);
}

function getLbUserApiEndpoint() {
  if (isDev) {
    return i18n.t('url_user_dev', routeLocale);
  }

  return i18n.t('url_user', routeLocale);
}

function getGrowthbookEndpoint() {
  if (isDev) {
    return `${i18n.t(
      'url_growthbook',
      routeLocale
    )}/api/features/sdk-hjCQTYvcbXSocjRM`;
  }
  return `${i18n.t(
    'url_growthbook',
    routeLocale
  )}/api/features/sdk-KOiKSqWF9kqykF`;
}

function getNotificationsEndpoint() {
  return i18n.t('url_notifications', routeLocale);
}

function checkIfLocalUrlExists(url) {
  try {
    var http = new XMLHttpRequest();
    http.open(
      'HEAD',
      `${url}/api/statistics/GetStartpageStatistics?date=${new Date().toLocaleString()}`,
      false
    );
    http.send(null);
    if (http.status === 404) {
      return false;
    }
  } catch (error) {
    return false;
  }

  return true;
}

function getAdmin2ApiEndpoint() {
  const { hostname } = window.location;
  if (hostname.indexOf('local.') === 0) {
    const url = `https://${hostname}`;
    if (checkIfLocalUrlExists(url)) {
      return url;
    }
  }

  return i18n.t('url_admin2_api', routeLocale);
}

export {
  getLbApiEndpoint,
  getLbPropositionApiEndpoint,
  getLbUserApiEndpoint,
  getBetaEndpoint,
  getAWSEndpoint,
  getSearchEndpoint,
  getStreetviewEndpoint,
  getNotificationsEndpoint,
  getAuthenticationEndpoint,
  getPaymentEndpoint,
  getGrowthbookEndpoint,
  getAdmin2ApiEndpoint
};
