<template>
  <BaseLink :link="link" class="back-button">
    <BaseIcon icon="arrow-left" class="back-button-icon" />
    <slot>{{ $t('general_go_back') }}</slot>
  </BaseLink>
</template>

<script>
export default {
  name: 'BaseBackButton',

  props: {
    link: {
      type: String,
      default: '',
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.back-button {
  margin-top: -10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  border: none;
  color: $text-primary;

  @media ($desktop) {
    margin-bottom: 30px;
  }

  &:hover,
  &:focus {
    color: $text-primary;
    text-decoration: underline;
  }

  &:visited {
    color: $text-primary;
  }
}

.back-button-icon {
  margin-right: 3px;
}
</style>
