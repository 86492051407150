/**
 * Regex for Email check and whitepsaces
 */
export const regexEmail =
  /\b([A-Za-z0-9._%+-]+\s*@\s*[A-Za-z0-9.-]+\s*\.[A-Z|a-z|0-9]{2,}|\bgmail\b|\byahoo\b|\bhotmail\b|\boutlook\b|\bicloud\b|\bgmx\b)\b/gi;

/**
 * Regex for Phone check for all countries with dashes
 */
const regexPhone =
  /(0046|\+46|0046\(\s*0\s*\)|\+46\(\s*0\s*\)|0031|\+31|0031\(\s*0\s*\)|\+31\(\s*0\s*\)|0049|\+49|0049\(\s*0\s*\)|\+49\(\s*0\s*\)|0045|\+45|0045\(\s*0\s*\)|\+45\(\s*0\s*\)|0044|\+44|0044\(\s*0\s*\)|\+44\(\s*0\s*\)|0033|\+33|0033\(\s*0\s*\)|\+33\(\s*0\s*\)|\(\s*0\s*\)|0|\+?\d+)(-)?(\d{7,})/g;

export default {
  email: regexEmail,
  phone: regexPhone
};
