export function isObjectSame(obj1, obj2) {
  if (obj1 !== obj2) return false;

  if (obj1 && obj2) {
    for (const key in obj1) {
      if (obj2[key] !== obj1[key]) return false;
    }
  }

  return true;
}
