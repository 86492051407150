function toggleMobileHeader(state, value) {
  state.showMobileHeader = value;
}

function toggleDesktopHeader(state, value) {
  state.showDesktopHeader = value;
}

function setGlobalModal(state, value) {
  state.showGlobalModal = value;
}

function setCookiesModal(state, value) {
  state.showCookiesModal = value;
}

function setPropositionViewModalId(state, { propositionId, type }) {
  state.propositionViewModalId = propositionId;
  state.propositionType = type;
}

function pageModalActivePage(state, value) {
  state.pageModalActivePage = value;
}

function pageModalZIndex(state, value) {
  state.pageModalZIndex = value;
}

function toggleDisableScroll(state, value) {
  // Check if state.disableScroll already contains value
  if (state.disableScroll.includes(value)) {
    // If it does, remove it
    state.disableScroll = state.disableScroll.filter(item => item !== value);
  } else {
    // If it doesn't, add it
    state.disableScroll.push(value);
  }
}

function addDisableScroll(state, value) {
  if (!state.disableScroll.includes(value)) {
    state.disableScroll.push(value);
  }
}

function removeDisableScroll(state, value) {
  if (state.disableScroll.includes(value)) {
    state.disableScroll = state.disableScroll.filter(item => item !== value);
  }
}

function addActiveModal(state, value) {
  if (!state.activeModals.includes(value)) {
    state.activeModals.push(value);
  }
}

function removeActiveModal(state, value) {
  if (state.activeModals.includes(value)) {
    state.activeModals = state.activeModals.filter(item => item !== value);
  }
}

function addUsedModals(state, value) {
  if (!state.usedModals.includes(value)) {
    state.usedModals.push(value);
  }
}

function clearUsedModals(state) {
  state.usedModals = [];
}

function setCurrentModal(state, value) {
  state.currentModal = value;
}

function removeAllDisableScroll(state) {
  state.disableScroll = [];
}

function freemiumBannerTopMargin(state, value) {
  state.freemiumBannerTopMargin = value;
}

export default {
  toggleMobileHeader,
  toggleDesktopHeader,
  setGlobalModal,
  setCookiesModal,
  setPropositionViewModalId,
  pageModalActivePage,
  pageModalZIndex,
  toggleDisableScroll,
  addDisableScroll,
  removeDisableScroll,
  removeAllDisableScroll,
  freemiumBannerTopMargin,
  addActiveModal,
  removeActiveModal,
  setCurrentModal,
  addUsedModals,
  clearUsedModals
};
