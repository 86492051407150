<template>
  <div
    class="wrapper text-unselectable"
    v-bind="$attrs"
    :class="[
      { 'is-active': isActive },
      { rounded: isRounded },
      { small: isSmall },
      { 'extra-small': isExtraSmall }
    ]"
    v-on="$listeners"
    @change="$emit('change', !isActive)"
  >
    <div
      class="wrapper-content"
      :class="{ 'wrapper-content-small': isSmall || isExtraSmall }"
    >
      <div v-if="icon" class="icon-wrapper">
        <AImage :url="icon" :classes="['icon-choice']" />
      </div>
      <div class="text" :class="{ 'text-small': isSmall || isExtraSmall }">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import AImage from './a-image';
export default {
  name: 'APill',
  components: { AImage },
  props: {
    isActive: {
      type: Boolean,
      required: true
    },

    icon: { type: String, default: null },

    isRounded: {
      type: Boolean,
      default: false
    },

    isSmall: {
      type: Boolean,
      default: false
    },

    isExtraSmall: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d6dae9;
  background-color: $form-background-light;
  cursor: pointer;
}

.rounded {
  border-radius: 25px;
}

.wrapper-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 20px;
}

.wrapper-content-small {
  padding: 0 10px;
}

.is-active {
  border: 1px solid $transfer-blue;
  background-color: #e7f3ff;
}

.text {
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.icon-choice {
  height: 18px;
  width: 18px;
  background-position: center;
  background-size: contain;
}

.icon-wrapper {
  margin-right: 7px;
}

.text-small {
  font-size: 9px;
}

.small {
  height: 26px;
}

.extra-small {
  height: 20px;
}
</style>
