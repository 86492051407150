import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  tokenExists: null,
  lastTokenCheck: null,
  hasCheckedForToken: false,
  agreementChecked: false,
  session: {},
  hideKlarnaForm: false,
  klarnaToken: '',
  klarnaAuthResponse: {},
  buttonsEnabled: true,
  all: [],
  selectedProduct: null,
  isCreatingPayment: false,
  errorMessage: '',
  lastProductFetch: null,
  selectedAdditionalProducts: []
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
