<template>
  <span :class="['loading loading--spinner', { overlay: withOverlay }]">
    <svg
      class="spinner"
      :style="{ height: `${size}px`, width: `${size}px` }"
      viewBox="0 0 50 50"
    >
      <circle
        class="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke="#93bfec"
        stroke-width="5"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    size: {
      type: Number,
      default: 50
    },
    withOverlay: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 99;

  &.overlay {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.spinner {
  animation: rotate 2s linear infinite;

  & .path {
    animation: dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150; // ie11 fallback
    stroke-dashoffset: -35; // ie11 fallback
    stroke-linecap: round;
    stroke: #93bfec;
  }
}
</style>
