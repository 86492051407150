<template>
  <div class="toggle-wrapper">
    <div
      :class="['indicator', { 'offset-right': currentResidenceIndex === 1 }]"
    ></div>
    <div
      :class="['toggle-option', { selected: currentResidenceIndex === 0 }]"
      @click="() => setCurrentResidence({ index: 0 })"
    >
      {{ truncateAddress(residence1) }}
    </div>
    <div
      :class="[
        'darker',
        'toggle-option',
        { selected: currentResidenceIndex === 1 }
      ]"
      @click="() => setCurrentResidence({ index: 1 })"
    >
      {{ truncateAddress(residence2) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResidenceSwitcher',

  props: {
    residence1: {
      type: String,
      required: true
    },
    residence2: {
      type: String,
      required: true
    },
    currentResidenceIndex: {
      type: Number,
      required: true
    },
    setCurrentResidence: {
      type: Function,
      required: true
    }
  },

  methods: {
    truncateAddress(address) {
      if (!address || address.length <= 16) {
        return address;
      } else {
        return `${address.substr(0, 16)}...`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f1f2f6;
  width: 100%;
  border-radius: $small-box-radius;

  -webkit-tap-highlight-color: transparent;
  & > * {
    -webkit-tap-highlight-color: transparent;
  }
}

.indicator {
  position: absolute;
  top: 5%;
  left: 1%;
  width: 50%;
  height: 90%;
  border-radius: $small-box-radius;
  background-color: #fff;
  z-index: 0;
  transition: all 0.2s ease-in-out;
}

.offset-right {
  left: 49%;
}

.toggle-option {
  cursor: pointer;
  font-weight: 600;
  padding: 10px 5px;
  white-space: nowrap;
  font-size: 0.8rem;
  width: 50%;
  max-width: 50%;
  color: $text-secondary;

  display: flex;
  justify-content: center;
  z-index: 1;
}
</style>
