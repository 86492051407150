var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":_vm.animate && _vm.message.senderId !== -1 ? 'pop-in' : null,"appear":""}},[_c('article',{staticClass:"message-wrapper",class:{ 'current-user': _vm.isCurrentUser(_vm.message.senderId) }},[(
        _vm.message.senderId === -1 &&
        _vm.messageType === 'MATCH_CLOSED' &&
        _vm.usedInMatches
      )?_c('div',{staticClass:"system-notification-wrapper"},[(_vm.parsedMessage.closedByUser === true)?[_c('h3',[_vm._v("Det här matchningen är avslutad")]),_c('p',[_vm._v(" Matchningen har avslutats och chatten är nu stängd för nya meddelanden. Om matchningen återigen blir intressant för dig kan du ändra din intressestatus igen direkt här i chatten. ")])]:[_c('h3',[_vm._v("Det här matchningen är avslutad")]),_c('p',[_vm._v(" Tyvärr nådde matchningen inte hela vägen den här gången och chatten är nu stängd för nya meddelanden. ")])],_c('span',{staticClass:"chat-notification-date"},[_vm._v(_vm._s(_vm.messageCreatedText(_vm.message)))])],2):(_vm.message.senderId === -1 && _vm.messageType !== 'MATCH_CLOSED')?_c('div',{staticClass:"chat-notification-wrapper"},[_c('div',{staticClass:"notification-label"},[_vm._v("Status")]),_c('div',{staticClass:"chat-notification"},[_c('div',{staticClass:"dot system-message-dot",class:( _obj = {}, _obj[_vm.systemMessageInterestColor(_vm.message.content)] = _vm.systemMessageInterestColor(_vm.message.content), _obj )}),_vm._v(" "+_vm._s(_vm.getNotificationMessage(_vm.message.content))+" ")]),_c('p',{staticClass:"chat-explanation"},[_vm._v(" "+_vm._s(_vm.messageType === 'USER_LEFT' || _vm.messageType === 'MARKED_NO' ? _vm.$t('chat_notification_left_chat_explanation') : '')+" ")]),_c('span',{staticClass:"chat-notification-date"},[_vm._v(_vm._s(_vm.messageCreatedText(_vm.message)))])]):_vm._e(),(_vm.message.senderId !== -1)?_c('div',{staticClass:"message-image-wrapper"},[(
          !_vm.isCurrentUser(_vm.message.senderId) && _vm.isInterestDotVisible(_vm.message)
        )?_c('div',{staticClass:"dot",class:( _obj$1 = {}, _obj$1[_vm.interestColor(_vm.message)] = _vm.interestColor(_vm.message), _obj$1 )}):_vm._e(),_c('div',{staticClass:"message-image"},[(!_vm.isCurrentUser(_vm.message.senderId))?_c(_vm.owner(_vm.message).inactivatedAt ? 'div' : 'router-link',{tag:"router-link",staticClass:"swap-party-link",attrs:{"to":((_vm.$t('path_proposition', _vm.$routeLocale)) + "/" + (_vm.owner(_vm.message).propositionId)),"target":!_vm.isMobile && '_blank'},nativeOn:{"click":function($event){_vm.handleUserClicked(_vm.owner(_vm.message).propositionId)},"contextmenu":function($event){_vm.handlePropositionCardRightClick(_vm.owner(_vm.message).propositionId)}}},[_c('img',{attrs:{"src":_vm.owner(_vm.message).residenceImage}})]):_vm._e()],1)]):_vm._e(),(_vm.message.senderId !== -1)?_c('div',{staticClass:"message-content"},[_c('div',{staticClass:"message-bubble",class:{
          'current-user': _vm.isCurrentUser(_vm.message.senderId),
          'third-party': _vm.isThirdParty(_vm.message.senderId)
        }},[(_vm.owner(_vm.message))?_c('h2',{staticClass:"bubble-title"},[_c('span',[_vm._v(_vm._s(_vm.owner(_vm.message).displayName))]),_vm._v(", "),_c('span',[_vm._v(_vm._s(_vm.owner(_vm.message).address))])]):_vm._e(),_c('p',{staticClass:"message-text",domProps:{"innerHTML":_vm._s(_vm.message.content)}})]),_c('div',{staticClass:"date",class:{ 'current-user': _vm.isCurrentUser(_vm.message.senderId) }},[_c('span',{staticClass:"date-text"},[_vm._v(_vm._s(_vm.messageCreatedText(_vm.message)))]),(_vm.message.readBy)?[_c('span',{staticClass:"read-wrapper"},_vm._l((_vm.message.readBy.filter(
                function (r) { return !_vm.isCurrentUser(r) && r !== _vm.message.senderId; }
              )),function(participantId){return _c('span',{key:participantId,staticClass:"read-text"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"14","height":"14","viewBox":"-20 -20 160 160"}},[_c('path',{attrs:{"stroke":"#66bb6a","stroke-width":"32","stroke-linecap":"square","d":"M112.5 11.5L42 86.4 12 57","fill":"none"}})]),_vm._v(" "+_vm._s(!_vm.owners[participantId] ? participantId.toString() : _vm.owners[participantId].displayName || _vm.owners[participantId].address)+" ")])}),0)]:_vm._e()],2)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }