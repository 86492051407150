<template>
  <BaseButton
    type="button"
    :class="[{ rounded: isRounded, 'full-width': isFullWidth }]"
    @click="$emit('click')"
    ><slot />
  </BaseButton>
</template>

<script>
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseButton
  },
  props: {
    isRounded: {
      type: Boolean,
      default: true,
      required: false
    },
    isFullWidth: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};
</script>
<style scoped lang="scss">
.cta-button {
  &:active {
    background-color: #2c3e50;
  }
}
</style>
