<template>
  <div v-if="showInfoBanner && limitForABtestGroup" class="info-banner">
    <component
      :is="messageElement"
      :href="infoBannerUrl"
      class="info-banner-content"
    >
      <BaseIcon
        icon-file="icons/lightbulb-blue"
        :width="isMobile ? 32 : 48"
        :height="isMobile ? 32 : 48"
        class="icon"
      />
      <div v-html="infoBannerMessage"></div>
    </component>
    <div class="close-button" @click="handleCloseClick">
      <BaseIcon icon="close-black" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getCookie, setCookie } from '@/utils/cookies';
import { mapGetters } from 'vuex';

export default {
  name: 'InfoBanner',

  data() {
    return {
      showInfoBanner: false,
      infoBannerMessage: null,
      infoBannerUrl: null,
      publishedAt: null,
      ABtestFeatureKey: null
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    }),

    messageElement() {
      return this.infoBannerUrl ? 'a' : 'div';
    },

    limitForABtestGroup() {
      return (
        this.ABtestFeatureKey &&
        this.$growthbook.isFeatureFlagEnabled(`${this.ABtestFeatureKey}`)
      );
    }
  },

  created() {
    this.getInfoBannerNotification();
  },

  methods: {
    async getInfoBannerNotification() {
      try {
        const response = await axios.get(
          `https://cms.lagenhetsbyte.se/banner-notification?_locale=${this.$routeLocale}`
        );
        this.infoBannerMessage = response.data.BannerMessage;
        this.infoBannerUrl = response.data.URL;
        this.publishedAt = response.data.published_at;
        this.ABtestFeatureKey = response.data.ABtestFeatureKey;
        this.infoBannerVisibility();
      } catch (error) {
        this.error = error;
      }
    },
    infoBannerVisibility() {
      const hideInfoBanner = getCookie('hide-info-banner-' + this.publishedAt);
      if (hideInfoBanner) {
        this.showInfoBanner = false;
      } else {
        this.showInfoBanner = true;
      }
    },
    handleCloseClick() {
      setCookie('hide-info-banner-' + this.publishedAt, true, 180);
      this.showInfoBanner = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.info-banner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 54px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.icon {
  display: grid;
  place-items: center;
  margin-right: 15px;
  flex-shrink: 0;
}

.info-banner-content {
  display: flex;
  align-items: center;
  padding: 15px;
  padding-right: 0;
  font-size: 14px;
  line-height: 1.5;
  color: $text-primary;
}

.info-banner-content ::v-deep strong,
.info-banner-content ::v-deep a {
  font-weight: 600;
}

.info-banner-content ::v-deep p {
  margin: 0;
}

.close-button {
  display: grid;
  place-items: center;
  cursor: pointer;
}
</style>
