<template>
  <div class="thank-you-page">
    <div class="illustration" />
    <h1 class="title">
      {{ $t('confirmation_klarna_token_title') }}
    </h1>
    <p class="text">
      {{ $t('confirmation_klarna_token_text') }}
    </p>
    <router-link :to="$routes.SWAP_LIST">
      <primary-button>
        {{ $t('confirmation_klarna_token_confirm') }}
      </primary-button>
    </router-link>
  </div>
</template>

<script>
import PrimaryButton from '../../components/Buttons/Primary';

export default {
  name: 'PaymentDone',

  components: {
    PrimaryButton
  },
  head: {
    title() {
      return {
        inner: this.$t('meta_title_update_klarna')
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.thank-you-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding-top: 180px;

  margin: 0 auto;
  @media ($mobile) {
    padding: 20px;
  }
}

.illustration {
  height: 150px;
  width: 150px;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/baloons.svg');
  margin-bottom: 20px;

  @media ($mobile) {
    height: 100px;
    width: 100px;
  }
}

.title {
  text-align: center;
}

.text {
  font-weight: 600;
  color: $text-secondary;
  text-align: center;
  line-height: 150%;
  margin: 0;

  &:last-of-type {
    margin-bottom: 40px;
  }
}
</style>
