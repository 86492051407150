<template>
  <div class="proposition-info">
    <img
      class="house-illustration"
      src="../../assets/img/skyline-3-small.png"
    />
    <h2 class="header">
      <template v-if="infoType === INFO_TYPES.NO_ACTIVE">
        {{ $t('proposition_info_no_active') }}
      </template>
      <template v-else-if="infoType === INFO_TYPES.TRIAL_EXPIRED">
        {{
          $t('proposition_info_test_period_expired', {
            outdatedAddress: outdatedAddress
          })
        }}
      </template>
      <template v-else-if="infoType === INFO_TYPES.NO_PROPOSITIONS">
        {{ $t('proposition_info_no_active') }}
      </template>
    </h2>

    <p class="bread">
      <template v-if="infoType === INFO_TYPES.NO_ACTIVE">
        {{ $t('proposition_info_activate') }}
      </template>
      <template v-else-if="infoType === INFO_TYPES.TRIAL_EXPIRED">
        {{ $t('proposition_info_upgrade') }}
      </template>
      <template v-else-if="infoType === INFO_TYPES.NO_PROPOSITIONS">
        {{ $t('proposition_info_create_new') }}
      </template>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PropositionInfo',

  props: {
    infoType: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      INFO_TYPES: {
        NO_ACTIVE: 0,
        TRIAL_EXPIRED: 1,
        NO_PROPOSITIONS: 2
      }
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      displayedOutdatedTrial: 'trial/displayedOutdatedTrial'
    }),

    buttonText: function () {
      const buttonTexts = {
        0: this.$t('side_menu_add_ad'),
        1: this.$t('trial_banner_action')
      };

      return buttonTexts[this.infoType];
    },

    outdatedAddress: function () {
      if (
        !this.displayedOutdatedTrial ||
        !this.displayedOutdatedTrial.residences
      ) {
        return;
      }
      const [residence1, residence2] = this.displayedOutdatedTrial.residences;
      const address = `${residence1.streetAddress}${residence2 ? ', ' : ''}${
        residence2 ? residence2.streetAddress : ''
      }`;

      window.Logger.log(this.displayedOutdatedTrial);

      return address;
    }
  }
};
</script>

<style lang="scss" scoped>
.proposition-info {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;
  width: 100%;
}

.header {
  margin: 10px 0 5px 0;
  text-align: center;
}

.bread {
  text-align: center;
}

.house-illustration {
  opacity: 0.4;
  width: 300px;
}
</style>
