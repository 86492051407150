import services from './services';

import { router } from '../../../main';
import checkAppReady from '../../checkAppReady';
import store from '../../store';
import i18n, { routeLocale } from '@/i18n';
import { isFeatureDisabled } from '@/plugins/country/module';

async function setPrerenderedProposition(context, prerenderedState) {
  context.commit('setCurrentProposition', prerenderedState);
  context.commit('noNeedForExtrasLoading');
}

async function setCurrentProposition(
  context,
  { propositionId, residenceIndex = '1' }
) {
  context.commit('setIsLoading');
  context.dispatch('clearCurrentProposition');
  const isMobile = window.innerWidth < 768;

  let actualResidenceIndex = residenceIndex === '1' ? 0 : 1;

  try {
    const fetchedProposition = await services.fetchProposition({
      propositionId,
      isMobile,
      isAuthenticated: context.rootState.app.isAuthenticated
    });

    // Hide inactive propositions that aren't the current users
    if (!fetchedProposition.active && !fetchedProposition.isOwner) {
      router.replace(i18n.t('path_not_found', routeLocale));
      return;
    } else if (!fetchedProposition.active && fetchedProposition.isOwner) {
      fetchedProposition.showActivateUsersPropositionBanner = true;
    }

    if (actualResidenceIndex === 1 && !fetchedProposition.residences[1]) {
      actualResidenceIndex = 0;
    }

    context.dispatch('fetchPropositionUser', {
      userId: fetchedProposition.userId
    });

    fetchedProposition.isVisited = false;

    context.commit('setCurrentProposition', {
      proposition: fetchedProposition,
      residenceIndex: actualResidenceIndex
    });

    await checkAppReady();

    const isAuthenticated = store.getters['app/isAuthenticated'];
    let visited = false;
    if (isAuthenticated) {
      try {
        visited = await services.fetchVisited(propositionId);
      } catch (err) {
        console.error(err);
      }
    }

    context.commit('setCurrentVisited', { visited });

    try {
      if (isAuthenticated) {
        context.dispatch('fetchInterestData', { propositionId });
        context.dispatch('fetchFavouriteData', { propositionId });
      } else {
        context.commit('stopInterestLoading');
      }
    } catch (err) {
      console.error(err);
    }
  } catch (e) {
    router.replace(i18n.t('path_not_found', routeLocale));
    throw e;
  }
}

async function fetchInterestData(context, { propositionId }) {
  try {
    const userPropositionId =
      context.rootState.myPropositions.primaryProposition.propositionId;
    const interested = await services.fetchInterest(
      userPropositionId,
      propositionId
    );
    const isMatch = await services.fetchIsMatch(
      userPropositionId,
      propositionId
    );

    context.commit('mapInterestDataToProposition', {
      interestData: {
        interested,
        isMatch
      },
      propositionId
    });
  } catch (e) {
    context.commit('stopInterestLoading');
  }
}

async function fetchFavouriteData(context, { propositionId }) {
  try {
    const favouriteData = await services.fetchFavourite(propositionId);
    context.commit('mapFavouriteDataToProposition', {
      favouriteData,
      propositionId
    });
  } catch (e) {
    console.error(e);
  }
}

function setCurrentResidence(context, { index }) {
  context.commit('setCurrentResidence', {
    index
  });
}

async function fetchPropositionUser(context, { userId }) {
  const user = await services.fetchUser(userId);
  context.commit('setPropositionUser', {
    user
  });
}

async function getSponsoredPropositions(context, propositionId) {
  if (isFeatureDisabled('EXTRA_VISIBILITY')) return;

  await checkAppReady();
  const userPropositionId =
    context.rootState.myPropositions.primaryProposition.propositionId;

  const pid = propositionId || userPropositionId;
  if (!pid) {
    return;
  }

  const sponsored = await services.fetchSponsoredPropositions(pid);
  context.commit('setSponsoredPropositions', { sponsored });
}

async function getPropositionsSiteCount(context) {
  const propositionsSiteCount = await services.fetchPropositionsSiteCount();

  context.commit(
    'setPropositionsSiteCount',
    propositionsSiteCount.Object.NumAds
  );
}

// *** Interest *** //

function setSwapInterest(context, { targetPropositionId, interested }) {
  context.commit('setSwapInterest', {
    targetPropositionId,
    interested
  });
}

function clearCurrentProposition(context) {
  context.commit('clearCurrentProposition');
}

function favouriteSocketUpdate(context, { targetPropositionId, favourite }) {
  const currentPropositionId = context.state.currentProposition.propositionId;

  if (targetPropositionId === currentPropositionId) {
    window.Logger.log('Updating current proposition favourite');
    context.commit('setCurrentFavourite', {
      favourite
    });
  }
}

async function updateCurrentVisited(context, { propositionId, visited }) {
  context.commit('setCurrentVisited', {
    visited
  });
  await services.updateVisited({
    propositionId,
    visited
  });
}

export default {
  setPrerenderedProposition,
  setCurrentProposition,
  getSponsoredPropositions,
  getPropositionsSiteCount,
  setCurrentResidence,
  setSwapInterest,
  clearCurrentProposition,
  favouriteSocketUpdate,
  updateCurrentVisited,
  fetchInterestData,
  fetchFavouriteData,
  fetchPropositionUser
};
