<template>
  <transition-group tag="div" name="shuffle" class="selected-suggestions-box">
    <div
      v-for="selected in selectedSuggestions"
      :key="selected"
      class="selected-suggestion"
      @click="deselectSuggestion({ suggestion: selected })"
    >
      <span>{{ selected }}</span>
      <div class="deselect-button"></div>
    </div>
  </transition-group>
</template>

<script>
export default {
  name: 'MapSelectedSuggestions',

  props: {
    selectedSuggestions: {
      type: Array,
      default: () => []
    },
    deselectSuggestion: {
      type: Function,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.selected-suggestions-box {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 10px 0;
}

.selected-suggestion {
  height: 32px;
  margin: 2px 2px;
  padding: 0 7px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  border: 1px solid #000;
  border-radius: 3px;
}

.deselect-button {
  height: 10px;
  width: 10px;

  transform: rotate(45deg);
  background-position: center;
  background-size: contain;
  margin-left: 7px;
  margin-bottom: -3px;
}
</style>
