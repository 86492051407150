<template>
  <div ref="progressBar" class="progress-bar-content">
    <span v-if="showIcon">
      <BaseIcon icon-file="flash" class="flash" />
    </span>
    <div class="progress-bar-bg">
      <div
        class="progress-start"
        :style="{
          width: 0
        }"
        :class="percentage === 0 ? 'right-border-radius' : ''"
      />

      <div
        ref="progressBarProgress"
        class="progress left-border-radius"
        :style="{
          width: percentage + '%'
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: 0
    },
    showIcon: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      shouldAnimate: true,
      previousProgress: 0,
      targetProgress: 0,
      steps: []
    };
  }
};
</script>

<style scoped lang="scss">
.left-border-radius {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.right-border-radius {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.progress-bar-content {
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 20px;
  height: 20px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  gap: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

  .progress-bar-bg {
    position: relative;
    display: flex;
    height: 4px;
    width: 100%;
    border-radius: 4px;
    background: #e8e8e8;
  }

  .progress-start {
    position: relative;
    height: 4px;
    width: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #409fff;
  }

  .progress {
    position: relative;
    height: 4px;
    width: 0%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #409fff;
    transition: width 0.2s linear;
  }

  .flash {
    height: 13px;
    width: 7px;
  }

  @keyframes progress {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
}
</style>
