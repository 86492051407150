<template>
  <div class="my-propositions">
    <SideMenu :is-loading="!ready || loadingPropositions" />

    <div v-if="!ready || loadingPropositions" class="propositions-container">
      <h2 class="first-heading">{{ $t('my_propositions_loading') }}</h2>
      <MyPropositionsCardLoader />
    </div>

    <div v-else class="propositions-container">
      <div v-if="shouldShowOnboardingBanner" class="onboarding-wrapper">
        <OnboardingBanner />
      </div>

      <VerifyBanner
        v-if="$country.isFeatureEnabled('BANKID') && user && !user.verified"
        class="verify-wrapper"
      />

      <h2 v-if="pausedPropositions.length > 0">
        {{ $t('my_propositions_paused') }}
      </h2>

      <div v-if="pausedPropositions.length > 0" class="my-propositions-card">
        <MyPropositionsCard
          v-for="proposition in pausedPropositions"
          :key="proposition.propositionId"
          :proposition="proposition"
        />
      </div>

      <PropositionInfo
        v-if="
          (!activePropositions.length > 0 &&
            (activePropositions.length === 0 ||
              displayedOutdatedTrial !== null)) ||
          propositions.length === 0
        "
        :info-type="propositionInfoType"
      />

      <h2 v-if="activePropositions.length > 0" class="first-heading">
        {{ $t('my_propositions_active') }}
      </h2>

      <div class="my-propositions-card">
        <MyPropositionsCard
          v-for="proposition in activePropositions"
          :key="proposition.propositionId"
          :proposition="proposition"
        />
      </div>

      <h2
        v-if="inactivePropositions.length > 0"
        :class="[{ 'inactive-header': true }]"
      >
        {{ $t('my_propositions_inactive') }}
      </h2>

      <transition-group tag="div" name="my-propositions-card" mode="in-out">
        <MyPropositionsCard
          v-for="proposition in inactivePropositions"
          :key="proposition.propositionId"
          :proposition="proposition"
        />
      </transition-group>

      <div class="add-proposition-button-container">
        <BaseButton
          :link="$t('path_add_proposition', $routeLocale)"
          :is-external="true"
          icon="focus-add"
          class="secondary smaller"
          >{{ $t('my_propositions_add_new_prop') }}</BaseButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import MyPropositionsCard from '../../components/PropositionCards/MyPropositionsCard';
import MyPropositionsCardLoader from '../../components/PropositionCards/Loaders/MyPropositionsCard';
import SideMenu from '../../components/MyPropositions/SideMenu';
import PropositionInfo from '../../components/MyPropositions/PropositionInfo';
import OnboardingBanner from '../../components/Onboarding/OnboardingBanner';
import VerifyBanner from '../../components/Onboarding/VerifyBanner';

export default {
  name: 'MyPropositions',

  components: {
    MyPropositionsCard,
    SideMenu,
    PropositionInfo,
    MyPropositionsCardLoader,
    OnboardingBanner,
    VerifyBanner
  },

  head: {
    title() {
      return {
        inner: this.$t('meta_title_my_propositions')
      };
    }
  },

  computed: {
    ...mapGetters({
      ready: 'app/ready',
      user: 'app/user',
      isMobile: 'screenSize/isMobile',
      loadingPropositions: 'myPropositions/loadingPropositions',
      fetchingPropositions: 'myPropositions/fetchingPropositions',
      propositions: 'myPropositions/propositions',
      primaryProposition: 'myPropositions/primaryProposition',
      displayedOutdatedTrial: 'trial/displayedOutdatedTrial',
      shouldShowOnboardingBanner: 'myPropositions/onboardingState'
    }),

    propositionInfoType() {
      if (this.displayedOutdatedTrial) {
        return 1;
      } else if (this.propositions.length === 0) {
        return 2;
      } else {
        return 0;
      }
    },

    hasPayingProposition() {
      return this.propositions.some(
        p => !p.inFreeArea && !p.isTrial && p.active
      );
    },

    pausedPropositions() {
      return this.propositions.filter(
        proposition => proposition.pausedAt !== null
      );
    },

    inactivePropositions() {
      return this.propositions.filter(
        proposition => !proposition.active && proposition.pausedAt === null
      );
    },

    activePropositions() {
      return this.propositions
        .filter(proposition => proposition.active)
        .sort((a, b) => {
          return a.isPrimary > b.isPrimary ? -1 : 1;
        });
    }
  },

  created() {
    if (!this.fetchingPropositions && this.ready) this.fetchPropositions();

    if (this.$country.isFeatureEnabled('PAYMENT')) {
      this.getSetHasPaymentToken();
    }
  },

  mounted() {
    this.showMobileTabBar();

    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });
  },

  methods: {
    ...mapActions({
      fetchPropositions: 'myPropositions/fetchPropositions',
      showMobileTabBar: 'menu/showMobileTabBar',
      getSetHasPaymentToken: 'payment/getSetHasPaymentToken'
    })
  }
};
</script>

<style lang="scss" scoped>
.my-propositions {
  display: flex;
  justify-content: space-around;

  @media ($mobile) {
    flex-direction: column;
  }
}

.propositions-container {
  padding-left: 60px;
  width: calc(75% - 30px); // IE

  @media ($mobile) {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;

    @media ($mobile) {
      font-size: 18px;
    }
  }
}

.first-heading {
  margin-top: 0;

  @media ($mobile) {
    margin-bottom: -10px;
    margin-top: 20px;
  }
}

.inactive-header {
  margin-top: 60px;

  @media ($mobile) {
    margin-top: 40px;
    margin-bottom: -10px;
  }
}

.my-propositions-card {
  &-move {
    transition: all 600ms ease-in-out;
  }
  &-enter-active {
    transition: all 300ms ease-out;
  }

  &-leave-active {
    opacity: 0;
    position: fixed;
    z-index: 0;
    max-width: 100%;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
  &-enter {
    transform: scale(0.9);
  }
}

.onboarding-wrapper {
  margin-bottom: 20px;
}

.verify-wrapper {
  margin-bottom: 40px;
}

.add-proposition-button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 60px;
}
</style>
