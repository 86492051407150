<template>
  <div class="wrapper">
    <div class="inner-wrapper">
      <div class="progress-wrapper">
        <BaseIcon
          icon="verified"
          :width="55"
          :height="55"
          class="verified-badge"
        />
      </div>

      <div class="text-content">
        <div class="title">Nu kan du verifiera dig med BankID</div>
        <div class="text">
          Detta innebär större säkerhet för dig och alla andra bostadsbytare.
        </div>
      </div>
    </div>

    <router-link :to="$routes.EDIT_PROFILE" class="button-wrapper">
      <primary-button
        :full-width="isMobile"
        :rounded="true"
        :invert-colors="true"
      >
        Verifiera dig här
      </primary-button>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PrimaryButton from '../Buttons/Primary';

export default {
  name: 'VerifyBanner',

  components: {
    PrimaryButton
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  border-radius: 24px;
  background-color: #2ec973;
  color: white;
  padding: 15px 30px;

  @media ($mobile) {
    flex-direction: column;
    padding: 20px;
  }
}

.inner-wrapper {
  display: flex;
  align-items: center;

  @media ($desktop) {
    max-width: 70%;
  }

  @media ($mobile) {
    align-items: flex-start;
    width: 100%;
  }
}

.progress-wrapper {
  margin-right: 24px;
  position: relative;
  min-width: 80px;
  height: 80px;
  border-radius: 80px;
  background-color: white;
  display: grid;
  place-items: center;

  @media ($mobile) {
    margin-right: 16px;
  }
}

.text-content {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 6px;

  @media ($mobile) {
    font-size: 1rem;
    margin-bottom: 12px;
  }
}

.text {
  font-weight: 600;
  line-height: 130%;
}

.button-wrapper {
  display: flex;
  min-width: 165px;

  @media ($desktop) {
    margin-left: auto;
  }

  @media ($mobile) {
    margin-top: 12px;
    width: 100%;
  }

  button {
    margin-left: auto;
  }
}
</style>
