<template>
  <div class="swap-header">
    <div v-if="showFilters" class="swap-list-info">
      <div class="header-wrapper">
        <div class="header">
          <div v-if="isSwapList">
            {{
              isUserList
                ? $t('swaplist_header_your_swap_suggestions')
                : address
                ? `${address} ${$t('swaplist_header_swap_suggestions')}`
                : $t('swaplist_header_loading')
            }}
          </div>
          <div v-else class="results-and-toggle">
            <div class="results-info">
              <div class="results-title">{{ pageTitle }}</div>
            </div>
          </div>
        </div>

        <filter-container
          v-if="
            showFilters && swapFilterType !== SWAP_FILTER_TYPES.UNANSWERED_NEW
          "
          :is-swap-list="isSwapList"
        />
      </div>

      <div
        v-if="showPropositionSwitcher & isSwapList"
        class="desktop-proposition-switcher-wrapper"
      >
        <proposition-switcher v-if="isUserList" />
      </div>

      <div class="center-section-wrapper">
        <div v-if="loading" class="swap-list-count">
          {{ $t('swaplist_header_loading_swaps') }}
        </div>

        <div
          v-else-if="swapFilterType === SWAP_FILTER_TYPES.ALL"
          class="swap-list-count"
        >
          <span v-if="isUserList">{{ $t('swaplist_header_you_have') }} </span
          ><span class="bold">{{ preFilterCountTotal }}</span>
          {{ $t('swaplist_header_possible_swaps') }}
          <span class="bold">{{ preFilterCountUnmarked }}</span>
          {{ $t('swaplist_header_unanswered') }}
          <div v-if="filterCount > 0" class="swap-list-count small">
            {{
              $t('swaplist_header_showing_after_filtering', {
                total: postFilterCountTotal
              })
            }}
          </div>
        </div>

        <div
          v-else-if="swapFilterType === SWAP_FILTER_TYPES.FULL_INTEREST"
          class="swap-list-count"
        >
          {{
            $t('swaplist_header_showing_full_interest', {
              total: postFilterCountTotal
            })
          }}
        </div>

        <div
          v-else-if="swapFilterType === SWAP_FILTER_TYPES.NEW"
          class="swap-list-count"
        >
          {{
            $t('swaplist_header_showing_last_24', {
              total: postFilterCountTotal
            })
          }}
        </div>

        <div
          v-else-if="
            swapFilterType === SWAP_FILTER_TYPES.UNANSWERED &&
            distributedSwapsEnabled &&
            newSwapsCount > 0
          "
          class="swap-list-count"
        >
          <span v-if="newSwapsCount === 1">
            {{ $t('swaplist_header_new_count_single_header') }}</span
          >
          <span v-if="newSwapsCount > 1">
            {{
              $t('swaplist_header_new_count_header', {
                new: newSwapsCount
              })
            }}
          </span>
        </div>

        <div
          v-else-if="swapFilterType === SWAP_FILTER_TYPES.UNANSWERED"
          class="swap-list-count"
        >
          {{ $t('swaplist_header_showing') }}
          {{ postFilterCountTotal }}
          {{ $t('swaplist_header_unanswered_suggestions') }}
        </div>

        <div
          v-else-if="swapFilterType === SWAP_FILTER_TYPES.ONLY_FULL_INTEREST"
          class="swap-list-count"
        >
          <template>
            {{
              $t('swaplist_header_showing_full_interest_only', {
                total: postFilterCountTotal
              })
            }}
          </template>
        </div>

        <div
          v-else-if="swapFilterType === SWAP_FILTER_TYPES.LAST_PART"
          class="swap-list-count"
        >
          {{
            $t('swaplist_header_showing_last_part', {
              total: postFilterCountTotal
            })
          }}
        </div>

        <div
          v-else-if="swapFilterType === SWAP_FILTER_TYPES.INTEREST_MARKED"
          class="swap-list-count"
        >
          {{
            $t('swaplist_header_showing_interest_marked', {
              total: postFilterCountTotal
            })
          }}
        </div>

        <div
          v-else-if="swapFilterType === SWAP_FILTER_TYPES.SWAPLIST_MARKED"
          class="swap-list-count"
        >
          {{
            $t('swaplist_header_showing_own_interest_marked', {
              total: postFilterCountTotal
            })
          }}
        </div>

        <div
          v-else-if="swapFilterType === SWAP_FILTER_TYPES.UNANSWERED_NEW"
          class="swap-list-count"
        >
          {{
            $t('swaplist_header_showing_unanswered', {
              total: postFilterCountTotal
            })
          }}
        </div>

        <div
          v-else-if="swapFilterType === SWAP_FILTER_TYPES.MARKED"
          class="swap-list-count"
        >
          {{
            $t('swaplist_header_showing_marked', {
              total: postFilterCountTotal
            })
          }}
          <!-- <toggle-button
            v-if="swapFilterType !== SWAP_FILTER_TYPES.UNANSWERED_NEW"
            :is-on="false"
            :on-toggle="() => {}"
            :title="'Endast intresse-markerade'"
          /> -->
        </div>

        <div
          v-else-if="swapFilterType === SWAP_FILTER_TYPES.FAVOURITES"
          class="swap-list-count"
        >
          {{
            $t('swaplist_header_showing_favourites', {
              total: postFilterCountTotal
            })
          }}
        </div>

        <toggle-button
          :is-on="swapItemMode === SWAP_ITEM_TYPES.EXCEL"
          :on-toggle="
            swapItemMode === SWAP_ITEM_TYPES.CARDS
              ? () => toggleSwapItemMode({ mode: SWAP_ITEM_TYPES.EXCEL })
              : () => toggleSwapItemMode({ mode: SWAP_ITEM_TYPES.CARDS })
          "
          :title="$t('swaplist_header_show_as_list')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SWAP_FILTER_TYPES, SWAP_ITEM_TYPES } from '@/store/modules/swapList';

import PropositionSwitcher from './PropositionSwitcher';
import FilterContainer from './Filter/FilterContainer';
import ToggleButton from '../Toggle/ToggleButton';

export default {
  name: 'SwapHeader',

  components: {
    PropositionSwitcher,
    FilterContainer,
    ToggleButton
  },

  props: {
    showFilters: {
      type: Boolean,
      required: true
    },
    isSwapList: {
      type: Boolean,
      default: true
    },
    pageTitle: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      SWAP_FILTER_TYPES,
      SWAP_ITEM_TYPES
    };
  },

  computed: {
    ...mapGetters({
      user: 'app/user',
      pendingSwapCount: 'swapList/pendingSwapCount',
      propositions: 'myPropositions/propositions',
      isUserList: 'swapList/isUserList',
      swapFilterType: 'swapList/swapFilterType',
      address: 'swapList/address',
      preFilterCountTotal: 'swapList/preFilterCountTotal',
      preFilterCountUnmarked: 'swapList/preFilterCountUnmarked',
      postFilterCountTotal: 'swapList/postFilterCountTotal',
      newSwapsCount: 'swapList/newSwapsCount',
      postFilterCountUnmarked: 'swapList/postFilterCountUnmarked',
      filterCount: 'swapList/filterCount',
      loading: 'swapList/loading',
      swapItemMode: 'swapList/swapItemMode',
      swapInterestCounts: 'swapList/swapInterestCounts'
    }),

    activePropositions() {
      return this.propositions.filter(p => p.active);
    },

    showPropositionSwitcher() {
      return this.activePropositions && this.activePropositions.length > 1;
    },

    distributedSwapsEnabled() {
      return this.$growthbook.isFeatureFlagEnabled('distributed-swaps-2');
    },

    greeting() {
      const currentHour = new Date().getHours() + 1;

      if (currentHour >= 0 && currentHour <= 9) {
        return this.$t('swaplist_header_greeting_good_morning');
      }

      if (currentHour >= 10 && currentHour <= 11) {
        return this.$t('swaplist_header_greeting_good_morning_2');
      }

      if (currentHour >= 12 && currentHour <= 17) {
        return this.$t('swaplist_header_greeting_good_afternoon');
      }

      if (currentHour >= 18) {
        return this.$t('swaplist_header_greeting_evening');
      }

      return this.$t('swaplist_header_greeting_welcome');
    }
  },
  methods: {
    ...mapActions({
      toggleSwapItemMode: 'swapList/toggleSwapItemMode'
    })
  }
};
</script>

<style lang="scss" scoped>
.swap-header {
  margin-bottom: 30px;
}

.swap-list-info {
  // margin: 40px 0;
  //temp while button is there
  margin: 0 0 20px 0;
}
.greeting {
  font-weight: 600;
  margin-bottom: 6px;
  font-size: 1.7rem;
}

.center-section-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.swap-list-count {
  opacity: 0.9;
  font-size: 0.95rem;
  font-weight: 400;

  & .small {
    margin-top: 10px;
  }
}

.bold {
  font-weight: 600;
}

.extra-bold {
  font-weight: 700;
}

.header {
  font-weight: 700;
  font-size: 1.3rem;
  display: flex;
  color: $text-primary;

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &.map-mode {
    padding: 0;
    margin: 0;
  }
}

.popup-content {
  font-size: 0.9rem;
  padding: 16px;
  font-weight: 400;
  line-height: 150%;

  @media ($mobile) {
    text-align: center;
  }

  &-title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 8px;
  }
}

.results-and-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.results-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2c3e50;
  margin-bottom: 3px;
}

.results-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #878b9d;
}
</style>
