function setProgressBar(context, value) {
  context.commit('setProgressBar', value);
}

function setShowContextualTutorial(context, value) {
  context.commit('setShowContextualTutorial', value);
}

function setEscClose(context, value) {
  context.commit('setEscClose', value);
}

function incrementTutInterestMarks(context, value) {
  context.commit('incrementTutInterestMarks', value);
}

function setTutInterestMarks(context, value) {
  context.commit('setTutInterestMarks', value);
}

function setTutStatus(context, value) {
  context.commit('setTutStatus', value.status);
}

function setTutActiveStep(context, value) {
  context.commit('setTutActiveStep', value.step);

  // Remove scroll lock unless step is highlightSwap
  if (value.step !== 'highlightSwap') {
    context.commit('ui/removeDisableScroll', 'ContextualTutModal', {
      root: true
    });
  }
}

export default {
  setProgressBar,
  setShowContextualTutorial,
  setEscClose,
  incrementTutInterestMarks,
  setTutInterestMarks,
  setTutStatus,
  setTutActiveStep
};
