import { render, staticRenderFns } from "./Congratulations.vue?vue&type=template&id=ceb335f6&scoped=true"
import script from "./Congratulations.vue?vue&type=script&lang=js"
export * from "./Congratulations.vue?vue&type=script&lang=js"
import style0 from "./Congratulations.vue?vue&type=style&index=0&id=ceb335f6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ceb335f6",
  null
  
)

export default component.exports