function calculatePlacement({ position, mapSize }) {
  const [, , positionX, positionY] = position.match(/\d+/g);

  const positionRight = mapSize.width / 2 > positionX;
  const positionTop = mapSize.height / 2 < positionY;
  const centerHorizontal =
    positionX > mapSize.width / 3 && positionX < (mapSize.width / 3) * 2;

  const placement = `translate(${
    centerHorizontal ? '-50%' : positionRight ? '2%' : '-102%'
  }, ${
    positionTop ? '-120%' : '-0%'
  }) translate(${positionX}px,${positionY}px)`;

  return placement;
}

async function setSwapDetails(context, { swapDetails }) {
  context.commit('setSwapDetails', { swapDetails });
}

function setSwapInterest(context, { targetPropositionId, interested }) {
  context.commit('setSwapInterest', { targetPropositionId, interested });
}

function setActiveSwap(context, { propositionId, position, mapSize }) {
  let placement;

  if (!propositionId) {
    context.commit('setActiveSwap', { activeSwap: null });
    return;
  }
  const { swapDetails } = context.state;

  const activeSwap = swapDetails.find(
    s => s.propositionId.toString() === propositionId.toString()
  );
  if (!activeSwap) window.Logger.log('Could not find swap');

  if (position) {
    placement = calculatePlacement({ position, mapSize });
  }
  context.commit('setActiveSwap', { activeSwap, position: placement });
}

export default { setSwapDetails, setActiveSwap, setSwapInterest };
