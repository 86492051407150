<template v-if="appReady">
  <NoActiveModal
    v-if="!primaryPropositionActive"
    :payment-page-proposition-id="primaryPropositionId"
    :in-free-area="primaryPropositionFreeArea"
    source="chat"
  />

  <div v-else class="wrapper-content-base">
    <div class="content-width">
      <div
        v-if="(isMobile && !selectedRoom) || !isMobile || !$route.params.roomId"
        class="left column"
        :class="{
          'is-freemium-chat-unlocked': !isRoomLocked
        }"
      >
        <Rooms
          :data="data"
          :candidate-id="chatOwnerId"
          :is-loading="isLoading"
          :is-loading-more="isLoadingMore"
          :is-mobile="isMobile"
          :is-freemium-or-trial="primaryPropositionIsLimited"
          :unlocked-rooms="unlockedRooms"
          @selected="handleRoomSelection($event)"
          @loadMore="loadMore()"
          @delete="handleLeaveRoom($event)"
        ></Rooms>
      </div>
      <div
        v-if="!isMobile || ($route.params.roomId && selectedRoom)"
        class="right column"
        :class="{
          'is-freemium-chat-unlocked': !isRoomLocked
        }"
      >
        <Messages
          v-if="$route.params.roomId && chatOwnerId && selectedRoom"
          :in-room="selectedRoom"
          :user-id="user.userId"
          :is-impersonated="user.isImpersonated"
          :is-mobile="isMobile"
          :standalone="false"
          where="inbox"
        ></Messages>
        <div v-else-if="!isLoading" class="empty-area">
          <svg
            class="initial-svg"
            version="1.1"
            viewBox="0 0 555 555"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#dddddd"
              d="M530.5 148.6H404.8v162.2c0 23.6-19.2 42.8-42.8 42.8H168.5v54.3c0 13.5 10.9 24.5 24.5 24.5h190.1l79.4 66.4c10.4 8.7 15.9 5.1 12.5-7.9l-15.5-58c0-0.1-0.1-0.3-0.1-0.4h71.2c13.5 0 24.5-10.9 24.5-24.5V173.1C555 159.6 544 148.6 530.5 148.6zM24.5 335.2h37.4c0 0.1-0.1 0.3-0.1 0.4l-15.5 58c-2.2 8.2-0.8 12.7 3.1 12.7 2.3 0 5.5-1.5 9.3-4.7l79.4-66.4h30.4 193.5c13.5 0 24.5-10.9 24.5-24.5V148.6 76c0-13.5-10.9-24.5-24.5-24.5H24.5C11 51.5 0 62.5 0 76v234.8C0 324.3 10.9 335.2 24.5 335.2z"
            ></path>
          </svg>
          <h2>{{ $t('chat_no_selected_title') }}</h2>
          <p>
            {{ $t('chat_no_selected_text') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { paymentApi } from '@/utils/axiosConfig';
import { lbApiBeta } from '../../utils/axiosConfig';
import { mapGetters, mapActions, mapState } from 'vuex';

import Rooms from './Rooms';
import Messages from './Messages';
import NoActiveModal from '@/components/Modals/NoActiveModal';
import { subscribe, unsubscribe } from '../../utils/socket';
import { log } from '../../utils/logger';
import { isDebug } from '../../utils/debug';
import events from '@/utils/helpers/events';

export default {
  components: {
    Rooms,
    Messages,
    NoActiveModal
  },
  data() {
    return {
      data: null,
      isLoading: false,
      isLoadingMore: false,
      selectedRoom: null,
      trialCTAModalOpen: true,
      unlockedRooms: [],
      isRoomLocked: true
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('meta_title_chat')
      };
    }
  },
  computed: {
    ...mapState('menu', ['chatOwnerId']),
    ...mapGetters({
      appReady: 'app/ready',
      user: 'app/user',
      isMobile: 'screenSize/isMobile',
      myPropositions: 'myPropositions/propositions',
      primaryProposition: 'myPropositions/primaryProposition',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryPropositionActive: 'myPropositions/primaryPropositionActive',
      primaryPropositionFreeArea: 'myPropositions/primaryPropositionFreeArea',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited',
      primaryPropositionInTrial: 'myPropositions/primaryPropositionInTrial'
    })
  },
  watch: {
    chatOwnerId(newId, oldId) {
      if (!oldId || newId == oldId) return;

      this.init();
    }
  },

  async created() {
    this.showMobileTabBar();

    this.isLoading = true;

    await this.waitForState('chatOwnerId');

    subscribe('reconnect', this.init);
    subscribe('chat-message-sent', this.handleNewMessage);
    subscribe('new-payment', this.handleNewPayment);

    try {
      await this.init();

      if (
        this.$route.params.roomId &&
        this.primaryPropositionIsLimited &&
        !this.unlockedRooms.includes(Number(this.$route.params.roomId))
      ) {
        this.$router.replace({ path: this.$routes.MESSAGES });
        this.isLoading = false;
        return;
      }

      if (this.data && this.data.rooms && this.$route.params.roomId) {
        const currentroom = this.data.rooms.find(
          x => x.id == this.$route.params.roomId
        );

        if (currentroom) {
          this.selectedRoom = currentroom;
        } else this.$router.replace({ path: this.$routes.MESSAGES });
      }
    } catch (error) {
      this.handleGeneralError(error);
    }

    this.isLoading = false;
  },

  beforeDestroy() {
    unsubscribe('reconnect', this.init);
    unsubscribe('chat-message-sent', this.handleNewMessage);
    unsubscribe('new-payment', this.handleNewPayment);
  },

  methods: {
    ...mapActions({
      showMobileTabBar: 'menu/showMobileTabBar',
      waitForState: 'menu/waitForState',
      setToast: 'toast/setToast',
      setGlobalModal: 'ui/setGlobalModal',
      setSelectedProductId: 'klarnaPayments/selectProductId'
    }),

    async init() {
      if (this.primaryPropositionActive) {
        await Promise.all([this.getRooms(), this.getPaidRooms()]);
      }
    },

    handleNewMessage(data) {
      if (!this.chatOwnerId || !data || data.senderId == -1) return;

      const result = this.setRoom(data.chatRoomId, {
        lastMessage: data.content,
        updatedAt: this.$dayjs.tz()
      });

      if (!result) {
        this.init();
      }

      if (this.data && this.data.rooms) {
        this.$set(this.data, 'rooms', this.sortRooms(this.data.rooms));
      }

      if (
        !this.$route.params.roomId ||
        (this.selectedRoom &&
          this.selectedRoom.id != data.chatRoomId &&
          data.senderId != this.chatOwnerId)
      ) {
        const currentConversation = this.getConversation(data.chatRoomId);
        if (!currentConversation) {
          this.init();
        }
      }
    },

    handleNewPayment() {
      this.init();
    },

    sortRooms(rooms) {
      if (!rooms) {
        return null;
      }

      return rooms
        .slice()
        .sort((a, b) => this.getSortDate(b) - this.getSortDate(a));
    },

    getSortDate(room) {
      return this.$dayjs(room.updatedAt).toDate();
    },

    handleRoomSelection({ room }) {
      if (
        (room.isFreemiumLocked &&
          this.primaryProposition.isFreemium &&
          !this.unlockedRooms.includes(room.id)) ||
        (this.primaryPropositionInTrial &&
          !this.unlockedRooms.includes(room.id))
      ) {
        this.openFreemiumCtaModal(room.id);
        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'open-chat',
          src: 'chat'
        });
        return;
      }

      if (!room.isFreemiumLocked && this.primaryProposition.isFreemium) {
        this.isRoomLocked = room.isFreemiumLocked;
      }

      if (this.$route.params.roomId == room.id) return;

      this.selectedRoom = room;
      this.$router.push({
        path: `${this.$t('path_messages', this.$routeLocale)}/${room.id}`
      });
    },

    getConversation(roomId) {
      const index = this.data.rooms.findIndex(x => x.id == roomId);
      if (index === -1) return;

      const index2 = this.data.rooms[index].conversations.findIndex(
        x => x.ownerId == this.chatOwnerId
      );
      if (index2 === -1) return;

      return this.data.rooms[index].conversations[index2];
    },

    setRoom(roomId, data) {
      const index = this.data.rooms.findIndex(x => x.id == roomId);
      if (index === -1) return false;

      this.$set(this.data.rooms, index, {
        ...this.data.rooms[index],
        ...data
      });

      return true;
    },

    async getPaidRooms() {
      try {
        if (this.primaryPropositionIsLimited) {
          const { data } = await paymentApi.get('micro-purchases/chat');
          this.unlockedRooms = data;
        }
      } catch (error) {
        this.handleGeneralError(error);
      }
    },

    async getRooms() {
      if (!this.chatOwnerId || this.chatOwnerId === -1) return;

      const { data } = await lbApiBeta.get(
        `/api/messenger/chat-room/participant/${this.chatOwnerId}`
      );

      data.rooms = this.sortRooms(data.rooms);
      this.data = data;

      events.emitEvent(events.eventTypes.PAGEVIEW, 'Chat', {
        hasMore: data.hasMore,
        rooms: data.rooms.length,
        unreadCount: data.rooms.filter(r => r.conversations[0].unreadCount > 0)
          .length
      });
    },

    async loadMore() {
      if (!this.chatOwnerId || this.chatOwnerId === -1) return;

      try {
        this.isLoadingMore = true;

        const { data } = await lbApiBeta.get(
          `/api/messenger/chat-room/participant/${this.chatOwnerId}`,
          {
            params: { currentSize: this.data.rooms.length }
          }
        );

        this.data.hasMore = data.hasMore;
        data.rooms.map(x => {
          this.data.rooms.push(x);
        });
      } catch (error) {
        this.handleGeneralError(error);
      }

      this.isLoadingMore = false;
    },

    async handleLeaveRoom({ room, id }) {
      const index = this.data.rooms.findIndex(x => x.id == room.id);
      if (index === -1) return;

      if (this.selectedRoom && this.selectedRoom.id == room.id) {
        this.selectedRoom = null;
        this.$router.push({ path: this.$routes.MESSAGES });
      }

      this.$delete(this.data.rooms, index);
      await this.leaveRoom(id);
    },

    async leaveRoom(userConversationId) {
      try {
        await lbApiBeta.delete(
          `/api/messenger/chat-room/${userConversationId}`
        );
      } catch (error) {
        this.handleGeneralError(error);
      }
    },

    handleGeneralError(error) {
      if (isDebug()) {
        console.error(error);
      }

      log(error.toString(), true);
      this.setToast({
        timer: 10,
        title: this.$t('toast_general_error_message')
      });
    },

    async openFreemiumCtaModal() {
      this.setGlobalModal('ChatLocked');
    }
  }
};
</script>

<style lang="scss" scoped>
.content-width {
  display: flex;
  max-width: 1200px;
  width: 100%;
  height: 100%;

  @media ($mobile) {
    max-width: unset;
    width: 100%;
  }
}

.wrapper-content-base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  height: 100%;

  @media ($mobile) {
    flex-direction: column;
  }
}

.content {
  width: 100%;
  max-width: 100%;
  margin: 0;
  margin-top: 80px;
  @media ($mobile) {
    margin-top: 0px;
  }
}

.column {
  display: flex;
  align-content: center;
  justify-content: center;
  height: calc(100vh - 80px);

  @media ($desktop) {
    overflow-x: auto;
    scrollbar-gutter: stable;
  }

  @media ($mobile) {
    flex-direction: column;
    position: relative;
  }

  &.is-freemium-chat-unlocked {
    @media ($desktop) {
      height: calc(100vh - 150px);
    }
  }
}

.left {
  display: flex;
  overflow-x: hidden;
  width: 450px;
  flex-shrink: 0;
  flex-grow: 0;

  @media ($mobile) {
    width: 100%;
    height: 100%;
    margin-bottom: 95px;
  }

  @media ($desktop) {
    padding-left: 10px;
  }
}

.right {
  display: flex;
  overflow-x: hidden;
  width: 100%;
  border-left: 1px solid lightgray;
  scrollbar-gutter: initial;

  @media ($mobile) {
    border: none;
    height: 100%;
  }
}

.empty-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.initial-svg {
  width: 220px;
}
</style>
