<template>
  <div v-html="sitemapHtml"></div>
</template>

<script>
import axios from 'axios';
import i18n from '@/i18n';

export default {
  name: 'Sitemap',
  data: function () {
    return {
      sitemapHtml: ''
    };
  },
  mounted() {
    document.title = `Sitemap - ${i18n.t(
      'meta_title_complement',
      this.$routeLocale
    )}`;
    this.loadSitemap();
  },
  methods: {
    async loadSitemap() {
      const { pathname } = window.location;
      const country = this.$country.getCountry().toUpperCase();
      const resp = await axios.get(
        `https://feed.lagenhetsbyte.se/${country}${pathname}`
      );
      const linkRegexp = new RegExp(`\/${country}\/sitemap\/`, 'g');
      this.sitemapHtml = resp.data.replaceAll(linkRegexp, '/sitemap/');

      const titleRegexp = new RegExp(`<title>(.*)<\/title>`);
      document.title = `${titleRegexp.exec(this.sitemapHtml)[1]} - ${i18n.t(
        'meta_title_complement',
        this.$routeLocale
      )}`;
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  padding-top: 80px;
}
</style>
