import axios from 'axios';
import { lbApiBeta } from '../../../utils/axiosConfig';
import { currentCountry } from '@/plugins/country/module';
import i18n, { routeLocale } from '../../../i18n';

async function search(searchTerm) {
  try {
    const res = await axios.get(
      `${i18n.t(
        'url_geolocation2',
        routeLocale
      )}/search/${currentCountry}/${encodeURI(searchTerm)}`
    );

    return { val: res.data, error: false };
  } catch (e) {
    return { val: e, error: true };
  }
}

async function getMultiPolygon(areas) {
  try {
    const res = await axios.post(
      `${i18n.t(
        'url_geolocation2',
        routeLocale
      )}/location/${currentCountry}/geoJson`,
      {
        locationIds: areas
      }
    );

    return { val: res.data, error: false };
  } catch (e) {
    return { val: e, error: true };
  }
}

async function getExistingAreas({ propositionId, wishIndex }) {
  try {
    const { data: areas } = await lbApiBeta.get(
      `/api/v2/proposition/${propositionId}/wish/${wishIndex}/geolocations`
    );

    return areas;
  } catch (e) {
    console.log({ e });

    return [];
  }
}

async function updateAreas({ propositionId, wishIndex, geoIds }) {
  try {
    const res = await lbApiBeta.post(
      `/api/v2/proposition/${propositionId}/wish/${wishIndex}/geolocations`,
      geoIds
    );

    return res;
  } catch (e) {
    console.error(e);
    return false;
  }
}

async function getWishAreaButtons() {
  try {
    const res = await axios.get(
      `${i18n.t('url_geolocation2', routeLocale)}/wisharea/${currentCountry}`
    );

    return { val: res.data, error: false };
  } catch (e) {
    return { val: e, error: true };
  }
}

export {
  search,
  getMultiPolygon,
  getExistingAreas,
  updateAreas,
  getWishAreaButtons
};
