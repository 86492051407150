<template>
  <img v-bind="$attrs" :src="url || NO_IMAGE" v-on="$listeners" />
</template>

<script>
import { NO_IMAGE } from '@/utils/helpers/default-values';

export default {
  name: 'BaseImage',

  props: {
    url: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      NO_IMAGE
    };
  },

  watch: {
    url(newUrl, oldUrl) {
      if (newUrl !== oldUrl && newUrl !== null) {
        this.load();
      }
    }
  },

  mounted() {
    // With browser cache, the image will only be downloaded once
    this.load();
  },

  methods: {
    load() {
      this.$nextTick(() => {
        const image = new Image();

        image.onload = () => {
          // Notify that the image is now visible in dom
          this.$emit('done');
        };

        image.src = this.url;
      });
    }
  }
};
</script>
