<template>
  <div class="overlay"></div>
</template>

<script>
export default {
  name: 'BaseOverlay'
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 150;
}
</style>
