<template>
  <div class="interest-buttons">
    <div
      :class="[
        'interest-button interest-button--no',
        { 'is-not-interested': interested === false }
      ]"
      @click.prevent.stop="handleNoClick"
    >
      <div class="button-content">
        <BaseIcon
          :icon-file="!isNotInterested ? 'close-new' : 'close-new-white'"
          class="button-icon"
        />
        {{ $t('interest_button_hide') }}
      </div>
    </div>
    <div
      v-if="swap.details.percentage !== 100 || matchesViewABTest"
      :class="[
        'interest-button interest-button--yes',
        { 'is-interested': interested === true }
      ]"
      @click.prevent.stop="handleYesClick"
    >
      <div class="button-content">
        <BaseIcon
          :icon-file="interested ? 'check-mark-new-white' : 'check-mark-new'"
          class="button-icon"
        />
        {{ $t('interest_button_interested') }}
      </div>
    </div>
    <div
      v-else
      class="interest-button start-chat"
      @click.prevent.stop="$emit('open-chat')"
    >
      <div class="button-content">
        <BaseIcon icon-file="icon-chat-bubbles" class="button-icon" />
        <template v-if="!swap.chat">
          {{ $t('swap_item_start_chat') }}
        </template>
        <template v-else-if="swap.chat && !swap.chat.unreadCount">
          {{ $t('swap_item_open_chat') }}
        </template>
        <template v-else>
          {{ $t('swap_item_chat_x_unread', { unread: swap.chat.unreadCount }) }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  TUTORIAL_STATUS_TYPES,
  TUTORIAL_STEPS_TYPES
} from '../../store/modules/tutorial';

export default {
  name: 'InterestButtons',

  props: {
    interested: {
      type: [Boolean, null],
      default: null
    },
    targetPropositionId: {
      type: String,
      required: true
    },
    swap: {
      type: Object,
      required: true
    },
    usedInTutorial: {
      type: Boolean,
      default: false
    },
    context: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      isNotInterested: false,
      tutHasMarkedInterest: false,
      TUTORIAL_STATUS_TYPES,
      TUTORIAL_STEPS_TYPES
    };
  },

  computed: {
    ...mapGetters({
      tutStatus: 'tutorial/status',
      tutActiveStep: 'tutorial/activeStep',
      tutInterestMarks: 'tutorial/tutInterestMarks'
    }),

    isTutorialLike() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutInterestMarks < 3
      );
    },

    isTutorialFirstLike() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutActiveStep === TUTORIAL_STEPS_TYPES.HIGHLIGHT_SWAP &&
        this.tutInterestMarks === 1
      );
    },

    matchesViewABTest() {
      return this.$growthbook.isFeatureFlagEnabled('matches-view');
    }
  },

  methods: {
    ...mapActions({
      markInterest: 'interests/markInterest',
      setGlobalModal: 'ui/setGlobalModal',
      incrementTutInterestMarks: 'tutorial/incrementTutInterestMarks'
    }),

    handleNoClick() {
      if (this.isTutorialLike && !this.tutHasMarkedInterest) {
        this.incrementTutInterestMarks(1);
        this.tutHasMarkedInterest = true;
      }

      if (this.usedInTutorial) {
        this.$emit('interested', false);
      } else {
        this.markInterest({
          targetPropositionId: this.targetPropositionId,
          interested: false,
          where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
        });
        this.isNotInterested = true;
      }
    },

    handleYesClick() {
      if (this.isTutorialLike && !this.tutHasMarkedInterest) {
        this.incrementTutInterestMarks(1);
        this.tutHasMarkedInterest = true;
      }

      if (this.usedInTutorial) {
        this.$emit('interested', true);
      } else {
        if (this.interested) {
          this.markInterest({
            targetPropositionId: this.targetPropositionId,
            interested: null,
            where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
          });
        } else {
          this.markInterest({
            targetPropositionId: this.targetPropositionId,
            interested: true,
            where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.interest-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid #e8e8e8;
}

.interest-button {
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  padding: 10px 16px;
}

.interest-button:first-of-type {
  border-right: 1px solid #e8e8e8;
}

.button-content {
  display: flex;
  align-items: center;
}

.button-icon {
  margin-right: 4px;
  width: 14px;
  height: 14px;
}

.is-not-interested {
  background-color: $sweet-red;
  color: #fff;
}

.is-interested {
  background-color: $transfer-green;
  color: #fff;
}

.start-chat {
  background-color: #409fff;
  color: #fff;
}
</style>
