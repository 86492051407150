export default function smoothScroll({ from, to }) {
  if (from === to) return;
  const distance = to > from ? to - from : from - to;

  let speed = Math.round(distance / 20);

  if (speed >= 6) speed = 6;

  const step = Math.round(distance / 25);
  let leapY = to > from ? from + step : from - step;
  let timer = 0;
  if (to > from) {
    for (let i = from; i < to; i += step) {
      setTimeout('window.scrollTo(0, ' + leapY + ')', timer * speed);
      leapY += step;
      if (leapY > to) leapY = to;
      timer++;
    }
    return;
  }
  for (let i = from; i > to; i -= step) {
    setTimeout('window.scrollTo(0, ' + leapY + ')', timer * speed);
    leapY -= step;
    if (leapY < to) leapY = to;
    timer++;
  }

  timer = 0;
}
