<template>
  <div class="not-found">
    <div class="four-o-four-image"></div>
    <div class="text"><b>404:</b> {{ $t('page_not_found_text') }}</div>
    <router-link class="text-link" :to="$t('path_my_propositions')">{{
      $t('page_not_found_action')
    }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  head: {
    title() {
      return {
        inner: this.$t('meta_title_404')
      };
    }
  },
  mounted() {
    window.pageNotFound = true;
    window.prerenderPaused = false;
  }
};
</script>

<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;
}

.four-o-four-image {
  width: 600px;
  height: 300px;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/img/404-transparent.png');

  @media ($mobile) {
    width: 100%;
    background-size: cover;
  }
}

.text {
  font-size: 1.4rem;
}
.text-link {
  margin-top: 20px;
  font-size: 1.1rem;
}
</style>
