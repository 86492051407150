<template>
  <AImage
    :url="url"
    :classes="[
      'image',
      {
        'rounded-all-left': !position && mode !== 'card-mobile',
        'rounded-up-left': position === 'upper' && mode !== 'card-mobile',
        'rounded-down-left': position === 'lower' && mode !== 'card-mobile',
        'rounded-all-upper': position === 'upper' && mode === 'card-mobile'
      }
    ]"
    :styles="styles"
    loading="lazy"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
  </AImage>
</template>

<script>
import AImage from '@/components/atomic/atoms/a-image';
import { thumbnail } from '@/filters/thumbnail';

export default {
  name: 'MListImage',
  components: { AImage },

  filters: {
    thumbnail
  },
  props: {
    url: {
      type: String,
      required: false,
      default: null
    },

    classes: {
      type: Array,
      default: () => [],
      required: false
    },

    styles: {
      type: Array,
      default: () => [],
      required: false
    },

    position: {
      type: String,
      required: false,
      default: null
    },

    mode: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      hovered: false
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
$row-height: 50px;

.zoomed {
  position: absolute;
  transform-origin: 0% 100%;
  bottom: 0;
  left: 0;
  border-radius: $small-box-radius;
  background-position: center;
  background-size: cover;
  height: 200px;
  width: calc(200px * 1.77777777778);
  z-index: 888;
}

.image {
  position: relative;
  float: left;
  background-position: center;
  background-size: cover;
  background-color: #ebebeb;

  object-fit: cover;

  height: 100%;
  width: 100%;
}

.rounded {
  border-radius: 9px;
}

.rounded-all-upper {
  border-radius: 9px 9px 0 0;
}

.rounded-up-left {
  border-radius: 9px 0 0 0;
}

.rounded-down-left {
  border-radius: 0 0 0 9px;
}

.rounded-all-left {
  border-radius: 9px 0 0 9px;
}
</style>
