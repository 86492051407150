<template>
  <div class="proposition-gallery">
    <div
      v-for="(img, index) in images"
      :key="img.fileName"
      class="image-container"
    >
      <img
        :src="img.fileName"
        loading="lazy"
        :class="{ 'blurred-background-image': blurImages && index >= 2 }"
      />
      <div v-if="blurImages && index >= 2" class="overlay"></div>
      <div v-if="blurImages && index >= 2" class="blur-msg-wrapper">
        <div
          class="blur-msg-click-container"
          @click="goToEditPropositionImages()"
        >
          <BaseIcon icon-file="blur-img-eye" :width="48" :height="46" />

          <div class="blur-msg">
            <p class="unblock-text">
              {{ $t('unlock_blur_msg') }}
            </p>
            <div class="upload-images-text">
              <span
                ><u>{{ $t('proposition_gallery_blur_link_msg') }}</u>
              </span>
              <span>
                {{ $t('proposition_gallery_blur_msg') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PropositionGallery',

  props: {
    images: {
      type: Array,
      required: true
    },
    proposition: {
      type: Object,
      required: true
    },
    blurImages: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    })
  },

  mounted() {
    this.temporaryHideTrialBanner({ hide: true });
  },

  beforeDestroy() {
    this.temporaryHideTrialBanner({ hide: false });
  },

  methods: {
    ...mapActions({
      temporaryHideTrialBanner: 'trial/temporaryHideTrialBanner'
    }),

    goToEditPropositionImages() {
      this.$router.push({
        path: `${this.$t('path_edit_proposition_photos', this.$routeLocale)}/${
          this.primaryPropositionId
        }/1`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.proposition-gallery {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: rgb(39, 39, 39);
  margin: 0 auto;

  @media ($desktop) {
    max-width: 75%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.image-container {
  padding: 0 10px;
  background-color: rgb(39, 39, 39);
  position: relative;
  overflow: hidden;

  &:first-of-type {
    padding-top: 10px;
  }

  @media ($mobile) {
    padding: 0;

    &:first-of-type {
      padding-top: 0;
    }
  }

  .blurred-background-image {
    opacity: 1;
    margin: -30px -60px -60px -30px;
    filter: blur(30px);
    -webkit-filter: blur(30px); /* Safari 6.0 - 9.0 */
    -webkit-backface-visibility: hidden; /* For backface visibility */
    backface-visibility: hidden; /* Standard */
    transform: translateZ(0); /* Hardware acceleration */
    -moz-filter: blur(30px);
    -o-filter: blur(30px);
    -ms-filter: blur(30px);

    @media ($mobile) {
      padding: 0;
      padding-bottom: 8px;

      &:first-of-type {
        padding-top: 0;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;

    @media ($mobile) {
      padding: 0;

      &:first-of-type {
        padding-top: 0;
      }
    }
  }

  .blur-msg-wrapper {
    position: absolute;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    @media ($mobile) {
      padding: 0;
      width: unset;
      height: unset;

      &:first-of-type {
        padding-top: 0;
      }
    }

    .blur-msg-click-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 26px;
      margin: 0 40px;
      cursor: pointer;

      p {
        margin: 0;
      }
    }

    .blur-msg {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 18px;
      .unblock-text {
        font-weight: 600;
      }
      .upload-images-text {
        color: #fff;
        line-height: 150%;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 80vh;
    object-fit: contain;
  }
}
</style>
