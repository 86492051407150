<template>
  <div class="container">
    <div class="spinner-container">
      <BaseSpinner class="spinner-alt" />
    </div>
    <div class="loading-text">{{ $t('general_loading') }}</div>
  </div>
</template>

<script>
export default {
  name: 'BaseSpinnerAndText'
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner-container {
  width: 40px;
  height: 40px;
}

.loading-text {
  margin-top: 20px;
  font-weight: 600;
}
</style>
