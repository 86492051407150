<template>
  <div class="thank-you-page">
    <div class="thank-you-page-inner">
      <div :class="['illustration', { 'illustration-swapped': hasSwapped }]" />
      <h1 class="title">{{ $t('confirmation_cancelled_title') }}</h1>
      <h4 v-if="!hasSwapped" class="subtitle">
        {{ $t('confirmation_cancelled_text_not_swapped_1') }}
      </h4>
      <h4 v-if="hasSwapped" class="subtitle">
        {{ $t('confirmation_cancelled_text_swapped_1') }}
      </h4>
      <p v-if="!hasSwapped" class="text">
        {{ $t('confirmation_cancelled_text_not_swapped_2') }}
        <a
          :href="`mailto:${$t(
            'confirmation_cancelled_text_not_swapped_mailto'
          )}`"
        >
          {{ $t('confirmation_cancelled_text_not_swapped_mailto') }}
        </a>
        {{ $t('confirmation_cancelled_text_not_swapped_3') }}
      </p>
    </div>

    <primary-button @click.native="handleClick">
      {{ $t('confirmation_cancelled_confirm') }}
    </primary-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PrimaryButton from '../../components/Buttons/Primary';

export default {
  name: 'SubscriptionCancelled',

  components: {
    PrimaryButton
  },

  head: {
    title() {
      return {
        inner: this.$t('meta_title_subscription_cancelled')
      };
    }
  },

  computed: {
    ...mapGetters({
      user: 'app/user'
    }),
    hasSwapped: function () {
      const swapped = this.getParameterByName({ name: 'swapped' });

      return swapped === 'true';
    }
  },

  mounted() {
    if (window.parent && window.parent.postMessage) {
      window.parent.postMessage('SUCCESSFULLY_CANCELLED', '*');
    }
  },

  methods: {
    handleClick: function () {
      this.$router.replace(this.$t('path_my_propositions'));
    },

    getParameterByName: function ({ name }) {
      const url = window.location.href;

      // eslint-disable-next-line no-useless-escape
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
  }
};
</script>

<style lang="scss" scoped>
.thank-you-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 810px;
  padding-top: 180px;
  margin: 0 auto;
  @media ($mobile) {
    padding: 20px;
    padding-bottom: 100px;
  }
}

.thank-you-page-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin-bottom: 3rem;
}

.illustration {
  height: 150px;
  width: 150px;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/check-double.svg');
  margin-bottom: 20px;

  @media ($mobile) {
    height: 100px;
    width: 100px;
  }

  &-swapped {
    background-image: url('../../assets/svg/mood-peace.svg');
  }
}

.title {
  text-align: center;
}

.subtitle {
  text-align: center;
  color: $text-secondary;
  margin: 0;
  margin-bottom: 10px;
}

.text {
  font-weight: 600;
  color: $text-secondary;
  text-align: center;
  line-height: 150%;
  margin: 0;

  &:first-of-type {
    margin-top: 20px;
  }

  &:last-of-type {
    margin-bottom: 40px;
  }
}
</style>
