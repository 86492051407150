<template>
  <div class="swap-view">
    <template v-if="swapViewLoading">
      <div class="title-wrapper">
        <div class="title">{{ $t('swap_view_loading') }}</div>
      </div>

      <SwapViewToggle v-if="isUserList" />
      <NonUserSwapViewToggle v-else />

      <div
        ref="currentContainer"
        class="current-container"
        :style="{ height: contentMaxHeight }"
      >
        <Spinner />
      </div>
    </template>

    <template v-else-if="swapDetails === null">
      <div class="panel">
        <h4>{{ $t('swap_view_not_available') }}</h4>
        <BaseButton @click="goToDirectSwap">{{
          $t('swap_view_not_available_button')
        }}</BaseButton>
      </div>
    </template>

    <template v-else>
      <div
        v-if="
          currentSwapViewTab !== SWAP_VIEW_TABS.SWAP_INFO &&
          currentSwapViewTab !== SWAP_VIEW_TABS.SWAP_ALTERNATIVES
        "
        class="title-wrapper"
      >
        <div class="title">
          {{
            isTriangle
              ? $t('swap_view_triangle_swap')
              : isManual
              ? $t('swap_view_manual_swap')
              : $t('swap_view_direct_swap')
          }}
        </div>
        <div class="swap-info-btn" @click="goToInfo" />
      </div>

      <div
        v-if="
          currentSwapViewTab === SWAP_VIEW_TABS.SWAP_INFO ||
          currentSwapViewTab === SWAP_VIEW_TABS.SWAP_ALTERNATIVES
        "
        class="modal-header"
        @click.stop="closeModal"
      >
        <div class="close-btn" />
        <div class="close-label">{{ $t('swap_view_close') }}</div>
      </div>

      <SwapViewToggle
        v-else-if="isUserList"
        :is-full-interest="isFullInterest"
        :has-active-chat="!!(swapDetails && swapDetails.chat)"
        :chat-dot="
          swapDetails && swapDetails.chat && swapDetails.chat.unreadCount > 0
        "
      />
      <NonUserSwapViewToggle v-else />

      <div class="current-container">
        <div v-if="currentSwapViewTab === SWAP_VIEW_TABS.FULL_INTEREST">
          <SwapFullInterest
            :is-triangle="isTriangle"
            :full-since-date="swapDetails.fullSwapSince"
          />
        </div>

        <div v-if="currentSwapViewTab === SWAP_VIEW_TABS.SWAP">
          <SwapOverview :swap-details="swapDetails" />
        </div>

        <div v-if="currentSwapViewTab === SWAP_VIEW_TABS.SWAP_ALTERNATIVES">
          <SwapAlternatives />
        </div>

        <div v-if="currentSwapViewTab === SWAP_VIEW_TABS.SWAP_INFO">
          <SwapInfo :swap-details="swapDetails" />
        </div>

        <template v-if="currentSwapViewTab === SWAP_VIEW_TABS.CHAT">
          <Messages
            v-if="freemiumCanAnswerChat || premiumOrFreeLimitlessChat"
            :standalone="true"
            :proposition-ids="messengerPropositionIds"
            :user-id="user.userId"
            :is-impersonated="user.isImpersonated"
            :is-mobile="isMobile"
            where="swapView"
            mode="plain"
            @isFreemiumLocked="handleFreemiumChatLocked"
            :scroll-elements="['current-container', 'conversations-wrapper']"
            class="in-swap-view"
          />

          <SwapViewChatPay
            v-else-if="isTrial"
            :payment-page-proposition-id="primaryPropositionId"
          />
        </template>

        <SwapChainMap
          v-if="currentSwapViewTab === SWAP_VIEW_TABS.MAP"
          :interests="interests"
          :swaps="swaps"
          :primary-proposition-id="primaryPropositionId"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SWAP_VIEW_TABS } from '../../store/modules/swapList';
import SwapViewToggle from './SwapViewToggle';
import NonUserSwapViewToggle from './NonUserSwapViewToggle';
import SwapOverview from './SwapOverview';
import SwapAlternatives from './SwapAlternatives';
import SwapInfo from './SwapInfo';
import Spinner from '../Loading/Spinner';
import SwapChainMap from '../Maps/SwapChainMap';
import SwapFullInterest from './SwapFullInterest';
import SwapViewChatPay from './SwapViewChatPay';
import Messages from '@/views/Chat/Messages';
import events from '@/utils/helpers/events';

export default {
  name: 'SwapView',

  components: {
    SwapViewToggle,
    NonUserSwapViewToggle,
    SwapOverview,
    SwapAlternatives,
    SwapInfo,
    Spinner,
    SwapChainMap,
    Messages,
    SwapFullInterest,
    SwapViewChatPay
  },

  data() {
    return {
      SWAP_VIEW_TABS: SWAP_VIEW_TABS,
      contentMaxHeight: 0,
      showChatPay: false
    };
  },

  computed: {
    ...mapGetters({
      user: 'app/user',
      isMobile: 'screenSize/isMobile',
      currentSwapViewTab: 'swapList/currentSwapViewTab',
      swapViewLoading: 'swapList/swapViewLoading',
      swapDetails: 'swapList/swapDetails',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      myPropositions: 'myPropositions/propositions',
      isUserList: 'swapList/isUserList',
      primaryPropositionIsPremiumOrFree:
        'myPropositions/primaryPropositionIsPremiumOrFree',
      primaryProposition: 'myPropositions/primaryProposition'
    }),

    freemiumCanAnswerChat() {
      return (
        this.primaryProposition.isFreemium &&
        !this.showChatPay &&
        this.messengerPropositionIds.length > 0
      );
    },

    premiumOrFreeLimitlessChat() {
      return (
        this.primaryPropositionIsPremiumOrFree &&
        this.messengerPropositionIds.length > 0
      );
    },

    isTriangle() {
      return this.swapDetails && this.swapDetails.type === 'triangleswap';
    },

    isManual() {
      return this.swapDetails && this.swapDetails.type === 'manual';
    },

    swaps() {
      if (!this.swapDetails || !this.swapDetails.swapParts) {
        return [];
      }

      return this.swapDetails.swapParts;
    },

    interests() {
      if (!this.swaps) return [];

      return this.swaps.map(sp => sp.interested);
    },

    isFullInterest() {
      return this.swaps.every(sp => sp.interested);
    },

    isTrial() {
      if (!this.myPropositions) return false;

      const primary = this.myPropositions.find(proposition => {
        return (
          this.primaryPropositionId &&
          proposition.propositionId &&
          proposition.propositionId.toString() ===
            this.primaryPropositionId.toString()
        );
      });

      if (
        primary &&
        (primary.isTrial === true || primary.isFreemium === true)
      ) {
        return true;
      }

      return false;
    },

    messengerPropositionIds() {
      return this.swaps.map(sp => parseInt(sp.propositionId, 10));
    }
  },

  watch: {
    currentSwapViewTab(tab) {
      events.emitEvent(events.eventTypes.PAGEVIEW, `Swap Chain ${tab}`);
    }
  },

  mounted() {
    const { calculateContentMaxHeight } = this;

    calculateContentMaxHeight();

    window.addEventListener('resize', calculateContentMaxHeight);
  },

  beforeDestroy() {
    const { calculateContentMaxHeight } = this;
    window.removeEventListener('resize', calculateContentMaxHeight);
  },

  methods: {
    ...mapActions({
      setCurrentSwapViewTab: 'swapList/setCurrentSwapViewTab',
      fetchSwapDetails: 'swapList/fetchSwapDetails'
    }),

    goToDirectSwap() {
      const newPath = this.$route.fullPath.replace(
        'triangleswap',
        'directswap'
      );

      this.$router.push({ path: newPath });

      this.fetchSwapDetails({
        propositionId: this.$route.query.propositionPageId,
        type: 'directswap'
      });
    },

    goToInfo() {
      this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP_INFO });
    },

    closeModal() {
      this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP });
    },

    calculateContentMaxHeight() {
      const { currentContainer } = this.$refs;
      if (!currentContainer) return;
      const placement = currentContainer.offsetTop;
      const { innerHeight } = window;

      const padding = 20;

      const maxHeight = innerHeight - placement - padding;
      this.contentMaxHeight = maxHeight;
    },

    handleFreemiumChatLocked(isLocked) {
      this.showChatPay = isLocked;
    }
  }
};
</script>

<style lang="scss" scoped>
.swap-view {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 16px 15px;

  @media ($desktop) {
    padding: 24px;
    width: 95%;
  }
}

.trial-margin-wrapper {
  @media ($desktop) {
    padding-top: calc(
      16px + 103px
    ); // dnormal padding + trialbanner, sass variable messing up trial state
  }
}

.modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 25px;
  padding: 0 12px;
  cursor: pointer;
}

.close-btn {
  height: 35px;
  width: 35px;
  border: 1px solid $black;
  border-radius: 50%;
  background-position: center;
  background-size: 50%;
  background-image: url('../../assets/svg/close-cross.svg');
}

.close-label {
  font-size: 0.8rem;
  color: $text-secondary;
  font-weight: 600;
  margin-top: 3px;
}

.title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  font-weight: 700;
  font-size: 1.2rem;
}

.swap-info-btn {
  margin-left: 5px;
}

.current-container {
  height: 62vh;
  margin-top: 12px;
  // overflow-y: auto;

  @media ($desktop) {
    margin-top: 24px;
    height: 72vh;
  }
}

.swap-info-btn {
  height: 14px;
  width: 14px;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/svg/information.svg');
}

.panel {
  padding: 30px 20px;
  border-radius: 3px;
  background-color: #eee;

  h4 {
    margin-top: 0;
  }
}
</style>
