<template>
  <div
    class="radio"
    :class="{
      'is-active': isChecked,
      'is-disabled': disabled
    }"
  >
    <label class="radio-container">
      <input
        type="radio"
        :name="name"
        :value="value"
        :checked="isChecked"
        :disabled="disabled"
        @change="changeHandler"
        @input="inputHandler"
      />
      <!-- @slot Custom checkmark markup (bind 'isChecked' boolean, 'disabled' boolean -->
      <slot name="checkmark" v-bind="{ isChecked, disabled }">
        <div class="radio-checkmark" :class="{ 'is-active': isChecked }"></div>
      </slot>
      <div class="radio-content">
        <!-- @slot Custom label markup (bind 'label' string, 'isChecked' boolean, 'disabled' boolean -->
        <slot name="label" v-bind="{ label, isChecked, disabled }">
          <div v-if="label" class="radio-label">{{ label }}</div>
        </slot>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseRadioButton',

  model: {
    prop: 'selected',
    event: 'input'
  },

  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: [String, Number],
      default: ''
    }
  },

  computed: {
    isChecked() {
      return this.value === this.selected;
    }
  },

  methods: {
    changeHandler() {
      this.$emit('change', this.value);
    },
    inputHandler() {
      this.$emit('input', this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.radio {
  margin-bottom: 1rem;
}

.radio-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio-container input {
  display: none;
}

.radio-checkmark {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  margin-right: 10px;
}

.radio-checkmark.is-active::before {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #2ec973;
}

.radio-label {
  font-size: 14px;
  font-weight: 600;
  color: #2c3e50;
}
</style>
