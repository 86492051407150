<template>
  <VueSlider
    :key="$attrs.max"
    v-bind="{ ...$props, ...$attrs, ...sliderProps }"
    :reverse="reversed"
    @callback="handleInput($event)"
  />
</template>

<script>
import VueSlider from 'vue-slider-component';
export default {
  components: { VueSlider },

  props: {
    value: {
      type: [Array, Number, String],
      required: true
    },
    reversed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      firstDone: false,
      sliderProps: {
        sliderStyle: {
          backgroundColor: '#fff',
          border: '2px solid #878B9D',
          boxSizing: 'border-box',
          boxShadow: 'none'
        },
        processStyle: {
          backgroundColor: '#409fff'
        },
        bgStyle: {
          backgroundColor: '#e2eaef'
        },
        width: '100%',
        height: '4',
        class: 'slider',
        dotSize: 18
      }
    };
  },

  methods: {
    handleInput(e) {
      this.$emit('input', e);
    }
  }
};
</script>
