<template>
  <div class="blocked-propositions">
    <SideMenu />

    <div class="content-container">
      <h2>{{ $t('blocked_props_title') }}</h2>
      <p>
        {{ $t('blocked_props_desc') }}
      </p>
      <template v-if="isLoading">
        <h4>{{ $t('blocked_is_loading') }}</h4>
      </template>
      <template v-else-if="blockedPropositions.length">
        <BasePropositionCard
          v-for="blockedAd in blockedPropositions"
          :key="blockedAd.propositionId"
          :swap="blockedAd"
          source="blocked-propositions"
        >
          <BaseButton
            class="smaller outline"
            @click.prevent="resetBlock(blockedAd.propositionId)"
            >{{ $t('blocked_props_btn') }}</BaseButton
          >
        </BasePropositionCard>
      </template>
      <template v-else>
        <h4>{{ $t('blocked_no_found') }}</h4>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SideMenu from '@/components/MyPropositions/SideMenu';

export default {
  name: 'BlockedPropositions',

  components: {
    SideMenu
  },

  data() {
    return {
      blockedPropositions: [],
      isLoading: true
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('blocked_props_meta_title')
      };
    }
  },

  computed: {
    ...mapGetters({
      primaryProposition: 'myPropositions/primaryProposition'
    })
  },

  watch: {
    primaryProposition() {
      this.getBlockedPropositions();
    }
  },

  created() {
    this.getBlockedPropositions();
  },

  methods: {
    ...mapActions({
      fetchBlockedPropositions: 'swapList/fetchBlockedPropositions',
      blockSwapParty: 'swapList/blockSwapParty',
      setToast: 'toast/setToast'
    }),

    async getBlockedPropositions() {
      this.blockedPropositions = await this.fetchBlockedPropositions(
        this.primaryProposition.propositionId
      );

      this.isLoading = false;
    },

    resetBlock(propositionId) {
      this.blockSwapParty({
        propositionId: propositionId,
        blocked: false
      });

      // Remove clicked proposition from the list
      const index = this.blockedPropositions.findIndex(
        item => item.propositionId === propositionId
      );
      if (index !== -1) {
        this.blockedPropositions.splice(index, 1);
      }

      this.setToast({
        title: this.$t('blocked_toast_message')
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.blocked-propositions {
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  @media ($desktop) {
    flex-direction: row;
  }
}

.content-container {
  padding-left: 60px;
  width: calc(75% - 30px);

  @media ($mobile) {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 10px;

    @media ($mobile) {
      margin-top: 20px;
      font-size: 18px;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 30px;
    line-height: 1.5;

    @media ($mobile) {
      font-size: 14px;
    }
  }
}
</style>
