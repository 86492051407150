import { getCountryUpperCase } from '../plugins/country/module';
import { isDebug } from './debug';

export function addDataLayer(data) {
  if (isDebug()) {
    return;
  }

  if (!data.event) data.event = 'interaction';
  if (!data.project) data.project = 'authenticated';

  if (data.what) data.label = data.what;

  // Make sure value is a number
  if (data.value) data.value = data.value.toString().replace(/[^0-9]/g, '');
  if (data.value) data.value = parseFloat(data.value);

  if (!window.dataLayer) window.dataLayer = [];

  if (data.propositionId) {
    data.customEventId = getEventId(
      getCountryUpperCase(),
      data.propositionId.toString()
    );
  }

  window.dataLayer.push(data);
}

function getEventId(country, propositionId) {
  const coeff = 1000 * 60 * 5;
  const date = new Date();
  const time = Math.round(date.getTime() / coeff) * coeff;

  return `${country}_${propositionId}_${time.toString()}`;
}
