function isLoading(state) {
  return state.loading;
}

function changeSubscriptionLoading(state) {
  return state.changeSubscriptionLoading;
}

function cancelSubscriptionLoading(state) {
  return state.cancelSubscriptionLoading;
}

function autoSubscriptionLoading(state) {
  return state.autoSubscriptionLoading;
}

function currentProposition(state) {
  return state.currentProposition;
}

function currentSubscription(state) {
  return state.currentSubscription;
}

function cancelModalOpen(state) {
  return state.cancelModalOpen;
}

function feedback(state) {
  return state.cancelReasons.feedback;
}

function offers(state) {
  return state.offers;
}

function cancelImmediatelyChecked(state) {
  return state.cancelImmediatelyChecked;
}

function CANCEL_MODAL_STEPS(state) {
  return state.CANCEL_MODAL_STEPS;
}

function currentStep(state) {
  return state.currentStep;
}

function feedbackType(state) {
  return state.feedbackType;
}

function offerType(state) {
  return state.offerType;
}

function cancelButtonReady(state) {
  return state.cancelButtonReady;
}

function showCancelOffer(state) {
  return state.showCancelOffer;
}

function products(state) {
  return state.products;
}

export default {
  isLoading,
  currentProposition,
  cancelModalOpen,
  feedback,
  currentSubscription,
  offers,
  changeSubscriptionLoading,
  cancelSubscriptionLoading,
  cancelImmediatelyChecked,
  autoSubscriptionLoading,
  CANCEL_MODAL_STEPS,
  currentStep,
  feedbackType,
  offerType,
  cancelButtonReady,
  showCancelOffer,
  products
};
