<template>
  <component
    :is="blurContent ? 'div' : 'router-link'"
    :to="{
      query: {
        propositionPageId: swap.propositionId,
        type: swap.details.type
      }
    }"
    :class="['swap-list-item', { toned }, { 'blur-content': blurContent }]"
    @click="handlePropositionCardClick"
    @click.native="handlePropositionCardClick"
    @click.right.native="handlePropositionCardRightClick"
  >
    <div class="column fav-column" style="min-width: 22px">
      <div
        v-if="isUserList"
        :class="['favourite-icon', { marked: swap.isFavourite }]"
        @click.stop.prevent="handleMarkFavourite"
      ></div>
    </div>

    <div class="column image-address-column">
      <div style="display: flex; flex-direction: column">
        <div
          v-for="(residence, index) in swap.residences"
          :key="residence.index"
          style="display: flex"
          :style="[index === 1 ? { marginTop: '6px' } : '']"
        >
          <div
            :class="[
              'swap-list-item-image',
              { 'has-image': residence.pictures[0] }
            ]"
            :style="[
              residence.pictures[0]
                ? {
                    backgroundImage: `url(${residence.pictures[0]})`,
                    backgroundSize: 'cover'
                  }
                : ''
            ]"
            @mouseenter="imageHovered = index"
            @mouseleave="imageHovered = false"
          >
            <transition name="scale" mode="out-in">
              <div
                v-if="residence.pictures[0] && imageHovered === index"
                class="zoomed-image"
                :style="[
                  {
                    backgroundImage: `url(${getHighDef(residence.pictures[0])})`
                  }
                ]"
              ></div>
            </transition>
          </div>

          <div class="address-info-wrapper">
            <div class="address-info">
              <div class="address">
                {{ residence.streetAddress }}
                <BaseIcon
                  v-if="swap.verified"
                  icon="verified"
                  :width="14"
                  :height="14"
                  class="verified-badge"
                />
              </div>

              <template
                v-if="
                  swap.details.percentage === 100 &&
                  isUserList &&
                  !matchesViewABTest
                "
              >
                <BaseButton
                  class="chat-button"
                  @click.prevent.stop="openPropositionModalTab('chatTab')"
                  ><template v-if="!swap.chat">
                    {{ $t('swap_item_start_chat') }}
                  </template>
                  <template v-else-if="swap.chat && !swap.chat.unreadCount">
                    {{ $t('swap_item_open_chat') }}
                  </template>
                  <template v-else>
                    {{
                      $t('swap_item_chat_x_unread', {
                        unread: swap.chat.unreadCount
                      })
                    }}
                  </template></BaseButton
                >
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      :class="[
        { 'diff-value': swap.diff && swap.diff.rooms },
        'column rooms-column'
      ]"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        "
      >
        <div
          v-for="residence in swap.residences"
          :key="residence.index"
          class="flex-column"
          style="
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          {{ residence.rooms }}
        </div>
      </div>
    </div>

    <div
      :class="[
        { 'diff-value': swap.diff && swap.diff.sqm },
        'column sqm-column'
      ]"
    >
      <div style="display: flex; flex-direction: column">
        <div
          v-for="residence in swap.residences"
          :key="residence.index"
          class="flex-column"
          style="
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          {{ residence.sqm }}
        </div>
      </div>
    </div>

    <div
      :class="[
        { 'diff-value': swap.diff && swap.diff.floor },
        'column floor-column'
      ]"
    >
      <div style="display: flex; flex-direction: column">
        <div
          v-for="residence in swap.residences"
          :key="residence.index"
          class="flex-column"
          style="
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          {{ residence.floor }}
        </div>
      </div>
    </div>

    <div
      :class="[
        { 'diff-value': swap.diff && swap.diff.rent },
        'column rent-column'
      ]"
    >
      <div style="display: flex; flex-direction: column">
        <div
          v-for="residence in swap.residences"
          :key="residence.index"
          style="
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          {{ spacedRent(residence) }}
        </div>
      </div>
    </div>

    <div
      :class="[
        { 'diff-value': swap.diff && swap.diff.geolocation },
        'column postal-area-column'
      ]"
    >
      <div style="display: flex; flex-direction: column">
        <div
          v-for="residence in swap.residences"
          :key="residence.index"
          style="
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          {{ residence.postalArea }}
        </div>
      </div>
    </div>

    <div class="column swap-type-column">
      {{ swapTypeReadable }}
    </div>

    <div class="column interest-column">
      <div class="interest-area">
        <interest-indicators :interests="interests" :list-size="true" />
      </div>
    </div>

    <div
      class="column min-width-95 interest-button-column"
      :style="swap.residences.length > 1 ? { height: '115px' } : ''"
    >
      <interest-buttons
        v-if="isUserList"
        :interested="interests[0]"
        :target-proposition-id="swap.propositionId.toString()"
      />
    </div>
  </component>
</template>

<script>
import InterestIndicators from './InterestIndicators';
import InterestButtons from './InterestButtons';
import events from '@/utils/helpers/events';
import { SWAP_VIEW_TABS } from '@/store/modules/swapList';
import { mapActions, mapGetters } from 'vuex';
import { addRightClickTracker } from '@/utils/helpers/rightClick';

export default {
  name: 'SwapListItem',

  components: {
    InterestIndicators,
    InterestButtons
  },

  props: {
    showSwapDetails: {
      type: Function,
      required: true
    },
    swap: {
      type: Object,
      required: true
    },
    toned: {
      type: Boolean,
      required: true
    },
    blurContent: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentResidence: 0,
      imageHovered: false,
      SWAP_VIEW_TABS: SWAP_VIEW_TABS
    };
  },

  computed: {
    ...mapGetters({
      isUserList: 'swapList/isUserList',
      isMobile: 'screenSize/isMobile',
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    }),

    matchesViewABTest() {
      return this.$growthbook.isFeatureFlagEnabled('matches-view');
    },

    interests() {
      return this.swap.details.interests;
    },

    swapTypeReadable() {
      switch (this.swap.details.type) {
        case 'manual':
          return this.$t('swap_list_item_manual_swap');
        case 'triangleswap':
          return this.$t('swap_list_item_triangle_swap');
        case 'directswap':
          return this.$t('swap_list_item_direct_swap');
        default:
          return '';
      }
    },

    swapLabel() {
      const { swapType } = this.swap;

      switch (swapType) {
        case 21:
          return this.$t('general_2_for_1');
        case 12:
          return this.$t('general_1_for_2');
        default:
          return this.$t('general_1_for_1');
      }
    },
    typeColor() {
      const { swapType } = this.swap;

      switch (swapType) {
        case 21:
          return 'pink';
        case 12:
          return 'yellow';
        default:
          return 'blue';
      }
    }
  },

  methods: {
    ...mapActions({
      markFavourite: 'favourites/markFavourite',
      unmarkFavourite: 'favourites/unmarkFavourite',
      setGlobalModal: 'ui/setGlobalModal',
      setCurrentSwapViewTab: 'swapList/setCurrentSwapViewTab',
      setPageModalActivePage: 'ui/setPageModalActivePage',
      fetchSwapDetails: 'swapList/fetchSwapDetails'
    }),

    spacedRent(residence) {
      return residence.rent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },

    getHighDef(imgUrl) {
      return imgUrl.replace('200x200', '800x800');
    },

    handleMarkFavourite() {
      if (this.swap.isFavourite) {
        events.emitEvent(events.eventTypes.CLICK, 'Favourite Unmark', {
          src: `swaplist`
        });
        this.unmarkFavourite({ propositionId: this.swap.propositionId });
      } else {
        events.emitEvent(events.eventTypes.CLICK, 'Favourite Mark', {
          src: `swaplist`
        });
        this.markFavourite({ propositionId: this.swap.propositionId });
      }
    },

    triggerPropositionClickedEvent() {
      events.emitEvent(events.eventTypes.CLICK, 'View Proposition', {
        src: `swaplist`,
        propositionId: this.swap.propositionId
      });
    },

    handlePropositionCardRightClick() {
      addRightClickTracker(this.swap.propositionId, 'swaplist');
    },

    handlePropositionCardClick() {
      if (this.blurContent) {
        this.setGlobalModal('SwapsLocked');

        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'swap-locked',
          src: 'swap-list'
        });
      } else {
        this.triggerPropositionClickedEvent();
      }
    },

    openPropositionModalTab(tab) {
      if (tab === 'chatTab' || tab === 'swapchainTab') {
        // Get swap details
        this.fetchSwapDetails({
          propositionId: this.swap.propositionId,
          type: this.swap.details.type
        });

        // Set active page modal tab to details
        this.setPageModalActivePage('swapdetails');

        if (tab === 'chatTab') {
          // Set current swap view tab to chat
          this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.CHAT });
        }

        if (tab === 'swapchainTab') {
          // Set current swap view tab to swap chain
          this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP });
        }
      } else {
        this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP });
        this.triggerPropositionClickedEvent();
      }

      this.$router.push({
        query: {
          propositionPageId: this.swap.propositionId,
          type: this.swap.details.type
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$row-height: 50px;

.swap-list-item {
  cursor: pointer;
  display: table-row;

  &.toned {
    background-color: rgba(241, 242, 246, 0.35);
  }
}

.swap-list-item > * {
  border-top: 1px solid rgba(28, 45, 65, 0.15);
}

.swap-list-item:hover {
  background-color: rgba(241, 242, 246, 0.22);
}

.column {
  display: table-cell;
  vertical-align: middle;
  color: $text-primary;
  padding: 7px 0;
  font-weight: 600;
  font-size: 0.7rem;
}

.column.image-address-column {
  min-width: 210px;
  text-align: left;
  color: $text-primary;
}

.column.postal-area-column {
  word-break: break-word;
  margin: 0 auto;
  width: 140px;
  padding: 0px 10px;
  text-align: center;
}

.column.swap-type-column {
  width: 81px;
}

.column.interest-column {
  min-width: 80px;
  text-align: center;
}

.column.rent-column {
  min-width: 65px;
  text-align: right;
}

.rooms-column {
  min-width: 20px;
  text-align: right;
}

.sqm-column {
  min-width: 20px;

  text-align: right;
}

.column.postal-area-column {
  min-width: 115px;
}

.floor-column {
  min-width: 20px;

  text-align: right;
}

.flex-column {
  display: inline-flex;
}

.swap-list-item-image {
  position: relative;
  width: calc(#{$row-height} * 1.2);
  height: $row-height;
  border-radius: $small-box-radius;
  float: left;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/skyline-placeholder-img.svg');
  background-color: #ebebeb;
}

.zoomed-image {
  position: absolute;
  transform-origin: 0% 100%;
  bottom: 0;
  left: 0;
  border-radius: $small-box-radius;
  background-position: center;
  background-size: cover;
  height: 200px;
  width: calc(200px * 1.77777777778);
  z-index: 888;
}

.address-info-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  height: $row-height;
  // max-width: 150px;
}

.address-info {
  margin-left: 10px;
}

.icon-area {
  position: relative;
}

.underline {
  text-decoration: underline;
}

.favourite-icon {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 auto;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/heart-empty.svg');

  &.marked {
    background-image: url('../../assets/svg/heart-full.svg');
  }
}

.swap-item-slider {
  height: 220px;
  width: 100%;
  @media ($desktop) {
    height: 180px;
  }
  @media ($mobile) {
    height: 180px;
  }
}

.profile-picture {
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  background-image: url('../../assets/img/nopic-square.png');
}

.address {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;

  &.secondary {
    display: flex;
    align-items: top;
    font-size: 8px;
  }
}

.verified-badge {
  margin-left: 6px;
  flex-grow: 1;
}

.small-text {
  font-size: 8px;
}

.shift-icon {
  height: 13px;
  width: 15px;
  margin-left: 6px;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/shift.svg');
}

.interest-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0 6px 0;
}

.interest-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.min-width-95 {
  min-width: 95px;
  display: flex;
  align-items: center;
  height: 65px;
  justify-content: center;
  padding: 0;
}

.min-width-20 {
  min-width: 20px;
  display: flex;
  align-items: center;
  height: 65px;
  justify-content: center;
  padding: 0;
}

.proposition-type {
  display: inline-flex;
  padding: 4px 5px;
  border-radius: 12px;
  font-weight: 700;
  white-space: nowrap;
  margin-bottom: 3px;
  font-size: 0.5rem;

  &.pink {
    background-color: #fce5f6;
    color: #ed63d2;
    border: 1px solid #ed63d2;
  }

  &.blue {
    background-color: #e7f3ff;
    color: $transfer-blue;
    border: 1px solid $transfer-blue;
  }

  &.yellow {
    color: #ffc212;
    background-color: #fff8e6;
    border: 1px solid #ffc212;
  }
}

.diff-value {
  color: #db8e00;
}

.blur-content {
  filter: blur(4px);
  user-select: none;

  .interest-button-column,
  .fav-column {
    pointer-events: none;
  }
}

.chat-button {
  padding: 5px 6px !important;
  font-size: 10px !important;
  margin-top: 3px !important;
}
</style>
