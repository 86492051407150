<template>
  <div class="clickable" @click.stop.prevent="handleToggleFavorite($event)">
    <div
      v-if="isFavoriteSaving || imageLoading"
      class="spinner-wait-wrapper icon-size"
    >
      <div class="spinner icon-size">
        <a-spinner :classes="['spinner-wait']" />
      </div>
    </div>

    <AImage
      v-show="!imageLoading"
      v-if="!isFavoriteSaving"
      :classes="['icon-favorite', 'icon-size']"
      :url="isFavorite ? icons.FILTER.MARKED_FAVORITE : icons.FILTER.FAVORITE"
      @done="imageLoading = false"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import icons from '@/utils/icon-constants';

import AImage from '@/components/atomic/atoms/a-image';
import ASpinner from '@/components/atomic/atoms/a-spinner';
import events from '@/utils/helpers/events';

export default {
  components: { AImage, ASpinner },

  props: {
    item: { type: Object, required: true }
  },
  data() {
    return {
      icons: icons,
      isFavoriteSaving: false,
      imageLoading: false
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated',
      favorites: 'favourites/getFavourites'
    }),

    isFavorite() {
      return this.favorites.some(
        x => x.propositionId == this.item.propositionId && x.favourite
      );
    }
  },

  methods: {
    async handleToggleFavorite(e) {
      this.$emit('click', e);
      if (!this.isAuthenticated) return;

      this.imageLoading = true;
      this.isFavoriteSaving = true;

      if (this.isFavorite) {
        events.emitEvent(events.eventTypes.CLICK, 'Favourite Unmark', {
          src: `search`
        });
        await this.unmarkFavourite({ propositionId: this.item.propositionId });
        this.$emit('done', false);
      } else {
        events.emitEvent(events.eventTypes.CLICK, 'Favourite Mark', {
          src: `search`
        });
        await this.markFavourite({ propositionId: this.item.propositionId });
        this.$emit('done', true);
      }

      this.isFavoriteSaving = false;
    },

    ...mapActions({
      markFavourite: 'favourites/markFavourite',
      unmarkFavourite: 'favourites/unmarkFavourite'
    }),

    ...mapMutations({
      setItem: 'search/setItem'
    })
  }
};
</script>

<style lang="scss" scoped>
.icon-favorite {
  background-position: center;
  background-size: contain;
}

.icon-size {
  width: 24px;
  height: 24px;
}

.spinner-wait {
  border-top-color: $main_blue !important;
}

#spinner {
  border-width: 2px !important;
}
</style>
