export function truncate(value, lengthStart = 5, lengthEnd = 5) {
  value = value.toString();

  if (value.length > lengthStart + lengthEnd) {
    return `${value.substring(0, lengthStart)}...${value.substring(
      value.length - lengthEnd,
      value.length
    )}`;
  }

  return value;
}
