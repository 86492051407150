<template>
  <div :class="[...wrapperClasses]">
    <ARadioButton
      v-for="item of values"
      :key="item.key"
      :name="groupName"
      :active="item.active"
      :value="item.key"
      :classes="classes"
      :styles="styles"
      :button="button"
      @change="handleCheck"
    >
      <div v-html="item.value" />
    </ARadioButton>
  </div>
</template>

<script>
import { randomUuid } from '@/utils/helpers/string';
import ARadioButton from '@/components/atomic/atoms/a-radio-button';

export default {
  name: 'MRadioButtons',
  components: { ARadioButton },
  props: {
    values: {
      type: Array,
      required: true
    },
    wrapperClasses: {
      type: Array,
      default: () => [],
      required: false
    },
    classes: {
      type: Array,
      default: () => [],
      required: false
    },
    styles: {
      type: Array,
      default: () => [],
      required: false
    },
    button: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data() {
    return {
      groupName: randomUuid()
    };
  },

  methods: {
    handleCheck(key) {
      const newChecked = [...this.values].map(x => {
        x = { ...x };
        if (key === x.key) x.active = true;
        else x.active = false;
        return x;
      });

      this.$emit('change', { values: newChecked, key: key });
    }
  }
};
</script>
