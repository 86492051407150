<template>
  <transition name="tut-slide-up" appear>
    <div
      v-if="
        !showGlobalModal &&
        tutActiveStep === TUTORIAL_STEPS_TYPES.HIGHLIGHT_SWAP &&
        tutStatus === TUTORIAL_STATUS_TYPES.STARTED
      "
      class="interest-pointer-wrapper"
    >
      <div class="interest-tooltip-wrapper">
        <BaseTooltip variant="dark"
          ><div v-html="$t('tutorial_contextual_desc_interested_choice')" />
        </BaseTooltip>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  TUTORIAL_STATUS_TYPES,
  TUTORIAL_STEPS_TYPES
} from '../../store/modules/tutorial';

export default {
  name: 'InterestPointer',

  data() {
    return {
      TUTORIAL_STATUS_TYPES,
      TUTORIAL_STEPS_TYPES
    };
  },

  computed: {
    ...mapGetters({
      tutActiveStep: 'tutorial/activeStep',
      tutStatus: 'tutorial/status',
      showGlobalModal: 'ui/showGlobalModal'
    })
  }
};
</script>

<style lang="scss" scoped>
.interest-pointer-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  z-index: 0;
}

.interest-tooltip-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  top: 4px;
  font-size: 14px;
  position: absolute;
  user-select: none;
  height: min-content;
  width: max-content;

  & .tooltip {
    width: 320px;
    left: -3px;
    top: 13px;
    max-width: 320px;
  }

  animation: nudge 2s ease infinite;
}

@media ($desktop) {
  .interest-tooltip-wrapper {
    justify-content: flex-end;
    top: 10px;
    & .tooltip {
      min-width: 320px;
      top: -10px;
      left: 37px;
    }
  }

  .interest-pointer-wrapper {
    justify-content: flex-end;
  }
}

@keyframes nudge {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
</style>
