<template>
  <div class="mobile-swap-header">
    <StandardModal
      v-if="sortModalOpen"
      standard-modal-name="SortModal"
      :close-function="closeSortModal"
      style="z-index: 1002"
    >
      <SortOptions
        slot="content"
        @reorder="sortOption => handleSelectSortType(sortOption)"
      />
    </StandardModal>

    <StandardModal
      v-if="filterModalOpen"
      standard-modal-name="FilterModal"
      :title="$t('mobile_header_filter')"
      :close-function="closeFilterModal"
      :action-function="resetFilters"
      :action-title="$t('mobile_header_clear')"
      :with-border="true"
    >
      <Filters
        slot="content"
        :close-filter-modal="closeFilterModal"
        :readable-sort-type="readableSortType"
        @sort="openSortModal"
      />
    </StandardModal>
    <div
      :class="
        tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        tutActiveStep === TUTORIAL_STEPS_TYPES.FIRST_INTEREST_MODAL
          ? 'animate-slide-down-height'
          : ''
      "
    >
      <InfoBanner class="info-banner" />

      <PropositionSwitcher v-if="isUserList && showPropositionSwitcher" />
      <div
        v-if="
          shouldShowOnboardingBanner &&
          tutStatus !== TUTORIAL_STATUS_TYPES.STARTED &&
          accountType !== 'trial'
        "
        class="onboarding-wrapper"
        :class="{
          'onboarding-fade-grow-animation':
            tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED
        }"
      >
        <OnboardingBanner />
      </div>
      <SwapListToggle
        v-if="isUserList && !distributedSwapsEnabled && !matchesViewABTest"
      />

      <div class="search-filter-wrapper">
        <Search />
        <div class="filter-action" @click.stop="openFilterModal">
          <div class="filter-icon" />
          <div v-if="filterCount > 0" class="filter-count">
            {{ filterCount }}
          </div>
        </div>
      </div>

      <div class="action-wrapper">
        <div v-if="loading" class="swap-count-wrapper">
          {{ $t('mobile_header_loading_swaps') }}
        </div>
        <div
          v-else-if="distributedSwapsEnabled && newSwapsCount > 0"
          class="swap-count-wrapper"
        >
          <span v-if="newSwapsCount === 1">
            {{ $t('swaplist_header_new_count_single_header') }}</span
          >
          <span v-if="newSwapsCount > 1">
            {{
              $t('swaplist_header_new_count_header', {
                new: newSwapsCount
              })
            }}
          </span>
        </div>
        <div v-else class="swap-count-wrapper">
          {{ $t('mobile_header_showing') }}
          <span class="bold">{{ postFilterCountTotal }}</span>
          {{ $t('mobile_header_swaps') }}
          <div
            v-if="swapFilterType == 'ALL' || swapFilterType == 'NEW'"
            class="swap-count-unanswered"
          >
            <span class="bold">{{ postFilterCountUnmarked }}</span>
            {{ $t('mobile_header_unanswered') }}
          </div>
        </div>
        <ListTypeToggle />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SORT_TYPES, SWAP_LIST_TYPES } from '../../store/modules/swapList';

import Search from './Search';
import PropositionSwitcher from './PropositionSwitcher';
import StandardModal from '@/components/Modals/Standard';
import SortOptions from './SortOptions';
import Filters from './Filter/Filters';
import OnboardingBanner from '../Onboarding/OnboardingBanner';
import SwapListToggle from './SwapListToggle';
import ListTypeToggle from './ListTypeToggle';
import InfoBanner from '@/components/InfoBanner';
import {
  TUTORIAL_STATUS_TYPES,
  TUTORIAL_STEPS_TYPES
} from '@/store/modules/tutorial';

export default {
  name: 'MobileSwapHeader',

  components: {
    Search,
    PropositionSwitcher,
    StandardModal,
    SortOptions,
    Filters,
    OnboardingBanner,
    SwapListToggle,
    ListTypeToggle,
    InfoBanner
  },

  data() {
    return {
      sortModalOpen: false,
      filterModalOpen: false,
      SWAP_LIST_TYPES,
      SORT_TYPES,
      TUTORIAL_STATUS_TYPES,
      TUTORIAL_STEPS_TYPES
    };
  },

  computed: {
    ...mapGetters({
      isUserList: 'swapList/isUserList',
      propositions: 'myPropositions/propositions',
      sortType: 'swapList/sortType',
      filterCount: 'swapList/filterCount',
      postFilterCountTotal: 'swapList/postFilterCountTotal',
      postFilterCountUnmarked: 'swapList/postFilterCountUnmarked',
      loading: 'swapList/loading',
      shouldShowOnboardingBanner: 'myPropositions/onboardingState',
      accountType: 'myPropositions/accountType',
      newSwapsCount: 'swapList/newSwapsCount',
      swapFilterType: 'swapList/swapFilterType',
      tutStatus: 'tutorial/status',
      tutActiveStep: 'tutorial/activeStep'
    }),

    matchesViewABTest() {
      return this.$growthbook.isFeatureFlagEnabled('matches-view');
    },

    activePropositions() {
      return this.propositions.filter(p => p.active);
    },

    showPropositionSwitcher() {
      return this.activePropositions && this.activePropositions.length > 1;
    },

    readableSortType() {
      switch (this.sortType) {
        case SORT_TYPES.HIGHEST_SCORE:
          return this.$t('filter_container_type_score');
        case SORT_TYPES.SQM:
          return this.$t('mobile_header_type_sqm');
        case SORT_TYPES.ROOMS:
          return this.$t('mobile_header_type_num_of_rooms');
        case SORT_TYPES.RENT:
          return this.$t('mobile_header_type_rent');
        case SORT_TYPES.PERCENTAGE:
          return this.$t('mobile_header_type_interest');
        case SORT_TYPES.INTEREST_DATE:
          return this.$t('sort_options_type_interest_date');
        default:
          return '';
      }
    },

    distributedSwapsEnabled() {
      return this.$growthbook.isFeatureFlagEnabled('distributed-swaps-2');
    }
  },

  methods: {
    ...mapActions({
      setSortType: 'swapList/setSortType',
      resetFilters: 'swapList/resetFilters',
      fetchPropositions: 'myPropositions/fetchPropositions'
    }),

    handleSelectSortType(sortOption) {
      this.setSortType({ type: sortOption });
      this.closeSortModal();
    },

    checkIfNeedsFetchPropositions() {
      if (this.propositions.length === 0) {
        this.fetchPropositions();
      }
    },

    openSortModal() {
      this.sortModalOpen = true;
    },

    closeSortModal() {
      this.sortModalOpen = false;
    },

    openFilterModal() {
      this.filterModalOpen = true;
    },

    closeFilterModal() {
      this.filterModalOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-swap-header {
  animation: slide-down 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0s;
  background-color: #f1f2f6;
  padding-top: 10px;
}

.search-filter-wrapper {
  display: flex;
  padding: 10px;
}

.bold {
  font-weight: 700;
}

.filter-action {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 14px;
  padding-right: 4px;
  font-size: 0.8rem;
  cursor: pointer;
}

.filter-icon {
  height: 22px;
  width: 22px;
  background-image: url('../../assets/svg/filter2.svg');
  background-position: center;
  background-size: contain;
}

.filter-count {
  border-radius: 50%;
  background-color: #213a75;
  color: white;
  font-size: 0.7rem;
  font-weight: 700;
  margin-left: 8px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
  margin-top: -1px;
}

.action-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.map-btn-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;

  @media ($desktop) {
    margin-left: 24px;
  }
}

.swap-count-wrapper {
  font-size: 0.8rem;
  font-weight: 600;
}

.swap-count-unanswered {
  color: #878b9d;
}

.onboarding-wrapper {
  padding: 0 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.onboarding-fade-grow-animation {
  animation: fade-grow-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 1s;
}

.info-banner {
  margin: 10px;
  margin-top: 0;
}

@keyframes fade-grow-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
    transform-origin: 50% 0%;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}

.animate-slide-down-height {
  animation: slide-down-height 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.4s;
}

@keyframes slide-down-height {
  0% {
    height: 0;
    opacity: 0;
  }
  20% {
    transform: translateY(10px);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    height: 140px;
  }
}
</style>
