<template>
  <div>
    <div>
      <p
        v-if="product && product.category == 'annonsforlangning'"
        class="agreement"
      >
        {{ $t('payment_new_terms1') }}
        <a :href="$t('path_general_terms', $routeLocale)" target="_blank">{{
          $t('payment_new_terms2')
        }}</a>
        {{ $t('payment_new_terms3') }}
      </p>
      <section v-if="hasPaymentToken">
        <section>
          <button
            class="order-button"
            :disabled="isLoading"
            @click="submitPayment()"
          >
            <span v-if="payText">
              {{ payText }}
            </span>
            <span v-else>{{ $t('klarna_button_action') }}</span>
          </button>

          <a
            v-if="!isLoading"
            class="change-payment-method"
            @click="restart()"
            >{{ $t('payment_new_method_action') }}</a
          >
        </section>
      </section>

      <section>
        <form @submit.stop.prevent="dropinComponent.submit()">
          <div id="dropin-container"></div>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { paymentApi } from '@/utils/axiosConfig';
import { parseJwt } from '@/utils/jwt';
import events from '@/utils/helpers/events';

export default {
  props: {
    payText: {
      type: String,
      default: null
    },

    product: {
      type: Object,
      default: null
    },

    meta: {
      type: Object,
      default: null
    },

    propositionId: {
      type: [String, Number, null],
      default: null
    },

    additionalProducts: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      isLoadingDropinComponent: false,
      dropinComponent: null,
      hasPaymentToken: null,
      checkout: null,
      redirecting: false,
      isLoading: false,
      paymentSuccessful: false
    };
  },

  computed: {
    redirectResult() {
      return this.$route.query.redirectResult;
    },

    details() {
      return this.$route.query.q;
    },

    productId() {
      if (!this.product) {
        return null;
      }

      return this.product.productId;
    },

    secure3dVerification() {
      const { MD, PaRes } = this.$route.query;
      if (!MD || !PaRes) {
        return null;
      }

      return {
        MD,
        PaRes
      };
    }
  },

  watch: {
    async product(newVal) {
      if (newVal) {
        this.update();
      }
    },

    async additionalProducts(newVal) {
      if (newVal) {
        this.update();
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('unload', this.domRestart);
  },

  async created() {
    window.addEventListener('unload', this.domRestart);

    this.setIsLoading(true);

    this.selectProductFromQuery(this.details);

    if (this.secure3dVerification) {
      // we were redirected back after 3d verification
      this.handle3dSecureCallback(this.secure3dVerification, this.details);
    } else if (this.redirectResult) {
      this.setIsLoading(true);
      // we were redirected back after payment method specific actions
      await this.handleRedirectResult(this.redirectResult, this.details);
    } else {
      this.hasPaymentToken = await this.getHasPaymentToken();

      if (!this.hasPaymentToken) {
        this.initPaymentForm();
      }
      this.setIsLoading(false);
    }

    if (this.details) {
      this.$router.replace({
        params: {},
        query: {}
      });
    }
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast',
      setSelectedProductId: 'klarnaPayments/selectProductId'
    }),

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
      this.$emit('loading', isLoading);
    },

    async update() {
      if (!this.details && !this.hasPaymentToken) {
        await this.initPaymentForm();
        this.checkout.update();
      }
    },

    restart() {
      this.hasPaymentToken = false;

      this.$emit('resetPaymentMethod');

      if (this.checkout) {
        this.checkout.update();
        this.setIsLoading(false);
      } else {
        this.initPaymentForm();
      }
    },

    domRestart() {
      if (this.checkout && this.redirecting && !this.paymentSuccessful) {
        this.checkout.update();
      }
    },

    selectProductFromQuery(query) {
      try {
        if (query) {
          const { productId } = parseJwt(query);
          this.setSelectedProductId({ productId });
        }
      } catch {
        console.log('Couldnt select product from query');
      }
    },

    async handlePaypalCallback(payload) {
      try {
        const { data } = await paymentApi.post(
          `/adyen/validate-paypal-response`,
          payload
        );

        this.handlePaymentStatusResult(data);
      } catch (error) {
        this.handleError(error);
      }
    },

    async handle3dSecureCallback(secure3dVerification, details) {
      try {
        const { data } = await paymentApi.post(`/adyen/validate-3d-response`, {
          ...secure3dVerification,
          details
        });

        this.handlePaymentStatusResult(data);
      } catch (error) {
        this.handleError(error);
      }
    },

    async handleRedirectResult(redirectResult, details) {
      try {
        const { data } = await paymentApi.post(
          `/adyen/validate-token-response`,
          {
            redirectResult,
            details
          }
        );

        await this.handlePaymentStatusResult(data);
      } catch (error) {
        this.handleError(error);
      }
    },

    sendContinuePaymentEvent() {
      events.emitEvent(events.eventTypes.CLICK, 'Continue Payment', {
        provider: 'Adyen',
        propositionId: this.propositionId,
        productId: this.productId,
        additionalProductIds: this.additionalProducts
          ? this.additionalProducts.map(p => p.id)
          : []
      });
    },

    async handlePaymentFormSubmitted(payload) {
      this.setIsLoading(true);
      this.sendContinuePaymentEvent();

      try {
        const { data } = await paymentApi.post('/adyen/payment-token', {
          propositionId: this.propositionId,
          productId: this.productId,
          returnUrl: window.location.href,
          additionalProductIds: Array.isArray(this.additionalProducts)
            ? this.additionalProducts.map(p => p.id)
            : [],
          adyenPayload: payload.data,
          meta: this.meta
        });

        this.handlePaymentStatusResult(data);
      } catch (error) {
        this.handleError(error);
      }
    },

    async initPaymentForm() {
      await this.preloadGooglePay();

      if (!this.dropinComponent) {
        this.isLoadingDropinComponent = true;
      }

      const config = await this.getPaymentMethods();
      delete config.paymentMethodsResponse.storedPaymentMethods;

      this.checkout = await AdyenCheckout({
        ...config,
        locale: this.$t('locale_language'),
        showPayButton: true,
        onSubmit: this.handlePaymentFormSubmitted,
        onAdditionalDetails: ev => {
          if (ev.data.details.paymentSource === 'paypal') {
            this.handlePaypalCallback(ev.data);
          }
        },
        onError: error => {
          console.log(error);
        }
      });

      this.dropinComponent = this.checkout
        .create('dropin', {
          onReady: () => {
            this.setIsLoading(false);
          }
        })
        .mount('#dropin-container');

      this.isLoadingDropinComponent = false;
    },

    async submitPayment() {
      this.setIsLoading(true);
      this.sendContinuePaymentEvent();

      try {
        const { data } = await paymentApi.post('/adyen/pay', {
          propositionId: this.propositionId,
          productId: this.productId,
          additionalProductIds: this.additionalProducts
            ? this.additionalProducts.map(p => p.id)
            : [],
          meta: this.meta
        });

        this.handlePaymentStatusResult(data);
      } catch (error) {
        this.handleError(error);
      }
    },

    async getPaymentMethods() {
      try {
        const { data } = await paymentApi.get('/adyen/payment-methods', {
          params: {
            productId: this.productId,
            additionalProductIds: this.additionalProducts
              ? this.additionalProducts.map(p => p.id)
              : undefined
          }
        });
        return data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getHasPaymentToken() {
      try {
        const { data } = await paymentApi.get('/adyen/has-payment-token');
        return data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async handlePaymentStatusResult(statusResult) {
      const { status } = statusResult;

      if (status === 'AUTHORIZED') {
        await this.waitForSuccess(statusResult.orderId);
        return;
      } else if (status === 'PENDING' && statusResult.action) {
        this.dropinComponent.handleAction(statusResult.action);
        return;
      } else if (status === 'PENDING') {
        this.$emit('pending', statusResult.orderId);
        return;
      } else if (status === 'REDIRECT') {
        this.redirecting = true;
        this.dropinComponent.handleAction(statusResult.action);
        return;
      } else if (status === 'CANCELLED') {
        this.setToast({ timer: 10, title: this.$t('payment_cancelled') });
      } else if (status === 'PAYMENT_STARTED') {
        this.setToast({ timer: 10, title: this.$t('payment_already_started') });
      } else if (status === 'REFUSED') {
        if (statusResult.reason) {
          this.setToast({
            timer: 30,
            title: this.$t('payment_refused_reason', {
              reason: statusResult.reason
            })
          });
        } else {
          this.setToast({
            timer: 30,
            title: this.$t('payment_refused')
          });
        }
      } else if (status === 'FAILED' || status === 'INTERNAL_ERROR') {
        this.handleError(new Error(statusResult.reason));
      } else if (status === 'ALREADY_PAID') {
        this.handleError(new Error(statusResult.reason));
        // prevent adyen reload
        return;
      }

      this.restart();
    },

    pause(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async waitForSuccess(orderId) {
      try {
        while (!this.paymentSuccessful) {
          if (!orderId) {
            break;
          }

          const { data } = await paymentApi.get(`/status/order/${orderId}`);
          if (data.status === 'SUCCESS') {
            this.paymentSuccessful = true;

            document.dispatchEvent(
              new CustomEvent('success', {
                detail: null
              })
            );
          } else if (
            data.status === 'FAILED' ||
            data.status === 'INTERNAL_ERROR'
          ) {
            throw new Error(data.status);
          }

          await this.pause(2000);
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    handleError(error) {
      this.setIsLoading(false);
      this.$emit('error', error);

      document.dispatchEvent(
        new CustomEvent('error', {
          detail: error
        })
      );
    },

    async preloadGooglePay() {
      await this.loadJs('https://pay.google.com/gp/p/js/pay.js');
    },

    loadJs(address) {
      return new Promise(resolve => {
        const exists = document.querySelector(`script[src="${address}"]`);
        if (exists) {
          resolve();
        }

        const script = document.createElement('script');
        script.onload = resolve;
        script.async = true;
        script.src = address;
        document.head.appendChild(script);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.order-button {
  border: none;
  cursor: pointer;
  background-color: $transfer-blue;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  font-family: inherit;
  border-radius: 5px;
  padding: 15px 45px;
  margin-bottom: 30px;
  width: 100%;
  height: auto;
  line-height: inherit;
  margin-top: 10px;
}

.order-button[disabled] {
  background: #d0d0d0;
}

.change-payment-method {
  display: block;
  font-weight: 600;
  font-size: 14px;
  color: #409fff;
  text-align: center;
}

.change-payment-method:hover {
  text-decoration: underline;
  cursor: pointer;
}

.agreement {
  font-weight: 600;
  font-size: 12px;
  margin-top: 20px;
}

#dropin-container {
  margin-top: 20px;
}
</style>
