function setFavourites(state, { data }) {
  state.favourites = data;
}

function unmarkFavourite(state, { propositionId }) {
  const favourite = state.favourites.find(
    fav => fav.propositionId === propositionId.toString()
  );
  if (favourite) {
    favourite.favourite = false;
  }
  //favourite.isLoading = true;
}

function markFavourite(state, { propositionId }) {
  const favourite = state.favourites.find(
    fav => fav.propositionId.toString() === propositionId.toString()
  );

  if (favourite) {
    favourite.favourite = true;
    return;
  }

  const favouritesCopy = [...state.favourites];
  favouritesCopy.push({
    propositionId: propositionId.toString(),
    favourite: true,
    isLoading: false
  });
  state.favourites = favouritesCopy;
}

function removeFavourite(state, { propositionId }) {
  const filteredFavourites = state.favourites.filter(favourite => {
    return favourite.propositionId !== propositionId;
  });

  state.favourites = filteredFavourites;
}

function setLoading(state, { loading }) {
  state.loading = loading;
}

export default {
  setFavourites,
  removeFavourite,
  unmarkFavourite,
  markFavourite,
  setLoading
};
