<template>
  <div :class="['primary-image-container']" @touchstart="initializeImages">
    <BaseImageSlider
      ref="imageSliderRef"
      :proposition-images="propositionImages"
      :carousel-settings="carouselSettings"
      :active-index="sliderPageIndex"
      :after-page-change="afterPageChange"
      @update:activeIndex="updateActiveIndex"
      @swipeDirection="swipeDirection"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseImageSlider from './BaseImageSlider.vue';
import { resizeImage } from '@/utils/helpers/image';
import { lbPropositionApi } from '../../utils/axiosConfig';

export default {
  name: 'SwapListImageSlider',

  components: {
    BaseImageSlider
  },

  props: {
    currentShownResidence: {
      type: Object,
      default: () => {}
    },
    currentResidence: {
      type: Number,
      required: true
    },
    propositionId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      imagesInitialized: false,
      propositionImages: [],
      sliderPageIndex: 0,
      carouselSettings: {
        arrows: false,
        autoplay: false,
        dots: false,
        edgeFriction: 0.35,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true
      },
      direction: ''
    };
  },

  computed: {
    ...mapGetters({
      primaryProposition: 'myPropositions/primaryProposition'
    })
  },

  mounted() {
    this.propositionImages = [
      this.getImageThumbnail(this.currentShownResidence.pictures[0])
    ];

    // Add mocked pictures to render the proper image count on init
    for (let i = 0; i < this.currentShownResidence.imageCount - 1; i++) {
      this.propositionImages.push('');
    }
  },

  methods: {
    async initializeImages() {
      if (this.imagesInitialized === true) {
        return;
      }

      // Fix to make sure that the increment works correctly on the first touch
      if (this.sliderPageIndex === 0) {
        this.sliderPageIndex = 0;
      }

      this.imagesInitialized = true;

      const imagesRequest = await lbPropositionApi.get(
        `/propositions/${this.propositionId}/images/${
          this.currentResidence + 1
        }`
      );

      const images = imagesRequest.data.map(image => {
        return `${this.$t('url_residences', this.$routeLocale)}/800x800/${
          this.propositionId
        }/${image}`;
      });

      this.propositionImages = [
        this.propositionImages[0],
        ...images.splice(1, images.length)
      ];
    },
    getImageThumbnail(image, size = '800x800') {
      return resizeImage(image, size);
    },
    swipeDirection(direction) {
      this.direction = direction;
    },
    afterPageChange(currentIndex) {
      this.sliderPageIndex = currentIndex;
    },
    updateActiveIndex(newIndex) {
      this.sliderPageIndex = newIndex;
    }
  }
};
</script>

<style lang="scss" scoped>
.primary-image-container {
  overflow: hidden;
}
</style>
