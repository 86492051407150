<template>
  <div
    v-if="!isSmall"
    :class="[
      'wrapper',
      { 'block-pulse loading': isLoading, 'placeholder-container': !isLoading }
    ]"
  >
    <div v-if="!isLoading" class="bg-image"></div>
    <p v-if="!isLoading">{{ $t('proposition_missing_image_text') }}</p>
    <div v-if="isLoading && !isMobile" class="desktop-loading">
      <div class="image-loading-area">
        <div class="gallery">
          <div v-for="i in [1, 2, 3]" :key="i" class="gallery-loading"></div>
        </div>
        <div class="large-image-loading-area"></div>
      </div>
      <div class="map-loading-area"></div>
    </div>
  </div>
  <div v-else class="placeholder-small-container">
    <div class="img-small"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ImagePlaceholder',

  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  @media ($desktop) {
    height: 25vw;
    min-height: 370px;
    width: 100%;
    margin-top: 15px;
  }

  @media ($mobile) {
    height: 250px;
    width: 100%;
    background-color: #cfcfcf;
    background-size: contain;
    background-position: center;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    overflow: hidden;
  }
}

.placeholder-container {
  position: relative;
  background-color: #dddcdb;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bg-image {
    @media ($mobile) {
      background-position: 50%;
      background-color: #dddcdb;
      background-size: 50%;
      background-image: url('../../assets/svg/skyline-placeholder-img.svg');
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
      aspect-ratio: 2 / 0.9;
    }
  }

  p {
    font-size: 12px;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 70%;
    z-index: 1;

    @media (min-width: 520px) and (max-width: 690px) {
      font-size: 14px;
      top: 75%;
    }
    @media (min-width: 691px) and (max-width: 768px) {
      font-size: 16px;
      top: 78%;
    }
  }
}

.placeholder-small-container {
  .img-small {
    background-image: url('../../assets/svg/placeholder-img-small.svg');
  }
}

.desktop-loading {
  width: 95%;
  height: 25vw;
  min-height: 370px;
  margin: 0 auto;
  margin-top: 15px;
  max-width: 1600px;
  display: flex;
}

.image-loading-area {
  width: 60%;
  min-width: 60%;
  display: flex;
  justify-content: space-evenly;
}

.map-loading-area {
  max-width: 40%;
  min-width: 40%;
  min-height: 100%;
  background-color: #ddd;
}

.gallery {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gallery-loading {
  width: 100%;
  height: 32.6%;
  border-radius: 5px;
  background-color: #ddd;
}

.large-image-loading-area {
  width: 78%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: #ddd;
}
.loader-image {
  min-height: 100%;
  max-height: 30vw;
  min-width: 54vw;
  margin-right: 1vw;
  background-color: #ddd;
  border-radius: 5px;
}
</style>
