<template>
  <div v-if="isLoading" class="spinner-container">
    <ASpinner />
  </div>
  <div v-else-if="swapDetails">
    <div class="swap-chain-container">
      <SwapOverview
        v-if="!showSwapAlternatives"
        :swap-details="swapDetails"
        :show-action-buttons="false"
        @show-alt-click="showSwapAlternatives = true"
      />
      <SwapAlternatives v-else @alt-selected="showSwapAlternatives = false" />
    </div>
    <Messages
      v-if="!primaryPropositionIsLimited && messengerPropositionIds.length > 0"
      :standalone="true"
      :used-on-page="true"
      :proposition-ids="messengerPropositionIds"
      :user-id="user.userId"
      :is-impersonated="user.isImpersonated"
      :is-mobile="isMobile"
      where="swapDetails"
      mode="plain"
      style="margin-top: 30px"
    />
  </div>
  <div v-else class="broken-swap">
    {{ $t('swap_overview_swap_not_available') }}
  </div>
</template>

<script>
import SwapOverview from '@/components/SwapList/SwapOverview';
import SwapAlternatives from '@/components/SwapList/SwapAlternatives';
import ASpinner from '@/components/atomic/atoms/a-spinner';
import Messages from '@/views/Chat/Messages';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SwapDetails',

  components: {
    SwapOverview,
    SwapAlternatives,
    ASpinner,
    Messages
  },

  props: {
    propositionIdBPart: {
      type: Number,
      required: true
    },
    propositionIdCPart: {
      type: Number,
      required: false,
      default: undefined
    }
  },

  data() {
    return {
      isLoading: false,
      showSwapAlternatives: false
    };
  },

  computed: {
    ...mapGetters({
      swapDetails: 'swapList/swapDetails',
      user: 'app/user',
      isMobile: 'screenSize/isMobile',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited'
    }),

    isDirectSwap() {
      return !this.$route.path.includes('triangle');
    },

    messengerPropositionIds() {
      if (!this.swapDetails || !this.swapDetails.swapParts) return [];

      return this.swapDetails.swapParts.map(sp =>
        parseInt(sp.propositionId, 10)
      );
    }
  },

  watch: {
    propositionId(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getSwapDetails();
      }
    },

    propositionIdBPart(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getSwapDetails();
      }
    },

    propositionIdCPart(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getSwapDetails();
      }
    }
  },

  created() {
    this.getSwapDetails();
  },

  methods: {
    ...mapActions({
      fetchSwapDetails: 'swapList/fetchSwapDetails'
    }),

    async getSwapDetails() {
      await this.fetchSwapDetails({
        propositionId: this.propositionIdBPart,
        propositionId2: this.propositionIdCPart,
        type: this.isDirectSwap ? 'directswap' : 'triangleswap'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.spinner-container {
  height: 5rem;
  width: 5rem;
  margin: 0 auto;

  .spinner {
    border-top-color: $main_blue;
  }
}

.swap-chain-container {
  padding: 0 20px;
}

.broken-swap {
  text-align: center;
  font-size: 1.2rem;
  border: 1px solid rgba(28, 45, 65, 0.15);
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 5%);
  background-color: #f8f8fa;
  border-radius: 5px;
  padding: 12px;
  color: #2c3e50;
  font-style: italic;
}
</style>
