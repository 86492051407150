import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  menuOpen: false,
  notificationCenterOpen: false,
  notifications: {
    unreadMessages: 0,
    newSwaps: 0,
    onboarding: 0,
    pendingPayments: 0
  },
  showAttentionDot: false,
  showMobileTabBar: true,
  stickyTabBar: true,
  chatOwnerId: undefined
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
