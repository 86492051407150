<template>
  <div class="impersonate-banner">
    <div class="impersonate-content">
      <span class="impersonated-as-text">
        Inloggad som <em>{{ user.email }}</em>
      </span>
      <br v-if="isMobile" />
      <a class="impersonated-as-logout" @click="handleImpersonationStop"
        >[Logga ut]</a
      >
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ImpersonateBanner',

  computed: {
    ...mapGetters({
      user: 'app/user',
      admin: 'app/admin',
      isMobile: 'screenSize/isMobile'
    })
  },

  created() {},

  methods: {
    ...mapActions({
      removeImpersonation: 'app/removeImpersonation'
    }),

    async handleImpersonationStop() {
      try {
        const result = await axios.get('/admin/stopimpersonating', {
          headers: {
            Accept: 'text/html,application/xhtml+xml,application/xml'
          },
          withCredentials: true
        });

        if (result.status === 200) {
          this.removeImpersonation();
          window.location.href = new URL(result.request.responseURL).pathname;
        }
      } catch (error) {
        if (error.code === 'ERR_BAD_RESPONSE') {
          this.removeImpersonation();
          window.location.href = '/';
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.impersonate-banner {
  background: #c00;
  color: #fff;
  z-index: 1000000;
  height: 28px;
  position: fixed;
  bottom: 0;
  width: 100%;

  @media ($mobile) {
    height: 90px;
    max-width: 320px;
  }
}

.impersonate-content {
  max-width: 1400px;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media ($mobile) {
    align-items: flex-start;
    flex-direction: column;
    padding: 10px 0;
    display: flex;
  }
}

.impersonated-as-logout {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;

  @media ($desktop) {
    margin-left: 10px;
  }
}

.impersonated-as-logout::before {
  @media ($desktop) {
    content: '|';
    margin-right: 10px;
  }
}
</style>
