import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const MATCH_STATUS_TYPES = {
  UNANSWERED: 'unanswered',
  UNREAD: 'unread',
  UPDATED: 'updated',
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export const matchesInitialState = Object.keys(MATCH_STATUS_TYPES).reduce(
  (acc, key) => {
    acc[MATCH_STATUS_TYPES[key]] = null;
    return acc;
  },
  {}
);

const state = {
  matches: matchesInitialState,
  isLoadingMatches: null,
  isLoadingStatusCounts: null,
  activeTab: MATCH_STATUS_TYPES.UNANSWERED,
  initialMatchStatusCounts: null,
  matchStatusCounts: null
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
