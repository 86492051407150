<template>
  <div :class="['payment-card', { 'overdue-border': showOverdueBorder }]">
    <div v-if="repaid" class="repaid-banner">
      <span class="repaid-banner-content">
        {{ $t('payment_card_repaid') }}
      </span>
    </div>
    <div class="card-content">
      <div class="card-presentation">
        <div
          class="img-placeholder"
          :style="[img && { 'background-image': `url(${img})` }]"
        ></div>
        <div class="card-info">
          <div>
            <p class="primary-text">
              {{ title }}
            </p>
            <p class="date">{{ readableDate }}</p>
            <p class="secondary-text">{{ address }}</p>
            <p v-if="typeof sum === 'number'" class="secondary-text">
              {{ sum }} {{ $t('payment_card_currency_code') }}
            </p>
            <p class="secondary-text">
              {{ $t('payment_card_provider_via') }} {{ provider }}
            </p>
            <a
              v-if="type == 'FakturaBillogra' && invoiceUrl"
              :href="invoiceUrl"
              target="_blank"
              class="link"
              >{{ $t('payment_card_show_invoice') }}</a
            >
            <p v-if="isImpersonated" class="extra-bold red">{{ reference }}</p>
          </div>
          <p :class="['secondary-text', { 'paid-check': paid && !repaid }]">
            {{ paidText }}
          </p>
        </div>
      </div>
      <div v-if="!paid && !isMobile" class="actions-container">
        <div class="selected-status">
          <p
            :class="['status-text', { 'overdue-status': daysFromPayDate < 0 }]"
          >
            {{ paidStatusText }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PaymentCard',

  props: {
    type: {
      type: String,
      default: null
    },
    days: {
      type: Number,
      default: null
    },
    productId: {
      type: Number,
      required: true
    },

    propositionId: {
      type: [Number, String],
      default: null
    },

    sum: {
      type: [Number, String],
      default() {
        return this.$t('payment_card_price_not_available');
      }
    },
    payDate: {
      type: Date,
      required: true
    },
    paid: {
      type: Boolean,
      default: false
    },
    repaid: {
      type: Boolean,
      default: false
    },
    reference: {
      type: String,
      default: ''
    },
    invoiceUrl: {
      type: String,
      default: ''
    },
    friendlyProductName: {
      type: String,
      default: ''
    },
    friendlyPaymentType: {
      type: String,
      default: ''
    }
  },

  computed: {
    img() {
      if (
        !this.proposition ||
        this.proposition.residences[0].images.length === 0
      ) {
        return '';
      }

      return this.proposition.residences[0].images[0].fileName;
    },

    address() {
      if (!this.proposition || !this.proposition.residences[0]) {
        if (this.productId < 1030) {
          return this.$t('payment_history_address');
        } else return '';
      }

      return this.proposition.residences[0].streetAddress;
    },

    proposition() {
      return this.propositions.find(x => x.propositionId == this.propositionId);
    },

    readableDate() {
      return this.payDate.toLocaleDateString(
        this.$country.getLanguageFromCountry()
      );
    },

    showOverdueBorder() {
      return !this.paid && this.daysFromPayDate < 0;
    },

    title() {
      return this.friendlyProductName;
    },

    provider() {
      return this.friendlyPaymentType;
    },

    daysFromPayDate() {
      // The number of milliseconds in one day
      const oneDay = 1000 * 60 * 60 * 24;
      const difference = this.payDate - new Date();
      return Math.ceil(difference / oneDay);
    },

    paidText() {
      if (!this.paid && this.daysFromPayDate < 0) {
        return `${this.$t('payment_card_expired')} ${this.readableDate}`;
      } else if (this.paid) {
        if (this.type === 'Klarna2019' || this.type === 'Adyen') {
          return this.$t('payment_card_handeled_by_provider', {
            provider: this.provider
          });
        } else {
          return `${this.readableDate} - ${this.$t('payment_card_paid')}`;
        }
      } else {
        return `${this.$t('payment_card_pay_latest')} ${this.readableDate}`;
      }
    },

    paidStatusText() {
      if (this.daysFromPayDate >= 0) {
        return `${this.daysFromPayDate} ${this.$t('payment_card_days_left')}`;
      } else {
        return `${this.daysFromPayDate.toString().substring(1)} ${this.$t(
          'payment_card_days_since'
        )}`;
      }
    },

    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      isImpersonated: 'app/isImpersonated',
      propositions: 'myPropositions/propositions'
    })
  }
};
</script>

<style lang="scss" scoped>
.date {
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}

.payment-card {
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  margin: 30px 0;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.09);
  width: 100%;
}

.overdue-border {
  box-shadow: 0 2px 15px rgba(255, 0, 0, 0.6);
}

.repaid-banner {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(256, 256, 256, 0.7);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  z-index: 2;
}

.repaid-banner-content {
  font-size: 16px;
  color: #34c375;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.card-content {
  padding: 18px;
  display: flex;
  justify-content: space-between;

  @media ($mobile) {
    padding: 12px;
    flex-direction: column;
  }
}

.card-presentation {
  display: flex;
}

.img-placeholder {
  height: 138px;
  width: 138px;
  border-radius: 2px;
  background-color: #d2d2d2;
  background-image: url('../assets/svg/placeholder-img-small.svg');
  background-size: cover;
  background-position: center;

  @media ($mobile) {
    min-height: 90px;
    max-height: 90px;
    min-width: 90px;
    max-width: 90px;
  }
}

.card-info {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media ($mobile) {
    padding: 0 10px;
  }
}

.actions-container {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  justify-content: space-between;
  align-items: flex-end;

  @media ($mobile) {
    flex-direction: row;
    margin-top: 25px;
    justify-content: center;
  }
}

.selected-status {
  height: 30px;
  width: 140px;
  border: 1px solid rgba(151, 151, 151, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ($mobile) {
    display: none;
  }
}

.status-text {
  color: #8e8d8f;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  padding-right: 13px;

  &::after {
    content: '';
    position: absolute;
    top: 5px;
    right: 0px;
    height: 8px;
    width: 8px;
    background-color: #34c375;
    border-radius: 50%;
  }
}

.overdue-status {
  &::after {
    background-color: red;
  }
}

.secondary-text {
  margin: 6px 0 0 0;
  padding: 0;
  color: #8e8d8f;
  font-weight: 600;
  font-size: 14px;

  @media ($mobile) {
    margin: 3px 0 0 0;
  }
}

.link {
  margin: 6px 0 0 0;
  padding: 0;
  color: $main-blue;
  font-weight: 600;
  font-size: 14px;

  @media ($mobile) {
    margin: 3px 0 0 0;
  }
}

.paid-check {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    height: 21px;
    width: 21px;
    background-position: center;
    background-size: contain;
    background-image: url('../assets/svg/check-mark-green.svg');
    top: -1px;
    right: -19px;
  }
}

.primary-text {
  margin: 0 0 0 0;
  padding: 0;
  color: #3c3c3f;
  font-weight: 700;
  font-size: 24px;

  @media ($mobile) {
    font-size: 16px;
  }
}

.extra-bold {
  font-weight: 600;
}

.red {
  color: red;
}
</style>
