<template>
  <div class="interest-buttons">
    <div :class="['buttons-wrapper', { 'blur-content': blurContent }]">
      <div
        :class="[
          'interest-btn not-interested',
          { 'is-not-interested': interested === false }
        ]"
        @click.prevent.stop="handleNoClick"
      >
        {{ $t('interest_button_hide') }}
      </div>
      <div
        :class="[
          'interest-btn interested',
          { 'is-interested': interested === true }
        ]"
        @click.prevent.stop="handleYesClick"
      >
        <BaseIcon
          :icon-file="interested ? 'icon-check3-white' : 'icon-check3'"
          :width="14"
          :height="14"
          class="button-icon"
        />
        {{ $t('interest_button_interested') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  TUTORIAL_STATUS_TYPES,
  TUTORIAL_STEPS_TYPES
} from '../../store/modules/tutorial';

export default {
  name: 'SwapListInterestButtonsFreemium',

  props: {
    interested: {
      type: [Boolean, null],
      default: null
    },
    targetPropositionId: {
      type: String,
      required: true
    },
    blurContent: {
      type: Boolean,
      default: false
    },
    context: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      TUTORIAL_STATUS_TYPES,
      TUTORIAL_STEPS_TYPES,
      tutHasMarkedInterest: false
    };
  },

  computed: {
    ...mapGetters({
      tutStatus: 'tutorial/status',
      tutActiveStep: 'tutorial/activeStep',
      tutInterestMarks: 'tutorial/tutInterestMarks'
    }),
    isTutorialLike() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutInterestMarks < 3
      );
    },
    isTutorialFirstLike() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutActiveStep === TUTORIAL_STEPS_TYPES.HIGHLIGHT_SWAP &&
        this.tutInterestMarks === 1
      );
    }
  },

  methods: {
    ...mapActions({
      markInterest: 'interests/markInterest',
      setGlobalModal: 'ui/setGlobalModal',
      incrementTutInterestMarks: 'tutorial/incrementTutInterestMarks'
    }),

    handleNoClick() {
      if (this.isTutorialLike && !this.tutHasMarkedInterest) {
        this.incrementTutInterestMarks(1);
        this.tutHasMarkedInterest = true;
      }

      this.markInterest({
        targetPropositionId: this.targetPropositionId,
        interested: false,
        where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
      });
    },

    handleYesClick() {
      if (this.isTutorialLike && !this.tutHasMarkedInterest) {
        this.incrementTutInterestMarks(1);
        this.tutHasMarkedInterest = true;
      }

      if (this.interested) {
        this.markInterest({
          targetPropositionId: this.targetPropositionId,
          interested: null,
          where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
        });
      } else {
        this.markInterest({
          targetPropositionId: this.targetPropositionId,
          interested: true,
          where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.interest-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 10px;
}

.buttons-wrapper {
  display: flex;
}

.interest-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 7px 12px;
  font-size: 0.75rem;
  font-weight: 600;
  // text-transform: uppercase;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  border-radius: 3px;
  color: $text-primary;
}

.button-icon {
  margin-right: 3px;
}

.not-interested {
  border-radius: 3px 0px 0px 3px;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  border-right: none;
}

.interested {
  border-radius: 0px 3px 3px 0px;
  border: 1px solid #e8e8e8;
}

.is-interested {
  background-color: $transfer-green;
  border-color: $transfer-green;
  color: white;
}

.is-not-interested {
  background-color: $sweet-red;
  border-color: $sweet-red;
  color: white;
}

.blur-content {
  filter: blur(4px);
  pointer-events: none;
  user-select: none;
}
</style>
