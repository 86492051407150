export const isOutsideViewport = function (
  element,
  offset = 0,
  mode = 'under'
) {
  if (!element) return null;

  const rect = element.getBoundingClientRect();
  if (!rect) return null;

  if (mode == 'above') {
    return (
      rect.top - offset >
      (window.innerHeight || document.documentElement.clientHeight)
    );
  } else if (mode == 'under') {
    return rect.top - offset < 0;
  }
};

export const isInViewport = function (element, offset = 0) {
  if (!element) return null;

  const rect = element.getBoundingClientRect();
  if (!rect) return null;

  return (
    rect.top - offset >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const scrollToElement = function (element, offset) {
  if (!element) return;

  const y =
    element.getBoundingClientRect().top + window.scrollY - (offset || 0);
  window.scroll({
    top: y,
    behavior: 'smooth'
  });
};

export default {
  isInViewport,
  scrollToElement
};
