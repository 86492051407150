<template>
  <div class="image-block">
    <img :src="content.Image.formats.large.url" alt="" />
  </div>
</template>

<script>
export default {
  name: 'OneImage',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.image-block {
  margin-bottom: 16px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;

  img {
    width: 100%;
  }
}
</style>
