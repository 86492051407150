<template>
  <div
    ref="container"
    :class="[
      'sponsored-propositions',
      { flexible, 'has-boost': primaryProposition.extraVisibility }
    ]"
  >
    <div class="sponsored-propositions-inner">
      <div class="header">
        <div class="title">
          {{ title }}
        </div>
      </div>
      <div
        ref="scrollBox"
        :class="[
          'slider-items',
          {
            'not-flexible': !flexible,
            flexible: flexible
          }
        ]"
      >
        <SponsoredPropositionsItem
          v-for="proposition in propositions"
          ref="item"
          :key="proposition.propositionId + 'slideritem'"
          :proposition="proposition"
          class="sponsored-propositions-item"
        />
      </div>
      <div v-if="containerWidth < scrollBoxWidth" class="dot-container">
        <div v-if="!isMobile" class="arrow-container" @click="scrollToLeft">
          <BaseIcon icon-file="icon-chevron-left" :width="8" :height="15" />
        </div>
        <div v-if="isMobile" class="dots">
          <div
            v-for="(proposition, index) in propositions"
            :key="proposition.propositionId + 'dot'"
            :class="['dot', { active: currentActive === index }]"
          ></div>
        </div>
        <div v-if="!isMobile" class="arrow-container" @click="scrollToRight">
          <BaseIcon
            icon-file="icon-chevron-left"
            :width="8"
            :height="15"
            class="arrow-right"
          />
        </div>
      </div>
    </div>
    <SponsoredPropositionsCta
      v-if="isAuthenticated && !primaryProposition.extraVisibility"
      :flexible="flexible"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SponsoredPropositionsItem from './SponsoredPropositionsItem';
import SponsoredPropositionsCta from './SponsoredPropositionsCta';

export default {
  name: 'SponsoredPropositions',
  components: {
    SponsoredPropositionsItem,
    SponsoredPropositionsCta
  },

  props: {
    propositions: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    // Horizontal scroll on desktop
    flexible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentActive: 0,
      originX: 0,
      containerWidth: null,
      scrollBoxWidth: null,
      itemWidth: null
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      primaryProposition: 'myPropositions/primaryProposition',
      isAuthenticated: 'app/isAuthenticated'
    }),

    itemsGap() {
      return this.isMobile || this.flexible ? 16 : 48;
    }
  },

  mounted() {
    const { scrollBox, container, item } = this.$refs;
    scrollBox.addEventListener('scroll', () => this.checkScroll());
    this.containerWidth = container.offsetWidth;
    this.scrollBoxWidth = scrollBox.scrollWidth;
    this.itemWidth = item[0].$el.offsetWidth;
  },

  methods: {
    checkScroll() {
      this.currentActive = Math.round(this.$refs.scrollBox.scrollLeft / 343);
    },

    scrollToRight() {
      this.$refs.scrollBox.scrollLeft += this.itemWidth + this.itemsGap;
    },

    scrollToLeft() {
      this.$refs.scrollBox.scrollLeft -= this.itemWidth + this.itemsGap;
    }
  }
};
</script>

<style lang="scss" scoped>
.sponsored-propositions-inner {
  position: relative;
  background-color: #f5e6dc;
  padding-top: 30px;
  padding-bottom: 80px;
  margin: 1rem 0;

  @media ($mobile) {
    width: 100vw;
  }

  @media ($desktop) {
    width: 100%;
    border-radius: 10px;
    padding-bottom: 60px;
  }

  .has-boost & {
    padding-bottom: 25px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
  color: $text-primary;

  @media ($desktop) {
    margin-left: 32px;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

.slider-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, 343px);
  grid-auto-flow: column;
  grid-auto-columns: 343px;
  grid-column-gap: 16px;
  padding: 20px 16px;
  padding-bottom: 0;
  overflow-x: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  user-select: none;

  @media ($mobile) {
    scroll-snap-type: x mandatory;
  }

  @media ($desktop) {
    padding: 20px 32px;

    &:not(.flexible) {
      grid-column-gap: 48px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.sponsored-propositions-item {
  cursor: pointer;
}

.dot-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin-top: -15px;
  margin-bottom: -5px;
  min-height: 35px;

  @media ($desktop) {
    justify-content: space-between;
    padding: 0 32px;
    margin-top: -25px;
  }

  .flexible & {
    @media ($desktop) {
      margin-bottom: 16px;
    }
  }
}

.dots {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.dot {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  margin: 0 3px;
  background-color: #faf4ff;

  &.active {
    background-color: #9f41e9;
  }
}

.arrow-container {
  padding: 10px;
  z-index: 2;
  cursor: pointer;
}

.arrow-right {
  transform: rotate(180deg);
}
</style>
