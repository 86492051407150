<template>
  <span :style="[{ fontSize: `${size}px` }]" class="separator"
    >&#8226;&nbsp;</span
  >
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 5,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.separator {
  vertical-align: middle;
  padding: 2px;
}
</style>
