<template>
  <div class="employees-block">
    <h1 class="heading">{{ content.Heading }}</h1>
    <div class="employees">
      <div
        v-for="employee in content.Employee"
        :key="employee.id"
        class="employee"
      >
        <div class="photo-container">
          <img class="photo" :src="employeeUrl(employee)" alt="" />
        </div>
        <div class="name-and-title">
          <div class="name">{{ employee.Name }}</div>
          <div class="title">{{ employee.Title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Employees',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    employeeUrl(employee) {
      return employee.Image.formats.medium
        ? employee.Image.formats.medium.url
        : employee.Image.formats.small.url;
    }
  }
};
</script>

<style lang="scss" scoped>
.employees-block {
  margin-top: 40px;
  margin-bottom: 32px;
  padding: 0 16px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @media ($desktop) {
    margin-top: 80px;
  }
}

.heading {
  font-family: $font-headings;
  font-weight: 900;
  font-size: 32px;
  line-height: 1.2;
  color: #213975;
  text-align: center;

  @media ($desktop) {
    font-size: 64px;
    margin: 0 10%;
  }
}

.employees {
  display: grid;
  grid-gap: 24px;

  @media ($desktop) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px;
  }
}

.employee {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 24px 40px;

  @media ($desktop) {
    padding: 72px 50px;
  }
}

.photo-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 32px;

  @media ($desktop) {
    margin-bottom: 40px;
  }
}

.photo {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
}

.name-and-title {
  font-family: $font-headings;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.01em;
}

.name {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 16px;

  @media ($desktop) {
    font-size: 24px;
  }
}

.title {
  font-size: 16px;
}
</style>
