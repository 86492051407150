<template>
  <PageModal
    v-if="!!propositionViewModalId"
    :show-modal="!!propositionViewModalId"
    :page-modal-name="'proposition-page'"
    @close="handleCloseModal"
  >
    <ModalNavigation v-if="propositionViewModalType" slot="nav" />
    <PropositionAndSwapDetails
      :proposition-view-modal-id="propositionViewModalId"
      :proposition-view-modal-type="propositionViewModalType"
    />
  </PageModal>
</template>

<script>
import { mapGetters } from 'vuex';

import PageModal from '@/components/Modals/PageModal/PageModal';
import ModalNavigation from '@/components/Modals/PageModal/ModalNavigation';
import PropositionAndSwapDetails from '@/components/Modals/PageModal/PropositionAndSwapDetails';

export default {
  components: {
    PageModal,
    ModalNavigation,
    PropositionAndSwapDetails
  },

  computed: {
    ...mapGetters({
      propositionViewModalId: 'ui/propositionViewModalId',
      propositionViewModalType: 'ui/propositionViewModalType'
    })
  },

  methods: {
    handleCloseModal() {
      this.$router.replace({ propositionPageId: null });
    }
  }
};
</script>

<style lang="scss" scoped></style>
