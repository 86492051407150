<template>
  <div id="proposition-map" class="proposition-map"></div>
</template>

<script>
import { mapGetters } from 'vuex';
import { loadJs, loadCss } from '@/utils/maptiler';

export default {
  name: 'PropositionMap',
  props: {
    residence: {
      type: Object,
      required: true
    },
    coords: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      zoom: 13,
      map: null,
      activeMarker: null,
      ready: false
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  },
  watch: {
    coords(newVal, oldVal) {
      if (
        this.ready &&
        newVal &&
        JSON.stringify(newVal) != JSON.stringify(oldVal)
      ) {
        this.removeMarker();
        this.addMarker();
      }
    }
  },

  async mounted() {
    await Promise.all([loadJs(), loadCss()]);
    this.initializeMap();
  },

  beforeDestroy() {
    if (this.map) {
      try {
        this.map.remove();
      } catch (error) {
        // Do nothing
      }
    }
  },
  methods: {
    initializeMap() {
      const el = document.getElementById('proposition-map');
      if (!el) {
        return;
      }

      const { lng, lat } = this.coords;

      this.map = new maplibregl.Map({
        container: 'proposition-map',
        center: [lng, lat],
        style:
          'https://api.maptiler.com/maps/bc61ba4d-d8c0-49f0-b6c2-52c6004aee6f/style.json?key=1CDi4tTTlJKFFQHgS5zi',
        zoom: this.zoom,
        attributionControl: false,
        logoPosition: 'bottom-right'
      });

      this.addMarker();
      this.map.addControl(
        new maplibregl.NavigationControl({
          showCompass: false
        })
      );

      this.ready = true;
      this.$emit('on-init', el);
    },

    addMarker() {
      const { lng, lat } = this.coords;

      this.activeMarker = new maplibregl.Marker({
        anchor: 'center'
      })
        .setLngLat([lng, lat])
        .addTo(this.map);

      this.map.flyTo({
        center: [lng, lat]
      });
    },

    removeMarker() {
      if (this.activeMarker) {
        this.activeMarker.remove();
      }
    }
  }
};
</script>

<style>
/*
IMPORTANT **********
These styles aren't
scoped due to mapbox implementation,
so rules will apply globally
*/
.proposition-map {
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>
