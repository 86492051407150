import services from './services';
import { subscribe } from '@/utils/socket';
import { log } from '@/utils/logger';

async function init(context) {
  context.dispatch('fetchUnseenNotificationsCount');

  subscribe('reconnect', () => {
    context.dispatch('fetchUnseenNotificationsCount');
  });

  subscribe('new-notification', ({ propositionId }) => {
    if (
      context.rootGetters['myPropositions/primaryPropositionId'] !=
      propositionId
    ) {
      return;
    }

    const isOpened = context.rootGetters['menu/isNotificationCenterOpen'];
    if (isOpened) {
      context.dispatch('fetchNotifications');
    } else if (!context.state.unseenNotificationsCount) {
      context.dispatch('fetchUnseenNotificationsCount');
    }
  });
}

async function fetchUnseenNotificationsCount(context) {
  const propositionId =
    context.rootGetters['myPropositions/primaryPropositionId'];

  if (!propositionId) {
    context.commit('setUnseenNotificationsCount', 0);
    return;
  }

  const unseenNotificationsCount = await services.fetchUnseenNotificationsCount(
    propositionId
  );
  context.commit('setUnseenNotificationsCount', unseenNotificationsCount);
}

async function fetchNotifications(context) {
  context.commit('setLoading', { loading: true });

  const propositionId =
    context.rootGetters['myPropositions/primaryPropositionId'];

  if (!propositionId) {
    context.commit('setNotifications', { notifications: [] });
    context.commit('setUnseenNotificationsCount', 0);
    context.commit('setLoading', { loading: false });
    return;
  }

  try {
    context.commit('setFetchNotificationsFailed', false);

    const notifications = await services.fetchNotifications(propositionId);
    context.commit('setNotifications', { notifications });
    context.commit('setUnseenNotificationsCount', 0);
  } catch (error) {
    context.commit('setFetchNotificationsFailed', true);
    log(
      `Failed to fetch notifications: ${propositionId} | ${error.toString()}`
    );
  }

  context.commit('setLoading', { loading: false });
}

async function markAsRead(context, notificationId) {
  await services.updateReadStatus(notificationId);
}

export default {
  init,
  fetchUnseenNotificationsCount,
  fetchNotifications,
  markAsRead
};
