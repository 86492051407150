<template>
  <div v-if="showButton" class="new-swap-link-wrapper">
    <button class="new-swap-link" @click="scrollToNextNewSwap">
      <BaseIcon
        :icon-file="'arrow-down-streamline'"
        class="arrow-icon"
        :width="12"
        :height="12"
      ></BaseIcon>
      {{ $t('swap_list_next_new_swap') }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NextNewSwapButton',
  data() {
    return {
      showButton: false,
      lastSwapElement: null,
      maxNewSwapCount: 60,
      scrollCounter: 0
    };
  },

  computed: {
    ...mapGetters({
      swaps: 'swapList/swaps',
      newSwapsCount: 'swapList/newSwapsCount'
    })
  },

  watch: {
    newSwapsCount(current) {
      if (this.newSwapsCount >= this.maxNewSwapCount) {
        this.showButton = false;
        return;
      }
      this.showButton = current > 1;
    },
    swaps() {
      this.$nextTick(this.initLastNewSwapElement);
    }
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll);
    if (this.newSwapsCount >= this.maxNewSwapCount) {
      this.showButton = false;
      return;
    }

    setTimeout(() => {
      const { above } = this.getNewSwapElementsRelativeToScreen();
      this.showButton = above.length !== this.newSwapsCount;
    }, 200);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    initLastNewSwapElement() {
      const newSwaps = this.swaps.filter(s => s.details.isNew);
      const lastNewSwap = newSwaps[this.newSwapsCount - 1];
      if (lastNewSwap) {
        const swapContainerRef = this.$parent.$refs[`swap-container-ref`];
        if (swapContainerRef) {
          this.lastSwapElement =
            swapContainerRef.$refs[
              lastNewSwap.propositionId + lastNewSwap.details.type
            ][0];
        }
      }
    },
    onScroll() {
      this.scrollCounter++;
      if (this.scrollCounter < 10) {
        return;
      }

      this.scrollCounter = 0;

      if (!this.lastSwapElement) {
        return;
      }

      if (this.isElementBelowScreenCenter(this.lastSwapElement)) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    isElementBelowScreenCenter(el) {
      var rect = el.getBoundingClientRect();
      return rect.top >= window.innerHeight / 2;
    },
    getNewSwapElementsRelativeToScreen() {
      const swapContainerRef = this.$parent.$refs[`swap-container-ref`];
      const newSwaps = this.swaps.filter(s => s.details.isNew);

      const above = [];
      const below = [];
      if (swapContainerRef) {
        for (const swap of newSwaps) {
          const element =
            swapContainerRef.$refs[swap.propositionId + swap.details.type][0];

          if (this.isElementBelowScreenCenter(element)) {
            below.push(element);
          } else {
            above.push(element);
          }
        }
      }
      return {
        above,
        below
      };
    },
    scrollToNextNewSwap() {
      const waitForMoreSwaps = originalSwapCount => {
        return new Promise(resolve => {
          const waitIval = setInterval(() => {
            if (this.swaps.length > originalSwapCount) {
              clearInterval(waitIval);
              resolve();
            }
          }, 100);
        });
      };

      const { above, below } = this.getNewSwapElementsRelativeToScreen();
      if (above.length >= this.newSwapsCount) {
        this.showButton = false;
        return;
      }

      if (below.length === 0) {
        waitForMoreSwaps(this.swaps.length).then(this.scrollToNextNewSwap);
        scrollTo(0, document.body.scrollHeight);
        return;
      }

      const element = below[0];
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
      element.classList.add('pulse-animation');
      setTimeout(() => {
        element.classList.remove('pulse-animation');
      }, 1500);

      if (above.length + 1 >= this.newSwapsCount) {
        this.scrollCounter = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.new-swap-link-wrapper {
  position: fixed;
  width: 400px;
  left: calc(50% - 200px);
  bottom: 75px;
  z-index: 1;
  text-align: center;

  @media ($desktop) {
    bottom: 25px;
    left: calc(60% - 200px);
  }
}

.new-swap-link {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 50px;
  text-align: center;
  background: #e9efff;
  color: #419fff;
  box-shadow: 0px 0px 0px 1px rgba(44, 62, 80, 0.2);
  border: none;
  transition: all 0.2s ease-in-out;
}

.new-swap-link:hover {
  box-shadow: 0px 0px 0px 1px rgba(44, 62, 80, 0.3);
  background: #dee4f2;
  cursor: pointer;
}

.arrow-icon {
  display: inline-block;
  margin-right: 10px;
}
</style>
