<template>
  <svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>chevron</title>
    <path
      class="chevron-path path"
      d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25"
    />
  </svg>
</template>

<script>
export default {
  name: 'Chevron'
};
</script>

<style lang="scss" scoped>
.chevron-path {
  fill-rule: evenodd;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: currentColor;
}
</style>
