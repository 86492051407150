<template>
  <div ref="stickySidebar">
    <slot></slot>
  </div>
</template>

<script>
import StickySidebar from 'sticky-sidebar-v2';

export default {
  name: 'StickySidebar',
  props: {
    topSpacing: {
      type: Number,
      default: 80
    }
  },
  data() {
    return {
      stickySidebar: null
    };
  },
  mounted() {
    this.stickySidebar = new StickySidebar(this.$refs.stickySidebar, {
      topSpacing: this.topSpacing,
      scrollContainer: '.scroll-wrapper'
    });
  }
};
</script>
