<template>
  <div class="filter-bar-wrapper">
    <AButton
      v-if="
        activeFilterKeys.includes('geolocations') && filter.geolocationsSource
      "
      :is-pill="true"
      :is-small="true"
      :capitalize="true"
      @click="
        setFilterDefaultByKey('searchArea');
        setRootFilter({ map: null });
        setFilter({
          geolocations: [],
          freetext: null,
          geolocationsSource: null
        });
        $emit('search');
      "
    >
      {{ geolocationsSourceShort }}
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('freetext')"
      :is-pill="true"
      :is-small="true"
      :capitalize="true"
      @click="
        setFilterDefaultByKey('freetext');
        setFilterDefaultByKey('searchArea');
        setRootFilter({ map: null });
        $emit('search');
      "
    >
      {{ filter.freetext }}
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('searchArea')"
      :is-pill="true"
      :is-small="true"
      :capitalize="true"
      @click="
        setFilterDefaultByKey('searchArea');
        // setRootFilter({ map: null });
        $emit('search');
      "
    >
      <span class="capitalize">
        {{ $t('general_map_territory') }}
      </span>
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('rooms')"
      :is-pill="true"
      :is-small="true"
      @click="
        setFilterDefaultByKey('rooms');
        $emit('search');
      "
    >
      <strong>
        <span> {{ filter.rooms.min }}</span>
        <span v-if="filter.rooms.max">
          –
          {{
            filter.rooms.max === sliderDefaults.ROOMS.MAX
              ? filter.rooms.max + '+'
              : filter.rooms.max
          }}</span
        >
        <span
          >&nbsp;{{
            $tc('general_rooms_short', Number(filter.rooms.max))
          }}</span
        >
      </strong>
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('sqm')"
      :is-pill="true"
      :is-small="true"
      @click="
        setFilterDefaultByKey('sqm');
        $emit('search');
      "
    >
      <strong>
        <span> {{ filter.sqm.min }}</span>
        <span v-if="filter.sqm.max">
          –
          {{
            filter.sqm.max === sliderDefaults.SQM.MAX
              ? filter.sqm.max + '+'
              : filter.sqm.max
          }}</span
        >
        <span>&nbsp;{{ $t('general_sqm_short') }}</span>
      </strong>
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('rent')"
      :is-pill="true"
      :is-small="true"
      @click="
        setFilterDefaultByKey('rent');
        $emit('search');
      "
    >
      <strong>
        <span> {{ filter.rent.min | formatCurrency(false) }}</span>
        <span v-if="filter.rent.max">
          –
          {{
            filter.rent.max === sliderDefaults.RENT.MAX
              ? formatCurrency(filter.rent.max, false) + '+'
              : formatCurrency(filter.rent.max, false)
          }}
        </span>
        <span>&nbsp; {{ $t('general_currency_short') }}</span>
      </strong>
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('swapTypes') && oneToOneChecked"
      :is-pill="true"
      :is-small="true"
      @click="handleSwapTypeClick(11)"
    >
      {{ $t('general_1_for_1') }}
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('swapTypes') && oneToTwoChecked"
      :is-pill="true"
      :is-small="true"
      @click="handleSwapTypeClick(12)"
    >
      {{ $t('general_1_for_2') }}
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('swapTypes') && twoToOneChecked"
      :is-pill="true"
      :is-small="true"
      @click="handleSwapTypeClick(21)"
    >
      {{ $t('general_2_for_1') }}
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('hasImage')"
      :is-pill="true"
      :is-small="true"
      @click="
        setFilterDefaultByKey('hasImage');
        $emit('search');
      "
    >
      {{ $t('search_filters_choice_4_short') }}
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('hasElevator')"
      :is-pill="true"
      :is-small="true"
      @click="
        setFilterDefaultByKey('hasElevator');
        $emit('search');
      "
    >
      {{ $t('search_filters_choice_5_short') }}
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('hasBalcony')"
      :is-pill="true"
      :is-small="true"
      @click="
        setFilterDefaultByKey('hasBalcony');
        $emit('search');
      "
    >
      {{ $t('search_filters_choice_6_short') }}
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('hasFireplace')"
      :is-pill="true"
      :is-small="true"
      @click="
        setFilterDefaultByKey('hasFireplace');
        $emit('search');
      "
    >
      {{ $t('search_filters_choice_13_short') }}
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('hasBathtub')"
      :is-pill="true"
      :is-small="true"
      @click="
        setFilterDefaultByKey('hasBathtub');
        $emit('search');
      "
    >
      {{ $t('search_filters_choice_14_short') }}
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('floor') && filter.floor === 0"
      :is-pill="true"
      :is-small="true"
      @click="
        setFilterDefaultByKey('floor');
        $emit('search');
      "
    >
      {{ $t('search_filters_choice_7_short') }}
    </AButton>

    <AButton
      v-if="
        activeFilterKeys.includes('floor') && typeof filter.floor === 'object'
      "
      :is-pill="true"
      :is-small="true"
      @click="
        setFilterDefaultByKey('floor');
        $emit('search');
      "
    >
      {{ $t('search_filters_choice_15_short') }}
    </AButton>

    <AButton
      v-if="activeFilterKeys.includes('isBecomingCondo')"
      :is-pill="true"
      :is-small="true"
      @click="
        setFilterDefaultByKey('isBecomingCondo');
        $emit('search');
      "
    >
      {{ $t('search_filters_choice_9') }}
    </AButton>

    <AButton
      :is-small="true"
      :is-transparent="true"
      :classes="['clear-all-button']"
      @click="
        resetFilter();
        $emit('search', { skipAreaSearch: true });
      "
    >
      <span class="clear-all-icon"></span>
      <strong> {{ $t('search_button_clear') }}</strong>
    </AButton>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { formatCurrency } from '@/filters/currency';
import { toggleArrayItem } from '@/utils/helpers/array';
import { searchTypes } from '@/models/search-filter';
import AButton from '@/components/atomic/atoms/a-button';
import sliderConstants from '@/utils/values/slider-filter-constants';

export default {
  name: 'MSearchFilterBar',
  components: { AButton },
  filters: {
    formatCurrency
  },
  data() {
    return {
      searchTypes,
      sliderConstants
    };
  },

  computed: {
    sliderDefaults() {
      return sliderConstants.DEFAULT[this.rootFilter.type.toUpperCase()];
    },

    geolocationsSourceShort() {
      const index = this.filter.geolocationsSource.lastIndexOf(',');
      if (index === -1) return this.filter.geolocationsSource;

      return this.filter.geolocationsSource.substring(0, index);
    },

    oneToOneChecked() {
      return this.rootFilter.swapTypes.includes(11);
    },

    oneToTwoChecked() {
      return this.rootFilter.swapTypes.includes(12);
    },

    twoToOneChecked() {
      return this.rootFilter.swapTypes.includes(21);
    },

    ...mapGetters({
      filter: 'search/filter',
      rootFilter: 'search/rootFilter',
      activeFilterKeys: 'search/activeFilterKeys'
    })
  },

  mounted() {},

  methods: {
    formatCurrency,
    toggleArrayItem,

    handleSwapTypeClick(swapType) {
      if (this.rootFilter.swapTypes.length <= 1) {
        this.setFilterDefaultByKey('swapTypes');
      } else {
        this.setRootFilter({
          swapTypes: this.toggleArrayItem(this.rootFilter.swapTypes, swapType)
        });
      }

      this.$emit('search');
    },

    ...mapActions({
      resetFilter: 'search/resetFilter'
    }),

    ...mapMutations({
      setFilter: 'search/setFilter',
      setRootFilter: 'search/setRootFilter',
      setFilterDefaultByKey: 'search/setFilterDefaultByKey'
    })
  }
};
</script>

<style lang="scss" scoped>
.filter-bar-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 5px 0 5px;
  background-color: $info-gray;
  border-radius: 10px;
  //height: 80px;
}

.filter-bar-wrapper > * {
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

strong {
  font-weight: 600;
}

.clear-all-button {
  color: $transfer-blue;
}

.clear-all-icon {
  height: 20px;
  width: 20px;
  background-position: center;
  background-size: contain;
  background-image: url('../../../assets/svg/trash.svg');
  margin-right: 10px;
}
</style>
