<template>
  <transition name="scale">
    <div class="center login-box-wrapper">
      <div :class="{ overlay: overlay }" @click="$emit('close', $event)"></div>
      <div class="login-box">
        <p class="no-hits mgb-25">
          <slot>
            <strong>{{ $t('search_coverage_not_logged_in') }}.</strong>
          </slot>
        </p>

        <a
          class="full-width"
          :href="`${$routes.LOGIN}?returnUrl=${$routes.SEARCH}?filter=${
            $router.currentRoute.query.filter || ''
          }`"
        >
          <a-button class="is-fullwidth is-secondary is-rounded is-inverted">{{
            $t('general_go_to_login')
          }}</a-button>
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
import outsideClick from '@/directives/outside-click';
import AButton from '@/components/atomic/atoms/a-button';

export default {
  name: 'MSearchLoginBox',
  components: { AButton },

  directives: {
    outsideClick
  },

  props: {
    overlay: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.mgb-25 {
  margin-bottom: 25px;
}

p {
  margin: 0;
}

.login-box-wrapper {
}

.login-box {
  text-align: center;
  background: rgba(241, 242, 246, 0.8);
  backdrop-filter: blur(4px);
  width: 350px;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  z-index: 3000;
}

.full-width {
  width: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  background-color: tansparent;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
</style>
