<template>
  <div>
    <spinner v-if="loading" />

    <div v-else>
      <div ref="scrollBox" class="swap-alternatives">
        <div
          v-for="alternative in filteredAlternatives"
          :key="`${alternative.swapParts[2].propositionId}-${alternative.swapParts[1].propositionId}`"
          class="alternative"
          @click="() => handleSelectThirdParty(alternative)"
        >
          <div
            class="alternative-img"
            :style="getImageStyle(alternative.swapParts)"
          />
          <div class="alternative-info">
            <div class="alternative-city">
              {{ getCity(alternative.swapParts) }}
            </div>
            <div class="alternative-address">
              {{ getAddress(alternative.swapParts) }}
            </div>
            <div v-if="isImpersonated" class="score-text">
              Score: {{ getScore(alternative.swapParts) }}
            </div>
            <div class="alternative-interests">
              <interest-indicators
                :interests="getInterests(alternative.swapParts)"
              />
            </div>
          </div>
        </div>
        <pagination
          v-if="totalPages > 1"
          style="margin-top: 30px"
          :max-page="totalPages"
          :current-page="currentPage"
          :on-page-click="page => handleSelectPage(page)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SWAP_VIEW_TABS } from '../../store/modules/swapList';
import InterestIndicators from './InterestIndicators';
import Pagination from '../Pagination/Pagination';
import Spinner from '../Loading/Spinner';

export default {
  name: 'SwapAlternatives',

  components: {
    InterestIndicators,
    Pagination,
    Spinner
  },

  data() {
    return {
      SWAP_VIEW_TABS
    };
  },

  computed: {
    ...mapGetters({
      alternatives: 'swapList/swapAlternatives',
      currentPage: 'swapList/swapAlternativesCurrentPage',
      totalPages: 'swapList/swapAlternativesTotalPages',
      swapDetails: 'swapList/swapDetails',
      loading: 'swapList/swapAlternativesLoading',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      isImpersonated: 'app/isImpersonated'
    }),

    filteredAlternatives() {
      return this.alternatives.filter(
        alternative =>
          this.swapDetails &&
          alternative.swapParts[2].propositionId !==
            this.swapDetails.swapParts[2].propositionId
      );
    },

    currentPropositionId() {
      return (
        this.$route.params.otherUserPropositionId || this.primaryPropositionId
      );
    }
  },
  mounted() {
    const { scrollBox } = this.$refs;
    if (scrollBox)
      scrollBox.addEventListener('touchend', this.stopTouchEventPropagation);
  },

  beforeDestroy() {
    const { scrollBox } = this.$refs;
    if (scrollBox)
      scrollBox.removeEventListener('touchend', this.stopTouchEventPropagation);
  },
  methods: {
    ...mapActions({
      setCurrentSwapViewTab: 'swapList/setCurrentSwapViewTab',
      fetchSwapAlternatives: 'swapList/fetchSwapAlternatives',
      selectThirdParty: 'swapList/selectThirdParty'
    }),

    handleGoBack() {
      this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP });
    },

    handleSelectThirdParty(alternative) {
      this.selectThirdParty({ alternative });
      this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP });
      this.$emit('alt-selected');
    },

    getImageStyle(swapParts) {
      if (swapParts[2].residences[0].pictures) {
        return {
          backgroundImage: `url(${swapParts[2].residences[0].pictures[0]})`,
          opacity: 1,
          backgroundSize: 'cover'
        };
      }

      return '';
    },

    getInterests(swapParts) {
      return swapParts.map(sp => sp.interested);
    },
    getCity(swapParts) {
      return swapParts[2].residences[0].postalArea;
    },
    getAddress(swapParts) {
      return swapParts[2].residences[0].streetAddress;
    },
    getScore(swapParts) {
      return swapParts[2].score;
    },

    handleSelectPage(page) {
      const userPropositionId = this.swapDetails.swapParts[0].propositionId;
      const propositionId = this.swapDetails.swapParts[1].propositionId;
      this.fetchSwapAlternatives({ page, userPropositionId, propositionId });
    },

    stopTouchEventPropagation(e) {
      e.stopPropagation();
    }
  }
};
</script>

<style lang="scss" scoped>
.swap-alternatives {
  @media ($desktop) {
    max-height: 85vh;
    overflow-y: auto;
  }
}

.header {
  display: flex;
  padding: 8px 0;
  align-items: center;
}
.back-icon {
  height: 16px;
  width: 16px;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/svg/arrow-down-black.svg');
  transform: rotate(90deg);
}
.back-text {
  font-weight: 700;
  margin-left: 8px;
}

.alternative {
  padding: 8px 0;
  border-top: 1px solid #ddd;
  display: flex;

  &-img {
    height: 70px;
    min-height: 70px;
    width: 70px;
    min-width: 70px;
    background-color: #f2f2f2;
    background-size: contain;
    background-position: center;
    background-image: url('../../assets/svg/skyline-placeholder-img.svg');
    margin-right: 10px;
    border-radius: 5px;
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &-address {
    font-weight: 600;
    margin: 5px 0;
  }

  &-city {
    color: $text-secondary;
    font-size: 0.9rem;
    font-weight: 600;
  }
}

.score-text {
  color: red;
  font-weight: 600;
}
</style>
