<template>
  <div class="swap-info">
    <p v-if="isTriangle" class="info-content">
      {{ $t('swap_info_is_triangle') }}
    </p>

    <p v-else-if="isManual" class="info-content">
      {{ $t('swap_info_is_manual1') }}
      <br /><br />
      {{ $t('swap_info_is_manual2') }}
    </p>

    <p v-else class="info-content">
      {{ $t('swap_info_is_direct') }}
    </p>

    <primary-button
      style="margin-top: 30px"
      :full-width="true"
      @click.native="close"
      >{{ $t('swap_info_action') }}</primary-button
    >
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PrimaryButton from '../Buttons/Primary';
import { SWAP_VIEW_TABS } from '../../store/modules/swapList';

export default {
  name: 'SwapInfo',

  components: {
    PrimaryButton
  },

  props: {
    swapDetails: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      SWAP_VIEW_TABS: SWAP_VIEW_TABS
    };
  },

  computed: {
    isTriangle() {
      return this.swapDetails && this.swapDetails.type === 'triangleswap';
    },
    isManual() {
      return this.swapDetails && this.swapDetails.type === 'manual';
    }
  },

  methods: {
    ...mapActions({
      setCurrentSwapViewTab: 'swapList/setCurrentSwapViewTab'
    }),
    close() {
      this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP });
    }
  }
};
</script>

<style lang="scss" scoped>
.info-content {
  font-size: 1rem;
  color: $text-primary;
  font-weight: 600;
  line-height: 150%;
  text-align: center;

  @media ($desktop) {
    font-size: 0.9rem;
  }
}
</style>
