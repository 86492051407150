<template>
  <div class="welcome-wrapper">
    <div ref="greetingText" class="greeting-text">
      {{ $t('start_info_greet') }}
    </div>
    <div class="info-list-wrapper">
      <ul class="info-list">
        <li ref="infoListItem1" class="info-list-item">
          <BaseIcon
            icon-file="icon-stars-black"
            :width="32"
            :height="32"
            class="info-list-icon"
          />
          <div class="title-and-description">
            <div class="title" v-html="$t('start_info_1_title')" />
            <div
              class="description"
              v-html="$t('start_info_1_description', { propositionsSiteCount })"
            />
          </div>
        </li>
        <li ref="infoListItem2" class="info-list-item">
          <BaseIcon
            icon-file="icon-plus-one-black"
            :width="28"
            :height="28"
            class="info-list-icon"
          />
          <div class="title-and-description">
            <div class="title" v-html="$t('start_info_2_title')" />
            <div class="description" v-html="$t('start_info_2_description')" />
          </div>
        </li>
        <li ref="infoListItem3" class="info-list-item">
          <BaseIcon
            icon-file="icon-crown-black"
            :width="32"
            :height="32"
            class="info-list-icon"
          />
          <div class="title-and-description">
            <div class="title" v-html="$t('start_info_3_title')" />
            <div class="description" v-html="$t('start_info_3_description')" />
          </div>
        </li>
      </ul>
    </div>
    <div ref="ctaButton">
      <BaseButton class="cta-button" @click="handleClose($event)">
        {{ $t('start_info_cta') }}</BaseButton
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'FreemiumStartInfo',
  props: {
    propositionsSiteCount: {
      type: String,
      default: '0'
    }
  },
  computed: {
    ...mapGetters({
      user: 'app/user'
    })
  },
  methods: {
    handleClose(event) {
      this.$emit('close', event);
    }
  }
};

import { mapGetters } from 'vuex';
</script>

<style lang="scss" scoped>
$blue-color: #409fff;

.welcome-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 90%;
  width: 100%;
  z-index: 3;

  & .status-heading-text {
    margin-bottom: 30px;
  }

  @media ($mobile) and (orientation: landscape) {
    & .status-heading-text {
      margin: 10px 0;
    }
  }
}

.info-list-wrapper {
  display: flex;
  justify-content: center;
  user-select: none;

  @media ($desktop) {
    margin: 20px;
  }
}

.info-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  margin: 15px;
  padding: 0;
  width: 80%;

  & .title-and-description {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .title {
      font-weight: 600;
    }

    & .description {
      font-size: 14px;
      font-weight: 600;
      color: #878b9d;
      margin-top: 6px;
    }
  }

  @media ($desktop) {
    gap: 30px;
  }

  @media ($mobile) and (orientation: landscape) {
    overflow: auto;
    height: 130px;
    margin: 30px;
    padding-top: 110px;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 0;
    /* Fade content to white on the bottom */
    mask-image: linear-gradient(to bottom, white 80%, transparent);
    scroll-behavior: smooth;
  }
}

.cta-button {
  border-radius: 8px;
  border: 1px solid $blue-color;
  background: $blue-color;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.info-list-item {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  opacity: 1;

  @media ($mobile) and (orientation: landscape) {
    margin-bottom: 15px;
  }

  & .info-list-icon {
    min-width: 32px;
  }
}

.greeting-text {
  display: flex;
  position: relative;
  justify-content: center;
  color: #2c3e50;
  margin: 10px;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.6px;
  text-align: center;

  @media ($mobile) and (orientation: landscape) {
    margin: 5px;
    top: 5px;
  }

  @media ($desktop) {
    font-size: 1.875rem;
  }
}

.cta-button {
  border-radius: 8px;
  border: 1px solid $blue-color;
  background: $blue-color;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.slide-fade-from-bottom {
  animation: slide-fade-from-bottom 1s cubic-bezier(0.35, 1, 0.45, 1) both;
}

.slide-in-from-left {
  animation: slide-in-from-left 1.5s cubic-bezier(0.35, 1, 0.45, 1) both;
  animation-delay: 0.5s;
}

@keyframes slide-fade-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-in-from-left {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
