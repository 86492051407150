import { lbApiBeta, paymentApi } from '../../../utils/axiosConfig';

async function isFirstTimePayment(ctx, userId) {
  try {
    const response = await lbApiBeta.get(
      `/api/payment/IsFirstTimePayment?userid=${
        userId || ctx.rootState.app.user.userId
      }`
    );

    return response.data.Object;
  } catch (error) {
    console.error(error);
  }
}

async function fetchPaymentHistory(context) {
  try {
    context.commit('setLoading', { loading: true });

    const { data } = await paymentApi.get('payment-history');
    const paymentHistory = data;

    context.commit('setPaymentHistory', { payments: paymentHistory });
    context.commit('setLoading', { loading: false });
  } catch (e) {
    window.Logger.log(e);
    throw Error('Could not load payment history');
  }
}

export default {
  fetchPaymentHistory,
  isFirstTimePayment
};
