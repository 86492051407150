<template>
  <!-- TrustBox widget -->
  <div v-if="trustBoxEnabled">
    <slot name="header" />
    <div
      ref="trustbox"
      class="trustpilot-widget"
      :data-locale="$t('locale_language')"
      :data-template-id="trustboxType"
      :data-businessunit-id="trustpilotBusinessUnitId"
      :data-style-height="trustboxHeight"
      data-stars="4,5"
      data-style-width="100%"
      :data-theme="theme"
      data-font-family="Open Sans"
    >
      <a
        href="https://se.trustpilot.com/review/lagenhetsbyte.se"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
  </div>
  <!-- End TrustBox widget -->
</template>

<script>
import { getValue } from '@/plugins/country/module';

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    darkMode: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    trustBoxEnabled() {
      return window.hasOwnProperty('Trustpilot');
    },

    theme() {
      return this.darkMode ? 'dark' : 'light';
    },

    trustpilotBusinessUnitId() {
      return getValue('TRUSTPILOT');
    },

    trustboxType() {
      switch (this.type) {
        // Reviews
        case 'Slider':
          return '54ad5defc6454f065c28af8b';

        // Banners
        case 'Horizontal':
          return '5406e65db0d04a09e042d5fc';
        case 'Micro Button':
          return '5419b732fbfb950b10de65e5';
        case 'Micro Combo':
          return '5419b6ffb0d04a076446a9af';
        case 'Micro Trustscore':
          return '5419b637fa0340045cd0c936';
        case 'Starter':
          return '5613c9cde69ddc09340c6beb';
        case 'Micro Star':
          return '5419b732fbfb950b10de65e5';
        case 'Mini':
        default:
          return '53aa8807dec7e10d38f59f32';
      }
    },

    trustboxHeight() {
      switch (this.type) {
        // Reviews
        case 'Slider':
          return '240px';

        // Banners
        case 'Horizontal':
          return '28px';
        case 'Micro Button':
          return '150px';
        case 'Micro Combo':
        case 'Micro Trustscore':
          return '20px';
        case 'Starter':
          return '150px';
        case 'Micro Star':
          return '24px';
        case 'Mini':
        default:
          return '150px';
      }
    }
  },

  mounted() {
    if (this.trustBoxEnabled) {
      const trustbox = this.$refs.trustbox;
      window.Trustpilot.loadFromElement(trustbox);
    }
  }
};
</script>
