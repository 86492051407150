function getFavourites(state) {
  return state.favourites;
}

function isLoading(state) {
  return state.loading;
}

export default {
  getFavourites,
  isLoading
};
