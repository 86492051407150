<template>
  <div class="button-wrapper">
    <button class="social-login-btn" @click="$emit('click')">
      <slot />
      <span class="btn-text">{{ btnText }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SocialLoginButton',
  props: {
    btnText: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  .social-login-btn {
    width: 100%;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: white;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex: 1 1 0%;
    justify-content: center;
    text-align: center;
    .btn-text {
      flex: 1;
    }
  }

  .social-login-btn:hover {
    background: #fafafa;
    cursor: pointer;
  }

  .social-login-btn svg {
    width: 20px;
    height: 20px;
    @media ($mobile) {
      margin-right: auto;
    }
  }
}
</style>
