<template>
  <div class="sticky-container block block-pulse" />
</template>

<script>
export default {
  name: 'PropositionInterestBoxLoader'
};
</script>
<style lang="scss" scoped>
.sticky-container {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 100px;
  width: 300px;
  min-width: 300px;
  height: 150px;
  min-height: 170px;
  height: fit-content;
  display: flex;
  margin-top: 20px;
  border-radius: 8px;
  margin-left: 15px;
}
</style>
