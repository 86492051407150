<template>
  <div class="headings-and-text">
    <h1 class="title">{{ content.Title }}</h1>
    <h2 class="subtitle">{{ content.Subtitle }}</h2>
    <div class="body-text editor-content" v-html="content.Bodytext"></div>
  </div>
</template>

<script>
export default {
  name: 'HeadingsAndText',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.headings-and-text {
  margin-top: 60px;
  margin-bottom: 32px;
  padding: 0 16px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @media ($small-screen) {
    margin-top: 40px;
  }
}

.title {
  font-family: $font-headings;
  font-weight: 900;
  font-size: 32px;
  line-height: 1.2;
  color: #213975;
  text-align: center;

  @media ($desktop) {
    font-size: 64px;
    padding: 0 10%;
  }
}

.subtitle {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 400;
  text-align: center;
  color: #878b9d;
  margin-bottom: 40px;

  @media ($desktop) {
    font-size: 24px;
    margin-bottom: 80px;
  }
}

.body-text {
  @media ($desktop) {
    column-count: 2;
    column-gap: 30px;
  }
}

.body-text > ::v-deep * {
  break-inside: avoid-column;
}
</style>
