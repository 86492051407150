<template>
  <div class="search-wrapper">
    <div class="search-box" :class="{ large: isLarge }">
      <div class="left">
        <span v-if="icon" class="search-icon-wrapper">
          <AImage :url="icon" :classes="['search-left-icon']"
        /></span>
      </div>
      <span v-if="icon" class="search-icon-separator"></span>

      <AInput
        class="search-input"
        :value="value"
        :settings="{
          ...settings,
          type: 'text'
        }"
        v-on="$listeners"
      />

      <div class="right">
        <transition name="fade" mode="out-in">
          <div v-if="isLoading" class="spinner">
            <ASpinner />
          </div>

          <div
            v-if="!isLoading && value && value.length > 0"
            class="clear-search"
            @click="$emit('clear')"
          />
        </transition>
      </div>
      <slot v-if="!isLoading"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import icons from '@/utils/icon-constants';

import AInput from '@/components/atomic/atoms/a-input';
import ASpinner from '@/components/atomic/atoms/a-spinner';

import AImage from '@/components/atomic/atoms/a-image';

export default {
  name: 'MSearchInput',
  components: {
    AInput,
    ASpinner,
    AImage
  },
  props: {
    value: {
      type: String,
      default: '',
      required: false
    },

    settings: {
      type: Object,
      default: () => {
        return {};
      },
      required: false
    },

    classes: {
      type: Array,
      required: false,
      default: () => []
    },

    styles: {
      type: Array,
      required: false,
      default: () => []
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },

    icon: { type: String, required: false, default: '' },

    isLarge: { type: Boolean, default: false }
  },
  data() {
    return {
      icons: icons
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  }
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  @media ($mobile) {
    width: 100%;
    padding: 0;
  }
}

.search-box {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 10px 12px 10px 10px;
  border-radius: 10px;
  border: 1px solid #d6dae9;
  background-color: $form-background-light;
}

.search-input {
  background: transparent;
  font-size: 14px;
  font-weight: normal;
  border: none;
  outline: none;
  width: 100%;
  padding-left: 0;
}

.search-icon {
  height: 16px;
  width: 16px;
  background-position: center;
  background-size: contain;
  background-image: url('../../../assets/svg/byteslista-search.svg');
}

.clear-search {
  cursor: pointer;
  height: 14px;
  width: 14px;
  background-position: center;
  background-size: contain;
  background-image: url('../../../assets/svg/icons/close.svg');
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}

#spinner {
  border-color: rgba(98, 163, 248, 0.3) !important;
  border-top-color: $transfer-blue !important;
  border-width: 2px !important;
}

.search-icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.search-left-icon {
  display: inline-flex;
  height: 18px;
  width: 18px;
  background-position: center;
  background-size: contain;
}

.search-icon-separator {
  margin: 0 15px;
  height: 15px;
  border-left: 1px solid #e8e8e8;
}

.right {
  min-width: 14px;
}

.large {
  height: 60px;
  padding: 12px 12px 10px 12px;
}
</style>
