<template>
  <div class="edit-description-container">
    <div class="edit-description">
      <BaseBackButton :link="editPropositionPath" class="back-button" />
      <h1 class="page-heading">{{ $t('edit_description_title') }}</h1>
      <p>
        {{ $t('edit_description_desc1') }}
      </p>
      <p class="sub-text">
        {{ $t('edit_description_desc2') }}
      </p>
      <BaseTextArea
        v-model="textareaDescription"
        name="desc"
        cols="30"
        :placeholder="$t('edit_description_placeholder')"
        :rows="isMobile ? 10 : 15"
        :valid="!(hasContactInfo && hasTriedToSave)"
        :error-message="$t('edit_description_error_contact_info')"
        :highlighted-words="[]"
        @onChange="handleInput"
      />
      <div class="button-container">
        <BaseButton :disabled="textAreaHasErrors" @click="handleSaveClick">
          <template v-if="isSaving">{{
            $t('edit_user_credentials_loading')
          }}</template>
          <template v-else>{{
            $t('edit_user_credentials_save')
          }}</template></BaseButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { lbApiBeta } from '../../utils/axiosConfig';
import formValidations from '../../utils/formValidations';

export default {
  name: 'EditDescription',

  data() {
    return {
      propositionId: null,
      residenceIndex: null,
      proposition: null,
      textareaDescription: '',
      isSaving: false,
      hasTriedToSave: false,
      textAreaHasErrors: false,
      hasContactInfo: false
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('edit_description_title')
      };
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      userPropositions: 'myPropositions/propositions'
    }),

    editPropositionPath() {
      return this.$routes.EDIT_PROPOSITION + '/' + this.propositionId;
    }
  },

  watch: {
    textareaDescription(newValue) {
      this.hasContactInfo = formValidations.residenceDescription(
        newValue.trim()
      );
    }
  },

  created() {
    this.propositionId = this.$route.params.propositionId;
    this.residenceIndex = this.$route.params.residenceIndex;

    window.scrollTo(0, 0);

    this.getDescription();
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast'
    }),

    handleInput() {
      if (this.hasTriedToSave) {
        this.textAreaHasErrors = this.hasContactInfo;

        if (!this.hasContactInfo) {
          this.hasTriedToSave = false;
        }
      }
    },

    handleSaveClick() {
      this.hasTriedToSave = true;

      if (this.hasContactInfo) {
        this.textAreaHasErrors = true;

        this.isSaving = false;
      } else {
        this.textAreaHasErrors = false;
        this.isSaving = true;
        this.updateDescription();
      }
    },

    async getDescription() {
      try {
        const { data } = await lbApiBeta.get(
          `/api/v2/proposition/${this.propositionId}/residence/${this.residenceIndex}/description`
        );

        this.textareaDescription = data;
      } catch (e) {
        console.log(e);
      }
    },

    async updateDescription() {
      await lbApiBeta
        .post(
          `/api/v2/proposition/${this.propositionId}/residence/${this.residenceIndex}/description`,
          this.textareaDescription
        )
        .then(() => {
          this.setToast({ title: this.$t('toast_changes_saved') });

          this.$router.push(this.editPropositionPath);
        })
        .catch(() => {
          this.setToast({
            title: this.$t('toast_general_error_message'),
            timer: 5
          });
        })
        .then(() => {
          this.isSaving = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-description-container {
  max-width: none;
  width: 100%;
  background-color: #f8f8fa;
  min-height: 100vh;
}

.edit-description {
  padding: 0 10px;

  @media ($small-desktop) {
    width: 96%;
  }

  @media ($desktop) {
    max-width: 700px;
    width: 90%;
    margin: 0 auto;
  }
}

.edit-description .back-button {
  margin-left: -5px;
  margin-top: 20px;

  @media ($desktop) {
    margin-left: 0;
  }
}

.page-heading {
  margin-top: 0;
  font-size: 28px;

  @media ($mobile) {
    margin-top: 24px;
    font-size: 26px;
  }
}

.sub-text {
  margin-bottom: 30px;
}

textarea {
  width: 100%;
  padding: 10px 12px;

  @media ($desktop) {
    padding: 20px;
  }
}

.button-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 200px;

  @media ($desktop) {
    margin-bottom: 50px;
  }
}
</style>
