<template>
  <div class="cancel-step-wrapper">
    <spinner v-if="changeSubscriptionLoading" />
    <h2 v-if="offerType === 'SAVE'" class="title">
      {{ $t('offer_title_save') }}
    </h2>
    <h2 v-else class="title">{{ $t('offer_title') }}</h2>

    <p v-if="offer1" class="subtitle">{{ offer1.subtitle }}</p>

    <div v-if="offer1" class="offer">
      <div v-if="offer1.isCheapest" class="cheapest-tag">
        {{ $t('offer_tag') }}
      </div>
      <div class="offer-price-wrapper">
        <div class="offer-days">{{ offer1.offerTitle }}</div>
        <div class="offer-price">{{ offer1.subscriptionPrice }}</div>
      </div>

      <div class="offer-info">
        <span v-if="offer1.savings">
          {{ $t('offer_savings', { savings: offer1.savings }) }}
        </span>
        <span>
          {{ $t('offer_payment_period', { offerDays: offer1.offerDays }) }}
        </span>
      </div>

      <div class="offer-button-wrapper">
        <primary-button
          class="offer-button"
          :accent="true"
          @click.native="
            () =>
              changeSubscription({
                newPrice: offer1.subscriptionPrice,
                productId: offer1.productId,
                currentProductId,
                changeContext
              })
          "
          >{{ $t('offer_action') }}</primary-button
        >
      </div>
    </div>

    <div v-if="offer2" class="offer">
      <div class="offer-price-wrapper">
        <div class="offer-days">{{ offer2.offerTitle }}</div>
        <div class="offer-price">{{ offer2.subscriptionPrice }}</div>
      </div>

      <div class="offer-info">
        <span v-if="offer2.savings">
          {{ $t('offer_savings', { savings: offer2.savings }) }}
        </span>
        <span>{{
          $t('offer_payment_period', { offerDays: offer2.offerDays })
        }}</span>
      </div>

      <div class="offer-button-wrapper">
        <primary-button
          class="offer-button"
          :accent="true"
          @click.native="
            () =>
              changeSubscription({
                newPrice: offer2.subscriptionPrice,
                productId: offer2.productId,
                currentProductId,
                changeContext
              })
          "
          >{{ $t('offer_action') }}</primary-button
        >
      </div>
    </div>

    <div v-if="offerType === 'SAVE'" class="button-wrapper">
      <link-button @click.native="handleRejectOffer">{{
        $t('offer_reject')
      }}</link-button>
      <link-button @click.native="closeFunction">{{
        $t('offer_cancel_and_back')
      }}</link-button>
    </div>

    <div v-else class="button-wrapper">
      <link-button @click.native="closeFunction">{{
        $t('offer_cancel')
      }}</link-button>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '../../Buttons/Primary';
import LinkButton from '../../Buttons/Link';
import Spinner from '../../Loading/Spinner';
import { mapGetters } from 'vuex';

export default {
  name: 'CancelSubscriptionOffer',

  components: {
    LinkButton,
    PrimaryButton,
    Spinner
  },

  props: {
    changeSubscription: {
      type: Function,
      required: true
    },
    offerType: {
      type: String,
      required: true
    },
    offers: {
      type: Object,
      required: true
    },
    changeSubscriptionLoading: {
      type: Boolean,
      required: true
    },
    goToStep: {
      type: Function,
      required: true
    },
    setSpecifiedCancelCategory: {
      type: Function,
      required: true
    },
    closeFunction: {
      type: Function,
      required: true
    }
  },

  computed: {
    offer1: function () {
      return this.offers.offer1;
    },
    offer2: function () {
      return this.offers.offer2;
    },
    ...mapGetters({
      currentSubscription: 'handleSubscription/currentSubscription'
    }),
    currentProductId() {
      return this.currentSubscription.productId;
    },
    changeContext() {
      return this.offerType === 'SAVE' ? 'cancel' : 'change';
    }
  },

  methods: {
    handleRejectOffer: function () {
      this.setSpecifiedCancelCategory({
        category: undefined,
        reasonIndex: undefined
      });
      this.goToStep({ step: 'feedback', feedbackType: 'COST' });
    }
  }
};
</script>

<style lang="scss" scoped>
.cancel-step-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  @media ($mobile) {
    padding-bottom: 50px;
  }
}

.button-wrapper {
  margin: 30px 0;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 10px;
  }
}

.title {
  color: $text-primary;
  @media ($mobile) {
    text-align: center;
    font-size: 1.9rem;
    margin-top: 20px;
  }

  @media ($desktop) {
    margin-top: 40px;
    font-size: 1.8rem;
  }
}

.subtitle {
  font-size: 1.4rem;
  color: $black;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
  @media ($mobile) {
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
}

.offer-wrapper {
  width: 100%;
  margin: 30px 0;
  max-width: 500px;
  // border: 1px solid $text-secondary;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.offer-title {
  font-size: 1.4rem;
  font-weight: 600;
}

.offer-text {
  color: white;
  z-index: 99;
  font-weight: 700;
  font-size: 1.1rem;
  transform: rotate(15deg);
}

.subscription-info {
  margin: 5px 0;
  font-size: 1.1rem;
}

.offer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.17);
  border-radius: 8px;
  padding: 40px 20px;
  margin-top: 15px;
  width: 100%;
  max-width: 1000px;
  position: relative;

  &:first-of-type {
    margin-top: 60px;
  }

  @media ($mobile) {
    flex-direction: column;
  }

  &-price-wrapper {
    min-width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media ($desktop) {
      padding-right: 20px;
      &::after {
        position: absolute;
        content: '';
        height: 100%;
        width: 1px;
        background-color: rgba(0, 0, 0, 0.17);
        top: 0;
        right: 15px;
      }
    }
  }

  &-days {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &-price {
    margin-top: 9px;
    font-weight: 600;
  }

  &-info {
    font-weight: 600;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 48px 0 0;
    line-height: 1.3rem;

    @media ($mobile) {
      margin: 25px 0;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-size: 0.9rem;
      line-height: 1.3rem;
    }
  }

  &-button {
    min-width: 260px;
    padding: 0px 5px;
  }
}

.cheapest-tag {
  position: absolute;
  top: -31px;
  left: 50px;
  height: 30px;
  background-color: #f5fcf8;
  border: 1px solid $accent-green;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $accent-green;
  padding: 0 25px;
  font-weight: 700;
  font-size: 0.8rem;

  @media ($mobile) {
    left: calc(50% - 80px);
  }
}
</style>
