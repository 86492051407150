<template>
  <div class="proposition-description-wrapper block-pulse">
    <p class="proposition-description-content block">
      blocktext
      blocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktextblocktext
    </p>
  </div>
</template>

<script>
export default {
  name: 'PropositionDescription'
};
</script>

<style lang="scss" scoped>
.proposition-description-content {
  line-height: 1.5;
  font-size: 16px;
}
</style>
