<template>
  <NoActiveModal
    v-if="ready && !primaryPropositionActive"
    :payment-page-proposition-id="primaryPropositionId"
    :in-free-area="primaryPropositionFreeArea"
    source="swap-list"
  />

  <div v-else class="swaps-page-container">
    <div class="swaps-page">
      <div class="swaps-page-header">
        <InfoBanner v-if="isMobile" class="info-banner" />

        <div v-if="!isMobile" class="my-swaps-title">
          {{ $t('swaps_title') }}
        </div>

        <SwapMenu class="swap-menu" />

        <div v-if="isMobile" class="results-and-toggle">
          <div class="results-info">
            <div class="results-title">{{ pageTitleAndDescription.title }}</div>
            <div class="results-text">
              {{ pageTitleAndDescription.description }}
            </div>
          </div>
        </div>
      </div>
      <div class="main-content">
        <InfoBanner v-if="!isMobile" class="info-banner" />

        <SwapHeader
          v-if="!isMobile && swapListType === SWAP_LIST_TYPES.LIST"
          :show-filters="swapListType === SWAP_LIST_TYPES.LIST"
          :is-swap-list="false"
          :page-title="pageTitleAndDescription.title"
          style="margin-top: -20px"
        />

        <LoaderContainer
          v-if="shouldShowLoadingContainer"
          :progress="progress"
          :is-mobile="isMobile"
          :swap-item-mode="swapItemMode"
          :current-page-size="25"
        />

        <template v-else-if="swaps && swaps.length === 0 && queueCount > 0">
          <div class="no-suggestions">
            <BaseIcon
              icon-file="hands-clapping-blue"
              :width="117"
              :height="117"
            />
            <h1 class="heading">
              {{ $t('swaps_no_left_title') }}
            </h1>
            <p class="text-body">
              {{ $t('swaps_no_left_text') }}
            </p>
          </div>
        </template>

        <NoResult v-else-if="swaps && swaps.length === 0" />

        <SwapContainer
          v-else-if="
            isMobile ||
            swapFilterType === SWAP_FILTER_TYPES.UNANSWERED_NEW ||
            (!isMobile && swapItemMode === SWAP_ITEM_TYPES.CARDS)
          "
          used-in="swaps-page"
        />

        <SwapListContainer
          v-else
          :blur-content="
            (primaryProposition.isFreemium || primaryProposition.isTrial) &&
            swapFilterType == 'LAST_PART'
          "
          :is-swaps-page="true"
        />

        <FlowPagination
          v-if="
            swapFilterType !== SWAP_FILTER_TYPES.UNANSWERED_NEW &&
            totalPages > 1
          "
          :current-page="currentPage"
          :max-page="totalPages"
          :on-page-click="handlePageClick"
          :item-count="postFilterCountWithDiffs"
          :current-page-size="currentPageSize"
          :on-select-page-size="handlePageSizeSelected"
          :is-loading="loading"
        />

        <InfiniteScrollPagination
          v-if="swapFilterType === SWAP_FILTER_TYPES.UNANSWERED_NEW && hasMore"
          :on-page-click="handlePageClick"
          :is-loading="loading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SwapMenu from '@/components/Swaps/SwapMenu';
import NoResult from '@/components/Swaps/NoResult';
import SwapHeader from '@/components/SwapList/Header';
import SwapContainer from '@/components/SwapList/SwapContainer';
import SwapListContainer from '@/components/SwapList/SwapListContainer';
import LoaderContainer from '@/components/SwapList/Loading/LoaderContainer';
import FlowPagination from '@/components/Pagination/FlowPagination';
import InfiniteScrollPagination from '@/components/Pagination/InfiniteScrollPagination';
import InfoBanner from '@/components/InfoBanner';
import NoActiveModal from '@/components/Modals/NoActiveModal.vue';
import { subscribe, unsubscribe } from '../../utils/socket';
import events from '@/utils/helpers/events';
import { FETCH_MODE } from '@/store/modules/swapList';

import { mapGetters, mapActions } from 'vuex';
import {
  SWAP_LIST_TYPES,
  SWAP_FILTER_TYPES,
  SWAP_ITEM_TYPES
} from '../../store/modules/swapList';
import smoothScroll from '@/utils/smoothScroll';

export default {
  name: 'Swaps',

  components: {
    SwapMenu,
    NoResult,
    SwapHeader,
    SwapContainer,
    SwapListContainer,
    LoaderContainer,
    FlowPagination,
    InfoBanner,
    NoActiveModal,
    // SwapTutorial,
    InfiniteScrollPagination
  },

  data() {
    return {
      SWAP_LIST_TYPES,
      SWAP_FILTER_TYPES,
      SWAP_ITEM_TYPES,
      currentPageSize: 0,
      progress: 0,
      fetchMoreMode: false
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('menu_items_link_12')
      };
    }
  },

  computed: {
    ...mapGetters({
      ready: 'app/ready',
      swaps: 'swapList/swaps',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryPropositionActive: 'myPropositions/primaryPropositionActive',
      swapFilterType: 'swapList/swapFilterType',
      isMobile: 'screenSize/isMobile',
      swapItemMode: 'swapList/swapItemMode',
      swapListType: 'swapList/swapListType',
      primaryProposition: 'myPropositions/primaryProposition',
      swapView: 'swapList/swapView',
      loading: 'swapList/loading',
      isMyPropositionsLoading: 'myPropositions/loadingPropositions',
      currentPage: 'swapList/currentPage',
      hasMore: 'swapList/hasMore',
      totalPages: 'swapList/totalPages',
      postFilterCountWithDiffs: 'swapList/postFilterCountWithDiffs',
      postFilterCountTotal: 'swapList/postFilterCountTotal',
      queueCount: 'swapList/queueCount',
      swapInterestCounts: 'swapList/swapInterestCounts',
      primaryPropositionFreeArea: 'myPropositions/primaryPropositionFreeArea'
    }),

    shouldShowLoadingContainer() {
      if (
        this.swapFilterType === SWAP_FILTER_TYPES.UNANSWERED_NEW &&
        this.swaps.length > 0
      ) {
        return false;
      }

      return (
        (this.loading || this.isMyPropositionsLoading) && !this.fetchMoreMode
      );
    },

    pageTitleAndDescription() {
      switch (this.swapFilterType) {
        case SWAP_FILTER_TYPES.UNANSWERED_NEW:
          return {
            title: this.$t('swaps_list_toggle_unanswered'),
            description: this.$t('swaplist_header_showing_unanswered')
          };
        case SWAP_FILTER_TYPES.ONLY_FULL_INTEREST:
          return {
            title: this.$t('swaps_list_toggle_full'),
            description: this.$t('swaplist_header_showing_full_interest_only', {
              total: this.postFilterCountTotal
            })
          };
        case SWAP_FILTER_TYPES.LAST_PART:
          return {
            title: this.$t('swaps_list_toggle_me'),
            description: this.$t('swaplist_header_showing_last_part', {
              total: this.postFilterCountTotal
            })
          };
        case SWAP_FILTER_TYPES.INTEREST_MARKED:
          return {
            title: this.$t('swaps_list_toggle_my_interests'),
            description: this.$t('swaplist_header_showing_interest_marked', {
              total: this.postFilterCountTotal
            })
          };
        case SWAP_FILTER_TYPES.MARKED:
          return {
            title: this.$t('swaps_list_toggle_my_marks'),
            description: this.$t('swaplist_header_showing_marked', {
              total: this.postFilterCountTotal
            })
          };
        default:
          return {
            title: this.$t('swaps_list_toggle_my_favourites'),
            description: this.$t('swaplist_header_showing_favourites', {
              total: this.postFilterCountTotal
            })
          };
      }
    },

    currentSwapItemComponent() {
      if (!this.isMobile) return 'SwapItem';

      if (this.swapItemMode === SWAP_ITEM_TYPES.CARDS) {
        return 'SwapItem';
      }

      return 'CompressedSwapItem';
    }
  },

  watch: {
    swapItemMode(current) {
      if (this.swapFilterType === SWAP_FILTER_TYPES.UNANSWERED_NEW) {
        events.emitEvent(events.eventTypes.PAGEVIEW, 'Swap List', {
          propositionId: this.currentPropositionId,
          viewType: this.swapListType,
          listType: current
        });
      }
    },

    swapListType(current) {
      if (this.swapFilterType === SWAP_FILTER_TYPES.UNANSWERED_NEW) {
        events.emitEvent(events.eventTypes.PAGEVIEW, 'Swap List', {
          propositionId: this.currentPropositionId,
          viewType: current,
          listType: this.swapItemMode
        });
      }
    },

    swapFilterType() {
      this.fetchMoreMode = false;
      this.initPageSize();
    }
  },

  created() {
    window.scrollTo(0, 0);

    this.initPageSize();
  },

  async mounted() {
    this.showSwapAddedBadge(false);
    subscribe('swaplist', msg => {
      this.progress = msg.progress;
    });

    this.progress = 0;

    this.setSwapType({
      propositionId: this.primaryPropositionId,
      swapFilterType: SWAP_FILTER_TYPES.ONLY_FULL_INTEREST,
      update: true,
      onPageLoad: true
    });

    if (
      this.primaryPropositionActive &&
      this.swapFilterType === SWAP_FILTER_TYPES.UNANSWERED_NEW
    ) {
      events.emitEvent(events.eventTypes.PAGEVIEW, 'SwapList', {
        propositionId: this.currentPropositionId,
        viewType: this.swapListType,
        listType: this.swapItemMode
      });
    }
  },

  beforeDestroy() {
    unsubscribe('swaplist');
  },

  methods: {
    ...mapActions({
      fetchSwapDetails: 'swapList/fetchSwapDetails',
      setSwapType: 'swapList/setSwapType',
      closeSwapDetails: 'swapList/closeSwapDetails',
      selectPage: 'swapList/selectPage',
      showSwapAddedBadge: 'swapList/showSwapAddedBadge'
    }),

    handleShowSwapDetails({ propositionId, type }) {
      this.fetchSwapDetails({ propositionId, type });
    },

    handlePageClick(page) {
      if (this.swapFilterType === SWAP_FILTER_TYPES.UNANSWERED_NEW) {
        this.fetchMoreMode = true;
        this.selectPage({
          page: this.swaps.length,
          mode: FETCH_MODE.MERGE
        });
        return;
      }

      smoothScroll({ from: window.scrollY, to: 0 });

      window.setTimeout(() => {
        this.selectPage({
          page,
          mode: FETCH_MODE.REPLACE
        });
      }, 200);
    },

    handlePageSizeSelected(pageSize) {
      localStorage.setItem('swap-list_page-size', pageSize);
      this.currentPageSize = pageSize;
      this.handlePageClick(0);
    },

    initPageSize() {
      const savedPageSize =
        localStorage.getItem('swap-list_page-size') || false;
      this.currentPageSize = savedPageSize ? parseInt(savedPageSize) : 25;
    }
  }
};
</script>

<style scoped lang="scss">
.swaps-page-container {
  max-width: none;
  width: 100%;
  min-height: 100vh;

  @media ($mobile) {
    background-color: #f1f2f6;
  }

  @media ($desktop) {
    padding-top: 80px;
    padding-bottom: 0;
  }
}

.swaps-page {
  @media ($desktop) {
    display: grid;
    grid-template-columns: 350px minmax(0, 1fr);
    max-width: 1400px;
    margin: 0 auto;
  }
}

.swaps-page-header {
  @media ($mobile) {
    background-color: #f1f2f6;
    padding: 10px;
    padding-top: 25px;
  }

  @media ($desktop) {
    min-height: calc(100vh - 80px);
    border-right: 1px solid rgba(28, 45, 65, 0.103);
  }
}

.my-swaps-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #2c3e50;
  padding-left: 10px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  padding-top: 40px;
  border-bottom: 1px solid rgba(28, 45, 65, 0.103);
}

.results-and-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  @media ($desktop) {
    margin-top: 10px;
  }
}

.results-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2c3e50;
  margin-bottom: 3px;
}

.results-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #878b9d;
}

.main-content {
  padding: 10px;

  @media ($desktop) {
    padding: 20px;
  }
}

.swaps-container {
  position: relative;
}

.swap-items-wrapper {
  margin-bottom: 20px;

  @media ($desktop) {
    margin-bottom: 16px;
  }
}

.swap-menu {
  @media ($mobile) {
    background-color: #f1f2f6;
  }
}

.info-banner {
  @media ($mobile) {
    margin-top: -15px;
    margin-bottom: 20px;
  }

  @media ($desktop) {
    margin-bottom: 40px;
  }
}

.no-suggestions {
  display: grid;
  place-items: center;
  text-align: center;
  padding: 20px;
  padding-bottom: 25px;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 10px;

  @media ($desktop) {
    padding-top: 70px;
  }
}

.heading {
  font-family: $font-headings;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  margin-top: 30px;
  margin-bottom: 10px;
}

.text-body {
  margin: 0;
  line-height: 1.5;
}
</style>
