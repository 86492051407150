<template>
  <div class="reviews-block">
    <div class="reviews-block-container">
      <h1 class="heading">{{ content.Heading }}</h1>
      <div class="user-reviews">
        <blockquote
          v-for="review in content.UserReviews"
          :key="review.id"
          class="user-review"
        >
          <p>{{ review.Review }}</p>
          <figcaption>
            <cite>{{ review.Name }}</cite>
            <div class="date">{{ review.Date }}</div>
          </figcaption>
        </blockquote>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reviews',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.reviews-block {
  margin-top: 32px;
  margin-bottom: 32px;
  background-color: #2e71f3;
  color: #fff;
}

.reviews-block-container {
  padding: 60px 16px 50px 16px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @media ($desktop) {
    padding: 80px 16px 60px 16px;
  }
}

.heading {
  font-family: $font-headings;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 900;
  text-align: center;
  margin-top: 0;
  margin-bottom: 50px;

  @media ($desktop) {
    font-size: 64px;
    margin-bottom: 70px;
  }
}

.user-reviews {
  display: grid;
  gap: 60px;

  @media ($desktop) {
    grid-template-columns: repeat(2, 1fr);
  }
}

blockquote {
  display: flex;
  flex-direction: column;
  margin: 0;
}

blockquote p {
  flex-grow: 1;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  margin-top: 0;
  margin-bottom: 40px;

  @media ($desktop) {
    font-size: 24px;
    margin-bottom: 80px;
  }
}

figcaption::before {
  content: '';
  display: block;
  border-top: 1px solid #d9d9d9;
  margin-bottom: 20px;

  @media ($desktop) {
    margin-bottom: 24px;
  }
}

figcaption {
  font-family: $font-headings;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.01em;
}

cite {
  font-size: 20px;
  text-transform: uppercase;
  font-style: normal;

  @media ($desktop) {
    font-size: 24px;
  }
}

.date {
  font-size: 14px;
}
</style>
