<template>
  <span
    :class="[
      'loading loading--spinner',
      { overlay: withOverlay, 'full-screen': fullScreen }
    ]"
  >
    <svg
      class="spinner"
      :style="{ height: `${size}px`, width: `${size}px` }"
      viewBox="0 0 50 50"
    >
      <circle
        class="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke="#409fff"
        stroke-width="5"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    size: {
      type: Number,
      default: 50
    },

    withOverlay: {
      type: Boolean,
      default: true
    },

    fullScreen: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;

  &.overlay {
    background-color: rgba(255, 255, 255, 0.6);
  }

  &.full-screen {
    position: fixed !important;
  }
}

.spinner {
  animation: rotate 2s linear infinite;

  & .path {
    animation: dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150; // ie11 fallback
    stroke-dashoffset: -35; // ie11 fallback
    stroke-linecap: round;
    stroke: #409fff;
  }
}
</style>
