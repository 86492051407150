<template>
  <div :class="['indicator-wrapper']">
    <div class="indicator-icons">
      <div
        v-for="(interest, i) in interests"
        :key="i"
        :class="[
          'indicator-icon',
          {
            interested: interest === true,
            'not-interested': interest === false
          }
        ]"
      ></div>
    </div>
    <div class="interest-status">{{ swapStatusText }}</div>
  </div>
</template>

<script>
export default {
  name: 'InterestIndicator',

  props: {
    interests: {
      type: Array,
      required: true
    }
  },

  computed: {
    swapStatusText() {
      const interestedCount = this.interests.filter(Boolean).length;
      const interestsLength = this.interests.length;

      // You are not interested
      if (this.interests[0] === false) {
        return this.$t('swap_item_you_not_interested');
      }
      // Noone interested
      else if (interestedCount === 0) {
        return this.$t('swap_item_no_interest');
      }
      // Full interest
      else if (interestedCount === interestsLength) {
        return this.$t('swap_item_full_interest');
      }
      // Direct swap
      else if (interestsLength === 2) {
        if (this.interests[0]) {
          return this.$t('swap_item_you_interested');
        } else {
          return this.$t('swap_item_other_interested');
        }
      }
      // Triangle swap
      else {
        return `${interestedCount} ${this.$t('swap_item_x_of_y_interested')}`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.indicator-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 12px;
  line-height: 15px;
}

.indicator-icons {
  display: flex;
}

.indicator-icon {
  height: 17px;
  width: 17px;
  margin-right: 3px;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/svg/interest-indicator-empty.svg');

  &.interested {
    background-image: url('../../assets/svg/interest-indicator-interested.svg');
  }

  &.not-interested {
    background-image: url('../../assets/svg/interest-indicator-not-interested.svg');
  }
}

.interest-status {
  margin-left: 8px;
}
</style>
