var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.open)?_c('div',{staticClass:"start-animation-wrapper fade-in",style:({
      animationDuration: (_vm.modalAnimMs + "ms")
    })},[_c('div',{staticClass:"start-animation-modal"},[_c('div',{staticClass:"status-heading"},[_c('span',{ref:"statusText",staticClass:"status-heading-text"},[_vm._v(" "+_vm._s(_vm.currentText)+" ")])]),_c('ProgressBar',{ref:"progressBar",staticClass:"progress-bar fade-in",attrs:{"percentage":_vm.isSuccess ? 100 : _vm.progressBarPercentage}}),_c('div',{ref:"linearGradientTop",staticClass:"linear-gradient-top"}),_c('StartInfo',{style:({
          display: !_vm.isSuccess ? 'none' : 'flex'
        }),attrs:{"propositions-site-count":_vm.formattedPropositionsSiteCount},on:{"close":_vm.handleCloseAnimation}}),(!_vm.isSuccess)?_c('div',{ref:"cardAnimationWrapper",staticClass:"card-animation-wrapper"},[_c('div',{staticClass:"card-animation-left-container"},[_c('div',{ref:"cardAnimationLeft",staticClass:"cards-list"},_vm._l((_vm.exampleFlatImagesSet1),function(exampleImage,index){return _c('div',{key:index,staticClass:"card-wrapper"},[_c('div',{ref:"card",refInFor:true,staticClass:"card",class:exampleImage.state,style:({
                  'animation-duration': ((index / 3) + "s")
                }),attrs:{"data-id":index}},[_c('div',{staticClass:"image",class:exampleImage.size,style:({
                    'background-image': ("url(" + (exampleImage.image) + ")")
                  })})]),_c('div',{ref:"skeleton",refInFor:true,staticClass:"text-skeleton-wrapper",class:exampleImage.state,style:({
                  'animation-delay': ("{" + index + "s"),
                  'animation-duration': ((index / 3) + "s")
                })},[_c('div',{staticClass:"text-skeleton1",class:exampleImage.state === 'dimmed'
                      ? 'highlighted-animation-skeleton'
                      : ''}),_c('div',{staticClass:"text-skeleton2",class:exampleImage.state === 'dimmed'
                      ? 'highlighted-animation-skeleton'
                      : ''})])])}),0)]),_c('div',{staticClass:"card-animation-right-container"},[_c('div',{ref:"cardAnimationRight",staticClass:"cards-list"},_vm._l((_vm.exampleFlatImagesSet2),function(exampleImage,index){return _c('div',{key:index,staticClass:"card-wrapper"},[_c('div',{ref:"card",refInFor:true,staticClass:"card",class:exampleImage.state,style:({
                  'animation-duration': ((index / 3) + "s")
                }),attrs:{"data-id":index}},[_c('div',{staticClass:"image",class:exampleImage.size,style:({
                    'background-image': ("url(" + (exampleImage.image) + ")")
                  })})]),_c('div',{ref:"skeleton",refInFor:true,staticClass:"text-skeleton-wrapper",class:exampleImage.state,style:({
                  'animation-delay': ("{" + index + "s"),
                  'animation-duration': ((index / 3) + "s")
                })},[_c('div',{staticClass:"text-skeleton1",class:exampleImage.state === 'dimmed'
                      ? 'highlighted-animation-skeleton'
                      : ''}),_c('div',{staticClass:"text-skeleton2",class:exampleImage.state === 'dimmed'
                      ? 'highlighted-animation-skeleton'
                      : ''})])])}),0)])]):_vm._e(),_c('div',{ref:"linearGradientBottom",staticClass:"linear-gradient-bottom"})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }