<template>
  <section :class="['tab-bar__wrapper', stickyTabBar ? '' : 'is-not-sticky']">
    <router-link :to="$routes.SWAP_LIST" class="tab-bar__item">
      <template slot-scope="{ route, isActive }">
        <router-link :to="route" @click.native="itemClick(isActive)">
          <BaseIcon
            :icon-file="isActive ? 'tabbar-swap-sug-active' : 'tabbar-swap-sug'"
            class="tab-bar__item__icon"
          />
          {{ $t('tab_bar_swap_suggestions') }}
          <BadgeCount
            :notifications="notifications && notifications.newSwaps"
            class="absolute"
          />
        </router-link>
      </template>
    </router-link>

    <router-link
      v-if="matchesViewABTest"
      :to="$routes.MATCHES"
      class="tab-bar__item"
    >
      <template slot-scope="{ route, isActive }">
        <router-link :to="route" @click.native="itemClick(isActive)">
          <BaseIcon
            :icon-file="isActive ? 'tabbar-swaps-active' : 'tabbar-swaps'"
            class="tab-bar__item__icon"
          />
          {{ $t('tab_bar_matches') }}
          <BadgeCount
            :notifications="
              notifications && notifications.unreadAndUpdatedMatches
            "
            class="absolute"
          />
        </router-link>
      </template>
    </router-link>

    <router-link v-else :to="$routes.SWAPS" class="tab-bar__item">
      <template slot-scope="{ route, isActive }">
        <router-link :to="route" @click.native="itemClick(isActive)">
          <BaseIcon
            :icon-file="isActive ? 'tabbar-swaps-active' : 'tabbar-swaps'"
            class="tab-bar__item__icon"
          />
          {{ $t('tab_bar_swaps') }}
          <BadgeCount
            :notifications="swapInterestCountsUnseenTotal"
            class="absolute"
          />
          <transition name="plus-one">
            <div v-if="showSwapAddedBadge" class="swap-added-icon">+1</div>
          </transition>
        </router-link>
      </template>
    </router-link>

    <router-link
      v-if="!primaryPropositionIsPremiumOrFree"
      :to="$routes.PAYMENT_PROPOSITION + '/' + primaryPropositionId"
      class="tab-bar__item premium"
    >
      <template slot-scope="{ route, isActive }">
        <router-link :to="route" @click.native="itemClick(isActive)">
          <BaseIcon
            :icon-file="isActive ? 'premium-active' : 'premium'"
            :width="27"
            :height="27"
            class="tab-bar__item__icon"
          />
          Premium
        </router-link>
      </template>
    </router-link>

    <span
      v-if="
        primaryPropositionIsPremiumOrFree &&
        $country.isFeatureEnabled('PAYMENT')
      "
      class="tab-bar__item premium"
      @click="handleBoostClick"
    >
      <BaseIcon
        :icon-file="hasBoost ? 'has-boost' : 'boost'"
        :width="27"
        :height="27"
        :class="['tab-bar__item__icon', { 'has-boost': hasBoost }]"
      />
      Boost
    </span>

    <router-link
      v-if="matchesViewABTest || !$country.isFeatureEnabled('PAYMENT')"
      :to="$routes.MY_PROPOSITIONS"
      class="tab-bar__item"
    >
      <template slot-scope="{ route, isActive }">
        <router-link :to="route" @click.native="itemClick(isActive)">
          <BaseIcon
            :icon="isActive ? 'my-prop-active' : 'my-prop'"
            class="tab-bar__item__icon"
          />
          {{ $t('tab_bar_my_ads') }}
        </router-link>
      </template>
    </router-link>

    <router-link
      v-if="!matchesViewABTest"
      :to="$routes.MESSAGES"
      class="tab-bar__item"
    >
      <template slot-scope="{ route, isActive }">
        <router-link :to="route" @click.native="itemClick(isActive)">
          <BaseIcon
            :icon-file="isActive ? 'tabbar-chat-active' : 'tabbar-chat'"
            class="tab-bar__item__icon"
          />
          {{ $t('tab_bar_messages') }}
          <BadgeCount :notifications="unreadChatMessages" class="absolute" />
        </router-link>
      </template>
    </router-link>

    <div class="tab-bar__item" @click="toggleMenu">
      <BaseIcon icon-file="tabbar-hamburger" class="tab-bar__item__icon" />
      {{ $t('tab_bar_more') }}
      <NotificationDot
        v-if="matchesViewABTest && notifications.unreadMessages > 0"
        class="notification-dot"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import BadgeCount from './Icons/BadgeCount';
import events from '@/utils/helpers/events';
import NotificationDot from './Icons/NotificationDot';

export default {
  name: 'TabBar',
  components: {
    BadgeCount,
    NotificationDot
  },

  computed: {
    ...mapGetters({
      notifications: 'menu/getNotifications',
      user: 'app/user',
      stickyTabBar: 'menu/stickyTabBar',
      swapInterestCountsUnseenTotal: 'swapList/swapInterestCountsUnseenTotal',
      showSwapAddedBadge: 'swapList/showSwapAddedBadge',
      primaryProposition: 'myPropositions/primaryProposition',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryPropositionIsPremiumOrFree:
        'myPropositions/primaryPropositionIsPremiumOrFree'
    }),

    matchesViewABTest() {
      return this.$growthbook.isFeatureFlagEnabled('matches-view');
    },

    unreadChatMessages() {
      if (
        this.notifications &&
        this.notifications.unreadMessages &&
        this.notifications.unreadMessages > 0
      ) {
        return this.notifications.unreadMessages;
      }

      return 0;
    },

    hasBoost() {
      return this.primaryProposition.extraVisibility;
    }
  },

  methods: {
    ...mapActions({
      toggleMenu: 'menu/toggleMenu',
      setGlobalModal: 'ui/setGlobalModal'
    }),

    ...mapMutations({
      setExtraVisibilityPaymentVisible: 'extraVisibility/setVisible',
      setExtraVisibilityPaymentProposition: 'extraVisibility/setProposition'
    }),

    handleBoostClick() {
      if (this.hasBoost) {
        this.setGlobalModal('BoostInfo');
        events.emitEvent(events.eventTypes.CLICK, 'Boost Statistics', {
          src: 'boost-info-tabbar'
        });
      } else {
        this.setExtraVisibilityPaymentProposition(this.primaryProposition);
        this.setExtraVisibilityPaymentVisible(true);
      }
    },

    itemClick(isActive) {
      if (isActive) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-bar__wrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: calc(58px + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  padding-right: 6px;
  padding-left: 6px;
  background-color: #fff;
  border-top: 1px solid #f1f2f6;
  box-shadow: 0px 3px 5px rgba(176, 190, 197, 0.25),
    0px 8px 24px rgba(176, 190, 197, 0.4), 0px 0px 15px rgba(0, 0, 0, 0.1);
  z-index: 199;

  // Remove position fixed when keyboard is open
  // to prevent that too much screen space is covered
  @media (max-height: 500px) {
    position: absolute;
  }

  &.is-not-sticky {
    position: absolute;
  }
}

.tab-bar__item {
  user-select: none;
  align-items: center;
  color: #878b9d;
  display: flex;
  flex-direction: column;
  flex: 0 0 20%;
  font-size: 10px;
  font-weight: 600;
  justify-content: flex-start;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  padding-bottom: 10px;

  &.active {
    color: #2c3e50;
  }
}

.tab-bar__item__icon {
  margin-bottom: 3px;

  // &.has-boost {
  //   animation: grow-and-shrink 1.5s infinite ease-in-out;
  // }

  .premium & {
    margin-bottom: 2px;
  }
}

.notification-dot {
  top: -4px;
  right: 10px;
}

.swap-added-icon {
  visibility: hidden;
  position: absolute;
  top: 6px;
  right: 14px;
  width: 12px;
  height: 12px;
  background-color: #2ec973;
  border-radius: 50%;
  font-size: 8px;
  color: #2ec973;
  display: grid;
  place-items: center;
}

.plus-one-enter-active {
  visibility: visible;
  animation-name: plus-one;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
}

@keyframes plus-one {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(4);
    border: none;
    color: #fff;
    padding-right: 1px;
  }
  100% {
    transform: scale(1);
  }
}
</style>
