var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"wrapper"},[(_vm.showConversionModal)?_c('BaseModal',{attrs:{"title":_vm.$t('proposition_conversion_modal_title')},on:{"close":_vm.toggleConversionModal}},[_c('ConversionModalContent')],1):_vm._e(),(!_vm.hasAccess)?_c('PropositionGalleryCTA'):_vm._e(),_c(_vm.imagesWrapperTag,{ref:"slider",tag:"component",staticClass:"slides-container",attrs:{"to":_vm.imageUrl},on:{"click":function($event){return _vm.handleImageClick()}}},[_c('div',{staticClass:"image-wrapper"},[(_vm.imageCount > 1)?_c('div',{staticClass:"image",style:([
          _vm.images[_vm.imageCount - 1].fileName
            ? {
                backgroundImage: ("url(" + (_vm.images[_vm.imageCount - 1].fileName) + ")"),
                opacity: 1
              }
            : ''
        ])}):_vm._e()]),_vm._l((_vm.images),function(image,index){return _c('div',{key:image + Math.random(),staticClass:"image-wrapper"},[_c('div',{staticClass:"image",class:{
          'blurred-background-image': _vm.blurImages && index >= 2
        },style:([
          image.fileName
            ? {
                backgroundImage: ("url(" + (image.fileName) + ")"),
                opacity: 1
              }
            : ''
        ])}),(_vm.blurImages && index >= 2)?_c('div',{staticClass:"overlay"}):_vm._e(),(_vm.blurImages && index >= 2)?_c('div',{staticClass:"blur-msg-wrapper"},[_c('div',{staticClass:"blur-msg-click-container",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goToEditPropositionImages()}}},[_c('BaseIcon',{attrs:{"icon-file":"blur-img-eye","width":48,"height":46}}),_c('div',{staticClass:"blur-msg"},[_c('p',{staticClass:"unblock-text"},[_vm._v(" "+_vm._s(_vm.$t('unlock_blur_msg'))+" ")]),_c('div',{staticClass:"upload-images-text"},[_c('span',[_c('u',[_vm._v(_vm._s(_vm.$t('proposition_gallery_blur_link_msg')))])]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('proposition_gallery_blur_msg'))+" ")])])])],1)]):_vm._e()])}),_c('div',{staticClass:"image-wrapper"},[(_vm.imageCount > 1)?_c('div',{staticClass:"image",style:([
          _vm.images[0].fileName
            ? {
                backgroundImage: ("url(" + (_vm.images[0].fileName) + ")"),
                opacity: 1
              }
            : ''
        ])}):_vm._e()])],2),(_vm.imageCount > 1)?_c('div',{ref:"dotsContainer",staticClass:"dots-container"},_vm._l((_vm.images),function(image,index){return _c('div',{key:image + Math.random(),ref:("dot-" + index),refInFor:true,staticClass:"dot",style:([
        index === 0 ? { backgroundColor: 'rgba(255, 255, 255, 0.9)' } : ''
      ])})}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }