<template>
  <section class="root-wrapper" @click="$emit('click')">
    <MSearchInput
      ref="input"
      v-model="text"
      type="text"
      :settings="{ placeholder: placeholder }"
      :is-loading="isSearching"
      :icon="icons.GENERAL.SEARCH"
      :is-large="!isMobile"
      @input="handleSearch()"
      @clear="handleClearText()"
      @keydown.enter="
        locations.length > 0 && addValue(locations[0]);
        handleSearch();
      "
    >
      <slot></slot>
    </MSearchInput>

    <section
      v-show="locations && locations.length > 0"
      v-outside-click="clickOutsideSearchList"
    >
      <div ref="searchList" class="list-wrapper">
        <ul class="list">
          <li
            v-for="(location, index) in locations"
            :key="index"
            class="list-item clickable"
            @click="addValue(location)"
          >
            <span :class="{ capitalize: !location.isFreetext }">
              <span v-html="getFullLocationHtml(location)"></span>
            </span>

            <span>
              <span v-if="icon" class="search-icon-wrapper">
                <AImage :url="icon" :classes="['search-right-icon']"
              /></span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section v-if="isMultiple && value && value.length > 0">
      <ul class="list mgt-7">
        <li
          v-for="(location, index) in value"
          :key="index"
          class="clickable value"
          @click="removeValue(location)"
        >
          <div class="full-width">
            <span v-if="location.name" class="name"> {{ location.name }}</span>
            <span v-if="location.description" class="description"
              >, {{ location.description | truncate(10, 10) }}</span
            >
          </div>
          <span class="flex-center">
            <span class="close"></span>
          </span>
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
import { searchGeolocation } from '@/store/modules/search/services';
import { truncate } from '@/filters/truncate';
import outsideClick from '@/directives/outside-click';
import icons from '@/utils/icon-constants';
import { mapGetters } from 'vuex';

import MSearchInput from '@/components/atomic/molecules/m-search-input';
import AImage from '@/components/atomic/atoms/a-image';

export default {
  components: {
    MSearchInput,
    AImage
  },
  filters: {
    truncate: truncate
  },

  directives: { outsideClick },
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    locationTypes: {
      type: Array,
      default: () => []
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    freetextEnabled: {
      type: Boolean,
      default: false
    },
    maxResults: {
      type: [Number, null],
      default: null
    },
    hideType: {
      type: Boolean,
      default: false
    },
    icon: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' }
  },

  data() {
    return {
      timeoutId: null,
      debText: false,
      debounce: 500,
      isSearching: false,
      text: '',
      locations: [],
      types: {
        County: this.$t('wish_area_county', this.$routeLocale),
        PostalArea: this.$t('wish_area_postal_area', this.$routeLocale),
        PostalCode: this.$t('wish_area_postal_code', this.$routeLocale),
        Municipality: this.$t('wish_area_municipality', this.$routeLocale),
        PostalCodeDistrict: this.$t(
          'wish_area_postal_code_district',
          this.$routeLocale
        ),
        ThreeDigitPostalCode: this.$t(
          'wish_area_three_digit_postal_code',
          this.$routeLocale
        ),
        Urban: this.$t('wish_area_urban', this.$routeLocale),
        District: this.$t('wish_area_district', this.$routeLocale)
      },
      icons: icons
    };
  },

  computed: {
    locationText() {
      return str => {
        if (this.matchesText(str)) return str.substring(this.text.length);
        return str;
      };
    },

    matchesText() {
      return str => {
        return str.toLowerCase().includes(this.text.toLowerCase());
      };
    },

    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  },

  watch: {
    value(newVal) {
      if (this.isMultiple) return;

      this.setText(newVal);
    },
    deep: true
  },

  beforeDestroy() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
  },

  created() {
    if (
      !this.isMultiple &&
      this.value &&
      this.value.length > 0 &&
      typeof this.value === 'object'
    ) {
      this.text = this.getFullLocationText(this.value[0]);
    }
  },

  mounted() {
    this.setText(this.value);
  },

  methods: {
    getFullLocationText(location) {
      let str = location.name;
      if (location.description) str += `, ${location.description}`;
      return str;
    },

    getFullLocationHtml(location) {
      let locationStr = this.getFullLocationText(location);

      const reg = new RegExp(this.text, 'ig');
      const matches = locationStr.match(reg);

      if (matches && matches.length > 0) {
        const replaceStr = matches[0];
        return locationStr.replace(
          replaceStr,
          '<strong>' + replaceStr + '</strong>'
        );
      }

      return locationStr;
    },

    clickOutsideSearchList() {
      if (this.locations && this.locations.length > 0) {
        this.clearSearch();
        this.handleClearText();
      }
    },
    setText(newVal) {
      if (typeof newVal === 'object') {
        if (newVal && newVal.length > 0)
          this.text = this.getFullLocationText(this.value[0]);
        else this.text = '';
        return;
      }

      this.text = newVal || '';
    },

    handleClearText() {
      if (!this.isMultiple)
        this.$emit('input', {
          geolocations: [],
          freetext: null,
          geolocationsSource: null
        });
    },

    removeValue(location) {
      this.$emit(
        'input',
        this.value.filter(x => x.id !== location.id)
      );
    },

    addValue(location) {
      if (location.isFreetext) {
        this.$emit('input', {
          geolocations: [],
          freetext: location.name,
          geolocationsSource: null
        });

        this.clearSearch();

        return;
      }

      if (this.isMultiple) this.text = '';

      if (this.$refs.input && this.$refs.input.$children.length > 0) {
        this.$nextTick(() => {
          this.$refs.input.$children[0].$el.focus();
        });
      }

      if (this.isMultiple && this.value.some(x => x.id === location.id)) {
        this.clearSearch();
        return false;
      }

      this.$emit('input', {
        geolocations: this.isMultiple ? [...this.value, location] : [location],
        freetext: null,
        geolocationsSource: this.getFullLocationText(location)
      });

      this.clearSearch();
    },

    clearSearch() {
      this.locations = [];
    },

    async handleSearch() {
      try {
        this.clearSearch();

        if (!this.text) {
          this.clearSearch();
          clearTimeout(this.timeoutId);
          this.isSearching = false;
          return false;
        }

        if (this.text !== this.debText) {
          this.isSearching = true;

          if (this.timeoutId) clearTimeout(this.timeoutId);

          this.debText = this.text;

          this.timeoutId = setTimeout(async () => {
            const result = await searchGeolocation(this.text);

            if (this.maxResults)
              result.data.splice(
                this.maxResults,
                result.data.length - this.maxResults
              );

            if (this.locationTypes && this.locationTypes.length > 0) {
              this.locations = result.data.filter(x =>
                this.locationTypes.some(y => y == x.type)
              );
            } else this.locations = result.data;

            if (this.freetextEnabled)
              result.data.push({ name: this.text.trim(), isFreetext: true });

            this.isSearching = false;
            this.timeoutId = null;
          }, this.debounce);
        }
      } catch (error) {
        console.error(error);
        this.isSearching = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.type {
  text-transform: lowercase;
  color: #a0a0a0;
  font-size: 14px;
}

.capitalize {
  text-transform: capitalize;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-item {
  padding: 16px 10px 16px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.list-item:hover {
  background-color: #d9f5fb;
}

.list-wrapper {
  background-color: white;
  border-radius: 5px;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow-y: auto;
  max-height: 300px;
  z-index: 999;
  margin-top: 5px;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));

  width: 100%;
}

.mgt-7 {
  margin-top: 7px;
}

.value {
  display: flex;
  color: white;
  font-size: 16px;
}

.close {
  cursor: pointer;
  height: 14px;
  width: 14px;
  background-position: center;
  background-size: contain;
  background-image: url('../../../assets/svg/close-white.svg');
  display: inline-flex;
}

.full-width {
  width: 100%;
}

.add-icon {
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: contain;
}

.root-wrapper {
  position: relative;
}

.search-icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  float: right;
}

.search-right-icon {
  display: inline-flex;
  height: 10px;
  width: 6px;
  background-position: center;
  background-size: contain;
}
</style>
