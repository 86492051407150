import {
  lbPropositionApi,
  lbApiBeta,
  lbUserApi
} from '../../../utils/axiosConfig';

const DIMENSIONS = {
  mobile: '800x800',
  desktop: '1600x1600'
};

async function fetchUser(userId) {
  const { data: user } = await lbUserApi.get(`/user/${userId}`);
  window.Logger.log('Fetched user data: ', user);

  return user;
}

export async function fetchProposition({ propositionId, isMobile }) {
  const dimensions = DIMENSIONS[isMobile ? 'mobile' : 'desktop'];

  const { data: proposition } = await lbPropositionApi.get(
    `/propositions/${propositionId}?dimensions=${dimensions}`
  );

  return proposition;
}

async function fetchVisited(propositionId) {
  const { data: visited } = await lbPropositionApi.get(
    `/visited/${propositionId}`
  );
  return visited;
}

async function fetchInterest(propositionId, propositionId2) {
  const { data: interestData } = await lbApiBeta(
    `/api/v2/interest/${propositionId}/${propositionId2}`
  );
  const { interest } = interestData;
  return interest === null ? null : interest === 1;
}

async function fetchFavourite(propositionId) {
  const { data: favouriteData } = await lbApiBeta.get(
    `api/v2/favourite/${propositionId}`
  );

  return favouriteData ? true : false;
}

async function fetchIsMatch(propositionId, propositionId2) {
  const { data: isMatch } = await lbApiBeta(
    `/api/liveswap/IsIncludedInSwap?propositionId=${propositionId}&candidateId=${propositionId2}`
  );

  if (!isMatch.Data) {
    return false;
  }

  return isMatch.Data;
}

async function fetchSponsoredPropositions(propositionId) {
  const {
    data: { data: propositions }
  } = await lbApiBeta.get(`/api/extravisibility/${propositionId}`);

  window.Logger.log('Sponsored propositions: ', propositions);

  return propositions;
}

async function updateVisited({ propositionId, visited }) {
  try {
    await lbPropositionApi.post(`/visited`, {
      propositionId,
      visited
    });
    return true;
  } catch (e) {
    window.Logger.error('Cant update visited for ', propositionId);
    return false;
  }
}

async function fetchPropositionsSiteCount() {
  try {
    const { data } = await lbApiBeta.get(
      `/api/Statistics/GetStartpageStatistics`,
      {
        params: { date: new Date() }
      }
    );
    return data;
  } catch (e) {
    window.Logger.log('Error fetching total propositions count: ', e);
  }
}

export default {
  fetchProposition,
  fetchVisited,
  fetchSponsoredPropositions,
  fetchInterest,
  fetchFavourite,
  fetchIsMatch,
  updateVisited,
  fetchUser,
  fetchPropositionsSiteCount
};
