import Vue from 'vue';
import Vuex from 'vuex';
import appModule from './modules/app';
import favouritesModule from './modules/favourites';
import menuModule from './modules/menu';
import uiModule from './modules/ui';
import myPropositionsModule from './modules/myPropositions';
import handleSubscriptionModule from './modules/handleSubscription';
import overlayModule from './modules/overlay';
import propositionSwitcherModule from './modules/propositionSwitcher';
import screenSizeModule from './modules/screenSize';
import trialModule from './modules/trial';
import klarnaPaymentsModule from './modules/klarnaPayments';
import paymentHistoryModule from './modules/paymentHistory';
import trackingModule from './modules/tracking';
import swapListModule from './modules/swapList';
import propositionsModule from './modules/propositions';
import interestsModule from './modules/interests';
import toastModule from './modules/toast';
import swapMapModule from './modules/swapMap';
import searchModule from './modules/search';
import wishAreasModule from './modules/wishAreas';
import gtmModule from './modules/gtm';
import notificationCenterModule from './modules/notificationCenter';
import extraVisibility from './modules/extraVisibility';
import payment from './modules/payment';
import matches from './modules/matches';
import tutorial from './modules/tutorial';
import startAnimation from './modules/startAnimation';
import reportProposition from './modules/reportProposition';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    app: appModule,
    gtm: gtmModule,
    favourites: favouritesModule,
    menu: menuModule,
    ui: uiModule,
    myPropositions: myPropositionsModule,
    handleSubscription: handleSubscriptionModule,
    overlay: overlayModule,
    propositionSwitcher: propositionSwitcherModule,
    screenSize: screenSizeModule,
    trial: trialModule,
    klarnaPayments: klarnaPaymentsModule,
    paymentHistory: paymentHistoryModule,
    tracking: trackingModule,
    swapList: swapListModule,
    propositions: propositionsModule,
    interests: interestsModule,
    toast: toastModule,
    swapMap: swapMapModule,
    search: searchModule,
    wishAreas: wishAreasModule,
    notificationCenter: notificationCenterModule,
    extraVisibility,
    payment,
    matches,
    tutorial,
    startAnimation,
    reportProposition
  }
});
