<template>
  <div class="map-wrapper">
    <div ref="mapEl" class="map"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SWAP_LIST_TYPES, FILTER_SETUP } from '@/store/modules/swapList';
import { cloneDeep } from 'lodash';
import { lbApiBeta } from '../../utils/axiosConfig';
import { loadJs, loadCss } from '@/utils/maptiler';

export default {
  name: 'Map',

  props: {
    propositions: {
      type: Array,
      default: () => []
    },
    propositionId: {
      type: String,
      required: true
    },
    scrollEnabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      zoom: 0,
      map: {},
      hasDoneInitialSearch: false,

      renderedMarkers: [],
      previusHoveredMarkerId: null,

      loadingData: false,
      timeoutId: null
    };
  },
  computed: {
    ...mapGetters({
      searchFilters: 'swapList/searchFilters',
      activeSwap: 'swapMap/activeSwap',
      swapListType: 'swapList/swapListType',
      isMobile: 'screenSize/isMobile'
    })
  },
  watch: {
    propositions() {
      this.updateMarkers();
    },

    activeSwap() {
      if (!this.activeSwap) return;
      this.renderedMarkers.forEach(renderedMarker => {
        if (
          renderedMarker._element.id.toString() ===
          this.activeSwap.propositionId.toString()
        )
          renderedMarker._element.classList.add('highlight');
        else renderedMarker._element.classList.remove('highlight');
      });
    }
  },

  async mounted() {
    await Promise.all([loadJs(), loadCss()]);

    await this.initializeMap();
    await this.search();

    this.setUpFilterListener();
    window.addEventListener('resize', this.adjustToScreenSize);
    this.$refs.mapEl.addEventListener('click', this.markerClickedHandler);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.adjustToScreenSize);
    this.$refs.mapEl.removeEventListener('click', this.markerClickedHandler);

    this.destroyFilterListener();
  },

  methods: {
    ...mapActions({
      setSwapDetails: 'swapMap/setSwapDetails',
      setActiveSwap: 'swapMap/setActiveSwap'
    }),

    async initializeMap() {
      window.Logger.log('Initing map 1');
      const mapSettings = this.$country.getValue('MAP_SETTINGS');
      this.zoom = mapSettings.zoom;

      const mapEl = this.$refs.mapEl;
      const { offsetWidth, offsetHeight } = mapEl;

      const map = new maplibregl.Map({
        container: mapEl,
        width: offsetWidth,
        height: offsetHeight,
        center: mapSettings.center,
        style:
          'https://api.maptiler.com/maps/bc61ba4d-d8c0-49f0-b6c2-52c6004aee6f/style.json?key=1CDi4tTTlJKFFQHgS5zi',
        zoom: this.zoom,
        attributionControl: false,
        logoPosition: 'bottom-right',
        willReadFrequently: true
      });
      this.map = map;
      //   map.dragPan.disable();
      map.addControl(
        new maplibregl.NavigationControl({
          showCompass: false
        })
      );

      if (!this.scrollEnabled || this.isMobile) {
        map.scrollZoom.disable();
      }

      this.map.on('drag', () => {
        this.clearHighlighted();
        this.setActiveSwap({ propositionId: null });
        if (this.activeSwap != null) {
          this.setActiveSwap({ propositionId: null, position: null });
        }
      });

      this.map.on('click', e => {
        if (this.activeSwap != null) {
          this.setActiveSwap({ propositionId: null, position: null });
        }

        // If we aren't rendering markers, try search on that specific location
        if (this.renderedMarkers.length === 0) {
          this.overlaySearch(e.lngLat, e.point);
        }
      });

      this.map.on('dragend', () => {
        clearTimeout(this.timeoutId);

        this.timeoutId = setTimeout(this.search, 200);
      });

      this.map.on('zoom', () => {
        clearTimeout(this.timeoutId);

        this.clearHighlighted();
        this.setActiveSwap({ propositionId: null });
        this.timeoutId = setTimeout(this.search, 200);
      });
    },

    createMarker(lat, lng, propositionId) {
      const el = document.createElement('div');
      el.innerHTML = '<div class="marker"></div>';
      el.id = propositionId;

      const marker = new maplibregl.Marker(el)
        .setLngLat([lat, lng])
        .addTo(this.map);
      this.renderedMarkers.push(marker);
    },
    clearMarkers() {
      this.renderedMarkers = [];
    },

    updateMarkers() {
      const { propositions, map, renderedMarkers } = this;

      // First remove all markers
      renderedMarkers.forEach(renderedMarker => {
        renderedMarker.remove();
      });

      const newRenderedMarkers = [];

      if (!propositions) {
        return;
      }

      // Then add new ones
      propositions.forEach(proposition => {
        if (
          proposition.residences[0].latitude &&
          proposition.residences[0].longitude
        ) {
          const el = document.createElement('div');
          el.innerHTML = '<div class="marker"></div>';

          el.id = proposition.propositionId;

          if (proposition.hovered) {
            el.className = 'highlight';
          }

          const [lat, lng] = [
            proposition.residences[0].latitude.toString(),
            proposition.residences[0].longitude.toString()
          ];
          const newMarker = new maplibregl.Marker(el)
            .setLngLat([lng, lat])
            .addTo(map);
          newRenderedMarkers.push(newMarker);
        }
      });

      this.renderedMarkers = newRenderedMarkers;

      this.fitToBounds();
    },

    clearHighlighted() {
      this.renderedMarkers.forEach(renderedMarker =>
        renderedMarker._element.classList.remove('highlight')
      );
    },

    markerClickedHandler(e) {
      const { target } = e;
      let position;
      let mapSize;
      // If not a proposition marker, return

      if (!target.className.includes('mapboxgl-marker')) {
        this.clearHighlighted();
        return;
      }

      if (!this.isMobile) {
        const { transform } = target.style;
        position = transform;
      }

      const mapEl = this.$refs.mapEl;
      const { offsetWidth, offsetHeight } = mapEl;
      mapSize = { width: offsetWidth, height: offsetHeight };

      const propositionId = target.id;

      this.setActiveSwap({
        propositionId,
        position,
        mapSize
      });
    },

    fitToBounds() {
      const { map, renderedMarkers } = this;

      if (!map || !renderedMarkers) return;

      const bound = new maplibregl.LngLatBounds();

      const bounds = renderedMarkers
        .filter(x => x._lngLat && (x._lngLat.lng || x._lngLat.lat))
        .map(marker => [marker._lngLat.lng, marker._lngLat.lat]);

      bounds.forEach(b => {
        bound.extend(b);
      });

      if (!bound || Object.keys(bound).length === 0) return;

      map.fitBounds(bound, { padding: 80 });
    },

    adjustToScreenSize() {
      if (!this.$refs.mapEl) return;
      const { offsetWidth, offsetHeight } = this.$refs.mapEl;

      this.map.width = offsetWidth;
      this.map.height = offsetHeight;
    },

    async search() {
      if (this.loadingData) return;

      this.loadingData = true;

      const bounds = this.map.getBounds();
      const zoom = this.map.getZoom();

      const coordinates = [
        bounds.getNorthWest().toArray(),
        bounds.getNorthEast().toArray(),
        bounds.getSouthEast().toArray(),
        bounds.getSouthWest().toArray()
      ];

      const filter = cloneDeep(this.searchFilters);

      // Avoid cutting of extremes by setting max to ridiculus number in request
      if (filter?.rent?.max >= FILTER_SETUP.rent.max) {
        filter.rent.max = 100000000;
      }
      if (filter?.rooms?.max >= FILTER_SETUP.rooms.max) {
        filter.rooms.max = 100000000;
      }
      if (filter?.floor?.max >= FILTER_SETUP.floor.max) {
        filter.floor.max = 100000000;
      }
      if (filter?.sqm?.max >= FILTER_SETUP.sqm.max) {
        filter.sqm.max = 100000000;
      }

      const swapResponse = await this.findSwaps({
        propositionId: parseInt(this.propositionId, 10),
        filter,
        northWest: bounds.getNorthWest(),
        southEast: bounds.getSouthEast(),
        zoom
      });

      this.clearMap();

      this.loadingData = false;
      this.clearMarkers();

      if (!swapResponse) return;

      for (let i = 0; i < swapResponse.swaps.length; i++) {
        for (let x = 0; x < swapResponse.swaps[i].residences.length; x++) {
          if (!swapResponse.swaps || !swapResponse.swaps[i].residences) return;

          const { lng, lat } = swapResponse.swaps[i].residences[x].coordinates;

          if (lng && lat) {
            this.createMarker(lng, lat, swapResponse.swaps[i].propositionId);
          }
        }
      }

      if (!swapResponse.clusterImage) {
        return;
      }

      if (!this.hasDoneInitialSearch) {
        this.map.fitBounds([
          [
            swapResponse.clusterBounds.southWest.lng,
            swapResponse.clusterBounds.southWest.lat
          ],
          [
            swapResponse.clusterBounds.northEast.lng,
            swapResponse.clusterBounds.northEast.lat
          ]
        ]);
        this.hasDoneInitialSearch = true;
      } else {
        this.map.addSource('clusterSrc', {
          type: 'image',
          url: 'data:image/png;base64,' + swapResponse.clusterImage,
          coordinates
        });

        this.map.addLayer({
          id: 'clusterLayer',
          source: 'clusterSrc',
          type: 'raster'
        });
      }
    },

    async overlaySearch(highlightPoint, resultLocation) {
      if (this.loadingData) return;

      this.loadingData = true;

      const bounds = this.map.getBounds();
      const zoom = this.map.getZoom();

      const coordinates = [
        bounds.getNorthWest().toArray(),
        bounds.getNorthEast().toArray(),
        bounds.getSouthEast().toArray(),
        bounds.getSouthWest().toArray()
      ];

      const swapResponse = await this.findSwaps({
        propositionId: parseInt(this.propositionId, 10),
        filter: {
          ...this.searchFilters
        },
        northWest: bounds.getNorthWest(),
        southEast: bounds.getSouthEast(),
        highlightPoint,
        zoom
      });

      this.clearMap();

      this.loadingData = false;

      if (swapResponse.clusterImage) {
        this.map.addSource('clusterSrc', {
          type: 'image',
          url: 'data:image/png;base64,' + swapResponse.clusterImage,
          coordinates
        });

        this.map.addLayer({
          id: 'clusterLayer',
          source: 'clusterSrc',
          type: 'raster'
        });
      }

      if (!swapResponse.highlighted) {
        return;
      }

      this.setSwapDetails({ swapDetails: [swapResponse.highlighted] });

      const mapEl = this.$refs.mapEl;
      const { offsetWidth, offsetHeight } = mapEl;
      let mapSize = { width: offsetWidth, height: offsetHeight };
      let position;

      if (this.isMobile) {
        resultLocation = null;
        mapSize = null;
      }

      const { propositionId } = swapResponse.highlighted;

      if (resultLocation) {
        position = `translate(-50%, -50%) translate(${resultLocation.x}px, ${resultLocation.y}px)`;
      }

      setTimeout(() => {
        this.setActiveSwap({ propositionId, position, mapSize });
      }, 100);
    },

    async findSwaps(searchModel) {
      try {
        const result = await lbApiBeta.post('/api/swapmap/search', searchModel);

        this.setSwapDetails({ swapDetails: result.data.swaps });
        return result.data;
      } catch (error) {
        // Empty catch block
      }

      return null;
    },

    clearMap() {
      if (this.map.getLayer('clusterLayer')) {
        this.map.removeLayer('clusterLayer');
      }

      if (this.map.getSource('clusterSrc')) {
        this.map.removeSource('clusterSrc');
      }

      this.renderedMarkers.forEach(marker => {
        marker.remove();
      });
    },

    async performMapSearch() {
      if (this.swapListType === SWAP_LIST_TYPES.MAP) {
        await this.search();
        this.fitToBounds();
      }
    },

    setUpFilterListener() {
      this.$root.$on('apply-filters-map', this.performMapSearch);
    },

    destroyFilterListener() {
      this.$root.$off('apply-filters-map', this.performMapSearch);
    }
  }
};
</script>

<style lang="scss" scoped>
.map-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.map {
  height: 100%;
  width: 100%;
  background-color: $bg-gray;
}
</style>
