import * as services from './services';
import i18n from '@/i18n';
import { router } from '@/main';

async function getExistingSelectedAreas(context, { propositionId, wishIndex }) {
  context.commit('setLoading', { loading: true });

  const areas = await services.getExistingAreas({ propositionId, wishIndex });

  context.commit('setExistingAreas', { areas });

  context.dispatch('getPolygons');

  context.commit('setLoading', { loading: false });
}

async function getWishAreaButtons(context) {
  const fetchedCities = await services.getWishAreaButtons();

  if (!fetchedCities.error) {
    context.commit('setWishAreaOptions', { val: fetchedCities.val });
  }
}

async function updateAreas(context, { propositionId, wishIndex }) {
  context.commit('setLoading', { loading: true });

  const { selectedAreas } = context.state;
  const geoIds = selectedAreas.map(a => a.id);

  const updateAreas = await services.updateAreas({
    propositionId,
    wishIndex,
    geoIds
  });

  if (updateAreas.status == 200 && !context.rootGetters['app/isAppRequest']) {
    router.replace(
      `${i18n.t('path_edit_proposition', i18n.routeLocale)}/${propositionId}`
    );
  }

  context.commit('setLoading', { loading: false });
}

async function performSearch(context) {
  context.commit('setSearchLoading', { loading: true });
  context.commit('setSearchNoResultsFound', { noResults: false });

  const { searchTerm } = context.state;

  const { val, error } = await services.search(searchTerm);

  if (error) {
    //Handle error
    context.commit('setSearchResult', { val: [] });
    context.commit('setSearchLoading', { loading: false });
  } else {
    context.commit('setSearchResult', { val: val });
    context.commit('setSearchLoading', { loading: false });
    if (val.length === 0) {
      context.commit('setSearchNoResultsFound', { noResults: true });
    }
  }
}

function blurAreaField(context) {
  context.commit('setSearchTerm', { inputValue: '' });
  context.commit('setSearchResult', { val: [] });
}

function selectArea(context, { area }) {
  context.commit('addWishArea', { area });
  context.commit('setSearchTerm', { inputValue: '' });
  context.commit('setSearchResult', { val: [] });

  context.dispatch('getPolygons');
}

async function getPolygons(context) {
  const { selectedAreas } = context.state;
  const areaIds = selectedAreas.map(a => a.id);
  if (areaIds.length === 0) {
    context.commit('setPolygon', {
      val: {
        type: 'Feature',
        properties: null,
        geometry: {
          type: 'Polygon',
          coordinates: []
        }
      }
    });
    return;
  }

  const { val } = await services.getMultiPolygon(areaIds);

  context.commit('setPolygon', { val });
}

function deselectArea(context, { area }) {
  context.commit('removeWishArea', { area });
  context.dispatch('getPolygons');
}

function setSearchTerm(context, { inputValue }) {
  context.commit('setSearchTerm', { inputValue });
}

function resetState(context) {
  context.commit('resetState');
}

export default {
  performSearch,
  setSearchTerm,
  getWishAreaButtons,
  blurAreaField,
  selectArea,
  getPolygons,
  deselectArea,
  getExistingSelectedAreas,
  updateAreas,
  resetState
};
