<template>
  <section class="agreement-wrapper">
    <div v-show="!checkboxHidden" class="agreement-checkbox-wrapper">
      <CheckBox
        id="termsCheckbox"
        type="checkbox"
        :label="$t('app_extra_visibility_checkbox_label')"
        :value="value"
        @change="$emit('input', $event)"
      />

      <a
        :href="$t('path_general_terms', $routeLocale)"
        rel="noopener noreferrer"
        target="_blank"
        class="terms-link"
      >
        {{ $t('klarna_button_terms') }}
      </a>
    </div>

    <section
      v-if="product && product.category == 'annonsforlangning'"
      class="order-info"
    >
      <div v-if="$country.isCountry('SE')" class="klarna-logo">
        <AImage :url="icons.GENERAL.KLARNA_LOGO" />
      </div>
      <p class="klarna-info">
        <span>
          {{ $t('order_button_proposition') }}
        </span>
      </p>
    </section>
  </section>
</template>

<script>
import CheckBox from '@/components/FormElements/Checkbox/Checkbox';
import AImage from '@/components/atomic/atoms/a-image';
import icons from '@/utils/icon-constants';

export default {
  components: { CheckBox, AImage },

  props: {
    value: {
      type: Boolean,
      default: false
    },

    checkboxHidden: {
      type: Boolean,
      default: false
    },

    product: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      icons
    };
  }
};
</script>

<style lang="scss" scoped>
.agreement-wrapper {
  margin-top: 30px;
  margin-bottom: 20px;
}

.klarna-info {
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  line-height: 1.5;
}

.agreement-checkbox-wrapper {
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
  display: flex;
}

.terms-link {
  margin-left: 3px;
  line-height: 26px;
}

.order-info {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.order-text {
  text-align: center;
  max-width: 430px;
  font-weight: 600;
  color: $text-secondary;
  font-size: 0.9rem;
  line-height: 150%;
}

.klarna-logo {
  height: 40px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media ($mobile) {
    margin-bottom: 20px;
  }
}
</style>
