import { vue, router } from '@/main';
import { convertToCoverage, SearchFilter } from '@/models/search-filter';

import {
  getSearchPropositions,
  mergeData,
  getCoverageData,
  addCoverage,
  getCoverageGeolocations,
  removeCoverage
} from './services';

const actions = {
  async addCoverage({ commit }, searchItem) {
    try {
      const coverage = convertToCoverage(searchItem);
      const result = await addCoverage(coverage);

      const coverageArrayWithGeolocation = await getCoverageGeolocations([
        result.data
      ]);

      commit('addCoverageItem', coverageArrayWithGeolocation[0]);

      return result.status;
    } catch (error) {
      console.error(error);

      if (error.response) return error.response.status;
      else return -1;
    }
  },

  async deleteCoverage({ commit }, coverageId) {
    await removeCoverage(coverageId);
    commit('removeCoverageItem', coverageId);

    return true;
  },

  async fetchCoverages({ commit }) {
    commit('setLoadingCoverage', true);

    const result = await getCoverageData();

    if (result) {
      result.data.results = await getCoverageGeolocations(result.data.results);
    }

    commit('setCoverageData', result.data);
    commit('setLoadingCoverage', false);
  },

  async fetchSearch(
    { commit, dispatch, state },
    { rootFilter, merge, skipBoundingBoxAlign }
  ) {
    // This part will load search results from localstorage to state (if any), and then go to the old scroll position.
    const searchData = localStorage.getItem('searchData');
    localStorage.removeItem('searchData');

    const searchObject = convertToCoverage(rootFilter);

    // use page number from query, if present
    if (router.currentRoute.query.page)
      searchObject.page = Number(
        router.currentRoute.query.page.replace(/[^0-9]/g, '')
      );

    if (searchData) {
      vue.$nextTick(() => {
        if (!state.data || (state.data && !state.data.results)) {
          const parsedSearchData = JSON.parse(searchData);
          commit('setData', parsedSearchData);
          dispatchMapEvents(searchObject, parsedSearchData, false);
        } else {
          dispatchMapEvents(searchObject, state.data, false);
        }

        dispatch('scrollToSavedPosition');
      });

      if (!router.currentRoute.query.page) {
        dispatch('setFilterToQuery');
      }

      return;
    }

    commit('setLoading', true);

    if (!router.currentRoute.query.page) {
      // Save the filter to filter query
      dispatch('setFilterToQuery');
    }

    try {
      const response = await getSearchPropositions(searchObject);

      if (response) {
        if (state.data && searchObject.fromMapInteraction && !merge) {
          commit('setPoints', response.data.points);
          dispatchMapEvents(
            searchObject,
            response.data,
            searchObject.fromMapInteraction && state.data
          );
        } else if (merge) {
          const mergedData = mergeData(state.data, response.data);
          commit('setData', mergedData);
        } else {
          commit('setData', response.data);
          dispatchMapEvents(
            searchObject,
            response.data,
            skipBoundingBoxAlign ? true : false
          );
        }
      }
    } catch (error) {
      console.error(error);
      commit('setData', {});
    }

    commit('setLoading', false);
  },

  async resetFilter({ commit, dispatch }, type) {
    commit('resetFilter', type);
    dispatch('setFilterToQuery');
  },

  async getQueryFilterAndSetToState({ commit }) {
    const { filter } = router.currentRoute.query;

    if (!filter) {
      const newFilter = new SearchFilter();
      commit('setRootFilter', newFilter);
      return newFilter;
    }

    try {
      const filterObjectFromQuery = JSON.parse(atob(filter));
      commit('setRootFilter', filterObjectFromQuery);
      return filterObjectFromQuery;
    } catch (error) {
      console.error('could not apply filter from query:', error);
    }
  },

  async setFilterToQuery({ state }) {
    const urlState = {
      query: {}
    };

    if (router.currentRoute.query.page) {
      urlState.query.page = router.currentRoute.query.page;
    }

    urlState.query.filter = btoa(JSON.stringify(state.filter));

    if (urlState.query.filter === router.currentRoute.query.filter) {
      //No changes
      return;
    }

    if (state.filter.searchArea) {
      router.replace(urlState);
    } else {
      router.push(urlState);
    }
  },

  scrollToSavedPosition({ commit, rootGetters }) {
    document.body.scrollHeight;
    let scrollTop = localStorage.getItem('scrollTop');
    localStorage.removeItem('scrollTop');

    if (scrollTop) {
      const isMobile = rootGetters['screenSize/isMobile'];
      vue.$nextTick(() => {
        if (isMobile) {
          commit('setSearchBottomBar', true);
          window.scrollTo(0, scrollTop);
        } else {
          document.getElementById('results').scrollTo(0, scrollTop);
        }
      });
    }
  }
};

function dispatchMapEvents(searchObject, resultData, skipBoundingBoxAlign) {
  const markerPointsEvent = new CustomEvent('markerPoints', {
    detail: resultData.points
  });

  document.dispatchEvent(markerPointsEvent);

  if (skipBoundingBoxAlign) {
    return;
  }

  if (searchObject.map) {
    document.dispatchEvent(
      new CustomEvent('boundingBox', {
        detail: searchObject.map.bounds
      })
    );
  } else if (resultData.bbox) {
    document.dispatchEvent(
      new CustomEvent('boundingBox', {
        detail: resultData.bbox
      })
    );
  } else if (searchObject.searchArea && searchObject.searchArea.bounds) {
    document.dispatchEvent(
      new CustomEvent('boundingBox', {
        detail: searchObject.searchArea.bounds
      })
    );
  }
}

export default actions;
