<template>
  <div class="loader-container">
    <div v-if="progress !== 0" class="progress-bar-wrapper">
      <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
    </div>
    <div
      v-if="
        isMobile ||
        swapFilterType === SWAP_FILTER_TYPES.UNANSWERED_NEW ||
        swapItemMode === SWAP_ITEM_TYPES.CARDS
      "
      class="card-loader-container"
    >
      <div v-for="i in currentPageSize" :key="i">
        <swap-item-loading :is-mobile="isMobile" />
      </div>
    </div>

    <div
      v-if="!isMobile && swapItemMode === SWAP_ITEM_TYPES.EXCEL"
      class="swap-list-container"
    >
      <div class="swap-table-list">
        <thead>
          <tr>
            <th class="fav-mark">
              <!--Fav mark-->
            </th>
            <th style="min-width: 100px" class="left-align">
              {{ $t('loader_container_accommodation') }}
            </th>
            <th style="min-width: 45px">{{ $t('loader_container_rooms') }}</th>
            <th style="min-width: 53px">{{ $t('loader_container_sqm') }}</th>
            <th style="min-width: 65px">{{ $t('loader_container_floor') }}</th>
            <th>{{ $t('loader_container_rent') }}</th>
            <th>{{ $t('loader_container_area') }}</th>
            <th>{{ $t('loader_container_type') }}</th>
            <th>{{ $t('loader_container_interest') }}</th>
            <th>
              <!--Interest mark-->
            </th>
          </tr>
        </thead>
        <tbody>
          <swap-list-item-loading v-for="i in currentPageSize" :key="i" />
        </tbody>
      </div>
    </div>
  </div>
</template>

<script>
import { SWAP_ITEM_TYPES, SWAP_FILTER_TYPES } from '@/store/modules/swapList';
import SwapItemLoading from './SwapItemLoading';
import SwapListItemLoading from './SwapListItemLoading';
import { mapGetters } from 'vuex';

export default {
  name: 'SwapListLoaderContainer',

  components: {
    SwapItemLoading,
    SwapListItemLoading
  },

  props: {
    progress: {
      type: Number,
      default: 0
    },
    swapItemMode: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: true
    },
    currentPageSize: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      SWAP_ITEM_TYPES,
      SWAP_FILTER_TYPES
    };
  },

  computed: {
    ...mapGetters({
      swapFilterType: 'swapList/swapFilterType'
    })
  }
};
</script>

<style lang="scss" scoped>
.progress-bar-wrapper {
  height: 5px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 10px;
}

.progress-bar {
  position: absolute;
  left: 0;
  height: 5px;
  background: #409fff;
  border-radius: 10px;
  transition: width 0.5s;
}

.swap-list-container {
  padding: 24px 0;
  width: 100%;
}

.card-loader-container {
  width: 100%;
}

.header {
  color: $black;
  font-weight: 700;
  font-size: 1.2rem;
  padding: 16px 0 0 16px;
  display: flex;

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.swap-table-list {
  display: table;
  width: 100%;
  text-align: center;
  min-width: 830px;
  overflow-x: auto;
}

.swap-table-list thead {
  font-size: 10px;
}

.swap-table-list thead th {
  min-width: 50px;
  text-align: center;
  font-size: 0.7rem;
}

.swap-table-list thead th.fav-mark {
  min-width: 25px;
}

.swap-table-list thead th.left-align {
  text-align: left;
  padding-left: 10px !important;
}
</style>
