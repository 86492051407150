const propositionsSiteCount = state => state.propositionsSiteCount;
const currentProposition = state => state.currentProposition;
const currentUser = state => state.currentProposition.user;
const currentResidenceIndex = state => state.currentResidenceIndex;
const currentResidenceCoords = state => {
  if (
    state.currentProposition &&
    state.currentProposition.residences &&
    state.currentProposition.residences[state.currentResidenceIndex] &&
    state.currentProposition.residences[state.currentResidenceIndex].longitude
  ) {
    return {
      lat: state.currentProposition.residences[state.currentResidenceIndex]
        .latitude,
      lng: state.currentProposition.residences[state.currentResidenceIndex]
        .longitude
    };
  }

  return false;
};
const baseLoading = state => state.baseLoading;
const hittaLoading = state => state.hittaLoading;
const interestDataLoading = state => state.interestDataLoading;
const sponsoredPropositions = state => state.sponsoredPropositions;
const isCurrentVisited = state => state.currentProposition.isVisited;

export default {
  currentProposition,
  currentUser,
  currentResidenceIndex,
  currentResidenceCoords,
  baseLoading,
  hittaLoading,
  interestDataLoading,
  sponsoredPropositions,
  isCurrentVisited,
  propositionsSiteCount
};
