<template>
  <div class="input-wrapper">
    <div class="masked-input" tabindex="0" @focus="onFocus('min')">
      <span
        ref="textbox-min"
        class="input"
        role="textbox"
        contenteditable
        @blur="e => onBlur(e, 'min')"
        @paste.prevent="onPaste('min')"
        v-text="value[0]"
      ></span>
      <span>{{ specialFormatting('min') }}{{ suffix }}</span>
    </div>

    <div class="range-separator">—</div>

    <div class="masked-input" tabindex="0" @focus="onFocus('max')">
      <span
        ref="textbox-max"
        class="input"
        role="textbox"
        contenteditable
        @blur="e => onBlur(e, 'max')"
        @paste.prevent="onPaste('max')"
        v-text="value[1]"
      ></span>
      <span>{{ specialFormatting('max') }}{{ suffix }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Array, required: true },
    suffix: { type: String, default: '' },
    max: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    specialCase: {
      type: Function,
      default: null
    }
  },

  methods: {
    specialFormatting(type) {
      if (!this.specialCase) return '';
      const specialCase = this.specialCase(this.value[type === 'min' ? 0 : 1]);
      if (specialCase) return specialCase;
      return '';
    },

    onBlur(_, type) {
      this.emitInput(this.$refs[`textbox-${type}`].textContent, type);
    },

    onPaste(e, type) {
      const text = (e.clipboardData || window.clipboardData).getData('text');
      this.emitInput(text, type);
    },

    emitInput(input, type) {
      const parsedNumber = parseInt(input, 10) || this.value;
      const normalizedNumber = Math.max(
        this.min || 0,
        Math.min(this.max || parsedNumber, parsedNumber)
      );
      // Without this the element sometimes won't update,
      // for example, when you enter something like 0500.
      // It will be parsed to 500 and if you previously entered
      // just plain 500 Vue won't think there is a difference.
      this.$refs[`textbox-${type}`].textContent = normalizedNumber;

      const emitValue = [
        this.$refs['textbox-min'].textContent,
        this.$refs['textbox-max'].textContent
      ];
      this.$emit('input', emitValue);
    },

    onFocus(type) {
      this.$refs[`textbox-${type}`].focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.range-separator {
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.masked-input {
  background: #fff;
  display: inline-block;
  padding: 10px;
  border: 1px solid #ecf4f9;
  border-radius: 3px;
  font-family: $font-body;
  cursor: text;
  width: 120px;
}

.input {
  outline: none;
}

// .input >>> * {
//   display: inline;
// }

// .input >>> br {
//   display: none;
// }
</style>
