function setProgressBar(state, value) {
  state.progressBar = value;
}

function setShowContextualTutorial(state, value) {
  state.showContextualTutorial = value;
}

function setEscClose(state, value) {
  state.escClose = value;
}

function incrementTutInterestMarks(state, value) {
  state.tutInterestMarks = state.tutInterestMarks + value;
}

function setTutInterestMarks(state, value) {
  state.tutInterestMarks = value;
}

function setTutStatus(state, value) {
  state.status = value;
}

function setTutActiveStep(state, value) {
  state.activeStep = value;
}

export default {
  setProgressBar,
  setShowContextualTutorial,
  setEscClose,
  incrementTutInterestMarks,
  setTutInterestMarks,
  setTutStatus,
  setTutActiveStep
};
