function loadJs() {
  return new Promise(resolve => {
    const address =
      'https://cdn.maptiler.com/maplibre-gl-js/v1.14.0/maplibre-gl.js';

    const el = document.getElementById('mapJs');
    if (el) {
      return resolve();
    }

    const script = document.createElement('script');
    script.async = true;
    script.onload = resolve;
    script.src = address;
    script.id = 'mapJs';
    document.head.appendChild(script);
  });
}

function loadCss() {
  return new Promise(resolve => {
    const address =
      'https://cdn.maptiler.com/maplibre-gl-js/v1.14.0/maplibre-gl.css';

    const el = document.getElementById('mapCss');
    if (el) {
      return resolve();
    }

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.async = true;
    link.onload = () => {
      setTimeout(() => {
        resolve();
      }, 500);
    };
    link.href = address;
    link.id = 'mapCss';
    document.head.appendChild(link);
  });
}

export { loadJs, loadCss };
