var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.blurContent ? 'div' : 'router-link',{tag:"component",class:['swap-list-item', { toned: _vm.toned }, { 'blur-content': _vm.blurContent }],attrs:{"to":{
    query: {
      propositionPageId: _vm.swap.propositionId,
      type: _vm.swap.details.type
    }
  }},on:{"click":_vm.handlePropositionCardClick},nativeOn:{"click":function($event){return _vm.handlePropositionCardClick.apply(null, arguments)},"contextmenu":function($event){return _vm.handlePropositionCardRightClick.apply(null, arguments)}}},[_c('div',{staticClass:"column fav-column",staticStyle:{"min-width":"22px"}},[(_vm.isUserList)?_c('div',{class:['favourite-icon', { marked: _vm.swap.isFavourite }],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleMarkFavourite.apply(null, arguments)}}}):_vm._e()]),_c('div',{staticClass:"column image-address-column"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},_vm._l((_vm.swap.residences),function(residence,index){return _c('div',{key:residence.index,staticStyle:{"display":"flex"},style:([index === 1 ? { marginTop: '6px' } : ''])},[_c('div',{class:[
            'swap-list-item-image',
            { 'has-image': residence.pictures[0] }
          ],style:([
            residence.pictures[0]
              ? {
                  backgroundImage: ("url(" + (residence.pictures[0]) + ")"),
                  backgroundSize: 'cover'
                }
              : ''
          ]),on:{"mouseenter":function($event){_vm.imageHovered = index},"mouseleave":function($event){_vm.imageHovered = false}}},[_c('transition',{attrs:{"name":"scale","mode":"out-in"}},[(residence.pictures[0] && _vm.imageHovered === index)?_c('div',{staticClass:"zoomed-image",style:([
                {
                  backgroundImage: ("url(" + (_vm.getHighDef(residence.pictures[0])) + ")")
                }
              ])}):_vm._e()])],1),_c('div',{staticClass:"address-info-wrapper"},[_c('div',{staticClass:"address-info"},[_c('div',{staticClass:"address"},[_vm._v(" "+_vm._s(residence.streetAddress)+" "),(_vm.swap.verified)?_c('BaseIcon',{staticClass:"verified-badge",attrs:{"icon":"verified","width":14,"height":14}}):_vm._e()],1),(
                _vm.swap.details.percentage === 100 &&
                _vm.isUserList &&
                !_vm.matchesViewABTest
              )?[_c('BaseButton',{staticClass:"chat-button",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openPropositionModalTab('chatTab')}}},[(!_vm.swap.chat)?[_vm._v(" "+_vm._s(_vm.$t('swap_item_start_chat'))+" ")]:(_vm.swap.chat && !_vm.swap.chat.unreadCount)?[_vm._v(" "+_vm._s(_vm.$t('swap_item_open_chat'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('swap_item_chat_x_unread', { unread: _vm.swap.chat.unreadCount }))+" ")]],2)]:_vm._e()],2)])])}),0)]),_c('div',{class:[
      { 'diff-value': _vm.swap.diff && _vm.swap.diff.rooms },
      'column rooms-column'
    ]},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"space-around"}},_vm._l((_vm.swap.residences),function(residence){return _c('div',{key:residence.index,staticClass:"flex-column",staticStyle:{"height":"50px","display":"flex","align-items":"center","justify-content":"flex-end"}},[_vm._v(" "+_vm._s(residence.rooms)+" ")])}),0)]),_c('div',{class:[
      { 'diff-value': _vm.swap.diff && _vm.swap.diff.sqm },
      'column sqm-column'
    ]},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},_vm._l((_vm.swap.residences),function(residence){return _c('div',{key:residence.index,staticClass:"flex-column",staticStyle:{"height":"50px","display":"flex","align-items":"center","justify-content":"flex-end"}},[_vm._v(" "+_vm._s(residence.sqm)+" ")])}),0)]),_c('div',{class:[
      { 'diff-value': _vm.swap.diff && _vm.swap.diff.floor },
      'column floor-column'
    ]},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},_vm._l((_vm.swap.residences),function(residence){return _c('div',{key:residence.index,staticClass:"flex-column",staticStyle:{"height":"50px","display":"flex","align-items":"center","justify-content":"flex-end"}},[_vm._v(" "+_vm._s(residence.floor)+" ")])}),0)]),_c('div',{class:[
      { 'diff-value': _vm.swap.diff && _vm.swap.diff.rent },
      'column rent-column'
    ]},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},_vm._l((_vm.swap.residences),function(residence){return _c('div',{key:residence.index,staticStyle:{"height":"50px","display":"flex","align-items":"center","justify-content":"flex-end"}},[_vm._v(" "+_vm._s(_vm.spacedRent(residence))+" ")])}),0)]),_c('div',{class:[
      { 'diff-value': _vm.swap.diff && _vm.swap.diff.geolocation },
      'column postal-area-column'
    ]},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},_vm._l((_vm.swap.residences),function(residence){return _c('div',{key:residence.index,staticStyle:{"height":"50px","display":"flex","align-items":"center","justify-content":"center"}},[_vm._v(" "+_vm._s(residence.postalArea)+" ")])}),0)]),_c('div',{staticClass:"column swap-type-column"},[_vm._v(" "+_vm._s(_vm.swapTypeReadable)+" ")]),_c('div',{staticClass:"column interest-column"},[_c('div',{staticClass:"interest-area"},[_c('interest-indicators',{attrs:{"interests":_vm.interests,"list-size":true}})],1)]),_c('div',{staticClass:"column min-width-95 interest-button-column",style:(_vm.swap.residences.length > 1 ? { height: '115px' } : '')},[(_vm.isUserList)?_c('interest-buttons',{attrs:{"interested":_vm.interests[0],"target-proposition-id":_vm.swap.propositionId.toString()}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }