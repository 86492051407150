import { cloneDeep } from 'lodash';

function matchesCancellableScenario(dispatch, compareData) {
  switch (dispatch.name) {
    case 'interests/markInterest':
      if (
        dispatch.body.targetPropositionId.toString() ===
        compareData.body.targetPropositionId.toString()
      ) {
        return true;
      }
      return false;
    default:
      return false;
  }
}

function setToast(
  context,
  { title, dispatch, timer = 8, showTimer, uiDispatch, rejectedUiDispatch }
) {
  if (uiDispatch) {
    const oldDispatch = cloneDeep(context.state.dispatch);
    if (oldDispatch) {
      if (
        oldDispatch.body.cancellable &&
        matchesCancellableScenario(oldDispatch, dispatch)
      ) {
        context.commit('cancelToast');
        window.Logger.log('Canceled earlier dispatch in toast');
      } else {
        context.dispatch(oldDispatch.name, oldDispatch.body, { root: true });
        context.commit('cancelToast');
      }
    }

    context.dispatch(uiDispatch.name, uiDispatch.body, { root: true });
    context.commit('setRejectedUiDispatch', { dispatch: rejectedUiDispatch });
  }

  context.commit('setTitle', { title });
  context.commit('setOpen', { open: true });
  context.commit('setTimer', { timer });
  context.commit('setShowTimer', { showTimer });

  context.commit('setDispatch', { dispatch });

  let timeLeft = timer;
  context.commit('setInterval', {
    interval: setInterval(() => {
      timeLeft = timeLeft - 1;
      context.commit('setTimer', { timer: timeLeft });
      if (timeLeft === 0) {
        if (dispatch) {
          context.dispatch(dispatch.name, dispatch.body, { root: true });
        }
        context.commit('cancelToast');
      }
    }, 1000)
  });
}

function cancelToast(context) {
  const { rejectedUiDispatch } = context.state;
  context.dispatch(rejectedUiDispatch.name, rejectedUiDispatch.body, {
    root: true
  });
  context.commit('cancelToast');
}

export default { setToast, cancelToast };
