<template>
  <div :id="id" class="hidden" />
</template>

<script>
import { isInViewport, isOutsideViewport } from '@/utils/helpers/dom';
import { randomUuid } from '@/utils/helpers/string';

export default {
  name: 'AViewPort',
  props: {
    debounce: {
      type: Number,
      default: 200
    },

    defer: {
      type: Number,
      default: 0
    },

    mode: {
      type: String,
      default: 'in'
      // modes: in, out, above, under
    },

    offset: {
      type: Number,
      default: 0
    },

    stop: {
      type: Boolean,
      default: true
    },

    elementId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      id: randomUuid(),
      checking: false
    };
  },

  async mounted() {
    if (this.defer !== 0) await this.wait(this.defer);

    let element = document;

    if (this.elementId) element = document.getElementById(this.elementId);

    if (element) {
      element.addEventListener('wheel', this.checkViewport);
      element.addEventListener('touchmove', this.checkViewport);
      element.addEventListener('scroll', this.checkViewport);
    }

    this.checkViewport();
  },

  beforeDestroy() {
    this.removeEventListners();
  },

  methods: {
    removeEventListners() {
      let element = document;
      if (this.elementId) element = document.getElementById(this.elementId);

      if (element) {
        element.removeEventListener('wheel', this.checkViewport);
        element.removeEventListener('touchmove', this.checkViewport);
        element.removeEventListener('scroll', this.checkViewport);
      }
    },
    checkViewport() {
      if (!this.checking) {
        this.checking = true;

        setTimeout(() => {
          const el = document.getElementById(this.id);
          let result;

          if (this.mode == 'in' || this.mode == 'out')
            result = isInViewport(el, this.offset);
          else result = isOutsideViewport(el, this.offset, this.mode);

          this.checking = false;

          if (
            (result && this.mode == 'in') ||
            (!result && this.mode == 'out') ||
            (result && this.mode != 'in' && this.mode != 'out')
          ) {
            if (this.stop) {
              this.removeEventListners();
            }

            this.$emit('change', true);
          }
        }, this.debounce);
      }
    },
    wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, ms);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.hidden {
  height: 0;
  widows: 0;
}
</style>
