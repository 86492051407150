<template>
  <div :class="['proposition-buttons-container', { onboarding: isOnboarding }]">
    <InterestToggle
      :interested="interested"
      :toggle-interest="markInterest"
      :is-loading="isLoading"
      @toggleConversionModal="() => $emit('toggleConversionModal')"
    />
  </div>
</template>

<script>
import InterestToggle from '../SwapComponents/InterestToggle';

export default {
  name: 'PropositionButtons',

  components: {
    InterestToggle
  },

  props: {
    isOnboarding: {
      type: Boolean,
      default: false
    },
    interested: {
      type: [Boolean, null],
      required: false,
      default: null
    },
    markInterest: {
      type: Function,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.proposition-buttons-container {
  padding: 0px;
  width: 100%;

  @media ($desktop) {
    padding: 0 20px;
    position: relative;
  }
  @media ($mobile) {
    // border-top: 1px solid rgba(28, 45, 65, 0.103);
    // background: #fff;
  }
}
</style>
