<template>
  <div class="edit-proposition-container">
    <div v-if="proposition" class="edit-proposition">
      <h1 class="page-heading">
        {{ $t('edit_prop_page_title') }} –
        <span class="u-no-wrap">{{
          proposition.residences[0].streetAddress
        }}</span>
        <template v-if="proposition.residences[1]">
          &
          <span class="u-no-wrap">{{
            proposition.residences[1].streetAddress
          }}</span>
        </template>
      </h1>
      <div class="link-groups">
        <div class="link-group">
          <h3 class="link-group-heading">
            {{ $t('edit_prop_you_apartment') }}
          </h3>
          <div
            v-for="(residence, index) in proposition.residences"
            :key="index"
            class="link-section"
          >
            <h4
              v-if="proposition.residences.length == 2"
              class="link-group-sub-heading"
            >
              {{ residence.streetAddress }}
            </h4>
            <router-link
              :to="editPhotosPath(residence.residenceIndex)"
              class="link-group-link"
              ><BaseIcon icon-file="icon-image-v" class="link-group-icon" />{{
                residence.images.length == 0
                  ? $t('edit_prop_add_imgs')
                  : $t('edit_prop_edit_imgs')
              }}</router-link
            >
            <router-link
              :to="editDescriptionPath(residence.residenceIndex)"
              class="link-group-link"
              ><BaseIcon icon-file="icon-pen" class="link-group-icon" />{{
                residence.description
                  ? $t('edit_prop_edit_desc')
                  : $t('edit_prop_add_desc')
              }}</router-link
            >
            <router-link
              :to="editGeneralDetailsPath(residence.residenceIndex)"
              class="link-group-link"
              ><BaseIcon
                icon-file="icon-clipboard-notes"
                class="link-group-icon"
              />{{ $t('edit_prop_update_apt_info') }}</router-link
            >
          </div>
        </div>
        <div class="link-group">
          <h3 class="link-group-heading">{{ $t('edit_prop_your_wishes') }}</h3>
          <div
            v-for="(wish, index) in proposition.wishes"
            :key="index"
            class="link-section"
          >
            <h4
              v-if="proposition.wishes.length == 2"
              class="link-group-sub-heading"
            >
              {{ $t('edit_prop_wish_x') }} {{ index + 1 }}
            </h4>
            <router-link
              :to="
                $t('path_edit_proposition_wishes') +
                '/' +
                propositionId +
                '/' +
                (index + 1)
              "
              class="link-group-link"
              ><BaseIcon
                icon-file="icon-file-heart"
                class="link-group-icon"
              />{{ $t('edit_prop_edit_wishes') }}</router-link
            >
            <p class="link-label">
              — {{ wish.rooms.min }}
              {{ $tc('general_rooms', Number(wish.rooms.min)) }},
              {{ wish.sqm.min }} {{ $t('general_sqm_short') }},
              {{ $t('general_max') }}
              {{ (wish.rent || wish.fee).max.toLocaleString() }}
              {{ $t('general_currency_per_month_short') }}
            </p>
            <router-link
              :to="`${$routes.EDIT_WISH_AREAS}/${propositionId}/${index + 1}`"
              class="link-group-link"
              ><BaseIcon icon-file="icon-map" class="link-group-icon" />{{
                $t('edit_prop_edit_areas')
              }}</router-link
            >
            <template v-if="proposition.wishes.length !== 2">
              <router-link
                :to="
                  $t('path_edit_proposition_wishes') +
                  '/' +
                  propositionId +
                  '/new'
                "
                class="link-group-link"
                ><BaseIcon
                  icon-file="icon-plus-circle"
                  class="link-group-icon"
                />{{ $t('edit_prop_add_additional') }}</router-link
              >
              <p class="link-label">— {{ $t('edit_prop_addition_label') }}</p>
            </template>
          </div>
        </div>

        <div class="link-group">
          <h3 class="link-group-heading">{{ $t('edit_prop_settings') }}</h3>
          <div class="link-section">
            <router-link :to="propositionSettingsLink" class="link-group-link"
              ><BaseIcon
                icon-file="icon-minus-path"
                class="link-group-icon"
              />{{ $t('edit_prop_settings_link') }}
            </router-link>
          </div>
        </div>
      </div>
      <h1 class="page-sub-heading">{{ $t('edit_prop_ad_preview') }}</h1>
      <div class="proposition-card-container">
        <BasePropositionCard
          v-if="proposition"
          :swap="proposition"
          :is-link="false"
          style="margin: 0"
        />
      </div>
      <div class="go-to-prop-page-container">
        <BaseLink :link="propositionPageLink">{{
          $t('edit_prop_show_ad_page')
        }}</BaseLink>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EditProposition',

  data() {
    return {
      propositionId: null,
      proposition: null
    };
  },

  head: {
    title() {
      return {
        inner:
          this.$t('edit_prop_meta') +
            this.proposition?.residences[0].streetAddress || ''
      };
    }
  },

  computed: {
    ...mapGetters({
      userPropositions: 'myPropositions/propositions'
    }),

    propositionPageLink() {
      return `${this.$routes.PROPOSITION}/${this.propositionId}`;
    },

    propositionSettingsLink() {
      return `${this.$routes.EDIT_PROP_SETTINGS}/${this.propositionId}`;
    }
  },

  created() {
    this.propositionId = this.$route.params.propositionId;

    window.scrollTo(0, 0);

    this.getProposition();
  },

  methods: {
    ...mapActions({
      fetchPropositions: 'myPropositions/fetchPropositions'
    }),

    async getProposition() {
      await this.fetchPropositions();

      this.proposition = this.userPropositions.find(
        id => id.propositionId == this.propositionId
      );

      this.$emit('updateHead');
    },

    editPhotosPath(residenceIndex) {
      return (
        this.$routes.EDIT_PROPOSITION_PHOTOS +
        '/' +
        this.propositionId +
        '/' +
        residenceIndex
      );
    },

    editDescriptionPath(residenceIndex) {
      return (
        this.$t('path_edit_proposition_description', this.$routeLocale) +
        '/' +
        this.propositionId +
        '/' +
        residenceIndex
      );
    },

    editGeneralDetailsPath(residenceIndex) {
      return (
        this.$t('path_edit_proposition_details', this.$routeLocale) +
        '/' +
        this.propositionId +
        '/' +
        residenceIndex
      );
    },

    editGeneralSettingsPath() {
      return (
        this.$t('path_edit_swap', this.$routeLocale) +
        '/' +
        this.propositionId +
        this.$t('path_proposition_settings', this.$routeLocale)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-proposition-container {
  max-width: none;
  width: 100%;
  background-color: #f8f8fa;
  min-height: 100vh;
}

.edit-proposition {
  padding: 0 10px;

  @media ($small-desktop) {
    width: 96%;
  }

  @media ($desktop) {
    max-width: 1000px;
    width: 90%;
    margin: 0 auto;
  }
}

.page-heading {
  margin-top: 0;
  font-size: 28px;

  @media ($mobile) {
    margin-top: 30px;
    font-size: 26px;
  }
}

.page-sub-heading {
  margin-top: 0;
  font-size: 22px;
}

.proposition-card-container {
  margin-bottom: 30px;

  @media ($mobile) {
    margin-right: -5px;
    margin-left: -5px;
    padding: 1px 6px;
    background-color: #fff;
    border-radius: 4px;
  }
}

.link-groups {
  display: grid;
  gap: 16px;
  margin-bottom: 50px;
}

.link-group {
  padding: 16px;
  padding-bottom: 6px;
  border-radius: 4px;
  font-weight: 600;
  background-color: #fff;
  border: 1px solid rgba(28, 45, 65, 0.15);
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 5%);
}

.link-section {
  margin-bottom: 20px;
}

.link-group-heading {
  margin-top: 4px;
  margin-bottom: 14px;
  font-size: 22px;
}

.link-group-sub-heading {
  margin-top: 0;
  margin-bottom: 8px;
}

.link-group-link {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.link-group-icon {
  margin-right: 10px;
}

.link-label {
  margin: 0 38px;
  margin-top: -12px;
  margin-bottom: 10px;
  color: $text-secondary;
  font-size: 12px;
  line-height: 1.2;
}

.go-to-prop-page-container {
  text-align: center;
  margin-bottom: 40px;
}
</style>
