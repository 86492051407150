function setTitle(state, { title }) {
  state.title = title;
}
function setOpen(state, { open }) {
  state.open = open;
}
function setTimer(state, { timer }) {
  state.timer = timer;
}
function setShowTimer(state, { showTimer }) {
  state.showTimer = showTimer;
}
function setInterval(state, { interval }) {
  state.interval = interval;
}
function setDispatch(state, { dispatch }) {
  state.dispatch = dispatch;
}
function cancelToast(state) {
  clearInterval(state.interval);
  state.interval = undefined;
  state.open = false;
  state.title = '';
  state.timer = 0;
  state.dispatch = undefined;
  state.rejectedUiDispatch = undefined;
}
function setRejectedUiDispatch(state, { dispatch }) {
  state.rejectedUiDispatch = dispatch;
}

export default {
  setTitle,
  setOpen,
  cancelToast,
  setInterval,
  setTimer,
  setShowTimer,
  setDispatch,
  setRejectedUiDispatch
};
