<template>
  <div class="cancel-step-wrapper">
    <h2 class="title">{{ $t('search_coverage_title') }}</h2>

    <p class="subtitle">{{ $t('search_coverage_subtitle_1') }}</p>
    <p class="subtitle">{{ $t('search_coverage_subtitle_2') }}</p>

    <div class="button-wrapper">
      <primary-button>{{ $t('search_coverage_action_button') }}</primary-button>

      <link-button @click.native="closeFunction">{{
        $t('search_coverage_link_cancel')
      }}</link-button>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '../../Buttons/Primary';

import LinkButton from '../../Buttons/Link';

export default {
  name: 'CancelSubscriptionSearchCoverage',

  components: {
    PrimaryButton,
    LinkButton
  },

  props: {
    closeFunction: {
      type: Function,
      required: true
    },
    goToStep: {
      type: Function,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.cancel-step-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  max-width: 800px;
  margin: 0 auto;

  @media ($mobile) {
    padding-bottom: 50px;
  }
}

.title {
  color: $text-primary;
  @media ($mobile) {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  @media ($desktop) {
    margin-top: 40px;
    font-size: 1.8rem;
  }
}

.subtitle {
  font-size: 1.4rem;
  color: $black;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
  @media ($mobile) {
    font-size: 1.4rem;
  }
}

.button-wrapper {
  margin: 30px 0;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 10px;
  }
}
</style>
