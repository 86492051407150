function setTrialBanner(context, { primaryPropositionId, propositions }) {
  if (!propositions) return;

  const primaryProposition = propositions.find(
    proposition =>
      primaryPropositionId &&
      proposition.propositionId.toString() === primaryPropositionId.toString()
  );

  if (primaryProposition && primaryProposition.isTrial) {
    context.commit(
      'trial/setTrialData',
      { data: primaryProposition.paidUntil },
      { root: true }
    );
  } else {
    context.commit('removeTrialData');
  }
}

function temporaryHideTrialBanner(context, { hide }) {
  context.commit('temporaryHideTrialBanner', { hide });
}

function setOutdatedTrials(context, { propositions }) {
  // check if there is any active propositions
  const activeProposition = propositions.find(
    proposition => proposition.active
  );
  // if any active, don't display cta
  if (activeProposition) {
    context.commit('removeDisplayedOutdatedTrial');
    return;
  }

  const now = new Date();

  const outdatedTrialPropositions = propositions.filter(proposition => {
    const { isTrial, active, paidUntil } = proposition;

    if (!isTrial) return false;

    const endDate = new Date(paidUntil);

    // If enddate is before now, the trial is outdated
    if (isTrial && endDate < now && !active) {
      return true;
    }
  });

  if (outdatedTrialPropositions.length === 0) {
    context.commit('removeDisplayedOutdatedTrial');
    return;
  }

  const displayedOutdatedTrial = outdatedTrialPropositions[0];

  context.commit('setDisplayedOutdatedTrial', { displayedOutdatedTrial });
}

export default {
  setTrialBanner,
  setOutdatedTrials,
  temporaryHideTrialBanner
};
