import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  showMobileHeader: true,
  showDesktopHeader: true,
  showGlobalModal: '',
  showCookiesModal: false,
  propositionViewModalId: null,
  propositionType: null,
  pageModalActivePage: 'proposition',
  pageModalZIndex: null,
  disableScroll: [],
  freemiumBannerTopMargin: '0px',
  activeModals: [],
  usedModals: [],
  currentModal: ''
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
