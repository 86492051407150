<template>
  <div class="trial-banner">
    <div class="inner-wrap">
      <div class="trial-bar-wrapper">
        <div class="text-wrapper">
          <p class="header" v-html="headerText" />
          <p v-if="!isMobile" class="text">{{ promotionText }}</p>
        </div>

        <router-link
          class="secondary-button"
          :to="`${$t(
            'path_payment_proposition',
            $routeLocale
          )}/${primaryPropositionId}`"
        >
          {{ $t('trial_bottom_banner_action_link') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import i18n from '@/i18n';

const HEADER_VARIANTS = {
  0: days => i18n.t('trial_bottom_banner_days_left_5_header', { days: days }),
  1: days => i18n.t('trial_bottom_banner_days_left_4_header', { days: days }),
  2: days => i18n.t('trial_bottom_banner_days_left_3_header', { days: days }),
  3: days => i18n.t('trial_bottom_banner_days_left_2_header', { days: days }),
  4: () => i18n.t('trial_bottom_banner_days_left_1_header'),
  5: () => i18n.t('trial_bottom_banner_days_left_0_header')
};

const PROMOTION_VARIANTS = {
  0: () => i18n.t('trial_bottom_banner_days_left_5_body'),
  1: () => i18n.t('trial_bottom_banner_days_left_4_body'),
  2: () => i18n.t('trial_bottom_banner_days_left_3_body'),
  3: () => i18n.t('trial_bottom_banner_days_left_2_body'),
  4: () => i18n.t('trial_bottom_banner_days_left_1_body'),
  5: () => i18n.t('trial_bottom_banner_days_left_0_body')
};

export default {
  name: 'TrialBanner',

  computed: {
    primaryPropositionId() {
      if (this.primaryProposition) return this.primaryProposition.propositionId;
      return '';
    },

    ...mapGetters({
      daysLeft: 'trial/trialDaysLeft',
      primaryProposition: 'myPropositions/primaryProposition',
      isMobile: 'screenSize/isMobile',
      user: 'app/user'
    }),

    headerText() {
      if (this.daysLeft >= 5) return HEADER_VARIANTS[0](this.daysLeft);
      if (this.daysLeft === 4) return HEADER_VARIANTS[1](this.daysLeft);
      if (this.daysLeft === 3) return HEADER_VARIANTS[2](this.daysLeft);
      if (this.daysLeft === 2) return HEADER_VARIANTS[3](this.daysLeft);
      if (this.daysLeft === 1) return HEADER_VARIANTS[4]();
      if (this.daysLeft <= 0) return HEADER_VARIANTS[5]();

      return '';
    },

    promotionText() {
      if (this.daysLeft >= 5) return PROMOTION_VARIANTS[0]();
      if (this.daysLeft === 4) return PROMOTION_VARIANTS[1]();
      if (this.daysLeft === 3) return PROMOTION_VARIANTS[2]();
      if (this.daysLeft === 2) return PROMOTION_VARIANTS[3]();
      if (this.daysLeft === 1) return PROMOTION_VARIANTS[4]();
      if (this.daysLeft <= 0) return PROMOTION_VARIANTS[5]();

      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
.trial-banner {
  width: 100%;
  background-color: $text-primary;
  z-index: 1;

  @media ($desktop) {
    height: 103px;
    position: relative;
    top: 80px;
  }
}

.fixed-header-adjusted {
  margin-top: $mobile-static-header;
  margin-bottom: $mobile-static-header;
}

.inner-wrap {
  @media ($desktop) {
    height: 100%;
    position: relative;
    width: 100%;
  }
}

.trial-bar-wrapper {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 28px 18px;
  width: 100%;

  @media ($desktop) {
    flex-direction: row;
    justify-content: center;
    padding: 0 20px;
    margin: 0 auto;
    margin-bottom: 0px;
  }
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  position: relative;
  top: -2px;
  flex-grow: 1;

  @media ($small-desktop) {
    max-width: 670px;
  }
}

.header {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-block-end: 0;
  margin-block-start: 0;
  padding-right: 10px;

  @media ($desktop) {
    font-size: 18px;
  }
}

.text {
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  margin: 5px 0 0 0;
  padding-right: 50px;
  width: 100%; //IE
}

.secondary-button {
  background-color: $main-blue;
  border-radius: 18px;
  color: #fff;
  display: inline-block;
  font-size: 0.82em;
  font-weight: 600;
  letter-spacing: 0.7px;
  height: 30px;
  width: 200px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  z-index: 2;

  &:hover {
    opacity: 0.8;
  }
}
</style>
