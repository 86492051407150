import mixpanel from 'mixpanel-browser';
import { getValue } from '../../../plugins/country/module';

import { addDataLayer } from '@/utils/gtm';

let mixpanelInitialized = false;

function startMixpanelSession(ctx, data) {
  try {
    const mixpanelToken = getValue('MIXPANEL');
    const domain = getValue('DOMAIN');
    if (mixpanelToken) {
      mixpanel.init(mixpanelToken);
      mixpanel.identify(data.userId);

      const mixpanelData = {
        'User Id': data.userId,
        'Is Trial': data.isTrial,
        'Account Type': data.accountType,
        'User Created At': data.userCreatedAt,
        'Active Propositions': data.userActiveAds,
        'In Swap Process': data.inSwapProcess,
        'Mobile User': data.isMobileUser
      };
      mixpanel.register(mixpanelData);
      mixpanel.people.set({
        ...mixpanelData,
        'Number Of Proposition Images': data.userNoAdPictures
      });

      if (
        !document.referrer.includes(domain) ||
        document.referrer.includes('logga-in')
      )
        mixpanel.track('Session Start');

      mixpanelInitialized = true;
    }
  } catch (error) {
    console.error('Error in mixpanel session start:', error);
  }
}

function trialMessageClicked(ctx, { where }) {
  try {
    addDataLayer({
      category: 'message',
      action: 'click',
      userId: ctx.rootState.app.user.userId,
      what: 'trial',
      where
    });
    if (mixpanelInitialized)
      mixpanel.track('Trial tried open messages', { Where: where });
  } catch (error) {
    console.error('Could not addDataLayer:', error);
  }
}

function updatedProfilePic(ctx) {
  try {
    addDataLayer({
      category: 'user',
      action: 'uploaded',
      userId: ctx.rootState.app.user.userId,
      what: 'profile-pic'
    });
    if (mixpanelInitialized) mixpanel.track('Uploaded Profile Picture');
  } catch (error) {
    console.error('Could not addDataLayer:', error);
  }
}

function sentMessage(ctx, data) {
  try {
    addDataLayer({
      category: 'message',
      action: 'sent',
      what: 'text',
      userId: ctx.rootState.app.user.userId,
      from: data.from,
      to: data.to,
      where: data.where
    });
    if (mixpanelInitialized)
      mixpanel.track('Sent Message', {
        'Message Type': data.text,
        'User Id': ctx.rootState.app.user.userId,
        'From Proposition': data.from,
        'To Propositions': data.to,
        Where: data.where
      });
  } catch (error) {
    console.error('Could not addDataLayer:', error);
  }
}

function interestMarked(ctx, data) {
  try {
    const interest = data.interest ? 'interested' : 'not-interested';
    addDataLayer({
      category: 'proposition',
      action: 'marked-interest',
      what: interest,
      propositionId: data.propositionId,
      interestedIn: data.propositionId2,
      userId: ctx.rootState.app.user.userId,
      where: data.where
    });
    if (mixpanelInitialized)
      mixpanel.track('Interest Mark', {
        Interest: interest,
        'User Id': ctx.rootState.app.user.userId,
        'Proposition Id': data.propositionId,
        'Proposition Marked': data.propositionId2,
        Where: data.where
      });
  } catch (error) {
    console.error('Could not addDataLayer:', error);
  }
}

function loadSwapList(ctx, data) {
  try {
    addDataLayer({
      category: 'swapList',
      action: 'loaded',
      userId: ctx.rootState.app.user.userId,
      ...data
    });
    if (mixpanelInitialized)
      mixpanel.track('Loaded Swap List', {
        'User Id': ctx.rootState.app.user.userId,
        'Current Page': data.currentPage,
        'Total Pages': data.totalPages,
        'Total Swaps In List': data.preFilterCountTotal,
        'Total Swaps Not Marked': data.preFilterCountUnmarked
      });
  } catch (error) {
    console.error('Could not addDataLayer:', error);
  }
}

function purchaseProduct(ctx, data) {
  try {
    const toSend = {
      category: 'purchase',
      action: data.action,
      what: data.what,
      userId: ctx.rootState.app.user.userId,
      productId: data.product.id || data.product.productId,
      value: data.product.priceInclVat
    };

    if (data.propositionId) {
      toSend.propositionId = data.propositionId;
    }

    addDataLayer(toSend);
    addDataLayer({ ...toSend, action: 'any' });
    if (mixpanelInitialized) {
      const mixpanelData = {
        'First Time': data.action,
        'Paid For': data.what,
        'User Id': ctx.rootState.app.user.userId,
        'Product Id': toSend.productId,
        Price: toSend.value
      };
      if (data.propositionId)
        mixpanelData['Proposition Id'] = data.propositionId;
      mixpanel.track('Paid', mixpanelData);
    }
  } catch (error) {
    console.error('Could not addDataLayer:', error);
  }
}

function acceptPropositionWinbackOffer(ctx, data) {
  try {
    const toSend = {
      category: 'purchase',
      action: 'winback',
      what: 'proposition',
      userId: ctx.rootState.app.user.userId,
      productId: data.productId,
      propositionId: data.propositionId,
      value: data.value
    };

    addDataLayer(toSend);
  } catch (error) {
    console.error('Could not addDataLayer:', error);
  }
}

function cancelPurchasedProduct(ctx, data) {
  try {
    const toSend = {
      category: 'purchase',
      action: 'cancel',
      userId: ctx.rootState.app.user.userId,
      what: data.what
    };

    if (data.propositionId) toSend.propositionId = data.propositionId;

    addDataLayer(toSend);
  } catch (error) {
    console.error('Could not addDataLayer:', error);
  }
}

function newAccountType(ctx, newAccountType) {
  try {
    addDataLayer({
      category: 'accountType',
      action: 'change',
      userId: ctx.rootState.app.user.userId,
      label: newAccountType
    });
    if (mixpanelInitialized)
      mixpanel.people.set({
        'Account Type': newAccountType
      });
  } catch (error) {
    console.error('Could not addDataLayer:', error);
  }
}

function checkCurrentAccountType({ state, commit, rootGetters, dispatch }) {
  const newAccountType = rootGetters['myPropositions/accountType'];

  if (!newAccountType) return false;

  if (!state.currentAccountType) {
    commit('setCurrentAccountType', newAccountType);
    return false;
  }

  if (newAccountType == state.currentAccountType) return false;

  commit('setCurrentAccountType', newAccountType);
  dispatch('newAccountType', newAccountType);
}

function cancelledPropositionReason(ctx, data) {
  try {
    addDataLayer({
      category: 'proposition',
      action: 'cancel-reason',
      userId: ctx.rootState.app.user.userId,
      feedback: data.feedback,
      reason: data.category,
      specifiedCategory: data.specifiedCategory,
      propositionId: data.propositionId
    });
    if (mixpanelInitialized)
      mixpanel.track('Proposition Cancelled With Reason', {
        'User Id': ctx.rootState.app.user.userId,
        'Main Reason': data.category,
        'Specific Reason': data.specifiedCategory,
        'Proposition Id': data.propositionId,
        Feedback: data.feedback
      });
  } catch (error) {
    console.error('Could not addDataLayer:', error);
  }
}

export default {
  startMixpanelSession,
  trialMessageClicked,
  interestMarked,
  sentMessage,
  loadSwapList,
  purchaseProduct,
  newAccountType,
  checkCurrentAccountType,
  cancelPurchasedProduct,
  acceptPropositionWinbackOffer,
  updatedProfilePic,
  cancelledPropositionReason
};
