<template>
  <div class="image-slider-wrapper">
    <div class="carousel-container">
      <VueSlickCarousel
        v-if="propositionImages && propositionImages.length > 0"
        ref="slickRef"
        v-bind="effectiveCarouselSettings"
        class="slick-carousel"
        @afterChange="handleAfterChange"
        @swipe="handleSwipe"
      >
        <div
          v-for="(image, index) in propositionImages"
          :key="index"
          class="image-wrapper"
        >
          <img
            :src="image"
            :class="[
              'primary-image',
              {
                'is-blurred': blurImage && index >= 2
              }
            ]"
          />
          <div v-if="blurImage && index >= 2" class="overlay"></div>
          <div v-if="blurImage && index >= 2" class="blur-msg-wrapper">
            <div class="blur-msg-click-container">
              <BaseIcon icon-file="blur-img-eye" :width="42" :height="34" />

              <div class="blur-msg">
                <p class="unblock-text">
                  {{ $t('unlock_blur_msg') }}
                </p>
                <div class="upload-images-text">
                  <span
                    ><u>{{ $t('proposition_gallery_blur_link_msg') }}</u>
                  </span>
                  <span>
                    {{ $t('proposition_gallery_blur_msg') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>

    <div
      v-if="propositionImages.length > 1 && isMobile"
      class="image-count-container"
    >
      <div class="image-count-indicator" v-html="imageCountIndicator"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
  name: 'BaseImageSlider',

  components: {
    VueSlickCarousel
  },

  props: {
    propositionImages: {
      type: Array,
      default: () => []
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    carouselSettings: {
      type: Object,
      default: () => {}
    },
    afterPageChange: {
      type: Function,
      default: () => {}
    },
    beforePageChange: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      blurImage: false,
      timeoutId: null
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      primaryProposition: 'myPropositions/primaryProposition'
    }),
    primaryPropositionImages() {
      return this.primaryProposition.residences[0].images;
    },
    imageCount() {
      return this.propositionImages.length;
    },
    imageCountIndicator() {
      return `${
        this.activeIndex + 1
      } <span style="color: rgba(240, 240, 240, 0.5);">· ${
        this.imageCount
      }</span>`;
    },
    effectiveCarouselSettings() {
      if (this.propositionImages.length <= 1 || !this.isMobile) {
        return {
          ...this.carouselSettings,
          swipe: false
        };
      }
      return this.carouselSettings;
    }
  },

  mounted() {
    if (this.primaryPropositionImages.length < 2) {
      this.blurImage = true;
    }
  },

  methods: {
    handleAfterChange(currentIndex) {
      this.$emit('update:activeIndex', currentIndex);
      this.afterPageChange(currentIndex);
    },
    handleSwipe(direction) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        const currentIndex = this.$refs.slickRef.$refs.innerSlider.currentSlide;
        this.$emit('swipeDirection', direction);
        this.afterPageChange(currentIndex);
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.carousel-container {
  position: relative;
  height: 100%;

  .slick-carousel {
    div {
      overflow: hidden;
    }
  }
}

.image-wrapper {
  position: relative;
  height: 100%;
}

.primary-image {
  object-fit: cover;
  aspect-ratio: 2 / 1.2;
  overflow: hidden;

  @media ($mobile) {
    aspect-ratio: 2 / 0.9;
  }

  position: relative;
  width: 100%;
  background-position: center;
  background-size: cover;

  z-index: 1;
  background-image: url('../../assets/svg/skyline-placeholder-img.svg');
}

.is-blurred {
  background-position: center;
  top: 0;
  left: 0;
  z-index: 0;
  filter: blur(30px);
  -webkit-filter: blur(30px); /* Safari 6.0 - 9.0 */
  -moz-filter: blur(30px);
  -o-filter: blur(30px);
  -ms-filter: blur(30px);
  background-size: cover;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.blur-msg-wrapper {
  position: absolute;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  .blur-msg-click-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 40px;
    cursor: pointer;
    gap: 5px;

    p {
      margin: 0;
    }
  }

  .blur-msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 7px;

    .unblock-text {
      font-size: 16px;
      font-weight: 600;
    }
    .upload-images-text {
      color: #fff;
      line-height: 120%;
      font-size: 14px;
    }
  }
}

.image-count-container {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
  bottom: 12px;
  right: 8px;
  .image-count-indicator {
    background-color: #2c3e50b7;
    color: #fff;
    font-size: 11px;
    line-height: 10px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 16px;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  }
}
</style>
