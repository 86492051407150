<template>
  <div>
    <div class="subscription-picker">
      <div
        v-if="
          selectedProduct && selectedProduct.category === 'annonsforlangning'
        "
        class="subscription-options"
      >
        <div
          v-for="(product, index) in sortedProducts"
          :key="product.productId"
          :class="[
            'option',
            {
              'option-is-selected':
                product.productId === selectedProduct.productId
            }
          ]"
          @click="handleSelectProductId(product.productId)"
        >
          <div v-if="index == 1" class="option-label">
            {{ $t('subscription_picker_label') }}
          </div>
          <div class="subscription-period">{{ productTitle(product) }}</div>
          <div class="price-description">{{ productDescription(product) }}</div>
          <div class="price">
            <template v-if="!product.campaign">
              {{ monthlyPrice(product) }}
              {{ $t('subscription_picker_currency') }}
              <span class="month">/{{ $t('subscription_picker_month') }}</span>
            </template>
            <template v-else>
              {{ product.priceWithVAT }}
              {{ $t('subscription_picker_currency') }}
            </template>
          </div>
          <div v-if="monthlyPercentageOff(product)" class="price-discount">
            {{ $t('subscription_picker_save_perc_prefix') }}
            {{ monthlyPercentageOff(product) }}%
          </div>
        </div>
      </div>

      <div
        v-else-if="
          selectedProduct && selectedProduct.category === 'extrasynlighet'
        "
        class="choice-wrapper"
      >
        <button
          v-for="product in products"
          :key="product.id"
          :class="[
            'choice',
            {
              'choice-selected': selectedProduct.productId == product.productId
            }
          ]"
          @click="handleSelectProductId(product.productId)"
        >
          <div v-if="product.quantity == 14" class="choice-label">
            {{ $t('subscription_picker_choice_label') }}
          </div>
          <div class="choice-title">
            <div>{{ $t('subscription_picker_heading') }}</div>
            {{ product.quantity }} {{ $t('extra_visibility_days') }}
          </div>
          <div class="choice-price">
            {{ product.priceInclVat }} {{ $t('extra_visibility_currency') }}
          </div>
          <div class="choice-per-day">
            {{
              $t('subscription_picker_per_day', { price: pricePerDay(product) })
            }}
          </div>
        </button>
      </div>

      <div
        v-if="
          !displayCampaignInfo &&
          selectedProduct &&
          selectedProduct.category === 'annonsforlangning'
        "
        class="subscription-terms-info"
      >
        <strong>{{ $t('subscription_picker_no_lock_period') }}</strong> –
        {{ $t('subscription_picker_no_lock_period_postfix') }}
      </div>

      <div v-if="displayCampaignInfo" class="campaign-info">
        <strong>{{ $t('product_list_title') }}</strong>
        <div>
          {{ $t('product_list_info_1') }}
          {{ paymentPeriod }} {{ $t('product_list_info_2') }} ({{
            selectedProduct.subscriptionProduct.priceInclVat
          }}
          {{ $t('product_list_info_3') }}).
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import events from '@/utils/helpers/events';

export default {
  name: 'SubscriptionPicker',

  computed: {
    ...mapGetters({
      products: 'klarnaPayments/getProducts',
      selectedProduct: 'klarnaPayments/getSelectedProduct',
      isMobile: 'screenSize/isMobile'
    }),

    sortedProducts() {
      const sorted = [...this.products];

      sorted.sort((a, b) => {
        return a.quantity > b.quantity ? 1 : -1;
      });

      return sorted;
    },

    displayCampaignInfo() {
      const { selectedProduct } = this;

      return (
        selectedProduct &&
        selectedProduct.campaign &&
        selectedProduct.subscriptionProduct &&
        selectedProduct.subscriptionProduct.productId !=
          selectedProduct.productId
      );
    },

    paymentPeriod() {
      switch (this.selectedProduct.subscriptionProduct.quantity) {
        case 30:
          return this.$t('product_list_monthly');
        case 60:
          return this.$t('product_list_every_second_month');
        case 180:
          return this.$t('product_list_every_six_month');
        default:
          return this.$t('product_list_when_expired');
      }
    }
  },

  watch: {
    products() {
      if (this.products.length === 0) {
        return;
      }
      const productIds = this.products.map(p => p.id);
      events.emitEvent(events.eventTypes.PAGEVIEW, 'Payment', {
        productIds
      });
    }
  },

  methods: {
    ...mapActions({
      setSelectedProductId: 'klarnaPayments/selectProductId'
    }),

    handleSelectProductId(productId) {
      window.Logger.log('[ProductList]: Selecting productId: ', productId);
      if (productId) {
        this.setSelectedProductId({ productId });
      }
    },

    pricePerDay(product) {
      if (this.$country.isCountry('SE')) {
        return Math.ceil(product.priceInclVat / product.quantity);
      }

      return (product.priceInclVat / product.quantity).toFixed(2);
    },

    monthlyPrice(product) {
      if (this.$country.isCountry('SE')) {
        return Math.ceil(product.priceInclVat / (product.quantity / 30));
      }

      return (product.priceInclVat / (product.quantity / 30)).toFixed(2);
    },

    monthlyPercentageOff(product) {
      const mostExpensiveProductPrice = this.monthlyPrice(
        this.sortedProducts[0]
      );
      const discount = mostExpensiveProductPrice - this.monthlyPrice(product);

      if (discount > 0) {
        return Math.floor((discount / mostExpensiveProductPrice) * 100);
      }

      return false;
    },

    productTitle(product) {
      // Do not change name if product is a campaign product
      if (!product.subscriptionProduct) {
        switch (product.quantity) {
          case 30:
            return this.$t('subscription_picker_one_month');
          case 60:
            return this.$t('subscription_picker_two_months');
          case 180:
            return this.$t('subscription_picker_six_months');
          default:
            return product.name;
        }
      } else {
        return product.name;
      }
    },

    productDescription(product) {
      // Do not change description if product is a campaign product
      if (!product.subscriptionProduct) {
        switch (product.quantity) {
          case 30:
            return this.$t('subscription_picker_one_month_description');
          case 60:
            return this.$t('subscription_picker_two_months_description');
          case 180:
            return this.$t('subscription_picker_six_months_description');
          default:
            return product.description;
        }
      } else {
        return product.description;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.subscription-picker {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.subscription-options {
  @media ($desktop) {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    column-gap: 1rem;
  }
}

.option {
  position: relative;
  margin: 20px 0;
  padding: 20px 4px;
  border: 2px solid #f1f2f6;
  border-radius: 8px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.option-is-selected {
  border: 2px solid #409fff;
  background-color: #e7f3ff;
  color: #409fff;
}

.option-label {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 3px 12px;
  border-radius: 10px;
  background-color: #f1f2f6;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.07em;
  white-space: nowrap;

  .option-is-selected & {
    background-color: #409fff;
    color: #f8f8fa;
  }
}

.subscription-period {
  font-weight: 700;
  margin-bottom: 2px;
}

.price {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;

  .month {
    font-size: 16px;
  }
}

.price-description {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 600;
}

.price-discount {
  font-size: 13px;
  font-weight: 600;
}

.subscription-terms-info {
  margin-top: -5px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.campaign-info {
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.choice {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border: 2px solid #f1f2f6;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    width: 100%;
    margin: 30px 0;

    @media ($desktop) {
      padding: 0 60px;
    }
  }

  &-label {
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: -26px;
    height: 26px;
    padding: 0 25px;
    background: #9f41e9;
    border-radius: 10px 10px 0px 0px;
    font-weight: 600;
    font-size: 10px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    color: #ffffff;
  }

  &-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #9f41e9;
    margin-bottom: 13px;
  }

  &-price {
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #2c3e50;
    margin-bottom: 10px;
  }

  &-per-day {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #878b9d;
  }

  &-selected {
    border-color: #9f41e9;
    background-color: #faf4ff;
  }
}

.checkbox-and-label {
  display: flex;
  margin-left: 4px;

  .labels {
    margin-left: 13px;
  }

  .label {
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
  }

  .sub-label {
    font-weight: 700;
    font-size: 10px;
    line-height: 150%;
    color: #878b9d;
  }
}
</style>
