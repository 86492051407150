function getRightClickTracker(propositionId) {
  let ads = JSON.parse(localStorage.getItem('ad_r_t')) || [];

  return ads.filter(x => x.p === propositionId)[0];
}

function addRightClickTracker(propositionId, source) {
  let ads = JSON.parse(localStorage.getItem('ad_r_t')) || [];

  if (!ads.some(x => x.p === propositionId)) {
    ads.push({ p: propositionId, s: source });
    localStorage.setItem('ad_r_t', JSON.stringify(ads));
  }
}

function removeRightClickTracker(propositionId) {
  let ads =
    JSON.parse(localStorage.getItem('ad_r_t')).filter(
      x => x.p !== propositionId
    ) || [];

  if (ads && ads.length) {
    localStorage.setItem('ad_r_t', JSON.stringify(ads));
  } else {
    localStorage.removeItem('ad_r_t');
  }
}

export { getRightClickTracker, addRightClickTracker, removeRightClickTracker };
