const isMenuOpen = state => state.menuOpen;

const isNotificationCenterOpen = state => state.notificationCenterOpen;

function getNotifications(state) {
  return state.notifications;
}

function getAttentionDot(state) {
  return state.showAttentionDot;
}

function tabBarVisible(state) {
  return state.showMobileTabBar;
}

function stickyTabBar(state) {
  return state.stickyTabBar;
}

export default {
  isMenuOpen,
  isNotificationCenterOpen,
  getNotifications,
  getAttentionDot,
  tabBarVisible,
  stickyTabBar
};
