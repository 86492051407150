<template>
  <div class="conveniences-container">
    <div v-if="residence.balcony" class="convenience">
      <div class="amenities-icon balcony">{{ $t('conveniences_balcony') }}</div>
    </div>
    <div v-if="residence.elevator" class="convenience">
      <div class="amenities-icon elevator">
        {{ $t('conveniences_elevator') }}
      </div>
    </div>
    <div v-if="residence.fireplace" class="convenience">
      <div class="amenities-icon fireplace">
        {{ $t('conveniences_fireplace') }}
      </div>
    </div>
    <div v-if="residence.bathtub" class="convenience">
      <div class="amenities-icon bathtub">{{ $t('conveniences_bathtub') }}</div>
    </div>
    <div v-if="residence.isCondo" class="convenience">
      <div class="amenities-icon condo">{{ $t('conveniences_condo') }}</div>
    </div>
    <div v-if="residence.isBecomingCondo" class="convenience">
      <div class="amenities-icon becoming-condo">
        {{ $t('conveniences_becoming_condo') }}
      </div>
    </div>
    <!-- Missing data -->
    <!-- <div class="convenience">Tvättmaskin</div>
    <div class="convenience">Diskmaskin</div>
    <div class="convenience">Fiber</div>-->
  </div>
</template>

<script>
export default {
  name: 'Conveniences',

  props: {
    residence: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.conveniences-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  @media ($desktop) {
    margin: 0 -5px;
  }
  @media ($mobile) {
    flex-direction: column;
  }
}

.convenience {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 600;

  &:nth-child(3n + 2) {
    // margin: 0 5%;
  }
  @media ($desktop) {
    margin: 0 !important;
    font-size: 0.85rem;
    margin-right: 15px;
    align-items: center;
    padding-bottom: 20px;
  }
  @media ($mobile) {
    justify-content: left;
    align-items: left !important;
    margin-bottom: 15px;
    text-align: left;
    font-size: 0.7rem;
    font-weight: 600;
  }
}

.amenities-icon {
  height: 22px;
  background-size: contain;
  background-position: left;
  padding-left: 33px;
  margin-right: 40px;
  font-size: 0.95rem;
  line-height: 22px;
  font-weight: 600;

  @media ($mobile) {
    background-position: right;
    padding-left: 0px;
    margin-right: 0px;
  }

  &.balcony {
    background-image: url('../../assets/svg/amenitiesIcons/balcony.svg');
  }

  &.elevator {
    background-image: url('../../assets/svg/amenitiesIcons/elevator.svg');
  }

  &.fireplace {
    background-image: url('../../assets/svg/amenitiesIcons/fireplace.svg');
  }

  &.bathtub {
    background-image: url('../../assets/svg/amenitiesIcons/bathtub.svg');
  }

  &.becoming-condo {
    background-image: url('../../assets/svg/amenitiesIcons/becomingCondo.svg');
  }

  &.condo {
    background-image: url('../../assets/svg/amenitiesIcons/condo.svg');
  }

  &.fiber {
    background-image: url('../../assets/svg/amenitiesIcons/fiber.svg');
  }

  &.laundry {
    background-image: url('../../assets/svg/amenitiesIcons/laundry.svg');
  }

  &.dishwasher {
    background-image: url('../../assets/svg/amenitiesIcons/dishwasher.svg');
  }
}
</style>
